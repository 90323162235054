import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { useOAuthLogin } from '../hooks/useOAuthLogin';
import { useEffect } from 'react';
import { useRouter } from '@/hooks/useRouter';

export default function DiscordCallbackPage() {
	const { query } = useRouter();
	const { connectDiscord } = useOAuthLogin(query);

	useEffect(() => {
		connectDiscord();
	}, []);

	return (
		<div className="container flex h-screen w-screen flex-col items-center justify-center">
			<FullPageLoading />
		</div>
	);
}
