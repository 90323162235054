import { DataTable } from '@/components/element/data-table/DataTable';
import { columns } from '../components/channels/ChannelsTable';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import { useMemo } from 'react';
import { QUEST_URL } from '@/config';
import { getSentenceCase } from '@/utils/parsers';

const Channels = () => {
	const { campaign } = useCampaignReport();

	const channels = useMemo(() => {
		if (!campaign || !campaign.channels) return [];
		return campaign.channels
			.map((i) => ({
				title: i.name,
				channel: getSentenceCase(i.medium),
				link: `${QUEST_URL}/quest/${campaign._id}?ref=${i.name}`,
				initiations: 0,
				completions: 0,
				// initiations: i.funnel.started.uniqueUsers,
				// completions: i.funnel.completed.uniqueUsers,
			}))
			?.filter((i) => i.channel !== 'Homepage');
	}, [campaign]);

	return (
		<div className="mt-5">
			<DataTable data={channels} columns={columns} totalCount={12} />
		</div>
	);
};

export default Channels;
