import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { deleteQuery } from '../../services/dave.service';
import { handleErrorMessage } from '@/utils/notifications';
import { useConfirm } from '@/hooks/useConfirm';
import { toast } from 'sonner';
import { queryClient } from '@/lib/react-query';

export const useDeleteQuery = () => {
	const [isLoading, setIsLoading] = useState(false);

	const { show } = useConfirm();

	const deleteQueryHandler = async (ids: string[]) => {
		try {
			setIsLoading(true);
			const res = await show({
				title: 'Are you absolutely sure?',
				subtitle: `This action cannot be undone. This will permanently delete your ${ids.length} ${ids.length === 1 ? ' query' : ' querys'} from our servers.`,
				confirmText: 'Delete',
				cancelText: 'Cancel',
				type: 'error',
			});
			toast.promise(
				Promise.all(ids.map((id) => deleteQuery(id))).then((res) => {
					setIsLoading(false);
					queryClient.invalidateQueries({
						queryKey: ['dave', 'queries'],
					});
				}),
				{
					loading: 'Deleting...',
					success: 'Query deleted successfully',
					error: 'Failed to delete query!',
				},
			);
		} catch (err) {
			setIsLoading(false);
			handleErrorMessage(err);
			console.error(err);
		}
	};

	return {
		deleteQuery: deleteQueryHandler,
		isLoading,
	};
};
