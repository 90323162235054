import { useState } from 'react';
import CampaignCreateLayout from '../components/create-campaign/layout/CampaignCreateLayout';
import CampaignRewards from '../components/create-campaign/rewards/CampaignRewards';
import AddDetails from '../components/create-campaign/details/CampaignDetails';
import { CreateCampaignProvider } from '../providers/CreateCampaignProvider';
import EditTasks from '../components/create-campaign/tasks/EditTasks';
import { CreateCampaignSections } from '../types';

const CampaignCreatePage = () => {
	const [section, setSection] = useState<CreateCampaignSections>(
		CreateCampaignSections.Details,
	);
	return (
		<CreateCampaignProvider section={section} setSection={setSection}>
			<CampaignCreateLayout section={section} setSection={setSection}>
				{section === CreateCampaignSections.Details && <AddDetails />}
				{section === CreateCampaignSections.Tasks && <EditTasks />}
				{section === CreateCampaignSections.Rewards && <CampaignRewards />}
			</CampaignCreateLayout>
		</CreateCampaignProvider>
	);
};

export default CampaignCreatePage;
