import AuthLayout from '@/components/layout/auth/AuthLayout';
import { Button } from '@/components/ui/button';
import useResetPassword from '../hooks/useResetPassword';
import InputPassword from '@/components/element/inputs/InputPassword';
import { useRouter } from '@/hooks/useRouter';
import { useEffect } from 'react';

const ResetPasswordPage = () => {
	const { query } = useRouter();
	const {
		formFields,
		setFormFields,
		formErrors,
		isLoading,
		handleSubmit,
		setFormErrors,
	} = useResetPassword(query?.token?.toString());

	useEffect(() => {
		setFormErrors({});
	}, [formFields, setFormErrors]);
	return (
		<AuthLayout>
			<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
				<div className="flex flex-col space-y-2 text-center">
					<h1 className="text-2xl font-semibold tracking-tight">
						Reset your password
					</h1>
					<p className="text-sm text-muted-foreground">
						Create a new password and make sure it's secure.
					</p>
				</div>
				<div className="grid gap-6">
					<form onSubmit={handleSubmit}>
						<div className="grid gap-2">
							<div className="grid gap-5">
								<InputPassword
									className="mt-2"
									label="Your new password"
									placeholder="8+ characters required"
									required
									error={formErrors['password']}
									errorText={formErrors['password']}
									value={formFields.password}
									setValue={(e) =>
										setFormFields((prev: any) => ({
											...prev,
											password: e,
										}))
									}
								/>
								<InputPassword
									label="Confirm your new password"
									placeholder="8+ characters required"
									required
									error={formErrors['confirmPassword']}
									errorText={formErrors['confirmPassword']}
									value={formFields.confirmPassword}
									setValue={(e) =>
										setFormFields((prev: any) => ({
											...prev,
											confirmPassword: e,
										}))
									}
								/>
								<Button disabled={isLoading} className="mt-2">
									{isLoading ? (
										<i className="bi bi-arrow-clockwise animate-spin mr-1 text-sm"></i>
									) : null}
									Update your password
								</Button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</AuthLayout>
	);
};

export default ResetPasswordPage;
