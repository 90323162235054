import { axios } from '@/lib/axios';

export const getNFTs = async () => {
	const { data } = await axios.get('/tools/nfts');
	return data;
};

export const createNftContract = async (nftContract) => {
	const { data } = await axios.post('/tools/nfts/create', {
		...nftContract,
		nftType: 'INTRACTOPIA',
	});
	return data;
};

export const uploadImage = async (image) => {
	const formData = new FormData();
	formData.append('image', image);

	const response = await axios.post('/tools/nfts/upload-image', formData);
	return response.data;
};

export const updateNftContract = async (nftContract) => {
	const { data } = await axios.put('/tools/nfts/update', nftContract);
	return data;
};
