import { Card, CardContent, CardHeader } from '@/components/ui/card';
import Legends from './Legends';
import {
	TooltipProvider,
	Tooltip,
	TooltipTrigger,
	TooltipContent,
} from '@/components/ui/tooltip';

const ChartCardWithLegends = ({
	children,
	className,
	title,
	legends,
	tooltip,
}: {
	children: React.ReactNode;
	className?: string;
	title: string;
	tooltip?: string;
	legends: {
		key: string;
		label: string;
		opacity?: number | string;
	}[];
}) => {
	return (
		<Card className={className}>
			<CardHeader className="flex justify-between items-center flex-row">
				<div className="flex items-center">
					<h2 className="text-lg font-semibold mb-0">{title}</h2>
					<TooltipProvider delayDuration={0}>
						<Tooltip>
							<TooltipTrigger className="ms-2">
								<i className="bi-info-circle-fill block text-muted-foreground text-xs cursor-pointer"></i>
							</TooltipTrigger>
							<TooltipContent>
								<div className="text-sm font-normal max-w-[400px]">
									{tooltip}
								</div>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
				<div className="w-2/3 text-end">
					<Legends legends={legends} />
				</div>
			</CardHeader>
			<CardContent className="ps-0">{children}</CardContent>
		</Card>
	);
};

export default ChartCardWithLegends;
