import LineChartComponent from '@/components/charts/LineChart';
import ChartCardWithLegends from '@/features/campaigns/components/campaign-report/components/analytics/cards/ChartCardWithLegends';
import { ICampaign } from '@/features/campaigns/types';
import { IEventReport } from '@/features/events/types/events.type';
import React, { useMemo } from 'react';

const EventCompletionChart = ({
	report,
	campaigns,
}: {
	report: IEventReport;
	campaigns: ICampaign[];
}) => {
	const { chartData, legends } = useMemo(() => {
		if (!report && !campaigns) return { chartData: [], legends: [] };
		const legends = campaigns.map((i, index) => ({
			key: i._id,
			label: i.name,
			opacity: 1 - index * 0.2,
		}));
		if (!report?.campaignCompletionTimeSeries)
			return { chartData: [], legends: [] };
		const result = report.campaignCompletionTimeSeries
			.reduce((acc, { _id: { date, campaignId }, count }) => {
				const dateString = new Date(date).toISOString().split('T')[0];
				const existingEntry = acc.find((entry) => entry.date === dateString);
				if (existingEntry) {
					existingEntry[campaignId] = count;
				} else {
					acc.push({ [campaignId]: count, date: dateString });
				}
				return acc;
			}, [])
			?.sort(
				(a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
			);

		return { chartData: result, legends: legends };
	}, [report, campaigns]);

	return (
		<ChartCardWithLegends
			className="mt-10"
			title="Campaign Completions"
			legends={legends}
			tooltip="Check how your campaign initiations happened day over day"
		>
			<LineChartComponent
				data={chartData}
				height="350px"
				tooltipFn={({ active, payload }) => {
					if (active && payload && payload.length) {
						return (
							<div className="rounded-lg border bg-background p-2 shadow-sm">
								<div className="grid grid-cols-1 gap-2">
									{legends.map((i, index) => {
										if (
											payload?.[index] &&
											payload?.[index]?.value
										) {
											return (
												<div className="flex flex-col">
													<span className="text-[0.70rem] uppercase text-muted-foreground">
														{i.label}
													</span>
													<span className="font-bold text-muted-foreground">
														{payload[index].value}
													</span>
												</div>
											);
										}
									})}
								</div>
							</div>
						);
					}
					return null;
				}}
				legends={legends}
			/>
		</ChartCardWithLegends>
	);
};

export default EventCompletionChart;
