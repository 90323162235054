import { Input } from '@/components/ui/input';
import clsx from 'clsx';
import { ReactNode } from 'react';

const InputGroup = ({
	className,
	placeholder,
	value,
	setValue,
	error,
	errorText,
	prepend,
	append,
	type,
	inputClassName,

	onKeyDown,
	wrapperClassName,
	...rest
}: {
	className?: string;
	placeholder?: string;
	value: string;
	setValue: (value: string) => void;
	error?: boolean;
	type?: string;
	errorText?: string;
	prepend?: ReactNode;
	append?: ReactNode;
	inputClassName?: string;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	wrapperClassName?: string;
}) => {
	return (
		<div>
			<div
				className={clsx(
					'flex items-center border rounded-lg overflow-none',
					error ? 'border-destructive rounded-sm' : '',
					wrapperClassName,
				)}
			>
				{prepend && (
					<div className="border-e h-9 flex items-center">{prepend}</div>
				)}
				<Input
					placeholder={placeholder}
					autoCorrect="off"
					value={value}
					onChange={(e) => setValue(e.target.value)}
					className={clsx(
						'border-none shadow-none w-full ring-0 focus:ring-0 focus:outline-none focus-visible:ring-0 focus-visible:outline-none',
						inputClassName,
					)}
					type={type}
					onKeyDown={onKeyDown}
					{...rest}
				/>
				{append && (
					<div className="border-l h-0 flex items-center">{append}</div>
				)}
			</div>
			{error && errorText && (
				<p className="text-xs text-destructive mt-1">{errorText}</p>
			)}
		</div>
	);
};

export default InputGroup;
