import { useState } from 'react';
// DnD
import {
	DndContext,
	DragEndEvent,
	DragMoveEvent,
	DragOverlay,
	DragStartEvent,
	KeyboardSensor,
	PointerSensor,
	UniqueIdentifier,
	closestCorners,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import {
	SortableContext,
	arrayMove,
	sortableKeyboardCoordinates,
	useSortable,
} from '@dnd-kit/sortable';
import React from 'react';

export type DNDType = {
	id: `container-${string | number}`;
	[key: string]: any;
	items: {
		id: `item-${string | number}`;
		[key: string]: any;
	}[];
};

export default function DnDKanban({
	containers,
	setContainers,
	ContainerComponent,
	ItemComponent,
	addItem,
	editItem,
	editContainer,
	selectItem,
}: {
	containers: DNDType[];
	setContainers: (containers: DNDType[], isDragEnd?: boolean) => void;
	ContainerComponent: any;
	ItemComponent: any;
	addItem: (sectionId: string) => any;
	selectItem: (itemId?: string, sectionId?: string) => any;
	editItem: any;
	editContainer: any;
}) {
	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

	// Find the value of the items
	function findValueOfItems(id: UniqueIdentifier | undefined, type: string) {
		if (type === 'container') {
			return containers?.find((item) => item.id === id);
		}
		if (type === 'item') {
			return containers.find((container) =>
				container?.items?.find((item) => item.id === id),
			);
		}
	}

	const findItem = (id: UniqueIdentifier | undefined) => {
		const container = findValueOfItems(id, 'item');
		if (!container) return null;
		const item = container?.items?.find((item) => item.id === id);
		if (!item) return null;
		return item;
	};

	const findContainerItems = (id: UniqueIdentifier | undefined) => {
		const container = findValueOfItems(id, 'container');
		if (!container) return [];
		return container.items;
	};

	// DND Handlers
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	function handleDragStart(event: DragStartEvent) {
		const { active } = event;
		const { id } = active;
		setActiveId(id);
	}

	const handleDragMove = (event: DragMoveEvent) => {
		const { active, over } = event;

		// Handle Items Sorting
		if (
			active.id.toString().includes('item') &&
			over?.id.toString().includes('item') &&
			active &&
			over &&
			active.id !== over.id
		) {
			// Find the active container and over container
			const activeContainer = findValueOfItems(active.id, 'item');
			const overContainer = findValueOfItems(over.id, 'item');

			// If the active or over container is not found, return
			if (!activeContainer || !overContainer) return;

			// Find the index of the active and over container
			const activeContainerIndex = containers.findIndex(
				(container) => container.id === activeContainer.id,
			);
			const overContainerIndex = containers.findIndex(
				(container) => container.id === overContainer.id,
			);

			// Find the index of the active and over item
			const activeitemIndex = activeContainer.items.findIndex(
				(item) => item.id === active.id,
			);
			const overitemIndex = overContainer.items.findIndex(
				(item) => item.id === over.id,
			);
			// In the same container
			if (activeContainerIndex === overContainerIndex) {
				const newItems = [...containers];
				newItems[activeContainerIndex].items = arrayMove(
					newItems[activeContainerIndex].items,
					activeitemIndex,
					overitemIndex,
				);

				setContainers(newItems);
			} else {
				// In different containers
				const newItems = [...containers];
				const [removeditem] = newItems[activeContainerIndex].items.splice(
					activeitemIndex,
					1,
				);
				newItems[overContainerIndex].items.splice(
					overitemIndex,
					0,
					removeditem,
				);
				setContainers(newItems);
			}
		}

		// Handling Item Drop Into a Container
		if (
			active.id.toString().includes('item') &&
			over?.id.toString().includes('container') &&
			active &&
			over &&
			active.id !== over.id
		) {
			// Find the active and over container
			const activeContainer = findValueOfItems(active.id, 'item');
			const overContainer = findValueOfItems(over.id, 'container');

			// If the active or over container is not found, return
			if (!activeContainer || !overContainer) return;

			// Find the index of the active and over container
			const activeContainerIndex = containers.findIndex(
				(container) => container.id === activeContainer.id,
			);
			const overContainerIndex = containers.findIndex(
				(container) => container.id === overContainer.id,
			);

			// Find the index of the active and over item
			const activeitemIndex = activeContainer.items.findIndex(
				(item) => item.id === active.id,
			);

			// Remove the active item from the active container and add it to the over container
			const newItems = [...containers];
			const [removeditem] = newItems[activeContainerIndex].items.splice(
				activeitemIndex,
				1,
			);
			newItems[overContainerIndex].items.push(removeditem);
			setContainers(newItems);
		}
	};

	// This is the function that handles the sorting of the containers and items when the user is done dragging.
	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event;

		// Handling Container Sorting
		if (
			active.id.toString().includes('container') &&
			over?.id.toString().includes('container') &&
			active &&
			over &&
			active.id !== over.id
		) {
			// Find the index of the active and over container
			const activeContainerIndex = containers.findIndex(
				(container) => container.id === active.id,
			);
			const overContainerIndex = containers.findIndex(
				(container) => container.id === over.id,
			);
			// Swap the active and over container
			let newItems = [...containers];
			newItems = arrayMove(newItems, activeContainerIndex, overContainerIndex);
			setContainers(newItems, true);
		}

		// Handling item Sorting
		if (
			active.id.toString().includes('item') &&
			over?.id.toString().includes('item') &&
			active &&
			over &&
			active.id !== over.id
		) {
			// Find the active and over container
			const activeContainer = findValueOfItems(active.id, 'item');
			const overContainer = findValueOfItems(over.id, 'item');

			// If the active or over container is not found, return
			if (!activeContainer || !overContainer) return;
			// Find the index of the active and over container
			const activeContainerIndex = containers.findIndex(
				(container) => container.id === activeContainer.id,
			);
			const overContainerIndex = containers.findIndex(
				(container) => container.id === overContainer.id,
			);
			// Find the index of the active and over item
			const activeitemIndex = activeContainer.items.findIndex(
				(item) => item.id === active.id,
			);
			const overitemIndex = overContainer.items.findIndex(
				(item) => item.id === over.id,
			);

			// In the same container
			if (activeContainerIndex === overContainerIndex) {
				const newItems = [...containers];
				newItems[activeContainerIndex].items = arrayMove(
					newItems[activeContainerIndex].items,
					activeitemIndex,
					overitemIndex,
				);
				setContainers(newItems, true);
			} else {
				// In different containers
				const newItems = [...containers];
				const [removeditem] = newItems[activeContainerIndex].items.splice(
					activeitemIndex,
					1,
				);
				newItems[overContainerIndex].items.splice(
					overitemIndex,
					0,
					removeditem,
				);
				setContainers(newItems, true);
			}
		}
		// Handling item dropping into Container
		if (
			active.id.toString().includes('item') &&
			over?.id.toString().includes('container') &&
			active &&
			over &&
			active.id !== over.id
		) {
			// Find the active and over container
			const activeContainer = findValueOfItems(active.id, 'item');
			const overContainer = findValueOfItems(over.id, 'container');

			// If the active or over container is not found, return
			if (!activeContainer || !overContainer) return;
			// Find the index of the active and over container
			const activeContainerIndex = containers.findIndex(
				(container) => container.id === activeContainer.id,
			);
			const overContainerIndex = containers.findIndex(
				(container) => container.id === overContainer.id,
			);
			// Find the index of the active and over item
			const activeitemIndex = activeContainer.items.findIndex(
				(item) => item.id === active.id,
			);

			const newItems = [...containers];
			const [removeditem] = newItems[activeContainerIndex].items.splice(
				activeitemIndex,
				1,
			);
			newItems[overContainerIndex].items.push(removeditem);
			setContainers(newItems, true);
		}
		setActiveId(null);
	}

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCorners}
			onDragStart={handleDragStart}
			onDragMove={handleDragMove}
			onDragEnd={handleDragEnd}
		>
			<SortableContext items={containers ? containers?.map((i) => i.id) : []}>
				{containers ? (
					containers?.map((container) => (
						<Container
							id={container.id}
							ContainerComponent={ContainerComponent}
							{...container}
							key={container.id}
							addItem={addItem}
							editContainer={editContainer}
						>
							<SortableContext
								items={
									container?.items && container?.items?.length
										? container?.items?.map((i) => i.id)
										: []
								}
							>
								{container?.items && container?.items?.length ? (
									container?.items?.map((i) => (
										<Item
											id={i.id}
											{...i}
											key={i.id}
											sectionId={container.id}
											ItemComponent={ItemComponent}
											editItem={editItem}
											selectItem={selectItem}
										/>
									))
								) : (
									<></>
								)}
							</SortableContext>
						</Container>
					))
				) : (
					<></>
				)}
			</SortableContext>
			<DragOverlay adjustScale={false}>
				{/* Drag Overlay For item Item */}
				{activeId && activeId.toString().includes('item') && (
					<Item
						id={activeId}
						ItemComponent={ItemComponent}
						{...findItem(activeId)}
						editItem={editItem}
						selectItem={selectItem}
						sectionId={activeId}
					/>
				)}
				{/* Drag Overlay For Container */}
				{activeId && activeId.toString().includes('container') && (
					<Container
						ContainerComponent={ContainerComponent}
						id={activeId}
						addItem={addItem}
						editContainer={editContainer}
					>
						{findContainerItems(activeId)?.map((i) => (
							<Item
								ItemComponent={ItemComponent}
								key={i.id}
								{...i}
								editItem={editItem}
								selectItem={selectItem}
								sectionId={activeId}
							/>
						))}
					</Container>
				)}
			</DragOverlay>
		</DndContext>
	);
}

const Container = ({ id, ContainerComponent, ...rest }) => {
	const { attributes, setNodeRef, listeners, transform, transition, isDragging } =
		useSortable({
			id: id,
			data: {
				type: 'container',
			},
		});

	return (
		<>
			<ContainerComponent
				{...rest}
				attributes={attributes}
				setNodeRef={setNodeRef}
				listeners={listeners}
				transform={transform}
				transition={transition}
				isDragging={isDragging}
				id={id}
			/>
			{/* {component({
				...rest,
				attributes,
				setNodeRef,
				listeners,
				transform,
				transition,
				isDragging,
			})} */}
		</>
	);
};

const Item = ({ id, ItemComponent, ...rest }) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
		isSorting,
	} = useSortable({
		id: id,
		data: {
			type: 'item',
		},
	});
	return (
		<>
			<ItemComponent
				{...rest}
				attributes={attributes}
				setNodeRef={setNodeRef}
				listeners={listeners}
				transform={transform}
				transition={transition}
				isDragging={isDragging}
				id={id}
				isSorting={isSorting}
			/>
			{/* {component({
				...rest,
				attributes,
				listeners,
				setNodeRef,
				transform,
				transition,
				isDragging,
				isSorting,
			})} */}
		</>
	);
};
