import React from 'react';

const UnderstandingTxn = () => {
	return (
		<div className="mt-10">
			<div className="mb-5">
				<div className="text-sm font-medium">
					Types of Input Data in an Ethereum Transaction
				</div>
				<p className="text-sm ">
					There are two main types of input data that can be included in an
					Ethereum transaction: function call data and arbitrary data.
				</p>
			</div>
			<div className="mb-10">
				<div className="text-md font-medium mb-2">Function Call Data</div>
				<p className="text-sm">
					Function call data is the most common type of input data and is
					used to call a specific function in a smart contract. Function
					call data typically includes the function signature (or MethodID)
					and any parameters that the function requires. For example, if a
					user wants to transfer tokens to another account on Ethereum,
					they would need to send a transaction to the smart contract that
					manages those tokens. The Input Data for this transaction would
					typically include the function signature for the transfer
					function and the parameters for that function, such as the
					recipient's address and the amount of tokens to transfer.
				</p>
				<img src="https://info.etherscan.com/understanding-transaction-input-data/images/size/w1600/2023/04/image_2023-04-10_084441421.png" />
			</div>
			<div className="mb-10">
				<div className="text-md font-medium mb-2">Event Logs Data</div>
				<p className="text-sm mb-5">
					Event logs are a mechanism for smart contracts to emit structured
					data during their execution. They serve the purpose of providing
					a transparent and decentralized way for contracts to communicate
					information to external applications and other contracts.
				</p>
				<img
					className="mb-4 rounded-2xl"
					src="https://info.etherscan.com/what-is-event-logs/images/size/w1600/2023/09/image-31.png"
				/>
				<img
					className="mb-4 rounded-2xl"
					src="https://info.etherscan.com/what-is-event-logs/images/size/w1600/2023/09/image-32.png"
				/>
			</div>
		</div>
	);
};

export default UnderstandingTxn;
