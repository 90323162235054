import Spinner from '@/components/element/loading/Spinner';
import { Button } from '@/components/ui/button';
import { exportLeaderboards } from '@/features/leaderboard/services/leaderboard.service';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { useState } from 'react';
import { toast } from 'sonner';

const DownloadLoyaltyLeaderboard = ({ campaignId }: { campaignId: string }) => {
	const [isLoading, setIsLoading] = useState(false);

	const downloadLeaderboard = async () => {
		try {
			setIsLoading(true);
			await exportLeaderboards({ campaignId });
			analytics.track(TrackingEvents.LoyaltyLeaderboardExported, {});
			toast.success(
				'Exporting leaderboard. You will recieve an email shortly.',
			);
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			console.log(err);
		}
	};

	return (
		<div>
			<Button
				onClick={() => {
					downloadLeaderboard();
				}}
				variant="outline"
				disabled={isLoading}
			>
				{isLoading ? (
					<Spinner className="me-2" />
				) : (
					<i className="bi-download me-2"></i>
				)}
				Export Leaderboard
			</Button>
		</div>
	);
};

export default DownloadLoyaltyLeaderboard;
