import { cn } from '@/lib/utils';

const InputRadioCards = ({
	options,
	className,
	colSize,
}: {
	options: {
		label: string;
		icon?: string;
		isChecked: boolean;
		setIsChecked: (value: boolean) => void;
		className?: string;
	}[];
	className?: string;
	colSize?: number;
}) => {
	return (
		<div
			className={`grid gap-4  grid-cols-${colSize ? colSize : options.length}  ${className}`}
		>
			{options.map((option, index) => (
				<RadioItem
					key={index}
					isChecked={option.isChecked}
					label={option.label}
					icon={option.icon}
					className={option.className}
					onClick={() => {
						option.setIsChecked(!option.isChecked);
					}}
				/>
			))}
		</div>
	);
};

export default InputRadioCards;

const RadioItem = ({ isChecked, label, icon, onClick, className }) => {
	return (
		<div
			className={cn(
				isChecked
					? 'border-violet-500 border-2 border-opacity-80'
					: 'border-2 border-slate-300',
				`p-3 rounded-lg cursor-pointer relative flex items-center `,
				className,
			)}
			onClick={onClick}
		>
			<div className="absolute top-0 end-0 p-3">
				<i
					className={cn(
						isChecked ? 'bi-check-circle-fill' : `bi-circle`,
						isChecked ? 'text-violet-500' : `text-slate-300 `,
					)}
				/>
			</div>
			<i
				className={cn(
					isChecked ? 'text-violet-500' : 'text-slate-400',
					icon,
					'me-1 block',
				)}
			/>
			<div className="text-sm font-medium">{label}</div>
		</div>
	);
};
