import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';

const EditTaskLayout = ({ children }) => {
	const { isReordering, setIsReordering } = useCreateCampaign();
	return (
		<div className="relative">
			<div className="flex flex-col items-center justify-center pt-5 relative">
				<div className="absolute top-0 end-0 h-full p-3">
					<Button
						variant={isReordering ? 'default' : 'outline'}
						size="sm"
						onClick={() => setIsReordering((prev) => !prev)}
					>
						<i className="bi bi-sort-down me-1"></i>
						{!isReordering ? 'Reorder Tasks' : 'Save Ordering'}
					</Button>
				</div>
				<div className="absolute top-0 h-full">
					<Separator
						className=" h-full  -z-10 bg-slate-300"
						orientation="vertical"
					/>
				</div>
				<div className="relative flex flex-col items-center justify-center z-30">
					{children}
				</div>
			</div>
		</div>
	);
};

export default EditTaskLayout;
