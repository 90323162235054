import InputText from '@/components/element/inputs/InputText';
import { useOnboarding } from '../hooks/useOnboardingV2';
import { Button } from '@/components/ui/button';
import logo from '@/assets/logo.svg';

export default function OnboardingUser() {
	const { community, setCommunity, isLoading, errors, handleCommunitySetup } =
		useOnboarding();

	return (
		<div>
			<div className="mx-auto flex w-screen h-screen flex-col justify-center items-center sm:w-[420px] px-8 lg:px-0 text-center">
				<div className="space-y-8 w-full">
					<div className="mb-5 pb-5 text-center flex flex-col items-center">
						<img src={logo} className="h-10 mb-5" />
						<h3 className="text-lg font-medium">
							Setup your community profile
						</h3>
						<p className="text-muted-foreground mt-1 text-sm px-10">
							Please provide the following details to setup your
							community profile
						</p>
					</div>
					<InputText
						label="Project Name"
						required={true}
						placeholder="Uniswap"
						value={community.name}
						setValue={(e) =>
							setCommunity((p) => ({
								...p,
								name: e,
							}))
						}
						error={errors.name}
					/>
					<InputText
						label="Project Twitter"
						required={true}
						placeholder="intractCampaign"
						prepend={
							<div className="ps-3 pe-2 flex items-center text-xs">
								<i className="bi bi-twitter text-twitter pe-2"></i>
								<span className="text-muted-foreground font-medium">
									https://twitter.com/
								</span>
							</div>
						}
						value={community.twitter}
						setValue={(e) =>
							setCommunity((p) => ({
								...p,
								twitter: e,
							}))
						}
						errorText="Please enter your twitter username"
						error={errors.twitter}
					/>
					<div className="mt-5 flex gap-3">
						<Button
							onClick={handleCommunitySetup}
							disabled={isLoading}
							className="flex-grow"
						>
							{isLoading ? (
								<span>Loading...</span>
							) : (
								<span>Continue</span>
							)}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}
