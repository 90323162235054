import { integrationColumns } from './table/IntegrationsColumns';
import { IIntegration } from '../types/integrations.types';
import { DataTable } from '@/components/element/data-table/DataTable';
import { toast } from 'sonner';
import { useIntegrationsMutations } from '../hooks/useIntegrationsMutations';
import { IntegrationType } from '../types/integrations.enums';

const ConnectedIntegrations = ({
	isLoading,
	integrations,
}: {
	isLoading: boolean;
	integrations: IIntegration[];
}) => {
	const { refetchIntegration, disconnectIntegration } = useIntegrationsMutations();

	const cols = integrationColumns([
		{
			label: 'Copy Integration ID',
			onClick: (row: any) => {
				navigator.clipboard.writeText(row._id);
				toast.success('Integration ID copied to clipboard');
			},
		},
		{
			label: 'Refetch Discord Data',
			onClick: (row: any) => {
				refetchIntegration(row?._id);
			},
			show: (row: IIntegration) =>
				row.integrationType === IntegrationType.Discord,
		},
		{
			type: 'separator',
		},
		{
			label: 'Delete Integration',
			onClick: (row: any) => {
				navigator.clipboard.writeText(row._id);
				disconnectIntegration(row?._id);
			},
		},
	]);

	return (
		<div>
			<DataTable
				columns={cols}
				data={integrations ?? []}
				isLoading={isLoading}
				totalCount={integrations?.length || 0}
				hidePagination
			/>
		</div>
	);
};

export default ConnectedIntegrations;
