import { createContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { LoyaltySectionTabs } from '../types/loyalty.enums';
import { ICampaign } from '@/features/campaigns/types';
import { getLoyaltyCampaign } from '../services/loyalty.service';

interface LoyaltyCampaignProviderState {
	campaign: ICampaign;
	isLoading: boolean;
	id: string;
	selectedTab: LoyaltySectionTabs;
	setSelectedTab: (tab: LoyaltySectionTabs) => void;
}

export const LoyaltyCampaignContext = createContext<LoyaltyCampaignProviderState>({
	campaign: null,
	isLoading: false,
	id: '',
	selectedTab: LoyaltySectionTabs.Tasks,
	setSelectedTab: () => {},
});

export function LoyaltyCampaignProvider({ children }) {
	const [id, setId] = useState('');
	const [selectedTab, setSelectedTab] = useState(LoyaltySectionTabs.Tasks);
	const { data, isLoading } = useQuery<ICampaign>({
		queryKey: ['campaign', 'loyalty-quests'],
		queryFn: () => getLoyaltyCampaign(),
	});

	useEffect(() => {
		if (data) setId(data._id);
	}, [data]);

	return (
		<LoyaltyCampaignContext.Provider
			value={{
				campaign: data,
				isLoading,
				id,
				selectedTab,
				setSelectedTab,
			}}
		>
			{children}
		</LoyaltyCampaignContext.Provider>
	);
}
