import { Button } from '@/components/ui/button';
import ShareCampaign from '../../../campaign/ShareCampaign';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import { useCampaignActions } from '@/features/campaigns/hooks/report/useCampaignActions';
import { useEffect, useMemo, useState } from 'react';
import {
	CampaignRewardCategory,
	CampaignRewardType,
	ReportTabs,
	Status,
} from '@/features/campaigns/types';
import Spinner from '@/components/element/loading/Spinner';
import CampaignLaunchDialog from '../../../create-campaign/launch/CampaignLaunchDialog';
import DotsDropdown from '@/components/element/dropdowns/DotsDropdown';
import { useSearchParams } from 'react-router-dom';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { useCampaignWithdrawFunds } from '@/features/campaigns/hooks/report/useCampaignWithdrawFunds';
import RestartCampaign from '../../../campaign/RestartCampaign';

const CampaignActions = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { campaign, id, link, selectedTab } = useCampaignReport();
	const [openLaunch, setOpenLaunch] = useState(false);
	const [openRestart, setOpenRestart] = useState(false);
	const [openShare, setOpenShare] = useState(
		searchParams.get('share-celebrate') === 'true' ? true : false,
	);
	useCampaignWithdrawFunds();

	const {
		isLoading,
		convertToDraft,
		duplicateCampaign,
		deleteCampaign,
		downloadLeaderboard,
		downloadWinners,
		downloadSubmissions,
		restartCampaign,
	} = useCampaignActions();

	useEffect(() => {
		if (searchParams.get('share-celebrate') === 'true') {
			setOpenShare(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams.get('share-celebrate')]);

	const canRestart = useMemo(() => {
		if (campaign.status === Status.Completed) {
			const isTokenReward = campaign.reward.find(
				(i) => i.category === CampaignRewardCategory.Token,
			);
			const isLuckyDraw = campaign.reward.find((i) =>
				[
					CampaignRewardType.Leaderboard,
					CampaignRewardType.SmartRaffle,
					CampaignRewardType.LuckyDraw,
				].includes(i.method),
			);
			if (!isTokenReward && !isLuckyDraw) return true;
			return false;
		}
		return false;
	}, [campaign]);

	const menuOptions = [
		{
			type: 'item',
			label: 'Share Campaign',
			onClick: () => setOpenShare(true),
		},
		{
			type: 'item',
			label: 'Duplicate Campaign',
			onClick: () => duplicateCampaign(id),
		},
		...(canRestart
			? [
					{
						type: 'item',
						label: 'Restart Campaign',
						onClick: () => setOpenRestart(true),
					},
				]
			: []),
		{
			type: 'separator',
		},
		{
			type: 'item',
			label: 'Delete Campaign',
			onClick: () => deleteCampaign(id),
		},
	];

	if (!campaign) return <></>;
	return (
		<>
			{campaign?.status === Status.InReview && (
				<>
					<Button
						variant="outline"
						onClick={() => convertToDraft(id, campaign?.status)}
						disabled={Object.values(isLoading).some((i) => i)}
					>
						{isLoading.convertToDraft ? (
							<Spinner className="me-2" />
						) : (
							<i className="bi-pencil-square me-2"></i>
						)}
						Edit Campaign
					</Button>
					<Button
						onClick={() => setOpenLaunch(true)}
						disabled={Object.values(isLoading).some((i) => i)}
					>
						<i className="bi-rocket-takeoff me-2"></i>
						<span>Launch Campaign</span>
					</Button>
				</>
			)}
			{campaign?.status !== Status.InReview &&
				selectedTab !== ReportTabs.Winners &&
				selectedTab !== ReportTabs.Submissions && (
					<>
						<Button
							onClick={() => {
								downloadLeaderboard(campaign?._id);
							}}
							variant="outline"
							disabled={isLoading.exporting}
						>
							{isLoading.exporting ? (
								<Spinner className="me-2" />
							) : (
								<i className="bi-download me-2"></i>
							)}
							<span>Download Leaderboard</span>
						</Button>
					</>
				)}
			{campaign?.status !== Status.InReview &&
				campaign?.status === Status.Completed &&
				selectedTab === ReportTabs.Winners && (
					<>
						<Button
							onClick={() => {
								downloadWinners(campaign?._id);
							}}
							variant="outline"
							disabled={isLoading.exporting}
						>
							{isLoading.exporting ? (
								<Spinner className="me-2" />
							) : (
								<i className="bi-download me-2"></i>
							)}
							<span>Download Winners</span>
						</Button>
					</>
				)}
			{campaign?.status !== Status.InReview &&
				selectedTab === ReportTabs.Submissions && (
					<>
						<Button
							onClick={() => {
								downloadSubmissions(campaign?._id);
							}}
							variant="outline"
						>
							{isLoading.exporting ? (
								<Spinner className="me-2" />
							) : (
								<i className="bi-download me-2"></i>
							)}
							<span>Download Submissions</span>
						</Button>
					</>
				)}
			<TooltipWrapper tooltip="Check how your campaign looks" align="end">
				<Button
					variant="outline"
					size="icon"
					className=""
					onClick={() => {
						window.open(link, '_blank');
					}}
				>
					<i className="bi bi-box-arrow-up-right"></i>
				</Button>
			</TooltipWrapper>
			<DotsDropdown options={menuOptions} />
			{campaign?.status === Status.InReview && (
				<CampaignLaunchDialog
					open={openLaunch}
					setOpen={setOpenLaunch}
					campaignId={campaign?._id}
					tokenReward={campaign?.reward?.find(
						(i) => i.category === CampaignRewardCategory.Token,
					)}
					startImmediately={campaign?.startImmediately}
				/>
			)}
			<ShareCampaign
				open={openShare}
				setOpen={() => {
					setOpenShare(false);
					setSearchParams({});
				}}
				isCelebrate={
					searchParams.get('share-celebrate') === 'true' ? true : false
				}
				link={link}
			/>
			<RestartCampaign
				open={openRestart}
				setOpen={setOpenRestart}
				campaign={campaign}
			/>
		</>
	);
};

export default CampaignActions;
