import { useContext } from 'react';
import { DaveQueryCreateContext } from '../../providers/DaveQueryCreateProvider';

export const useCreateQueryProvider = () => {
	const context = useContext(DaveQueryCreateContext);

	if (context === undefined)
		throw new Error(
			'useCreateCampaign must be used within a CreateCampaignProvider',
		);

	return context;
};
