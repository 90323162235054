import type { Option } from '@/types/';
import { CheckIcon, PlusCircledIcon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator';

export function FacetedFilterSingle({
	title,
	options,
	selectedValue,
	setSelectedValue,
	className,
	varient,
}: {
	title: string;
	options: {
		value: string;
		label: string | JSX.Element;
		emoji?: string;
		img?: string;
	}[];
	selectedValue: string;
	setSelectedValue: (values: string) => void;
	className?: string;
	varient?: 'default' | 'outline';
}) {
	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					variant={varient ? varient : 'outline'}
					size="sm"
					className={`h-9 border-dashed ${className}`}
				>
					<PlusCircledIcon className="mr-2 size-4" />
					{title}
					{selectedValue && (
						<>
							<Separator orientation="vertical" className="mx-2 h-4" />
							<div className="hidden space-x-1 lg:flex">
								{options
									.filter(
										(option) => selectedValue === option.value,
									)
									.map((option) => (
										<Badge
											variant="secondary"
											key={option.value}
											className="rounded-sm px-1 font-normal"
										>
											{option.label}
										</Badge>
									))}
							</div>
						</>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[250px] p-0" align="start">
				<Command>
					<CommandInput placeholder={title} />
					<CommandList>
						<CommandEmpty>No results found.</CommandEmpty>
						<CommandGroup>
							{options.map((option) => {
								const isSelected = selectedValue.includes(
									option.value,
								);
								return (
									<CommandItem
										key={option.value}
										onSelect={() => {
											setSelectedValue(option.value);
										}}
									>
										<div
											className={cn(
												'mr-2 flex size-4 items-center justify-center rounded-sm border border-primary',
												isSelected
													? 'bg-primary text-primary-foreground'
													: 'opacity-50 [&_svg]:invisible',
											)}
										>
											<CheckIcon
												className={cn('size-4')}
												aria-hidden="true"
											/>
										</div>
										{option.emoji && (
											<span className="mr-2">
												{option.emoji}
											</span>
										)}
										<span className="flex gap-2 items-center">
											{option?.img ? (
												<img
													src={option.img}
													className="size-4 rounded-full"
												/>
											) : null}
											{option.label}
										</span>
									</CommandItem>
								);
							})}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
}
