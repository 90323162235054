const Legends = ({
	legends,
}: {
	legends: {
		key: string;
		label: string;
		opacity?: number | string;
	}[];
}) => {
	return (
		<div className="flex justify-end flex-wrap text-xs gap-3">
			{legends.map((legend, i) => (
				<>
					<div key={i}>
						<i
							className={`bi-circle-fill text-primary  me-2`}
							style={{
								opacity: legend.opacity,
							}}
						></i>
						{legend.label}
					</div>
					{/* {legends.length - 1 !== i && (
						<i className="bi-dot text-primary "></i>
					)} */}
				</>
			))}
		</div>
	);
};

export default Legends;
