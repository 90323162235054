import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { IEnterpriseNotification } from '@/features/profile/types/notifications.type';
import React from 'react';

const EventNotification = ({
	notification,
}: {
	notification: IEnterpriseNotification;
}) => {
	return (
		<div className="mt-">
			<Card className="shadow-none rounded-lg w-full bg-slate-100">
				<CardContent className="p-6 flex items-center justify-between">
					<div>
						<div className=" text-lg font-medium">
							Be a part of Intract Bonanza Event 🔥
						</div>
						<div className="text-sm mt-1">
							Join the event and we will match your reward in the
							campaign upto $2000.
						</div>
					</div>
					<div className="text-center">
						<Button size="lg" variant="outline">
							Participate in Event
							<i className="bi-arrow-right-circle-fill ms-2"></i>
						</Button>
					</div>
				</CardContent>
			</Card>
		</div>
	);
};

export default EventNotification;
