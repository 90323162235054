import { useEffect, useState } from 'react';
import logo from '@/assets/logo.svg';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/hooks/useAuth';

const Navbar = () => {
	const { user } = useAuth();
	const [isTop, setIsTop] = useState(true);

	useEffect(() => {
		const handleScroll = () => {
			const top = window.scrollY < 100;
			setIsTop(top);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className="sticky inset-x-0 top-0 z-30 w-full transition-all">
			<div
				className={`-z-1 absolute inset-0 transition-all ${isTop ? 'border-transparent' : 'border-b border-black/10 bg-white/75 backdrop-blur-lg dark:border-white/10 dark:bg-black/75'}`}
			/>
			<div className="mx-auto w-full max-w-screen-xl px-2.5 lg:px-20 relative">
				<div className="flex h-14 items-center justify-between">
					<div className="flex items-center space-x-12">
						<Link to="/">
							<div className="max-w-fit flex items-center">
								<img
									src={logo}
									alt="Intract Logo"
									className="h-8 w-auto"
								/>
							</div>
						</Link>
						<nav
							aria-label="Main"
							data-orientation="horizontal"
							dir="ltr"
							className="relative hidden lg:block"
						>
							<div style={{ position: 'relative' }}>
								<ul
									data-orientation="horizontal"
									className="flex flex-row space-x-2 p-4"
									dir="ltr"
								>
									{navbarConfig.map((i) =>
										i.link.includes('http') ? (
											<a
												className="rounded-md px-3 py-2 text-sm font-medium text-zinc-500 transition-colors ease-out hover:text-black dark:text-white/70 dark:hover:text-white "
												href={`${i.link}`}
												target="_blank"
												rel="noreferrer"
											>
												{i.label}
											</a>
										) : (
											<Link
												className="rounded-md px-3 py-2 text-sm font-medium text-zinc-500 data-[active=true]:text-zinc-900  transition-colors ease-out hover:text-black dark:text-white/70 dark:hover:text-white"
												to={`/${i.link}`}
												data-active={window.location.pathname.includes(
													i.link,
												)}
											>
												{i.label}
											</Link>
										),
									)}
								</ul>
							</div>
						</nav>
					</div>
					{user ? (
						<div className="lg:block flex space-x-3">
							<Link to="/app">
								<Button className="rounded-xl">
									Continue to Dashboard
								</Button>
							</Link>
						</div>
					) : (
						<div className="lg:block flex space-x-3">
							<Link to="/auth/login">
								<Button variant="ghost">Login</Button>
							</Link>
							<Link to="/auth/register">
								<Button className="rounded-xl">Sign Up</Button>
							</Link>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Navbar;

const navbarConfig = [
	{
		label: 'Features',
		link: 'features',
	},
	{
		label: 'Customers',
		link: 'customers',
	},
	{
		label: 'Enterprise',
		link: 'enterprise',
	},

	{
		label: 'About',
		link: 'about',
	},
	{
		label: 'Docs',
		link: 'https://docs.intract.io',
	},
	{
		label: 'Blogs',
		link: 'https://www.blogs.intract.io',
	},
];
