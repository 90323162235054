import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { cn } from '@/lib/utils';

const TabOptions = ({
	options,
	selected,
	setSelected,
	className,
}: {
	options: { value: string; label: string; icon?: string; disabled?: boolean }[];
	selected: string;
	setSelected: (value: any) => void;
	className?: string;
}) => {
	return (
		<Tabs value={selected} onValueChange={setSelected} className={className}>
			<TabsList className={`grid  grid-cols-${options.length}`}>
				{options.map((i) => (
					<TabsTrigger value={i.value} key={i.value} disabled={i.disabled}>
						{i.icon && <i className={cn(i.icon, 'me-2')}></i>}
						{i.label}
					</TabsTrigger>
				))}
			</TabsList>
		</Tabs>
	);
};

export default TabOptions;
