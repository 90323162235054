import React from 'react';
import { SubmissionItem } from './SubmissionItem';
import { Button } from '@/components/ui/button';
import Spinner from '@/components/element/loading/Spinner';
import { ITask, POWVerifyStatus } from '@/features/campaigns/types';
import { useCampaignSubmissionsApprovals } from '@/features/campaigns/hooks/report/useCampaignSubmissionsApprovals';

export const SubmissionCard = ({
	selectedRow,
	handlePrevious,
	handleNext,
	selectedTaskId,
	powTasks,
	queryKey,
	setCursor,
	campaignId,
}: {
	selectedRow: any;
	handlePrevious: () => void;
	handleNext: () => void;
	selectedTaskId: string;
	powTasks: ITask[];
	queryKey: any[];
	setCursor: any;
	campaignId: string;
}) => {
	const { handleReview, isActionLoading } = useCampaignSubmissionsApprovals(
		queryKey,
		campaignId,
	);

	return (
		<div className=" p-4 rounded-xl border flex-col h-full relative min-h-[65vh] border-dashed flex items-center justify-start bg-slate-50">
			<SubmissionItem
				task={powTasks.find((i) => i._id === selectedTaskId)}
				submission={selectedRow}
			/>
			<div className="absolute flex gap-2 justify-between w-full bottom-0 p-4 items-center">
				<div className="flex gap-2">
					<Button
						size="icon"
						variant="outline"
						className="h-6 w-6"
						onClick={handlePrevious}
					>
						<i className="bi-chevron-left"></i>
					</Button>
					<Button
						size="icon"
						variant="outline"
						className="h-6 w-6"
						onClick={handleNext}
					>
						<i className="bi-chevron-right"></i>
					</Button>
				</div>
				<div className="flex gap-2">
					{selectedRow?.status === POWVerifyStatus.Initiated && (
						<>
							<Button
								variant="outline"
								disabled={
									isActionLoading.reject ||
									isActionLoading.approval
								}
								onClick={async () => {
									await handleReview(
										selectedRow?._id,
										selectedTaskId,
										false,
									);
									handleNext();
								}}
							>
								{isActionLoading.rejectSelected && (
									<Spinner className="me-1" />
								)}
								<span>Reject</span>
							</Button>

							<Button
								disabled={
									isActionLoading.reject ||
									isActionLoading.approval
								}
								onClick={async () => {
									await handleReview(
										selectedRow?._id,
										selectedTaskId,
										true,
									);
									handleNext();
								}}
							>
								{isActionLoading.approval && (
									<Spinner className="me-1" />
								)}
								<span>Approve</span>
							</Button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
