import { Route, Routes } from 'react-router-dom';
import CampaignTablePage from './campaign-table.page';
import CampaignCreatePage from './campaign-create.page';
import CampaignReportPage from './campaign-report.page';

export default function CampaignRoutes() {
	return (
		<Routes>
			<Route path="" element={<CampaignTablePage />} />
			<Route path="new" element={<CampaignCreatePage />} />
			<Route path=":id/update" element={<CampaignCreatePage />} />
			<Route path=":id" element={<CampaignReportPage />} />
		</Routes>
	);
}
