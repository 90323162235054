import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import React, { useState } from 'react';

export const AuthenticateDiscord = ({ authenticateDiscord }) => {
	const [checked, setChecked] = useState(false);

	return (
		<div className="p-8">
			<div className="font-medium mb-2">What you’ll need</div>
			<div className="text-sm text-muted-foreground">
				To authenticate your community Discord and connect your account,
				confirm that you have Manage Server permissions.
			</div>
			<div>
				<div
					className="border inline-flex items-center rounded mt-3 px-3 py-2 cursor-pointer"
					onClick={() => {
						setChecked((p) => !p);
					}}
				>
					<Checkbox checked={checked} />
					<div className="text-sm  ms-2">
						I have Manage Server permissions
					</div>
				</div>
			</div>
			<Button
				size="sm"
				className="mt-4 "
				disabled={!checked}
				onClick={authenticateDiscord}
			>
				Authenticate Discord
			</Button>
		</div>
	);
};
