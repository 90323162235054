import DashboardProject from './DashboardProject';
import { SlashIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import Logo from '@/assets/logo.svg';

export default function DashboardScope() {
	return (
		<div className="flex items-center flex-1 pr-6">
			<Link to="/" className="md:block hidden">
				<img src={Logo} className="size-7 text-foreground fill-foreground" />
			</Link>
			<SlashIcon className="md:block hidden text-foreground/10 size-6 -rotate-[15deg] mx-3" />
			<DashboardProject />
		</div>
	);
}
