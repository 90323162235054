import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { registerWithEmailAndPassword } from '../services/auth.service';
import { useAuth } from '@/hooks/useAuth';

import * as z from 'zod';
import { API_URL } from '@/config';
import { handleErrorMessage } from '@/utils/notifications';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

const userAuthSchema = z.object({
	name: z.string(),
	email: z.string().email(),
	password: z.string().min(8),
});
type FormData = z.infer<typeof userAuthSchema>;

export const useRegister = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		resolver: zodResolver(userAuthSchema),
	});
	const { login } = useAuth();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isDiscordLoading, setIsDiscordLoading] = useState(false);
	const [isGoogleLoading, setIsGoogleLoading] = useState(false);

	async function onSubmit(data: FormData) {
		setIsLoading(true);
		try {
			const user = await registerWithEmailAndPassword({
				name: data.name,
				email: data.email.toLowerCase(),
				password: data.password,
			});
			analytics.track(TrackingEvents.Signup, {
				loginMethod: 'email',
				email: data.email,
			});
			analytics.register(user?._id, {});
			await login(data.email, data.password);
			setIsLoading(false);
		} catch (err) {
			console.log(err);
			setIsLoading(false);
			handleErrorMessage(err);
		}
	}

	async function loginWithDiscord() {
		setIsDiscordLoading(true);
		window.location.href = `${API_URL}/api/v1/auth/discord`;
	}

	async function loginWithGoogle() {
		setIsGoogleLoading(true);
		window.location.href = `${API_URL}/api/v1/auth/google`;
	}

	return {
		handleSubmit,
		register,
		errors,
		isLoading,
		onSubmit,
		loginWithDiscord,
		loginWithGoogle,
		isDiscordLoading,
		isGoogleLoading,
	};
};
