import { useActiveComboboxStore } from '@udecode/plate-combobox';
import CampaignMetricsCard from './CampaignMetricsCard';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import { useMemo } from 'react';
import moment from 'moment';

const CampaignMetrics = () => {
	const { campaign } = useCampaignReport();

	const metrics = useMemo(() => {
		if (!campaign) return [];
		const totalViewsToDate = campaign?.timeSeriesMetrics?.pageViewUsers?.find(
			(i) => {
				return (
					moment(new Date(i.timestamp)).format('YYYY-MM-DD') ===
					moment(new Date()).format('YYYY-MM-DD')
				);
			},
		)?.count;
		return [
			{
				title: 'Page Views',
				icon: 'rocket',
				value: campaign?.pageViewUsersCount,
				subtitle: totalViewsToDate
					? `Total views today: ${totalViewsToDate}`
					: '',
			},
			{
				title: 'Initiations',
				icon: 'rocket',
				value: campaign?.initiatedUsersCount,
				subtitle:
					campaign?.pageViewUsersCount && campaign?.initiatedUsersCount
						? `Bounce rate is ${Number(100 * ((campaign.pageViewUsersCount - campaign.initiatedUsersCount) / campaign.pageViewUsersCount)).toFixed(1)}%`
						: 'Bounce rate is 0.0%',
			},
			{
				title: 'Completions',
				icon: 'rocket',
				value: campaign?.completedUsersCount,
				subtitle:
					campaign?.initiatedUsersCount && campaign?.completedUsersCount
						? `Completion rate is ${Number(100 * (campaign.completedUsersCount / campaign.initiatedUsersCount)).toFixed(2)}%`
						: 'Completion rate is 0.00%',
			},
		];
	}, [campaign]);

	return (
		<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mt-7">
			{metrics.map((i) => (
				<CampaignMetricsCard {...i} key={i.title} />
			))}
		</div>
	);
};

export default CampaignMetrics;
