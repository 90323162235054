import { useAuth } from '@/hooks/useAuth';
import { queryClient } from '@/lib/react-query';
import { useState } from 'react';
import { getTelegramIntegration } from '../services/integrations.service';
import { toast } from 'sonner';
import { handleErrorMessage } from '@/utils/notifications';
import { TELEGRAM_BOT_USERNAME } from '@/config';

export const useTelegramIntegration = (setOpen) => {
	const { user } = useAuth();
	const [step, setStep] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	const handleNext = async () => {
		try {
			setIsLoading(true);
			const integration = await getTelegramIntegration();
			if (integration) {
				queryClient.invalidateQueries({
					queryKey: ['integrations'],
				});
				queryClient.invalidateQueries({
					queryKey: ['user'],
				});
				toast.success(
					'Telegram verified successfully, you can now verify Telegram based Tasks in your Quests',
				);
				setStep(2);
				setIsLoading(false);
				setOpen(false);
			} else {
				setIsLoading(false);
				toast.error(
					'Telegram integration not found, please follow the steps and try again! In case any issue please contact us using Intercom',
				);
			}
		} catch (err) {
			setIsLoading(false);
			handleErrorMessage(err);
		}
	};

	const instructions = [
		{
			title: 'Add Intract Telegram Bot',
			description: `Click the link or search for @${TELEGRAM_BOT_USERNAME} in Telegram to add the bot to your group.`,
		},
		{
			title: 'Give Bot Permissions',
			description: `In your group, click on the group name and select "Group Info". In top-right, select “Edit” and navigate to “Administrators” options. Click "Add Admin" and search for the ${TELEGRAM_BOT_USERNAME} bot. Once you've added the bot as an admin, you can proceed to the next step.`,
		},
		{
			title: 'Connect the Bot to Your Group',
			description:
				'Copy the command and unique code from the Intract dashboard and paste it into your Telegram group. The command looks like this:',
			code: `/connect_intract ${user?.enterpriseId}`,
		},
	];

	return {
		handleNext,
		isLoading,
		step,
		instructions,
	};
};
