import { Button } from '@/components/ui/button';
import { useState } from 'react';
import { IAdminCampaigns } from '../../types/admin.types';
import { Sheet, SheetContent } from '@/components/ui/sheet';

const CampaignDetailsDialog = ({
	open,
	setOpen,
}: {
	open: boolean;
	setOpen: any;
	campaign: IAdminCampaigns;
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [details, setDetails] = useState({
		status: '',
		message: '',
	});

	const updateStatus = async () => {
		try {
			setIsLoading(true);
			//
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			console.log(err);
		}
	};

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetContent className="min-w-[550px] p-0 overflow-y-auto pb-10">
				<div className="border-b pb-3">
					<div>Update Enterprise Approval Status</div>
				</div>
				<div
					className={`justify-between space-x-2 border-t pt-3 flex sm:justify-between `}
				>
					<div>
						<div></div>
					</div>
					<div className="flex gap-2">
						<Button variant="ghost" onClick={() => setOpen(false)}>
							Cancel
						</Button>
						<Button onClick={() => updateStatus()} disabled={isLoading}>
							Update
						</Button>
					</div>
				</div>
			</SheetContent>
		</Sheet>
	);
};

export default CampaignDetailsDialog;
