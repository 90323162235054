import { ColumnDef } from '@tanstack/react-table';
import { Avatar } from '@/components/ui/avatar';
import { AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { truncateString } from '@/utils/parsers';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { IQuestPOWSubmissions } from '@/features/campaigns/types';
import { Checkbox } from '@/components/ui/checkbox';
import dayjs from 'dayjs';
import StatusBadge from '@/components/element/badges/StatusBadge';

export const submissionColumns = (selectedRow): ColumnDef<IQuestPOWSubmissions>[] =>
	[
		{
			id: 'select',
			header: ({ table }) => (
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						!!(table.getIsSomePageRowsSelected() && 'indeterminate')
					}
					onCheckedChange={(value) =>
						table.toggleAllPageRowsSelected(!!value)
					}
					aria-label="Select all"
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => row.toggleSelected(!!value)}
					aria-label="Select row"
					onClick={(e) => {
						e.stopPropagation();
					}}
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Name" className="" />
			),
			cell: ({ row }) => (
				<div className="flex items-center ">
					<Avatar className="h-7 w-7 me-2">
						<AvatarImage src={row.original.avatar} alt="@intract" />
						<AvatarFallback name={row.original.avatar} />
					</Avatar>
					{truncateString(row.original.name, 20)}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'status',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Status" className="" />
			),
			cell: ({ row }) => (
				<div className="flex items-center ">
					<StatusBadge status={row.original.status} />
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'createdAt',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Submitted at"
					className=" max-w-[200px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center ">
					{dayjs(row.original.createdAt).fromNow()}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
	].filter((i) => i);
