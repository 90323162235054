import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Dropzone } from './Dropzone';
import ImageControl from '../images/ImageControl';
import { cn } from '@/lib/utils';

const DropzoneWithReposition = ({
	file,
	clearSelection,
	setFile,
	fileLink,
	description,
	label,
	cta,
	aspectRatio,
	className,
	customHeight,
	required,
	error,
	errorText,
	allowedFiles,
	onImageReposition,
	maxSize,
	bannerPosition,
	lockBodyScroll = true,
	dropzoneClass,
}: {
	file: File | null;
	clearSelection: () => void;
	setFile: (file: File | File[], fileLink: string) => void;
	fileLink: string;
	description?: string;
	label?: string;
	cta: string;
	aspectRatio: number;
	className?: string;
	customHeight?: string;
	required?: boolean;
	error?: string | boolean;
	errorText?: string;
	allowedFiles?: string[];
	onImageReposition?: ({ x, y }: { x: number; y: number }) => void;
	maxSize?: number;
	bannerPosition?: {
		x: number;
		y: number;
	};
	lockBodyScroll?: boolean;
	dropzoneClass?: string;
}) => {
	return (
		<div className={className}>
			{label && (
				<div className="flex mb-2 justify-between items-center">
					<div>
						<Label className="block mb-1">
							{label}
							{required ? (
								<span className="text-red-400 ms-1">*</span>
							) : null}
						</Label>

						{description && (
							<p className="text-xs text-muted-foreground ">
								{description}
							</p>
						)}
					</div>
					{(file || fileLink) && (
						<Button
							onClick={() => clearSelection()}
							size="icon"
							variant="outline"
						>
							<i className="bi-archive "></i>
						</Button>
					)}
				</div>
			)}
			{!fileLink && (
				<Dropzone
					className={cn(
						'flex h-[150px] flex-col w-full cursor-pointer items-center justify-center rounded-md dark:bg-slate-950 border-dashed border-2 text-sm',
						error ? 'border-destructive' : '',
						dropzoneClass ? dropzoneClass : 'bg-white',
					)}
					onFileChange={(file: File | File[], fileLink: string) => {
						setFile(file, fileLink);
					}}
					height={customHeight}
					aspectRatio={aspectRatio * 2}
					allowedFiles={allowedFiles}
					maxSize={maxSize}
				>
					<div>{cta}</div>
					<div className="text-xs text-muted-foreground">
						{description}
					</div>
				</Dropzone>
			)}
			{fileLink && (
				<ImageControl
					backgroundImage={fileLink}
					aspectRatio={aspectRatio}
					error={error}
					onImageReposition={onImageReposition}
					imagePosition={bannerPosition}
					lockBodyScroll={lockBodyScroll}
				/>
			)}
			{error ? (
				<p className="text-xs text-destructive mt-1">{errorText || error}</p>
			) : null}
		</div>
	);
};

export default DropzoneWithReposition;
