import React from 'react';
import { aboutPage } from '../../content/about.content';
import { Link } from 'react-router-dom';
import LandingContainer from '../ui/LandingContainer';

const IntractCareers = () => {
	return (
		<LandingContainer className=" pt-14 md:pt-16 lg:pt-32 pb-14 md:pb-16 lg:pb-32">
			<div className="relative  space-y-16 md:space-y-32">
				<Link
					className=" group relative flex h-full flex-col justify-between gap-6 overflow-hidden rounded-xl px-6 py-14 md:pt-20 lg:min-h-[567px] lg:pt-[167px]"
					to="/careers"
				>
					<img
						alt="Background Styles"
						loading="lazy"
						width={2560}
						height={1135}
						decoding="async"
						data-nimg={1}
						className="transition-opacity absolute inset-0 h-full w-full opacity-100"
						style={{ color: 'transparent' }}
						src="https://canopywp.wpengine.com/wp-content/uploads/2024/01/Background-Styles.png"
					/>
					<div className="flex w-full flex-col relative z-10 flex-1">
						<div className="text-card items-center text-center justify-center md:items-center md:text-center md:justify-center">
							<div className="flex flex-col items-center text-center justify-center md:items-center md:text-center md:justify-center">
								<div className="flex flex-col space-y-4 md:space-y-8 items-center text-center justify-center md:items-center md:text-center md:justify-center">
									<div className="rounded-full border px-3 pb-1.5 pt-[0.5rem] font-alliance text-[10px] font-normal uppercase leading-none tracking-[1px] self-center text-black border-white/10 text-black! bg-black/5">
										{aboutPage.careers.badge}
									</div>
									<div className="relative">
										<h2 className="w-full transition-colors text-7xl md:w-full text-black">
											{aboutPage.careers.title}
										</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex w-full flex-col relative z-10">
						<div className="text-card items-center text-center justify-center md:items-center md:text-center md:justify-center">
							<div className="flex flex-col items-center text-center justify-center md:items-center md:text-center md:justify-center">
								<div className="flex flex-col space-y-4 md:space-y-8 items-center text-center justify-center md:items-center md:text-center md:justify-center">
									<div className="text-lg transition-colors text-black w-full md:max-w-lg">
										<p>{aboutPage.careers.description}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="absolute right-3 top-5 flex size-10 shrink-0 items-center justify-center rounded-full border border-black/10 bg-black/5 text-black transition-colors group-hover:bg-black/20 group-hover:text-white">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width={18}
							height={19}
							fill="none"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="1.5"
								d="m4.346 14.127 9-9M6.033 5.127h7.313v7.312"
							/>
						</svg>
					</div>
				</Link>
			</div>
		</LandingContainer>
	);
};

export default IntractCareers;
