import PageHeader from '@/components/layout/PageHeader';
import { StartTourCard } from '../components/getting-started/StartTourCard';
import { ProductDemo } from '../components/getting-started/ProductDemo';
import { ProductGuides } from '../components/getting-started/ProductGuides';
import { Checklist } from '../components/getting-started/Checklist';

const GettingStartedPage = () => {
	return (
		<div className="">
			<PageHeader
				title="Set up your Workplace"
				description="Complete the following setup tasks to get started."
			/>
			<div className="grid grid-cols-12 mt-10 gap-10">
				<div className="col-span-7 2xl:col-span-6">
					<Checklist />
				</div>
				<div className="2xl:col-span-2  xl:hidden"></div>
				<div className="2xl:col-span-3 xl:col-span-4 gap-6 flex flex-col">
					<StartTourCard />
					<ProductDemo />
					<ProductGuides />
				</div>
			</div>
		</div>
	);
};

export default GettingStartedPage;
