export const customersContent = {
	hero: {
		title: 'Check our Top **Projects on Intract**',
		description:
			'Join 35,061 customers who use our link management infrastructure to take their marketing efforts to the next level.',
		titleGradient: 'blue',
	},
	projects: [
		{
			link: {
				label: 'analy.se',
				link: 'analy.se',
			},
			logo: 'https://dub.co/_static/clients/analyse.svg',
			caseStudy: {
				description: `Dub's link infrastructure & analytics has helped us gain valuable insights into the link-sharing use case of Ray.so.`,
				link: '/case-studies/analyse',
			},
		},
		{
			link: {
				label: 'analy.se',
				link: 'analy.se',
			},
			logo: 'https://dub.co/_static/clients/analyse.svg',
		},
		{
			link: {
				label: 'analy.se',
				link: 'analy.se',
			},
			logo: 'https://dub.co/_static/clients/analyse.svg',
			caseStudy: {
				description: `Dub's link infrastructure & analytics has helped us gain valuable insights into the link-sharing use case of Ray.so.`,
				link: '/case-studies/analyse',
			},
		},
		{
			link: {
				label: 'analy.se',
				link: 'analy.se',
			},
			logo: 'https://dub.co/_static/clients/analyse.svg',
		},
		{
			link: {
				label: 'analy.se',
				link: 'analy.se',
			},
			logo: 'https://dub.co/_static/clients/analyse.svg',
			caseStudy: {
				description: `Dub's link infrastructure & analytics has helped us gain valuable insights into the link-sharing use case of Ray.so.`,
				link: '/case-studies/analyse',
			},
		},
		{
			link: {
				label: 'analy.se',
				link: 'analy.se',
			},
			logo: 'https://dub.co/_static/clients/analyse.svg',
		},
	],
};
