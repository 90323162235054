import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useCreateCampaignTasks } from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import { ICreateTasks } from '@/features/campaigns/types';
import { MoreVertical } from 'lucide-react';

const TaskOptions = ({
	featureApplicability,
	taskId,
	isRequiredTask,
	appliedFeatures,
	learningContent,
}: ICreateTasks) => {
	const { moveTask, deleteTask, editTask } = useCreateCampaignTasks();
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" size="icon">
					<MoreVertical className="h-4 w-4" />
					<span className="sr-only">More</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end">
				<DropdownMenuItem
					onClick={() => editTask(taskId, 'OPTIONAL', !isRequiredTask)}
				>
					<Checkbox checked={!isRequiredTask} className="me-2" />
					Optional
				</DropdownMenuItem>
				<DropdownMenuItem
					onClick={() =>
						editTask(
							taskId,
							'LEARNING_CONTENT_TOGGLE',
							!learningContent.enabled,
						)
					}
				>
					<Checkbox checked={learningContent.enabled} className="me-2" />
					Learning Content
				</DropdownMenuItem>
				<DropdownMenuSeparator />
				{featureApplicability?.maxFailedCount && (
					<DropdownMenuItem
						onClick={() =>
							editTask(
								taskId,
								'MAX_RETRIES_TOGGLE',
								!appliedFeatures?.maxFailedCount?.enabled,
							)
						}
					>
						{appliedFeatures.maxFailedCount?.enabled ? (
							<i className="bi bi-record-circle-fill text-violet-500 me-2"></i>
						) : (
							<i className="me-2 bi-circle"></i>
						)}
						Max Attemptes
					</DropdownMenuItem>
				)}
				{featureApplicability?.recurrence && (
					<DropdownMenuItem
						onClick={() =>
							editTask(
								taskId,
								'RECURRING_TOGGLE',
								!appliedFeatures.recurrance?.enabled,
							)
						}
					>
						{appliedFeatures.recurrance?.enabled ? (
							<i className="bi bi-record-circle-fill text-violet-500 me-2"></i>
						) : (
							<i className="me-2 bi-circle"></i>
						)}
						Recurrance
					</DropdownMenuItem>
				)}
				<DropdownMenuSeparator />
				<DropdownMenuItem onClick={() => moveTask(taskId, 'up')}>
					<i className="bi-arrow-up-circle me-2" />
					Move Up
				</DropdownMenuItem>
				<DropdownMenuItem onClick={() => moveTask(taskId, 'down')}>
					<i className="bi-arrow-down-circle me-2" />
					Move Down
				</DropdownMenuItem>
				<DropdownMenuSeparator />
				<DropdownMenuItem onClick={() => deleteTask(taskId)}>
					<i className="bi-trash me-2" />
					Delete
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default TaskOptions;
