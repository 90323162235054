export const dashboardNav: {
	title?: string;
	nav: {
		icon: string;
		href: string;
		label: string;
		isActive?: boolean;
		options?: {
			icon: string;
			href: string;
			label: string;
			isActive?: boolean;
		}[];
		badge?: {
			variant: 'default' | 'secondary' | 'destructive' | 'outline';
			text: string;
		};
	}[];
}[] = [
	{
		title: '',
		nav: [
			{
				icon: 'chevron-double-right',
				href: '/app/getting-started',
				label: 'Getting Started',
			},
			// {
			// 	icon: 'house',
			// 	href: '/app/home',
			// 	label: 'Home',
			// },
			{
				icon: 'people',
				href: '/app/leaderboard',
				label: 'Leaderboard',
			},
		],
	},
	{
		title: 'Community Hub',
		nav: [
			{
				icon: 'compass',
				href: '/app/campaign/quests',
				label: 'Quests',
			},
			{
				icon: 'heart',
				href: '/app/campaign/loyalty',
				label: 'Loyalty Program',
			},
			{
				icon: 'calendar2-event',
				href: '/app/campaign/events',
				label: 'Events',
			},
		],
	},
	{
		title: 'Tools',
		nav: [
			{
				icon: 'plug',
				href: '/app/tools/task-apis',
				label: 'Task APIs',
			},
			{
				icon: 'cloud-fog2',
				href: '/app/tools/dave/actions',
				label: 'No Code Verification',
			},
			{
				icon: 'code-square',
				href: '/app/tools/nfts',
				label: 'NFT Contracts',
			},
			{
				icon: 'rocket',
				href: '/app/boosters',
				label: 'Participation Boosters',
				options: [
					{
						icon: 'rocket',
						href: '/app/boosters/kol-marketplace',
						label: 'KOL Marketplace',
					},
					{
						icon: 'rocket',
						href: '/app/boosters/partnerships',
						label: 'Partnerships',
					},
				],
			},
		],
	},
	{
		title: '',
		nav: [
			{
				icon: 'palette',
				href: '/app/customise-hub/profile',
				label: 'Customise your Hub',
			},
			{
				icon: 'bounding-box',
				href: '/app/integrations',
				label: 'Integrations',
			},
		],
	},
];
