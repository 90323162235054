import { DataTable } from '@/components/element/data-table/DataTable';
import { useCampaignWinners } from '@/features/campaigns/hooks/report/useCampaignWinners';
import { winnersColumns } from '../components/approvals/WinnersColumns';

const Winners = () => {
	const {
		isLoading,
		winners,
		totalCount,
		pagination,
		setPagination,
		setSorting,
		sorting,
	} = useCampaignWinners();

	const cols = winnersColumns([]);
	return (
		<div>
			<div className="mt-5">
				<DataTable
					onRowClick={() => {}}
					data={winners ?? []}
					columns={cols}
					totalCount={totalCount}
					isLoading={isLoading}
					isServerSide={true}
					pagination={pagination}
					setPagination={setPagination}
					sorting={sorting}
					selectedRow={null}
					setSorting={setSorting}
				/>
			</div>
		</div>
	);
};

export default Winners;
