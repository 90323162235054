import InputSwitch from '@/components/element/inputs/InputSwitch';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogDescription,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import Spinner from '@/components/element/loading/Spinner';
import { useCampaignsSettings } from '@/features/campaigns/hooks/report/useCampaignsSettings';
import InputSelect2 from '@/components/element/inputs/InputSelect2';
import { useQuery } from '@tanstack/react-query';
import { getDiscordGuildChannels } from '@/features/integrations/services/integrations.service';
import { useMemo } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import InputSelect from '@/components/element/inputs/InputSelect';
import IntegrateDiscordDialog from './IntegrateDiscordDialog';

interface IDiscordChannels {
	id: string;
	name: string;
}

const CmapaignSettingsDialog = ({ open, setOpen }) => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const { data } = useQuery<IDiscordChannels[]>({
		queryKey: ['discord-channels'],
		queryFn: getDiscordGuildChannels,
		enabled: user?.integrationStatus?.discord,
	});

	const channels = useMemo(() => {
		if (!data || data.length === 0) return [];
		return data.map((i) => ({
			label: i.name,
			value: i.id,
		}));
	}, [data]);

	const { isLoading, updateSettings, settings, setSettings } =
		useCampaignsSettings(setOpen);

	if (!user.integrationStatus.discord) {
		return <IntegrateDiscordDialog open={open} setOpen={setOpen} />;
	}
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[550px] px-0 pb-0">
				<DialogHeader className="border-b pb-3 px-5">
					<DialogTitle>Settings</DialogTitle>
					<DialogDescription>
						Configure your quests settings.
					</DialogDescription>
				</DialogHeader>
				<div className="space-y-10 mt-4 px-5 mb-10">
					{!user?.integrationStatus?.discord && (
						<div className=" px-5 py-4 rounded-lg bg-slate-100 flex items-center justify-between">
							<div>
								<div>
									<div className="text-sm font-semibold">
										Connect Discord
									</div>
								</div>
								<div className="text-xs"></div>
							</div>
							<Button
								className=""
								variant="outline"
								onClick={() => navigate('/app/integrations/discord')}
							>
								<i className="bi-discord text-discord me-1"></i>
								Connect Discord
							</Button>
						</div>
					)}
					{user?.integrationStatus?.discord && (
						<InputSwitch
							value={settings?.isDiscordAnnouncement}
							setValue={(value) =>
								setSettings((p) => ({
									...p,
									isDiscordAnnouncement: value,
								}))
							}
							label="Send Discord Announcements"
							subLabel="To enable automatic notifications of live campaigns on your discord server, please set up your announcement channel."
							subLabelClassName="text-muted-foreground font-normal mt-2"
							disabled={!user?.integrationStatus?.discord}
						/>
					)}
					{settings?.isDiscordAnnouncement && (
						<InputSelect
							value={settings.announcementChannel}
							setValue={(value) =>
								setSettings((p) => ({
									...p,
									announcementChannel: value,
								}))
							}
							label="Announcement Channel"
							options={channels}
						/>
					)}
				</div>
				<DialogFooter className="border-t px-5 pt-3 pb-3">
					<Button onClick={() => updateSettings()} disabled={isLoading}>
						{isLoading && <Spinner className="me-2" />}
						<span>Save Changes</span>
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default CmapaignSettingsDialog;
