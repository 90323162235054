export const homepageContent = {
	hero: {
		title: 'Build your Community. Only thing that matters.',
		description:
			'Access world’s first bot-less community of 12M+ on-chain users with our quest platform',
		badge: {
			label: 'Intract Raises $15.2M to Fuel Growth Plans in 2024',
			link: '',
		},
		cta: {
			primary: {
				link: '/auth/register',
				label: 'Start Now',
			},
			secondary: {
				link: 'https://intract.io/explore',
				label: 'Explore Trending Projects',
			},
		},
	},
	metrics: [
		{
			metric: '12M+',
			title: 'On-chain users',
		},
		{
			metric: '1.2M+',
			title: 'Daily active users',
		},
		{
			metric: '1,000+',
			title: 'Communities',
		},
	],
	companies: {
		title: 'Giving superpowers to modern teams at world-class companies',
		logos: [
			{
				img: 'https://dub.co/_static/clients/framer.svg',
				name: 'zksync',
			},
			{
				img: 'https://dub.co/_static/clients/tinybird.svg',
				name: 'polygon',
			},
			{
				img: 'https://dub.co/_static/clients/attio.svg',
				name: 'polygon',
			},
			{
				img: 'https://dub.co/_static/clients/appwrite.svg',
				name: 'polygon',
			},
			{
				img: 'https://dub.co/_static/clients/appwrite.svg',
				name: 'polygon',
			},
		],
	},
	features: {
		title: 'Powerful analytics for Web3 and the modern marketer',
		description: `There is a reason we are called World’s No. 1 Questing Platform. We've built a suite of powerful features that gives you marketing superpowers. `,
		featureList: [
			{
				emoji: '🚀',
				title: 'Easy Questing Interface',
			},
			{
				emoji: '🚀',
				title: 'Easy Questing Interface',
			},
			{
				emoji: '🚀',
				title: 'Easy Questing Interface',
			},
			{
				emoji: '🚀',
				title: 'Easy Questing Interface',
			},
			{
				emoji: '🚀',
				title: 'Easy Questing Interface',
			},
			{
				emoji: '🚀',
				title: 'Easy Questing Interface',
			},
			{
				emoji: '🚀',
				title: 'Easy Questing Interface',
			},
			{
				emoji: '🚀',
				title: 'Easy Questing Interface',
			},
			{
				emoji: '🚀',
				title: 'Easy Questing Interface',
			},
		],
		coreFeatures: [
			{
				id: '1',
				icon: 'people',
				title: 'Intitutive Quest Interface',
				description: `Dub.co provides powerful analytics for your links, including geolocation, device, browser, and referrer information.`,
				cta: {
					link: '/features',
					label: 'Learn more',
				},
				image: 'https://assets.dub.co/features/powerful-analytics.jpg',
				video: 'https://assets.dub.co/features/powerful-analytics.mp4',
			},
			{
				id: '2',
				icon: 'people',
				title: 'No Code On Chain Task Verification',
				description: `Dub.co provides powerful analytics for your links, including geolocation, device, browser, and referrer information.`,
				cta: {
					link: '/features',
					label: 'Learn more',
				},
				image: 'https://assets.dub.co/features/powerful-analytics1.jpg',
				video: 'https://assets.dub.co/features/powerful-analytics.mp4',
			},
			{
				id: '3',
				icon: 'people',
				title: 'Analytics that matter',
				description: `Dub.co provides powerful analytics for your links, including geolocation, device, browser, and referrer information.`,
				cta: {
					link: '/features',
					label: 'Learn more',
				},
				image: 'https://assets.dub.co/features/powerful-analytics.jpg',
				video: 'https://assets.dub.co/features/powerful-analytics.mp4',
			},
			{
				id: '4',
				icon: 'people',
				title: 'Unified Point System for Quests and Rewards',
				description: `Dub.co provides powerful analytics for your links, including geolocation, device, browser, and referrer information.`,
				cta: {
					link: '/features',
					label: 'Learn more',
				},
				image: 'https://assets.dub.co/features/powerful-analytics.jpg',
				video: 'https://assets.dub.co/features/powerful-analytics.mp4',
			},
			{
				id: '5',
				icon: 'people',
				title: 'For Pre-Token and Airdrop Ecosystems',
				description: `Dub.co provides powerful analytics for your links, including geolocation, device, browser, and referrer information.`,
				cta: {
					link: '/features',
					label: 'Learn more',
				},
				image: 'https://assets.dub.co/author/steventey.jpg',
				video: 'https://assets.dub.co/features/powerful-analytics.mp4',
				author: 'https://assets.dub.co/author/steventey.jpg',
			},
		],
	},
	valueProps: [
		{
			title: 'Find KOLs and Partners',
			description:
				'You can control board privacy, allowing you to control who sees the feedback.',
			image: 'https://supahub.com/assets/feedback-boards/feedback-board-privacy-supahub.png',
		},
		{
			title: 'Own your Data',
			description:
				'You can control board privacy, allowing you to control who sees the feedback.',
			image: 'https://supahub.com/assets/feedback-boards/feedback-board-privacy-supahub.png',
		},
		{
			title: 'Set up Reward Store',
			description:
				'You can control board privacy, allowing you to control who sees the feedback.',
			image: 'https://supahub.com/assets/feedback-boards/feedback-board-privacy-supahub.png',
		},
	],
	testimonials: {
		title: 'What our **customers are saying**',
		description:
			'Here’s how our 10,500+ partners use Intract to take their communities to the next level.',
		titleGradient: 'pink',
	},
};

export const testimonials = [
	{
		companyLogo: 'https://assets.dub.co/testimonials/vercel.svg',
		testimonial: `I honestly like Intract a lot better than the other quest platforms, the UX and flow are a lot more intuitive. Working with them is just another level. Less chit chat and more real delivery. The team rocks. 🔥`,
		avatar: 'https://assets.dub.co/testimonials/guillermo-rauch.jpeg',
		name: 'Guillermo Rauch',
		profile: 'Founding Member, Linea',
		backgroundVariant: 'gradient-purple',
		isDark: false,
		link: 'https://vercel.com',
	},
	{
		companyLogo: 'https://assets.dub.co/testimonials/vercel.svg',
		testimonial: `Intract powered our first campaign and played a key role in onboarding tons of early adopters onto our platform 🚀 I love their onchain verifications which eliminate any manual processed involved in running a campaign. The team is super helpful in setting up campaigns ✨`,
		avatar: 'https://assets.dub.co/testimonials/guillermo-rauch.jpeg',
		name: 'Guillermo Rauch',
		profile: 'Founder, Crypto x AI Memecoin',
		link: 'https://vercel.com',
		backgroundVariant: 'white',
		isDark: true,
	},
	{
		companyLogo: 'https://assets.dub.co/testimonials/vercel.svg',
		testimonial: `Intract stands as a key partner in our community engagement initiatives. We are thrilled by their innovative ideas and execution 🚀🚀🚀
			Collaborating with them has been a rewarding experience 🔥`,
		avatar: 'https://assets.dub.co/testimonials/guillermo-rauch.jpeg',
		name: 'Guillermo Rauch',
		profile: 'CEO, Vercel',
		link: 'https://vercel.com',
		backgroundVariant: 'white',
		isDark: true,
	},
	{
		companyLogo: 'https://assets.dub.co/testimonials/raycast.svg',
		testimonial: `Intract team was super helpful while we were going through our first campaign. It got a lot of real attention and helped put us on the map. We’ll definitely launch another Intract campaign soon. 💎`,
		avatar: 'https://assets.dub.co/testimonials/guillermo-rauch.jpeg',
		name: 'Guillermo Rauch',
		profile: 'CEO, Vercel',
		backgroundVariant: 'white',
		link: 'https://vercel.com',
		isDark: true,
	},

	{
		companyLogo: 'https://assets.dub.co/testimonials/vercel.svg',
		testimonial: `The Intract team has been incredibly helpful at every step of the process. Any questions we had and any modifications we had to make were swiftly answered and help was provided almost immediately. The team also stepped up and sent speakers over to our Twitter spaces who have been incredibly insightful and their input is highly valued. 🚀🚀🚀`,
		avatar: 'https://assets.dub.co/testimonials/guillermo-rauch.jpeg',
		name: 'Guillermo Rauch',
		profile: 'CEO, Vercel',
		link: 'https://vercel.com',
		backgroundVariant: 'white',
		isDark: true,
	},
	{
		companyLogo: 'https://assets.dub.co/testimonials/vercel.svg',
		testimonial: `Our partnership with Intract has been exceptional. 💎 We've launched over 6 successful campaigns with them at Rango, attracting numerous users on Twitter, Discord, and for cross-chain swapping. Looking forward to more successes with Intract! 🔥`,
		avatar: 'https://assets.dub.co/testimonials/guillermo-rauch.jpeg',
		name: 'Guillermo Rauch',
		profile: 'CEO, Vercel',
		link: 'https://vercel.com',
		backgroundVariant: 'white',
		isDark: true,
	},
	{
		companyLogo: 'https://assets.dub.co/testimonials/vercel.svg',
		testimonial: `Experience with Intract has been quite great. The entire experience was smooth and straight-forward. I was really surprised by the advanced analytics dashboard. The ability to check all the stats was on point. Everything that was promised was delivered. 🔥`,
		avatar: 'https://assets.dub.co/testimonials/guillermo-rauch.jpeg',
		name: 'Guillermo Rauch',
		profile: 'CEO, Vercel',
		link: 'https://vercel.com',
		backgroundVariant: 'gradient-blue',
		isDark: false,
	},
	{
		companyLogo: 'https://assets.dub.co/testimonials/vercel.svg',
		testimonial: `We had the Intract team helping us a lot with the operational and technical part. 💎 I’d like to highlight first the support we’re getting is exceptional. Samriti from Intract is super tech-savvy, helpful and kind. Even minor issues, she’s always there to help me. Second thing I want to talk about is the super easy user experience. For both users and project creators. Last point, the engagement we got from Intract was organic and not botted. This is a major edge Intract offers. 🚀`,
		avatar: 'https://assets.dub.co/testimonials/guillermo-rauch.jpeg',
		name: 'Guillermo Rauch',
		profile: 'CEO, Vercel',
		link: 'https://vercel.com',
		backgroundVariant: 'white',
		isDark: true,
	},
];
