import {
	AvatarGroup,
	Avatar,
	AvatarGroupList,
	AvatarImage,
	AvatarFallback,
	AvatarOverflowIndicator,
} from '@/components/ui/avatar-group';
import { cn } from '@/lib/utils';

const AvatarGroupSimple = ({
	images,
	className,
}: {
	images: string[];
	className?: string;
}) => {
	return (
		<AvatarGroup limit={4} className="-space-x-3">
			<AvatarGroupList count={images.length}>
				{images.map((i, index) => (
					<Avatar
						key={index}
						className={cn('h-6 w-6 ms-0 border ', className)}
					>
						<AvatarImage src={i} alt="@shadcn" sizes="sm" className="" />
						<AvatarFallback>CN</AvatarFallback>
					</Avatar>
				))}
			</AvatarGroupList>
			<AvatarOverflowIndicator className="h-6 w-6 text-[10px] border " />
		</AvatarGroup>
	);
};

export default AvatarGroupSimple;
