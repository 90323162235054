import { Card, CardContent } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import { updateCampaignMetadata } from '@/features/campaigns/services/campaigns.service';
import { ICampaign } from '@/features/campaigns/types';
import { queryClient } from '@/lib/react-query';
import { cn } from '@/lib/utils';
import { handleErrorMessage } from '@/utils/notifications';
import { useMemo } from 'react';

const Checklist = () => {
	const { campaign, id } = useCampaignReport();

	const checklist = useMemo(
		() => [
			{
				title: 'Announce on Your Discord',
				description:
					'Kick off the excitement by sharing your quest launch on your Discord server',
				checked:
					campaign?.metadata?.checklist?.find(
						(item: any) => item.key === 'discord',
					)?.checked ?? false,
				key: 'discord',
			},
			{
				title: 'Craft an Official Tweet',
				description:
					'Create a buzz by tweeting about your quest. Tag @IntractCampaign for added visibility and share the tweet with us for engagement.',
				checked:
					campaign?.metadata?.checklist?.find(
						(item: any) => item.key === 'tweet',
					)?.checked ?? false,
				key: 'tweet',
			},
			{
				title: 'Spread the Word on Telegram',
				description: `Amplify your quest's reach by sharing it in your Telegram groups.`,
				checked:
					campaign?.metadata?.checklist?.find(
						(item: any) => item.key === 'telegram',
					)?.checked ?? false,
				key: 'telegram',
			},
			{
				title: `Contribute a Blog Post.`,
				description: `Enhance SEO and awareness by writing a blog post for our collaborative series`,
				checked:
					campaign?.metadata?.checklist?.find(
						(item: any) => item.key === 'blog',
					)?.checked ?? false,
				key: 'blog',
			},
			{
				title: `Engage with KOLs`,
				description: `Boost your quest's impact by reaching out to Key Opinion Leaders in the space.`,
				checked:
					campaign?.metadata?.checklist?.find(
						(item: any) => item.key === 'kol',
					)?.checked ?? false,
				key: 'kol',
			},
			{
				title: `Utilize Intract's Promotional Tools`,
				description: `Feature in Our Email Newsletter: Gain an audience through inclusion in our targeted email blasts.`,
				checked:
					campaign?.metadata?.checklist?.find(
						(item: any) => item.key === 'newsletter',
					)?.checked ?? false,
				key: 'newsletter',
			},
		],
		[campaign.metadata],
	);

	const handleCheck = async (key: string, value: boolean) => {
		try {
			const data_ = (await queryClient.getQueryData([
				'campaign',
				id,
			])) as ICampaign;
			if (data_) {
				await queryClient.setQueryData(['campaign', id], {
					...data_,
					metadata: {
						...data_.metadata,
						checklist: checklist.map((item: any) => ({
							key: item.key,
							checked: item.key === key ? value : item.checked,
						})),
					},
				});
			}
			await updateCampaignMetadata(id, {
				metadata: {
					checklist: checklist.map((item: any) => ({
						key: item.key,
						checked: item.key === key ? value : item.checked,
					})),
				},
			});
		} catch (err) {
			handleErrorMessage(err);
		}
	};

	return (
		<div className="col-span-6 2xl:col-span-4">
			<Card className="shadow-none">
				<CardContent className="p-4">
					<div className="flex items-center">
						<div>Check List</div>
						<div className="h-5 w-5 ms-2 text-xs border rounded-full bg-muted-foreground text-background flex items-center justify-center">
							4
						</div>
					</div>
					<div className="mt-4">
						{checklist.map((item, index) => (
							<ChecklistItem
								key={index}
								key_={item.key}
								{...item}
								onCheck={(key: string, value: boolean) => {
									handleCheck(key, value);
								}}
							/>
						))}
					</div>
				</CardContent>
			</Card>
		</div>
	);
};

export default Checklist;

const ChecklistItem = ({ title, checked, description, onCheck, key_ }) => {
	return (
		<div
			className={cn(
				'flex items-center px-3 py-2 rounded bg-slate-100 mb-3',
				checked ? ' line-through text-muted-foreground' : '',
			)}
		>
			<Checkbox checked={checked} onCheckedChange={(e) => onCheck(key_, e)} />
			<div className="ms-2">
				<div className="text-sm">{title}</div>
				<div className=" text-xs text-muted-foreground">{description}</div>
			</div>
		</div>
	);
};
