import { Card, CardContent, CardTitle } from '@/components/ui/card';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from '@/components/ui/carousel';
import {
	CampaignRewardCategory,
	ICampaignReward,
	Status,
} from '@/features/campaigns/types';
import { convertToTitleCase, formatNumberWithCommas } from '@/utils/parsers';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Button } from '@/components/ui/button';
import { useCampaignWithdrawFunds } from '@/features/campaigns/hooks/report/useCampaignWithdrawFunds';
import Spinner from '@/components/element/loading/Spinner';

const RewardDetails = () => {
	const { campaign } = useCampaignReport();
	if (campaign.reward.length === 0)
		return (
			<Card className="h-full">
				<CardContent className="flex items-center justify-center h-full px-10 text-center">
					<div className="text-sm text-muted-foreground mt-5">
						No rewards have been set up for this campaign
					</div>
				</CardContent>
			</Card>
		);

	return (
		<Card className="h-full">
			<Carousel className="relative shadow-none border-none">
				<CarouselContent>
					{campaign.reward.map((reward) => (
						<CarouselItem>
							<RewardCard reward={reward}>
								{reward.category ===
									CampaignRewardCategory.Token && (
									<TokenCard
										reward={reward}
										claimDate={new Date(campaign?.claimDate)}
										status={campaign?.status}
										isWithdrawn={
											campaign?.tokenRewardWithdrawal
												?.isWithdrawn
										}
									/>
								)}
								{reward.category === CampaignRewardCategory.Nft && (
									<NFTCard reward={reward} />
								)}
								{reward.category ===
									CampaignRewardCategory.Whitelist && (
									<WhitelistCard reward={reward} />
								)}
								{reward.category ===
									CampaignRewardCategory.DiscordRole && (
									<DiscordCard reward={reward} />
								)}
								{reward.category ===
									CampaignRewardCategory.SecretCode && (
									<SecretCode reward={reward} />
								)}
							</RewardCard>
						</CarouselItem>
					))}
				</CarouselContent>
				{campaign.reward.length > 1 && (
					<div className="absolute top-0 right-0 p-4 flex items-center ">
						<CarouselPrevious className="relative h-7 w-7 me-2" />
						<CarouselNext className="relative h-7 w-7 " />
					</div>
				)}
			</Carousel>
		</Card>
	);
};

export default RewardDetails;

const RewardCard = ({ children, reward }) => {
	return (
		<CardContent className="py-5 flex flex-col h-full">
			<div>
				<CardTitle className="text-sm font-medium">
					💰 Reward Details
				</CardTitle>
			</div>
			<div>
				<div className="flex text-sm  mb-4 mt-5">
					<div className="w-[130px]">
						<div className="text-muted-foreground ">Reward Type</div>
					</div>
					<div className="flex">
						<div className="me-3 text-muted-foreground">
							<i className="bi-arrow-right me-2"></i>
						</div>
						<div className="font-semibold">
							{reward.category === 'NFT'
								? 'NFT'
								: convertToTitleCase(reward.category)}
						</div>
					</div>
				</div>
				<div className="flex text-sm  mb-4 mt-5">
					<div className="w-[130px]">
						<div className="text-muted-foreground ">
							Distribution Type
						</div>
					</div>
					<div className="flex">
						<div className="me-3 text-muted-foreground">
							<i className="bi-arrow-right me-2"></i>
						</div>
						<div className="font-semibold">
							{convertToTitleCase(reward.method)}
						</div>
					</div>
				</div>
				{children}
			</div>
		</CardContent>
	);
};

const NFTCard = ({ reward }: { reward: ICampaignReward }) => {
	return (
		<>
			<div className="flex text-sm  mb-4">
				<div className="w-[130px]">
					<div className="text-muted-foreground ">Winner count</div>
				</div>
				<div className="flex">
					<div className="me-3 text-muted-foreground">
						<i className="bi-arrow-right me-2"></i>
					</div>
					<div className="font-semibold">{reward?.numRewards}</div>
				</div>
			</div>
			<div className="flex text-sm  mb-4">
				<div className="w-[130px]">
					<div className="text-muted-foreground ">NFT</div>
				</div>
				<div className="flex">
					<div className="me-3 text-muted-foreground">
						<i className="bi-arrow-right me-2"></i>
					</div>
					<div className="font-semibold">{reward.nftReward.name}</div>
				</div>
			</div>
		</>
	);
};

const WhitelistCard = ({ reward }: { reward: ICampaignReward }) => {
	return (
		<>
			<div className="flex text-sm  mb-4">
				<div className="w-[130px]">
					<div className="text-muted-foreground ">Winner count</div>
				</div>
				<div className="flex">
					<div className="me-3 text-muted-foreground">
						<i className="bi-arrow-right me-2"></i>
					</div>
					<div className="font-semibold">{reward.numRewards}</div>
				</div>
			</div>
			<div className="flex text-sm  mb-4">
				<div className="w-[130px]">
					<div className="text-muted-foreground ">Name</div>
				</div>
				<div className="flex">
					<div className="me-3 text-muted-foreground">
						<i className="bi-arrow-right me-2"></i>
					</div>
					<div className="font-semibold">
						{reward.whitelistReward.name}
					</div>
				</div>
			</div>
		</>
	);
};

const TokenCard = ({
	reward,
	claimDate,
	status,
	isWithdrawn,
}: {
	reward: ICampaignReward;
	claimDate: Date;
	status: Status;
	isWithdrawn?: boolean;
}) => {
	const { isLoading, claimTokens, isClaimable } = useCampaignWithdrawFunds();
	const totalRewardPool = useMemo(() => {
		if (
			!reward?.tokenReward?.tokenAmountPerUser ||
			!reward?.numRewards ||
			!reward?.tokenReward?.tokenSymbol
		) {
			return 0;
		}
		return `${formatNumberWithCommas(
			+reward?.tokenReward?.tokenAmountPerUser * reward?.numRewards,
		)} ${reward?.tokenReward?.tokenSymbol}`;
	}, [reward]);

	return (
		<div>
			<div className="flex text-sm  mb-4">
				<div className="w-[130px]">
					<div className="text-muted-foreground ">Winner count</div>
				</div>
				<div className="flex">
					<div className="me-3 text-muted-foreground">
						<i className="bi-arrow-right me-2"></i>
					</div>
					<div className="font-semibold">{reward.numRewards}</div>
				</div>
			</div>
			<div className="flex text-sm  mb-4">
				<div className="w-[130px]">
					<div className="text-muted-foreground ">Reward Pool</div>
				</div>
				<div className="flex">
					<div className="me-3 text-muted-foreground">
						<i className="bi-arrow-right me-2"></i>
					</div>
					<div className="font-semibold">{totalRewardPool}</div>
				</div>
			</div>
			<div className="text-xs mt-8">
				{isWithdrawn ? (
					<span>
						<div className="text-muted-foreground">
							You have withdrawn the remaining tokens
						</div>
					</span>
				) : !isClaimable ? (
					<span>
						PS: You can withdraw pending tokens after 30 days of campaign
						completions i.e. {dayjs(claimDate).fromNow()}
					</span>
				) : status === Status.Completed ? (
					<>
						<div>
							<div className="mb-2 text-muted-foreground">
								You can withdraw the remaining amount
							</div>
							<Button onClick={claimTokens} disabled={isLoading}>
								{isLoading && <Spinner className="me-2" />}
								<span>Withdraw Funds</span>
							</Button>
						</div>
					</>
				) : (
					<span>
						You can withdraw your tokens after the campaign is completed
					</span>
				)}
			</div>
		</div>
	);
};

const DiscordCard = ({ reward }: { reward: ICampaignReward }) => {
	return (
		<>
			<div className="flex text-sm  mb-4">
				<div className="w-[130px]">
					<div className="text-muted-foreground ">Winner count</div>
				</div>
				<div className="flex">
					<div className="me-3 text-muted-foreground">
						<i className="bi-arrow-right me-2"></i>
					</div>
					<div className="font-semibold">{reward.numRewards}</div>
				</div>
			</div>
			<div className="flex text-sm  mb-4">
				<div className="w-[130px]">
					<div className="text-muted-foreground ">Role Name</div>
				</div>
				<div className="flex">
					<div className="me-3 text-muted-foreground">
						<i className="bi-arrow-right me-2"></i>
					</div>
					<div className="font-semibold">
						{reward.discordReward.roleName}
					</div>
				</div>
			</div>
		</>
	);
};

const SecretCode = ({ reward }: { reward: ICampaignReward }) => {
	return (
		<>
			<div className="flex text-sm  mb-4">
				<div className="w-[130px]">
					<div className="text-muted-foreground ">Winner count</div>
				</div>
				<div className="flex">
					<div className="me-3 text-muted-foreground">
						<i className="bi-arrow-right me-2"></i>
					</div>
					<div className="font-semibold">{reward.numRewards}</div>
				</div>
			</div>
			<div className="flex text-sm  mb-4">
				<div className="w-[130px]">
					<div className="text-muted-foreground ">Secret Code</div>
					<div className="text-muted-foreground text-xs">
						({reward.codeReward.name})
					</div>
				</div>
				<div className="flex">
					<div className="me-3 text-muted-foreground">
						<i className="bi-arrow-right me-2"></i>
					</div>
					<div className="font-semibold">{reward.codeReward.code}</div>
				</div>
			</div>
		</>
	);
};
