import { Confetti } from '@/components/element/others/Confetti';
import { createContext, useEffect, useState } from 'react';

type Theme = 'dark' | 'light' | 'system';

type ThemeProviderProps = {
	children: React.ReactNode;
	defaultTheme?: Theme;
	storageKey?: string;
};

type ThemeProviderState = {
	theme: Theme;
	setTheme: (theme: Theme) => void;
	celebrate: (duration: number) => void;
};

const initialState: ThemeProviderState = {
	theme: 'system',
	setTheme: () => null,
	celebrate: (duration: number) => null,
};

export const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({
	children,
	defaultTheme = 'light',
	storageKey = 'intract-ui-theme',
	...props
}: ThemeProviderProps) {
	const [showConfetti, setShowConfetti] = useState(false);

	const celebrate = (duration = 5000) => {
		setShowConfetti(true);
		setTimeout(() => {
			setShowConfetti(false);
		}, duration);
	};

	const [theme, setTheme] = useState<Theme>(
		() => (localStorage.getItem(storageKey) as Theme) || defaultTheme,
	);

	useEffect(() => {
		const root = window.document.documentElement;

		root.classList.remove('light', 'dark');

		if (theme === 'system') {
			const systemTheme = window.matchMedia('(prefers-color-scheme: dark)')
				.matches
				? 'dark'
				: 'light';

			root.classList.add(systemTheme);
			return;
		}

		root.classList.add(theme);
	}, [theme]);

	const value = {
		theme,
		setTheme: (theme: Theme) => {
			localStorage.setItem(storageKey, theme);
			setTheme(theme);
		},
		celebrate,
	};

	return (
		<ThemeProviderContext.Provider {...props} value={value}>
			{children}
			{showConfetti && <Confetti />}
		</ThemeProviderContext.Provider>
	);
}
