import { Button } from '@/components/ui/button';
import Spinner from '../loading/Spinner';
import { cn } from '@/lib/utils';
import { useEffect, useRef, useState } from 'react';
import { Media } from '../media/Media';

const NoDataCard = ({
	title,
	description,
	icon,
	btnLabel,
	btnOnClick,
	isLoading,
	className,
	image,
	learnMore,
}: {
	title: string;
	icon?: string;
	description?: string;
	btnLabel?: string;
	btnOnClick?: () => void;
	isLoading?: boolean;
	className?: string;
	image?: string;
	learnMore?: string;
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const [height, setHeight] = useState('auto');

	useEffect(() => {
		const updateHeight = () => {
			const topOffset = ref.current?.getBoundingClientRect().top || 0;
			const calculatedHeight = `calc(100vh - ${topOffset}px - 40px)`;
			setHeight(calculatedHeight);
		};

		window.addEventListener('resize', updateHeight);
		updateHeight(); // Initial call to set height

		return () => {
			window.removeEventListener('resize', updateHeight);
		};
	}, []);

	return (
		<div
			className={cn(
				'flex  items-center justify-center rounded-md border border-dashed border-slate-200 bg-slate-50 bg-opacity-50 dark:bg-slate-950',
				className,
				'bg-gradient-to-b from-slate-50 from-40%  to-white to-90% ',
			)}
			ref={ref}
			style={{ minHeight: height }}
		>
			<div className="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">
				<Media
					src={image}
					className="w-[370px] h-[210px] object-cover border rounded-3xl bg-white dark:bg-primary"
				/>
				<h3 className="mt-4 text-lg font-semibold">{title}</h3>
				<p className="mb-4 mt-2 text-sm text-muted-foreground">
					{description}
				</p>
				{btnLabel && (
					<Button size="lg" onClick={btnOnClick}>
						{isLoading ? (
							<Spinner className="me-2" />
						) : (
							<i className="bi-plus-circle-fill me-2" />
						)}
						<span>{btnLabel}</span>
					</Button>
				)}
				{learnMore && (
					<Button
						variant="link"
						className="mt-4"
						onClick={() => {
							window.open(learnMore, '_blank');
						}}
					>
						<span>Learn more</span>
						<i className="bi-box-arrow-in-up-right ms-2" />
					</Button>
				)}
			</div>
		</div>
	);
};

export default NoDataCard;
