import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { fetchAllProjects } from '../services/partnerships.service';
import { useInView } from 'react-intersection-observer';

export interface IProject {
	_id: string;
	name: string;
	description: string;
	logo: string;
	followerCount: number;
	chainId: number;
	primaryChainId: number;
	chainIds: number[];
	slug: string;
	socials: {
		twitter: string;
		telegram: string;
		discord: string;
	};
}

export const useGetProjects = () => {
	const { ref, inView } = useInView({ threshold: 0.5 });

	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({
		chainIds: [],
		categories: [],
		numFollowers: 0,
	});
	const pageSize = 21;

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
		useInfiniteQuery<{ projects: IProject[]; hasNext: true; page: number }>({
			queryKey: [
				'boosters',
				'partnerships',
				{
					search,
					filters,
				},
			],
			queryFn: ({ pageParam = 0 }) =>
				fetchAllProjects({
					search,
					filters,
					page: pageParam as number,
					pageSize,
				}),
			getNextPageParam: (lastPage) => {
				return lastPage.hasNext ? lastPage.page + 1 : undefined;
			},
			initialPageParam: 0, // starting with 0 as first batch was being skipped.
		});

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, fetchNextPage]);

	const results = data?.pages.flatMap((page) => page.projects) ?? [];

	return {
		setSearch,
		search,
		data: results,
		filters,
		setFilters,
		ref,
		isFetchingNextPage,
		isFetching,
	};
};
