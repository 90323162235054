import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { getExportRequests } from '../services/manage-team';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { cn } from '@/lib/utils';
import { DataTable } from '@/components/element/data-table/DataTable';
import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import dayjs from 'dayjs';
import { Button } from '@/components/ui/button';
import { formatNumberWithCommas, getSentenceCase } from '@/utils/parsers';
import ExportDialog from '../components/export/ExportDialog';

export interface IExportUserDataRequest {
	enterpriseId: string;
	exportType: string;
	resourceType: string;
	metadata: {
		campaignId: string;
		eventId: string;
		eventName: string;
		campaignName: string;
	};
	email: string;
	firstName: string;
	status: 'initiated' | 'completed' | 'failed';
	totalCount: number;
	files: { key: string; url: string }[];
	duration: number;
	completedAt: Date;
}

const ExportsPage = () => {
	const [open, setOpen] = useState(false);
	const [selectedExport, setSelectedExport] =
		useState<IExportUserDataRequest>(null);

	const { data, isLoading } = useQuery<IExportUserDataRequest[]>({
		queryKey: ['exports', 'all'],
		queryFn: () => getExportRequests(),
	});

	const cols = columns((exportDoc: IExportUserDataRequest) => {
		if (exportDoc?.files?.length === 1) {
			if (
				exportDoc.files[0].url?.includes(
					'https://static.highongrowth.xyz.s3.amazonaws.com/',
				)
			) {
				const link =
					'https://static.highongrowth.xyz/' +
					exportDoc.files[0].url.split(
						'https://static.highongrowth.xyz.s3.amazonaws.com/',
					)[1];
				window.open(link, '_blank');
			} else window.open(exportDoc.files[0].url, '_blank');
		} else {
			setSelectedExport(exportDoc);
			setOpen(true);
		}
	});

	if (isLoading) {
		return <FullPageLoading />;
	}

	return (
		<div>
			<div className={cn('flex items-center justify-between')}>
				<div className="space-y-1">
					<h2 className="text-xl font-medium tracking-tight">
						Data Exports
					</h2>
					<p className="text-sm text-muted-foreground max-w-[700px]">
						Exported data can be used for analysis and reporting.
					</p>
				</div>
				<div className="ml-auto mr-4"></div>
			</div>
			<div className="mt-5">
				<DataTable
					data={data ?? []}
					columns={cols}
					totalCount={data?.length || 0}
				/>
			</div>
			<ExportDialog
				open={open}
				setOpen={setOpen}
				requestDoc={selectedExport}
			/>
		</div>
	);
};

export default ExportsPage;

const columns = (fn): ColumnDef<IExportUserDataRequest>[] => [
	{
		accessorKey: 'resourceType',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Resource Type" />
		),
		cell: ({ row }) => {
			if (
				row.original.resourceType === 'campaign' ||
				row.original.resourceType === 'event'
			) {
				return (
					<div className="w-[200px] truncate">
						{row.original.resourceType === 'campaign'
							? !(
									row.original.metadata?.campaignName?.startsWith(
										'Celebrating',
									) &&
									row.original.metadata?.campaignName.endsWith(
										'Loyalty Campaign',
									)
								)
								? `Campaign: ${row?.original?.metadata?.campaignName}`
								: 'Loyalty Program'
							: `Event ${row?.original?.metadata?.eventName}`}
					</div>
				);
			} else {
				return <div>Overall</div>;
			}
		},
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'exportType',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Export Type" />
		),
		cell: ({ row }) => <div>{getSentenceCase(row.getValue('exportType'))}</div>,
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'totalCount',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Total Users" />
		),
		cell: ({ row }) => {
			return <div>{formatNumberWithCommas(row.getValue('totalCount'))}</div>;
		},
		enableSorting: true,
		enableHiding: false,
	},

	{
		accessorKey: 'status',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Status" />
		),
		cell: ({ row }) => {
			const status = row.getValue('status');
			let color = 'text-yellow-500';
			if (status === 'initiated') {
				color = 'text-yellow-500';
			} else if (status === 'completed') {
				color = 'text-green-500';
			} else if (status === 'failed') {
				color = 'text-red-500';
			}
			return (
				<div>
					<i className={`bi-circle-fill ${color} me-2`}></i>
					{getSentenceCase(row.getValue('status'))}
				</div>
			);
		},
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'completedAt',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Completed At" />
		),
		cell: ({ row }) => (
			<div>{dayjs(row.getValue('completedAt')).format('Do MMM YY')}</div>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'files',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Files" />
		),
		cell: ({ row }) =>
			row.original.files?.length > 0 ? (
				<Button variant="outline" size="sm" onClick={() => fn(row.original)}>
					{row.original.files?.length === 1
						? 'Download file'
						: 'View Files'}
				</Button>
			) : (
				<></>
			),
		enableSorting: false,
	},
];
