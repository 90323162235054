import {
	updateCustomTokenApproval,
	updateDaveActionApproval,
} from '../services/admin.service';
import { toast } from 'sonner';

export const useAdminDaveActions = ({ refetch }) => {
	const toggleApprovalStatus = async (actionId: string, isApproved: boolean) => {
		return new Promise((resolve) => {
			toast.promise(
				Promise.all([
					updateDaveActionApproval(actionId, { isApproved }).then(
						(res) => {
							resolve(res);
							refetch();
						},
					),
				]),
				{
					loading: isApproved
						? 'Approving dave action'
						: 'Disapproving dave action',
					success: isApproved
						? 'Dave action approved successfully'
						: 'Dave action disapproved successfully',
					error: isApproved
						? 'Failed to approve dave action'
						: 'Failed to disapprove dave action',
				},
			);
		});
	};

	return {
		toggleApprovalStatus,
	};
};
