import { UserType } from '@/providers/AuthProvider';
import { axios } from '@/lib/axios';

export const loginWithEmailAndPassword = async (data: {
	email: string;
	password: string;
}): Promise<UserType> => {
	const response = await axios.post('auth/login', data);
	return response.data as UserType;
};

export const loginWithGoogle = async (data: any) => {
	const response = await axios.post('auth/google/callback', data);
	return response.data;
};

export const loginWithDiscord = async (data: any) => {
	const response = await axios.post('auth/discord/callback', data);
	return response.data;
};

export const registerWithEmailAndPassword = async (data: any) => {
	const response = await axios.post('/auth/register-2', data);
	return response.data;
};

export const getUser = async (): Promise<UserType> => {
	try {
		const response = await axios.get('/auth/get-account');
		return response.data as UserType;
	} catch (err) {
		return null;
	}
};

export const logout = async () => {
	const response = await axios.get('/auth/logout');
	return response.data;
};

export const forgotPassword = async (data: any) => {
	const response = await axios.post('/auth/forgot-password', data);
	return response.data;
};

export const resetPassword = async (data: any) => {
	const response = await axios.post('/auth/reset-password', data);
	return response.data;
};

export const resendVerificationEmail = async () => {
	const response = await axios.get('/onboarding/resent-confirmation-email');
	return response.data;
};

export const confirmEmail = async (hash: string) => {
	const response = await axios.get('/onboarding/confirm-email?token=' + hash);
	return response.data;
};

export const updateDemoConfig = async (enabled: boolean, category: string) => {
	const response = await axios.put('/onboarding/demo-data', {
		enabled,
		category,
	});
	return response.data;
};

export const acceptInvite = async (data: any) => {
	const response = await axios.post('/enterprise/team/accept-invite', data);
	return response.data;
};

export const updatedTrackedUTMs = async (data: any) => {
	const response = await axios.put('/auth/update-utm', data);
	return response.data;
};

export const checkInvitation = async (hash: string) => {
	const response = await axios.post(`/enterprise/team/check-user`, {
		hash: hash,
	});
	return response.data;
};
