import { Button } from '@/components/ui/button';
import { useState } from 'react';
import AddContentDialog from '../../LearningContent/AddTaskContent';
import { ISlides } from '@/features/campaigns/types';
import { useCreateCampaignTasks } from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import { Label } from '@/components/ui/label';

const LearningContent = ({
	slides,
	taskId,
}: {
	slides: ISlides[];
	taskId: string;
}) => {
	const { editTask } = useCreateCampaignTasks();
	const [open, setOpen] = useState(false);
	return (
		<div className="border-t py-5 px-5 flex items-center justify-between">
			<div>
				<Label className="block mb-1">
					<i className="bi-easel2 mr-2"></i>
					Education Slides
				</Label>
				<p className="text-xs mt-2 mb-0 text-muted-foreground max-w-[400px]">
					Users will be shown slides to learn from before attempting the
					task.
				</p>
			</div>
			<div>
				<Button
					variant="outline"
					size="sm"
					onClick={() => {
						if (slides.length === 0) {
							editTask(taskId, 'EDIT_SLIDES', [
								{ title: '', content: '' },
							]);
						}
						setOpen(true);
					}}
				>
					Configure
				</Button>
			</div>
			<AddContentDialog
				setOpen={setOpen}
				open={open}
				slides={slides}
				setSlides={(v) => {
					editTask(taskId, 'EDIT_SLIDES', v);
				}}
			/>
		</div>
	);
};

export default LearningContent;
