import { defineChain } from 'viem';

export const xlayer = defineChain({
	id: 196,
	name: 'X Layer Mainnet',
	nativeCurrency: { decimals: 18, name: 'OKB', symbol: 'OKB' },
	rpcUrls: {
		default: {
			http: ['https://xlayerrpc.okx.com'],
			webSocket: ['wss://xlayerrpc.okx.com'],
		},
	},
	blockExplorers: {
		default: {
			name: 'OKLink X Layer',
			url: 'https://www.oklink.com/xlayer',
		},
	},
	testnet: false,
});
