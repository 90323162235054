export const contextualFormLabels = (category: string) => {
	const labels = {
		functions:
			'Choose the function that must be called for the action to be considered valid. Eg swapExactTokensForTokens.',
		events: 'Choose the event that must be emitted for the action to be validated. Eg. Transfer.',
		userAddress:
			'Specify how to identify the user within the transaction. Eg Txn Sender.',
	};
	return labels;
};
