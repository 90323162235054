import InputDuration from '@/components/element/inputs/input-duration/InputDuration';
import { useLoyaltyTasks } from '@/features/loyalty-campaigns/hooks/tasks/useLoyaltyTasks';
import { useLoyaltyTasksEdit } from '@/features/loyalty-campaigns/hooks/tasks/useLoyaltyTasksEdit';

const LoyaltyTaskDuration = () => {
	const { task, errors } = useLoyaltyTasks();
	const { editTask } = useLoyaltyTasksEdit();

	return (
		<div>
			<div className="mt-8">
				<div className="text-sm font-medium">Task Duration:</div>
				<p className="text-xs text-muted-foreground">
					Set task duration, immediate start, or no deadline
				</p>
			</div>
			<InputDuration
				className="w-full"
				startDate={task?.taskStartDate}
				endDate={task?.taskEndDate}
				startImmediately={task?.startImmediately}
				noDeadline={task?.noEndTime}
				timezone={task?.taskStartDateTz}
				setStartDate={(date) => editTask('taskStartDate', date)}
				setEndDate={(date) => editTask('taskEndDate', date)}
				setStartImmediately={(e) => editTask('startImmediately', e)}
				setTimezone={(e) => editTask('taskStartDateTz', e)}
				setNoDeadline={(e) => editTask('noEndTime', e)}
			/>
			{errors?.duration && (
				<p className="text-xs text-red-500 mt-2">{errors.duration}</p>
			)}
		</div>
	);
};

export default LoyaltyTaskDuration;
