import PageHeader from '@/components/layout/PageHeader';
import EventsTable from '../components/events-table/EventsTable';
import { useNavigate } from 'react-router-dom';
import { links } from '@/config/links';
import { useEvents } from '../hooks/report/useEvents';

const EventsPage = () => {
	const navigate = useNavigate();
	const {
		events,
		searchInput,
		isLoading,
		setSearchInput,
		filters,
		setFilters,
		totalEvents,
	} = useEvents();
	return (
		<div>
			<PageHeader
				title="Events"
				description="Here you can see all the events"
				icon="calendar"
				btnOnClick={() => navigate('/app/campaign/events/new')}
				btnLabel="Create Event"
				learn={totalEvents === 0 ? links.docs.events : ''}
			/>
			<EventsTable
				events={events}
				searchInput={searchInput}
				isLoading={isLoading}
				setSearchInput={setSearchInput}
				filters={filters}
				setFilters={setFilters}
				totalEvents={totalEvents}
			/>
		</div>
	);
};

export default EventsPage;
