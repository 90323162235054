import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import { usePrompt } from '@/hooks/usePrompt';
import { IDaveCreateQuery, QueryListType } from '../types/dave.types';
import { DaveQueryCreateSectionEnum, QueryCategory } from '../types/dave.enum';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetActions } from '../hooks/actions/useGetActions';
import { v4 as uuidv4 } from 'uuid';
import { useGetQuery } from '../hooks/queries/useGetQuery';
import { deepCopy } from '@/utils/parsers';
interface DaveQueryCreateProviderState {
	setIsDirty: Dispatch<SetStateAction<boolean>>;
	setSection: Dispatch<SetStateAction<DaveQueryCreateSectionEnum>>;
	section: DaveQueryCreateSectionEnum;
	details: IDaveCreateQuery;
	setDetails: Dispatch<SetStateAction<IDaveCreateQuery>>;
	errors: {
		name?: boolean;
		conditions?: {
			id?: string;
			actionTag: boolean;
			inflowAmount?: boolean;
			outflowAmount?: boolean;
			countCondition: boolean;
		}[];
	};
	setErrors: Dispatch<SetStateAction<Record<string, any>>>;
	contextualSuggestions: {
		id: string;
		element: string | JSX.Element | Element;
	};
	id?: string;
	setContextualSuggestions: Dispatch<
		SetStateAction<{
			element: string | JSX.Element | Element;
			id: string;
		}>
	>;
}

const initalQueryList: QueryListType = {
	id: 'first-condition',
	category: QueryCategory.SingleTransaction,
	actionTag: '',
	eligibleInflowAddressesTag: 'ANY',
	inflowAddressesList: [],
	eligibleOutflowAddressesTag: 'ANY',
	outflowAddressesList: [],
	inflowAmount: 0,
	outflowAmount: 0,
	chainId: null,
	countCondition: 1,
	isChecked: {
		tokenIn: false,
		tokenOut: false,
		multipleTxn: false,
		aggregatedVolume: false,
	},
};

const initialFormState: IDaveCreateQuery = {
	name: '',
	description: '',
	requiredTrueCount: 1,
	logicSelector: 'and',
	queryList: [initalQueryList],
};

const initialState: DaveQueryCreateProviderState = {
	setSection: () => {},
	setIsDirty: () => {},
	section: DaveQueryCreateSectionEnum.Filters,
	details: initialFormState,
	setDetails: () => {},
	errors: {
		name: false,
		conditions: [],
	},
	setErrors: () => {},
	contextualSuggestions: {
		element: '',
		id: '',
	},
	id: '',
	setContextualSuggestions: () => {},
};

export const DaveQueryCreateContext =
	createContext<DaveQueryCreateProviderState>(initialState);

export function DaveQueryCreateProvider({
	children,
	section,
	setSection,
}: {
	children: JSX.Element;
	section: DaveQueryCreateSectionEnum;
	setSection: Dispatch<SetStateAction<DaveQueryCreateSectionEnum>>;
}) {
	const { id } = useParams();
	const [mounted, setMounted] = useState(false);

	const { query } = useGetQuery();

	const [searchParams] = useSearchParams();
	const { actions } = useGetActions('');
	const [isDirty, setIsDirty] = useState(false);
	const [details, setDetails] = useState(initialFormState);
	const [errors, setErrors] = useState({
		name: false,
		conditions: [
			{
				id: 'first-condition',
				actionTag: false,
				inflowAmount: false,
				outflowAmount: false,
				countCondition: false,
			},
		],
	});
	const [contextualSuggestions, setContextualSuggestions] = useState({
		element: '',
		id: '',
	});

	useEffect(() => {
		if (id) return;
		if (!actions || actions.length === 0) return;
		if (searchParams.has('actionId')) {
			const actionIds = searchParams.get('actionId')?.split(',');
			setDetails((prev) => ({
				...prev,
				queryList: actionIds.map((actionId) => {
					return {
						...initalQueryList,
						id: uuidv4(),
						category: QueryCategory.SingleTransaction,
						actionTag: actions.find((a) => a._id === actionId)
							?.actionTag,
						chainId: actions.find((a) => a._id === actionId)?.chainId,
					};
				}),
			}));
		}
	}, [id, searchParams, actions]);

	useEffect(() => {
		if (mounted) return;
		if (query) {
			setDetails((prev) => ({
				...prev,
				name: query.name,
				description: query.description,
				requiredTrueCount: query.requiredTrueCount,
				logicSelector: query.requiredTrueCount > 1 ? 'and' : 'or',
				queryList: query.queryList.map((q) => {
					return {
						id: uuidv4(),
						category: q.category,
						actionTag: q.actionTag,
						eligibleInflowAddressesTag: q.eligibleInflowAddressesTag,
						inflowAddressesList: q.inflowAddressesList,
						eligibleOutflowAddressesTag: q.eligibleOutflowAddressesTag,
						outflowAddressesList: q.outflowAddressesList,
						inflowAmount: q.inflowAmount,
						outflowAmount: q.outflowAmount,
						chainId: q.chainId,
						countCondition: q.countCondition,
						isChecked: {
							tokenIn:
								q.eligibleInflowAddressesTag !== 'ANY' ||
								q.inflowAmount > 0,
							tokenOut:
								q.eligibleOutflowAddressesTag !== 'ANY' ||
								q.outflowAmount > 0,
							multipleTxn: q.countCondition > 1,
							aggregatedVolume:
								q.category === QueryCategory.MultipleTransaction,
						},
					};
				}),
			}));
			setMounted(true);
		}
	}, [query, mounted]);

	usePrompt({ isDirty });

	return (
		<DaveQueryCreateContext.Provider
			value={{
				setIsDirty,
				section,
				setSection,
				details,
				setDetails,
				contextualSuggestions,
				setContextualSuggestions,
				errors,
				setErrors,
				id,
			}}
		>
			{children}
		</DaveQueryCreateContext.Provider>
	);
}
