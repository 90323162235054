import { useQuery } from '@tanstack/react-query';
import { fetchAllActions } from '../../services/dave.service';
import { IDaveActions } from '../../types/dave.types';
import { useMemo } from 'react';

export const useGetActions = (search: string) => {
	const { data, isLoading } = useQuery<IDaveActions[]>({
		queryFn: fetchAllActions,
		queryKey: ['dave', 'actions'],
	});

	const actions = useMemo(() => {
		return (
			data?.filter((action) => {
				if (search) {
					return action.name.toLowerCase().includes(search.toLowerCase());
				}
				return true;
			}) ?? []
		);
	}, [data, search]);

	return {
		actions: actions,
		hasActions: data?.length > 0,
		isLoading,
	};
};
