import { createContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { globalSearch } from '../features/getting-started/services/home.service';
import { useDebounce } from '@/hooks/useDebounce';
import { useQuickAccess } from '../features/getting-started/hooks/useQuickAccess';
import { useAuth } from '@/hooks/useAuth';
import GlobalSearchCommand from '@/features/getting-started/components/search/GlobalSearchCommand';
import { TrackingEvents } from '@/types/tracking.type';
import analytics from '@/lib/analytics';

type SearchProviderState = {
	results?: GlobalSearchResult[];
	isLoading?: boolean;
	open: boolean;
	setOpen: (open: boolean) => void;
	recentCampaigns?: any[];
	search: string;
	setSearch: (search: string) => void;
};

const initialState: SearchProviderState = {
	open: false,
	setOpen: () => {},
	search: '',
	setSearch: () => {},
};

export interface GlobalSearchResult {
	name: string;
	banner: string;
	_id: string;
	status: string;
	type: 'event' | 'campaign';
}

export const SearchProviderContext =
	createContext<SearchProviderState>(initialState);

export function SearchProvider({ children }) {
	const user = useAuth();
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState('');
	const debouncedSearch = useDebounce(search, 500);
	const { quickAccess } = useQuickAccess();
	const { data, isLoading } = useQuery<GlobalSearchResult[]>({
		queryKey: ['global-search', debouncedSearch],
		queryFn: () => globalSearch(debouncedSearch),
		enabled: !!user,
	});

	useEffect(() => {
		if (debouncedSearch?.length > 0) {
			analytics.track(TrackingEvents.GlobalSearchUsed, {
				search: debouncedSearch,
			});
		}
	}, [debouncedSearch]);

	useEffect(() => {
		if (!user) return;
		const down = (e: KeyboardEvent) => {
			if (e.key === 'j' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				if (open) {
					analytics.track(TrackingEvents.GlobalSearchViewed, {
						isShortcut: true,
					});
				}
				setOpen((open) => !open);
			}
		};
		document.addEventListener('keydown', down);
		return () => document.removeEventListener('keydown', down);
	}, []);

	const value = {
		results: data,
		isLoading,
		open,
		setOpen: (value: boolean) => {
			if (!user) return;
			if (value) {
				analytics.track(TrackingEvents.GlobalSearchViewed, {
					isShortcut: false,
				});
			}
			setOpen(value);
		},
		recentCampaigns: quickAccess?.recentResources,
		search,
		setSearch,
	};

	return (
		<SearchProviderContext.Provider value={value}>
			{children}
			<GlobalSearchCommand />
		</SearchProviderContext.Provider>
	);
}
