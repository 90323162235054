import { useMemo } from 'react';

const useKolFilters = ({
	kolsData,
	search,
	filters,
}: {
	kolsData: any;
	search: string;
	filters: any;
}) => {
	const filteredKOLs = useMemo(() => {
		if (!kolsData) return [];

		return kolsData.filter((kol) => {
			const isSearched = search
				? kol?.name?.toLowerCase().includes(search.toLowerCase())
				: true;

			const languageFilter =
				filters.language.length > 0
					? filters.language.includes(kol.language)
					: true;

			const regionFilter =
				filters.region.length > 0
					? filters.region.includes(kol.region) ||
						filters.region.includes('All')
					: true;

			const channelsFilter =
				filters.channels.length > 0
					? Array.isArray(kol?.social_info) &&
						kol?.social_info?.some((social: any) =>
							filters.channels.includes(social.name),
						)
					: true;

			let priceFilter = true;
			if (filters.price.length > 0) {
				// const kolPrice = parseFloat(kol.price); // Assuming kol.price is a string that can be converted to float
				const priceBounds = filters.price.map((p) => {
					if (p.startsWith('above')) {
						return {
							min: Number(p.split('above')[1]),
							max: Infinity,
						};
					} else {
						const parts = p.split('to').map(Number); // price filter is in format "min-to-max"
						return { min: parts[0], max: parts[1] };
					}
				});
				priceFilter = priceBounds.some(
					(bound) => kol.price >= bound.min && kol.price <= bound.max,
				);
			}

			return (
				isSearched &&
				languageFilter &&
				regionFilter &&
				channelsFilter &&
				priceFilter
			);
		});
	}, [kolsData, search, filters]);
	return filteredKOLs;
};

export default useKolFilters;
