import { axios } from '@/lib/axios';

export const getEnterprise = async () => {
	try {
		const response = await axios.get('/enterprise');
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const uploadFile = async (file: any) => {
	const formData = new FormData();
	formData.append('file', file);
	const response = await axios.post('/enterprise/upload-file', formData);
	return response.data[0].location;
};

export const updateEnterprise = async (data) => {
	const response = await axios.put('/enterprise/profile', data);
	return response.data;
};

export const updateEnterpriseSocials = async (data: any) => {
	const response = await axios.put('/enterprise/socials', data);
	return response.data;
};
