import { useState } from 'react';
import { SelectTimezone } from './SelectTimezone';

const Durationheader = ({ timezone, setTimezone }) => {
	const [editTimezone, setEditTimezone] = useState(false);
	return (
		<div className="mt-3 flex justify-end items-end">
			<div className="text-xs text-muted-foreground flex items-center">
				<span className="me-1">Timezone:</span>
				{editTimezone ? (
					<>
						<span className="font-medium ms-3">
							<SelectTimezone
								selected={timezone}
								setSelected={setTimezone}
								placeholder="Select timezone"
							/>
						</span>
					</>
				) : (
					<span className="">{timezone}</span>
				)}
				{!editTimezone && (
					<i
						className="bi bi-pencil-fill ms-2 text-xs cursor-pointer"
						onClick={() => setEditTimezone(true)}
					></i>
				)}
				{editTimezone && (
					<i
						className="bi bi-check-circle ms-2 text-xs cursor-pointer"
						onClick={() => setEditTimezone(false)}
					></i>
				)}
			</div>
		</div>
	);
};

export default Durationheader;
