export const ppcContent = {
	comparison: {
		title: 'Intract vs Zealy / Galxe',
		description:
			'Intract is the #1 preferred quest platform for top web3 enterprises. ',
		list: [
			{
				title: 'No bots, only real users',
				description: 'No bots, only real users',
				link: '/customers',
				col1: true,
				col2: false,
				col3: false,
			},
			{
				title: 'Priority 24x7 support',
				description: 'Priority 24x7 support',
				link: '/customers',
				col1: true,
				col2: false,
				col3: false,
			},
			{
				title: '100x co-marketing boost',
				description: '100x co-marketing boost',
				link: '/customers',
				col1: true,
				col2: false,
				col3: false,
			},

			{
				title: 'Quests for loyal users',
				description: 'Quests for loyal users',
				link: '/customers',
				col1: true,
				col2: false,
				col3: true,
			},
			{
				title: 'Network of 1,000+ KOLs',
				description: 'Network of 1,000+ KOLs',
				link: '/customers',
				col1: true,
				col2: true,
				col3: false,
			},

			{
				title: 'Intuitive & bug-free interface',
				description: 'Intuitive & bug-free interface',
				link: '/customers',
				col1: true,
				col2: false,
				col3: false,
			},

			{
				title: 'Free customisations',
				description: 'Free customisations',
				link: '/customers',
				col1: true,
				col2: false,
				col3: false,
			},

			{
				title: 'No-code on-chain quests',
				description: 'No-code on-chain quests',
				link: '/customers',
				col1: true,
				col2: false,
				col3: false,
			},

			{
				title: 'Advanced analytics',
				description: 'Advanced analytics',
				link: '/customers',
				col1: true,
				col2: false,
				col3: false,
			},
		],
	},
};
