export enum LoyaltySectionTabs {
	Tasks = 'tasks',
	Analytics = 'analytics',
	Submissions = 'submissions',
	Leaderboard = 'leaderboard',
}

export enum LoyaltyTaskCreateSteps {
	TaskParams = 'task-params',
	TaskFeatures = 'task-features',
	TaskDuration = 'task-duration',
}
