import { DataTable } from '@/components/element/data-table/DataTable';
import { QUEST_URL } from '@/config';
import { campaignsColumns } from '@/features/campaigns/core/campaign-columns';
import { Status } from '@/features/campaigns/types';
import { useNavigate } from 'react-router-dom';

const EventCampaignsTable = ({ campaigns }) => {
	const navigate = useNavigate();
	const cols = campaignsColumns([
		{
			type: 'button',
			label: 'Preview',
			onClick: (row: any) => {
				window.open(`${QUEST_URL}/quest/${row._id}`, '_blank');
			},
		},
	]);

	if (!campaigns) return;
	return (
		<div className="mt-10">
			<DataTable
				columns={cols}
				data={campaigns}
				onRowClick={(row) => {
					if (row.status === Status.Draft) {
						navigate(`/app/campaign/quests/${row._id}/update`);
					} else {
						navigate(`/app/campaign/quests/${row._id}`);
					}
				}}
				isLoading={false}
				totalCount={campaigns?.length}
			/>
		</div>
	);
};

export default EventCampaignsTable;
