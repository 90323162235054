import { queryClient } from '@/lib/react-query';
import { useRef } from 'react';
import { IntegrationType } from '../types/integrations.enums';
import { IIntegration } from '../types/integrations.types';
import { handleErrorMessage } from '@/utils/notifications';
import { toast } from 'sonner';
import {
	deleteIntegration,
	refetchDiscordIntegration,
} from '../services/integrations.service';
import { useConfirm } from '@/hooks/useConfirm';

export const useIntegrationsMutations = () => {
	const toastRef = useRef<any>();
	const { show } = useConfirm();

	const refetchData = async (integrationId: string) => {
		try {
			return new Promise((resolve) => {
				toast.promise(
					Promise.all([
						refetchDiscordIntegration(integrationId)
							.then((res) => {
								resolve(res);
								toast.success(
									'Discord integration data refetch in progress. This may take a few minutes. Please refresh the page after a few minutes to see the changes reflected.',
								);
								queryClient.invalidateQueries({
									queryKey: ['integrations'],
								});
							})
							.catch((err) => {
								resolve(err);
								handleErrorMessage(err);
							}),
					]),
				);
			});
		} catch (err) {
			handleErrorMessage(err);
			toast.dismiss(toastRef.current);
			console.log(err);
			queryClient.invalidateQueries({ queryKey: ['integrations'] });
		}
	};

	const disconnect = async (integrationId: string) => {
		try {
			const res = await show({
				title: 'Are you sure you want to disconnect this integration?',
				subtitle:
					'This will also delete all the data associated with this integration.',
				confirmText: 'Disconnect',
				cancelText: 'Cancel',
				type: 'warning',
			});
			if (!res) {
				return;
			}

			return new Promise((resolve) => {
				toast.promise(
					Promise.all([
						deleteIntegration(integrationId)
							.then((res) => {
								resolve(res);
								toast.success(
									'Integration disconnect in progress. This may take a few minutes. Please refresh the page after a few minutes to see the changes reflected.',
								);
								queryClient.invalidateQueries({
									queryKey: ['integrations'],
								});
								queryClient.invalidateQueries({
									queryKey: ['user'],
								});
							})
							.catch((err) => {
								resolve(err);
								handleErrorMessage(err);
							}),
					]),
				);
			});
		} catch (err) {
			toast.dismiss(toastRef.current);
			handleErrorMessage(err);
		}
	};

	return {
		refetchIntegration: refetchData,
		disconnectIntegration: disconnect,
	};
};
