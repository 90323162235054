import TopProjects from '../components/customers/TopProjects';
import Testimonials from '../components/homepage/Testimonials';
import LandingLayout from '../components/layout/LandingLayout';

const CustomersPage = () => {
	return (
		<LandingLayout>
			<TopProjects />
			<Testimonials />
		</LandingLayout>
	);
};

export default CustomersPage;
