export const API_URL = import.meta.env.VITE_API_URL;
export const QUEST_URL = import.meta.env.VITE_QUEST_URL;
export const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN;
export const HOTJAR_TOKEN = import.meta.env.VITE_HOTJAR_TOKEN;
export const TAG_MANAGER_TOKEN = import.meta.env.VITE_TAG_MANAGER_TOKEN;

export const PUBLIC_URL = 'https://app.intract.io';
export const RELEASE = import.meta.env.VITE_RELEASE;
export const ENV = import.meta.env.MODE;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_AUTH_TOKEN = import.meta.env.VITE_SENTRY_AUTH_TOKEN;
export const QUEST_API_URL = import.meta.env.VITE_QUEST_API_URL;

export const TELEGRAM_BOT_USERNAME = import.meta.env.VITE_TELEGRAM_BOT_USERNAME;
