import { useQuery } from '@tanstack/react-query';
import { AlphaHubNarrativeStatus, IEvent } from '../../types/events.type';
import { useMemo, useState } from 'react';
import { getEvents } from '../../services/event.service';

export const useEvents = () => {
	const { data, isLoading } = useQuery<IEvent[]>({
		queryKey: ['events'],
		queryFn: getEvents,
	});
	const [filters, setFilters] = useState<{
		status: AlphaHubNarrativeStatus[];
	}>({
		status: [],
	});
	const [searchInput, setSearchInput] = useState('');

	const events = useMemo(() => {
		if (!data) return data;
		return data.filter((i) => {
			if (
				searchInput &&
				!i.title.toLowerCase().includes(searchInput.toLowerCase())
			)
				return false;
			if (filters?.status.length && !filters.status.includes(i.status))
				return false;
			return true;
		});
	}, [data, filters, searchInput]);

	return {
		events,
		isLoading,
		searchInput,
		setSearchInput,
		filters,
		setFilters,
		totalEvents: data?.length ?? 0,
	};
};
