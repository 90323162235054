import { FacetedFilter } from '@/components/element/inputs/FacetedFilter';
import InputText from '@/components/element/inputs/InputText';
import { enterpriseCategories } from '../types/partnerships.content';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';

const PartnershipFilters = ({
	search,
	filters,
	setSearch,
	setFilters,
}: {
	search: string;
	filters: {
		chainIds: number[];
		categories: string[];
	};
	setSearch: any;
	setFilters: any;
}) => {
	const { chains, getChainOptions } = useGetSupportedChains({
		showAdditionalChains: false,
	});
	const chainsOptions = getChainOptions(chains);
	return (
		<div className="flex items-center mt-10 space-x-4">
			<InputText
				value={search}
				setValue={setSearch as any}
				prepend={
					<i className="bi-search px-3 text-muted-foreground text-sm"></i>
				}
				placeholder="Search Partners"
				className="w-[250px] lg:w-[250px]"
			/>
			<FacetedFilter
				title="Network"
				options={chainsOptions as any}
				selectedValues={filters.chainIds as any}
				setSelectedValues={(values: any) =>
					setFilters((p: any) => ({ ...p, chainIds: values }))
				}
			/>
			{/* <FacetedFilter
				title="Followers"
				options={chainsOptions as any}
				selectedValues={filters.chainIds as any}
				setSelectedValues={(values: any) =>
					setFilters((p) => ({ ...p, chainIds: values }))
				}
			/> */}
			<FacetedFilter
				title="Category"
				options={enterpriseCategories as any}
				selectedValues={filters.categories as any}
				setSelectedValues={(values: any) =>
					setFilters((p: any) => ({ ...p, categories: values }))
				}
			/>
		</div>
	);
};

export default PartnershipFilters;
