import * as React from 'react';

import { Button } from '@/components/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';

type Option = {
	value: string;
	label: string | JSX.Element;
	searchText?: string;
	selectedLabel?: any;
	heading?: boolean;
};

export function SelectWithSearch({
	options,
	selected,
	setSelected,
	placeholder,
	disabled,
	className,
	popoverClassName,
	appendIcon,
	error,
	prepend,
}: {
	options: Option[];
	selected: string | null;
	placeholder: string;
	setSelected: (option: string | null) => void;
	disabled?: boolean;
	className?: string;
	popoverClassName?: string;
	appendIcon?: string;
	error?: boolean;
	prepend?: any;
}) {
	const [open, setOpen] = React.useState(false);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					className={cn(
						`w-full flex justify-between font-normal`,
						className,
						error && ' border border-destructive',
					)}
					disabled={disabled}
				>
					<div className="truncate font-normal flex items-center">
						{appendIcon && (
							<i
								className={`${appendIcon} me-2 text-muted-foreground`}
							/>
						)}
						{selected && !disabled ? (
							<>
								{options.find((i) => i.value === selected)
									?.selectedLabel
									? options.find((i) => i.value === selected)
											?.selectedLabel
									: options.find((i) => i.value === selected)
											?.label}
							</>
						) : (
							<span className="text-muted-foreground">
								{placeholder}
							</span>
						)}
					</div>
					<CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent
				className={`w-[200px] p-0 ${popoverClassName}`}
				align="start"
				withoutPortal
			>
				<Options
					setOpen={setOpen}
					setSelected={setSelected}
					options={options}
					placeholder={placeholder}
				/>
			</PopoverContent>
		</Popover>
	);
}

function Options({
	setOpen,
	setSelected,
	options,
	placeholder,
}: {
	setOpen: (open: boolean) => void;
	placeholder: string;
	setSelected: (status: string | null) => void;
	options: Option[];
}) {
	const areOptionsGrouped = options.some((option) => option.heading);

	return (
		<Command
			filter={(value, search) => {
				const option = options.find((i) => i.value === value);
				if (option?.searchText) {
					return option?.searchText
						.toLowerCase()
						.includes(search.toLowerCase())
						? 1
						: 0;
				} else {
					return 0;
				}
			}}
		>
			<CommandInput placeholder={placeholder} />
			<CommandList>
				<CommandEmpty>No results found.</CommandEmpty>
				{!areOptionsGrouped && (
					<CommandGroup>
						{options.map((option) => (
							<CommandItem
								key={option.value}
								value={option.value}
								onSelect={() => {
									setSelected(option.value);
									setOpen(false);
								}}
							>
								{option.label}
							</CommandItem>
						))}
					</CommandGroup>
				)}
				{areOptionsGrouped && (
					<>
						{options.map((option) =>
							option.heading ? (
								<>
									<CommandSeparator />
									<CommandGroup
										key={option.value}
										heading={option.label}
									></CommandGroup>
									<CommandSeparator />
								</>
							) : (
								<CommandItem
									key={option.value}
									value={option.value}
									onSelect={() => {
										setSelected(option.value);
										setOpen(false);
									}}
								>
									{option.label}
								</CommandItem>
							),
						)}
					</>
				)}
			</CommandList>
		</Command>
	);
}
