/* eslint-disable no-mixed-spaces-and-tabs */
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { ICampaign, Status } from '../../types';
import {
	getCampaignTemplates,
	getCampaigns,
	getTotalSuperUsers,
} from '../../services/campaigns.service';

export const useCampaigns = () => {
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState<{
		status: Status[];
	}>({
		status: [],
	});

	const query = useQuery<ICampaign[]>({
		queryKey: ['campaigns'],
		queryFn: () => getCampaigns(),
	});

	const { data: templates } = useQuery<ICampaign[]>({
		queryKey: ['campaigns-templates'],
		queryFn: () => getCampaignTemplates(),
	});

	const { data: totalUsers = 0, isLoading: isTotalUsersLoading } =
		useQuery<number>({
			queryKey: ['total-users'],
			queryFn: () => getTotalSuperUsers(),
		});

	const formattedData = useMemo(() => {
		if (!query.data) return [];
		// console.log(query.data);
		return query.data
			.map((i) => ({
				...i,
			}))
			.filter((i: ICampaign) => {
				if (search && !i.name.toLowerCase().includes(search.toLowerCase()))
					return false;
				if (filters?.status.length && !filters.status.includes(i.status))
					return false;
				return true;
			})
			?.sort((a, b) => {
				return (
					new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
				);
			});
	}, [query.data, search, filters]);

	const metrics = useMemo(() => {
		if (!query.data || query.data.length === 0)
			return {
				totalInitiations: 0,
				totalCompletions: 0,
				totalUsers: 0,
			};
		const totalInitiations = query.data.reduce(
			(acc, curr) => acc + (curr?.initiatedUsersCount || 0),
			0,
		);
		const totalCompletions = query.data.reduce(
			(acc, curr) => acc + (curr?.completedUsersCount || 0),
			0,
		);
		return {
			totalInitiations,
			totalCompletions,
			totalUsers: totalUsers || 'N/A',
		};
	}, [query.data, totalUsers]);

	return {
		campaigns: formattedData,
		search,
		setSearch,
		isLoading: query.isLoading,
		filters,
		setFilters,
		metrics,
		totalCampaigns: query.data?.length,
		isTotalUsersLoading,
		templates,
	};
};
