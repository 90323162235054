import { axios } from '@/lib/axios';

export const postGoogleForm = async (data: any) => {
	try {
		await axios.post('/boosters/kols-partnership', data);
	} catch (error) {
		console.log(error);
	}
};

export const getKols = async () => {
	const { data } = await axios.get('/boosters/kols');
	return data;
};
