import { Button } from '@/components/ui/button';
import React from 'react';
import LandingContainer from '../ui/LandingContainer';
import { Card, CardContent } from '@/components/ui/card';
import { UserAuthForm } from './UserAuthForm';

const PPCHero = () => {
	return (
		<LandingContainer>
			<div className="absolute left-[24px] top-0 z-20 xl:left-[-354px] xl:top-[84px] bg-var-set">
				<div className="pointer-events-none absolute z-[0] h-[676px] w-[676px] rounded-full scale-[1.75] bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[--circle-background] to-[transparent] to-70% opacity-[12%]"></div>
			</div>
			<div className="pt-20 z-10 relative pb-5">
				<div className="grid grid-cols-12">
					<div className="col-span-6 backdrop-blur px-10">
						<div className="flex flex-col items-start justify-start ">
							<div className="text-sm rounded-full bg-slate-100 px-4 py-1">
								Unleash your Web3 Community
							</div>
							<div className="relative mb-8 mt-4">
								<h1 className=" text-[45px] font-medium  leading-snug  selection:text-black">
									Acquire 12M+ Real Web3 Users for Free
								</h1>
							</div>
							<div
								className="text-lg transition-colors text-zinc-500  w-full"
								style={{ maxWidth: 730 }}
							>
								<p>
									Sign up now to enjoy $10K credits. Grow a loyal
									community of only real users no bots. Get
									priority 24x7 support. Unlock 100x marketing
									boost from Intract’s socials and KOLs.
								</p>
							</div>
						</div>
					</div>
					<div className="col-span-1"></div>
					<div className="col-span-5">
						<Card>
							<CardContent className="p-5 py-7">
								<div className="font-medium text-xl text-center mb-6">
									Get Started
								</div>
								<UserAuthForm />
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		</LandingContainer>
	);
};

export default PPCHero;
