import { useQuery } from '@tanstack/react-query';
import { getNFTs } from '../services/nfts.service';
import { useMemo, useState } from 'react';
import { INFTContract } from '../types/nfts.enum';

export const useGetNFTs = () => {
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({
		chains: [],
	});

	const { data, isLoading } = useQuery<INFTContract[]>({
		queryKey: ['nfts'],
		queryFn: () => getNFTs(),
	});

	const formattedData = useMemo(() => {
		if (!data) return [];

		return data
			.map((i) => ({
				...i,
			}))
			.filter((i: INFTContract) => {
				if (search && !i.name.toLowerCase().includes(search.toLowerCase()))
					return false;
				if (filters?.chains.length && !filters.chains.includes(i.chainId))
					return false;
				return true;
			})
			.sort((a, b) => {
				return (
					new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
				);
			});
	}, [data, filters, search]);

	return {
		nfts: formattedData,
		isLoading,
		search,
		setSearch,
		filters,
		setFilters,
		userNFTs: data?.length,
	};
};
