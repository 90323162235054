import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogDescription,
	DialogTitle,
} from '@/components/ui/dialog';

import { Card, CardContent } from '@/components/ui/card';
import { Link } from 'react-router-dom';

const LaunchNewDialog = ({ open, setOpen, templates }: any) => {
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[525px] lg:max-w-[680px]  overflow-y-auto">
				<div className="grid grid-cols-7">
					<div className="col-span-7">
						<DialogHeader className="border-b pb-3">
							<DialogTitle>Launch your campaign</DialogTitle>
							<DialogDescription>
								Choose a template to get started or create a new
								campaign from scratch.
							</DialogDescription>
						</DialogHeader>
						<CampaignTemplates templates={templates} />
						<CampaignResources />
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default LaunchNewDialog;

const CampaignTemplates = ({
	templates,
}: {
	templates: {
		_id: string;
		name: string;
	}[];
}) => {
	return (
		<div>
			<div className="grid grid-cols-3 mt-4 gap-5">
				{templates.map((i) => (
					<CampaignTemplate {...i} />
				))}
				<Link to="/app/campaign/quests/new" className="h-full">
					<Card className="col-span-1 shadow-none hover:-translate-y-1 transition-all cursor-pointer border-dashed h-full min-h-[150px]">
						<CardContent className="p-3 py-10 text-center flex justify-center items-center h-full">
							<div className="font-medium">Create from scratch</div>
						</CardContent>
					</Card>
				</Link>
			</div>
		</div>
	);
};

const CampaignTemplate = ({ name, _id }: any) => {
	return (
		<Link to={`/app/campaign/quests/new?templateId=${_id}`} className="h-full">
			<Card
				className={`col-span-1 shadow-none hover:-translate-y-1 transition-all cursor-pointer bg-purple-100 flex min-h-[150px]`}
			>
				<CardContent className="p-3 py-10 text-center h-100 flex items-center justify-center">
					<div>
						<div className=" font-medium">{name}</div>
					</div>
				</CardContent>
			</Card>
		</Link>
	);
};
const CampaignResources = () => {
	return (
		<div className="bg-slate-100 -mx-7 px-8 -mb-7 pb-8 pt-8 mt-8">
			<h3 className="font-medium text-base">Campaign Resources</h3>
			<p className="text-xs text-muted-foreground">
				Learn from our resources to help you get started with your campaign
			</p>
			<div className="grid grid-cols-4 mt-4 gap-5">
				{resources.map((i) => (
					<CampaignResource {...i} />
				))}
			</div>
		</div>
	);
};

const resources = [
	{
		color: 'blue',
		title: 'Reaching 10M users in partnerships with 10 projects',
		description: 'Create a campaign for your NFT project',
	},
	{
		color: 'red',
		title: 'How to increase your completions using best practices?',
		description: 'Create a campaign for your NFT project',
	},
];

const CampaignResource = ({ title }: any) => {
	return (
		<Card className="col-span-2 shadow-none hover:-translate-y-1 transition-all cursor-pointer">
			{/* <div
				className={`h-3 w-full object-cover rounded-t-lg ${
					(colors as any)[color]
				}`}
			/> */}
			<CardContent className="p-3">
				<div className="flex items-center">
					<i className="bi bi-file-earmark-text-fill text-2xl text-muted-foreground me-3"></i>
					<div className="text-sm font-medium">{title}</div>
					{/* <div className="text-xs text-muted-foreground">
						{description}
					</div> */}
				</div>
			</CardContent>
		</Card>
	);
};

const templates = [
	{
		img: 'bg-yellow-100',
		title: 'Amplify your Product Updates',
	},
	{
		img: 'bg-red-100',
		title: 'Celebrate your Milestones',
	},
	{
		img: 'bg-green-100',
		title: 'Celebrate your launch on Intract',
	},
	{
		img: 'bg-blue-100',
		title: 'Celebrate your Milestones',
	},
	{
		img: 'bg-pink-100',
		title: 'Celebrate your launch on Intract',
	},
];
