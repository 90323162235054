import { CampaignProvider } from '../providers/CampaignProvider';
import CampaignReport from '../components/campaign-report/CampaignReport';

const CampaignReportPage = () => {
	return (
		<CampaignProvider>
			<CampaignReport />
		</CampaignProvider>
	);
};

export default CampaignReportPage;
