import { Button } from '@/components/ui/button';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IDaveContracts } from '../../types/dave.types';
import { Textarea } from '@/components/ui/textarea';
import TabOptions from '@/components/element/tabs/TabOptions';
import { Card, CardContent } from '@/components/ui/card';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { links } from '@/config/links';

const ConfirmContractABI = ({
	details,
	setDetails,
	errors,
}: {
	handleNext: () => void;
	details: IDaveContracts;
	setDetails: Dispatch<SetStateAction<IDaveContracts>>;
	errors: any;
}) => {
	const [selected, setSelected] = useState<'preview' | 'edit'>('preview');
	useEffect(() => {
		if (details.abi?.length === 0) {
			setSelected('edit');
		}
	}, [details]);
	return (
		<>
			<div className="mb-0 flex justify-between border-b items-center pb-3">
				<div className="text-lg font-medium">Add Contract ABI</div>
				<Button
					variant="link"
					onClick={() =>
						window.open(links.docs.needHelpAddingContract, '_blank')
					}
				>
					<i className="bi bi-question-circle-fill  me-1"></i>
					Need Help
				</Button>
			</div>
			<div className="flex -mb-2">
				<TabOptions
					className="w-full"
					selected={selected}
					setSelected={setSelected}
					options={[
						{
							label: 'Edit',
							value: 'edit',
						},
						{
							label: 'Preview',
							value: 'preview',
							disabled: details.abi?.length === 0,
						},
					]}
				/>
			</div>
			{selected === 'edit' && (
				<>
					<Textarea
						value={details.abi}
						className={`h-[300px] border border-black ${errors.abi ? 'border-destructive' : ''}`}
						onChange={(e) => {
							setDetails({ ...details, abi: e.target.value });
						}}
					/>
					{errors.abi && (
						<div className="text-destructive text-xs mt-1">
							ABI is invalid.
						</div>
					)}
				</>
			)}
			{selected === 'preview' && (
				<ABIPreview abi={details.abi} maxHeight="max-h-[150px]" />
			)}
		</>
	);
};

export default ConfirmContractABI;

export const ABIPreview = ({
	abi,
	maxHeight = 'max-h-[150px]',
}: {
	abi: any;
	maxHeight: string;
}) => {
	const [ABI, setABI] = useState([]);
	const [error, setError] = useState(false);

	useEffect(() => {
		try {
			const ABI_ = JSON.parse(abi);
			setABI(ABI_);
			setError(false);
		} catch (err) {
			console.log(err);
			setError(true);
		}
	}, [abi]);

	const events = ABI.filter((i: any) => i.type === 'event');
	const functions = ABI.filter((i: any) => i.type === 'function');

	return (
		<div className="mt-5 ">
			{error ? (
				<div className="text-destructive text-xs my-10 text-center">
					Invalid ABI. Please enter a valid ABI JSON
				</div>
			) : (
				<>
					<div className="font-medium text-sm mb-3 flex justify-between">
						<div>Functions</div>
						<div> ({functions.length})</div>
					</div>

					<Card
						className={`shadow-none ${maxHeight} overflow-auto bg-slate-50 border rounded-lg`}
					>
						<CardContent className="p-0 border rounded-lg">
							{functions.map((i: any) => (
								<ABILineItem name={i.name} inputs={i.inputs} />
							))}
						</CardContent>
					</Card>
					<div className="font-medium text-sm mb-3 mt-5 flex justify-between">
						<div>Events</div>
						<div> ({events.length})</div>
					</div>
					<Card
						className={`shadow-none ${maxHeight} overflow-auto bg-slate-50 border rounded-lg`}
					>
						<CardContent className="p-0 border rounded-lg">
							{events.map((i: any) => (
								<ABILineItem name={i.name} inputs={i.inputs} />
							))}
						</CardContent>
					</Card>
				</>
			)}
		</div>
	);
};

const ABILineItem = ({ name, inputs }) => {
	return (
		<div className="border-b px-2 py-2 flex items-center justify-between">
			<div className="text-xs ">{name}</div>
			{inputs.length > 0 && (
				<div>
					<TooltipWrapper
						tooltip={inputs.map((i) => `${i.type} ${i.name}`).join(', ')}
					>
						<div className="text-xs text-muted-foreground max-w-[200px] truncate">
							({inputs.map((i) => `${i.type} ${i.name}`).join(', ')})
						</div>
					</TooltipWrapper>
				</div>
			)}
		</div>
	);
};
