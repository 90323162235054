import { Button } from '@/components/ui/button';
import * as Sentry from '@sentry/react';

const ErrorSuggestion = ({ message }: { message: string }) => {
	if (message.includes("Failed to execute 'removeChild' on 'Node'")) {
		return (
			<div>
				We&apos;ve noticed that this type of error is thrown when Google
				Chrome&apos;s Translate Page feature is in use.
				<br />
				It might be worth trying another browser, another translation tool,
				or disable the translation feature.
			</div>
		);
	}

	return null;
};

export const AppError: Sentry.FallbackRender = ({ error, resetError }) => {
	return (
		<div className="w-screen h-screen flex flex-col items-center justify-center text-center">
			<div className="mb-4 text-lg font-medium">Something went wrong!</div>
			<div>
				Rest assured, we&apos;ve been notified and are looking into this
				error:
			</div>
			<code className="my-6">Error Message: {error.message}</code>
			<ErrorSuggestion message={error.message} />
			<div className="mt-4">
				<Button onClick={resetError}>Reset Error</Button>
			</div>
		</div>
	);
};
