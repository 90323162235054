import { Separator } from '@/components/ui/separator';
import EventCampaignSections from './event-campaigns/EventCampaignSections';
import { useCreateEvent } from '@/features/events/hooks/create/useCreateEvent';

const EventCampaigns = () => {
	const { errors } = useCreateEvent();
	return (
		<>
			<div className="">
				<div className="flex justify-center relative items-center pt-10">
					<div className="mb-5 w-[700px] relative">
						<h4 className="text-xl font-medium">
							Add Campaigns to your event
						</h4>
						<p className="text-muted-foreground text-xs mt-1 mb-4">
							Configure the rewards for your campaign.
						</p>
						<Separator className="my-4" />
						{errors?.campaigns && (
							<p className=" text-destructive text-xs mb-3">
								Please add at least one campaign to proceed!
							</p>
						)}
						<EventCampaignSections />
					</div>
				</div>
			</div>
		</>
	);
};

export default EventCampaigns;
