export const featuresContent = {
	hero: {
		label: 'Sign up and get $10k in credits',
		title: 'Build your Community with Intract',
		description:
			'Get everything you need for business loan management and servicing. Plus the ability to operate and scale multiple products. All on a single platform.',
		features: [
			{
				title: 'Feedback boards',
				description:
					'Collect customer feedback in one easy-to-access place.',
				link: '/features/feedback-boards',
				cta: 'Learn more',
			},
			{
				title: 'Product Roadmap',
				description:
					'Let your cutsomers know which features are being prioritized first.',
				link: '/features/product-roadmap',
				cta: 'Learn more',
			},
			{
				title: 'Product Changelog',
				description:
					'Notify your users from small updates to major feature releases.',
				link: '/features/product-changelog',
				cta: 'Learn more',
			},
		],
	},
	coreFeatures: [
		{
			label: 'Customer feedback board',
			title: 'Organize with feedback boards',
			description:
				'Feedback boards let you organize feature requests into topics and subtopics so that they’re easy to find later on.',
			features: [
				{
					type: '1',
					title: 'Collect feedback from different platforms',
					description:
						'Now all your customer feedback can be collected in one place, making it easier for you to respond.',
					image: 'https://supahub.com/assets/feedback-boards/feedback-boards-supahub.png',
				},
				{
					type: '2',
					title: 'Set board privacy',
					description:
						'You can control board privacy, allowing you to control who sees the feedback.',
					image: 'https://supahub.com/assets/feedback-boards/feedback-board-privacy-supahub.png',
				},
				{
					type: '2',
					title: 'Vote on behalf',
					description:
						'You can vote on behalf of your users on an existing feedback.',
					image: 'https://supahub.com/assets/feedback-boards/vote-on-behalf-supahub.jpg',
				},
				{
					type: '1',
					title: 'Merge duplicate posts',
					description:
						'Spot duplicates and merge them together so that your team doesn’t have to waste time reading through the same post twice.',
					image: 'https://supahub.com/assets/feedback-boards/merge-duplicate-posts-square-supahub.png',
				},
			],
		},
		{
			label: 'Customer feedback board',
			title: 'Organize with feedback boards',
			description:
				'Feedback boards let you organize feature requests into topics and subtopics so that they’re easy to find later on.',
			features: [
				{
					type: '1',
					title: 'Collect feedback from different platforms',
					description:
						'Now all your customer feedback can be collected in one place, making it easier for you to respond.',
					image: 'https://supahub.com/assets/feedback-boards/feedback-boards-supahub.png',
				},
				{
					type: '2',
					title: 'Set board privacy',
					description:
						'You can control board privacy, allowing you to control who sees the feedback.',
					image: 'https://supahub.com/assets/feedback-boards/feedback-board-privacy-supahub.png',
				},
				{
					type: '2',
					title: 'Vote on behalf',
					description:
						'You can vote on behalf of your users on an existing feedback.',
					image: 'https://supahub.com/assets/feedback-boards/vote-on-behalf-supahub.jpg',
				},
				{
					type: '1',
					title: 'Merge duplicate posts',
					description:
						'Spot duplicates and merge them together so that your team doesn’t have to waste time reading through the same post twice.',
					image: 'https://supahub.com/assets/feedback-boards/merge-duplicate-posts-square-supahub.png',
				},
			],
		},
	],
	valueProps: [
		{
			title: 'Find KOLs and Partners',
			description:
				'You can control board privacy, allowing you to control who sees the feedback.',
			image: 'https://supahub.com/assets/feedback-boards/feedback-board-privacy-supahub.png',
		},
		{
			title: 'Own your Data',
			description:
				'You can control board privacy, allowing you to control who sees the feedback.',
			image: 'https://supahub.com/assets/feedback-boards/feedback-board-privacy-supahub.png',
		},
		{
			title: 'Set up Reward Store',
			description:
				'You can control board privacy, allowing you to control who sees the feedback.',
			image: 'https://supahub.com/assets/feedback-boards/feedback-board-privacy-supahub.png',
		},
	],
	faqs: {
		badge: 'Frquently Asked Questions',
		title: 'Everything you need to know about Intract',
		description:
			'If you have anything else you want to ask, feel free to reach out to us.',
		faqs: [
			{
				question: 'What is Supahub?',
				answer: 'Supahub is a customer feedback management platform that helps businesses collect, organize, and prioritize feedback from their customers.',
			},
			{
				question: 'Is Supahub free?',
				answer: 'Yes. Supahub does come with a free plan. Paid plans start for only $19/month.',
			},
			{
				question: 'Can I vote on behalf of my users?',
				answer: 'Yes, you have the ability to vote on behalf of your users in Supahub.',
			},
			{
				question:
					'How is Supahub different from other feedback management tools?',
				answer: 'Supahub offers a user-friendly and intuitive interface, inspired by industry leaders like Canny.io, making it easy to collect and analyze customer feedback. We focus on helping you take action on the most important insights.',
			},
			{
				question:
					'Can I use Supahub as both an internal and a public feedback tool?',
				answer: 'Yes! You can use Supahub to collect feedback both from your internal team and from external users. The platform is highly customizable so that you can easily change feedback board privacy that serves your specific needs.',
			},
			{
				question: 'How can I get started with Supahub?',
				answer: 'To get started with Supahub, simply sign up for an account and begin collecting feedback from your customers and teammates.',
			},
			{
				question: 'When should I use public vs private boards?',
				answer: 'Use public boards when you want open feedback and engagement from customers. Use private boards when you want to limit visibility to specific users or teams.',
			},
			{
				question: 'How much does Supahub cost?',
				answer: 'Supahub offers a range of pricing plans to suit different needs and budgets, with plans starting at $19/month. But most importantly Supahub offers a free plan and a 10-day trial to get you started.',
			},
			{
				question:
					"Can I customize Supahub to match my brand's look and feel?",
				answer: "Absolutely. Supahub offers customization options to ensure that the platform aligns with your brand's identity, so your customers experience a seamless transition.",
			},
		],
	},
};
