import { createContext, useEffect, useState } from 'react';
import { ICampaign, ITask, ReportTabs, Status } from '../types';
import { useQuery } from '@tanstack/react-query';
import { getCampaign } from '../services/campaigns.service';
import { useNavigate, useParams } from 'react-router-dom';
import { QUEST_URL } from '@/config';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { handleErrorMessage } from '@/utils/notifications';

interface CampaignProviderState {
	campaign: ICampaign;
	isLoading: boolean;
	id: string;
	link: string;
	selectedTab: ReportTabs;
	setSelectedTab: (tab: ReportTabs) => void;
	hasPowTasks: boolean;
}

export const CampaignContext = createContext<CampaignProviderState>({
	campaign: null,
	isLoading: false,
	id: '',
	link: '',
	selectedTab: ReportTabs.Analytics,
	setSelectedTab: () => {},
	hasPowTasks: false,
});

export function CampaignProvider({ children }) {
	const navigate = useNavigate();
	const [selectedTab, setSelectedTab] = useState(ReportTabs.Analytics);
	const [recentCampaigns, setRecentCampaigns] = useLocalStorage(
		'recent-campaigns',
		'',
	);

	const { id } = useParams();
	const { data, isLoading, error } = useQuery<ICampaign>({
		queryKey: ['campaign', id],
		queryFn: () => getCampaign(id),
	});

	useEffect(() => {
		if (error) {
			handleErrorMessage(
				'Error fetching campaign report, you might be on a wrong page.',
			);
			navigate(`/app/campaign/quests`);
		}
	}, [error]);

	useEffect(() => {
		if (data && data.status === Status.Draft) {
			navigate(`/app/campaign/quests/${id}/update`);
		}
	}, [data]);

	useEffect(() => {
		if (!data) return;
		const recentCampaignsArray = recentCampaigns.split(',');
		if (!recentCampaignsArray.includes(id)) {
			recentCampaignsArray.push(id);
			setRecentCampaigns(recentCampaignsArray.join(','));
		}
	}, [data]);

	return (
		<CampaignContext.Provider
			value={{
				campaign: data,
				isLoading,
				id,
				selectedTab,
				setSelectedTab,
				link: `${QUEST_URL}/quest/${id}?utm_source=dashboard`,
				hasPowTasks: data?.quest?.tasks.some(
					(task: ITask) => task?.powVerifyConfig?.isPOWVerify,
				),
			}}
		>
			{children}
		</CampaignContext.Provider>
	);
}
