import React from 'react';

const LandingContainer = ({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) => {
	return (
		<div
			className={`mx-auto w-full max-w-screen-xl px-2.5 lg:px-20 relative ${className}`}
		>
			{children}
		</div>
	);
};

export default LandingContainer;
