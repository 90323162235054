import { useState } from 'react';
import { ILoyaltyTask } from '../../types/loyalty.type';
import { useLoyaltyTasksEdit } from './useLoyaltyTasksEdit';
import { useLoyaltyTasksInitialise } from './useLoyaltyTasksInitialise';

export enum LoyaltyTaskActions {
	Edit = 'Edit',
	Duplicate = 'Duplicate',
	Delete = 'Delete',
	Publish = 'Publish',
	Complete = 'Complete',
	SendNotification = 'SendNotification',
	Archive = 'Archive',
	Active = 'Active',
}

export const useLoyaltyTasksActions = ({
	setOpen,
	task,
}: {
	setOpen: any;
	task: ILoyaltyTask;
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const { editActiveTask, duplicateTask } = useLoyaltyTasksInitialise();
	const {
		deleteTask,
		completeTask,
		sendDiscordNotification,
		makeScheduledTaskActive,
	} = useLoyaltyTasksEdit();

	const getTaskAction = async (actionType: LoyaltyTaskActions) => {
		setIsLoading(true);
		try {
			if (actionType === LoyaltyTaskActions.Duplicate) {
				duplicateTask(task._id);
				setOpen(false);
			}
			if (actionType === LoyaltyTaskActions.Archive) {
				await deleteTask(task._id);
				setOpen(false);
			}
			if (actionType === LoyaltyTaskActions.Edit) {
				editActiveTask(task._id);
				setOpen(false);
			}
			if (actionType === LoyaltyTaskActions.Complete) {
				await completeTask(task._id);
				setOpen(false);
			}
			if (actionType === LoyaltyTaskActions.SendNotification) {
				await sendDiscordNotification(task._id);
				setOpen(false);
			}
			if (actionType === LoyaltyTaskActions.Active) {
				await makeScheduledTaskActive(task._id);
				setOpen(false);
			}
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
		}
	};

	return {
		getTaskAction,
		isLoading,
	};
};
