import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card';
import { CSS } from '@dnd-kit/utilities';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useLoyaltyCampaignSections } from '../../hooks/useLoyaltyCampaignSections';
import { Input } from '@/components/plate-ui/input';
import { useLoyaltyCampaignReorder } from '../../hooks/useLoyaltyCampaignReorder';
import { useLoyaltyCampaign } from '../../hooks/useLoyaltyCampaign';
import Spinner from '@/components/element/loading/Spinner';

const LoyaltySection = ({
	children,
	title,
	_id,
	addItem,
	isDragging,
	attributes,
	setNodeRef,
	transition,
	transform,
}) => {
	const { campaign } = useLoyaltyCampaign();
	const [isEditing, setIsEditing] = useState(false);
	const { deleteSection, editSection } = useLoyaltyCampaignSections({});
	const [name, setName] = useState(title);
	const [isOpen, setIsOpen] = useState(true);
	const { moveSectionDown, moveSectionUp, isMoving } = useLoyaltyCampaignReorder();
	const sectionIndex = campaign?.taskSections?.findIndex(
		(section_) => section_._id === _id,
	);

	return (
		<Card
			className={`shadow-none p-0 rounded-lg mb-10 ${
				isDragging && 'opacity-50'
			}`}
			{...attributes}
			ref={setNodeRef}
			style={{
				transition,
				transform: CSS.Translate.toString(transform),
			}}
		>
			<CardHeader
				className="p-0 flex flex-row border-b items-center space-y-0"
				// {...listeners}
			>
				<div
					className="w-[50px] flex items-center justify-center"
					onClick={() => setIsOpen((p) => !p)}
				>
					<i
						className={`text-muted-foreground text-sm text-purple-600 bi-chevron-down ${
							!isOpen ? '' : 'transform rotate-180'
						}`}
					></i>
				</div>
				<div className="bg-slate-100 dark:bg-slate-900 border-s py-0 px-4 rounded-tr-lg w-full mt-0 flex items-center justify-between cursor-auto">
					<div className="flex-grow flex  items-center">
						<Input
							variant="ghost"
							className="text-sm font-medium py-4 border-e pe-4 me-3 border-slate-300 max-w-[200px] "
							value={name}
							onChange={(e) => {
								console.log(e);
								setName(e.target.value);
							}}
							disabled={!isEditing}
						/>
						{isEditing ? (
							<>
								<i
									className="text-xs  bi-check-circle-fill  me-3 cursor-pointer"
									onClick={() => {
										editSection(_id, { title: name });
										setIsEditing(false);
									}}
								></i>
							</>
						) : (
							<>
								<i
									className="text-xs  bi-pencil  me-3 cursor-pointer"
									onClick={() => setIsEditing(true)}
								></i>
								<i
									className="text-xs  bi-trash cursor-pointer"
									onClick={() => deleteSection(_id)}
								></i>
							</>
						)}
					</div>
					<div className="flex space-x-2">
						<>
							<Button
								size="icon"
								variant="outline"
								className="h-7 w-7"
								onClick={() => moveSectionUp(_id)}
								disabled={
									isMoving?.down ||
									isMoving?.up ||
									sectionIndex === 0
								}
							>
								{isMoving?.up ? (
									<Spinner size="sm" />
								) : (
									<i className="bi-chevron-up"></i>
								)}
							</Button>
							<Button
								size="icon"
								variant="outline"
								className="h-7 w-7"
								onClick={() => moveSectionDown(_id)}
								disabled={
									isMoving?.down ||
									isMoving?.up ||
									sectionIndex ===
										campaign?.taskSections.length - 1
								}
							>
								{isMoving?.down ? (
									<Spinner size="sm" />
								) : (
									<i className="bi-chevron-down"></i>
								)}
							</Button>
						</>
					</div>
				</div>
			</CardHeader>
			<AnimateHeight duration={500} height={isOpen ? 'auto' : 0}>
				<CardContent className="p-0 ">{children}</CardContent>
				<CardFooter className="p-0 flex flex-row border-t items-center space-y-0">
					<div className="w-[50px] flex items-center justify-center"></div>
					<div className="flex justify-between items-center py-1">
						<Button onClick={() => addItem(_id)} variant="link">
							+ New Task
						</Button>
					</div>
				</CardFooter>
			</AnimateHeight>
		</Card>
	);
};

export default LoyaltySection;
