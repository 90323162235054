import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@/components/ui/alert-dialog';

export interface ConfirmOptions {
	title?: string;
	subtitle?: string;
	confirmText?: string;
	cancelText?: string;
	onConfirm?: () => void;
	onCancel?: () => void;
	type?: 'success' | 'error' | 'warning' | 'info';
	setOpen?: (open: boolean) => void;
}

type Props = ConfirmOptions & { open: boolean };

export function ConfirmDialog({
	open,
	title,
	subtitle,
	cancelText = 'Cancel',
	confirmText = 'Continue',
	onCancel,
	onConfirm,
	type = 'warning',
	setOpen,
}: Props) {
	return (
		<AlertDialog open={open}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>{title}</AlertDialogTitle>
					<AlertDialogDescription>{subtitle}</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel onClick={onCancel}>
						{cancelText}
					</AlertDialogCancel>
					<AlertDialogAction onClick={onConfirm}>
						{confirmText}
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
}
