import { axios } from '@/lib/axios';

export const cancelInvite = async (invitationId: string) => {
	const response = await axios.delete(`/enterprise/team/invite/${invitationId}`);
	return response.data;
};

export const resendInvite = async (invitationId: string) => {
	const response = await axios.put(`/enterprise/team/invite/${invitationId}`);
	return response.data;
};

export const getTeamData = async () => {
	try {
		const response = await axios.get('/enterprise/team/all');
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const addEnterpriseUser = async (data) => {
	const response = await axios.post('/enterprise/team/invite', data);
	return response.data;
};

export const deleteEnterpriseUser = async (userId) => {
	const response = await axios.delete(`/enterprise/team/${userId}`);
	return response.data;
};

export const getExportRequests = async () => {
	const response = await axios.get(`/profile/export-requests`);
	return response.data;
};
