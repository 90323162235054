import { useQuery } from '@tanstack/react-query';
import { fetchAllQueries } from '../../services/dave.service';
import { IDaveQueries } from '../../types/dave.types';

export const useGetQueries = (search: string) => {
	const { data, isLoading } = useQuery<IDaveQueries[]>({
		queryFn: fetchAllQueries,
		queryKey: ['dave', 'queries'],
	});

	return {
		queries:
			data?.filter((query) => {
				if (search) {
					return query.name.toLowerCase().includes(search.toLowerCase());
				}
				return true;
			}) ?? [],
		isLoading,
		hasQueries: data?.length > 0,
	};
};
