import { ResponsiveChoropleth } from '@nivo/geo';
import worldMap from '@/assets/worldmap.json';

interface GeoChartProps {
	data?: any;
	height?: string;
}

const GeoChart = ({ height, data }: GeoChartProps) => {
	return (
		<div style={{ height: height ?? '500px', width: '100%' }}>
			<ResponsiveChoropleth
				data={data}
				features={worldMap.features}
				margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
				colors={[
					'rgba(0,0,0,0.8)',
					'#4F4686',
					'#574D93',
					'#766CB2',
					'#9C94C7',
					'#C1BCDC',
				]}
				domain={[0, Math.max(...data.map((d: any) => d.value))]}
				unknownColor="#666666"
				label="properties.name"
				valueFormat=".2s"
				projectionTranslation={[0.5, 0.5]}
				projectionRotation={[0, 0, 0]}
				enableGraticule={true}
				graticuleLineColor="#dddddd"
				borderWidth={0.5}
				borderColor="#152538"
				legends={[
					{
						anchor: 'bottom-left',
						direction: 'column',
						justify: true,
						translateX: 20,
						translateY: -100,
						itemsSpacing: 0,
						itemWidth: 94,
						itemHeight: 18,
						itemDirection: 'left-to-right',
						itemTextColor: '#444444',
						itemOpacity: 0.85,
						symbolSize: 18,
						effects: [
							{
								on: 'hover',
								style: {
									itemTextColor: '#000000',
									itemOpacity: 1,
								},
							},
						],
					},
				]}
			/>
		</div>
	);
};

export default GeoChart;

const data = [
	{
		id: 'AFG',
		value: 541817,
	},
	{
		id: 'AGO',
		value: 98355,
	},
	{
		id: 'ALB',
		value: 182469,
	},
	{
		id: 'ARE',
		value: 237716,
	},
	{
		id: 'ARG',
		value: 139650,
	},
	{
		id: 'ARM',
		value: 495363,
	},
	{
		id: 'ATA',
		value: 522888,
	},
	{
		id: 'ATF',
		value: 275112,
	},
	{
		id: 'AUT',
		value: 856764,
	},
	{
		id: 'AZE',
		value: 542955,
	},
	{
		id: 'BDI',
		value: 105938,
	},
	{
		id: 'BEL',
		value: 853047,
	},
	{
		id: 'BEN',
		value: 244429,
	},
	{
		id: 'BFA',
		value: 443594,
	},
	{
		id: 'BGD',
		value: 311080,
	},
	{
		id: 'BGR',
		value: 527398,
	},
	{
		id: 'BHS',
		value: 749117,
	},
	{
		id: 'BIH',
		value: 694763,
	},
	{
		id: 'BLR',
		value: 458714,
	},
	{
		id: 'BLZ',
		value: 241044,
	},
	{
		id: 'BOL',
		value: 119139,
	},
	{
		id: 'BRN',
		value: 928788,
	},
	{
		id: 'BTN',
		value: 106228,
	},
	{
		id: 'BWA',
		value: 323725,
	},
	{
		id: 'CAF',
		value: 894807,
	},
	{
		id: 'CAN',
		value: 45220,
	},
	{
		id: 'CHE',
		value: 781268,
	},
	{
		id: 'CHL',
		value: 589763,
	},
	{
		id: 'CHN',
		value: 98677,
	},
	{
		id: 'CIV',
		value: 176622,
	},
	{
		id: 'CMR',
		value: 133026,
	},
	{
		id: 'COG',
		value: 524848,
	},
	{
		id: 'COL',
		value: 979322,
	},
	{
		id: 'CRI',
		value: 44776,
	},
	{
		id: 'CUB',
		value: 182031,
	},
	{
		id: '-99',
		value: 818437,
	},
	{
		id: 'CYP',
		value: 241867,
	},
	{
		id: 'CZE',
		value: 152261,
	},
	{
		id: 'DEU',
		value: 159972,
	},
	{
		id: 'DJI',
		value: 3513,
	},
	{
		id: 'DNK',
		value: 384578,
	},
	{
		id: 'DOM',
		value: 60098,
	},
	{
		id: 'DZA',
		value: 620616,
	},
	{
		id: 'ECU',
		value: 10988,
	},
	{
		id: 'EGY',
		value: 863676,
	},
	{
		id: 'ERI',
		value: 825174,
	},
	{
		id: 'ESP',
		value: 691669,
	},
	{
		id: 'EST',
		value: 550459,
	},
	{
		id: 'ETH',
		value: 596977,
	},
	{
		id: 'FIN',
		value: 525868,
	},
	{
		id: 'FJI',
		value: 488249,
	},
	{
		id: 'FLK',
		value: 934033,
	},
	{
		id: 'FRA',
		value: 221660,
	},
	{
		id: 'GAB',
		value: 708000,
	},
	{
		id: 'GBR',
		value: 458786,
	},
	{
		id: 'GEO',
		value: 357108,
	},
	{
		id: 'GHA',
		value: 104155,
	},
	{
		id: 'GIN',
		value: 737994,
	},
	{
		id: 'GMB',
		value: 884708,
	},
	{
		id: 'GNB',
		value: 331486,
	},
	{
		id: 'GNQ',
		value: 858887,
	},
	{
		id: 'GRC',
		value: 28706,
	},
	{
		id: 'GTM',
		value: 365376,
	},
	{
		id: 'GUY',
		value: 461666,
	},
	{
		id: 'HND',
		value: 471787,
	},
	{
		id: 'HRV',
		value: 528119,
	},
	{
		id: 'HTI',
		value: 560233,
	},
	{
		id: 'HUN',
		value: 548734,
	},
	{
		id: 'IDN',
		value: 158806,
	},
	{
		id: 'IND',
		value: 640017,
	},
	{
		id: 'IRL',
		value: 873428,
	},
	{
		id: 'IRN',
		value: 428793,
	},
	{
		id: 'IRQ',
		value: 499167,
	},
	{
		id: 'ISL',
		value: 978705,
	},
	{
		id: 'ISR',
		value: 862929,
	},
	{
		id: 'ITA',
		value: 438543,
	},
	{
		id: 'JAM',
		value: 833615,
	},
	{
		id: 'JOR',
		value: 798438,
	},
	{
		id: 'JPN',
		value: 625951,
	},
	{
		id: 'KAZ',
		value: 570683,
	},
	{
		id: 'KEN',
		value: 895242,
	},
	{
		id: 'KGZ',
		value: 2780,
	},
	{
		id: 'KHM',
		value: 633712,
	},
	{
		id: 'OSA',
		value: 448232,
	},
	{
		id: 'KWT',
		value: 538462,
	},
	{
		id: 'LAO',
		value: 535756,
	},
	{
		id: 'LBN',
		value: 414465,
	},
	{
		id: 'LBR',
		value: 280696,
	},
	{
		id: 'LBY',
		value: 371945,
	},
	{
		id: 'LKA',
		value: 189634,
	},
	{
		id: 'LSO',
		value: 569990,
	},
	{
		id: 'LTU',
		value: 92550,
	},
	{
		id: 'LUX',
		value: 171310,
	},
	{
		id: 'LVA',
		value: 154977,
	},
	{
		id: 'MAR',
		value: 516864,
	},
	{
		id: 'MDA',
		value: 827524,
	},
	{
		id: 'MDG',
		value: 831084,
	},
	{
		id: 'MEX',
		value: 991475,
	},
	{
		id: 'MKD',
		value: 773748,
	},
	{
		id: 'MLI',
		value: 190223,
	},
	{
		id: 'MMR',
		value: 228255,
	},
	{
		id: 'MNE',
		value: 132250,
	},
	{
		id: 'MNG',
		value: 416537,
	},
	{
		id: 'MOZ',
		value: 548351,
	},
	{
		id: 'MRT',
		value: 587987,
	},
	{
		id: 'MWI',
		value: 675938,
	},
	{
		id: 'MYS',
		value: 826357,
	},
	{
		id: 'NAM',
		value: 453627,
	},
	{
		id: 'NCL',
		value: 455006,
	},
	{
		id: 'NER',
		value: 261964,
	},
	{
		id: 'NGA',
		value: 401158,
	},
	{
		id: 'NIC',
		value: 612657,
	},
	{
		id: 'NLD',
		value: 549322,
	},
	{
		id: 'NOR',
		value: 609147,
	},
	{
		id: 'NPL',
		value: 731451,
	},
	{
		id: 'NZL',
		value: 365343,
	},
	{
		id: 'OMN',
		value: 765540,
	},
	{
		id: 'PAK',
		value: 646829,
	},
	{
		id: 'PAN',
		value: 934238,
	},
	{
		id: 'PER',
		value: 710316,
	},
	{
		id: 'PHL',
		value: 261880,
	},
	{
		id: 'PNG',
		value: 564242,
	},
	{
		id: 'POL',
		value: 142804,
	},
	{
		id: 'PRI',
		value: 971548,
	},
	{
		id: 'PRT',
		value: 547706,
	},
	{
		id: 'PRY',
		value: 433558,
	},
	{
		id: 'QAT',
		value: 282678,
	},
	{
		id: 'ROU',
		value: 687947,
	},
	{
		id: 'RUS',
		value: 661312,
	},
	{
		id: 'RWA',
		value: 962809,
	},
	{
		id: 'ESH',
		value: 389639,
	},
	{
		id: 'SAU',
		value: 661753,
	},
	{
		id: 'SDN',
		value: 961832,
	},
	{
		id: 'SDS',
		value: 123363,
	},
	{
		id: 'SEN',
		value: 950064,
	},
	{
		id: 'SLB',
		value: 74755,
	},
	{
		id: 'SLE',
		value: 110739,
	},
	{
		id: 'SLV',
		value: 714071,
	},
	{
		id: 'ABV',
		value: 609393,
	},
	{
		id: 'SOM',
		value: 46185,
	},
	{
		id: 'SRB',
		value: 469531,
	},
	{
		id: 'SUR',
		value: 86800,
	},
	{
		id: 'SVK',
		value: 118509,
	},
	{
		id: 'SVN',
		value: 248502,
	},
	{
		id: 'SWZ',
		value: 968661,
	},
	{
		id: 'SYR',
		value: 111723,
	},
	{
		id: 'TCD',
		value: 316519,
	},
	{
		id: 'TGO',
		value: 559598,
	},
	{
		id: 'THA',
		value: 772846,
	},
	{
		id: 'TJK',
		value: 370937,
	},
	{
		id: 'TKM',
		value: 129651,
	},
	{
		id: 'TLS',
		value: 455895,
	},
	{
		id: 'TTO',
		value: 406188,
	},
	{
		id: 'TUN',
		value: 578902,
	},
	{
		id: 'TUR',
		value: 49440,
	},
	{
		id: 'TWN',
		value: 852763,
	},
	{
		id: 'TZA',
		value: 519861,
	},
	{
		id: 'UGA',
		value: 131584,
	},
	{
		id: 'UKR',
		value: 633790,
	},
	{
		id: 'URY',
		value: 190144,
	},
	{
		id: 'USA',
		value: 949665,
	},
	{
		id: 'UZB',
		value: 690187,
	},
	{
		id: 'VEN',
		value: 87683,
	},
	{
		id: 'VNM',
		value: 468106,
	},
	{
		id: 'VUT',
		value: 824076,
	},
	{
		id: 'PSE',
		value: 640429,
	},
	{
		id: 'YEM',
		value: 200695,
	},
	{
		id: 'ZAF',
		value: 524607,
	},
	{
		id: 'ZMB',
		value: 584139,
	},
	{
		id: 'ZWE',
		value: 143581,
	},
	{
		id: 'KOR',
		value: 664479,
	},
];
