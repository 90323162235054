import { useContext } from 'react';
import { LoyaltyTaskContext } from '../../providers/loyalty-tasks.provider';

export const useLoyaltyTasks = () => {
	const context = useContext(LoyaltyTaskContext);
	if (context === undefined) {
		throw new Error('useLoyaltyTasks must be used within a LoyaltyTaskProvider');
	}
	return context;
};
