import { useState } from 'react';
import { DaveQueryCreateSectionEnum } from '../types/dave.enum';
import { DaveQueryCreateProvider } from '../providers/DaveQueryCreateProvider';
import QueryActionFilters from '../components/create-queries/QueryActionFilters';
import QueryTesting from '../components/create-queries/QueryTesting';
import DaveCreateQueryLayout from '../components/create-queries/layout/DaveCreateQueryLayout';

const DaveQueryCreatePage = () => {
	const [section, setSection] = useState<DaveQueryCreateSectionEnum>(
		DaveQueryCreateSectionEnum.Filters,
	);

	return (
		<DaveQueryCreateProvider section={section} setSection={setSection}>
			<DaveCreateQueryLayout>
				{/* {section === DaveQueryCreateSectionEnum.Testing && (
					<div>
						<div className="flex justify-center items-center pt-10">
							<div className="mb-5 w-[600px] relative">
								<QueryTesting />
							</div>
						</div>
					</div>
				)} */}
				{section === DaveQueryCreateSectionEnum.Filters && (
					<QueryActionFilters />
				)}
			</DaveCreateQueryLayout>
		</DaveQueryCreateProvider>
	);
};

export default DaveQueryCreatePage;
