import Spinner from '@/components/element/loading/Spinner';
import { Button } from '@/components/ui/button';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import { useCreateCampaignNavigation } from '@/features/campaigns/hooks/create/useCreateCampaignNavigation';
import { CreateCampaignSections } from '@/features/campaigns/types';

const CampaignCreateNavigation = () => {
	const { section } = useCreateCampaign();
	const {
		moveForward,
		moveBack,
		saveDraft,
		isLoading,
		launchCampaign,
		testCampaign,
	} = useCreateCampaignNavigation();

	return (
		<div className="flex justify-between items-center  border-t p-3 pb-3 px-4 relative">
			<div className="space-x-4 flex items-center">
				{section !== CreateCampaignSections.Details && (
					<Button size="icon" variant="outline" onClick={moveBack}>
						<i className="bi bi-chevron-left"></i>
					</Button>
				)}
				<Button
					variant="outline"
					className="min-w-[110px]"
					onClick={() => saveDraft(false)}
					disabled={isLoading.draft}
				>
					{isLoading.draft && <Spinner className="me-2" />}
					<span>Save</span>
				</Button>
				<Button
					variant="outline"
					className="min-w-[110px]"
					onClick={() => saveDraft(true)}
					disabled={isLoading.preview}
				>
					{isLoading.preview && <Spinner className="me-2" />}
					<span>Preview</span>
				</Button>
			</div>
			<div className="absolute left-0 right-0 mx-auto w-[400px]">
				<div className="text-xs"></div>
			</div>
			<div className="flex justify-end space-x-4">
				{section === CreateCampaignSections.Rewards ? (
					<div className="space-x-4">
						<Button
							className=" px-10"
							variant="outline"
							onClick={launchCampaign}
							disabled={isLoading.launch}
						>
							{isLoading.launch && <Spinner className="me-2" />}
							<span>Directly Launch</span>
						</Button>
						<Button
							className=" px-10"
							onClick={testCampaign}
							disabled={isLoading.test}
						>
							{isLoading.test && <Spinner className="me-2" />}
							<span>Test Campaign</span>
						</Button>
					</div>
				) : (
					<Button className=" px-10" onClick={moveForward}>
						<span>Next</span>
						<i className="bi bi-arrow-right ms-2"></i>
					</Button>
				)}
			</div>
		</div>
	);
};

export default CampaignCreateNavigation;
