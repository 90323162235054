import { Navigate, Route, Routes } from 'react-router-dom';
import { AppError } from '@/components/layout/error/SentryErrorFallback';
import * as Sentry from '@sentry/react';
import HomePage from './home.page';
import EnterprisePage from './enterprise.page';
import CustomersPage from './customers.page';
import AboutPage from './about.page';
import BlogsPage from './blogs.page';
import CareersPage from './careers.page';
import AdOptimisedSignUpPage from './ads.page';
import FeaturesPage from './features.page';

export default function LandingRoutes() {
	return (
		<Sentry.ErrorBoundary fallback={AppError} showDialog>
			<Routes>
				<Route path="" element={<HomePage />} />
				<Route path="enterprise" element={<EnterprisePage />} />
				<Route path="customers" element={<CustomersPage />} />
				<Route path="features" element={<FeaturesPage />} />
				<Route path="careers" element={<CareersPage />} />
				<Route path="about" element={<AboutPage />} />
				<Route path="blogs" element={<BlogsPage />} />
				<Route path="ppc/*" element={<AdOptimisedSignUpPage />} />
				<Route path="*" element={<Navigate replace to="/" />} />
			</Routes>
		</Sentry.ErrorBoundary>
	);
}
