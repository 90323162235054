import { useState } from 'react';
import { forgotPassword, resetPassword } from '../services/auth.service';
import { toast } from 'sonner';
import { ErrorTypes } from '@/utils/notifications';
import { useRouter } from '@/hooks/useRouter';

export const useResetPassword = (token: string) => {
	const { navigate } = useRouter();
	const [formFields, setFormFields] = useState({
		password: '',
		confirmPassword: '',
	});
	const [isLoading, setIsLoading] = useState(false);
	const [formErrors, setFormErrors] = useState<any>({});

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		try {
			setIsLoading(true);
			const error: any = {};

			if (!formFields.password || !formFields.password.trim()) {
				error.password = 'Password is required';
			}
			if (!formFields.confirmPassword || !formFields.confirmPassword.trim()) {
				error.confirmPassword = 'Confirm password is required';
			}
			if (formFields.password !== formFields.confirmPassword) {
				error.confirmPassword = 'Passwords do not match';
			}
			if (formFields.password.length < 8) {
				error.password = 'Password must be at least 8 characters';
			}

			if (Object.keys(error).length > 0) {
				setFormErrors(error);
				return;
			}

			await resetPassword({
				password: formFields.password,
				token: token,
			});
			toast.success(
				'Password successfully reset. You can now login with your new password',
			);
			setTimeout(() => {
				navigate('/login');
			}, 300);
		} catch (err) {
			console.log(err);
			if (err.response.data.code === ErrorTypes.InvalidToken) {
				toast.error(
					'This token seems invalid, are you sure you copied the link correctly?',
				);
				navigate('/login');
				return;
			}
			// toast.error(err.message);
		} finally {
			setIsLoading(false);
		}
	};
	return {
		handleSubmit,
		isLoading,
		setIsLoading,
		formErrors,
		formFields,
		setFormFields,
		setFormErrors,
	};
};

export default useResetPassword;
