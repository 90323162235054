import { Button } from '@/components/ui/button';
import Spinner from '@/components/element/loading/Spinner';
import { useCampaignSubmissionsApprovals } from '@/features/campaigns/hooks/report/useCampaignSubmissionsApprovals';
import { IQuestPOWSubmissions, POWVerifyStatus } from '@/features/campaigns/types';

export const SubmissionsFloatingContent = ({
	selectedTaskId,
	table,
	queryKey,
	setCursor,
	campaignId,
}) => {
	const { handleReviewSelected, isActionLoading } =
		useCampaignSubmissionsApprovals(queryKey, campaignId);

	const selected = table?.getFilteredSelectedRowModel().rows?.map((i) => ({
		status: i.original?.status,
		_id: i.original?._id,
	}));
	return (
		<div className="flex items-center space-x-2 ms-10">
			<Button
				variant="secondary"
				size="sm"
				onClick={async () => {
					await handleReviewSelected(selectedTaskId, selected, true);
					table?.toggleAllRowsSelected(false);
				}}
				disabled={
					Object.values(isActionLoading).some((i) => i) ||
					selected?.filter((i) => i.status === POWVerifyStatus.Verified)
						?.length > 0
				}
			>
				{isActionLoading.approvalSelected && <Spinner className="me-2" />}
				<i className="bi bi-plus-circle me-2"></i>
				<span>Approve</span>
			</Button>
			<Button
				variant="secondary"
				size="sm"
				onClick={async () => {
					await handleReviewSelected(selectedTaskId, selected, false);
					table?.toggleAllRowsSelected(false);
				}}
				disabled={
					Object.values(isActionLoading).some((i) => i) ||
					selected?.filter((i) => i.status === POWVerifyStatus.Verified)
						?.length > 0
				}
			>
				{isActionLoading.rejectSelected && <Spinner className="me-2" />}
				<i className="bi bi-plus-circle me-2"></i>
				<span>Reject</span>
			</Button>
		</div>
	);
};
