import { isAddress } from 'web3-validator';

export const validateEVMAddress = (address: string) => {
	return isAddress(address);
};

export const validateContractABI = (abi: string) => {
	if (!abi) return false;
	if (abi === '[]') return false;
	try {
		const parsedABI = JSON.parse(abi);
		if (Array.isArray(parsedABI)) return true;
	} catch (err) {
		return false;
	}
};

export const validateTransactionHash = (hash: string) => {
	const re = /^0x[a-fA-F0-9]{64}$/;
	return re.test(hash);
};

export const validateString = (value: string) => {
	return value && value.length > 0;
};

export const validateDuration = (startDate: Date, endDate: Date) => {
	if (!startDate || !endDate) return false;
	return startDate < endDate;
};

export const validatePositiveNumber = (value: number) => {
	return value && !isNaN(value) && value > 0;
};
