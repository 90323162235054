import { axios } from '@/lib/axios';
import { IEvent } from '../types/events.type';

export const getEvents = async (): Promise<IEvent[]> => {
	const response = await axios.get('/events');
	return response.data as IEvent[];
};

export const getEvent = async (id: string) => {
	const response = await axios.get(`/events/${id}`);
	return response.data;
};

export const getEventReport = async (id: string) => {
	const response = await axios.get(`/events/${id}/report`);
	return response.data;
};

export const upsertEvent = async (event: any): Promise<IEvent> => {
	const response = await axios.post('/events/upsert', event);
	return response.data as IEvent;
};

export const getEventSlug = async (name: string): Promise<string> => {
	const response = await axios.get(`/events/slug?name=${name}`);
	return response.data as string;
};
