import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import dayjs from 'dayjs';
import { useState } from 'react';
import QuestMetadataUpdate from '../updates/QuestMetadataUpdate';
import QuestBannerUpdate from '../updates/QuestBannerUpdate';
import ChainBadge from '@/components/element/badges/ChainBadge';

const QuestDetails = () => {
	const [showEdit, setShowEdit] = useState(false);
	const [showUpdateBanner, setShowUpdateBanner] = useState(false);
	const { campaign } = useCampaignReport();

	const chainId = +(
		campaign.tags.find((tag) => tag.key === 'chainId')?.value || 0
	);

	return (
		<>
			<Card className="col-span-2 relative ">
				<CardContent className="flex justify-between  p-6 h-100 gap-10">
					<div className="h-100 flex flex-col relative w-5/12">
						<div className="absolute top-0 end-0 ">
							<Button
								size="icon"
								variant="outline"
								onClick={() => setShowEdit(true)}
							>
								<i className="bi-pencil-square"></i>
							</Button>
						</div>
						<div className="flex items-center text-sm  mb-6">
							<div className="">
								<div className="text-xs text-muted-foreground font-medium">
									Name
								</div>
								<div className="font-semibold">{campaign.name}</div>
							</div>
						</div>
						<div className="flex items-center text-sm  mb-6">
							<div className="">
								<div className="text-xs text-muted-foreground font-medium">
									Description
								</div>
								<div
									className="font-medium line-clamp-6"
									dangerouslySetInnerHTML={{
										__html: campaign?.description,
									}}
								></div>
							</div>
						</div>
						<div className="flex items-center text-sm  mb-6">
							<div className="">
								<div className="text-xs text-muted-foreground font-medium">
									Network
								</div>
								<ChainBadge chainId={chainId} />
							</div>
						</div>
						<div className="flex items-center text-sm  mb-6">
							<div className="">
								<div className="text-xs text-muted-foreground font-medium">
									Duration
								</div>
								<div className="font-semibold">
									{dayjs(campaign.startDate).format('DD MMM YYYY')}{' '}
									-{' '}
									{campaign.noEndTime
										? 'No Deadline'
										: dayjs(campaign.endDate).format(
												'DD MMM YYYY',
											)}
								</div>
							</div>
						</div>
					</div>
					<div className=" relative w-7/12">
						<div
							className="w-full aspect-[1920/1080] border rounded-lg bg-slate-50 relative"
							style={{
								backgroundPosition: `${campaign.bannerPosition?.x}% ${campaign.bannerPosition?.y}%`,
								backgroundImage: `url(${campaign.banner})`,
								backgroundSize: 'cover',
							}}
						>
							<div className="absolute bottom-0 end-0 pb-4 pe-3">
								<Button
									variant="outline"
									onClick={() => setShowUpdateBanner(true)}
									size="sm"
								>
									<i className="bi-pencil-square me-2"></i> Update
									Banner
								</Button>
							</div>
						</div>
					</div>
				</CardContent>
			</Card>
			<QuestMetadataUpdate open={showEdit} setOpen={setShowEdit} />
			<QuestBannerUpdate
				open={showUpdateBanner}
				setOpen={setShowUpdateBanner}
			/>
		</>
	);
};

export default QuestDetails;
