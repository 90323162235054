import { Badge } from '@/components/ui/badge';
import { useGetProjects } from '../hooks/useGetProjects';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import ProjectCard from '../components/ProjectCard';
import PartnershipFilters from '../components/PartnershipFilters';
import Spinner from '@/components/element/loading/Spinner';

export const PartnershipsRoutes = () => {
	const {
		data,
		isFetchingNextPage,
		ref,
		search,
		setSearch,
		filters,
		setFilters,
		isFetching,
	} = useGetProjects();
	if (!isFetchingNextPage && !data) return <FullPageLoading />;
	return (
		<div>
			<Badge className="rounded-full px-3" variant="outline">
				<i className="bi-stars me-1 text-yellow-600"></i>
				Build your Hype Tribe
			</Badge>
			<div className="text-2xl font-medium tracking-tight mt-4">
				Find your Partners
			</div>
			<div className="text-md  mt-2">
				You need a
				<span className="px-1 text-violet-600 font-medium">
					right partner
				</span>{' '}
				to reach the world
			</div>
			<PartnershipFilters
				search={search}
				filters={filters}
				setSearch={setSearch}
				setFilters={setFilters}
			/>
			<div className="grid lg:grid-cols-3 2xl:grid-cols-4 gap-5 mt-8">
				{data?.length > 0
					? data?.map((project) => <ProjectCard project={project} />)
					: !isFetching && (
							<div className="text-sm">No projects found</div>
						)}
			</div>
			<div ref={ref}></div>
			<div className="flex w-full items-center mt-5">
				{(isFetchingNextPage || isFetching) && <Spinner />}
			</div>
		</div>
	);
};
