import { axios } from '@/lib/axios';

export const uploadFile = async (file: any) => {
	const formData = new FormData();
	formData.append('file', file);
	const response = await axios.post('/utility/upload-file', formData);
	return response.data[0].location;
};

export const getSupportedChains = async () => {
	const res = await axios.get('/enterprise/chains');
	return res.data;
};

export const getSupportedContracts = async (
	chainId: number,
	contractType: string,
) => {
	const res = await axios.get(
		`/utility/contracts?chainId=${chainId}&contractType=${contractType}`,
	);
	return res.data;
};

export const getSupportedTokens = async () => {
	const res = await axios.get('/enterprise/tokens');
	return res.data;
};

export const getSupportedTokensForChain = async (chainId: number) => {
	const res = await axios.get('/enterprise/tokens/approved', {
		params: {
			chainId,
		},
	});
	return res.data;
};

export const importToken = async (body: any) => {
	const res = await axios.post('/enterprise/tokens', body);
	return res.data;
};

export const getTokenDetails = async (body: any) => {
	const res = await axios.post('/utility/token-details', body);
	return res.data;
};
