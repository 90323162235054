import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { IAdminEnterprises } from '../../types/admin.types';
import TabOptions from '@/components/element/tabs/TabOptions';
import { useAdminEnterpriseApproval } from '../../hooks/useAdminEnterpriseApproval';
import { Sheet, SheetContent, SheetFooter } from '@/components/ui/sheet';
import {
	ApprovalData,
	EnterpriseCampaigns,
	EnterpriseFeatureUsage,
	SimilarEnterprises,
} from './EnterpriseDetails';
import EnterpriseApproval from './EnterpriseApproval';
import Spinner from '@/components/element/loading/Spinner';

const EnterpriseApprovalDialog = ({
	open,
	setOpen,
	enterprise,
	refetch,
}: {
	open: boolean;
	setOpen: any;
	refetch: any;
	enterprise: IAdminEnterprises;
}) => {
	const {
		updateStatus,
		setDetails,
		selected,
		errors,
		setSelected,
		isLoading,
		details,
		enterpriseData,
		isEnterpriseLoading,
		enterpriseFeatureUsage,
		isEnterpriseFeatureLoading,
		similarEnterprises,
		isSimilarEnterpriseLoading,
	} = useAdminEnterpriseApproval({
		enterpriseId: enterprise?._id,
		refetch: refetch,
	});

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetContent className="min-w-[550px] justify-between flex flex-col p-4 overflow-y-auto ">
				<div>
					<div className="border-b pb-3">
						<div>
							<DialogTitle>
								Update Enterprise Approval Status
							</DialogTitle>
							<div className="hover:underline mt-3 cursor-pointer text-muted-foreground text-sm flex items-center">
								<i className="bi bi-box-arrow-up-right me-2"></i>
								Community Hub Link
							</div>
						</div>
					</div>
					<TabOptions
						options={[
							{ label: 'Approval Data', value: 'approvalData' },
							{ label: 'Campaigns', value: 'campaigns' },
							{ label: 'Feature Usage', value: 'featureUsage' },
							{
								label: 'Similar Projects',
								value: 'similarEnterprises',
							},
						]}
						selected={selected}
						setSelected={setSelected}
						className="mt-6"
					/>
					<div className="mt-10 mb-8">
						{selected === 'approvalData' && (
							<ApprovalData
								enterprise={enterpriseData}
								isLoading={isEnterpriseLoading}
							/>
						)}
						{selected === 'campaigns' && (
							<EnterpriseCampaigns
								enterprise={enterpriseData}
								isLoading={isEnterpriseLoading}
							/>
						)}
						{selected === 'featureUsage' && (
							<EnterpriseFeatureUsage
								enterpriseFeatureUsage={enterpriseFeatureUsage}
								isLoading={isEnterpriseFeatureLoading}
							/>
						)}
						{selected === 'similarEnterprises' && (
							<SimilarEnterprises
								similarEnterprises={similarEnterprises}
								isLoading={isSimilarEnterpriseLoading}
							/>
						)}
						{selected === 'approval' && (
							<EnterpriseApproval
								details={details}
								setDetails={setDetails}
								errors={errors}
							/>
						)}
					</div>
				</div>
				<div
					className={`justify-start space-x-2 border-t pt-3 flex sm:justify-between mt-auto pe-20`}
				>
					<div className="flex gap-2">
						{selected !== 'approval' && (
							<Button
								onClick={() => setSelected('approval')}
								disabled={isLoading}
							>
								Update Approval Status
							</Button>
						)}
						{selected === 'approval' && (
							<Button
								onClick={() => updateStatus()}
								disabled={isLoading}
							>
								{isLoading && <Spinner className="me-2" />}
								Save Approval Status
							</Button>
						)}
						<Button variant="ghost" onClick={() => setOpen(false)}>
							Cancel
						</Button>
					</div>
				</div>
			</SheetContent>
		</Sheet>
	);
};

export default EnterpriseApprovalDialog;
