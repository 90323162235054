import React from 'react';
import LandingContainer from '../ui/LandingContainer';
import { careersContent } from '../../content/careers.content';

const WhyIntract = () => {
	return (
		<LandingContainer>
			<div className="relative  space-y-16 md:space-y-32 ">
				<div className="careers-scroll">
					<div className="relative pb-0 pt-0">
						<div className="careers- mx-auto  flex w-full max-w-[1280px] flex-col justify-between border-none border-red-500/10 md:mt-0 md:aspect-[1280/500] md:flex-wrap md:gap-12 xl:aspect-[1280/799] xl:gap-[125px]">
							<div className="w-full overflow-hidden rounded-xl  self-start md:max-w-[40%]  xl:max-w-[575px]">
								<img
									alt="Team 4"
									loading="lazy"
									width={1725}
									height={1152}
									decoding="async"
									data-nimg={1}
									className="transition-opacity w-full opacity-100"
									style={{ color: 'transparent' }}
									src={careersContent.about.img1}
								/>
							</div>

							<div className="w-full overflow-hidden rounded-xl xl:rounded-r-none w-full self-start md:max-w-[40%] lg:mt-40 xl:max-w-[575px]">
								<img
									alt="Team 5"
									loading="lazy"
									width={1977}
									height={1698}
									decoding="async"
									data-nimg={1}
									className="transition-opacity w-full opacity-100"
									style={{ color: 'transparent' }}
									src={careersContent.about.img2}
								/>
							</div>
						</div>
						<div className="flex flex-col items-start gap-12 pt-0 md:gap-24">
							<h1 className="text-7xl  font-medium w-full text-zinc-900 md:max-w-[750px]">
								{careersContent.about.title}
							</h1>
							<div
								className="flex w-full max-w-[736px] flex-col gap-16 self-end"
								style={{ opacity: 1, transform: 'none' }}
							>
								<img
									alt="Team 6"
									loading="lazy"
									width={2208}
									height={1374}
									decoding="async"
									data-nimg={1}
									className="transition-opacity aspect-[736/458] w-full max-w-[736px] rounded-xl opacity-100"
									style={{ color: 'transparent' }}
									src={careersContent.about.img3}
								/>
								<div className="flex w-full flex-col">
									<div className="text-card items-center text-center justify-center md:items-start md:text-left md:justify-start">
										<div className="flex flex-col items-center text-center justify-center md:items-start md:text-left md:justify-start">
											<div className="flex flex-col space-y-4 md:space-y-7 items-center text-center justify-center md:items-start md:text-left md:justify-start">
												<div className="text-xl  text-zinc-900 w-full md:w-full">
													<p>
														{
															careersContent.about
																.description
														}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</LandingContainer>
	);
};

export default WhyIntract;
