import { Button } from '@/components/ui/button';
import clsx from 'clsx';
import Spinner from '../element/loading/Spinner';

const PageHeader = ({
	title,
	description,
	btnLabel,
	btnOnClick,
	icon,
	className,
	children,
	learn,
	btnLabel2,
	btnOnClick2,
}: {
	title: string;
	description?: string;
	btnLabel?: string;
	btnOnClick?: () => void;
	icon?: string;
	className?: string;
	children?: React.ReactNode;
	learn?: string;
	btnLabel2?: string;
	btnOnClick2?: () => void;
}) => {
	return (
		<div className={clsx('flex items-center justify-between', className)}>
			<div className="space-y-1">
				<h2 className="text-2xl font-medium tracking-tight">{title}</h2>
				{description && (
					<p className="text-sm text-muted-foreground max-w-[700px]">
						{description}
					</p>
				)}
			</div>
			<div className="ml-auto mr-4 space-x-2">
				{children}
				{btnLabel2 && !learn ? (
					<Button variant="outline" onClick={btnOnClick2} className="px-6">
						<i className="bi-question-circle me-2"></i>
						{btnLabel2}
					</Button>
				) : (
					<></>
				)}
				{learn ? (
					<Button
						onClick={() => {
							window.open(learn, '_blank');
						}}
						className="px-6"
						variant="outline"
					>
						<i className={`bi-info-circle me-2`}></i>
						Learn More
					</Button>
				) : btnLabel ? (
					<Button onClick={btnOnClick} className="px-6">
						{icon && <i className={`bi-${icon} me-2`}></i>}
						{btnLabel}
					</Button>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default PageHeader;
