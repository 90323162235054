import { useContext } from 'react';
import { CreateCampaignContext } from '../../providers/CreateCampaignProvider';

export const useCreateCampaign = () => {
	const context = useContext(CreateCampaignContext);

	if (context === undefined)
		throw new Error(
			'useCreateCampaign must be used within a CreateCampaignProvider',
		);

	return context;
};
