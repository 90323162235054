import { DataTable } from '@/components/element/data-table/DataTable';
import { actionsColumns } from './columns/ActionColumns';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { Button } from '@/components/ui/button';
import { useDeleteAction } from '../../hooks/actions/useDeleteAction';
import Spinner from '@/components/element/loading/Spinner';

const ActionsTable = ({ actions, isLoading }) => {
	const navigate = useNavigate();
	const { deleteActions } = useDeleteAction();
	const columns = actionsColumns([
		{
			type: 'button',
			label: 'Create Query',
			onClick: (row: any) => {
				navigate(`/app/tools/dave/query/new?actionId=${row._id}`);
			},
		},
		{
			type: 'button',
			label: 'Edit Action',
			onClick: (row: any) => {
				navigate(`/app/tools/dave/action/update/${row._id}`);
			},
		},
		{
			type: 'separator',
		},
		{
			type: 'button',
			label: 'Delete Action',
			onClick: (row: any) => {
				deleteActions([row._id]);
			},
		},
	]);
	const [rowSelection, setRowSelection] = useState({});

	return (
		<div className="mt-4">
			<DataTable
				data={actions ?? []}
				isLoading={isLoading}
				totalCount={actions?.length ?? 9}
				columns={columns}
				enableRowSelection
				onRowClick={(row) => {
					navigate(`/app/tools/dave/action/${row._id}`);
				}}
				rowSelection={rowSelection}
				setRowSelection={setRowSelection}
				FloatingBarContent={FloatingContent}
			/>
			{/* <DeleteActionsDialog
				actions={selectedRows.map((i: any) => i._id)}
				onSuccess={() => {}}
				open
			/> */}
		</div>
	);
};

export default ActionsTable;

const FloatingContent = ({ table }) => {
	const navigate = useNavigate();

	const { deleteActions, isLoading: isDeleteLoading } = useDeleteAction();

	return (
		<div className="flex items-center space-x-2 ms-10">
			<Button
				variant="secondary"
				size="sm"
				onClick={() => {
					const rows = table
						?.getFilteredSelectedRowModel()
						.rows?.map((i: any) => i.original?._id);
					navigate(
						`/app/tools/dave/query/new?actionId=${rows?.join(',')}`,
					);
				}}
			>
				<i className="bi bi-plus-circle me-2"></i>
				<span>Create Query</span>
			</Button>
			<Button
				variant="secondary"
				size="sm"
				onClick={() => {
					const rows = table
						?.getFilteredSelectedRowModel()
						.rows?.map((i: any) => i.original?._id);
					deleteActions(rows);
				}}
				disabled={isDeleteLoading}
			>
				{isDeleteLoading ? (
					<Spinner className="me-2" />
				) : (
					<i className="bi bi-trash me-2"></i>
				)}
				<span>Delete Actions</span>
			</Button>
		</div>
	);
};
