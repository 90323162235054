import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Row } from '@tanstack/react-table';

import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import TooltipWrapper from '../../tooltips/TooltipWrapper';

export function DataTableRowActions({
	row,
	options,
}: {
	row: any;
	options: {
		type: 'button' | 'separator';
		label?: string;
		icon?: string;
		onClick?: (row: Row<any>) => void;
		disabled?: boolean;
		tooltip?: string;
		show?: (row: Row<any>) => boolean;
	}[];
}) {
	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button
						variant="ghost"
						className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
					>
						<DotsHorizontalIcon className="h-4 w-4" />
						<span className="sr-only">Open menu</span>
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="end" className="w-[160px]">
					{options.map((option, index) => {
						if (option.show && !option.show(row.original)) {
							return <span></span>;
						}
						if (option.type === 'separator') {
							return <DropdownMenuSeparator key={index} />;
						}
						if (option.tooltip) {
							return (
								<TooltipWrapper tooltip={option.tooltip}>
									<DropdownMenuItem
										key={index}
										onClick={() => option.onClick(row.original)}
										disabled={option.disabled}
									>
										{option.icon && (
											<i
												className={`bi-${option.icon} me-2`}
											></i>
										)}
										<span>{option.label}</span>
									</DropdownMenuItem>
								</TooltipWrapper>
							);
						}
						return (
							<DropdownMenuItem
								key={index}
								onClick={() => option.onClick(row.original)}
								disabled={option.disabled}
							>
								{option.icon && (
									<i className={`bi-${option.icon} me-2`}></i>
								)}
								<span>{option.label}</span>
							</DropdownMenuItem>
						);
					})}
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	);
}
