import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Avatar } from '@/components/ui/avatar';
import { AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { truncateString } from '@/utils/parsers';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { IQuestPOWSubmissions } from '@/features/campaigns/types';
import { Checkbox } from '@/components/ui/checkbox';
import dayjs from 'dayjs';

interface IWinner {
	username: string;
	avatar: string;
	xp: number;
	twitter: string;
	discord: string;
	completedAt: Date;
}

const columnHelper = createColumnHelper<IWinner>();

export const winnersColumns = (selectedRow): ColumnDef<IWinner>[] =>
	[
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Name" className="" />
			),
			cell: ({ row }) => (
				<div className="flex items-center ">
					<Avatar className="h-7 w-7 me-2">
						<AvatarImage src={row.original.avatar} alt="@intract" />
						<AvatarFallback name={row.original.avatar} />
					</Avatar>
					{truncateString(row.original.username, 20)}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'xp',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="XPs Earned"
					className=" max-w-[200px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center ">{row.original.xp}</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		columnHelper.accessor('twitter', {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Twitter"
					className=" w-[100px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center">
					{truncateString(row.getValue('twitter'), 20)}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		}),
		columnHelper.accessor('discord', {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Discord"
					className=" w-[100px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center">
					{truncateString(row.getValue('discord'), 20)}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		}),
		{
			accessorKey: 'completedAt',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Completed at"
					className=" max-w-[200px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center ">
					{dayjs(row.original.completedAt).fromNow()}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
	].filter((i) => i);
