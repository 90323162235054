import { Button } from '@/components/ui/button';
import {
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
	Dialog,
} from '@/components/ui/dialog';
import { ConnectIntegration } from '@/features/integrations/components/ConnectIntegration';
import { useNavigate } from 'react-router-dom';

const IntegrateDiscordDialog = ({ open, setOpen }) => {
	const navigate = useNavigate();

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[525px] p-0 border-0">
				<div
					className="h-[290px] rounded-t-lg flex items-end justify-center"
					style={{
						background: 'linear-gradient(to right,#70e1f5,#ffd194)',
					}}
				>
					<img
						className=" object-contain w-[480px] rounded-lg"
						src={
							'https://cdn2.unrealengine.com/what-is-discord-1920x1080-c3d90ca45f57.jpg'
						}
					/>
				</div>
				<DialogHeader className="p-4 pb-8 pt-4 text-center items-center justify-center">
					<div>
						<DialogTitle className="text-center text-xl px-10">
							Integrate Discord to Enable Discord Announcements
						</DialogTitle>
						<DialogDescription className="text-muted-foreground text-center mt-1 px-10">
							Send announcements for new quests and tasks in your
							loyalty program. Give a Discord role as a reward.
						</DialogDescription>
						<Button
							className="w-full mt-10 "
							size="lg"
							onClick={() => {
								navigate('/app/integrations/discord');
							}}
						>
							Integrate Discord
						</Button>
					</div>
				</DialogHeader>
			</DialogContent>
		</Dialog>
	);
};

export default IntegrateDiscordDialog;
