export const JSONViewer = ({
	data,
}: {
	data: {
		icon?: string;
		label: string | JSX.Element;
		value: string | JSX.Element | any;
		link?: string;
	}[];
}) => {
	return (
		<div className="mb-3">
			{data &&
				Array.isArray(data) &&
				data.map((item, index) => <LineItem key={index} {...item} />)}
		</div>
	);
};
const LineItem = ({
	label,
	value,
	icon,
	link,
}: {
	icon?: string;
	label: string | JSX.Element;
	value: string | JSX.Element | any;
	link?: string;
}) => {
	return (
		<div className="border-b  py-2 flex items-center justify-between">
			<div className="text-sm text-slate-800 min-w-[200px]">
				{icon && <i className={`bi-${icon} me-2`}></i>}
				{label}
			</div>
			<div className="text-sm font-medium">
				{link ? (
					<a href={link} target="_blank" className="hover:underline">
						{value}
						<i className="bi-box-arrow-up-right text-xs ms-3"></i>
					</a>
				) : (
					value
				)}
			</div>
		</div>
	);
};
