import { queryClient } from '@/lib/react-query';
import { useAuth } from '@/hooks/useAuth';

import { createEnterprise } from '@/services/profile.service';
import { uploadFile } from '@/services/utility.service';
import { handleErrorMessage } from '@/utils/notifications';
import { deepCopy, stripURLs, validateCompleteURL } from '@/utils/parsers';
import { useEffect, useState } from 'react';

export interface IOnboardingCommunity {
	logoFile: File | null | any;
	logoUrl: string;
	name: string;
	chainIds: number[];
	category: string[];
	goal?: string[];
	otherCategory: string;
}

export interface IOnboardingSocials {
	twitter: string;
	website: string;
	discord: string;
}

type step = 'community' | 'socials' | 'completed';

const defaultErrors = {
	community: {
		name: false,
		logo: false,
		chains: false,
		category: false,
		goal: false,
	},
	socials: {
		twitter: false,
		website: false,
		discord: false,
	},
};

//This hook is for a logged in user to create another enterprise
export const useCreateEnterprise = (setOpen: any) => {
	const { user, refetchUser } = useAuth();
	const [step, setStep] = useState<step>('community');
	const [isLoading, setIsLoading] = useState(false);

	const [community, setCommunity] = useState<IOnboardingCommunity>({
		logoFile: null,
		logoUrl: '',
		name: '',
		chainIds: [],
		category: [],
		otherCategory: '',
	});
	const [socials, setSocials] = useState<IOnboardingSocials>({
		twitter: '',
		website: '',
		discord: '',
	});
	const [errors, setErrors] = useState(defaultErrors);

	const validateURL = (url: string) => {
		const linkValidationRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/\S*)?$/;
		return linkValidationRegex.test(url);
	};

	const handleComplete = async () => {
		try {
			setIsLoading(true);
			const data = {
				name: community.name,
				chainIds: community.chainIds,
				category: community.category,
				logo: '',
			};
			if (community.logoFile) {
				const bannerImage = await uploadFile(community.logoFile);
				data.logo = bannerImage;
			}

			if (socials.website && !validateURL(socials.website)) {
				setErrors((prev) => ({
					...prev,
					socials: {
						...prev.socials,
						website: true,
					},
				}));

				setIsLoading(false);
				return;
			}

			const completeSocialLinks = {
				...(socials.website && {
					website: socials.website,
				}),
				...(socials.twitter && {
					twitter:
						'https://www.twitter.com/' +
						socials.twitter?.substring(
							socials.twitter?.lastIndexOf('/') + 1,
						),
				}),
				...(socials.discord && { discord: socials.discord }),
			};
			const correctedURLs = Object.keys(completeSocialLinks).reduce(
				(acc, key) => {
					if (completeSocialLinks[key]) {
						acc[key] = validateCompleteURL(completeSocialLinks[key]);
					}
					return acc;
				},
				{},
			);

			const strippedSocials = stripURLs(correctedURLs);

			setSocials({
				...socials,
				...strippedSocials,
			});

			await createEnterprise({
				...data,
				...correctedURLs,
			});

			setOpen(false);
			await refetchUser();
			queryClient.invalidateQueries();
			setIsLoading(false);
		} catch (err) {
			handleErrorMessage(err);
			setIsLoading(false);
			console.log(err);
		}
	};

	const handleNext = async () => {
		if (!validate(step)) return;
		if (step === 'community') {
			setStep('socials');
		} else if (step === 'socials') {
			await handleComplete();
		}
	};

	useEffect(() => {
		setErrors(deepCopy(defaultErrors));
	}, [community, socials]);

	const validate = (step: step) => {
		let isValidated = true;
		if (step === 'community') {
			const newErrors = { ...errors };
			if (!community.logoFile) newErrors.community.logo = true;
			if (!community.name) newErrors.community.name = true;
			if (!community.category.length) newErrors.community.category = true;
			setErrors(newErrors);
			isValidated = !Object.values(newErrors.community).some((e) => e);
		}
		if (step === 'socials') {
			const newErrors = { ...errors };
			const fields = ['website', 'twitter', 'discord'];
			let filledCount = 0;

			fields.forEach((field) => {
				if (socials[field]) {
					filledCount++;
				}
			});
			if (filledCount < 2) {
				fields.forEach((field) => {
					if (!socials[field]) {
						newErrors.socials[field] = true;
					}
				});
			}
			isValidated = filledCount >= 2;
			setErrors(newErrors);
		}
		return isValidated;
	};

	return {
		step,
		setStep,
		handleNext,
		isLoading,
		errors,
		community,
		setCommunity,
		socials,
		setSocials,
	};
};
