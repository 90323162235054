import { homepageContent, testimonials } from '../../content/homepage.content';
import LandingContainer from '../ui/LandingContainer';
import SectionHeader from '../ui/SectionHeader';

const Testimonials = () => {
	return (
		<LandingContainer className="pt-40 pb-28 lg:pb-0">
			<SectionHeader
				title={homepageContent.testimonials.title}
				description={homepageContent.testimonials.description}
				titleGradient={homepageContent.testimonials.titleGradient}
			/>
			<div className="mx-auto flex w-full max-w-sm flex-col justify-center space-y-4 pt-8 sm:hidden">
				<div className="space-y-6">
					{testimonials.slice(4).map((testimonial, index) => (
						<TestimonialCard key={index} {...testimonial} />
					))}
				</div>
			</div>
			<ul className="hidden space-y-6 pt-8 sm:block sm:columns-2 sm:gap-6 lg:columns-3">
				{testimonials.map((testimonial, index) => (
					<TestimonialCard key={index} {...testimonial} />
				))}
			</ul>
		</LandingContainer>
	);
};

export default Testimonials;

const TestimonialCard = ({
	companyLogo,
	testimonial,
	avatar,
	name,
	profile,
	backgroundVariant,
	link,
	isDark,
}) => {
	const variantClassNames = {
		default: '',
		'gradient-purple': 'bg-gradient-to-tr from-purple-500 to-indigo-500',
		'gradient-blue': 'bg-gradient-to-tr from-blue-500 to-teal-500',
		'gradient-pink': 'bg-gradient-to-tr from-pink-500 to-rose-500',
		'gradient-green': 'bg-gradient-to-tr from-green-500 to-emerald-500',
		'gradient-yellow': 'bg-gradient-to-tr from-yellow-500 to-amber-500',
		'gradient-red': 'bg-gradient-to-tr from-red-500 to-pink-500',
		'gradient-black': 'bg-gradient-to-tr from-black to-gray-800',
		white: 'bg-white/20',
		// Add more variants as needed
	};
	const variantClassName = variantClassNames[backgroundVariant] || ''; // Default to empty string if variant not found

	return (
		<li
			className={`group relative list-none break-inside-avoid rounded-3xl border border-gray-300 ${variantClassName} backdrop-blur-lg transition-all hover:shadow-lg `}
		>
			<img
				src="https://assets.dub.co/testimonials/card-dotted-grid-dark.png"
				alt="Dotted grid background"
				className="pointer-events-none absolute right-0 top-0"
			/>
			<a
				target="_blank"
				className="flex h-full flex-col justify-between p-8"
				href={link}
			>
				{companyLogo && (
					<div className="relative h-20">
						<img
							src={companyLogo}
							alt="Company Logo"
							className="absolute h-8 w-fit"
						/>
					</div>
				)}
				<div className={isDark ? 'text-gray-800' : 'text-gray-200'}>
					{testimonial}
				</div>
				<div className="mt-8 flex items-center justify-between">
					<div className="flex flex-col space-y-0.5 ">
						<div
							className={`font-semibold ${isDark ? 'text-gray-800' : 'text-gray-200'}`}
						>
							{name}
						</div>
						<div
							className={`text-sm ${isDark ? 'text-gray-500' : 'text-white'}`}
						>
							{profile}
						</div>
					</div>
					<img
						alt={name}
						loading="lazy"
						width={300}
						height={300}
						decoding="async"
						data-nimg={1}
						className="blur-0 h-12 w-12 rounded-full border border-gray-800"
						src={avatar}
						style={{ color: 'transparent' }}
					/>
				</div>
			</a>
		</li>
	);
};

/**
 * <li className="group relative list-none break-inside-avoid rounded-3xl border border-gray-300 backdrop-blur-lg transition-all hover:shadow-lg row-span-2 bg-gradient-to-tr from-transparent via-transparent to-[rgb(255,255,255,0.25)] bg-black">
							<img
								src="https://assets.dub.co/testimonials/card-dotted-grid-dark.png"
								alt="Dotted grid background"
								className="pointer-events-none absolute right-0 top-0"
							/>
							<a
								target="_blank"
								className="flex h-full flex-col justify-between p-8"
								href="https://vercel.com"
							>
								<div className="relative h-36">
									<img
										src="https://assets.dub.co/testimonials/vercel.svg"
										alt="Vercel"
										className="absolute h-8 w-fit"
									/>
								</div>
								<div className="text-gray-200">
									"Stripe for payments, Vercel for deployments,
									Dub.co for links. As the cloud evolves, we
									abstract out common needs into reusable,
									high-performance infrastructure. Excited about
									Dub filling this foundational missing piece of
									the puzzle."
								</div>
								<div className="mt-8 flex items-center justify-between">
									<div className="flex flex-col space-y-0.5 invert">
										<div className="font-semibold text-gray-800">
											Guillermo Rauch
										</div>
										<div className="text-sm text-gray-500">
											CEO, Vercel
										</div>
									</div>
									<img
										alt="Guillermo Rauch"
										loading="lazy"
										width={300}
										height={300}
										decoding="async"
										data-nimg={1}
										className="blur-0 h-12 w-12 rounded-full border border-gray-800"
										src="https://assets.dub.co/testimonials/guillermo-rauch.jpeg"
										style={{ color: 'transparent' }}
									/>
								</div>
							</a>
						</li>
 */
