import { axios } from '@/lib/axios';

export const getIntegrations = async () => {
	const response = await axios.get('/integrations/');
	return response.data;
};

export const getDiscordBotAuthUrl = async () => {
	const response = await axios.get('/integrations/discord/add');
	return response.data;
};

export const addDiscordIntegration = async (body: any) => {
	const response = await axios.post('/integrations/discord/redirect', body);
	return response.data;
};

export const refetchDiscordIntegration = async (integrationId: string) => {
	const response = await axios.get(
		`/integrations/discord/refetch?integrationId=${integrationId}`,
	);
	return response.data;
};

export const deleteIntegration = async (integrationId: string) => {
	const response = await axios.delete(`/integrations/${integrationId}`);
	return response.data;
};

export const getDiscordIntegrationsChannels = async () => {
	const response = await axios.get(`/integrations/discord/channel-permission`);
	return response.data;
};

export const getTelegramIntegration = async () => {
	const response = await axios.get(`/integrations/telegram`);
	return response.data;
};

export const getDiscordIntegration = async () => {
	const response = await axios.get(`/integrations/discord`);
	return response.data;
};

export const getDiscordGuildRoles = async () => {
	const response = await axios.get(`/integrations/discord/roles`);
	return response.data;
};

export const getDiscordGuildChannels = async () => {
	const response = await axios.get(`/integrations/discord/channels`);
	return response.data;
};
