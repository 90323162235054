import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { Label } from '@/components/ui/label';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import clsx from 'clsx';
import { SelectWithSearch } from './SelectWithSearch';

const InputSelect2 = ({
	labelClassName,
	className,
	placeholder,
	label,
	required,
	tooltip,
	options,
	error,
	value,
	setValue,
	errorText,
	inputClassName,
	popoverClassName,
	subLabel,
	subLabelClassName,
	appendIcon,
	prepend,
}: {
	labelClassName?: string;
	popoverClassName?: string;
	inputClassName?: string;
	className?: string;
	label?: string;
	required?: boolean;
	tooltip?: string;
	error?: boolean;
	setValue: (value: string, option?: any) => void;
	value: string;
	errorText?: string;
	options: {
		value: string;
		label: string | any;
		searchText?: string;
		selectedLabel?: any;
	}[];
	placeholder?: string;
	subLabel?: string;
	subLabelClassName?: string;
	appendIcon?: string;
	prepend?: any;
}) => {
	return (
		<div className={className}>
			{label && (
				<Label className={clsx('flex items-center mb-2', labelClassName)}>
					{label}
					{tooltip && (
						<TooltipProvider delayDuration={0}>
							<Tooltip>
								<TooltipTrigger className="ms-2">
									<i className="bi-info-circle-fill block text-muted-foreground text-xs cursor-pointer"></i>
								</TooltipTrigger>
								<TooltipContent>
									<div className="text-sm font-normal max-w-[400px]">
										{tooltip}
									</div>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					)}
					{required && <span className="text-destructive ms-1">*</span>}
				</Label>
			)}
			{subLabel ? (
				<p
					className={clsx(
						'text-xs text-muted-foreground',
						subLabelClassName,
					)}
				>
					{subLabel}
				</p>
			) : null}
			<SelectWithSearch
				selected={value}
				setSelected={setValue}
				options={options}
				placeholder={placeholder}
				className={inputClassName}
				popoverClassName={popoverClassName}
				appendIcon={appendIcon}
				error={error}
				prepend={prepend}
			/>
			{error && errorText && (
				<p className={cn('text-xs  text-destructive mt-1')}>{errorText}</p>
			)}
		</div>
	);
};

export default InputSelect2;
