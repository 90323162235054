import { Route, Routes } from 'react-router-dom';
import EventsPage from './events.page.tsx';
import EventsCreatePage from './events-create.page.tsx';
import EventsReportPage from './events-report.page.tsx';

const EventsRoutes = () => {
	return (
		<Routes>
			<Route path="" element={<EventsPage />} />
			<Route path="new" element={<EventsCreatePage />} />
			<Route path=":id" element={<EventsReportPage />} />
			<Route path=":id/update" element={<EventsCreatePage />} />
		</Routes>
	);
};

export default EventsRoutes;
