import { Button } from '@/components/ui/button';
import { DaveActionCreateSectionEnum } from '../../../types/dave.enum';
import { useCreateActionProvider } from '@/features/dave/hooks/actions/useCreateActionProvider';
import GuideVideoDialog from '../../dialogs/GuideVideoDialog';
import InviteYourDeveloperDialog from '../../dialogs/InviteYourDeveloperDialog';
import { useState } from 'react';

const DaveActionCreateSection = () => {
	const [showGuide, setShowGuide] = useState(false);
	const [showInvite, setShowInvite] = useState(false);
	const { details, section } = useCreateActionProvider();
	const sections = [
		{
			title: 'Actions Details',
			value: DaveActionCreateSectionEnum.Details,
			icon: 'file-text',
		},
		...(details.type === 'contract'
			? [
					{
						title: 'Interaction Requirements',
						value: DaveActionCreateSectionEnum.Intractions,
						icon: 'ui-checks',
					},
				]
			: []),
	];

	return (
		<div className="flex justify-center items-center space-x-4">
			{sections.map((i, index) => (
				<div
					key={i.value}
					className="flex justify-center items-center space-x-4"
				>
					<Button
						size="sm"
						className={`rounded-full px-4 cursor-default ${
							section !== i.value
								? ''
								: 'border-purple-700 bg-purple-100 text-purple-800  hover:bg-purple-100  hover:text-purple-800 '
						}`}
						variant="secondary"
					>
						<i className={`bi bi-${i.icon} me-2`}></i>
						{i.title}
					</Button>
					{sections.length !== index + 1 && (
						<div>
							<i className="bi bi-chevron-right text-muted-foreground"></i>
						</div>
					)}
				</div>
			))}
			{/* <Button
				size="sm"
				className="absolute start-0 ms-5"
				variant="outline"
				onClick={() => {
					setShowGuide(true);
				}}
			>
				<i className="bi bi-play-circle me-1"></i>
				Tutorial
			</Button> */}
			{/* <Button
				size="sm"
				className="absolute end-4"
				variant="outline"
				onClick={() => {
					setShowInvite(true);
				}}
			>
				<i className="bi bi-person-add me-1"></i>
				Invite your developers
			</Button> */}
			<GuideVideoDialog
				open={showGuide}
				setOpen={setShowGuide}
				embed="https://www.youtube.com/embed/ZMrjvxCIPpY?si=LzP0vR6vcLytBaZV"
			/>
			<InviteYourDeveloperDialog open={showInvite} setOpen={setShowInvite} />
		</div>
	);
};

export default DaveActionCreateSection;
