import { Button } from '@/components/ui/button';
import {
	CommandDialog,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '@/components/ui/command';
import { useCreateCampaignTasks } from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import { useTaskTemplates } from '@/features/campaigns/hooks/create/useTaskTemplates';
import { AdminInputType, ITaskTemplates } from '@/features/campaigns/types';
import { deepCopy } from '@/utils/parsers';
import { useEffect, useRef, useState } from 'react';

export function SearchTaskTemplates({
	open,
	setOpen,
	sectionId,
	addTask,
}: {
	setOpen: any;
	open: any;
	sectionId?: string;
	addTask: any;
}) {
	const { templates, families, rawTemplates } = useTaskTemplates();
	const [selectedFamily, setSelectedFamily] = useState('');

	return (
		<CommandDialog open={open} onOpenChange={setOpen}>
			<CommandInput placeholder="Search from our Task Library" />
			<Families
				families={families}
				onSelect={(family: string) => {
					setSelectedFamily(family);
				}}
				selected={selectedFamily}
			/>
			<CommandList className="text-xs">
				<CommandEmpty>No results found.</CommandEmpty>
				{templates
					?.filter((i) => {
						if (!selectedFamily) return true;
						return i.family === selectedFamily;
					})
					.map((i: any) => (
						<CommandGroup heading={i.group}>
							{i.templates.map((j: ITaskTemplates) => (
								<CommandItem
									onSelect={() => {
										addTask(deepCopy(j), i.icon, sectionId);
										setOpen(false);
									}}
									className=""
									value={j.name}
									disabled={j.disabled}
								>
									<div className="min-w-10 h-10 border rounded-full flex items-center justify-center me-3 bg-slate-50">
										{j?.logo?.type === 'icon' && (
											<i
												className={` ${j.logo?.value}  ${i.color} text-xl`}
											/>
										)}
									</div>
									<div>
										<div className="font-medium">{j.name}</div>
										<div className="text-xs text-muted-foreground mt-1">
											{j.disabled
												? 'Please integrate Telegram to use this task'
												: j.adminHelper}
										</div>
									</div>
								</CommandItem>
							))}
						</CommandGroup>
					))}
				<CommandSeparator />
			</CommandList>
		</CommandDialog>
	);
}

const Families = ({
	families,
	onSelect,
	selected,
}: {
	families: {
		label: string;
		value: string;
		icon: string;
		color: string;
	}[];
	onSelect: (family: string) => void;
	selected: string;
}) => {
	const containerRef = useRef(null);
	const [showScrollLeft, setShowScrollLeft] = useState(false);
	// const [showScrollRight, setShowScrollRight] = useState(false);

	// const checkScroll = () => {
	// 	const container = containerRef.current;
	// 	console.log('container.scrollLeft', container.scrollLeft);
	// 	if (container) {
	// 		setShowScrollLeft(container.scrollLeft > 0);
	// 		setShowScrollRight(
	// 			container.scrollLeft < container.scrollWidth - container.clientWidth,
	// 		);
	// 	}
	// };

	// console.log('showScrollLeft', showScrollLeft);

	// useEffect(() => {
	// 	checkScroll();
	// }, [families]);

	return (
		<div className="flex flex-col px-2 border-b py-2">
			<div
				className="items-center flex gap-2 flex-wrap relative"
				// ref={containerRef}
				// onScroll={checkScroll}
			>
				{/* {showScrollLeft && (
					<>
						<Button
							className="fixed left-2 z-20 rounded-full h-8 w-8"
							onClick={() =>
								containerRef.current.scrollTo({
									left: 0,
									behavior: 'smooth',
								})
							}
							variant="outline"
						>
							<i className="bi-chevron-left" />
						</Button>
						<div className="absolute top-0 left-0 bottom-0 w-10 bg-gradient-to-r from-slate-50 to-transparent" />
					</>
				)} */}
				{families.map((i) => (
					<Button
						key={i.value}
						className="rounded-full"
						size="sm"
						variant={selected === i.value ? 'default' : 'outline'}
						onClick={() => {
							if (selected !== i.value) onSelect(i.value);
							else onSelect('');
						}}
					>
						<div className="flex items-center">
							<i className={`bi-${i.icon} ${i.color} me-2`} />
							{i.label}
						</div>
					</Button>
				))}
				{/* {showScrollRight && (
					<>
						<Button
							className="fixed right-4 z-20 rounded-full h-8 w-8"
							onClick={() =>
								containerRef.current.scrollTo({
									left: containerRef.current.scrollWidth + 10000,
									behavior: 'smooth',
								})
							}
							variant="outline"
						>
							<i className="bi-chevron-right" />
						</Button>
						<div className="fixed top-0 right-0 bottom-0 w-20 bg-gradient-to-r from-transparent to-slate-50" />
					</>
				)} */}
			</div>
		</div>
	);
};
