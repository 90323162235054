import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { IExportUserDataRequest } from '../../routes/exports.page';

const ExportDialog = ({
	open,
	setOpen,
	requestDoc,
}: {
	open: boolean;
	setOpen: any;
	requestDoc: IExportUserDataRequest;
}) => {
	if (!requestDoc) return;
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:min-w-[525px]">
				<div>
					<h2 className="text-lg font-medium tracking-tight mb-">
						Export Details
					</h2>
					<div className="text-sm">
						Your export request has been{' '}
						{requestDoc?.status === 'completed'
							? 'completed'
							: requestDoc.status === 'failed'
								? 'failed'
								: 'pending'}
						. You can download the files below.
					</div>
				</div>
				<div className="grid grid-cols-4 gap-4">
					{requestDoc?.files.map((i, index) => (
						<Button
							onClick={() => {
								window.open(i.url, '_blank');
							}}
							className="truncate flex items-center justify-center flex-col h-[90px]"
							variant="outline"
						>
							<i className="bi bi-filetype-csv text-4xl text-muted-foreground mb-2"></i>
							{`File ${index + 1}`}
						</Button>
					))}
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ExportDialog;
