import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Separator } from '@/components/ui/separator';
import { useCommunityHub } from '../hooks/useCommunityHub';
import { useEffect, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { PUBLIC_URL } from '@/config';
import useUpdateSocials from '../hooks/useUpdateSocials';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';

const CommunityHubSocials = () => {
	const [showAddSocial, setShowAddSocial] = useState(false);

	const {
		formErrors,
		formFields,
		setFormFields,
		setFormErrors,
		isSubmitting,
		updateCommunitySocials,
		loading,
	} = useCommunityHub();

	const { addSocial, removeSocial, handleSocialInput } = useUpdateSocials({
		formFields,
		setFormFields,
		setFormErrors,
		formErrors,
		setShowAddSocial,
	});

	useEffect(() => {
		setFormErrors((prev: any) => ({
			...prev,
			socials: '',
		}));
	}, [formFields.socials, setFormErrors]);

	if (loading) {
		return <FullPageLoading />;
	}

	return (
		<div className="space-y-6 mt-10">
			{formFields.socials?.map((item: any, index: number) => (
				<div className="flex">
					<>
						<div className="w-1/3">
							<div className="text-sm font-medium mb-2">
								<i
									className={`bi-${item.key === 'website' ? 'globe' : item.key} text-muted-foreground me-2`} //TODO: add icon map? (eg: website <> globe)
								></i>
								{item?.key.charAt(0).toUpperCase() +
									item?.key.slice(1)}
							</div>
						</div>
						<div className="flex gap-2">
							<div>
								<Input
									placeholder={item.placeholder}
									className={` w-80 ${formErrors[item.key] ? 'border-destructive' : ''}`}
									name={item.key}
									value={item.value}
									onChange={(e) =>
										handleSocialInput(e.target.value, index)
									}
								/>
								{formErrors[item.key] ? (
									<p className={'text-xs  text-destructive mt-1'}>
										{formErrors[item.key]}
									</p>
								) : null}
							</div>

							<Button
								variant="outline"
								className="ms-2"
								onClick={() => removeSocial(item)}
							>
								<i className="bi bi-trash"></i>
							</Button>
						</div>
					</>
				</div>
			))}

			{formErrors.socials ? (
				<p className={'text-xs  text-destructive mt-1'}>
					{formErrors['socials']}
				</p>
			) : null}
			<Separator className="my-4" />
			<Popover open={showAddSocial} onOpenChange={setShowAddSocial}>
				<PopoverTrigger asChild>
					<Button variant="outline">+ Add Social</Button>
				</PopoverTrigger>
				<PopoverContent className="w-[8rem] p-0 max-h-[320px] overflow-y-auto">
					{communitySocialNetworks
						?.filter(
							(item: any) =>
								!formFields?.socials
									?.map((social) => social.key.toLowerCase())
									.includes(item?.name?.toLowerCase()),
						)
						?.map((key: any) => (
							<Button
								size="sm"
								variant="ghost"
								className="w-full justify-start text-sm font-normal "
								onClick={() => addSocial(key)}
							>
								{key.image ? (
									<img
										src={key?.icon}
										alt={key}
										className="size-[14px]"
									/>
								) : (
									<i
										className={`${key?.icon} text-muted-foreground me-2`}
									></i>
								)}
								{key?.name}
							</Button>
						))}
				</PopoverContent>
			</Popover>
			<Button
				className="ml-2"
				disabled={isSubmitting}
				onClick={() => updateCommunitySocials()}
			>
				{isSubmitting ? (
					<i className="bi bi-arrow-clockwise animate-spin mr-1 text-sm"></i>
				) : null}
				Update Socials
			</Button>
		</div>
	);
};

export default CommunityHubSocials;

const communitySocialNetworks = [
	{
		name: 'Website',
		icon: 'bi-globe',
		placeholder: 'www.example.com',
	},
	{
		name: 'Twitter',
		icon: 'bi-twitter',
		placeholder: 'x.com/username',
	},
	{
		name: 'Discord',
		icon: 'bi-discord',
		placeholder: 'discord.gg/invite/34g3g',
	},
	{
		name: 'Telegram',
		icon: 'bi-telegram',
		placeholder: 't.me/username',
	},
	{
		name: 'Instagram',
		icon: 'bi-instagram',
		placeholder: 'instagram.com/username',
	},
	{
		name: 'Facebook',
		icon: 'bi-facebook',
		placeholder: 'facebook.com/username',
	},
	{
		name: 'LinkedIn',
		icon: 'bi-linkedin',
		placeholder: 'linkedin.com/in/username',
	},
	{
		name: 'Reddit',
		icon: 'bi-reddit',
		placeholder: 'reddit.com/user/username',
	},
	{
		name: 'TikTok',
		icon: 'bi-tiktok',
		placeholder: 'tiktok.com/@username',
	},
	{
		name: 'Snapchat',
		icon: 'bi-snapchat',
		placeholder: 'snapchat.com/add/username',
	},
	{
		name: 'YouTube',
		icon: 'bi-youtube',
		placeholder: 'youtube.com/channel/username',
	},
	{
		name: 'Twitch',
		icon: 'bi-twitch',
		placeholder: 'twitch.tv/username',
	},
	{
		name: 'Farcaster',
		icon: `${PUBLIC_URL}/assets/img/defaults/farcaster-icon.svg`,
		placeholder: 'farcaster dapp link',
		img: true,
	},
	{
		name: 'Lenster',
		icon: '',
		placeholder: 'lenster Dapp link',
	},
	// {
	// 	name: 'Others',
	// 	icon: 'bi-plus-circle-fill',
	// 	placeholder: 'others',
	// },
];
