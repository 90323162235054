import PageHeader from '@/components/layout/PageHeader';
import { useMemo, useState } from 'react';
import NFTsGrid from '../components/NFTsGrid';
import { useGetNFTs } from '../hooks/useGetNFTs';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import InputText from '@/components/element/inputs/InputText';
import { FacetedFilter } from '@/components/element/inputs/FacetedFilter';
import { links } from '@/config/links';
import Spinner from '@/components/element/loading/Spinner';
import NoNFTsCard from '../components/NoNFTsCard';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';
import { DeployContractDialog } from '../components/DeployContractDialog';
import { useQueryInitialise } from '@/hooks/useQueryInitialise';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

const NFTContractPage = () => {
	const { nftChains, getChainOptions } = useGetSupportedChains({
		showAdditionalChains: false,
	});
	const [open, setOpen] = useState(false);

	const { nfts, isLoading, filters, setFilters, search, setSearch, userNFTs } =
		useGetNFTs();

	const showNFTNewDialog = () => {
		analytics.track(TrackingEvents.DeployNFTViewed, {});
		setOpen(true);
	};
	useQueryInitialise('new', 'true', () => {
		showNFTNewDialog();
	});

	const options = useMemo(() => {
		return getChainOptions(nftChains);
	}, [nftChains, getChainOptions]);

	if (isLoading) {
		return <FullPageLoading />;
	}
	return (
		<div className="">
			<PageHeader
				title="NFT Reward Contracts"
				description="Create, Reward, and Share Your Digital Artifacts in a Snap!"
				btnLabel={'Deploy NFT'}
				btnOnClick={() => {
					showNFTNewDialog();
				}}
				icon={'code-square'}
				learn={userNFTs === 0 ? links.docs.nfts : ''}
			/>
			<div
				dir="ltr"
				data-orientation="horizontal"
				className="h-full space-y-6"
			>
				{userNFTs > 0 ? (
					<div className="my-6">
						<div className="flex space-x-4 mb-4">
							<InputText
								value={search}
								setValue={setSearch as any}
								prepend={
									<i className="bi-search px-3 text-muted-foreground text-sm"></i>
								}
								placeholder="Search NFTs"
								className="w-[300px] lg:w-[300px]"
								inputClassName="h-8 "
							/>
							<FacetedFilter
								title="Chains"
								options={options as any}
								selectedValues={filters.chains}
								setSelectedValues={(values: any) =>
									setFilters((prev) => ({
										...prev,
										chains: values,
									}))
								}
							/>
						</div>
						<NFTsGrid data={nfts} />
						{nfts.length <= 0 ? (
							<div className="flex items-center justify-center p-10 border rounded-lg">
								<p className="text-sm">
									No NFTs found. Try changing your search criteria.
								</p>
							</div>
						) : null}
						<div className="flex w-full items-center mt-5">
							{isLoading && <Spinner />}
						</div>
					</div>
				) : (
					<NoNFTsCard setOpen={showNFTNewDialog} />
				)}
				<DeployContractDialog open={open} setOpen={setOpen} />
			</div>
		</div>
	);
};

export default NFTContractPage;
