import { additionalChainsNotInDB } from './../utils/chains/additionalChainsNotInDB';
import { getSupportedChains } from '@/services/utility.service';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

interface IChain {
	chainId: number;
	chainSymbol?: string;
	chainName?: string;
	chainLogo?: string;
	openSeaUrl?: string;
	blockExplorerDomain: string;
	isCampaignRewardErc20: boolean;
	isCampaignRewardErc1155: boolean;
	isNoCodeAvailable: boolean;
	isBlockExplorerAvailable: boolean;
	isRpcUrlsAvailable: boolean;
	isTestnet: boolean;
}

export const useGetSupportedChains = ({
	showAdditionalChains = false,
}: {
	showAdditionalChains?: boolean;
}) => {
	const [mergedChains, setMergedChains] = useState([]);

	const { data, isLoading } = useQuery<IChain[]>({
		queryKey: ['chains'],
		queryFn: () => getSupportedChains(),
		staleTime: Infinity,
	});

	function mergeChains(originalChains: any[], newChains: any[]): any[] {
		if (!originalChains) return newChains;
		const existingChainNames = new Set(
			originalChains.map((chain) => chain.chainName),
		);
		const filteredNewChains = newChains.filter(
			(chain) => !existingChainNames?.has(chain.chainName),
		);
		return [...originalChains, ...filteredNewChains];
	}
	const getChainOptions = (chains: IChain[]) => {
		return (
			chains?.map((chain) => ({
				label: chain.chainName,
				value: chain.chainId,
				img: chain.chainLogo,
			})) ?? []
		);
	};

	const getChainName = (chainId: number) => {
		const chain = mergedChains?.find(
			(chain) => Number(chain.chainId) === Number(chainId),
		);
		return chain?.chainName;
	};

	const getNativeChainLogo = (chainId: number) => {
		const chain = mergedChains?.find(
			(chain) => Number(chain.chainId) === Number(chainId),
		);
		return chain?.chainLogo;
	};

	useEffect(() => {
		if (data && showAdditionalChains) {
			const updatedChains = mergeChains(data, additionalChainsNotInDB);
			setMergedChains(updatedChains);
		} else {
			setMergedChains(data);
		}
	}, [data, showAdditionalChains]);

	return {
		chains: data,
		isLoading,
		getChainOptions,
		tokenChains: data?.filter((chain) => chain.isCampaignRewardErc20),
		nftChains: data?.filter((chain) => chain.isCampaignRewardErc1155),
		noCodeChains: data?.filter((chain) => chain.isNoCodeAvailable),
		getChainName,
		getNativeChainLogo,
	};
};
