import {
	Select,
	SelectContent,
	SelectValue,
	SelectItem,
	SelectTrigger,
} from '@/components/ui/select';
import { getDiscordGuildRoles } from '@/features/integrations/services/integrations.service';
import { cn } from '@/lib/utils';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

interface IDiscordRoles {
	id: string;
	name: string;
	permissions: string;
	color: string;
	isOurs: boolean;
	position: number;
	managed: boolean;
}

const SelectDiscordRole = ({ value, setValue, error, errorText }) => {
	const { data, isLoading } = useQuery<IDiscordRoles[]>({
		queryKey: ['discord-roles'],
		queryFn: getDiscordGuildRoles,
	});

	const roles = useMemo(() => {
		if (!data || data.length === 0) return [];
		const ourBotPosition = data?.find((role) => role.isOurs)?.position;
		return data?.filter((role) => {
			if (role.name === '@everyone') return false;
			if (role.managed) return false;
			if (role.position > ourBotPosition) return false;
			return true;
		});
	}, [data]);

	if (isLoading) return <div>Loading...</div>;
	if (!roles) return null;
	return (
		<>
			<Select
				value={value}
				onValueChange={(value) =>
					setValue(value, roles?.find((i) => i?.id === value)?.name)
				}
			>
				<SelectTrigger
					aria-label="Area"
					className={cn('text-sm bg-white', error && 'border-destructive')}
				>
					<SelectValue placeholder="Select Role" className="h-10 " />
				</SelectTrigger>
				<SelectContent>
					{roles.map((i) => (
						<SelectItem value={i.id}>{i.name}</SelectItem>
					))}
				</SelectContent>
			</Select>
			{error && errorText && (
				<p className={cn('text-xs  text-destructive mt-1')}>{errorText}</p>
			)}
		</>
	);
};

export default SelectDiscordRole;
