import { Dispatch, SetStateAction, useState } from 'react';
import { IDaveContracts } from '../../types/dave.types';
import { DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';

const ContractProxy = ({
	handleNext,
	details,
	isLoading,
	fetchStatus,
}: {
	handleNext: () => void;
	details: IDaveContracts;
	setDetails: Dispatch<SetStateAction<IDaveContracts>>;
	isLoading: {
		fetchingABI: boolean;
		checkingProxy: boolean;
	};
	fetchStatus: {
		fetchingABI: boolean;
		checkingProxy: boolean;
	};
}) => {
	return (
		<>
			<div className="mb-0">
				<div className="text-xl font-medium mb-2">
					Fetching Contract Details
				</div>
			</div>
			<div className="">
				<LoadingItem
					isLoading={isLoading.checkingProxy}
					isCompleted={fetchStatus.checkingProxy}
					isSuccess={details.isProxy}
					title="Checking if contract is a proxy"
					description="Intract automatically detects the EIP-1967 or EIP-1167 Proxy standards."
				>
					<div className="text-xs text-muted-foreground mt-3 border-t pt-3">
						{details.isProxy
							? `Proxy detected at ${details.implementationAddress}`
							: `Contract is not a proxy`}
					</div>
				</LoadingItem>
				<LoadingItem
					isLoading={isLoading.fetchingABI}
					isCompleted={fetchStatus.fetchingABI}
					isSuccess={!!details.abi}
					title="Fetching Contract ABI from Block Explorer"
					description="Intract automatically fetches ABI if your contract is verified"
				>
					<div className="text-xs text-muted-foreground mt-3 border-t pt-3">
						{details.abi
							? 'ABI detected successfully'
							: `Could not fetch ABI from block explorer.`}
					</div>
				</LoadingItem>
			</div>
		</>
	);
};

export default ContractProxy;

const LoadingItem = ({
	isLoading,
	isCompleted,
	title,
	description,
	children,
	isSuccess,
}: {
	isLoading: boolean;
	isCompleted: boolean;
	title: string;
	description: any;
	children: any;
	isSuccess: boolean;
}) => {
	return (
		<div className="flex items-center mb-4 border rounded-lg bg-slate-50 px-3 py-2">
			{isLoading ? (
				<img
					className="h-4 me-2  animate-spin-slow"
					src="https://static-00.iconduck.com/assets.00/loading-spinner-icon-2048x2048-hoenwdth.png"
				/>
			) : isCompleted ? (
				isSuccess ? (
					<i className="bi bi-check-circle-fill text-green-500 me-3 text-xl"></i>
				) : (
					<i className="bi bi-x-circle-fill text-red-500 me-3 text-xl"></i>
				)
			) : (
				<i className="bi bi-circle me-3 text-xl"></i>
			)}
			<div>
				<div className="text-sm">{title}</div>
				<p className="text-xs text-muted-foreground">{description}</p>
				{isCompleted && children}
			</div>
		</div>
	);
};
