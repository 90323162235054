import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { Label } from '@/components/ui/label';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import clsx from 'clsx';
import MultiSelect from './MultiSelect';

const InputMultiSelect = ({
	labelClassName,
	className,
	placeholder,
	label,
	required,
	tooltip,
	options,
	error,
	maxLimit,
	value,
	setValue,
	errorText,
	withoutPortal,
	popoverClassName,
}: {
	labelClassName?: string;
	className?: string;
	popoverClassName?: string;
	label?: string;
	required?: boolean;
	tooltip?: string;
	error: boolean | string;
	setValue: (value: string[]) => void;
	value: string[];
	errorText?: string;
	options: {
		value: string;
		label: string | JSX.Element;
		selectedLabel?: string | JSX.Element;
		searchText?: string;
	}[];
	placeholder?: string;
	withoutPortal?: boolean;
	maxLimit?: number;
}) => {
	return (
		<div className={className}>
			{label && (
				<Label className={clsx('flex items-center mb-2', labelClassName)}>
					<span>{label}</span>
					{tooltip && (
						<TooltipProvider delayDuration={0}>
							<Tooltip>
								<TooltipTrigger className="ms-2">
									<i className="bi-info-circle-fill block text-muted-foreground text-xs cursor-pointer"></i>
								</TooltipTrigger>
								<TooltipContent>
									<div className="text-sm font-normal max-w-[400px]">
										{tooltip}
									</div>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					)}
					{required && <span className="text-destructive ms-1">*</span>}
				</Label>
			)}
			<MultiSelect
				value={value}
				options={options}
				setValue={setValue}
				placeholder={placeholder}
				error={!!error}
				withoutPortal={withoutPortal}
				popoverClassName={popoverClassName}
				maxLimit={maxLimit}
			/>
			{error && errorText && (
				<p className={cn('text-xs  text-destructive mt-1')}>{errorText}</p>
			)}
		</div>
	);
};

export default InputMultiSelect;
