import clsx from 'clsx';
import { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '@/styles/quill.css';

interface WYSIWYGProps {
	className?: string;
	labelClassName?: string;
	value: any;
	setValue: (value: any) => void;
	placeholder?: string;
	label?: string;
	error?: boolean | string;
	errorText?: string;
	onBlur?: () => void;
	size?: 'sm' | 'lg';
}

export const QuillEditor = ({
	className,
	labelClassName,
	value,
	setValue,
	label,
	error,
	errorText,
	onBlur,
	placeholder,
	size,
}: WYSIWYGProps) => {
	const quillRef = useRef<ReactQuill>(null);

	return (
		<div
			className={`quill-custom ${size === 'lg' ? 'quill-custom-lg' : ''}  rounded-lg  ${className}`}
		>
			{label && (
				<label className={clsx('form-label', labelClassName)}>{label}</label>
			)}
			<ReactQuill
				ref={quillRef}
				theme="snow"
				value={value}
				onChange={setValue}
				placeholder={placeholder}
				onBlur={onBlur}
				className={clsx(
					'bg-white',
					error && 'border-destructive border rounded-lg',
				)}
				style={{ maxHeight: '400px', overflow: 'scroll' }}
			/>
			{error && (
				<div className="d-block text-destructive text-sm mt-2">
					{errorText}
				</div>
			)}
		</div>
	);
};
