import { FacetedFilter } from '@/components/element/inputs/FacetedFilter';
import { FacetedFilterSingle } from '@/components/element/inputs/FacetedFilterSingle';
import Spinner from '@/components/element/loading/Spinner';
import { Button } from '@/components/ui/button';
import { useCampaignSubmissionsApprovals } from '@/features/campaigns/hooks/report/useCampaignSubmissionsApprovals';
import { POWVerifyStatus } from '@/features/campaigns/types';

export const SubmissionsHeader = ({
	powTasks,
	selectedTaskId,
	setSelectedTaskId,
	statusFilters,
	setStatusFilters,
	queryKey,
	setCursor,
	campaignId,
}: {
	powTasks: any;
	selectedTaskId: string;
	setSelectedTaskId: any;
	statusFilters: any;
	setStatusFilters: any;
	queryKey: any[];
	setCursor: any;
	campaignId: string;
}) => {
	const { handleReviewAll, isActionLoading } = useCampaignSubmissionsApprovals(
		queryKey,
		campaignId,
	);
	return (
		<div className="mb-6 ">
			<div className="mb-3 flex justify-between items-center">
				<div>
					<h2 className="text-base font-semibold">Submissions</h2>
					<div className="text-sm text-muted-foreground">
						Approve or reject submissions from participants
					</div>
				</div>
				<div className="flex gap-2">
					<Button
						variant="outline"
						disabled={
							isActionLoading.rejectAll || isActionLoading.approvalAll
						}
						onClick={() =>
							handleReviewAll(selectedTaskId, false, setCursor)
						}
					>
						{isActionLoading.rejectAll && <Spinner className="me-1" />}
						<span>Reject All</span>
					</Button>
					<Button
						disabled={
							isActionLoading.rejectAll || isActionLoading.approvalAll
						}
						onClick={() =>
							handleReviewAll(selectedTaskId, true, setCursor)
						}
					>
						{isActionLoading.approvalAll && <Spinner className="me-1" />}
						<span>Approve All</span>
					</Button>
				</div>
			</div>
			<div className="flex gap-4">
				<FacetedFilterSingle
					title="Tasks"
					options={powTasks?.map((task) => ({
						label: task.description,
						value: task._id,
					}))}
					selectedValue={selectedTaskId}
					setSelectedValue={(values: any) => {
						setCursor();
						setSelectedTaskId(values);
					}}
				/>
				<FacetedFilter
					title="Status"
					options={[
						{
							value: POWVerifyStatus.Verified,
							label: 'Approved',
						},
						{
							value: POWVerifyStatus.Failed,
							label: 'Rejected',
						},
						{
							value: POWVerifyStatus.Initiated,
							label: 'Pending',
						},
					]}
					selectedValues={statusFilters}
					setSelectedValues={(values: any) => {
						if (values?.length === 0) return;
						setCursor();
						setStatusFilters(values);
					}}
				/>
			</div>
		</div>
	);
};
