import { ICampaign } from '@/features/campaigns/types';
import { axios } from '@/lib/axios';

export const getLoyaltyCampaign = async (): Promise<ICampaign> => {
	const response = await axios.get('/campaigns/loyalty');
	return response.data as ICampaign;
};

export const createLoyaltyCampaign = async (): Promise<ICampaign> => {
	const response = await axios.post('/campaigns/loyalty');
	return response.data as ICampaign;
};

export const addLoyaltySection = async (body: any) => {
	const response = await axios.post(`/campaigns/loyalty/section`, body);
	return response.data;
};

export const deleteLoyaltySection = async (body: any) => {
	const response = await axios.delete(`/campaigns/loyalty/section`, {
		params: body,
	});
	return response.data;
};

export const editLoyaltySection = async (body: any) => {
	const response = await axios.put(`/campaigns/loyalty/section`, body);
	return response.data;
};

export const addLoyaltyTask = async (body: any) => {
	const response = await axios.post(`/tasks`, body);
	return response.data;
};

export const editLoyaltyTask = async (body: any) => {
	const response = await axios.put(`/tasks`, body);
	return response.data;
};

export const deleteLoyaltyTask = async (body: any) => {
	const response = await axios.delete(`/tasks`, {
		params: body,
	});
	return response.data;
};

export const changeLoyaltyTaskStatus = async (body: any) => {
	const response = await axios.put(`/tasks/status`, body);
	return response.data;
};

export const rearrangeTasks = async (campaignId: string, body: any) => {
	const response = await axios.post(
		`/campaigns/${campaignId}/rearrange-task-section`,
		body,
	);
	return response.data;
};

export const updateLoyaltySettings = async (body: any) => {
	const response = await axios.put(`/campaigns/loyalty/settings`, body);
	return response.data;
};
export const hideLoyaltyProgram = async (hide) => {
	const response = await axios.put(`/campaigns/loyalty/hide`, { hide });
	return response.data;
};
