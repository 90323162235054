import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { IIntegration, IntegrationStatus } from '../types/integrations.types';
import { getIntegrations } from '../services/integrations.service';
import { IntegrationType } from '../types/integrations.enums';
import dayjs from 'dayjs';

export const useIntegrations = () => {
	const { data, isLoading } = useQuery<IIntegration[]>({
		queryKey: ['integrations'],
		queryFn: () => getIntegrations(),
	});

	const guildId = useMemo(() => {
		if (!data) return '';
		const discordIntegration = data.find(
			(integration) => integration.integrationType === IntegrationType.Discord,
		);
		if (!discordIntegration) return '';
		return discordIntegration.discord.guildId;
	}, [data]);

	const integrations: IIntegration[] = useMemo(() => {
		if (!data) return [];
		return data
			.map((i) => {
				let name = '';
				let icon = '';
				let color = '';
				let metrics = [];
				if (i.integrationType === IntegrationType.Discord) {
					name = i?.discord.name;
					icon = 'discord';
					color = 'text-discord';
					metrics = i.isSynced
						? [
								i?.discord.metrics.members + ' Members',
								i?.discord.metrics.messages + ' Messages',
							]
						: [];
				} else if (i.integrationType === IntegrationType.Telegram) {
					name = i.telegram.name;
					icon = 'telegram';
					color = 'text-telegram';
					metrics = [];
				}
				return {
					...i,
					name,
					integrationType: i.integrationType,
					status: i.isActive
						? IntegrationStatus.Connected
						: IntegrationStatus.Error,
					icon,
					color,
					metrics,
					url:
						i.integrationType === IntegrationType.Discord
							? `https://discord.com/channels/${i.discord.guildId}`
							: i.integrationType === IntegrationType.Telegram
								? `https://t.me/${i?.telegram?.username}`
								: '',
				};
			})
			.filter((i) => i && i.integrationType);
	}, [data]);

	return {
		integrations,
		isLoading,
		guildId,
	};
};
