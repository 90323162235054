import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IEvent, IEventDetails, IEventSections } from '../../types/events.type';
import { useParams, useSearchParams } from 'react-router-dom';
import { getEvent } from '../../services/event.service';
import { useQuery } from '@tanstack/react-query';
import { ICampaign } from '@/features/campaigns/types';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

export const useCreateEventInitialise = ({
	setDetails,
	setSections,
	setIsInitalising,
	setIsUpdate,
}: {
	setDetails: Dispatch<SetStateAction<IEventDetails>>;
	setSections: Dispatch<SetStateAction<IEventSections[]>>;
	setIsUpdate: Dispatch<SetStateAction<boolean>>;
	setIsInitalising: Dispatch<SetStateAction<boolean>>;
}) => {
	// const [searchParams] = useSearchParams();
	const { id } = useParams();
	const [isMounted, setIsMounted] = useState(false);

	const { data } = useQuery<{ event: IEvent; campaigns: ICampaign[] }>({
		queryKey: ['event', id],
		queryFn: () => getEvent(id),
		enabled: !!id,
	});

	useEffect(() => {
		if (isMounted) return;
		if (!id) {
			setIsInitalising(false);
			analytics.track(TrackingEvents.EventCreateViewed, {
				isUpdate: false,
			});
		}
		setIsMounted(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!data) return;
		const event = data.event;
		const campaigns = data.campaigns;
		analytics.track(TrackingEvents.EventCreateViewed, {
			isUpdate: true,
			eventId: event?._id,
			eventTitle: event?.title,
			eventStatus: event?.status,
		});
		setIsUpdate(true);
		setDetails({
			title: event.title,
			description: event.description,
			startDate: event.startDate,
			endDate: event.endDate,
			bannerFile: null,
			bannerFileLink: event.banner,
			network: event.network,
			startImmediately: event.startImmediately,
			timezone: event.timezone,
			bannerPosition: event.bannerPosition,
		});
		setSections(
			event.sections.map((i, index2) => ({
				_id: i._id,
				title: i.title,
				index: index2,
				campaigns: i.campaigns.map((j, index) => {
					const campaign = campaigns.find((k) => k._id === j.campaignId);
					return {
						_id: campaign._id,
						name: campaign.name,
						startDate: campaign.startDate,
						endDate: campaign.endDate,
						banner: campaign.banner,
						status: campaign.status,
						isComingSoon: false,
						index: index,
					};
				}),
			})),
		);
		setIsInitalising(true);
	}, [data]);

	return {};
};
