import { useCreateCampaign } from './useCreateCampaign';
import { CreateCampaignSections } from '../../types';
import { useCreateCampaignMutations } from './useCreateCampaignMutations';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

export const useCreateCampaignNavigation = () => {
	const { section, setSection } = useCreateCampaign();

	const { isLoading, previewCampaign, launchCampaign, testCampaign, saveDraft } =
		useCreateCampaignMutations();

	const moveForward = () => {
		analytics.track(TrackingEvents.CreateQuestNavigated, {
			actionType: 'Next',
			section: section,
		});
		if (section === CreateCampaignSections.Details) {
			setSection(CreateCampaignSections.Tasks);
			return;
		}
		if (section === CreateCampaignSections.Tasks) {
			setSection(CreateCampaignSections.Rewards);
			return;
		}
	};

	const moveBack = () => {
		analytics.track(TrackingEvents.CreateQuestNavigated, {
			actionType: 'Back',
			section: section,
		});
		if (section === CreateCampaignSections.Tasks) {
			setSection(CreateCampaignSections.Details);
			return;
		}
		if (section === CreateCampaignSections.Rewards) {
			setSection(CreateCampaignSections.Tasks);
			return;
		}
	};

	return {
		moveForward,
		moveBack,
		forwardLabel: 'Next',
		isLoading,
		previewCampaign,
		launchCampaign,
		testCampaign,
		saveDraft,
	};
};
