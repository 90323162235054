import LandingContainer from '../ui/LandingContainer';
import SectionHeader from '../ui/SectionHeader';
import { ppcContent } from '../../content/ppc.content';
import logo from '@/assets/logo.svg';

const FeatureComparison = () => {
	return (
		<LandingContainer>
			<SectionHeader
				title={ppcContent.comparison.title}
				description={ppcContent.comparison.description}
				className="my-20"
				titleGradient="blue"
			/>
			<div className="overflow-x-scroll rounded-xl border border-gray-200 bg-white/10 p-4 shadow-[inset_10px_-50px_94px_0_rgb(199,199,199,0.2)] backdrop-blur md:overflow-x-visible md:px-8">
				<table className="w-full table-fixed">
					<TableHeader />
					<tbody className="divide-y divide-gray-200">
						{ppcContent.comparison.list.map((i) => (
							<TableRow {...i} />
						))}
					</tbody>
				</table>
			</div>
		</LandingContainer>
	);
};

export default FeatureComparison;

const TableHeader = () => {
	return (
		<thead>
			<tr>
				<th className="w-60 md:w-1/2" />
				<th className="w-28 py-4 md:w-auto">
					<img src={logo} className="mx-auto h-9" />
				</th>
				<th className="w-28 py-4 md:w-auto">
					<img
						className="mx-auto h-6"
						src="https://cdn.presskithero.com/rails/active_storage/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa3E3IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--5ed52339e3aeb25ca7d3bed0e4568814af38a5b2/03_Galxe_B_Circle_White%20Symbol.png?disposition=attachment"
						alt="Bitly"
					/>
				</th>
				<th className="w-28 py-4 md:w-auto">
					<img
						className="mx-auto h-6"
						src="https://zealy.io/nstatic/apple-touch-icon.png"
						alt="Bitly"
					/>
				</th>
			</tr>
		</thead>
	);
};

const TableRow = ({ title, description, link, col1, col2, col3 }) => {
	return (
		<tr>
			<td className="p-2 md:p-4">
				<a target="_blank" className="group" href={link}>
					<h3 className="font-display font-bold text-gray-800">{title}</h3>
					<p className="text-sm text-gray-600 underline-offset-4 group-hover:underline">
						{description}
						<span className="opacity-0 transition-all group-hover:opacity-100">
							↗
						</span>
					</p>
				</a>
			</td>
			<td className="p-2 md:p-4">
				{col1 && (
					<i className="bi-check-circle-fill text-zinc-800 mx-auto block text-center text-2xl"></i>
				)}
			</td>
			<td className="p-2 md:p-4">
				<p className="text-center text-gray-600">
					{typeof col2 === 'string' ? (
						col2
					) : col2 ? (
						<i className="bi-check-circle-fill text-zinc-800 mx-auto block text-center text-2xl"></i>
					) : (
						<i className="bi-x-circle-fill text-zinc-500 mx-auto block text-center text-2xl"></i>
					)}
				</p>
			</td>
			<td className="p-2 md:p-4">
				<p className="text-center text-gray-600">
					{typeof col3 === 'string' ? (
						col3
					) : col3 ? (
						<i className="bi-check-circle-fill text-zinc-800 mx-auto block text-center text-2xl"></i>
					) : (
						<i className="bi-x-circle-fill text-zinc-500 mx-auto block text-center text-2xl"></i>
					)}
				</p>
			</td>
		</tr>
	);
};
