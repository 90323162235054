import { IEvent, IEventReport } from '@/features/events/types/events.type';
import EventMetrics from '../components/analytics/EventMetrics';
import { ICampaign } from '@/features/campaigns/types';
import EventCampaignsTable from '../components/analytics/EventCampaignsTable';
import EventCompletionChart from '../components/analytics/EventCompletionChart';

const EventOverview = ({
	report,
	campaigns,
	metrics,
}: {
	event: IEvent;
	report: IEventReport;
	campaigns: ICampaign[];
	metrics: any;
}) => {
	return (
		<div className="">
			<EventMetrics metrics={metrics} />
			<EventCompletionChart report={report} campaigns={campaigns} />
			<EventCampaignsTable campaigns={campaigns} />
		</div>
	);
};

export default EventOverview;
