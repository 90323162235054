import { Route, Routes } from 'react-router-dom';
import OnboardingPage from './onboarding.v2.page';
import { AppError } from '@/components/layout/error/SentryErrorFallback';
import * as Sentry from '@sentry/react';

export default function OnboardingRoutes() {
	return (
		<Sentry.ErrorBoundary fallback={AppError} showDialog>
			<Routes>
				<Route path="" element={<OnboardingPage />} />
			</Routes>
		</Sentry.ErrorBoundary>
	);
}
