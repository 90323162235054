import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import queryString from 'query-string';

export function useQueryInitialise(key: string, value: string, callback: () => any) {
	const params = useParams();
	const location = useLocation();
	const [, setSearchParam] = useSearchParams();

	const query = {
		...queryString.parse(location.search),
		...params,
	};

	useEffect(() => {
		if (query[key] === value) {
			callback?.();
			setSearchParam({});
		}
	}, [query]);
}
