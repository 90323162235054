import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { Separator } from '@/components/ui/separator';
import LoyaltyTaskFeatures from './steps/LoyaltyTaskFeatures';
import LoyaltyTaskParams from './steps/LoyaltyTaskParams';
import { useLoyaltyTasks } from '../../hooks/tasks/useLoyaltyTasks';
import { useLoyaltyTasksNavigate } from '../../hooks/tasks/useLoyaltyTasksNavigate';
import { LoyaltyTaskCreateSteps } from '../../types/loyalty.enums';
import AddLoyaltyTaskNavigation from './AddLoyaltyTaskNavigation';
import { useLoyaltyTasksInitialise } from '../../hooks/tasks/useLoyaltyTasksInitialise';
import { SearchTaskTemplates } from '@/features/campaigns/components/create-campaign/tasks/components/Templates/SearchTaskTemplates';
import { useLoyaltyCampaign } from '../../hooks/useLoyaltyCampaign';
import { formatNumberWithThSt } from '@/utils/parsers';
import LoyaltyTaskDuration from './steps/LoyaltyTaskDuration';
import { TaskStatus } from '@/features/campaigns/types/tasks.enums';
import { links } from '@/config/links';

const AddLoyaltyTask = ({
	showTemplates,
	setShowTemplates,
}: {
	showTemplates: boolean;
	setShowTemplates: any;
}) => {
	const { campaign } = useLoyaltyCampaign();

	const { task, template, step, showAddTask, selectedSectionId, isUpdate } =
		useLoyaltyTasks();
	const { onClose } = useLoyaltyTasksNavigate();
	const { formatTaskTemplate } = useLoyaltyTasksInitialise();

	const section = campaign?.taskSections?.find(
		(section) => section._id === selectedSectionId,
	);
	const taskIndex = task?.createdAt
		? section?.displayData?.findIndex((i) => i.unitId === task._id) + 1
		: section?.displayData.length;

	return (
		<>
			{task && template && (
				<Dialog open={showAddTask} onOpenChange={() => onClose()}>
					<DialogContent className="sm:max-w-[635px] px-0 p-0 overflow-visible">
						<DialogHeader className="border-b p-3 px-4 flex flex-row justify-between items-center">
							<div className="flex space-x-2 items-center text-black font-medium">
								<i className="bi-plus-circle-dotted "></i>
								<div className="text-sm text">
									{task?.createdAt
										? 'Updating Task'
										: 'Creating New Task'}
								</div>
							</div>
							<div className="flex items-center">
								<div
									className="text-xs hover:underline cursor-pointer"
									onClick={() => {
										window.open(
											links.docs.updatingTasks,
											'_blank',
										);
									}}
								>
									Learn more
								</div>
								<Separator orientation="vertical" className="mx-3" />
								<i
									className="bi-x text-xl text-muted-foreground"
									onClick={() => onClose()}
								></i>
							</div>
						</DialogHeader>
						<div className=" px-4">
							<div className="text-xs  mb-2">
								{section?.title} / {formatNumberWithThSt(taskIndex)}{' '}
								Task
							</div>
							<div className="text-xs  text-muted-foreground mt-1 mb-6">
								{template?.description}
							</div>
							<div>
								<div className="min-h-[420px]">
									{step === LoyaltyTaskCreateSteps.TaskParams && (
										<LoyaltyTaskParams isUpdate={isUpdate} />
									)}
									{!(
										isUpdate && task.status === TaskStatus.Active
									) &&
										step ===
											LoyaltyTaskCreateSteps.TaskFeatures && (
											<LoyaltyTaskFeatures />
										)}
									{!(
										isUpdate && task.status === TaskStatus.Active
									) &&
										step ===
											LoyaltyTaskCreateSteps.TaskDuration && (
											<LoyaltyTaskDuration />
										)}
								</div>
							</div>
						</div>
						<AddLoyaltyTaskNavigation />
					</DialogContent>
				</Dialog>
			)}
			<SearchTaskTemplates
				open={showTemplates}
				setOpen={setShowTemplates}
				addTask={(template: any, _icon: any) => {
					setShowTemplates(false);
					formatTaskTemplate(template);
				}}
			/>
		</>
	);
};

export default AddLoyaltyTask;
