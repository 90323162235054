import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { TooltipProvider } from '@/components/ui/tooltip';
import { AlphaHubNarrativeStatus } from '../../types/events.type';
import StatusBadge from '@/components/element/badges/StatusBadge';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { QUEST_URL } from '@/config';
import { useNavigate } from 'react-router-dom';
const tabs = [
	{
		value: 'overview',
		label: 'Overview',
	},
	{
		value: 'leaderboard',
		label: 'Leaderboard',
	},
];

const EventReportHeader = ({
	selectedTab,
	setSelectedTab,
	name,
	status,
	_id,
}: {
	selectedTab: string;
	setSelectedTab: (value: any) => void;
	name: string;
	_id: string;
	status: AlphaHubNarrativeStatus;
}) => {
	const navigate = useNavigate();
	return (
		<div>
			<div className="flex items-end space-x-3 justify-between">
				<h2 className="text-2xl font-medium tracking-tight ">{name}</h2>
				<Badge variant="secondary" className="border bg-green-100 mb-1">
					<StatusBadge status={status} />
				</Badge>
			</div>
			<p className="text-sm mb-3 mt-2 text-muted-foreground">
				View your event details and analytics here
			</p>
			<div className="flex items-end justify-between">
				<div className="space-y-1">
					<div className="flex">
						<Tabs value={selectedTab} className=" mt-4">
							<TabsList className="grid  grid-cols-2">
								{tabs.map((i) => (
									<div>
										<TabsTrigger
											value={i.value}
											key={i.value}
											onClick={() => setSelectedTab(i.value)}
										>
											{i.label}
										</TabsTrigger>
									</div>
								))}
							</TabsList>
						</Tabs>
					</div>
				</div>
				<div className="ml-auto space-x-2">
					<Button
						variant="outline"
						onClick={() => {
							navigate(`/app/campaign/events/${_id}/update`);
						}}
					>
						<i className="bi-pencil-square me-2"></i>
						Edit Event
					</Button>
					<TooltipWrapper
						tooltip="Check how your campaign looks"
						align="end"
					>
						<Button
							variant="outline"
							size="icon"
							className=""
							onClick={() => {
								window.open(`${QUEST_URL}/events/${_id}`, '_blank');
							}}
						>
							<i className="bi bi-box-arrow-up-right"></i>
						</Button>
					</TooltipWrapper>
					{/* <Button variant="outline" size="icon" className="ms-2">
						<i className="bi-three-dots-vertical"></i>
					</Button> */}
				</div>
			</div>
		</div>
	);
};

export default EventReportHeader;
