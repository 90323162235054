import { useState } from 'react';
import { setUpCommunityProfileAPIV2 } from '../services/onboarding.service';
import { useAuth } from '@/hooks/useAuth';
import { handleErrorMessage } from '@/utils/notifications';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

export const useOnboarding = () => {
	const { refetchUser } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [community, setCommunity] = useState({
		name: '',
		twitter: '',
	});

	const [errors, setErrors] = useState({
		name: false,
		twitter: false,
	});

	const handleCommunitySetup = async () => {
		try {
			setIsLoading(true);
			if (!community.name || !community.twitter) {
				if (!community.name) setErrors((prev) => ({ ...prev, name: true }));
				if (!community.twitter)
					setErrors((prev) => ({ ...prev, twitter: true }));
				return;
			}
			await setUpCommunityProfileAPIV2(community);
			analytics.track(TrackingEvents.OnboardingCommunityUpdatedV2, {
				enterpriseName: community.name,
			});
			setIsLoading(false);
			await refetchUser();
			setIsLoading(false);
		} catch (err) {
			handleErrorMessage(err);
			setIsLoading(false);
			console.log(err);
		}
	};

	return {
		handleCommunitySetup,
		community,
		setCommunity,
		isLoading,
		errors,
		setErrors,
	};
};
