import { useContext } from 'react';
import { DaveActionCreateContext } from '../../providers/DaveActionCreateProvider';

export const useCreateActionProvider = () => {
	const context = useContext(DaveActionCreateContext);

	if (context === undefined)
		throw new Error(
			'useCreateCampaign must be used within a CreateCampaignProvider',
		);

	return context;
};
