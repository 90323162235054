import { useAuth } from '@/hooks/useAuth';
import { Button } from '@/components/ui/button';
import VerifyProjectsDialog from '../verify/VerifyProject';
import { Progress } from '@/components/ui/progress';
import { Link } from 'react-router-dom';

export const BottomNavSection = ({ openVerifyProjects, setOpenVerifyProjects }) => {
	const { user, toggleAdminPanel } = useAuth();
	return (
		<>
			<div className="mt-auto">
				<CompleteOnboarding />
				{/* {enterprise?.isApproved === false && !user?.isIntractAdmin && (
					<div className="border p-4 m-3 rounded-md bg-slate-100 dark:bg-slate-900 relative">
						<div className="text-xs font-medium">
							Your account is pending approval.
						</div>
						<Button
							className="p-0 mt-1 h-auto text-xs text-muted-foreground"
							variant="link"
							onClick={() => {
								analytics.track(
									TrackingEvents.ApprovalDialogViewed,
									{},
								);
								setOpenVerifyProjects(true);
							}}
						>
							Learn more
						</Button>
					</div>
				)} */}
				{user?.isIntractAdmin && (
					<div className=" px-4 pb-4">
						<Button
							className="w-full"
							variant="outline"
							onClick={() => toggleAdminPanel()}
						>
							<i className="bi bi-gear me-2"></i> Shift to Admin Panel
						</Button>
					</div>
				)}
				<div className="space-y-4 p-4 border-t pb-5  text-center">
					<div className="text-xs text-muted-foreground">
						2024 Intract.
					</div>
				</div>
			</div>
			<VerifyProjectsDialog
				open={openVerifyProjects}
				setOpen={setOpenVerifyProjects}
			/>
		</>
	);
};

const CompleteOnboarding = () => {
	return (
		<Link
			to="/app/getting-started"
			className="border block p-4 m-3 rounded-md bg-white dark:bg-slate-900 relative hover:bg-zinc-100 cursor-pointer transition-all duration-200"
		>
			<div className="flex justify-between items-center">
				<div className="text-xs font-semibold">Getting Started</div>
				<i className="bi bi-chevron-right text-xs cursor-pointer"></i>
			</div>
			<div className="mt-3 flex gap-1 items-center">
				<span className="text-xs me-2">1/4</span>
				<Progress className="bg-violet-400" />
				<Progress />
				<Progress />
				<Progress />
			</div>
		</Link>
	);
};
