import RewardsHubPage from './reward-hub.page';

const RewardHubRoutes = () => {
	return (
		<div>
			<RewardsHubPage />
		</div>
	);
};

export default RewardHubRoutes;
