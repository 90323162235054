import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import clsx from 'clsx';
import { useState } from 'react';

const InputPassword = ({
	labelClassName,
	className,
	placeholder,
	label,
	required,
	tooltip,
	error,
	value,
	setValue,
	errorText,
}: {
	labelClassName?: string;
	className?: string;
	label: string;
	required?: boolean;
	tooltip?: string;
	error: boolean | string;
	setValue: (value: string) => void;
	value: string;
	errorText?: string;
	placeholder?: string;
}) => {
	const [show, setShow] = useState(false);

	return (
		<div className={className}>
			<Label className={clsx('flex items-center mb-2', labelClassName)}>
				{label}
				{tooltip && (
					<TooltipProvider delayDuration={0}>
						<Tooltip>
							<TooltipTrigger className="ms-2">
								<i className="bi-info-circle-fill block text-muted-foreground text-xs cursor-pointer"></i>
							</TooltipTrigger>
							<TooltipContent>
								<div className="text-sm font-normal max-w-[400px]">
									{tooltip}
								</div>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				)}
				{required && <span className="text-red-400 ms-1">*</span>}
			</Label>
			<div className="relative">
				<Input
					placeholder={placeholder}
					autoCorrect="off"
					value={value}
					onChange={(e) => setValue(e.target.value)}
					className={error ? 'border-destructive' : ''}
					type={show ? 'text' : 'password'}
				/>
				<span
					className="absolute right-2 top-2 cursor-pointer text-muted-foreground"
					onClick={() => setShow((prev) => !prev)}
				>
					<i className={`bi-${show ? 'eye-slash' : 'eye'}`}></i>
				</span>
			</div>
			{error && (
				<p className={cn('text-xs  text-destructive mt-1')}>{errorText}</p>
			)}
		</div>
	);
};

export default InputPassword;
