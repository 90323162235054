import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuPortal,
	DropdownMenuSeparator,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useAuth } from '@/hooks/useAuth';
import { Link } from 'react-router-dom';
import CreateEnterpriseDialog from '@/features/onboarding/components/create-enterprise/CreateEnterprise';
import { useState } from 'react';
import { useSwitchEnterprise } from '../../hooks/useSwitchEnterrpise';
import { PlusCircleIcon } from 'lucide-react';
import Spinner from '@/components/element/loading/Spinner';
import { Input } from '@/components/ui/input';

export function UserNav() {
	const { logout, user, refetchUser } = useAuth();
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState('');
	const {
		handleSwitchEnterprise,
		enterprises,
		primaryEnterpriseIndex,
		isSwitching,
	} = useSwitchEnterprise();

	if (!user) refetchUser();

	const handleLogout = (e: any) => {
		e?.preventDefault();
		logout();
	};

	return (
		<>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button
						variant="ghost"
						className="relative size-[30px] md:flex hidden rounded-full"
					>
						<Avatar className="size-[30px]">
							{user && user.avatar && (
								<AvatarImage src={user?.avatar} alt="@username" />
							)}
							{user && user?.name && (
								<AvatarFallback name={user?.name} />
							)}
						</Avatar>
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent className="w-56" align="end" forceMount>
					<DropdownMenuLabel className="font-normal">
						<div className="flex flex-col space-y-1">
							<p className="text-sm font-medium leading-none">
								{user.name}
							</p>
							<p className="text-xs leading-none text-muted-foreground">
								{user.email}
							</p>
						</div>
					</DropdownMenuLabel>
					<DropdownMenuSeparator />
					<DropdownMenuGroup>
						<DropdownMenuItem asChild>
							<Link to="/app/profile/referral">
								<i className="bi-people me-2"></i>Referral
							</Link>
						</DropdownMenuItem>
						<DropdownMenuItem asChild>
							<Link to="/app/profile/team">
								<i className="bi-person-plus me-2"></i>Invite Team
							</Link>
						</DropdownMenuItem>
						<DropdownMenuItem asChild>
							<Link to="/app/profile/exports">
								<i className="bi-cloud-download me-2"></i>
								Exports
							</Link>
						</DropdownMenuItem>
					</DropdownMenuGroup>
					<DropdownMenuSeparator />
					<DropdownMenuSub>
						<DropdownMenuSubTrigger>
							<span className="flex items-center justify-center">
								<Avatar className="size-7">
									<AvatarImage
										src={
											enterprises[primaryEnterpriseIndex]?.logo
										}
										alt="@username"
									/>
									<AvatarFallback
										name={
											enterprises[primaryEnterpriseIndex]?.name
										}
									/>
								</Avatar>
								{isSwitching ? (
									<span className="md:mx-3 ml-3 mr-1 text-sm w-[60px]">
										<Spinner />
									</span>
								) : (
									<span className="md:mx-3 ml-3 mr-1 text-sm flex items-center">
										{enterprises[primaryEnterpriseIndex]?.name}
										{enterprises[primaryEnterpriseIndex]
											?.isApproved && (
											<i className="bi bi-patch-check-fill text-green-500 ms-3"></i>
										)}
									</span>
								)}
							</span>
						</DropdownMenuSubTrigger>
						<DropdownMenuPortal>
							<DropdownMenuSubContent className="min-w-[200px] max-h-[400px] overflow-y-auto">
								<div>
									<Input
										value={search}
										onChange={(e) => setSearch(e.target.value)}
										placeholder="Search your projects"
										className="h-7 mb-1"
									/>
								</div>
								{enterprises
									?.filter((i) => {
										if (!search) return true;
										return i.name
											.toLowerCase()
											.includes(search.toLowerCase());
									})
									?.map((i) => (
										<DropdownMenuItem
											className="space-x-3"
											key={i._id}
											onClick={() =>
												handleSwitchEnterprise(i._id)
											}
										>
											<Avatar className="size-4">
												<AvatarImage
													src={i.logo}
													alt="@tesla"
												/>
												<AvatarFallback name={i.name} />
											</Avatar>
											<span>{i.name}</span>
											{i.isApproved && (
												<i className="bi bi-patch-check-fill text-green-500"></i>
											)}
										</DropdownMenuItem>
									))}
								<DropdownMenuItem
									className="space-x-3"
									onClick={() => setOpen(true)}
								>
									<PlusCircleIcon className="size-4" />
									<span>Create Project</span>
								</DropdownMenuItem>
							</DropdownMenuSubContent>
						</DropdownMenuPortal>
					</DropdownMenuSub>
					<DropdownMenuSeparator />
					<DropdownMenuItem asChild>
						<button className="w-full" onClick={handleLogout}>
							Log out
						</button>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
			<CreateEnterpriseDialog open={open} setOpen={setOpen} />
		</>
	);
}
