import { IOnboardingSocials } from '../../hooks/useOnboarding';
import { Dispatch, SetStateAction } from 'react';
import OnboardingStepLayout from '../onboarding/OnboardingStepLayout';
import InputText from '@/components/element/inputs/InputText';

const SetUpSocials = ({
	socials,
	setSocials,
	errors,
}: {
	socials: IOnboardingSocials;
	setSocials: Dispatch<SetStateAction<IOnboardingSocials>>;
	errors: {
		website: boolean;
		twitter: boolean;
		discord: boolean;
	};
}) => {
	return (
		<>
			<InputText
				label="Project Twitter"
				required={true}
				placeholder="intractCampaign"
				prepend={
					<div className="ps-3 pe-2 flex items-center text-xs">
						<i className="bi bi-twitter text-twitter pe-2"></i>
						<span className="text-muted-foreground font-medium">
							https://twitter.com/
						</span>
					</div>
				}
				value={socials.twitter}
				setValue={(e) =>
					setSocials({
						...socials,
						twitter: e,
					})
				}
				errorText="Please enter your twitter username"
				error={errors.twitter}
			/>
			<InputText
				label="Project Discord Invite Link"
				required={true}
				placeholder="discord.gg/intract"
				value={socials.discord}
				setValue={(e) =>
					setSocials({
						...socials,
						discord: e,
					})
				}
				prepend={
					<div className="ps-3 pe-2 flex items-center text-xs">
						<i className="bi bi-discord text-discord pe-2"></i>
						<span className="text-muted-foreground font-medium">
							https://
						</span>
					</div>
				}
				errorText="Please enter valid discord invite link"
				error={errors.discord}
			/>
			<InputText
				label="Project Website"
				required={true}
				placeholder="intract.io"
				value={socials.website}
				setValue={(e) =>
					setSocials({
						...socials,
						website: e,
					})
				}
				prepend={
					<div className="ps-3 pe-2 flex items-center text-xs">
						<i className="bi bi-globe text-red-400 pe-2"></i>
						<span className="text-muted-foreground font-medium">
							https://
						</span>
					</div>
				}
				errorText="Please enter your valid website"
				error={errors.website}
			/>
		</>
	);
};

export default SetUpSocials;
