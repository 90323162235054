import { queryClient } from '@/lib/react-query';
import { toast } from 'sonner';
import { updateCustomTokenApproval } from '../services/admin.service';
import { CustomTokenStatus } from '@/features/campaigns/hooks/tokens/useCreateToken';

export const useAdminCustomTokensActions = ({ refetch }) => {
	const toggleApprovalStatus = async (tokenId: string, status: string) => {
		return new Promise((resolve) => {
			toast.promise(
				Promise.all([
					updateCustomTokenApproval(tokenId, { status }).then((res) => {
						resolve(res);
						refetch();
					}),
				]),
				{
					loading:
						status === CustomTokenStatus.Approved
							? 'Approving token'
							: 'Disapproving token',
					success:
						status === CustomTokenStatus.Approved
							? 'Token approved successfully'
							: 'Token disapproved successfully',
					error:
						status === CustomTokenStatus.Approved
							? 'Failed to approve token'
							: 'Failed to disapprove token',
				},
			);
		});
	};

	return {
		toggleApprovalStatus,
	};
};
