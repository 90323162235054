import { SelectWithSearch } from '../SelectWithSearch';

export const SelectTime = ({ time, setTime, disabled, options }) => {
	return (
		<SelectWithSearch
			options={options}
			selected={time}
			setSelected={setTime}
			placeholder="Pick a time"
			disabled={disabled}
		/>
	);
};
