import React from 'react';

const WaitingForData = () => {
	return (
		<div className="h-full w-full flex flex-col items-center justify-center col-span-6 border rounded-lg bg-slate-50 border-dashed">
			<div className='mb-9"'>
				<i className="bi-hourglass-split text-2xl text-muted-foreground "></i>
			</div>
			<div>Waiting For Data</div>
		</div>
	);
};

export default WaitingForData;
