import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IntroducingDave from '../components/IntroducingDave';
import { useGetQueries } from '../hooks/queries/useGetQueries';
import { useGetActions } from '../hooks/actions/useGetActions';
import ActionsTable from '../components/tables/ActionsTable';
import QueriesTable from '../components/tables/QueriesTable';
import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import NoDataCard from '@/components/element/cards/NoDataCard';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import PageHeader from '@/components/layout/PageHeader';

const DavePage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [showIntroduction, setShowIntroduction] = useState(false);
	const [search, setSearch] = useState('');
	const {
		queries,
		isLoading: isQueriesLoading,
		hasQueries,
	} = useGetQueries(search);
	const {
		actions,
		isLoading: isActionsLoading,
		hasActions,
	} = useGetActions(search);

	if (isActionsLoading || isQueriesLoading) {
		return <FullPageLoading />;
	}

	const selected = location.pathname.includes('actions') ? 'actions' : 'queries';

	return (
		<div>
			<PageHeader
				title="Dynamic Auto Verification Engine (DAVE)"
				description="Verify user on chain actions without writing a single line of code."
			>
				<Button variant="outline" onClick={() => setShowIntroduction(true)}>
					<i className="bi bi-info-circle me-2"></i> Learn about DAVE
				</Button>
			</PageHeader>

			{!hasActions && !hasQueries ? (
				<NoDataCard
					title="No Actions or Queries"
					description="You haven't created any actions or queries yet."
					btnLabel="Create Action"
					btnOnClick={() => navigate('/app/tools/dave/action/new')}
					className="mt-10"
					image="https://framerusercontent.com/images/P6GMY0Ml4unFcts3BgQGGCOKwzo.png"
				/>
			) : (
				<>
					<div className="flex mt-10 justify-between">
						<div className="inline-flex h-9 items-center justify-center rounded-lg bg-muted p-1 text-muted-foreground">
							<div
								className="inline-flex px-10 items-center justify-center whitespace-nowrap rounded-md cursor-pointer py-1 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
								data-state={selected === 'actions' ? 'active' : ''}
								onClick={() => navigate('/app/tools/dave/actions')}
							>
								<i className={cn('bi-transparency', 'me-2')}></i>
								Actions
							</div>
							<div
								className="inline-flex px-10 items-center justify-center whitespace-nowrap rounded-md cursor-pointer py-1 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
								data-state={selected === 'queries' ? 'active' : ''}
								onClick={() => navigate('/app/tools/dave/queries')}
							>
								<i className={cn('bi-ui-checks', 'me-2')}></i>
								Queries
							</div>
						</div>
						<div className="flex space-x-4">
							<InputText
								placeholder="Search"
								value={search}
								setValue={(e) => setSearch(e)}
								prepend={<i className="bi bi-search mx-3"></i>}
							/>
							{selected === 'actions' && (
								<Button
									onClick={() =>
										navigate(`/app/tools/dave/action/new`)
									}
								>
									<i className="bi bi-plus-circle-fill me-2"></i>{' '}
									Create Action
								</Button>
							)}
							{selected === 'queries' && (
								<Button
									onClick={() =>
										navigate(`/app/tools/dave/query/new`)
									}
								>
									<i className="bi bi-plus-circle-fill me-2"></i>{' '}
									Create Query
								</Button>
							)}
						</div>
					</div>
					{selected === 'actions' && (
						<ActionsTable
							actions={actions}
							isLoading={isActionsLoading}
						/>
					)}
					{selected === 'queries' && (
						<QueriesTable
							queries={queries}
							actions={actions}
							isLoading={isQueriesLoading}
						/>
					)}
				</>
			)}
			<IntroducingDave open={showIntroduction} setOpen={setShowIntroduction} />
		</div>
	);
};

export default DavePage;
