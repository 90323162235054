import { useQuery } from '@tanstack/react-query';
import { getEnterprise } from '@/features/customise-hub/services/community.service';
import { ICommunityHub } from '@/features/customise-hub/providers/CommunityHubProvider';
import { IEnterprise } from '../types/enterprise.types';

export const useGetEnterprise = () => {
	const { data, isLoading } = useQuery<IEnterprise>({
		queryKey: ['enterprise'],
		queryFn: () => getEnterprise(),
	});

	return {
		enterprise: data,
		isLoading,
	};
};
