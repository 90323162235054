export const sampleNFTs = [
	{
		name: '1inch',
		artist: 'Lena Logic',
		cover: 'https://static.highongrowth.xyz/erc1155-images/654c82a733cc8772783ed7c1/ecd47269d6a64e8eaef70bd250105d67.png',
	},
	{
		name: 'Solana Stars',
		artist: 'Beth Binary',
		cover: 'https://static.highongrowth.xyz/erc1155-images/65017d014e5f24613adbfd67/7dd21d2d60c14483a6a8c7a5ab6419f7.png',
	},
	{
		name: 'Rhino.Fi',
		artist: 'Ethan Byte',
		cover: 'https://static.highongrowth.xyz/erc1155-images/656befd0ee0e51cc5fe04281/e378e203ee77471686b1e0d682872d38.png',
	},
	{
		name: 'Stateful Symphony',
		artist: 'Beth Binary',
		cover: 'https://static.highongrowth.xyz/erc1155-images/652e5f331fc5a47751cf2d60/1240224b04b542eb8d12605f3fb84339.png',
	},
	{
		name: 'Async Awakenings',
		artist: 'Nina Netcode',
		cover: 'https://static.highongrowth.xyz/erc1155-images/6579de60838f26eff87771f0/073c264df68d47d8874a666ee65e16c0.png',
	},
	{
		name: 'Stateful Symphony',
		artist: 'Beth Binary',
		cover: 'https://static.highongrowth.xyz/erc1155-images/656e038aee0e51cc5fe30d4e/1d3c5ae79a00422489084d4e8f2cc2b3.png',
	},
];
