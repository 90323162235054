import { useEventReport } from '../hooks/report/useEventReport';
import EventOverview from '../components/events-report/sections/Overview';
import EventReportHeader from '../components/events-report/EventReportHeader';
import EventLeaderboard from '../components/events-report/sections/Leaderboard';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';

const EventsReportPage = () => {
	const {
		selectedTab,
		setSelectedTab,
		event,
		campaigns,
		isLoading,
		eventReport,
		metrics,
	} = useEventReport();
	if (isLoading) return <FullPageLoading />;
	return (
		<div>
			<EventReportHeader
				selectedTab={selectedTab}
				setSelectedTab={setSelectedTab}
				name={event?.title}
				status={event?.status}
				_id={event?._id}
			/>
			{selectedTab === 'overview' && (
				<EventOverview
					event={event}
					campaigns={campaigns}
					metrics={metrics}
					report={eventReport}
				/>
			)}
			{selectedTab === 'leaderboard' && <EventLeaderboard />}
		</div>
	);
};

export default EventsReportPage;
