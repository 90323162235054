import { Navigate, Route, Routes } from 'react-router-dom';
import CustomizeHubLayout from '../components/layout/CustomizeHubLayout';
import { CommunityHubProvider } from '../providers/CommunityHubProvider';
import CustomiseHubPage from './customise-hub.page';
import ImportedTokens from './imported-tokens.page';
import ImportedContracts from './imported-contracts.page';
import ProjectSocials from './customise-socials.page';

const CustomiseHubRoutes = () => {
	return (
		<CommunityHubProvider>
			<CustomizeHubLayout>
				<Routes>
					<Route path="profile" element={<CustomiseHubPage />} />
					<Route path="socials" element={<ProjectSocials />} />
					<Route path="tracked-tokens" element={<ImportedTokens />} />
					<Route
						path="tracked-contracts"
						element={<ImportedContracts />}
					/>
					<Route path="*" element={<Navigate replace to="/" />} />
				</Routes>
			</CustomizeHubLayout>
		</CommunityHubProvider>
	);
};

export default CustomiseHubRoutes;
