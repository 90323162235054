import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { cn } from '@/lib/utils';
import { useState } from 'react';

const introductionContent = [
	{
		step: 'introduction',
		content: {
			title: 'Introducing DAVE: Your Gateway to Effortless Blockchain Task Verification',
			list: [
				`DAVE streamlines the verification of blockchain transactions for your quests, making it simpler than ever.`,
				`By allowing you to define actions and queries without the need for complex coding, you can directly connect user actions with your tasks`,
				'Say goodbye to engineering bottlenecks and hello to seamless user action verification.',
			],
		},
	},
	{
		step: 'actions',
		content: {
			title: 'Crafting Actions with DAVE',
			list: [
				`Actions are the building blocks of your DAVE. They define which blockchain transactions count as valid user activities.`,
				`Whether it’s a token swap or a lending operation, you can tailor actions to match the specific requirements of your quest`,
				'Plug and play UI components that you can drop into your app to easily integrate web3 functionality',
			],
		},
	},
	{
		step: 'queries',
		content: {
			title: 'Formulating Queries for Precise Verification!',
			list: [
				`Queries let you fine-tune how actions are verified, specifying the conditions under which user transactions fulfill quest tasks`,
				`From setting minimum token transfers to choosing specific tokens, queries ensure that only the right actions count.`,
			],
		},
	},
	{
		step: 'tasks',
		content: {
			title: 'Attaching Queries to Tasks',
			list: [
				`The final step in utilizing DAVE is attaching your carefully crafted queries to tasks.`,
				`DAVE automatically verifies user actions against the query criteria, streamlining task completion and reward distribution.`,
			],
		},
	},
];

const IntroducingDave = ({ open, setOpen }) => {
	const [direction, setDirection] = useState('next');
	const [active, setActive] = useState('introduction');

	const handleNext = () => {
		setDirection('next');
		if (active === 'introduction') setActive('actions');
		else if (active === 'actions') setActive('queries');
		else if (active === 'queries') setActive('tasks');
		else if (active === 'tasks') setOpen(false);
	};

	const handleBack = () => {
		setDirection('back');
		if (active === 'tasks') setActive('queries');
		else if (active === 'queries') setActive('actions');
		else if (active === 'actions') setActive('introduction');
	};

	const nav = [
		{
			label: 'Actions',
			value: 'actions',
		},
		{
			label: 'Queries',
			value: 'queries',
		},
		{
			label: 'Tasks',
			value: 'tasks',
		},
	];

	const content = introductionContent.find((item) => item.step === active);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:min-w-[825px] p-0 border-0 flex items-center">
				<div
					className="w-[680px] h-[480px] rounded-l-lg flex items-center justify-center p-8"
					style={{
						background:
							'linear-gradient(147.15deg, rgb(65, 10, 182) 30.17%, rgb(212, 92, 255) 100.01%)',
					}}
				>
					{nav.map((i) => i.value).includes(active) ? (
						<div className="text-center text-5xl">
							{nav.map((item) => (
								<div
									className={`mb-10 font-medium transition-all duration-300 ease-out text-white ${
										item.value === active ? '' : 'opacity-15'
									}`}
								>
									{item.label}
								</div>
							))}
						</div>
					) : (
						<>
							<img
								src="https://dashboard-client-v2-as3u.vercel.app/assets/bg-1-ij2nh8ix.png"
								className=" rounded-lg"
							/>
						</>
					)}
				</div>
				<DialogHeader
					className="p-4 pb-8 pt-0  h-[480px] w-[900px] flex justify-between py-10 flex-col"
					key={active}
				>
					<div>
						<DialogTitle
							className={cn(
								`text-2xl mb-5  opacity-0 [--slidein-delay:200ms]`,
								direction === 'next'
									? 'animate-slide-left'
									: 'animate-slide-right',
							)}
						>
							{content.content?.title}
						</DialogTitle>
						<div
							className={cn(
								`opacity-0 [--slidein-delay:300ms]`,
								direction === 'next'
									? 'animate-slide-left'
									: 'animate-slide-right',
							)}
						>
							<ul className="mt-6 text-sm list-disc px-4">
								{content.content?.list.map((i) => (
									<li className="pb-3" key={i}>
										{i}
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="flex space-x-3 mt-auto">
						<Button
							variant="secondary"
							className=" px-7"
							onClick={handleBack}
						>
							Back
						</Button>
						<Button className=" ps-8 pe-7" onClick={handleNext}>
							{active === 'tasks'
								? 'Create your first action'
								: 'Next'}
							<i className="bi-arrow-right ms-3"></i>
						</Button>
					</div>
				</DialogHeader>
			</DialogContent>
		</Dialog>
	);
};

export default IntroducingDave;
