import { Button } from '@/components/ui/button';
import { Progress } from '@/components/ui/progress';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';

export const Checklist = () => {
	return (
		<div className="mt-2">
			<div className="w-2/3 flex items-center">
				<span className="me-2 font-medium text-xs">1/4</span>
				<Progress
					title="Create your first project"
					value={10}
					max={100}
					className="h-2.5 bg-violet-100"
				/>
			</div>
			<Accordion type="single" collapsible className="w-full mt-5 space-y-5">
				{checklist.map((i) => (
					<ChecklistItem
						id={i.id}
						title={i.title}
						description={i.description}
						cta={i.cta}
						icon={i.icon}
						completed={i.completed}
					/>
				))}
			</Accordion>
		</div>
	);
};
const checklist = [
	{
		id: 1,
		title: 'Create your first project',
		description:
			'Create your first project to get started with your community hub. Create your first project to get started with your community hub.',
		cta: { label: 'Create Project', link: '/projects/new' },
		icon: 'file-earmark-text',
		completed: true,
	},
	{
		id: 2,
		title: 'Create your first project',
		description:
			'Create your first project to get started with your community hub. Create your first project to get started with your community hub.',
		cta: { label: 'Create Project', link: '/projects/new' },
		icon: 'file-earmark-text',
		completed: false,
	},
	{
		id: 3,
		title: 'Create your first project',
		description:
			'Create your first project to get started with your community hub. Create your first project to get started with your community hub.',
		cta: { label: 'Create Project', link: '/projects/new' },
		icon: 'file-earmark-text',
		completed: false,
	},
	{
		id: 4,
		title: 'Create your first project',
		description:
			'Create your first project to get started with your community hub. Create your first project to get started with your community hub.',
		cta: { label: 'Create Project', link: '/projects/new' },
		icon: 'file-earmark-text',
		completed: false,
	},
	{
		id: 5,
		title: 'Create your first project',
		description:
			'Create your first project to get started with your community hub. Create your first project to get started with your community hub.',
		cta: { label: 'Create Project', link: '/projects/new' },
		icon: 'file-earmark-text',
		completed: false,
	},
	{
		id: 6,
		title: 'Create your first project',
		description:
			'Create your first project to get started with your community hub. Create your first project to get started with your community hub.',
		cta: { label: 'Create Project', link: '/projects/new' },
		icon: 'file-earmark-text',
		completed: false,
	},
];
const ChecklistItem = ({ id, title, description, cta, icon, completed }) => {
	return (
		<AccordionItem
			className="border-b  py-0 border rounded-xl px-4"
			value={`${id}`}
		>
			<AccordionTrigger>
				<div className="flex items-center space-x-">
					<i
						className={`bi  ${completed ? 'bi-check-circle-fill text-green-600' : 'bi-circle'} me-2`}
					/>
					<h3 className="font-semibold text-sm">{title}</h3>
				</div>
			</AccordionTrigger>
			<AccordionContent className=" text-sm">
				<div className="pt-0">
					<p className="text-sm text-gray-500">{description}</p>
					<Button size="sm" className="mt-4" variant="outline">
						{cta.label} <i className="bi bi-chevron-right ms-2"></i>
					</Button>
				</div>
			</AccordionContent>
		</AccordionItem>
	);
};
