import { useState } from 'react';
import CriteriaGuide from '../guide/CriteriaGuide';
import InputSelect2 from '@/components/element/inputs/InputSelect2';
import { Separator } from '@/components/ui/separator';
import { useCreateActionProvider } from '../../hooks/actions/useCreateActionProvider';
import { useActionCriterias } from '../../hooks/actions/useActionCriterias';
import { contextualFormLabels } from '../../content/action.content';
import { Button } from '@/components/ui/button';
import SelectSecondaryContract from './details/SelectSecondaryContract';

const ActionCriterias = () => {
	const { details, errors } = useCreateActionProvider();
	const [defaultGuideType, setDefaultGuideType] = useState('');
	const [showOtherContracts, setShowOtherContracts] = useState(false);
	const [showGuide, setShowGuide] = useState(false);
	const {
		functions,
		events,
		handleFunctionSelected,
		handleEventSelected,
		handleUserAddressSelected,
		userAddressParams,
	} = useActionCriterias();

	const content = contextualFormLabels(details.category);

	return (
		<>
			<div className="w-full">
				<div className="flex justify-center items-center pt-10">
					<div className="mb-5 w-[600px] relative">
						<div className="flex justify-between items-center">
							<div>
								<div className="font-medium mb-1">
									Set Up Interaction Criteria
								</div>
								<div className="text-xs">
									Define what constitutes a valid transaction for
									your action.
								</div>
							</div>
							<div>
								<Button
									variant="outline"
									onClick={() => {
										setDefaultGuideType('txn');
										setShowGuide(true);
									}}
									size="sm"
								>
									<i className="bi-cloud-download me-2"></i>
									Import from Txn Hash
								</Button>
							</div>
						</div>
						<Separator className="my-5" />
						<div className="space-y-12">
							<div className="">
								<div className="text-sm font-medium">
									Select Relevant Function
								</div>
								<div className="text-xs text-muted-foreground mb-3">
									{content.functions}
								</div>
								<InputSelect2
									className="mb-10"
									value={details.functionSignature}
									setValue={handleFunctionSelected}
									placeholder="Select Function"
									popoverClassName="w-[400px]"
									options={[
										{
											value: 'NA',
											label: 'Not Required',
										},
										...functions,
									]}
								/>
							</div>
							<div className="">
								<div className="text-sm font-medium">
									Select Relevant Event
								</div>
								<div className="text-xs text-muted-foreground mb-3">
									{content.events}
								</div>
								<InputSelect2
									className=""
									value={details.selectedEvent}
									setValue={handleEventSelected}
									error={errors.event0Topic0}
									errorText="Your selected event has been removed as you've deselected the contract address where this event originated from."
									placeholder="Select Event"
									options={[
										{
											value: 'NA',
											label: 'Not Required',
										},
										...events,
									]}
									popoverClassName="w-[400px]"
								/>
								{!showOtherContracts && (
									<p
										className="mt-2 text-xs text-muted-foreground hover:underline cursor-pointer"
										onClick={() => setShowOtherContracts(true)}
									>
										<span>Your event not here? </span>
										<span>Add Custom Event</span>
									</p>
								)}
								{showOtherContracts && (
									<div className="mt-6">
										<SelectSecondaryContract
											setShow={setShowOtherContracts}
										/>
									</div>
								)}
							</div>
							<div className="">
								<div className="text-sm font-medium">
									Identify User Address Field
								</div>
								<div className="text-xs text-muted-foreground mb-3">
									{content.userAddress}
								</div>
								<InputSelect2
									className="mb-10"
									value={details.userAddressSelected}
									setValue={(e) => handleUserAddressSelected(e)}
									placeholder="Select User Address Key"
									options={userAddressParams}
									popoverClassName="w-[400px]"
									error={errors.userAddressSelected}
									errorText="Please select a user address key."
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className={`bg-red transition-all duration-300 ease-out ${
					showGuide ? 'w-[1050px]' : 'w-[0px]'
				}`}
			></div>
			<Guide
				showGuide={showGuide}
				setShowGuide={setShowGuide}
				defaultGuideType={defaultGuideType}
			/>
		</>
	);
};

export default ActionCriterias;

const Guide = ({ showGuide, setShowGuide, defaultGuideType }) => {
	return (
		<>
			<div
				className={`h-full ${
					showGuide ? 'w-[600px]' : 'w-[0px]'
				} absolute bg-white top-0 end-0 transition-all duration-300 ease-out`}
				style={{}}
			>
				<div
					className="absolute top-0 h-full w-8 -ms-7 flex items-center justify-center flex-nowrap"
					onClick={() => setShowGuide((p: boolean) => !p)}
				>
					<div className=" border-t border-s border-b bg-white py-5 cursor-pointer rounded-l-lg">
						<i
							className={`${showGuide ? 'bi-chevron-compact-right' : 'bi-chevron-compact-left'} text-2xl text-muted-foreground`}
						></i>{' '}
					</div>
				</div>
				<div className="p-4 max-h-[76vh] overflow-auto">
					{showGuide && (
						<CriteriaGuide defaultGuideType={defaultGuideType} />
					)}
				</div>
			</div>
		</>
	);
};
