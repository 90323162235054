import { DataTable } from '@/components/element/data-table/DataTable';
import { useCampaignSubmissions } from '@/features/campaigns/hooks/report/useCampaignSubmissions';
import { submissionColumns } from '../components/approvals/SubmissionsColumns';
import { cn } from '@/lib/utils';
import { SubmissionsFloatingContent } from '../components/submissions/SubmissionsFloatingContent';
import { SubmissionCard } from '../components/submissions/SubmissionCard';
import { SubmissionsHeader } from '../components/submissions/SubmissionsHeader';

const Submissions = ({ campaign }) => {
	const {
		isLoading,
		subissions,
		totalCount,
		pagination,
		setPagination,
		setSorting,
		sorting,
		powTasks,
		selectedTaskId,
		rowSelection,
		setRowSelection,
		setSelectedTaskId,
		selectedRow,
		setSelectedRow,
		handleNext,
		handlePrevious,
		statusFilters,
		setStatusFilters,
		queryKey,
		setCursor,
	} = useCampaignSubmissions({ campaign });
	const cols = submissionColumns(selectedRow);

	return (
		<div className="mt-10">
			<div className="grid grid-cols-12 gap-5 ">
				<div
					className={cn(
						selectedRow && subissions?.length > 0
							? 'col-span-8'
							: 'col-span-12',
						'overflow-hidden transition-all duration-300',
					)}
				>
					<SubmissionsHeader
						powTasks={powTasks}
						selectedTaskId={selectedTaskId}
						setSelectedTaskId={setSelectedTaskId}
						statusFilters={statusFilters}
						setStatusFilters={setStatusFilters}
						setCursor={setCursor}
						queryKey={queryKey}
						campaignId={campaign._id}
					/>
					<DataTable
						columns={cols}
						data={subissions}
						totalCount={totalCount}
						isLoading={isLoading}
						isServerSide={true}
						pagination={pagination}
						setPagination={setPagination}
						sorting={sorting}
						setSorting={setSorting}
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						FloatingBarContent={({ table }) => {
							return (
								<SubmissionsFloatingContent
									table={table}
									queryKey={queryKey}
									selectedTaskId={selectedTaskId}
									setCursor={setCursor}
									campaignId={campaign._id}
								/>
							);
						}}
						enableRowSelection
						selectedRow={selectedRow}
						onRowClick={(row) => {
							setSelectedRow(row);
						}}
					/>
				</div>
				<div
					className={cn(
						selectedRow && subissions?.length > 0
							? 'col-span-4'
							: 'col-span-0 hidden',
						'overflow-hidden transition-all duration-300',
					)}
				>
					<SubmissionCard
						selectedRow={selectedRow}
						handlePrevious={handlePrevious}
						handleNext={handleNext}
						selectedTaskId={selectedTaskId}
						powTasks={powTasks}
						setCursor={setCursor}
						queryKey={queryKey}
						campaignId={campaign._id}
					/>
				</div>
			</div>
		</div>
	);
};

export default Submissions;
