import { useCreateCampaignTasks } from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const TaskDraggableLayout = ({ className, children }) => {
	const { reorderTasks } = useCreateCampaignTasks();
	return (
		<>
			<DragDropContext onDragEnd={reorderTasks}>
				<Droppable droppableId="tasks">
					{(provided) => {
						return (
							<div
								key="droppable1"
								id={'tasks'}
								className={className}
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								{children}
							</div>
						);
					}}
				</Droppable>
			</DragDropContext>
		</>
	);
};

export default TaskDraggableLayout;
