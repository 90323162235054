import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import { getSentenceCase } from '@/utils/parsers';
import { Badge } from '@/components/ui/badge';
import { IIntegration } from '../../types/integrations.types';
import StatusBadge from '@/components/element/badges/StatusBadge';
import dayjs from 'dayjs';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';

export const integrationColumns = (options: any): ColumnDef<IIntegration>[] => {
	const cols: ColumnDef<IIntegration>[] = [
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Name" />
			),
			cell: ({ row }) => <div className="py-1">{row.getValue('name')}</div>,
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'integrationType',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Type" />
			),
			cell: ({ row }) => (
				<Badge variant="secondary">
					<span>
						<i
							className={`bi-${row.original.icon}  ${row.original.color} me-2`}
						></i>
					</span>
					<span>{getSentenceCase(row.getValue('integrationType'))}</span>
				</Badge>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'status',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Status"
					className="w-[100px]"
				/>
			),
			cell: ({ row }) => <StatusBadge status={row.getValue('status')} />,
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'url',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="URL" />
			),
			cell: ({ row }) => (
				<div className="font-normal  text-ellipsis text-xs rounded-sm px-3 py-0 bg-muted w-[230px] flex items-center ">
					<div className="me-2 truncate w-[180px]">
						{row.getValue('url')}
					</div>
					<Button
						variant="ghost"
						size="sm"
						className="ml-2 p-0"
						onClick={() => {
							navigator.clipboard.writeText(row.getValue('url'));
							toast.success(` has been copied on your clipboard`);
						}}
					>
						<i className="bi-clipboard-fill text-muted-foreground"></i>
					</Button>
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'updatedAt',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Last Updated" />
			),
			cell: ({ row }) => (
				<div>{dayjs(row.getValue('updatedAt')).format('Do MMM YY')}</div>
			),
			enableSorting: true,
			enableHiding: false,
		},

		{
			id: 'actions',
			cell: ({ row }) => <DataTableRowActions row={row} options={options} />,
		},
	];
	return cols;
};
