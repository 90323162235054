import { handleErrorMessage } from '@/utils/notifications';
import { useState } from 'react';
import { toast } from 'sonner';
import { IProject } from './useGetProjects';
import { createPartnershipRequest } from '../services/partnerships.service';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

export const useCreatePartnershipRequest = (project: IProject, setOpen: any) => {
	const [formFields, setFormFields] = useState({
		message: '',
		category: '',
	});
	const [errors, setErrors] = useState<{
		message: boolean;
	}>({
		message: false,
	});
	const [isLoading, setIsLoading] = useState(false);

	const sendRequest = async () => {
		try {
			setIsLoading(true);
			if (!formFields.message) {
				setErrors({
					...errors,
					message: true,
				});
				setIsLoading(false);
				return;
			}
			const data = {
				enterpriseId: project._id,
				...formFields,
			};
			await createPartnershipRequest(data);
			analytics.track(TrackingEvents.BoostersRequestSubmitted, {
				booster: 'partnership',
				projectName: project.name,
				projectId: project._id,
			});
			toast.success(
				`Request sent successfully, our team will contact you once we hear back from ${project.name}`,
			);
			setOpen?.(false);
			setIsLoading(false);
		} catch (err) {
			handleErrorMessage(err);
			setIsLoading(false);
			console.log(err);
		}
	};

	return {
		isLoading,
		formFields,
		setFormFields,
		errors,
		sendRequest,
	};
};
