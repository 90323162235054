import logo from '@/assets/logo.svg';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/hooks/useAuth';
import register1 from '@/assets/img/onboarding/onboarding-1.png';
import register2 from '@/assets/img/onboarding/onboarding-2.png';
import register3 from '@/assets/img/onboarding/onboarding-3.png';
import register4 from '@/assets/img/onboarding/onboarding-4.png';
import { useEffect, useState } from 'react';

const images = [register1, register2, register3, register4];

const AuthLayout = ({ children }) => {
	const { logout, user } = useAuth();
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
		}, 4000);

		return () => clearInterval(intervalId);
	}, []);

	return (
		<div className="container relative  h-screen flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-[1fr_650px] lg:px-0 2xl:grid-cols-[1fr_750px]">
			{user && (
				<div className="absolute left-4 top-4 z-[1000]">
					<Button variant="link" onClick={logout}>
						<span>Logout</span>
					</Button>
				</div>
			)}
			<div className="lg:p-8 relative">{children}</div>
			<div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
				<div
					className="absolute inset-0 bg-zinc-900"
					style={{
						backgroundImage: `url(${images[currentImageIndex]})`,
						backgroundSize: 'cover',
						transition: 'background-image 2.1s ease-in-out',
					}}
				></div>
				<div className="relative z-20 flex items-center text-2xl font-normal tracking-wider notranslate">
					<img
						src={logo}
						className="size-10 text-foreground me-3 fill-foreground"
					/>
					Intract
				</div>
			</div>
		</div>
	);
};

export default AuthLayout;
