import React, { useState } from 'react';
import { FacetedFilter } from '@/components/element/inputs/FacetedFilter';
import InputText from '@/components/element/inputs/InputText';

const KOLFilters = ({
	search,
	filters,
	setSearch,
	setFilters,
}: {
	search: string;
	filters: {
		language: string[];
		region: string[];
		channels: string[];
		price: string[];
	};
	setSearch: any;
	setFilters: any;
}) => {
	return (
		<div className="flex items-center mt-10 space-x-4">
			<InputText
				value={search}
				setValue={setSearch as any}
				prepend={
					<i className="bi-search px-3 text-muted-foreground text-sm"></i>
				}
				placeholder="Search KOLs"
				className="w-[250px] lg:w-[250px]"
			/>
			<FacetedFilter
				title="Language"
				options={languageOptions}
				selectedValues={filters.language}
				setSelectedValues={(values: any) =>
					setFilters({ ...filters, language: values })
				}
			/>
			<FacetedFilter
				title="Region"
				options={regionsOptions}
				selectedValues={filters.region}
				setSelectedValues={(values: any) =>
					setFilters({ ...filters, region: values })
				}
			/>
			<FacetedFilter
				title="Channels"
				options={channelOptions}
				selectedValues={filters.channels}
				setSelectedValues={(values: any) =>
					setFilters({ ...filters, channels: values })
				}
			/>
			<FacetedFilter
				title="Price"
				options={priceOptions}
				selectedValues={filters.price}
				setSelectedValues={(values: any) =>
					setFilters({ ...filters, price: values })
				}
			/>
		</div>
	);
};

export default KOLFilters;

const languageOptions = [
	{
		value: 'English',
		label: 'English',
		emoji: '🇬🇧',
	},
	{
		value: 'Spanish',
		label: 'Spanish',
		emoji: '🇪🇸',
	},
	{
		value: 'French',
		label: 'French',
		emoji: '🇫🇷',
	},
	{
		value: 'German',
		label: 'German',
		emoji: '🇩🇪',
	},
	{
		value: 'Chinese',
		label: 'Chinese',
		emoji: '🇨🇳',
	},
	{
		value: 'Japanese',
		label: 'Japanese',
		emoji: '🇯🇵',
	},
	{
		value: 'Russian',
		label: 'Russian',
		emoji: '🇷🇺',
	},
	{
		value: 'Arabic',
		label: 'Arabic',
		emoji: '🇸🇦',
	},
	{
		value: 'Portuguese',
		label: 'Portuguese',
		emoji: '🇵🇹',
	},
	{
		value: 'Hindi',
		label: 'Hindi',
		emoji: '🇮🇳',
	},
];

const regionsOptions = [
	{
		value: 'All',
		label: 'All',
		emoji: '🌍',
	},
	{
		value: 'Europe',
		label: 'Europe',
		emoji: '🇪🇺',
	},
	{
		value: 'Asia',
		label: 'Asia',
		emoji: '🌏',
	},
	{
		value: 'Southeast Asia',
		label: 'Southeast Asia',
		emoji: '🌏',
	},
	{
		value: 'South Asia',
		label: 'South Asia',
		emoji: '🌏',
	},
	{
		value: 'Africa',
		label: 'Africa',
		emoji: '🌍',
	},
	{
		value: 'North and Latin America',
		label: 'North and Latin America',
		emoji: '🌎',
	},
	{
		value: 'CIS',
		label: 'CIS',
		emoji: '🇷🇺',
	},
];

const channelOptions = [
	{
		value: 'youtube',
		label: 'YouTube',
		emoji: '📺',
	},
	{
		value: 'discord',
		label: 'Discord',
		emoji: '💬',
	},
	{
		value: 'twitter',
		label: 'Twitter',
		emoji: '🐦',
	},
	{
		value: 'telegram',
		label: 'Telegram',
		emoji: '📨',
	},
	{
		value: 'tiktok',
		label: 'TikTok',
		emoji: '🎵',
	},
	{
		value: 'insta',
		label: 'Instagram',
		emoji: '📸',
	},
	{
		value: 'facebook',
		label: 'Facebook',
		emoji: '📘',
	},
	{
		value: 'snapchat',
		label: 'Snapchat',
		emoji: '👻',
	},
	{
		value: 'reddit',
		label: 'Reddit',
		emoji: '👽',
	},
	{
		value: 'linkedin',
		label: 'LinkedIn',
		emoji: '🔗',
	},
];

const priceOptions = [
	{
		value: '50to100',
		label: '$50 to $100',
	},
	{
		value: '100to500',
		label: '$100 to $500',
	},
	{
		value: '500to1000',
		label: '$500 to $1000',
	},
	{
		value: '1000to2000',
		label: '$1000 to $2000',
	},
	{
		value: 'above2000',
		label: 'Above $2000',
	},
];
