import DashboardLinks from './DashboardHeaderLinks';
import DashboardScope from './DashboardScope';

export default function DashboardNav() {
	return (
		<nav className="flex mx-auto items-center px-6 h-16 relative">
			<DashboardScope />
			<DashboardLinks />
		</nav>
	);
}
