import { useMemo } from 'react';
import { ethers } from 'ethers';
import {
	constructDetailedSignature,
	extractFunctionParams,
} from '../../helpers/helpers';
import { useCreateActionProvider } from './useCreateActionProvider';

export const useActionCriterias = () => {
	const { details, setDetails } = useCreateActionProvider();

	const { functions, events } = useMemo(() => {
		let events__ = [];

		const iface = new ethers.utils.Interface(details.abi);
		const functions =
			iface.fragments
				.filter((fragment) => fragment.type === 'function')
				.map((fragment) => {
					const name = fragment.format();
					const selector = ethers.utils.id(name).substring(0, 10);
					const label = constructDetailedSignature(fragment);
					const item = fragment;
					return {
						value: selector,
						name,
						selector,
						label,
						item,
						params: fragment.inputs,
						searchText: name,
					};
				})
				?.filter(
					(i, index, self) =>
						index === self.findIndex((t) => t.value === i.value),
				) ?? [];

		for (const abi of details.abis) {
			const parsedAbi = abi.abi;
			const contractAddress = abi.contract?.toLowerCase();
			const iface2 = new ethers.utils.Interface(parsedAbi);
			const events_ = iface2.fragments
				.filter((fragment) => fragment.type === 'event')
				.map((fragment) => {
					const name = fragment.format();
					const topic = ethers.utils.id(name);
					const label = constructDetailedSignature(fragment);
					const item = fragment;
					return {
						name,
						topic,
						value: `${topic}:${contractAddress}`,
						label,
						item,
						params: fragment.inputs,
						contractAddress: contractAddress,
						searchText: name,
					};
				});
			events__.push({
				label:
					'Events from: ' +
					abi.contract?.slice(0, 10) +
					'...' +
					abi.contract?.slice(-10),
				heading: true,
			});
			events__.push(...events_);
		}
		events__ = events__?.filter((i, index, self) =>
			i.heading ? true : index === self.findIndex((t) => t.value === i.value),
		);

		return {
			functions,
			events: events__,
		};
	}, [details.abis, details.abi]);

	const userAddressParams = useMemo(() => {
		const baseOption = [
			{
				value: 'TXN_FROM',
				label: <div>Transaction Sender</div>,
				searchText: 'Transaction Sender',
			},
		];
		if (details.functionSignature !== 'NA') {
			const selectedFunction = functions.find(
				(fun) => fun.value === details.functionSignature,
			);
			const params = selectedFunction?.params;
			const addressParams = params?.filter(
				(param: any) => param.type === 'address',
			);
			const addressOptions =
				addressParams?.map((param: any) => ({
					value: `function:${selectedFunction.selector}:${param.name}:${param.type}`,
					label: (
						<div className="flex items-center">
							<strong>{param.name}</strong>
							<span className="text-muted-foreground text-xs block ms-1">
								parameter in Selected Function{' '}
								<span className="text-primary">
									{selectedFunction.item.name}
								</span>
							</span>
						</div>
					),
					searchText: `function:${selectedFunction.selector}:${param.name}`,
				})) ?? [];
			baseOption.push(...addressOptions);
		}
		if (details.event0Topic0 !== 'NA') {
			const selectedEvent = events.find(
				(event) => event.topic === details.event0Topic0,
			);
			const params = selectedEvent?.params;
			const addressParams = params?.filter(
				(param: any) => param.type === 'address',
			);
			const addressOptions =
				addressParams?.map((param: any) => ({
					value: `event:${selectedEvent.topic}:${param.name}:${param.type}`,
					label: (
						<div className="flex items-center">
							<strong>{param.name}</strong>
							<span className="text-muted-foreground text-xs block ms-1">
								parameter in Selected Event{' '}
								<span className="text-primary">
									{selectedEvent.item.name}
								</span>
							</span>
						</div>
					),
					searchText: `event:${selectedEvent.topic}:${param.name}:${param.type}`,
				})) ?? [];
			baseOption.push(...addressOptions);
			//
		}
		return baseOption;
	}, [details.functionSignature, details.event0Topic0, events, functions]);

	const handleFunctionSelected = (id: string) => {
		if (details.userAddressSelected?.includes('function:')) {
			setDetails((prev) => ({
				...prev,
				userAddressSelected: '',
			}));
		}
		if (id === 'NA') {
			setDetails((prev) => ({
				...prev,
				functionSignature: 'NA',
			}));
			return;
		}
		if (functions) {
			const selectedFunctionFromList = functions.find(
				(fun) => fun.value === id,
			);
			if (selectedFunctionFromList) {
				setDetails((prev) => ({
					...prev,
					functionSignature: selectedFunctionFromList?.selector
						? selectedFunctionFromList.selector
						: '',
				}));
			}
		}
	};

	const handleEventSelected = (id: string) => {
		if (details.userAddressSelected?.includes('event:')) {
			setDetails((prev) => ({
				...prev,
				userAddressSelected: '',
			}));
		}
		if (id === 'NA') {
			setDetails((prev) => ({
				...prev,
				event0Topic0: '',
				selectedEvent: 'NA',
				event0ContractAddress: '',
			}));
			return;
		}
		if (events) {
			const [topic, contractAddress] = id.split(':');
			const selectedEventFromList = events.find(
				(event) =>
					event.topic === topic &&
					event.contractAddress === contractAddress,
			);
			if (selectedEventFromList) {
				setDetails((prev) => ({
					...prev,
					event0Topic0: topic,
					event0ContractAddress: contractAddress,
					selectedEvent: id,
				}));
			}
		}
	};

	const handleUserAddressSelected = (id: string) => {
		if (id === 'TXN_FROM') {
			setDetails((prev) => ({
				...prev,
				userAddressSource: id,
				userAddressParamIndex: [],
				userAddressSelected: 'TXN_FROM',
			}));
			return;
		}
		const [source, topic, paramName, paramType] = id.split(':');
		if (source === 'function') {
			const selectedFunction = functions.find((fun) => fun.selector === topic);
			const params = extractFunctionParams(selectedFunction);
			const position = params.params.find(
				(param) => param.name === paramName && param.type === paramType,
			).position;
			if (selectedFunction) {
				setDetails((prev) => ({
					...prev,
					userAddressSource: 'FUNC_CALLDATA',
					userAddressParamIndex: position,
					userAddressSelected: id,
				}));
			}
		}
		if (source === 'event') {
			const selectedEvent = events.find((event) => event.topic === topic);
			const params = extractFunctionParams(selectedEvent);
			const position = params.params.find(
				(param) => param.name === paramName && param.type === paramType,
			).position;
			if (selectedEvent) {
				setDetails((prev) => ({
					...prev,
					userAddressSource: 'EVENT',
					userAddressParamIndex: position,
					userAddressSelected: id,
				}));
			}
		}
	};

	return {
		functions: functions ?? [],
		events: events ?? [],
		handleFunctionSelected,
		handleEventSelected,
		handleUserAddressSelected,
		userAddressParams,
	};
};
