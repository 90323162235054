import {
	AdminInputType,
	ITaskAdminInput,
	TaskKeys,
	TemplateFamily,
	TemplateType,
} from '@/features/campaigns/types';
import TaskInputString from './TaskInputString';
import TaskInputSelectNetwork from './TaskInputSelectNetwork';
import TaskInputSelectAsync from './TaskInputSelectAsync';
import TaskInputSelect from './TaskInputSelect';
import TaskInputStringArray from './TaskInputStringArray';
import TaskInputSelectAdminInput from './TaskInputSelectAdminInput';
import TaskInputCheckbox from './TaskInputCheckbox';
import TaskInputTextArea from './TaskInputTextArea';
import TaskInputDate from './TaskInputDate';
import TaskInputTime from './TaskInputTime';
import TaskInputTwitterTags from './TaskInputTwitterTags';

export interface ITaskInputsProps {
	adminInput: ITaskAdminInput;
	adminInputs: ITaskAdminInput[];
	templateFamily: TemplateFamily | string;
	templateType: TemplateType | string;
	description?: string;
	taskId?: string;
	error: boolean | string;
	editTaskAdminInput: (
		taskId: string,
		key: string,
		value:
			| string
			| number
			| { id: string; value: string }[]
			| boolean
			| undefined
			| Date,
	) => void;
	setErrors: (taskId: string, key: TaskKeys, value: boolean) => void;
	deleteTask?: (taskId: string) => void;
}

const TaskInputs = ({ adminInput, ...rest }: ITaskInputsProps) => {
	// return <></>
	if (!adminInput) return null;
	if (
		adminInput.inputType === AdminInputType.InputString ||
		adminInput.inputType === AdminInputType.InputAddress ||
		adminInput.inputType === AdminInputType.InputNumber
	) {
		return <TaskInputString adminInput={adminInput} {...rest} />;
	}
	if (adminInput.inputType === AdminInputType.SelectNetwork) {
		return <TaskInputSelectNetwork adminInput={adminInput} {...rest} />;
	}
	if (adminInput.inputType === AdminInputType.SelectAsync) {
		return <TaskInputSelectAsync adminInput={adminInput} {...rest} />;
	}
	if (adminInput.inputType === AdminInputType.Select) {
		return <TaskInputSelect adminInput={adminInput} {...rest} />;
	}
	if (adminInput.inputType === AdminInputType.InputStringArray) {
		return <TaskInputStringArray adminInput={adminInput} {...rest} />;
	}
	if (adminInput.inputType === AdminInputType.SelectFromAdminInput) {
		return <TaskInputSelectAdminInput adminInput={adminInput} {...rest} />;
	}
	if (adminInput.inputType === AdminInputType.InputCheckbox) {
		return <TaskInputCheckbox adminInput={adminInput} {...rest} />;
	}
	if (adminInput.inputType === AdminInputType.Textarea) {
		return <TaskInputTextArea adminInput={adminInput} {...rest} />;
	}
	if (adminInput.inputType === AdminInputType.InputDate) {
		return <TaskInputDate adminInput={adminInput} {...rest} />;
	}
	if (adminInput.inputType === AdminInputType.TwitterTags) {
		return <TaskInputTwitterTags adminInput={adminInput} {...rest} />;
	}
	// console.log(adminInput);
	// return <TaskInputString adminInput={adminInput} {...rest} />;
};

export default TaskInputs;
