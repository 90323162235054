export enum AccountRoles {
	Admin = 'ADMIN',
	Member = 'MEMBER',
	Guest = 'GUEST',
	Owner = 'OWNER',
	Developer = 'DEVELOPER',
}

export const roleMap = {
	[AccountRoles.Admin]: 'Admin',
	[AccountRoles.Member]: 'Member',
	[AccountRoles.Guest]: 'Guest',
	[AccountRoles.Owner]: 'Owner',
	[AccountRoles.Developer]: 'Developer',
};
