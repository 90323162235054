import { toast } from 'sonner';
import { ghostLogin, removeGhostLogin } from '../services/admin.service';
import { queryClient } from '@/lib/react-query';

export const useAdminEnterpriseActions = ({ refetch }) => {
	const handleGhostLogin = async (enterpriseId: string) => {
		return new Promise((resolve) => {
			toast.promise(
				Promise.all([
					ghostLogin(enterpriseId).then((res) => {
						resolve(res);
						queryClient.invalidateQueries({
							queryKey: ['user'],
						});
						refetch();
					}),
				]),
				{
					loading: 'Adding project in your account...',
					success: 'Project added successfully',
					error: 'Failed to add project',
				},
			);
		});
	};
	const handleRemoveGhostLogin = async (enterpriseId: string) => {
		return new Promise((resolve) => {
			toast.promise(
				Promise.all([
					removeGhostLogin(enterpriseId).then((res) => {
						resolve(res);
						queryClient.invalidateQueries({
							queryKey: ['user'],
						});
						refetch();
					}),
				]),
				{
					loading: 'Removing project in your account...',
					success: 'Project removed successfully',
					error: 'Failed to remove project',
				},
			);
		});
	};

	const toggleVerification = async (enterpriseId: string) => {};

	return {
		ghostLogin: handleGhostLogin,
		removeGhostLogin: handleRemoveGhostLogin,
		toggleVerification,
	};
};
