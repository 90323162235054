import { Separator } from '@/components/ui/separator';
import { useState } from 'react';
import CriteriaGuide from '../guide/CriteriaGuide';
import EditConditions from './action-filters/EditConditions';
import InputText from '@/components/element/inputs/InputText';
import { useCreateQueryProvider } from '../../hooks/queries/useCreateQueryProvider';
import InputTextArea from '@/components/element/inputs/InputTextArea';

const QueryActionFilters = () => {
	const [showGuide, setShowGuide] = useState(false);
	const { details, setDetails, errors } = useCreateQueryProvider();

	return (
		<>
			<div className="w-full">
				<div className="flex justify-center items-center pt-10">
					<div className="mb-5 w-[600px] relative">
						<div className="space-y-10 mb-10">
							<InputText
								label="Name"
								value={details.name}
								setValue={(e) => setDetails({ ...details, name: e })}
								error={errors.name}
								placeholder="Enter query name"
								inputClassName="bg-white dark:bg-gray-800"
							/>
							<InputTextArea
								label="Description"
								value={details.description}
								setValue={(e) =>
									setDetails({ ...details, description: e })
								}
								error={false}
								placeholder="Enter query name"
								inputClassName="bg-white dark:bg-gray-800"
							/>
						</div>
						<EditConditions />
					</div>
				</div>
			</div>
			{/* <div
				className={`bg-red transition-all duration-300 ease-out ${
					showGuide ? 'w-[1050px]' : 'w-[0px]'
				}`}
			></div>
			<Guide showGuide={showGuide} setShowGuide={setShowGuide} /> */}
		</>
	);
};

export default QueryActionFilters;

const Guide = ({ showGuide, setShowGuide }) => {
	return (
		<>
			<div
				className={`h-full ${
					showGuide ? 'w-[600px]' : 'w-[0px]'
				} absolute bg-white top-0 end-0 transition-all duration-300 ease-out border-s border-slate-300 rounded-lg`}
				style={{}}
			>
				<div
					className="absolute top-0 h-full w-8 -ms-9 flex items-center justify-center flex-nowrap"
					onClick={() => setShowGuide((p: boolean) => !p)}
				>
					<div className="rounded border-e border-s border-b bg-slate-300 py-2 cursor-pointer text-slate-700 rotate-90 px-5 text-sm font-medium tracking-widest">
						GUIDE
					</div>
				</div>
				<div className="p-4 max-h-[76vh] overflow-auto">
					{showGuide && <CriteriaGuide />}
				</div>
			</div>
		</>
	);
};
