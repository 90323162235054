import { DataTable } from '@/components/element/data-table/DataTable';
import { queriesColumns } from './columns/QueryColumns';
import { useNavigate } from 'react-router-dom';
import { useDeleteQuery } from '../../hooks/queries/useDeleteQuery';

const QueriesTable = ({ queries, isLoading, actions }) => {
	const navigate = useNavigate();
	const { deleteQuery } = useDeleteQuery();
	const columns = queriesColumns(
		[
			{
				type: 'button',
				label: 'Edit Query',
				onClick: (row: any) => {
					navigate(`/app/tools/dave/query/update/${row._id}`);
				},
			},
			{
				type: 'separator',
			},
			{
				type: 'button',
				label: 'Delete Query',
				onClick: (row: any) => {
					deleteQuery([row._id]);
				},
			},
		],
		actions,
	);
	return (
		<div className="mt-4">
			<DataTable
				data={queries ?? []}
				isLoading={isLoading}
				totalCount={queries?.length}
				columns={columns}
				onRowClick={(row) => {
					// navigate(`/app/tools/dave/query/${row._id}`);
				}}
			/>
		</div>
	);
};

export default QueriesTable;
