import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
	DialogFooter,
} from '@/components/ui/dialog';
import { useEffect, useState } from 'react';

const CampaignNarrativeText = ({
	open,
	setOpen,
	narrativeText,
	setNarrativeText,
}) => {
	const [text, setText] = useState(narrativeText);

	useEffect(() => {
		setText(narrativeText);
	}, [narrativeText, open]);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[525px] pb-3">
				<DialogHeader className=" pb-3">
					<DialogTitle>Add Spotlight text</DialogTitle>
					<DialogDescription className="text-xs">
						Add important details to your quest page prominently. Use
						this section to convey key messages, special instructions, or
						incentives.
					</DialogDescription>
				</DialogHeader>
				<div className="space-y-8">
					<InputText
						label="Spotlight Text"
						value={text}
						setValue={(e) => {
							setText(e);
						}}
						placeholder={'Enter narrative text'}
					/>
				</div>
				<DialogFooter>
					<Button
						onClick={() => {
							setNarrativeText(text);
							setOpen(false);
						}}
						className="px-10"
					>
						Save
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default CampaignNarrativeText;
