import { INFTContract } from '../types/nfts.enum';
import NFTCard from './NFTCard';

const NFTsGrid = ({ data }: { data: INFTContract[] }) => {
	return (
		<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
			{data &&
				data.map((nft) => (
					<div key={nft._id} className="">
						<NFTCard {...nft} />
					</div>
				))}
		</div>
	);
};

export default NFTsGrid;
