import { CheckIcon, PlusCircledIcon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useMemo } from 'react';

function MultiSelect({
	value,
	setValue,
	options,
	placeholder,
	error,
	withoutPortal,
	popoverClassName,
	maxLimit = 3,
}: {
	value: string[];
	setValue: (value: string[]) => void;
	options: {
		value: string;
		label: string | JSX.Element;
		selectedLabel?: string | JSX.Element;
		searchText?: string;
	}[];
	placeholder: string;
	popoverClassName?: string;
	error?: boolean;
	maxLimit?: number;
	withoutPortal?: boolean;
}) {
	const selectedLabel = useMemo(() => {
		const selected = value.map((i) => {
			const option = options.find((o) => o.value === i);
			return option?.selectedLabel || option?.label;
		});
		return value?.length ? (
			value?.length > maxLimit ? (
				`${value?.length} selected`
			) : (
				<span className="flex gap-1">
					Selected:{' '}
					{selected?.map((i, index) => (
						<span className="flex">
							<span>{i}</span>
							{index < selected.length - 1 && (
								<span className="">,</span>
							)}
						</span>
					))}
				</span>
			)
		) : (
			placeholder
		);
	}, [value, options]);

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					size="sm"
					className={`w-full h-9   justify-start ${
						error ? 'border-destructive' : ''
					}`}
				>
					<PlusCircledIcon className="mr-2 h-4 w-4" />
					<span>{selectedLabel}</span>
				</Button>
			</PopoverTrigger>
			<PopoverContent
				className={cn('w-[200px] p-0', popoverClassName)}
				align="start"
				withoutPortal={withoutPortal}
			>
				<Command>
					<CommandInput placeholder={placeholder} />
					<CommandList>
						<CommandEmpty>No results found.</CommandEmpty>
						<CommandGroup>
							{options.map((option) => {
								const isSelected =
									value.findIndex((i) => i === option.value) > -1;
								return (
									<CommandItem
										key={option.value}
										onSelect={() => {
											const newSelectedValues = [...value];
											if (isSelected) {
												const index =
													newSelectedValues.findIndex(
														(i) => i === option.value,
													);
												newSelectedValues.splice(index, 1);
												setValue(newSelectedValues);
											} else {
												newSelectedValues.push(option.value);
												setValue(newSelectedValues);
											}
										}}
									>
										<div
											className={cn(
												'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
												isSelected
													? 'bg-primary text-primary-foreground'
													: 'opacity-50 [&_svg]:invisible',
											)}
										>
											<CheckIcon className={cn('h-4 w-4')} />
										</div>
										<span>{option.label}</span>
									</CommandItem>
								);
							})}
						</CommandGroup>
						<CommandSeparator />
						<CommandGroup>
							<CommandItem
								onSelect={() => setValue([])}
								className="justify-center text-center"
							>
								Clear filters
							</CommandItem>
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
}

export default MultiSelect;
