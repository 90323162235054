import { Link } from 'react-router-dom';
import logo from '@/assets/logo.svg';
import { UserAuthForm } from '../components/UserAuthForm';

export default function LoginPage() {
	return (
		<div className="container flex h-screen w-screen flex-col items-center justify-center">
			<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
				<div className="flex flex-col space-y-2 text-center">
					<img src={logo} alt="logo" className="mx-auto h-10 w-10" />
					<h1 className="text-2xl font-semibold tracking-tight">
						Welcome back
					</h1>
					<p className="text-sm text-muted-foreground">
						Enter your email to sign in to your account
					</p>
				</div>
				<UserAuthForm />
				<p className="px-8 text-center text-sm text-muted-foreground">
					<Link
						to="/register"
						className="hover:text-brand underline underline-offset-4"
					>
						Don&apos;t have an account? Sign Up
					</Link>
				</p>
			</div>
		</div>
	);
}
