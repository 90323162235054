import InputText from '@/components/element/inputs/InputText';
import { Label } from '@/components/ui/label';
import SelectTokenSets from './SelectTokenSets';

const TokenTransferConfig = ({
	title,
	description,
	tooltip,
	icon,
	amount,
	setAmount,
	tokenSet,
	setTokenSet,
	chainId,
	isAnyToken,
	setIsAnyToken,
	amountError,
}: {
	title: string;
	description: string;
	chainId: number;
	tooltip: string;
	icon: string;
	amount: number;
	setAmount: (e: number) => void;
	tokenSet: string[];
	setTokenSet: (e: string[]) => void;
	isAnyToken: 'ANY' | 'LIST';
	setIsAnyToken: (e: 'ANY' | 'LIST') => void;
	amountError?: boolean;
}) => {
	return (
		<div className="relative mt-4">
			<div className="relative mb-5">
				<div className="mb-1 font-medium text-sm">
					<i className={`bi ${icon}  me-2`} />
					{title}
				</div>
				<p className="text-xs mb-4 text-muted-foreground">{description}</p>
				<div className="grid grid-cols-12 gap-3">
					<div className=" col-span-6">
						<Label className="mb-2">Tokens Allowed</Label>
						<SelectTokenSets
							chainId={chainId}
							value={tokenSet}
							setValue={setTokenSet}
							isAnyToken={isAnyToken}
							setIsAnyToken={setIsAnyToken}
						/>
					</div>
					<div className=" col-span-6">
						<InputText
							label="Minimum Amount"
							tooltip={tooltip}
							placeholder="1000"
							prepend={<i className="bi bi-coin text-sm px-2" />}
							inputClassName="ps-0"
							type="number"
							value={amount}
							setValue={setAmount}
							error={amountError}
							errorText="Invalid amount"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TokenTransferConfig;
