import { API_URL } from '@/config';
import {
	addDiscordIntegration,
	getDiscordIntegrationsChannels,
	refetchDiscordIntegration,
} from '../services/integrations.service';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useRouter } from '@/hooks/useRouter';
import { useAuth } from '@/hooks/useAuth';
import { handleErrorMessage } from '@/utils/notifications';
import { toast } from 'sonner';

export interface IChannelPermissions {
	channelId: string;
	channelName: string;
	hasAccess: boolean;
	requiredRoles: {
		id: string;
		name: string;
	}[];
}

export const useDiscordIntegration = () => {
	const { query } = useRouter();
	const [step, setStep] = useState(1);
	const { refetchUser } = useAuth();
	const [integrationId, setIntegrationId] = useState('');
	const [isAuthenticating, setIsAuthenticating] = useState(false);
	const [loading, setLoading] = useState(false);

	const {
		data: channels,
		isLoading: isLoadingChannels,
		isFetching: isFetchingChannels,
		refetch: refetchChannel,
	} = useQuery<IChannelPermissions[]>({
		queryKey: ['channel-permissions'],
		queryFn: getDiscordIntegrationsChannels,
	});

	useEffect(() => {
		if (channels && channels.length > 0) {
			setStep(2);
		}
	}, [channels]);

	useEffect(() => {
		if (query.code && query.permissions) {
			connectDiscord({
				guildId: query.guild_id.toString(),
				code: query.code.toString(),
				permissions: query.permissions.toString(),
			});
		}
	}, []);

	const connectDiscord = async (data: {
		guildId: string;
		code: string;
		permissions: string;
	}) => {
		try {
			if (!data) return;
			if (!data.guildId || !data.code || !data.permissions) return;
			setIsAuthenticating(true);
			const integration = await addDiscordIntegration({
				code: data.code,
				permissions: data.permissions,
			});
			setIntegrationId(integration?._id);
			await refetchUser();
			await refetchChannel();
			setIsAuthenticating(false);
		} catch (err) {
			handleErrorMessage(err);
			setIsAuthenticating(false);
		}
	};

	const handleFetching = async (integrationId: string) => {
		try {
			setLoading(true);
			await refetchDiscordIntegration(integrationId);
			toast.success(
				'Your Discord integration is complete! Give it a few moments and refresh your dashboard to see your community displayed! 🎉',
			);
			setLoading(false);
			setStep(3);
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	const authenticateDiscord = () => {
		window.location.href = API_URL + '/api/v1/integrations/discord/add';
	};

	return {
		isAuthenticating,
		isLoadingChannels,
		step,
		setStep,
		channels,
		isFetchingChannels,
		authenticateDiscord,
		handleFetching,
		integrationId,
		loading,
		refetchChannel,
	};
};
