import { useEffect, useMemo, useState } from 'react';
import {
	CampaignRewardCategory,
	ICampaign,
	ICampaignReward,
	Status,
} from '../../types';
import { useDepositToken } from './useDepositToken';
import {
	checkIfTokenRewardTxnAlreadyDone,
	getCampaign,
	updateCampaignStatus,
} from '../../services/campaigns.service';
import { queryClient } from '@/lib/react-query';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { handleErrorMessage } from '@/utils/notifications';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

export const useCampaignLaunch = ({
	campaignId,
	startImmediately,
}: {
	campaignId: string;
	startImmediately: boolean;
	setOpen: (value: boolean) => void;
}) => {
	const navigate = useNavigate();
	const { data: campaign, isLoading: isCampaignLoading } = useQuery<ICampaign>({
		queryKey: ['campaign', campaignId],
		queryFn: () => getCampaign(campaignId),
		enabled: !!campaignId,
	});
	const tokenReward = campaign?.reward?.find(
		(reward) => reward.category === CampaignRewardCategory.Token,
	);
	const [step, setStep] = useState('confirmation');
	const [isLoading, setIsLoading] = useState(false);
	const {
		startProcess,
		status,
		isLoading: isDeposting,
	} = useDepositToken({
		campaignId: campaignId,
		tokenReward,
	});
	const [isTxnRequired, setIsTxnRequired] = useState(tokenReward ? true : false);

	const { data, error } = useQuery({
		queryKey: ['campaign', 'reward-txn', campaignId],
		queryFn: () => checkIfTokenRewardTxnAlreadyDone(campaignId),
		enabled:
			!!campaignId &&
			!!tokenReward &&
			!isCampaignLoading &&
			!campaign?.rewardTransaction?.txHash,
	});

	useEffect(() => {
		if (data?.logFound) {
			toast.error('Token deposit txn already done');
			setIsTxnRequired(false);
		}
	}, [data, error]);

	useEffect(() => {
		if (!campaign && tokenReward) {
			setIsTxnRequired(true);
		} else if (!campaign?.rewardTransaction?.txHash && tokenReward) {
			setIsTxnRequired(true);
		} else {
			setIsTxnRequired(false);
		}
	}, [campaign, tokenReward]);

	const launchCampaign = async () => {
		try {
			setIsLoading(true);
			await updateCampaignStatus(campaignId, {
				newStatus: Status.Active,
			});
			analytics.track(TrackingEvents.LaunchQuestCompleted, {
				camapignId: campaignId,
				campaignStatus: startImmediately ? Status.Active : Status.Scheduled,
			});
			toast.success('Campaign has been launched successfully');
			await queryClient.invalidateQueries({
				queryKey: ['campaign', campaignId],
			});
			const existingTableData = await queryClient.getQueryData(['campaigns']);

			if (existingTableData) {
				await queryClient.setQueryData(
					['campaigns'],
					(existingTableData as any[]).map((item) => {
						if (item._id === campaignId) {
							return {
								...item,
								status: startImmediately
									? Status.Active
									: Status.Scheduled,
							};
						}
						return item;
					}),
				);
			}
			navigate(`/app/campaign/quests/${campaignId}?share-celebrate=true`);
			setIsLoading(false);
			// setOpen?.(false);
		} catch (err) {
			console.log(err);
			handleErrorMessage(err);
			setIsLoading(false);
			console.log(err);
		}
	};

	const handleNext = async () => {
		if (step === 'confirmation') {
			analytics.track(TrackingEvents.LaunchQuestConfirmed, {
				campaignId,
				isTokenReward: !!tokenReward,
				isTxnRequired: isTxnRequired,
			});
		}
		if (step === 'confirmation' && tokenReward && isTxnRequired) {
			setStep('token');
			return;
		} else if (step === 'confirmation') {
			await launchCampaign();
			return;
		} else if (step === 'token') {
			const res = await startProcess();
			if (res) {
				await launchCampaign();
			}
			return;
		}
	};

	const btnLabel = useMemo(() => {
		if (step === 'confirmation') {
			if (tokenReward && isTxnRequired) return 'Continue to Deposit Tokens';
			return 'Launch Campaign';
		}
		if (step === 'token') {
			return status;
		}
	}, [step, tokenReward, status, isTxnRequired]);

	return {
		step,
		setStep,
		handleNext,
		isLoading: isLoading || isDeposting,
		btnLabel,
		isCampaignLoading,
	};
};
