import { AccountRoles } from './team.enums';

export const roleOptions = [
	{
		value: AccountRoles.Admin,
		label: 'Admin',
	},
	{
		value: AccountRoles.Member,
		label: 'Member',
	},
	{
		value: AccountRoles.Guest,
		label: 'Guest',
	},
	{
		value: AccountRoles.Owner,
		label: 'Owner',
	},
	{
		value: AccountRoles.Developer,
		label: 'Developer',
	},
];
