import dayjs from 'dayjs';
import { ICampaignHistory } from '../../types/leaderboard.types';
import { Badge } from '@/components/ui/badge';
import { Media } from '@/components/element/media/Media';
import { useState } from 'react';
import { Button } from '@/components/ui/button';

const UserActivity = ({ activity }: { activity: ICampaignHistory[] }) => {
	const [showAll, setShowAll] = useState(false);

	if (!activity) return <></>;
	return (
		<div className="px-8">
			<div className="flex justify-between">
				<div className="font-medium text-sm text-muted-foreground">
					Activity history
				</div>
				{activity?.length > 10 && (
					<Button
						variant="link"
						className="p-0"
						size="sm"
						onClick={() => setShowAll((p) => !p)}
					>
						{showAll ? 'Show less' : 'Show all'}
					</Button>
				)}
			</div>
			{activity &&
				activity.length &&
				activity
					?.slice(0, showAll ? activity.length : 10)
					?.map((i) => (
						<CampaignHistoryItem
							title={i.campaignName}
							image={i.campaignBanner}
							date={
								i.completedAt ? dayjs(i.completedAt).fromNow() : ''
							}
							xps={i.xp}
						/>
					))}
		</div>
	);
};

export default UserActivity;

const CampaignHistoryItem = ({ title, image, date, xps }: any) => {
	return (
		<div className="mt-5">
			<div className="flex justify-between items-top">
				<div className="flex">
					<div>
						<Media
							src={image}
							className="rounded h-12 w-12 object-cover me-3 border"
						/>
					</div>
					<div>
						<div className="font-medium text-sm max-w-[340px] truncate">
							{title}
						</div>
						{date && (
							<div className="text-xs text-muted-foreground mt-2">
								{date}
							</div>
						)}
					</div>
				</div>
				<div className="">
					<Badge variant="secondary">{xps} XPs</Badge>
				</div>
			</div>
		</div>
	);
};
