import { useEffect, useState } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { authRoutes } from './auth';
import { publicRoutes } from './public';
import { protectedRoutes } from './protected';
import { useAuth } from '@/hooks/useAuth';

import { onboardingRoutes } from './onboarding';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { adminRoutes } from './admin';

function AppRoutes() {
	const { user } = useAuth();
	const [routes, setRoutes] = useState<RouteObject[]>([]);

	useEffect(() => {
		if (!user) {
			return setRoutes([...publicRoutes, ...authRoutes]);
		}
		if (user && user.isIntractAdmin) {
			return setRoutes([...protectedRoutes, ...adminRoutes]);
		}
		if (user && !user.enterpriseName) {
			return setRoutes([...onboardingRoutes]);
		}
		return setRoutes([...protectedRoutes]);
	}, [user]);

	if (!routes.length) {
		return <div>Loading...</div>;
	}

	return (
		<RouterProvider
			fallbackElement={<div>Loading...</div>}
			router={createBrowserRouter(routes)}
		/>
	);
}

export default AppRoutes;
