import React, { useMemo } from 'react';
import { useLoyaltyCampaign } from '../../hooks/useLoyaltyCampaign';
import moment from 'moment';
import ChartCardWithLegends from '@/features/campaigns/components/campaign-report/components/analytics/cards/ChartCardWithLegends';
import LineChartComponent from '@/components/charts/LineChart';

const LoyaltyInitiationsTimeSeries = () => {
	const { campaign: loyaltyCampaign } = useLoyaltyCampaign();
	const legends = [
		{
			key: 'pageViews',
			label: 'Page Views',
			opacity: 0.5,
		},
		{
			key: 'initiations',
			label: 'Initiations',
			opacity: 1,
		},
	];

	const data = useMemo(() => {
		if (
			!loyaltyCampaign ||
			!loyaltyCampaign?.timeSeriesMetrics ||
			!loyaltyCampaign?.timeSeriesMetrics?.pageViewUsers ||
			loyaltyCampaign?.timeSeriesMetrics?.pageViewUsers?.length === 0
		) {
			return [];
		}
		// Extract and sort the pageViewUsers by timestamp in ascending order
		const sortedPageViews = [
			...loyaltyCampaign.timeSeriesMetrics.pageViewUsers,
		].sort(
			(a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf(),
		);

		return sortedPageViews.map((i) => ({
			pageViews: i.count,
			initiation:
				loyaltyCampaign?.timeSeriesMetrics?.initiatedUsers?.find(
					(j) => j.timestamp === i.timestamp,
				)?.count || 0,
			date: moment(i.timestamp).format('Do MMM YYYY'),
		}));
	}, [loyaltyCampaign]);

	return (
		<ChartCardWithLegends
			className="mt-10"
			title="Campaign Completions"
			legends={legends}
			tooltip="Campaign Completions Chart"
		>
			<LineChartComponent
				data={data}
				height="350px"
				tooltipFn={({ active, payload }) => {
					if (active && payload) {
						return (
							<div className="rounded-lg border bg-background p-2 shadow-sm">
								<div className="grid grid-cols-1 gap-2">
									<div className="flex flex-col">
										<span className="text-[0.70rem] uppercase text-muted-foreground">
											Page Views
										</span>
										<span className="font-bold text-muted-foreground">
											{payload[0]?.payload?.pageViews}
										</span>
									</div>
									<div className="flex flex-col">
										<span className="text-[0.70rem] uppercase text-muted-foreground">
											Initiations
										</span>
										<span className="font-bold">
											{payload[0]?.payload?.initiation}
										</span>
									</div>
								</div>
							</div>
						);
					}
					return null;
				}}
				legends={legends}
			/>
		</ChartCardWithLegends>
	);
};

export default LoyaltyInitiationsTimeSeries;
