import LineChartComponent from '@/components/charts/LineChart';
import ChartCardWithLegends from '../cards/ChartCardWithLegends';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import { useMemo } from 'react';
import moment from 'moment';

const CampaignCompletionChart = () => {
	const { campaign } = useCampaignReport();
	const legends = [
		{
			key: 'pageViews',
			label: 'Page Views',
			opacity: 0.25,
		},
		{
			key: 'participations',
			label: 'Participation',
			opacity: 0.5,
		},
		{
			key: 'completions',
			label: 'Completion',
			opacity: 1,
		},
	];
	const data = useMemo(() => {
		if (
			!campaign ||
			!campaign?.timeSeriesMetrics ||
			!campaign?.timeSeriesMetrics?.pageViewUsers ||
			campaign?.timeSeriesMetrics?.pageViewUsers?.length === 0
		) {
			return [];
		}

		// Sort the data by timestamp in ascending order before mapping
		const sortedPageViews = campaign?.timeSeriesMetrics?.pageViewUsers?.sort(
			(a, b) => +new Date(a.timestamp) - +new Date(b.timestamp),
		);

		return sortedPageViews?.map((i) => ({
			pageViews: i.count,
			participations: campaign.timeSeriesMetrics?.initiatedUsers?.find(
				(j) => j.timestamp === i.timestamp,
			)?.count,
			completions:
				campaign.timeSeriesMetrics?.completedUsers?.find(
					(j) => j.timestamp === i.timestamp,
				)?.count || 0,
			date: moment(i.timestamp).format('Do MMM YYYY'),
		}));
	}, [campaign]);

	return (
		<ChartCardWithLegends
			className="mt-10"
			title="Campaign Completions"
			legends={legends}
			tooltip="Campaign Completions Chart"
		>
			<LineChartComponent
				data={data}
				height="350px"
				tooltipFn={({ active, payload }) => {
					if (
						active &&
						payload &&
						payload.length &&
						payload.length === 3
					) {
						return (
							<div className="rounded-lg border bg-background p-2 shadow-sm">
								<div className="grid grid-cols-1 gap-2">
									<div className="flex flex-col">
										<span className="text-[0.70rem] uppercase text-muted-foreground">
											Page Views
										</span>
										<span className="font-bold text-muted-foreground">
											{payload[0].value}
										</span>
									</div>
									<div className="flex flex-col">
										<span className="text-[0.70rem] uppercase text-muted-foreground">
											Initiations
										</span>
										<span className="font-bold">
											{payload[1].value}
										</span>
									</div>
									<div className="flex flex-col">
										<span className="text-[0.70rem] uppercase text-muted-foreground">
											Completions
										</span>
										<span className="font-bold">
											{payload[2].value}
										</span>
									</div>
								</div>
							</div>
						);
					}
					return null;
				}}
				legends={legends}
			/>
		</ChartCardWithLegends>
	);
};

export default CampaignCompletionChart;

const data = [
	{
		pageViews: 400,
		participations: 240,
		completions: 140,
		date: '1st Jan 2024',
	},
	{
		pageViews: 500,
		participations: 440,
		completions: 340,
		date: '2nd Jan 2024',
	},
	{
		pageViews: 450,
		participations: 220,
		completions: 200,
		date: '3rd Jan 2024',
	},
	{
		pageViews: 400,
		participations: 240,
		completions: 140,
		date: '4th Jan 2024',
	},
	{
		pageViews: 500,
		participations: 440,
		completions: 340,
		date: '5th Jan 2024',
	},
	{
		pageViews: 450,
		participations: 220,
		completions: 200,
		date: '6th Jan 2024',
	},
	{
		pageViews: 400,
		participations: 240,
		completions: 140,
		date: '4th Jan 2024',
	},
	{
		pageViews: 500,
		participations: 440,
		completions: 340,
		date: '5th Jan 2024',
	},
	{
		pageViews: 450,
		participations: 220,
		completions: 200,
		date: '6th Jan 2024',
	},
	{
		pageViews: 400,
		participations: 240,
		completions: 140,
		date: '4th Jan 2024',
	},
	{
		pageViews: 500,
		participations: 440,
		completions: 340,
		date: '5th Jan 2024',
	},
	{
		pageViews: 450,
		participations: 220,
		completions: 200,
		date: '6th Jan 2024',
	},
	{
		pageViews: 400,
		participations: 240,
		completions: 140,
		date: '4th Jan 2024',
	},
	{
		pageViews: 500,
		participations: 440,
		completions: 340,
		date: '5th Jan 2024',
	},
	{
		pageViews: 450,
		participations: 220,
		completions: 200,
		date: '6th Jan 2024',
	},
];
