import EventMetricsCard from './EventMetricsCard';
import { useMemo } from 'react';
import { formatNumberWithCommas } from '@/utils/parsers';

const EventMetrics = ({
	metrics,
}: {
	metrics: {
		totalParticipants: number;
		totalCompletions: number;
		totalCompletionRate: number;
		uniqueUsersCompletions: number;
		uniqueUsersInitiations: number;
	};
}) => {
	const metrics_ = useMemo(
		() => [
			{
				title: 'Total Initiations',
				icon: 'rocket',
				value: metrics.totalParticipants,
				subtitle: 'Bounce rate is 20%',
			},
			{
				title: 'Total Completions',
				icon: 'rocket',
				value: metrics.totalCompletions,
				subtitle: `Completion rate is ${metrics.totalCompletionRate}%`,
			},
			{
				title: 'Unique Users',
				icon: 'people',
				value: metrics.uniqueUsersInitiations,
				subtitle: `Unique users completions are ${formatNumberWithCommas(metrics.uniqueUsersCompletions)}`,
			},
		],
		[metrics],
	);

	return (
		<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mt-7">
			{metrics_.map((i) => (
				<EventMetricsCard {...i} key={i.title} />
			))}
		</div>
	);
};

export default EventMetrics;
