import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { useGetNFTs } from '@/features/nft-contracts/hooks/useGetNFTs';
import { SelectWithSearch } from '@/components/element/inputs/SelectWithSearch';
import { Media } from '@/components/element/media/Media';
import { ExploreNFTsDialog } from './ExploreNFTsDialog';
import ChainBadge from '@/components/element/badges/ChainBadge';
import { DeployContractDialog } from '@/features/nft-contracts/components/DeployContractDialog';

const RewardNFTSelect = ({ value, setValue, error }) => {
	const { nfts, isLoading } = useGetNFTs();
	const [open, setOpen] = useState(false);
	const [showExplore, setShowExplore] = useState(false);

	return (
		<>
			<div className="grid grid-cols-8 gap-2">
				<div className="col-span-6 text-sm font-medium  mb-1">
					<SelectWithSearch
						options={
							isLoading
								? []
								: nfts?.map((i) => ({
										value: i._id,
										label: (
											<div className="flex items-center justify-between w-[380px]">
												<div className="flex items-center">
													<Media
														src={i.imageUrl}
														className="w-6 h-6 rounded me-2"
														lazyLoading
													/>
													{i.name}
												</div>
												<div>
													<Badge
														variant="secondary"
														className="mt-2"
													>
														<ChainBadge
															chainId={i.chainId}
															className=" text-sm "
														/>
													</Badge>
												</div>
											</div>
										),
										searchText: i.name,
										selectedLabel: <div>{i.name}</div>,
									}))
						}
						selected={value}
						setSelected={(contractId) => {
							const contract = nfts.find((i) => i._id === contractId);
							setValue({
								_id: contract._id,
								name: contract.name,
								imageUrl: contract.imageUrl,
								chainId: contract.chainId,
								tokenId: contract.tokenId,
							});
						}}
						placeholder={isLoading ? 'Loading...' : 'Select NFT'}
						popoverClassName="w-[400px]"
						error={error}
					/>
				</div>
				<div className="col-span-2  font-medium mb-1">
					<Button
						variant="outline"
						className="w-full"
						onClick={() => setShowExplore(true)}
					>
						Browse NFTs
					</Button>
				</div>
				<ExploreNFTsDialog
					open={showExplore}
					setOpen={setShowExplore}
					setValue={(contractId: string) => {
						const contract = nfts.find((i) => i._id === contractId);
						setValue({
							_id: contract._id,
							name: contract.name,
							imageUrl: contract.imageUrl,
							chainId: contract.chainId,
							tokenId: contract.tokenId,
						});
					}}
				/>
				<DeployContractDialog open={open} setOpen={setOpen} />
			</div>
			{error && (
				<div className="text-xs text-destructive mt-1">
					Please select a contract
				</div>
			)}
			<div className="text-xs mt-1 mb-5">
				<div
					className="font-medium text-muted-foreground hover:underline cursor-pointer"
					onClick={() => setOpen(true)}
				>
					<i className="bi-info-circle mr-1 text-muted-foreground"></i>
					Deploy a new contract
				</div>
			</div>
		</>
	);
};

export default RewardNFTSelect;
