import React, { useEffect } from 'react';
import ReactConfetti from 'react-confetti';

export const Confetti = () => {
	const [timer, setTimer] = React.useState(0);
	const [opacity, setOpacity] = React.useState(1);

	useEffect(() => {
		const interval = setInterval(() => {
			if (timer > 10) return clearInterval(interval);
			setTimer((prev) => prev + 1);
			setOpacity((prev) => prev - 0.1);
		}, 1000);
		return () => clearInterval(interval);
	}, [timer]);

	return (
		<div
			id="confetti "
			className="absolute  w-100 top-0 start-0 z-[1000]"
			style={{
				zIndex: '100000',
			}}
		>
			<>
				{timer < 10 && (
					<ReactConfetti
						width={window.innerWidth}
						height={window.innerHeight}
						tweenDuration={3000}
						opacity={opacity}
					/>
				)}
			</>
		</div>
	);
};
