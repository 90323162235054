import PageHeader from '@/components/layout/PageHeader';
import { DataTable } from '@/components/element/data-table/DataTable';
import { toast } from 'sonner';
import { useGetAdminCustomTokens } from '../hooks/useGetAdminCustomTokens';
import TokenFilters from '../components/custom-tokens/TokenFilters';
import { tokenColumns } from '../components/custom-tokens/TokenColumns';
import { useAdminCustomTokensActions } from '../hooks/useAdminCustomTokensActions';
import { CustomTokenStatus } from '@/features/campaigns/hooks/tokens/useCreateToken';
import { ITokens } from '@/features/campaigns/hooks/tokens/useGetAllTokens';

const AdminCustomTokens = () => {
	const { search, setSearch, filters, setFilters, isLoading, results, refetch } =
		useGetAdminCustomTokens();

	const { toggleApprovalStatus } = useAdminCustomTokensActions({ refetch });

	const columns = tokenColumns([
		{
			type: 'button',
			label: 'Approve Token',
			onClick: (row: ITokens) => {
				toggleApprovalStatus(row._id, CustomTokenStatus.Approved);
			},
		},
		{
			type: 'button',
			label: 'Reject Token',
			onClick: (row: ITokens) => {
				toggleApprovalStatus(row._id, CustomTokenStatus.Rejected);
			},
		},
		{
			type: 'separator',
		},
		{
			type: 'button',
			label: 'Copy Enterprise ID',
			onClick: (row: ITokens) => {
				navigator.clipboard.writeText(row.enterpriseId?._id);
				toast.success('Enterprise ID copied to clipboard');
			},
		},
	]);

	return (
		<div>
			<PageHeader title="Custom Tokens Approval" />
			<TokenFilters
				search={search}
				filters={filters}
				setSearch={setSearch}
				setFilters={setFilters}
			/>
			<div className="mt-6">
				<DataTable
					data={results}
					columns={columns}
					totalCount={300}
					isLoading={isLoading}
					isServerSide={false}
				/>
			</div>
		</div>
	);
};

export default AdminCustomTokens;
