import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';

export const columns: ColumnDef<any>[] = [
	{
		accessorKey: 'title',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Name" />
		),
		cell: ({ row }) => <div>{row.getValue('title')}</div>,
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'channel',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Channel" />
		),
		cell: ({ row }) => (
			<div className="w-[180px]">{row.getValue('channel')}</div>
		),
		enableSorting: true,
		enableHiding: false,
	},

	{
		accessorKey: 'initiations',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Initiations" />
		),
		cell: ({ row }) => <div>{row.getValue('initiations')}</div>,
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'completions',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Completions" />
		),
		cell: ({ row }) => <div>{row.getValue('completions')}</div>,
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'link',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Link" />
		),
		cell: ({ row }) => (
			<Button
				variant="link"
				size="sm"
				className="px-0 truncate text-start justify-normal max-w-[200px]"
				onClick={() => {
					window.open(row.getValue('link'), '_blank');
				}}
			>
				{row.getValue('link')}
			</Button>
		),
		enableSorting: true,
		enableHiding: false,
	},
];
