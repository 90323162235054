import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';

export const StartTourCard = () => {
	return (
		<Card className="bg-slate-50">
			<CardContent className="py-4">
				<div className="font-medium">Take a quick tour</div>
				<div className="text-sm mb-5">
					Learn the basics with an interactive tour
				</div>
				<Button className="" variant="outline" size="sm">
					Start Tour
				</Button>
			</CardContent>
		</Card>
	);
};
