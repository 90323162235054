import { Input } from '@/components/plate-ui/input';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card';
import { useCreateEvent } from '@/features/events/hooks/create/useCreateEvent';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';

const EventSection = ({
	children,
	title,
	_id,
	addItem,
	isDragging,
	editContainer,
	attributes,
	setNodeRef,
	transition,
	transform,
}) => {
	const { sections } = useCreateEvent();
	const [isOpen, setIsOpen] = useState(true);

	return (
		<Card
			className={`shadow-none p-0 rounded-lg mb-5 ${
				isDragging && 'opacity-50'
			}`}
			{...attributes}
			ref={setNodeRef}
			style={{
				transition,
				transform: CSS.Translate.toString(transform),
			}}
		>
			{sections.length !== 1 && (
				<SectionTitle
					setIsOpen={setIsOpen}
					isOpen={isOpen}
					title={title}
					editContainer={editContainer}
					_id={_id}
				/>
			)}
			{!isDragging && (
				<AnimateHeight duration={300} height={isOpen ? 'auto' : 0}>
					<CardContent className="p-0 ">{children}</CardContent>
					<CardFooter className="p-0 flex flex-row border-t items-center space-y-0">
						<div className="w-[50px] flex items-center justify-center"></div>
						<div className="flex justify-between items-center py-1">
							<Button onClick={() => addItem(_id)} variant="link">
								+ Add Campaign
							</Button>
						</div>
					</CardFooter>
				</AnimateHeight>
			)}
		</Card>
	);
};

export default EventSection;

const SectionTitle = ({ setIsOpen, isOpen, title, editContainer, _id }) => {
	const [isEditing, setIsEditing] = useState(false);
	return (
		<CardHeader className="p-0 flex flex-row border-b items-center space-y-0">
			<div
				className="w-[50px] flex items-center justify-center"
				onClick={() => setIsOpen((p) => !p)}
			>
				<i
					className={`text-muted-foreground text-sm text-purple-600 bi-chevron-down ${
						!isOpen ? '' : 'transform rotate-180'
					}`}
				></i>
			</div>
			<div className="bg-slate-100 border-s py-0 px-4 rounded-tr-lg w-full mt-0 flex items-center justify-start">
				<Input
					variant="ghost"
					className="text-sm font-medium py-4 border-e pe-4 me-3 border-slate-300 "
					value={title}
					onChange={(e) => {
						console.log(e);
						editContainer(_id, 'EDIT_NAME', e.target.value);
					}}
					disabled={!isEditing}
				/>
				{isEditing ? (
					<>
						<i
							className="text-xs  bi-check-circle-fill  me-3"
							onClick={() => setIsEditing(false)}
						></i>
					</>
				) : (
					<>
						<i
							className="text-xs  bi-pencil  me-3"
							onClick={() => setIsEditing(true)}
						></i>
						<i
							className="text-xs  bi-trash "
							onClick={() => editContainer(_id, 'DELETE', true)}
						></i>
					</>
				)}
			</div>
		</CardHeader>
	);
};
