export const ERC20RewardABI = [
	{
		inputs: [
			{ internalType: 'address', name: '_trustedForwarder', type: 'address' },
		],
		stateMutability: 'nonpayable',
		type: 'constructor',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint96',
				name: 'rewardId',
				type: 'uint96',
			},
			{
				indexed: true,
				internalType: 'uint96',
				name: 'userId',
				type: 'uint96',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'userAddress',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amountToClaim',
				type: 'uint256',
			},
		],
		name: 'ERC20Claim',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: true, internalType: 'uint96', name: 'cid', type: 'uint96' },
			{
				indexed: false,
				internalType: 'uint256',
				name: 'oldClaimTime',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'newClaimTime',
				type: 'uint256',
			},
		],
		name: 'ERC20ClaimTimeUpdate',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: true, internalType: 'uint96', name: 'cid', type: 'uint96' },
			{
				indexed: true,
				internalType: 'uint96',
				name: 'rewardId',
				type: 'uint96',
			},
			{
				indexed: true,
				internalType: 'uint96',
				name: 'userId',
				type: 'uint96',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'userAddress',
				type: 'address',
			},
		],
		name: 'ERC20DummyClaim',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: true, internalType: 'uint96', name: 'cid', type: 'uint96' },
			{
				indexed: false,
				internalType: 'address',
				name: 'admin',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'startTime',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'claimTime',
				type: 'uint256',
			},
			{
				components: [
					{ internalType: 'uint96', name: 'rewardId', type: 'uint96' },
					{
						internalType: 'address',
						name: 'tokenAddress',
						type: 'address',
					},
					{ internalType: 'uint256', name: 'numRewards', type: 'uint256' },
				],
				indexed: false,
				internalType: 'struct ERC20QuestRewardsStorage.CampaignReward[]',
				name: 'campaignRewards',
				type: 'tuple[]',
			},
		],
		name: 'ERC20LaunchCampaign',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'oldSigner',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'newSigner',
				type: 'address',
			},
		],
		name: 'ERC20SignerUpdate',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: true, internalType: 'uint96', name: 'cid', type: 'uint96' },
			{
				indexed: true,
				internalType: 'uint96',
				name: 'rewardId',
				type: 'uint96',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'tokenAddress',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'admin',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'ERC20Withdraw',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint8',
				name: 'version',
				type: 'uint8',
			},
		],
		name: 'Initialized',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'previousOwner',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'newOwner',
				type: 'address',
			},
		],
		name: 'OwnershipTransferred',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'account',
				type: 'address',
			},
		],
		name: 'Paused',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'account',
				type: 'address',
			},
		],
		name: 'Unpaused',
		type: 'event',
	},
	{ stateMutability: 'payable', type: 'fallback' },
	{
		inputs: [],
		name: 'ERC20_ACTIVATE_CAMPAIGN_TYPEHASH',
		outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'ERC20_CLAIM_TYPEHASH',
		outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'ERC20_DUMMY_CLAIM_TYPEHASH',
		outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'ERC20_REWARD_CONFIG_TYPEHASH',
		outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint96', name: '', type: 'uint96' }],
		name: 'campaignConfigs',
		outputs: [
			{ internalType: 'address', name: 'admin', type: 'address' },
			{ internalType: 'uint256', name: 'startTime', type: 'uint256' },
			{ internalType: 'uint256', name: 'claimTime', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint96', name: '', type: 'uint96' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
		],
		name: 'campaignToRewards',
		outputs: [{ internalType: 'uint96', name: '', type: 'uint96' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint96', name: '_rewardId', type: 'uint96' },
			{ internalType: 'uint96', name: '_userId', type: 'uint96' },
			{ internalType: 'uint256', name: '_amountToClaim', type: 'uint256' },
			{ internalType: 'bytes', name: '_signature', type: 'bytes' },
		],
		name: 'claim',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint96', name: '_cid', type: 'uint96' },
			{ internalType: 'uint96', name: '_rewardId', type: 'uint96' },
			{ internalType: 'uint96', name: '_userId', type: 'uint96' },
			{ internalType: 'bytes', name: '_signature', type: 'bytes' },
		],
		name: 'dummyClaim',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint96', name: '', type: 'uint96' },
			{ internalType: 'address', name: '', type: 'address' },
		],
		name: 'hasClaimed',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint96', name: '', type: 'uint96' },
			{ internalType: 'uint96', name: '', type: 'uint96' },
		],
		name: 'hasClaimedUserId',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '_intractSigner', type: 'address' },
		],
		name: 'initialize',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'intractSigner',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: 'forwarder', type: 'address' }],
		name: 'isTrustedForwarder',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint96', name: '_cid', type: 'uint96' },
			{ internalType: 'uint256', name: '_startTime', type: 'uint256' },
			{ internalType: 'uint256', name: '_claimTime', type: 'uint256' },
			{
				components: [
					{ internalType: 'uint96', name: 'rewardId', type: 'uint96' },
					{
						internalType: 'address',
						name: 'tokenAddress',
						type: 'address',
					},
					{ internalType: 'uint256', name: 'numRewards', type: 'uint256' },
				],
				internalType: 'struct ERC20QuestRewardsStorage.CampaignReward[]',
				name: '_campaignRewards',
				type: 'tuple[]',
			},
			{ internalType: 'bytes', name: '_signature', type: 'bytes' },
		],
		name: 'launchCampaign',
		outputs: [],
		stateMutability: 'payable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'owner',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'pause',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'paused',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'renounceOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint96', name: '', type: 'uint96' }],
		name: 'rewardConfigs',
		outputs: [
			{ internalType: 'uint96', name: 'cid', type: 'uint96' },
			{ internalType: 'address', name: 'tokenAddress', type: 'address' },
			{ internalType: 'uint256', name: 'numRewards', type: 'uint256' },
			{ internalType: 'uint256', name: 'tokensClaimed', type: 'uint256' },
			{ internalType: 'uint256', name: 'usersClaimed', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
		name: 'transferOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'unpause',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint96', name: '_cid', type: 'uint96' },
			{ internalType: 'uint256', name: '_newClaimTime', type: 'uint256' },
		],
		name: 'updateClaimTime',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '_intractSigner', type: 'address' },
		],
		name: 'updateSigner',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
		name: 'usedDummyClaimHashes',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint96', name: '_cid', type: 'uint96' }],
		name: 'withdraw',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{ stateMutability: 'payable', type: 'receive' },
];
