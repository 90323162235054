import { useState } from 'react';
import { forgotPassword } from '../services/auth.service';
import { ErrorTypes } from '@/utils/notifications';
import { toast } from 'sonner';

export const useForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [formErrors, setFormErrors] = useState<any>({});

	const validateEmail = (email: string): boolean => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};
	const handleSubmit = async (e: any) => {
		e.preventDefault();
		try {
			setIsLoading(true);
			const error: any = {};
			if (!email || !email.trim()) {
				error.email = 'Email is required';
			} else {
				if (!validateEmail(email)) {
					error.email = 'Invalid email format';
				}
			}
			if (Object.keys(error).length > 0) {
				setFormErrors(error);
				return;
			}

			await forgotPassword({ email: email });
			toast.success('Password reset link has been sent to your email');
			setEmail('');
		} catch (err) {
			console.log(err);
			if (err?.response?.data?.code === ErrorTypes.IncorrectEmail) {
				setFormErrors({
					email: 'This email is not registered with us. Please check the email and try again.',
				});
			}
			// toast.error(err.message);
		} finally {
			setIsLoading(false);
		}
	};
	return {
		handleSubmit,
		isLoading,
		setIsLoading,
		formErrors,
		email,
		setEmail,
		setFormErrors,
	};
};

export default useForgotPassword;
