import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useCampaignReport } from './useCampaignReport';
import {
	getCampaignWinners,
	getCampaignWinnersCount,
} from '../../services/campaigns.service';
import { formatUserData } from '@/utils/parsers';

interface IWinners {
	users: {
		completedAt: Date;
		discordId: string;
		discordUsername: string;
		discordAvatar: string;
		events: {
			failedVerificationCount: number;
			isForceVerified: boolean;
			isVerified: boolean;
			isXpClaimed: boolean;
			metadata: { xp: number; templateFamily: string; userInput: any };
			name: string;
			taskId: string;
			verificationDoneAt: Date;
		}[];
		twitterId: string;
		twitterUsername: string;
		twitterAvatar: string;
		userAddresses: string[];
		xp: number;
	}[];
}

export const useCampaignWinners = () => {
	const { id } = useCampaignReport();
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});
	const [sorting, setSorting] = useState([]);

	const { data, isLoading } = useQuery<IWinners>({
		queryKey: ['campaign', 'winners', id, pagination, sorting],
		queryFn: () =>
			getCampaignWinners({
				campaignId: id,
				currentPage: pagination.pageIndex,
				perPage: pagination.pageSize,
			}),
	});

	const { data: count, isLoading: isCountLoading } = useQuery({
		queryKey: ['campaign', 'winners', 'count', id, pagination, sorting],
		queryFn: () =>
			getCampaignWinnersCount({
				campaignId: id,
			}),
	});

	const users =
		data?.users?.map((i) => {
			const { username, avatar } = formatUserData(i);
			return {
				username,
				avatar,
				completedAt: i.completedAt,
				xp: i.xp,
				discord: i.discordUsername,
				twitter: i.twitterUsername,
			};
		}) ?? [];

	return {
		winners: users ?? [],
		isLoading,
		totalCount: count?.count ?? 10,
		isCountLoading,
		pagination,
		setPagination,
		sorting,
		setSorting,
	};
};
