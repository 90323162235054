import { useContext } from 'react';
import { LoyaltyCampaignContext } from '../providers/loyalty-campaigns.provider';

export const useLoyaltyCampaign = () => {
	const context = useContext(LoyaltyCampaignContext);

	if (context === undefined)
		throw new Error(
			'useLoyaltyCampaign must be used within a LoyaltyCampaignContext',
		);

	return context;
};
