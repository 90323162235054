import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from '@/components/ui/hover-card';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

const CampaignCreateMode = () => {
	const { mode, setMode } = useCreateCampaign();
	return (
		<div className="absolute end-0 me-5 flex items-center justify-center">
			<Tabs
				className=""
				defaultValue="simple"
				value={mode}
				onValueChange={(e: 'simple' | 'advanced') => {
					analytics.track(TrackingEvents.CreateQuestToggleMode, {
						mode: e,
					});
					setMode(e);
				}}
			>
				<TabsList className="rounded-full">
					<SimpleModeTooltip isSelected={mode === 'simple'} />
					<AdvancedModeTooltip isSelected={mode === 'advanced'} />
				</TabsList>
			</Tabs>
		</div>
	);
};

export default CampaignCreateMode;

const SimpleModeTooltip = ({ isSelected }) => {
	return (
		<HoverCard openDelay={0} closeDelay={0}>
			<HoverCardTrigger asChild>
				<TabsTrigger
					className="rounded-full "
					value="simple"
					data-state={isSelected ? 'active' : ''}
				>
					<i className="bi bi-sliders me-2"></i>
					Simple
				</TabsTrigger>
			</HoverCardTrigger>
			<HoverCardContent className="w-80">
				<div className="flex justify-between space-x-4">
					<div className="space-y-1">
						<h4 className="text-sm font-semibold">Simple Mode</h4>
						<p className="text-sm">
							Quickly create a campaign with basic settings. Ideal for
							beginners.
						</p>
					</div>
				</div>
			</HoverCardContent>
		</HoverCard>
	);
};

const AdvancedModeTooltip = ({ isSelected }) => {
	return (
		<HoverCard openDelay={0} closeDelay={0}>
			<HoverCardTrigger asChild>
				<TabsTrigger
					className="rounded-full "
					value="advanced"
					data-state={isSelected ? 'active' : ''}
				>
					<i className="bi bi-gear me-2"></i>
					Advance
				</TabsTrigger>
			</HoverCardTrigger>
			<HoverCardContent className="w-80">
				<div className="flex justify-between space-x-4">
					<div className="space-y-1">
						<h4 className="text-sm font-semibold">Advanced Mode</h4>
						<p className="text-sm">
							Build a campaign with advanced settings. Ideal for
							experienced users.
						</p>
						<ul className="text-sm">
							<li>
								<i className="bi-check-circle-fill text-muted-foreground me-2"></i>
								Add multiple rewards
							</li>
							<li>
								<i className="bi-check-circle-fill text-muted-foreground me-2"></i>
								Add SEO metadata
							</li>
							<li>
								<i className="bi-check-circle-fill text-muted-foreground me-2"></i>
								Build task groups
							</li>
						</ul>
					</div>
				</div>
			</HoverCardContent>
		</HoverCard>
	);
};
