import { Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from './login.page';
import RegisterPage from './register.page';
import GoogleCallbackPage from './google-callback.page';
import DiscordCallbackPage from './discord-callback.page';
import ForgotPasswordPage from './forgot-password.page';
import ResetPasswordPage from './reset-password.page';
import AcceptInvitePage from './accept-invite.page';
import { AppError } from '@/components/layout/error/SentryErrorFallback';
import * as Sentry from '@sentry/react';
import RegisterV2Page from './register-v2.page';

export default function AuthRoutes() {
	return (
		<Sentry.ErrorBoundary fallback={AppError} showDialog>
			<Routes>
				<Route path="login" element={<LoginPage />} />
				<Route path="register" element={<RegisterPage />} />
				<Route path="register/v2" element={<RegisterV2Page />} />
				<Route path="google/callback" element={<GoogleCallbackPage />} />
				<Route path="discord/callback" element={<DiscordCallbackPage />} />
				<Route path="forgot-password" element={<ForgotPasswordPage />} />
				<Route path="reset-password" element={<ResetPasswordPage />} />
				<Route path="accept-invite" element={<AcceptInvitePage />} />
				{/* <Route path="*" element={<Navigate replace to="/login" />} /> */}
			</Routes>
		</Sentry.ErrorBoundary>
	);
}
