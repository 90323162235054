import { Button } from '@/components/ui/button';
import { Checkbox } from '@radix-ui/react-checkbox';

export const LaunchConfirmation = () => {
	return (
		<div>
			<div className="flex flex-col mb-8">
				<div className="h-14 w-14 mb-4 flex items-center bg-violet-400 border-violet-500 border justify-center rounded-lg me-3">
					<i className="bi-rocket-takeoff-fill text-white text-2xl"></i>
				</div>
				<div>
					<h3 className="text-xl font-medium ">Publish your Campaign</h3>
					<p className="text-sm text-gray-500">
						Before launching, please review and understand this following
						points!
					</p>
				</div>
			</div>

			<div className="py-3 border rounded-lg bg-slate-50 my-5 px-3 flex items-center">
				<Checkbox checked className="me-4" />
				<div className="text-sm ">
					You will not be able to make any changes once a campaign is live.
					All participating users will be eligible for the reward.
				</div>
			</div>
			<div className="py-3 border rounded-lg bg-slate-50 my-5 px-3 flex items-center">
				<Checkbox checked className="me-4" />
				<div className="text-sm ">
					Rewards are unlimited in this campaign! Every user participating
					will receive their reward
				</div>
			</div>
			<div className="py-3 border rounded-lg bg-slate-50 my-5 px-3 flex items-center">
				<Checkbox checked className="me-4" />
				<div className="text-sm ">
					Once launched, the campaign will become active at the scheduled
					start time. You can modify the start and end time at the last
					step again.
				</div>
			</div>
		</div>
	);
};
