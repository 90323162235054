import { Button } from '@/components/ui/button';
import { useAuth } from '@/hooks/useAuth';

const DashboardMobileLayout = () => {
	const { logout } = useAuth();

	return (
		<div className="w-screen h-screen flex flex-col items-center justify-center text-center">
			<img
				src="https://framerusercontent.com/images/axBztP7wGIM2Oz1MQwfrxJ0lg.png"
				className="w-48 rounded-lg"
			/>
			<div className="mb-2  mt-5 text-lg font-medium">
				Dashboard not supported on mobile
			</div>
			<div className="text-sm text-muted-foreground">
				This feature is not available on mobile view. Please switch to
				desktop view.
			</div>
			<div>
				<Button
					variant="outline"
					className="mt-4"
					onClick={() => {
						window.open('https://intract.io', '_blank');
					}}
				>
					<span>Check out Live Quests on Intract</span>
				</Button>
			</div>
			<div
				className="absolute bottom-0 pb-3 text-xs hover:underline cursor-pointer"
				onClick={() => logout()}
			>
				Log out
			</div>
		</div>
	);
};

export default DashboardMobileLayout;
