import { Separator } from '@/components/ui/separator';
import PageHeader from '@/components/layout/PageHeader';
import { useAuth } from '@/hooks/useAuth';
import IntegrationCard from '../components/IntegrationCard';
import discordLogo from '@/assets/logos/discord.png';
import telegramLogo from '@/assets/logos/telegram.jpeg';
import { useIntegrations } from '../hooks/useIntegrations';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConnectTelegramDialog } from '../components/telegram/ConnectTelegramDialog';
import ConnectedIntegrations from '../components/ConnectedIntegrations';
import { useQueryInitialise } from '@/hooks/useQueryInitialise';

const IntegrationsPage = () => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const { integrations: data, isLoading } = useIntegrations();
	const [showTelegram, setShowTelegram] = useState(false);

	useQueryInitialise('tab', 'telegram', () => {
		setShowTelegram(true);
	});

	const availableIntegrations = useMemo(
		() => [
			{
				title: 'Discord',
				description:
					'Connect Discord to assign and reward users exclusive discord roles',
				img: discordLogo,
				background:
					'linear-gradient(42deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6909357492997199) 83%, rgba(48,162,230,0.04) 100%)',
				isConnected: user.integrationStatus.discord,
				onClick: () => {
					navigate('/app/integrations/discord');
				},
			},
			{
				title: 'Telegram',
				description: 'Connect Telegram to verify related tasks',
				img: telegramLogo,
				background:
					'linear-gradient(42deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6909357492997199) 43%, rgba(87,101,242,0.04) 100%)',
				isConnected: user.integrationStatus.telegram,
				onClick: () => {
					setShowTelegram(true);
				},
			},
		],
		[user.integrationStatus],
	);

	return (
		<div>
			<PageHeader
				title="Integrations"
				description="Integrate with your favorite tools and services to automate your workflow."
			/>
			<Separator className="my-4" />
			<ConnectedIntegrations isLoading={isLoading} integrations={data} />
			<div>
				<Separator className="my-16" />
				<div className=" mb-10">
					<div className="text-lg font-medium mb-3">
						Available Integrations
					</div>
					<div className="grid grid-cols-4 2xl:grid-cols-4 gap-5">
						{availableIntegrations.map((i) => (
							<IntegrationCard {...i} />
						))}
					</div>
				</div>
			</div>
			<ConnectTelegramDialog open={showTelegram} setOpen={setShowTelegram} />
		</div>
	);
};

export default IntegrationsPage;
