import { useQuery } from '@tanstack/react-query';
import { apiRequests } from '../services/task-apis.service';
import useTaskAPITable from './useTaskAPITable';
import { IApiRequest } from '../types/task-apis.type';
import { useMemo, useState } from 'react';
import { TaskAPIStatus } from '../types/task-apis.enum';

export const useGetTaskAPIs = () => {
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState<{
		status: TaskAPIStatus[];
	}>({
		status: [],
	});
	const [showNew, setShowNew] = useState(false);

	const { taskApiColumns } = useTaskAPITable({
		setShowNew,
	});

	const apiRequestsQuery = useQuery<IApiRequest[]>({
		queryKey: ['api-requests'],
		queryFn: () => apiRequests(),
	});

	const filteredData = useMemo(() => {
		if (!apiRequestsQuery?.data) return [];
		return apiRequestsQuery?.data.filter((i) => {
			if (search && !i.apiName.toLowerCase().includes(search.toLowerCase()))
				return false;
			if (filters?.status.length && !filters.status.includes(i.status))
				return false;
			return true;
		});
	}, [apiRequestsQuery.data, filters, search]);

	return {
		apiRequestsQuery,
		taskApiColumns,
		showNew,
		setShowNew,
		filteredAPIs: filteredData,
		filters,
		setFilters,
		search,
		setSearch,
	};
};
