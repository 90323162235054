import { Button } from '@/components/ui/button';
import { useGlobalSearch } from '../../../../hooks/useGlobalSearch';

const GlobalSearch = () => {
	const { setOpen } = useGlobalSearch();

	return (
		<Button
			variant="outline"
			className="rounded-xl w-full text-base py-5 flex justify-between items-center"
			onClick={() => setOpen(true)}
		>
			<div className="text-muted-foreground font-normal text-sm">
				<i className="bi bi-search"></i> &nbsp; Search your campaigns and
				events
			</div>
			<div>
				<div className="text-xs text-muted-foreground">⌘J</div>
			</div>
		</Button>
	);
};

export default GlobalSearch;
