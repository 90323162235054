import { Badge } from '@/components/ui/badge';
import { convertCamelCaseToSentence } from '@/utils/parsers';
import TaskOptions from '../features/TaskOptions';
import { ICreateTasks } from '@/features/campaigns/types';
import { Select, SelectContent, SelectItem } from '@/components/ui/select';
import { SelectTrigger, SelectValue } from '@radix-ui/react-select';
import { useCreateCampaignTasks } from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import { useMemo } from 'react';
import { CaretSortIcon } from '@radix-ui/react-icons';

interface ITaskHeaderProps extends ICreateTasks {
	isReordering: boolean;
	isEditingTitle: boolean;
	setIsEditingTitle: any;
}

const TaskHeader = ({
	isReordering,
	isEditingTitle,
	setIsEditingTitle,
	...task
}: ITaskHeaderProps) => {
	return (
		<div
			className={`flex items-center justify-between px-4 py-4 bg-slate-50 ${
				!isReordering && task.isEditing ? 'rounded-t-lg' : 'rounded-lg'
			} border border-slate-300  transition-all duration-700`}
		>
			<div className="flex-grow pe-5">
				<div className=" flex items-center ">
					<div className=" border rounded-full flex items-center h-10 w-10 justify-center me-2">
						{task?.logo?.type === 'icon' && (
							<i
								className={`bi ${task.logo?.value} text-blue-600 text-lg`}
							></i>
						)}
					</div>
					<div className="flex-grow">
						<div className="text-[10px] text-muted-foreground rounded-full px-2 border bg-slate-200 inline-flex text-slate-800 font-medium">
							{convertCamelCaseToSentence(task.templateType)}
						</div>
						<div className="flex items-center mt-0">
							<div className="font-medium text-sm">
								{task.description}
							</div>
							{!isReordering && task.isEditing && (
								<div className="ms-2 cursor-pointer pb-1">
									{isEditingTitle ? (
										<>
											<i
												className="text-xs  bi-check-circle-fill "
												onClick={() =>
													setIsEditingTitle(false)
												}
											></i>
										</>
									) : (
										<>
											<i
												className="text-xs  bi-pencil-fill text-muted-foreground "
												onClick={() => {
													setIsEditingTitle(true);
												}}
											></i>
										</>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="items-center flex min-w-[130px] justify-end">
				<SelectXP task={task} />
				{task.isEditing && <TaskOptions {...task} />}
			</div>
		</div>
	);
};

export default TaskHeader;

const SelectXP = ({ task }: { task: ICreateTasks }) => {
	const { editTask } = useCreateCampaignTasks();
	const options = useMemo(() => {
		if (task.minXp && task.maxXp) {
			const length = Math.ceil((task.maxXp - task.minXp + 1) / 5);
			return Array.from({ length }, (_, i) => {
				const value = i * 5 + task.minXp;
				return { value, label: value };
			});
		}
		return [];
	}, [task]);

	if (!options.length)
		return (
			<Badge variant="secondary" className="border border-slate-200 me-3">
				{task.xp} XP
			</Badge>
		);
	return (
		<Select
			value={task.xp?.toString()}
			onValueChange={(v) => {
				editTask(task.taskId, 'EDIT_XP', Number(v));
			}}
		>
			<SelectTrigger>
				<Badge
					variant="secondary"
					className="border border-slate-200 me-3 pe-0"
				>
					<SelectValue />
					<CaretSortIcon className="ml-1 h-4 w-4 shrink-0 opacity-50" />
				</Badge>
			</SelectTrigger>
			<SelectContent>
				{options.map((i) => (
					<SelectItem key={i.value} value={i.value?.toString()}>
						{i.label} XP
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};
