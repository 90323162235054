import useHtmlClassObserver from '@/hooks/useHtmlElementClassObserver';
import { useEffect, useState } from 'react';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';

const GoogleTranslateWarningModal = () => {
	const htmlElementClassNames = useHtmlClassObserver(document.documentElement);

	const [shouldShowModal, setShouldShowModal] = useState(false);
	const [hasModalBeenSeen, setHasModalBeenSeen] = useState(false);

	useEffect(() => {
		if (
			htmlElementClassNames.some((className) =>
				className.startsWith('translated'),
			)
		) {
			setShouldShowModal(true);
		} else {
			setShouldShowModal(false);
			setHasModalBeenSeen(false);
		}
	}, [htmlElementClassNames]);

	return (
		<Dialog
			open={shouldShowModal && !hasModalBeenSeen}
			onOpenChange={() => setHasModalBeenSeen(true)}
		>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Google Chrome Translation</DialogTitle>
				</DialogHeader>
				<div>
					Due to a bug in Google Chrome, we are currently unable to support
					the Google Translate feature. If you choose to continue using
					Google Translate, you may experience errors while using this
					application. You may choose to disable Google Translate or use a
					different browser. We&apos;re sorry for the inconvenience and are
					working to remedy this issue.
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default GoogleTranslateWarningModal;
