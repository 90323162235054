import LandingLayout from '../components/layout/LandingLayout';
import CoreFeatures from '../components/features/CoreFeatures';
import FeatureFAQs from '../components/features/FeatureFAQs';
import FeaturesHero from '../components/features/FeaturesHero';
import FeatureValues from '../components/features/FeatureValues';

const FeaturesPage = () => {
	return (
		<LandingLayout>
			<FeaturesHero />
			<CoreFeatures />
			<FeatureValues />
			<FeatureFAQs />
		</LandingLayout>
	);
};

export default FeaturesPage;
