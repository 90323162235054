import QuestDetails from '../components/details/QuestDetails';
import RewardDetails from '../components/details/RewardDetails';
import TaskDetails from '../components/details/TaskDetails';

const Overview = () => {
	return (
		<div className="grid grid-cols-3 gap-5 mt-5">
			<QuestDetails />
			<RewardDetails />
			<TaskDetails />
		</div>
	);
};

export default Overview;
