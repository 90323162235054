import { useNavigate } from 'react-router-dom';
import { DataTable } from '@/components/element/data-table/DataTable';
import { eventsColumns } from '../../core/events-columns';
import NoDataCard from '@/components/element/cards/NoDataCard';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import InputText from '@/components/element/inputs/InputText';
import { FacetedFilter } from '@/components/element/inputs/FacetedFilter';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { AlphaHubNarrativeStatus } from '../../types/events.type';
import { useNotifications } from '@/features/dashboard/hooks/useNotifications';
import EventNotification from './EventNotification';

const EventsTable = ({
	events,
	searchInput,
	isLoading,
	setSearchInput,
	filters,
	setFilters,
	totalEvents,
}) => {
	const navigate = useNavigate();
	const { eventNotification } = useNotifications();

	if (isLoading) return <FullPageLoading />;
	if (totalEvents === 0)
		return (
			<NoDataCard
				title="Power Up Your Reach with Events"
				description="Create an event to combine quests and boost user engagement through exciting time-bound challenges."
				btnLabel="Create Your First Event"
				image="https://framerusercontent.com/images/qO5JpGs3474yfaRXefwwdFFAiyg.png"
				btnOnClick={() => navigate('/app/campaign/events/new')}
				className="mt-10"
			/>
		);

	return (
		<div className="mt-8">
			{eventNotification && (
				<EventNotification notification={eventNotification} />
			)}
			<div className="flex items-center mt-10 mb-5 space-x-4">
				<InputText
					value={searchInput}
					setValue={setSearchInput as any}
					prepend={
						<i className="bi-search px-2 text-muted-foreground text-sm"></i>
					}
					placeholder="Search Campaigns"
					className="w-[250px] lg:w-[250px]"
				/>
				<FacetedFilter
					title="Status"
					options={statusOptions}
					selectedValues={filters.status}
					setSelectedValues={(values: any) =>
						setFilters((prev) => ({ ...prev, status: values }))
					}
				/>
			</div>
			<div>
				<DataTable
					columns={eventsColumns}
					data={events ?? []}
					onRowClick={(row) => {
						if (row.status === AlphaHubNarrativeStatus.Draft) {
							navigate(`/app/campaign/events/${row._id}/update`);
						} else {
							navigate(`/app/campaign/events/${row._id}`);
						}
					}}
					isLoading={isLoading}
					totalCount={events?.length}
				/>
			</div>
		</div>
	);
};

export default EventsTable;

const statusOptions = [
	{
		value: AlphaHubNarrativeStatus.Active,
		label: (
			<StatusBadge
				status={AlphaHubNarrativeStatus.Active}
				className="text-sm"
			/>
		),
	},
	{
		value: AlphaHubNarrativeStatus.Completed,
		label: (
			<StatusBadge
				status={AlphaHubNarrativeStatus.Completed}
				className="text-sm"
			/>
		),
	},
	{
		value: AlphaHubNarrativeStatus.Draft,
		label: (
			<StatusBadge
				status={AlphaHubNarrativeStatus.Draft}
				className="text-sm"
			/>
		),
	},
	{
		value: AlphaHubNarrativeStatus.Scheduled,
		label: (
			<StatusBadge
				status={AlphaHubNarrativeStatus.Scheduled}
				className="text-sm"
			/>
		),
	},
];
