import { cn, getTimezone } from '@/lib/utils';
import { ITaskInputsProps } from './TaskInputs';
import { SelectTimeStamp } from '@/components/element/inputs/input-duration/SelectTimeStamp';

const TaskInputDate = ({
	adminInput,
	taskId,
	editTaskAdminInput,
}: ITaskInputsProps) => {
	return (
		<>
			<div className="text-sm font-medium mb-1">{adminInput.label}</div>
			{adminInput.description && (
				<div className="text-xs text-muted-foreground mb-1">
					{adminInput.description}
				</div>
			)}
			<div className="relative">
				<SelectTimeStamp
					isStart={false}
					isContinous={false}
					value={adminInput.value as Date}
					setValue={(value) => {
						editTaskAdminInput(taskId, adminInput.key, value);
					}}
					toDate={undefined}
					fromDate={new Date()}
					timezone={getTimezone()}
				/>{' '}
			</div>
		</>
	);
};

export default TaskInputDate;
