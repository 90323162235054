import Spinner from '@/components/element/loading/Spinner';
import { Input } from '@/components/ui/input';
import { useCreateCampaignValidateAdminInputs } from '@/features/campaigns/hooks/create/useCreateCampaignValidateAdminInputs';
import { cn } from '@/lib/utils';
import { ITaskInputsProps } from './TaskInputs';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Button } from '@/components/ui/button';
import InputText from '@/components/element/inputs/InputText';
import { set } from 'date-fns';

const TaskInputStringArray = ({
	adminInput,
	taskId,
	error,
	setErrors,
	editTaskAdminInput,
	templateFamily,
	templateType,
}: ITaskInputsProps) => {
	const { isLoading, message, validateTaskAdminInput } =
		useCreateCampaignValidateAdminInputs(
			taskId,
			adminInput.value,
			adminInput.key,
			setErrors,
		);

	useEffect(() => {}, [adminInput.value]);

	const addOption = () => {
		const currentValue =
			(adminInput.value as { value: string; id: string }[]) ?? [];
		const newValue = [
			...currentValue,
			{
				value: '',
				id: uuid(),
			},
		];
		editTaskAdminInput(taskId, adminInput.key, newValue);
	};

	const editOption = (value: string, id: string) => {
		const currentValue =
			(adminInput.value as { value: string; id: string }[]) ?? [];
		const newValue = [...currentValue];
		const index = newValue.findIndex((i) => i.id === id);
		newValue[index].value = value;
		editTaskAdminInput(taskId, adminInput.key, newValue);
	};

	const deleteOption = (id: string) => {
		const currentValue =
			(adminInput.value as { value: string; id: string }[]) ?? [];
		const newValue = [...currentValue];
		const index = newValue.findIndex((i) => i.id === id);
		newValue.splice(index, 1);
		editTaskAdminInput(taskId, adminInput.key, newValue);
	};

	return (
		<>
			<div className="relative mt-1">
				<div className="flex justify-between items-end  mb-1">
					<div>
						<div className="text-sm font-medium mb-1">
							{adminInput.label}
						</div>
						{adminInput.description && (
							<div className="text-xs text-muted-foreground ">
								{adminInput.description}
							</div>
						)}
					</div>
					<Button
						variant="outline"
						size="sm"
						className=""
						onClick={addOption}
					>
						<i className="bi bi-plus-circle-fill text-xs me-2"></i> Add{' '}
					</Button>
				</div>
				<div>
					<div className="grid grid-cols-2 gap-2 mt-2">
						{adminInput.value &&
							Array.isArray(adminInput.value) &&
							adminInput.value?.map((i, index) => (
								<div className="flex space-x-1">
									<InputText
										placeholder={
											adminInput.placeholder ??
											`Option ${index + 1}`
										}
										value={i.value}
										setValue={(e) => {
											editOption(e, i.id);
										}}
										error={error}
										className="flex-grow"
										onBlur={() => {
											validateTaskAdminInput(
												adminInput.inputType,
												templateFamily,
												templateType,
											);
										}}
										append={
											<Button
												size="icon"
												variant="ghost"
												onClick={() => deleteOption(i.id)}
											>
												<i className="bi bi-x-circle-fill text-muted-foreground"></i>
											</Button>
										}
									/>
								</div>
							))}
					</div>
				</div>
				<div className="absolute top-0 right-0 h-full flex items-center pr-2">
					{isLoading && <Spinner />}
				</div>
			</div>
			{error && (
				<div className="text-red-500 text-xs mt-1">
					{message || error || 'This field is requird'}
				</div>
			)}
		</>
	);
};

export default TaskInputStringArray;
