import { Button } from '@/components/ui/button';
import { DaveQueryCreateSectionEnum } from '../../../types/dave.enum';
import { useCreateQueryProvider } from '@/features/dave/hooks/queries/useCreateQueryProvider';
import { useCreateQueryNavigation } from '@/features/dave/hooks/queries/useCreateQueryNavigation';
import { ReactNode } from 'react';
import Spinner from '@/components/element/loading/Spinner';

const DaveQueryCreateNavigation = () => {
	const { section, contextualSuggestions } = useCreateQueryProvider();
	const { moveBack, moveForward, isLoading, forwardLabel } =
		useCreateQueryNavigation();

	return (
		<div className="flex justify-between items-center space-x-4 border-t p-3 pb-3 px-4">
			<div className="min-w-[200px]">
				{section !== DaveQueryCreateSectionEnum.Filters && (
					<Button
						className=" px-10"
						variant="secondary"
						onClick={() => moveBack()}
					>
						<i className="bi bi-arrow-left me-2"></i>
						Back
					</Button>
				)}
			</div>
			<div>
				<div className="text-xs">
					{contextualSuggestions.id && (
						<div
							className={`text-xs  repeat-[3] animate-wiggle`}
							key={contextualSuggestions.id}
						>
							{contextualSuggestions.element as ReactNode}
						</div>
					)}
				</div>
			</div>
			<Button className=" px-10" onClick={() => moveForward()}>
				{isLoading && <Spinner className="me-2" />}
				{forwardLabel}
				<i className="bi bi-arrow-right ms-2"></i>
			</Button>
		</div>
	);
};

export default DaveQueryCreateNavigation;
