import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DropzoneState, useDropzone } from 'react-dropzone';
import { getFileType } from '@/utils/parsers';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';

interface DropzoneProps {
	onFileChange: (file: File[] | File, fileName?: string) => void;
	labelClassName?: string;
	flexDirection?: 'row' | 'column' | 'flex-column';
	initialFile?: string;
	flex?: boolean;
	required?: boolean;
	label?: string;
	files?: File[];
	onFileClear?: () => void;
	light?: boolean;
	height?: string;
	isMulti?: boolean;
	limit?: number;
	error?: boolean | string;
	errorText?: string;
	description?: string;
	children?: React.ReactNode;
	className?: string;
	allowedFiles?: string[];
	maxSize?: number;
	aspectRatio?: number;
}

const supportedVideoExtensions = [
	'.mp4',
	'.mov',
	'.wmv',
	'.flv',
	'.avi',
	'.mkv',
	'.webm',
];

export const Dropzone = ({
	onFileChange,
	labelClassName,
	flexDirection,
	initialFile,
	required,
	label,
	files,
	onFileClear,
	height,
	isMulti,
	error,
	aspectRatio,
	errorText,
	children,
	className,
	description,
	maxSize = 2,
	allowedFiles,
}: DropzoneProps) => {
	const [fileName, setFileName] = useState('');
	const [selected, setSelected] = useState('');
	const [fileType, setFileType] = useState('');

	useEffect(() => {
		setFileName('File');
		setSelected(initialFile);
		setFileType(getFileType(initialFile));
	}, [initialFile]);

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			if (acceptedFiles.length > 0) {
				if (
					acceptedFiles.some((item) => {
						return item.size > (maxSize ?? 2) * 10e5;
					})
				) {
					toast.error(
						`Please upload a file which is less than ${maxSize}MB. Files over that size are not allowed.`,
					);
					return;
				}
				if (isMulti) {
					setFileName(acceptedFiles.map((item) => item.name).join(', '));
					onFileChange([...(files || []), ...acceptedFiles]);
				} else {
					const fileLink = URL.createObjectURL(acceptedFiles[0]);
					setFileName(acceptedFiles[0].name);
					onFileChange(acceptedFiles[0], fileLink);
					setSelected(URL.createObjectURL(acceptedFiles[0]));
					setFileType(getFileType(acceptedFiles[0].name));
				}
			} else {
				// 	`File submitted is not supported. Please upload a file with the following extensions: ${type}.`,
			}
		},
		[onFileChange, files, isMulti],
	);

	const acceptFiles = useMemo(() => {
		const accept = {};
		if (allowedFiles?.includes('images')) {
			accept['image/png'] = ['.png'];
			accept['image/gif'] = ['.gif'];
			accept['image/webp'] = ['.webp'];
			accept['image/jpeg'] = ['.jpeg', '.jpg'];
		}
		if (allowedFiles?.includes('videos')) {
			accept['video/*'] = supportedVideoExtensions;
		}
		return accept;
	}, [allowedFiles]);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	}: DropzoneState = useDropzone({
		onDrop,
		accept: acceptFiles,
		maxFiles: isMulti ? 5 : 1,
	});

	useEffect(() => {
		setFileName(files?.map((item) => item?.name).join(', '));
		if (files && files.length > 0) {
			setFileType(getFileType(files[0].type));
		}
	}, [files]);

	const style = useMemo(() => {
		if (aspectRatio) {
			return {
				...{
					...baseStyle,
					padding: `calc(100% / ${aspectRatio}) 0px`,
					width: '100%',
				},
				...(isDragActive ? activeStyle : {}),
				...(isDragAccept ? acceptStyle : {}),
				...(isDragReject ? rejectStyle : {}),
				...(error ? rejectStyle : {}),
			};
		}
		return {
			...{
				...baseStyle,
				minHeight: height || '20vh',
				maxHeight: height || '20vh',
			},
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
			...(error ? rejectStyle : {}),
		};
	}, [isDragActive, isDragReject, isDragAccept, error, aspectRatio]);

	const clearSelection = () => {
		setSelected('');
		setFileName('');
		onFileChange([]);
		onFileClear();
	};

	return (
		<form>
			<Label
				labelClassName={labelClassName}
				label={label}
				required={required}
				description={description}
			/>
			<div className={`flex ${flexDirection && flexDirection}`}>
				{selected && (
					<UploadedFile
						clearSelection={clearSelection}
						selected={selected}
						fileType={fileType}
					/>
				)}
				{!selected && (
					<>
						<div className={className} {...getRootProps({ style })}>
							<div className="dz-message h-full flex-col items-center py-10 justify-center flex">
								{isDragActive ? (
									<p className="mb-0">Drop the files here ...</p>
								) : fileName ? (
									fileName
								) : (
									children
								)}
							</div>
							<input {...getInputProps()} className={`dropzone`} />
						</div>
						{error && (
							<div className=" text-destructive text-sm block mt-2">
								{errorText}
							</div>
						)}
					</>
				)}
			</div>
		</form>
	);
};

const UploadedFile = ({ clearSelection, selected, fileType }) => {
	return (
		<div className=" relative">
			<div className="absolute " style={{ right: '-5px', top: '-5px' }}>
				<Button size="icon" onClick={clearSelection}>
					<i className="bi-x"></i>
				</Button>
			</div>
			{fileType === 'image' ? (
				<img
					src={selected}
					alt=""
					className=" border rounded-2 mb-3"
					style={{
						objectFit: 'cover',
						width: '100%',
						height: '240px',
					}}
				/>
			) : (
				<video
					src={selected}
					className="border rounded-2 mb-3"
					style={{
						objectFit: 'cover',
						width: '100%',
						height: '240px',
					}}
				/>
			)}
		</div>
	);
};

const Label = ({ labelClassName, label, required, description }) => {
	return (
		<label className={clsx('form-label', labelClassName)}>
			{label} {required && <span className="text-danger">*</span>}
			{description && (
				<p
					style={{
						color: '#6f6f6f',
						fontSize: '13px',
						fontWeight: '400',
						display: 'flex',
						alignItems: 'center',
						gap: '4px',
						marginTop: '2px',
					}}
				>
					<i className="bi-info-circle"></i>
					{description}
				</p>
			)}
		</label>
	);
};

const baseStyle = {
	flex: 1,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '20px',
	borderWidth: 1.4,
	borderRadius: 5,
	borderStyle: 'dashed',
	outline: 'none',
	transition: 'border .24s ease-in-out',
};

const activeStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};
