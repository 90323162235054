import { DotsHorizontalIcon } from '@radix-ui/react-icons';

import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

interface IDropdownOptions {
	type: string | 'item' | 'separator';
	label?: string;
	onClick?: any;
}

const DotsDropdown = ({ options }: { options: IDropdownOptions[] }) => {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant="outline"
					size="icon"
					className="data-[state=open]:bg-muted h-9 w-6"
				>
					<i className="bi-three-dots-vertical"></i>
					<span className="sr-only">Open menu</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end" className="w-[160px]">
				{options.map((i) => (
					<>
						{i.type === 'item' && (
							<DropdownMenuItem onClick={i.onClick}>
								{i.label}
							</DropdownMenuItem>
						)}
						{i.type === 'separator' && <DropdownMenuSeparator />}
					</>
				))}
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default DotsDropdown;
