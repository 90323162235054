import LeaderboardTable from '@/features/leaderboard/components/table/LeaderboardTable';
import { useLeaderboard } from '@/features/leaderboard/hooks/useLeaderboard';
import { useLoyaltyCampaign } from '../../hooks/useLoyaltyCampaign';

const LoyaltyLeaderboard = () => {
	const { id } = useLoyaltyCampaign();
	const {
		isLoading,
		users,
		totalCount,
		pagination,
		setPagination,
		setSorting,
		sorting,
	} = useLeaderboard({ campaignId: id });

	return (
		<div>
			<div className="mt-5">
				<LeaderboardTable
					isLoading={isLoading}
					users={users}
					totalCount={totalCount}
					showCampaignXps={true}
					pagination={pagination}
					setPagination={setPagination}
					sorting={sorting}
					setSorting={setSorting}
				/>
			</div>
		</div>
	);
};

export default LoyaltyLeaderboard;
