import NoDataCard from '@/components/element/cards/NoDataCard';
import PageHeader from '@/components/layout/PageHeader';
import { Separator } from '@/components/ui/separator';

const RewardsHubPage = () => {
	return (
		<div>
			<div>
				<PageHeader
					title="Rewards Hub"
					description="For a loving community, set up rewards for your users."
					btnLabel="Set up Rewards"
				/>
				<Separator className="my-4" />
				<NoDataCard
					title="Set up your first reward"
					description="Verify any on-chain action without writing a single line of code."
					btnLabel="Set up Rewards"
				/>
			</div>
		</div>
	);
};

export default RewardsHubPage;
