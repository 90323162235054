import { useCallback, useMemo } from 'react';
import SelectDate from './SelectDate';
import moment from 'moment-timezone';
import { isTimeGreater } from '@/utils/parsers';
import { SelectTime } from './SelectTime';
import { useCall } from 'wagmi';

export const SelectTimeStamp = ({
	isStart,
	isContinous,
	value,
	setValue,
	setIsContinous,
	toDate,
	fromDate,
	timezone,
	minTimestamp,
}: {
	isStart?: boolean;
	isContinous?: boolean;
	value: Date;
	setValue: (date: Date) => void;
	setIsContinous?: (isContinous: boolean) => void;
	toDate?: Date;
	fromDate: Date;
	timezone: string;
	minTimestamp?: string;
}) => {
	// const [value, setValue] = useState<Date | null>(null);
	const continuousLabel = !setIsContinous
		? null
		: isStart
			? 'Start Immediately'
			: 'No Deadline';

	const getComponentsFromDate = useCallback(
		(value: Date): { date: string; time: string } => {
			try {
				const date_ = moment(value).toDate();
				const date = moment.tz(date_, timezone).format('YYYY-MM-DD');
				const time = moment.tz(date_, timezone).format('hh:mm a');
				return { date, time };
			} catch (err) {
				return { date: '', time: '' };
			}
		},
		[timezone],
	);

	const values = useMemo(() => {
		let obj = { date: '', time: '' };
		if (value) {
			obj = getComponentsFromDate(value);
		}
		return obj;
	}, [value, getComponentsFromDate]);

	const options = useMemo(() => {
		const options = [...timeOptions];
		if (
			values.time &&
			timeOptions.findIndex((option) => option.value === values.time) === -1
		) {
			options.push({ value: values.time, label: values.time });
		}

		if (minTimestamp) {
			return options.filter((i) => {
				return isTimeGreater(i.value, minTimestamp);
			});
		}
		return options;
	}, [values.time, minTimestamp]);

	const getDateFromComponents = ({ time, date }) => {
		try {
			// if (!time || !date || !timezone) return null;
			if (!time) {
				time = '12:00 am';
			}
			if (time && !date) {
				date = values.date; //should be the prev selected date
			}
			if (minTimestamp) {
				if (isTimeGreater(minTimestamp, time)) {
					time =
						timeOptions.find((i) => {
							return isTimeGreater(i.value, minTimestamp);
						})?.value || '12:00 am';
				}
			}
			const formattedDate = moment(new Date(date)).format('YYYY-MM-DD');
			let hour = time.split(':')[0];
			const minutes = time.split(':')[1].split(' ')[0];
			const am = time.split(':')[1].split(' ')[1];
			if (am === 'pm') hour = hour + 12;
			if (am === 'am' && hour === 12) hour = 0;
			const formattedTime = `${hour}:${minutes} ${am}`;
			const parsedDate = moment
				.tz(
					`${formattedDate} ${formattedTime}`,
					'YYYY-MM-DD hh:mm a',
					timezone,
				)
				.format();
			setValue(new Date(parsedDate));
		} catch (err) {
			console.log(err);
			//
		}
	};

	return (
		<div className="grid grid-cols-2 gap-4">
			<SelectDate
				date={values.date}
				setDate={(date: Date) => getDateFromComponents({ ...values, date })}
				continuousLabel={continuousLabel}
				isContinous={!setIsContinous ? null : isContinous}
				fromDate={fromDate}
				toDate={toDate}
				setIsContinous={!setIsContinous ? null : setIsContinous}
				isStart={isStart}
			/>
			<SelectTime
				time={values.time}
				setTime={(time: string) => {
					getDateFromComponents({ ...values, time });
				}}
				disabled={isContinous || !values.date}
				options={options}
			/>
		</div>
	);
};

const timeOptions = Array(24)
	.fill(null)
	.map((_, index) => {
		let hour: any = index;
		let minutes: any = 0;
		let isAm: boolean;
		if (hour < 12) isAm = true;
		if (hour > 12) hour = hour - 12;
		if (hour === 0) hour = 12;
		if (minutes === 0) minutes = '00';
		if (hour < 10) hour = '0' + hour;
		const time = hour + ':' + minutes + ' ' + (isAm ? 'am' : 'pm');
		return { value: time, label: time === '12:00 am' ? '12:00 midnight' : time };
	});

export default SelectTime;
