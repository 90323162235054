import LoyaltySection from './LoyaltySection';
import LoyaltyTask from '../task/LoyaltyTask';
import { useState } from 'react';
import AddLoyaltySection from './AddLoyaltySection';
import { Button } from '@/components/ui/button';
import DnDKanban from '@/components/element/dnd/DnDKanban';
import { useLoyaltyCampaignSections } from '../../hooks/useLoyaltyCampaignSections';
import TaskDialog from '../task/TaskDialog';
import { useLoyaltyTasks } from '../../hooks/tasks/useLoyaltyTasks';
import { TaskStatus } from '@/features/campaigns/types/tasks.enums';
import { useLoyaltyTasksInitialise } from '../../hooks/tasks/useLoyaltyTasksInitialise';
import AddLoyaltyTask from '../add-task/AddLoyaltyTask';
import { useLoyaltyCampaignReorder } from '../../hooks/useLoyaltyCampaignReorder';
import { useQueryInitialise } from '@/hooks/useQueryInitialise';

const LoyaltyTaskSections = () => {
	const [selectedTask, setSelectedTask] = useState(null);
	const [showTemplates, setShowTemplates] = useState(false);
	const [showTask, setShowTask] = useState(false);

	const { setSelectedSectionId } = useLoyaltyTasks();
	const { editDraftTask } = useLoyaltyTasksInitialise();
	const { sections, tasks, showAddSection, setShowAddSection } =
		useLoyaltyCampaignSections({});
	const { reorderSections } = useLoyaltyCampaignReorder();

	useQueryInitialise('new', 'true', () => {
		setSelectedSectionId(sections[0]._id);
		setShowTemplates(true);
	});

	return (
		<div>
			<DnDKanban
				containers={sections}
				ContainerComponent={LoyaltySection}
				ItemComponent={LoyaltyTask}
				setContainers={(sections_, isDragEnd) => {
					if (!isDragEnd) return;
					reorderSections(sections_);
				}}
				addItem={(sectionId) => {
					setSelectedSectionId(sectionId);
					setShowTemplates(true);
				}}
				selectItem={(taskId, sectionId) => {
					setSelectedSectionId(sectionId);
					const task = tasks.find((task) => taskId?.includes(task._id));
					if (task.status === TaskStatus.Draft) {
						editDraftTask(task?._id);
					} else {
						setSelectedTask(task);
						setShowTask(true);
					}
				}}
				editItem={() => {}}
				editContainer={() => {}}
			/>
			<Button variant="outline" onClick={() => setShowAddSection(true)}>
				+ Add Section
			</Button>
			<AddLoyaltySection open={showAddSection} setOpen={setShowAddSection} />
			<AddLoyaltyTask
				showTemplates={showTemplates}
				setShowTemplates={setShowTemplates}
			/>
			<TaskDialog
				open={showTask}
				setOpen={setShowTask}
				task={selectedTask}
				setTask={setSelectedTask}
			/>
		</div>
	);
};

export default LoyaltyTaskSections;
