import { useCallback, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { useConfirm } from './useConfirm';
import { ConfirmOptions } from '@/types/confirm.type';

export const usePrompt = ({
	isDirty = false,
	title = 'You have unsaved changes!',
	subtitle = 'All unsaved changes will be lost. Are you sure you want to leave this page',
	confirmText = 'Stay',
	cancelText = 'Leave',
	type = 'warning',
}: ConfirmOptions & { isDirty?: boolean }) => {
	const blocker = useBlocker(isDirty);

	const { show } = useConfirm();

	const confirm = useCallback(async () => {
		if (!isDirty) return true;
		const res = await show({
			title,
			subtitle,
			confirmText,
			cancelText,
			type,
		});
		return !res;
	}, [cancelText, confirmText, isDirty, show, subtitle, title, type]);

	useEffect(() => {
		if (blocker.state === 'blocked') {
			confirm().then((result) => {
				if (result) blocker.proceed();
				else blocker.reset();
			});
		}
	}, [blocker, confirm]);

	useEffect(() => {
		if (isDirty) {
			window.onbeforeunload = () => subtitle;
		}

		return () => {
			window.onbeforeunload = null;
		};
	}, [isDirty, subtitle]);

	return {
		confirm,
	};
};
