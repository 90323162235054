import { useDiscordIntegration } from '../hooks/useDiscordIntegration';
import { DiscordDataImport } from '../components/discordIntegration/DiscordDataImport';

const DiscordIntegration = () => {
	const {
		isAuthenticating,
		isLoadingChannels,
		step,
		setStep,
		channels,
		isFetchingChannels,
		authenticateDiscord,
		handleFetching,
		loading,
		refetchChannel,
		integrationId,
	} = useDiscordIntegration();

	return (
		<div>
			<DiscordDataImport
				isLoadingChannels={isLoadingChannels}
				step={step}
				setStep={setStep}
				channels={channels}
				isFetchingChannels={isFetchingChannels}
				authenticateDiscord={authenticateDiscord}
				handleFetching={handleFetching}
				loading={loading}
				refetchChannel={refetchChannel}
				isAuthenticating={isAuthenticating}
				integrationId={integrationId}
			/>
		</div>
	);
};

export default DiscordIntegration;
