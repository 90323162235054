import { cn } from '@/lib/utils';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const CustomizeHubLayout = ({ children }: { children: React.ReactNode }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const selected = options.find(
		(option) => option.value === location.pathname.split('/').pop(),
	);

	return (
		<div>
			<div className="grid grid-cols-12 ">
				<div className="col-span-3 pt-6">
					{options.map((i) => (
						<div className="mb-3">
							<div
								className={cn(
									'px-4 pe-10 py-1 text-sm rounded-full cursor-pointer inline-flex ',
									selected?.value === i.value
										? 'bg-slate-100 border '
										: 'border-transparent',
								)}
								onClick={() => {
									navigate(`/app/customise-hub/${i.value}`);
								}}
							>
								<i
									className={`bi ${i.icon} text-muted-foreground me-2`}
								></i>
								{i.label}
							</div>
						</div>
					))}
				</div>
				<div className="col-span-9">
					<div className="flex items-center text-sm">
						<span className=" text-muted-foreground">
							Customize Hub /
						</span>
						<div className="ps-2 font-semibold">{selected?.label}</div>
					</div>
					<div className="mt-10">{children}</div>
				</div>
			</div>
		</div>
	);
};

export default CustomizeHubLayout;

const options = [
	{
		label: 'Project Profile ',
		value: 'profile',
		icon: 'bi-tencent-qq',
	},
	{
		label: 'Project Socials',
		value: 'socials',
		icon: 'bi-person-bounding-box',
	},
	{
		label: 'Imported Tokens',
		value: 'tracked-tokens',
		icon: 'bi-coin',
	},
	{
		label: 'Imported Contracts',
		value: 'tracked-contracts',
		icon: 'bi-code-square',
	},
];
