import {
	createContext,
	type ReactNode,
	useCallback,
	useMemo,
	useState,
} from 'react';
import { ConfirmContext, ConfirmOptions, Nullable } from '@/types/confirm.type';
import { ConfirmDialog } from '@/components/element/dialog/ConfirmDialog';

export const ConfirmCtx = createContext<Nullable<ConfirmContext>>(null);

interface Props {
	children: ReactNode;
}

let resolveCallback: (response: boolean) => void;

export function ConfirmProvider({ children }: Props) {
	const [confirm, setConfirm] = useState<Nullable<ConfirmOptions>>(null);

	const [open, toggle] = useState(false);

	const show = useCallback(
		(confirmOptions: Nullable<ConfirmOptions>): Promise<boolean> => {
			setConfirm(confirmOptions);
			toggle(true);
			return new Promise((res) => {
				resolveCallback = res;
			});
		},
		[toggle],
	);

	const onConfirm = () => {
		resolveCallback(true);
		toggle(false);
	};

	const onCancel = () => {
		resolveCallback(false);
		toggle(false);
	};

	const value = useMemo(() => ({ show }), [show]);

	return (
		<ConfirmCtx.Provider value={value}>
			<ConfirmDialog
				type="warning"
				{...confirm}
				onCancel={onCancel}
				onConfirm={onConfirm}
				open={open}
			/>
			{children}
		</ConfirmCtx.Provider>
	);
}
