/* eslint-disable no-mixed-spaces-and-tabs */
import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from '@/lib/utils';

interface SwitchProps
	extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
	size?: 'sm' | 'lg';
}

const Switch = React.forwardRef<
	React.ElementRef<typeof SwitchPrimitives.Root>,
	SwitchProps
>(({ className, size = 'lg', ...props }, ref) => {
	const sizeClasses =
		size === 'sm'
			? {
					root: 'h-4 w-7',
					thumb: 'h-3 w-3 translate-x-3',
			  }
			: {
					root: 'h-6 w-11',
					thumb: 'h-5 w-5 translate-x-5',
			  };

	return (
		<SwitchPrimitives.Root
			className={cn(
				`peer inline-flex ${sizeClasses.root} shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input`,
				className,
			)}
			{...props}
			ref={ref}
		>
			<SwitchPrimitives.Thumb
				className={cn(
					`pointer-events-none block ${sizeClasses.thumb} rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:${sizeClasses.thumb} data-[state=unchecked]:translate-x-0`,
				)}
			/>
		</SwitchPrimitives.Root>
	);
});

Switch.displayName = 'Switch';

export { Switch };
