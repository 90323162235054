import Sections from './EventCreateSection';
import Navigation from './EventCreateNavigation';
import FullScreenCreateLayout from '@/components/layout/full-screen-create/FullScreenCreateLayout';
import { Status } from '@/features/campaigns/types';
import { useNavigate } from 'react-router-dom';
import { useCreateEvent } from '@/features/events/hooks/create/useCreateEvent';

const EventCreateLayout = ({ children }: any) => {
	const navigate = useNavigate();
	const { details } = useCreateEvent();
	return (
		<FullScreenCreateLayout showGrid={false}>
			<FullScreenCreateLayout.Header>
				<FullScreenCreateLayout.Title
					name={details?.title}
					placeholder={'Untitled Events'}
					status={Status.Draft}
					lastSaved={new Date()}
					onFeedback={() => {
						window.open('https://forms.gle/SL6A1tr72ekPjvrY6', '_blank');
					}}
					onBack={() => navigate('/app/campaign/events')}
				/>
				<FullScreenCreateLayout.Sections>
					<Sections />
				</FullScreenCreateLayout.Sections>
			</FullScreenCreateLayout.Header>
			<FullScreenCreateLayout.Content>
				{children}
			</FullScreenCreateLayout.Content>
			<FullScreenCreateLayout.Navigation>
				<Navigation />
			</FullScreenCreateLayout.Navigation>
		</FullScreenCreateLayout>
	);
};

export default EventCreateLayout;
