import { v4 as uuidv4 } from 'uuid';
import { Fragment } from 'ethers/lib/utils';

export function extractFunctionParams(selectedFunction: any) {
	const processParams = (params: any, prefix = '', position: any = []) => {
		return params.flatMap((param: any, index: number) => {
			const paramName = prefix ? `${prefix}.${param.name}` : param.name;
			const currentPosition = [...position, index];
			if (param.type === 'tuple' && param.components) {
				return processParams(param.components, paramName, currentPosition);
			} else {
				if (param.type === 'address') {
					return [
						{
							name: paramName,
							type: param.type,
							position: currentPosition,
						},
					];
				} else {
					return [];
				}
			}
		});
	};

	const paramsInfo = processParams(selectedFunction.item.inputs);

	return {
		id: selectedFunction.id,
		params: paramsInfo.map((param: any) => ({ ...param, id: uuidv4() })),
	};
}

export function constructDetailedSignature(fragment: Fragment) {
	const signature = fragment.name;
	const params = fragment.inputs
		.map((input) => {
			if (input.type === 'tuple' || input.type === 'tuple[]') {
				const tupleString = input.components
					.map((component) => `${component.type} ${component.name}`)
					.join(', ');
				return `${input.type.includes('[]') ? 'tuple[]' : 'tuple'}(${tupleString}) ${input.name}`;
			} else {
				return `${input.type} ${input.name}`;
			}
		})
		.join(', ');
	return (
		<div className="text-sm truncate">
			{signature}
			<span className="text-xs text-muted-foreground">{`(${params})`}</span>
		</div>
	);
}
