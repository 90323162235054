import { ethers } from 'ethers';

export function getStringNumber(str: string) {
	if (!str) return 1;
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash += str.charCodeAt(i);
	}
	return (hash % 10) + 1;
}

export function toTitleCase(str: string) {
	if (!str) return str;
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}

export const convertCamelCaseToSentence = (str: string) => {
	if (!str) return '';
	return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
		return str.toUpperCase();
	});
};

export function deepCopy(obj) {
	return JSON.parse(JSON.stringify(obj));
}

export function sortByStatusOrder(array: any[], key: string, order: string[]) {
	return array.sort(function (a, b) {
		const x = a[key];
		const y = b[key];
		return order.indexOf(x) - order.indexOf(y);
	});
}

export function cleanDecimal(input: string) {
	const x = parseFloat(input).toFixed(2);
	return x;
}

export function getDiscordRoleColor(i: unknown) {
	if (typeof i === 'number') {
		// if (discordColorMap[i]) {
		// 	return discordColorMap[i];
		// }
		const BGR = ('000000' + i.toString(16)).slice(-6);
		const RGB = BGR.substr(4, 2) + BGR.substr(2, 2) + BGR.substr(0, 2);
		return '#' + RGB;
	} else {
		return '#000';
	}
}

export const getSentenceCase = (str: string) => {
	try {
		if (!str || str === '') return '';
		if (str === 'SDK') return 'SDK';
		console.log('str', str);
		return str?.[0]?.toUpperCase() + str?.slice(1)?.toLowerCase();
	} catch (err) {
		console.log('Error in getSentenceCase:', err, str);
		return '';
	}
};

export function removeEmptyStrings(obj: any) {
	Object.keys(obj).forEach((key) => {
		if (!obj[key] && typeof obj[key] === 'object') {
			removeEmptyStrings(obj[key]);
			if (Object.keys(obj[key]).length === 0) {
				delete obj[key];
			}
		} else if (obj[key] === '') {
			delete obj[key];
		}
	});
	return obj;
}

export const hexToRGBA = (hex: string, alpha: number) => {
	if (!hex || typeof hex !== 'string' || hex === '') return 'rgba(0,0,0,0)';
	const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
	return `rgba(${r},${g},${b},${alpha})`;
};

export function getChainSymbol(chainId: number) {
	switch (chainId) {
		case 1:
			return 'ETH';
		case 56:
			return 'BNB';
		case 137:
			return 'MATIC';
		case 43114:
			return 'AVAX';
		case 42161:
			return 'ARB';
		case 80001:
			return 'MATIC';
		default:
			return '';
	}
}

export const clickOutside = () => {
	const ele = document.querySelector('#click-outside') as HTMLElement;
	if (ele) {
		ele?.click();
	}
};

export const parseJSON = (str: string) => {
	try {
		return JSON.parse(str);
	} catch (e) {
		return null;
	}
};

export const formatBalance = (balance: number | string) => {
	if (!balance) return 0;
	if (balance === 0 || Number(balance) === 0) return 0;
	if (typeof balance === 'string') balance = parseFloat(balance);
	if (balance < 0.0001) return balance.toFixed(6);
	if (balance < 0.01) return balance.toFixed(4);
	if (balance < 1) return balance.toFixed(2);
	else {
		const numberOfDecimals =
			balance.toString()?.split('.').length > 1
				? balance.toString()?.split('.')[1]?.length
				: 0;
		if (numberOfDecimals > 3) return balance.toFixed(3);
		else return balance.toFixed(0);
	}
};

export const getMinAndMax = (arr: number[]) => {
	return [Math.min(...arr), Math.max(...arr)];
};

export const getFileType = (filename: string): string => {
	const imagePattern = /\.(jpg|jpeg|png|bmp|tiff|svg|webp)$/i;
	const gifPattern = /\.gif$/i;
	const videoPattern = /\.(mp4|mkv|mov|avi|flv|wmv)$/i;

	// Check for edge cases
	if (!filename || typeof filename !== 'string' || filename.trim().length === 0) {
		return 'unknown';
	}

	// Extract the file extension
	const trimmedFilename = filename.trim();
	if (gifPattern.test(trimmedFilename)) {
		return 'gif';
	} else if (imagePattern.test(trimmedFilename)) {
		return 'image';
	} else if (videoPattern.test(trimmedFilename)) {
		return 'video';
	} else {
		return 'unknown';
	}
};

export function extractHashtags(inputString: string): string[] {
	const hashtags = [];
	const items = inputString.split(',');
	items.forEach((item) => {
		const itemHashtags = item.match(/#\w+/g);
		if (itemHashtags) {
			hashtags.push(...itemHashtags.map((i) => i.split('#')[1]));
		}
	});
	return hashtags;
}

export function extractMentions(inputString: string): string[] {
	const mentions = [];
	const items = inputString.split(',');
	items.forEach((item) => {
		const itemMentions = item.match(/@\w+/g);
		if (itemMentions) {
			mentions.push(...itemMentions.map((i) => i.split('@')[1]));
		}
	});
	return mentions;
}

export function getAvatarInitials(name: string) {
	if (!name || typeof name !== 'string') return '';

	const slicedArray = name
		.split(' ')
		.map((value) => value.trim())
		.slice(0, 2);

	return slicedArray.map((value) => value.charAt(0).toUpperCase()).join('');
}

export function formatCompactNumber(number) {
	if (isNaN(number)) return 0;
	const formatter = Intl.NumberFormat('en', { notation: 'compact' });
	return formatter.format(number);
}

export function convertToSlug(text: string) {
	return text
		.toLowerCase()
		.replace(/ /g, '-')
		.replace(/[^\w-]+/g, '');
}

export function formatNumberWithCommas(x: number) {
	if (!x) return 0;
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function truncateString(str: string, num: number) {
	if (!str) return '';
	if (str.length <= num) {
		return str;
	}
	return str.slice(0, num) + '...';
}

export function formatDate(inputDate: string) {
	const date = new Date(inputDate);
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const day = date.getDate();
	const month = months[date.getMonth()];
	const year = date.getFullYear().toString().slice(-2);

	let suffix;
	if (day >= 11 && day <= 13) {
		suffix = 'th';
	} else {
		switch (day % 10) {
			case 1:
				suffix = 'st';
				break;
			case 2:
				suffix = 'nd';
				break;
			case 3:
				suffix = 'rd';
				break;
			default:
				suffix = 'th';
		}
	}

	return `${day}${suffix} ${month} ${year}`;
}
export const convertToTitleCase = (str: string) => {
	if (!str) return '';
	return str
		.split('_')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ');
};

export const formatNumberWithThSt = (n: number) => {
	const s = ['th', 'st', 'nd', 'rd'];
	const v = n % 100;
	return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export function parseJsonFromEthers(data: any) {
	try {
		return JSON.parse(
			JSON.stringify(
				data,
				(key, value) =>
					typeof value === 'bigint' ? value.toString() : value, // return everything else unchanged
			),
		);
	} catch (e) {
		return null;
	}
}

export const formatUserData = (i: {
	discordUsername: string;
	discordAvatar: string;
	twitterUsername: string;
	twitterAvatar: string;
	userAddresses: string[];
	discordId: string;
}) => {
	let username = '';
	let avatar = '';
	if (i?.discordUsername) username = i.discordUsername;
	else if (i?.twitterUsername) username = i.twitterUsername;
	else if (i.userAddresses.length) username = i.userAddresses[0];

	if (i?.discordAvatar)
		avatar = `https://cdn.discordapp.com/avatars/${i.discordId}/${i.discordAvatar}.png`;
	else if (i?.twitterAvatar) avatar = i.twitterAvatar;

	return {
		username: username,
		avatar: avatar,
	};
};

export const validateCompleteURL = (url: string) => {
	try {
		if (!url) return '';

		url = url.trim();

		// const protocolRegex = /(https?:\/\/)/gi;
		const protocolRegex = /(https?:?\/?\/?)/gi;

		const matches = url.match(protocolRegex);
		if (matches && matches.length > 1) {
			const lastProtocolIndex = url.lastIndexOf('https://');
			url = url.substring(lastProtocolIndex);
		}

		url = url.replace(protocolRegex, '');

		url = url.replace(/^www\./gi, '');
		url = url.replace(/@/g, '');

		url = 'https://' + url;

		const urlObj = new URL(url);

		if (urlObj.pathname === '/' && !urlObj.search && !urlObj.hash) {
			url = url.replace(/\/$/, '');
		} else if (urlObj.pathname !== '/') {
			url = url.replace(/\/$/, '');
		}

		return url;
	} catch (err) {
		console.log('Error correcting URL:', err);
		return url; // If URL construction fails, return the last best effort URL
	}
};

export const stripURLs = (urls: {
	website?: string;
	twitter?: string;
	discord?: string;
}) => {
	const newUrls = { ...urls };

	Object.keys(newUrls).forEach((key) => {
		let url = newUrls[key];

		switch (key) {
			case 'twitter':
				// Strip off 'https://twitter.com/' or 'https://www.twitter.com/' and similar variations
				url = url.substring(url.lastIndexOf('/') + 1);
				url = url.replace(/^@/, '');
				break;
			case 'discord':
			case 'website':
				// Remove the protocol part, keep starting from 'discord.gg/'
				url = url.replace(/https?:\/\//gi, '');
				break;
			default:
				url = url.replace(/https?:\/\//gi, '');
				url = url.substring(url.lastIndexOf('/') + 1);
				url = url.replace(/^@/, '');
				break;
		}

		// Remove any trailing slash for uniformity
		url = url.replace(/\/$/, '');

		newUrls[key] = url;
	});

	return newUrls;
};

export const isValidEndpoint = (endpoint: string) => {
	const regex = /^https:\/\/[^\s/$.?#].[^\s]*$/;
	return regex.test(endpoint);
};

export function isTimeGreater(time1: string, time2: string): boolean {
	// Helper function to convert time string to a Date object
	const parseTime = (time: string): Date => {
		const [timePart, period] = time.split(' ');
		const [hours, minutes] = timePart.split(':').map(Number);
		const date = new Date();
		date.setHours(
			(hours % 12) + (period.toLowerCase() === 'pm' ? 12 : 0),
			minutes,
			0,
		);
		return date;
	};

	// Convert both times to Date objects
	const dateTime1 = parseTime(time1);
	const dateTime2 = parseTime(time2);

	// Compare the two Date objects
	return dateTime1 > dateTime2;
}

export const formatObjectID = (_id: string): any => {
	try {
		return ethers.BigNumber.from('0x' + _id.toString());
	} catch (err) {
		return null;
	}
};
