import StatusBadge from '@/components/element/badges/StatusBadge';
import { Media } from '@/components/element/media/Media';
import { Badge } from '@/components/ui/badge';
import {
	CommandDialog,
	CommandEmpty,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '@/components/ui/command';
import { useCampaigns } from '@/features/campaigns/hooks/report/useCampaigns';
import { Status } from '@/features/campaigns/types';
import { useMemo } from 'react';

export function SearchCampaigns({
	open,
	setOpen,
	onSelect,
	removeCampaigns,
}: {
	setOpen: any;
	open: any;
	onSelect?: any;
	removeCampaigns: string[];
}) {
	const { campaigns, isLoading } = useCampaigns();

	const filteredCampaigns = useMemo(() => {
		if (!campaigns) return [];
		return campaigns.filter((i) => {
			const isRemoved = removeCampaigns?.length
				? !removeCampaigns?.includes(i._id)
				: true;
			const isNotCompleted = i.status !== Status.Completed;
			return isRemoved && isNotCompleted;
		});
	}, [campaigns, removeCampaigns]);
	return (
		<CommandDialog open={open} onOpenChange={setOpen}>
			<CommandInput placeholder="Search from your Campaigns" />
			<CommandList className="text-xs">
				<CommandEmpty>No results found.</CommandEmpty>
				{isLoading && <CommandEmpty>Loading...</CommandEmpty>}
				{filteredCampaigns?.map((i) => (
					<>
						<CommandItem
							onSelect={() => {
								onSelect(i._id);
								setOpen(false);
							}}
							className=""
						>
							<div className="flex justify-between items-center w-full">
								<div className="flex items-center">
									<Media
										src={i.banner}
										className="h-6 w-10 rounded me-3"
									/>
									<div className="font-medium">{i.name}</div>
								</div>
								<div>
									<Badge variant="secondary" className="text-xs">
										<StatusBadge status={i.status} />
									</Badge>
								</div>
							</div>
						</CommandItem>
						<CommandSeparator />
					</>
				))}
			</CommandList>
		</CommandDialog>
	);
}
