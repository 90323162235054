import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { useNFTContract } from '../hooks/useNFTContract';
import DropzoneWithReposition from '@/components/element/dropzone/DropzoneWithReposition';
import { SelectNetwork2 } from '@/components/element/inputs/SelectNetworks2';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';

export function DeployContractDialog({ open, setOpen }) {
	const { nftChains } = useGetSupportedChains({
		showAdditionalChains: false,
	});
	const {
		formFields,
		formErrors,
		setFormFields,
		isTxnLoading,
		loading,
		handleSubmit,
		resetForm,
	} = useNFTContract({ open, setOpen });

	const handleStartOver = () => {
		resetForm();
		setOpen(false);
	};

	if (!open) return;

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[47rem]">
				<DialogHeader className="mb-5">
					<DialogTitle>Deploy a new NFT</DialogTitle>
					<DialogDescription>
						Deploy a new NFT contract on the blockchain and reward your
						users
					</DialogDescription>
				</DialogHeader>
				<div className="grid grid-cols-12 gap-8">
					<div className="col-span-6 space-y-10">
						<InputText
							label="NFT Name"
							subLabel="Name for your NFT Contract that will be visible on blockchain"
							subLabelClassName="pt-1.5"
							placeholder="Eg, 2nd Anniversary NFT"
							required
							className="mt-2"
							error={formErrors['name']}
							errorText={formErrors['name']}
							value={formFields?.name}
							setValue={(e) =>
								setFormFields((prev: any) => ({
									...prev,
									name: e,
								}))
							}
						/>
						<InputText
							label="NFT Symbol"
							subLabel="Token ticket for NFT contract that will be visible on blockchain"
							subLabelClassName="pt-1.5"
							placeholder="INTRACT"
							required
							className="mt-2"
							error={formErrors['nftSymbol']}
							errorText={formErrors['nftSymbol']}
							value={formFields?.nftSymbol}
							setValue={(e) =>
								setFormFields((prev: any) => ({
									...prev,
									nftSymbol: e,
								}))
							}
						/>
						<div className="">
							<Label htmlFor="name">
								Chain
								<span className="text-red-400 ms-1">*</span>
							</Label>
							<div className="mt-1 w-full">
								<SelectNetwork2
									value={formFields?.chainId}
									setValue={(e) =>
										setFormFields((prev: any) => ({
											...prev,
											chainId: e,
										}))
									}
									error={formErrors['chainId']}
									errorMsg={formErrors['chainId']}
									allowedChainIds={
										nftChains?.map((chain) =>
											Number(chain.chainId),
										) ?? []
									}
								/>
							</div>
						</div>
					</div>
					<div className="col-span-6 mt-2">
						<DropzoneWithReposition
							file={formFields?.image}
							required
							dropzoneClass="bg-muted"
							clearSelection={() => {
								setFormFields((prev: any) => ({
									...prev,
									image: null,
									imageUrl: '',
								}));
							}}
							setFile={(file: File, fileLink: string) => {
								setFormFields((prev: any) => ({
									...prev,
									image: file,
									imageUrl: fileLink,
								}));
							}}
							fileLink={formFields?.imageUrl}
							description="Recommended size: Under 2MB"
							label="Upload image"
							cta="Upload NFT"
							aspectRatio={1 / 1}
							className="w-full"
							customHeight="23.313rem"
							error={formErrors['image']}
							errorText={formErrors['image']}
							onImageReposition={({ x, y }) => {
								setFormFields((prev: any) => ({
									...prev,
									imgPosition: { x, y },
								}));
							}}
							lockBodyScroll={false}
						/>
					</div>
				</div>

				<DialogFooter>
					<Button variant="outline" onClick={() => handleStartOver()}>
						Cancel
					</Button>
					<Button
						type="submit"
						onClick={handleSubmit}
						disabled={loading || isTxnLoading}
					>
						{loading || isTxnLoading ? (
							<i className="bi bi-arrow-clockwise animate-spin mr-1 text-sm"></i>
						) : null}
						Deploy Contract
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
