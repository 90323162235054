import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { useState } from 'react';
import UnderstandingTxn from './UnderstandingTxn';
import ActionExamples from './ActionExamples';
import { TransactionHashGuide } from './TransactionHashGuide';

const CriteriaGuide = ({
	defaultGuideType,
}: {
	defaultGuideType?: 'examples' | 'txn';
}) => {
	const [guideType, setGuideType] = useState<'examples' | 'txn' | null>(
		defaultGuideType ? defaultGuideType : null,
	);

	return (
		<div>
			<div className="flex justify-between items-center border-b pb-3">
				<div className="font-medium text-lg ">
					{!guideType
						? 'Learn how to create criterias '
						: guideType === 'txn'
							? 'Import from Txn Hash'
							: 'Learn from a Swap Txn example'}
				</div>
				{guideType && (
					<Button variant="link" onClick={() => setGuideType(null)}>
						Back
					</Button>
				)}
			</div>
			{!guideType && <GuideOptions setGuideType={setGuideType} />}
			{guideType === 'txn' && <TransactionHashGuide />}
			{guideType === 'examples' && <ActionExamples />}
		</div>
	);
};

export default CriteriaGuide;

const GuideOptions = ({ setGuideType }) => {
	return (
		<div className="mt-5">
			<iframe
				width="100%"
				height="215"
				src="https://www.youtube.com/embed/hbxQw4LQwws"
				title="YouTube video player"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				referrerPolicy="strict-origin-when-cross-origin"
				className="rounded-2xl "
			></iframe>

			<Separator className="my-7" title="OR" />
			<div className="grid grid-cols-2 space-x-4 ">
				<div
					className="flex flex-col items-center cursor-pointer justify-center p-4  rounded-lg border bg-slate-100 hover:bg-slate-200"
					onClick={() => setGuideType('txn')}
				>
					<i className="bi bi-code-slash text-4xl mb-2 text-blue-500"></i>
					<div className="text-sm font-medium">
						Test with Actual Sample Txn
					</div>
					<div className="text-xs text-center mt-1">
						Add your custom payload to test the API response
					</div>
				</div>
				<div
					className="flex flex-col items-center cursor-pointer justify-center p-4  rounded-lg border bg-slate-100 hover:bg-slate-200"
					onClick={() => setGuideType('examples')}
				>
					<i className="bi bi-collection text-4xl mb-2 text-blue-500"></i>
					<div className="text-sm font-medium">
						Learn from a Swap Aciton example
					</div>
					<div className="text-xs text-center mt-1">
						Let us send dummy data to test the API response
					</div>
				</div>
			</div>
			<Separator className="my-7" title="OR" />
			<UnderstandingTxn />
		</div>
	);
};
