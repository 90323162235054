import { Button } from '@/components/ui/button';
import { useCreateActionProvider } from '../../../hooks/actions/useCreateActionProvider';
import { DaveActionCreateSectionEnum } from '../../../types/dave.enum';
import { useCreateActionNavigation } from '@/features/dave/hooks/actions/useCreateActionNavigation';
import { ReactNode } from 'react';
import PostActionCreation from '../../dialogs/PostActionCreationDialog';

const DaveActionCreateNavigation = () => {
	const { section, contextualSuggestions } = useCreateActionProvider();
	const {
		isLoading,
		forwardLabel,
		moveForward,
		moveBack,
		showSuccess,
		actionId,
		setShowSuccess,
	} = useCreateActionNavigation();

	return (
		<div className="flex justify-between items-center space-x-4 border-t p-3 pb-3 px-4">
			<div className="min-w-[200px]">
				{section !== DaveActionCreateSectionEnum.Details && (
					<Button
						className=" px-10"
						variant="secondary"
						onClick={() => moveBack()}
					>
						<i className="bi bi-arrow-left me-2"></i>
						Back
					</Button>
				)}
			</div>
			<div>
				{contextualSuggestions.id && (
					<div
						className={`text-xs  repeat-[3] animate-wiggle`}
						key={contextualSuggestions.id}
					>
						{contextualSuggestions.element as ReactNode}
					</div>
				)}
			</div>
			<Button className=" px-10" onClick={() => moveForward()}>
				{isLoading && (
					<i className="bi bi-arrow-clockwise me-2 animate-spin"></i>
				)}
				{forwardLabel}
				<i className="bi bi-arrow-right ms-2"></i>
			</Button>
			<PostActionCreation
				open={showSuccess}
				setOpen={setShowSuccess}
				actionId={actionId}
			/>
		</div>
	);
};

export default DaveActionCreateNavigation;
