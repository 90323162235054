import { useMemo } from 'react';
import moment from 'moment';
import CampaignMetricsCard from '@/features/events/components/events-report/components/analytics/EventMetricsCard';
import { useLoyaltyCampaign } from '../../hooks/useLoyaltyCampaign';

export const LoyaltyCampaignMetrics = () => {
	const { campaign } = useLoyaltyCampaign();

	const metrics = useMemo(() => {
		if (!campaign) return [];
		const totalViewsToDate = campaign?.timeSeriesMetrics?.pageViewUsers?.find(
			(i) => {
				return (
					moment(new Date(i.timestamp)).format('YYYY-MM-DD') ===
					moment(new Date()).format('YYYY-MM-DD')
				);
			},
		)?.count;
		return [
			{
				title: 'Page Views',
				icon: 'rocket',
				value: campaign?.pageViewUsersCount,
				subtitle: totalViewsToDate
					? `Total views today: ${totalViewsToDate}`
					: '',
			},
			{
				title: 'Initiations',
				icon: 'rocket',
				value: campaign?.initiatedUsersCount,
				subtitle:
					campaign.initiatedUsersCount && campaign.pageViewUsersCount
						? `Bounce rate rate is ${Number(100 * ((campaign.pageViewUsersCount - campaign.initiatedUsersCount) / campaign.pageViewUsersCount)).toFixed(1)}%`
						: 'Bounce rate is 0.0%',
			},
			// {
			// 	title: 'Completions',
			// 	icon: 'rocket',
			// 	value: campaign?.completedUsersCount,
			// 	subtitle: `Completion rate is ${Number(100 * (campaign.completedUsersCount / campaign.initiatedUsersCount)).toFixed(2)}%`,
			// },
		];
	}, [campaign]);

	return (
		<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mt-7">
			{metrics.map((i) => (
				<CampaignMetricsCard {...i} key={i.title} />
			))}
		</div>
	);
};
