import InputTextArea from '@/components/element/inputs/InputTextArea';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { useCreatePartnershipRequest } from '../hooks/useCreatePartnershipRequest';
import Spinner from '@/components/element/loading/Spinner';
import InputSelect2 from '@/components/element/inputs/InputSelect2';

const MessageProjectDialog = ({
	open,
	setOpen,
	project,
}: {
	open: boolean;
	setOpen: (value: boolean) => void;
	project: any;
}) => {
	const { formFields, setFormFields, isLoading, errors, sendRequest } =
		useCreatePartnershipRequest(project, setOpen);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[565px] p-0">
				<DialogHeader className="flex flex-row items-end justify-between pt-4 px-8 pb-5">
					<div>
						<p>
							<Badge
								className="rounded-full px-3 mb-4"
								variant="outline"
							>
								<i className="bi-stars me-1 text-yellow-600"></i>
								Build your Hype Tribe
							</Badge>
						</p>
						<div className="ms-2">
							Partner with
							<DialogTitle className="text-3xl font-medium">
								{project.name}
							</DialogTitle>
						</div>
					</div>
					<div className="h-32 min-w-32 border rounded-full flex items-center justify-between p-3 relative">
						<div className="h-[7.3rem] w-[7.3rem] border rounded-full flex items-center justify-between p-3 absolute z-0 bg-slate-100  top-0 start-0 bottom-0 end-0 mx-auto my-auto"></div>
						<img
							src={project.logo}
							className="h-full w-full rounded-full relative z-3"
						/>
					</div>
				</DialogHeader>
				<div className="min-h-[200px] bg-slate-100 px-8 pb-8">
					<InputTextArea
						value={formFields.message}
						setValue={(value) =>
							setFormFields((prev) => ({ ...prev, message: value }))
						}
						placeholder="Type your message here"
						className="mt-10"
						error={errors.message}
						errorText="Message is required"
						label="Your Message"
						inputClassName="min-h-[200px]"
					/>
					<InputSelect2
						value={formFields.category}
						setValue={(value) =>
							setFormFields((prev) => ({
								...prev,
								category: value,
							}))
						}
						placeholder="Select a category"
						error={false}
						className="mt-10"
						label="Category"
						options={categoryOptions}
					/>
					<div className="mt-5 flex justify-end">
						<Button disabled={isLoading} onClick={sendRequest}>
							{isLoading && <Spinner className="me-2" />}
							<span>Send Message</span>
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default MessageProjectDialog;

const categoryOptions = [
	{
		label: 'Tweet',
		value: 'tweet',
	},
	{
		label: 'Retweet',
		value: 'retweet',
	},
	{
		label: 'Quote Tweet',
		value: 'quote-tweet',
	},
	{
		label: 'Thread',
		value: 'thread',
	},
	{
		label: 'Youtube Video',
		value: 'youtube-video',
	},
	{
		label: 'Newsletter',
		value: 'newsletter',
	},
	{
		label: 'Medium Blog',
		value: 'medium-blog',
	},
	{
		label: 'Streaming',
		value: 'streaming',
	},
	{
		label: 'Debank Marketing',
		value: 'debank-marketing',
	},
];
