import { useState } from 'react';
import DaveCreateLayout from '../components/create-actions/layout/DaveCreateLayout';
import ActionDetails from '../components/create-actions/ActionDetails';
import ActionCriterias from '../components/create-actions/ActionCriterias';
import { DaveActionCreateProvider } from '../providers/DaveActionCreateProvider';
import { DaveActionCreateSectionEnum } from '../types/dave.enum';

const DaveActionCreatePage = () => {
	const [section, setSection] = useState<DaveActionCreateSectionEnum>(
		DaveActionCreateSectionEnum.Details,
	);

	return (
		<DaveActionCreateProvider section={section} setSection={setSection}>
			<DaveCreateLayout>
				{section === DaveActionCreateSectionEnum.Details && (
					<div>
						<div className="flex justify-center items-center pt-10">
							<div className="mb-5 w-[600px] relative">
								{section === DaveActionCreateSectionEnum.Details && (
									<ActionDetails />
								)}
							</div>
						</div>
					</div>
				)}
				{section === DaveActionCreateSectionEnum.Intractions && (
					<ActionCriterias />
				)}
			</DaveCreateLayout>
		</DaveActionCreateProvider>
	);
};

export default DaveActionCreatePage;
