import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
	fetchAction,
	fetchActionTable,
	fetchActionTableCount,
} from '../../services/dave.service';

export const useGetAction = () => {
	const { id } = useParams();
	const [search, setSearch] = useState('');
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});
	const [sorting, setSorting] = useState([]);

	const { data, isLoading } = useQuery({
		queryKey: ['dave-actions', id],
		queryFn: () => fetchAction(id),
		enabled: !!id,
	});

	const { data: table, isLoading: isTableLoading } = useQuery({
		queryKey: [
			'dave-action',
			id,
			{
				...pagination,
				sorting,
				search,
			},
		],
		queryFn: () =>
			fetchActionTable(id, {
				page: Number(pagination.pageIndex + 1) ?? 1,
				pageSize: Number(pagination.pageSize) ?? 10,
				sort: sorting[0]?.id
					? `${sorting[0]?.id}.${sorting[0]?.desc ? 'desc' : 'asc'}`
					: null,
				search,
			}),
	});

	const { data: tableCount, isLoading: isTableCountLoading } = useQuery({
		queryKey: [
			'dave-action-count',
			id,
			{
				search,
			},
		],
		queryFn: () => fetchActionTableCount(id, search),
	});

	return {
		search,
		setSearch,
		action: data,
		isLoading,
		table,
		isTableLoading,
		tableCount,
		isTableCountLoading,
		pagination,
		setPagination,
		sorting,
		setSorting,
	};
};
