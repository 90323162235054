import { LoyaltyCampaignMetrics } from './LoyaltyCampaignMetrics';
import LoyaltyInitiationsTimeSeries from './LoyaltyInitiationsTimeSeries';
import LoyaltyTaskAnalyticsTable from './LoyaltyTaskAnalyticsTable';
import LoyaltyUsersData from './LoyaltyUsersData';

const LoyaltyAnalytics = () => {
	return (
		<div className="space-y-10">
			<LoyaltyCampaignMetrics />
			<LoyaltyTaskAnalyticsTable />
			<LoyaltyInitiationsTimeSeries />
			{/* <LoyaltyUsersData /> */}
		</div>
	);
};

export default LoyaltyAnalytics;
