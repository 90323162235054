import { homepageContent } from '../../content/homepage.content';
import LandingContainer from '../ui/LandingContainer';

const ValueProps = () => {
	const content = homepageContent.valueProps;
	return (
		<LandingContainer>
			<div className="grid gap-12 md:grid-cols-3 lg:gap-10">
				{content.map((i) => (
					<PropCard {...i} />
				))}
			</div>
		</LandingContainer>
	);
};

export default ValueProps;

const PropCard = ({ title, description, image }) => {
	return (
		<div className="col-span-1">
			<div className="h-full flex flex-col gap-2 items-center justify-between bg-zinc-100 rounded-3xl overflow-hidden">
				<div className="supahub-card-md !pb-4 flex flex-col gap-2">
					<h3 className="font-medium">{title}</h3>
					<p className="text-sm text-zinc-800">{description}</p>
				</div>
				<picture className="w-full max-w-lg">
					<img
						src={image}
						alt="Set boards privacy"
						className="w-full aspect-threebytwo object-center object-contain"
						loading="lazy"
						width={1200}
						height={800}
						decoding="async"
					/>
				</picture>
			</div>
		</div>
	);
};
