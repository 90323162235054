import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { roleOptions } from '../../types/team.content';
import useManageTeam from '../../hooks/useManageTeam';
import { useEffect } from 'react';

const AddTeamMembers = ({ open, setOpen }: { open: boolean; setOpen: any }) => {
	const {
		setTeamData,
		teamData,
		formErrors,
		setFormErrors,
		handleAddTeamMember,
		resetForm,
		loading,
	} = useManageTeam({
		setOpen,
	});

	useEffect(() => {
		setFormErrors({});
	}, [teamData, setFormErrors]);
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[450px]">
				<DialogHeader className="">
					<DialogTitle>Invite team member</DialogTitle>
					<DialogDescription>
						We'll send them an invitation to join your team
					</DialogDescription>
					<div className="py-6 space-y-6">
						<div className="flex gap-4 w-full mb-5">
							<InputText
								label="First Name"
								placeholder="Enter first name"
								required
								className="w-1/2"
								value={teamData?.fname}
								setValue={(e) =>
									setTeamData((prev: any) => ({
										...prev,
										fname: e,
									}))
								}
								error={formErrors['fname']}
								errorText={formErrors['fname']}
							/>
							<InputText
								label="Last Name"
								placeholder="Enter last name"
								className="w-1/2"
								value={teamData?.lname}
								setValue={(e) =>
									setTeamData((prev: any) => ({
										...prev,
										lname: e,
									}))
								}
								error={formErrors['lname']}
								errorText={formErrors['lname']}
							/>
						</div>
						<InputText
							label="Email"
							placeholder="Enter email"
							required
							className="w-full "
							value={teamData?.email}
							setValue={(e) =>
								setTeamData((prev: any) => ({
									...prev,
									email: e,
								}))
							}
							error={formErrors['email']}
							errorText={formErrors['email']}
						/>
						<div className="flex flex-col gap-2">
							<Label>
								Assign Role
								<span className="text-red-400 ms-1">*</span>
							</Label>
							<Select
								value={teamData?.role}
								onValueChange={(newVal) =>
									setTeamData((prev) => ({
										...prev,
										role: newVal,
									}))
								}
							>
								<SelectTrigger
									className={`w-full bg-white ${
										formErrors['role']
											? 'border-destructive'
											: ''
									} `}
								>
									<SelectValue
										className="text-muted-foreground text-sm"
										placeholder={'Select role'}
									/>
								</SelectTrigger>
								<SelectContent>
									{roleOptions.map((role) => (
										<SelectItem
											key={role.value}
											value={role.value}
										>
											{role.label}
										</SelectItem>
									))}
								</SelectContent>
							</Select>
							{formErrors['role'] ? (
								<p className="text-xs  text-destructive">
									{formErrors['role']}
								</p>
							) : null}
						</div>
					</div>
					<DialogFooter>
						<Button variant="outline" onClick={resetForm}>
							Cancel
						</Button>
						<Button
							type="submit"
							disabled={loading}
							onClick={handleAddTeamMember}
						>
							{loading ? (
								<i className="bi bi-arrow-clockwise animate-spin mr-1 text-sm"></i>
							) : null}
							Add Member
						</Button>
					</DialogFooter>
				</DialogHeader>
			</DialogContent>
		</Dialog>
	);
};

export default AddTeamMembers;
