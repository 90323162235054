import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { ICampaign } from '../../types';
import { Label } from '@/components/ui/label';
import { SelectTimeStamp } from '@/components/element/inputs/input-duration/SelectTimeStamp';
import { useState } from 'react';
import Spinner from '@/components/element/loading/Spinner';
import { restartCampaign } from '../../services/campaigns.service';
import { toast } from 'sonner';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';

const RestartCampaign = ({
	open,
	setOpen,
	campaign,
}: {
	open: boolean;
	setOpen: (value: boolean) => void;
	campaign: ICampaign;
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [endDate, setEndDate] = useState<Date>(new Date());
	const [error, setError] = useState(false);

	const updateMetadata = async () => {
		try {
			if (
				!endDate ||
				endDate < new Date() ||
				endDate < new Date(campaign.endDate)
			) {
				setError(true);
				return;
			}
			setIsLoading(true);
			await restartCampaign(campaign._id, { endDate });
			toast.success('Campaign restarted successfully');
			await queryClient.invalidateQueries({
				queryKey: ['campaign', campaign._id],
			});
			await queryClient.invalidateQueries({
				queryKey: ['campaigns'],
			});
			setIsLoading(false);
			setOpen(false);
		} catch (e) {
			handleErrorMessage(e);
			setIsLoading(false);
		}
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[585px] p-0 overflow-visible">
				<div className="p-6">
					<DialogHeader className="mb-8">
						<DialogTitle className="text-xl font-medium">
							Restart Campaign {campaign?.name}
						</DialogTitle>
						<div className="text-sm text-muted-foreground mt-2">
							You can restart the campaign by extending the end date.
						</div>
					</DialogHeader>
					<div className="space-y-8">
						<div>
							<Label className="mb-2 block">
								New Campaign End Date
							</Label>
							<div className="text-xs text-muted-foreground mb-4">
								Please select the new end date for the campaign.
							</div>
							<SelectTimeStamp
								isStart={false}
								isContinous={false}
								setIsContinous={() => {}}
								value={endDate}
								setValue={(e) => {
									setError(false);
									setEndDate(e);
								}}
								fromDate={
									new Date() > campaign.endDate
										? new Date()
										: campaign.endDate
								}
								timezone={campaign.endDateTz}
								minTimestamp={''}
							/>
							{error && (
								<div className="text-red-500 text-sm mt-1">
									Please enter a valid end date
								</div>
							)}
						</div>
					</div>
				</div>
				<DialogFooter className="w-full space-x-2 border-t py-3 px-9">
					<Button onClick={() => updateMetadata()} disabled={isLoading}>
						{isLoading && <Spinner className="me-2" />}
						<span>Restart Campaign</span>
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default RestartCampaign;
