import { useEffect, useState } from 'react';

const useHtmlElementClassObserver = (element: HTMLElement) => {
	const [classNames, setClassNames] = useState(Array.from(element.classList));

	useEffect(() => {
		const observer = new MutationObserver((mutationsList) => {
			mutationsList.forEach((mutation) => {
				if (
					mutation.type === 'attributes' &&
					mutation.attributeName === 'class'
				) {
					setClassNames(Array.from(element.classList));
				}
			});
		});

		observer.observe(element, { attributes: true });

		return () => {
			observer.disconnect();
		};
	}, [element]);

	return classNames;
};

export default useHtmlElementClassObserver;
