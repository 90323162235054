import { useQuery } from '@tanstack/react-query';
import { getQuickAccess } from '../services/home.service';
import { useLocalStorage } from '@/hooks/useLocalStorage';

interface IResource {
	_id: string;
	name: string;
	banner: string;
	type: string;
}

export const useQuickAccess = () => {
	const [recentCampaigns] = useLocalStorage('recent-campaigns', '');
	const [recentEvents] = useLocalStorage('recent-events', '');

	const { data: quickAccess, isLoading } = useQuery<{
		activeResources: IResource[];
		recentResources: IResource[];
	}>({
		queryKey: ['quick-access'],
		queryFn: () =>
			getQuickAccess({
				recentlyVisitedIds: {
					campaignIds: recentCampaigns
						?.split(',')
						.map((id) => id.trim())
						.filter(Boolean),
					narrativeIds: recentEvents
						?.split(',')
						.map((id) => id.trim())
						.filter(Boolean),
				},
			}),
	});

	return {
		quickAccess,
		isLoading,
	};
};
