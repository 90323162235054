import { defineChain } from 'viem';

export const bitlayer = defineChain({
	id: 200901,
	name: 'Bitlayer',
	nativeCurrency: { decimals: 18, name: 'BTC', symbol: 'BTC' },
	rpcUrls: {
		default: {
			http: ['https://rpc.bitlayer-rpc.com'],
			webSocket: ['wss://rpc.bitlayer-rpc.com'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Bitlayer Scan',
			url: 'https://www.btrscan.com',
		},
	},
	testnet: false,
});
