import { Label } from '@/components/ui/label';
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from '@/components/ui/select';
import { useCreateCampaignTasks } from '@/features/campaigns/hooks/create/useCreateCampaignTasks';

const RecurringConfig = ({
	recurrenceConfig,
	taskId,
}: {
	taskId: string;
	recurrenceConfig: {
		isRecurring: boolean;
		frequencyInDays: number;
	};
}) => {
	const { editTask } = useCreateCampaignTasks();
	return (
		<div className="border-t py-5 px-5 flex items-center justify-between">
			<div>
				<Label className="block mb-1">
					<i className="bi-repeat mr-2"></i>
					Set Recurrance
				</Label>
				<p className="text-xs mt-2 mb-0 text-muted-foreground max-w-[400px]">
					Set the frequency of the task so users can complete the task
					multiple times.
				</p>
			</div>
			<div>
				<Select
					value={
						recurrenceConfig?.frequencyInDays
							? recurrenceConfig?.frequencyInDays?.toString()
							: ''
					}
					onValueChange={(value) => {
						editTask(taskId, 'EDIT_RECURRANCE', +value);
					}}
				>
					<SelectTrigger className=" bg-white min-w-[200px] ">
						<SelectValue placeholder={'Select Frequency'} />
					</SelectTrigger>
					<SelectContent>
						<SelectItem value={'0'}>None</SelectItem>
						<SelectItem value={'1'}>Daily</SelectItem>
						<SelectItem value={'7'}>Weekly</SelectItem>
						<SelectItem value={'30'}>Monthly</SelectItem>
					</SelectContent>
				</Select>
			</div>
		</div>
	);
};

export default RecurringConfig;
