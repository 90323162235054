import { DataTable } from '@/components/element/data-table/DataTable';
import { useLoyaltyCampaign } from '../../hooks/useLoyaltyCampaign';
import { useMemo } from 'react';
import { ITask } from '@/features/campaigns/types';
import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { TaskStatus } from '@/features/campaigns/types/tasks.enums';
import StatusBadge from '@/components/element/badges/StatusBadge';

interface ITaskAnalyticsTable {
	title: string;
	status: TaskStatus;
	completions: number;
	initiations: number;
}

const LoyaltyTaskAnalyticsTable = () => {
	const { campaign } = useLoyaltyCampaign();

	const tasks = useMemo(() => {
		if (!campaign) return [];
		return (
			campaign?.quest?.tasks
				?.filter((i) => !i.isDeleted)
				.map((task: ITask) => {
					return {
						title: task.description,
						completions: task?.completedUsersCount,
						status: task?.status,
						// completions: task.funnel.completed.uniqueUsers,
						initiations: task?.initiatedUsersCount,
					};
				}) || []
		);
	}, [campaign.quest.tasks]);

	return (
		<div>
			<h2 className="text-lg font-semibold mb-3">Tasks</h2>
			<DataTable data={tasks} columns={columns} totalCount={tasks?.length} />
		</div>
	);
};

export default LoyaltyTaskAnalyticsTable;

const columns: ColumnDef<ITaskAnalyticsTable>[] = [
	{
		accessorKey: 'title',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Name" />
		),
		size: 1000,
		cell: ({ row }) => <div className="py-1">{row.getValue('title')}</div>,
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'status',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Status" />
		),
		cell: ({ row }) => (
			<div>
				<StatusBadge status={row.getValue('status')} />
			</div>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'initiations',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Initiations" />
		),
		size: 20,
		cell: ({ row }) => <div>{row.getValue('initiations')}</div>,
		enableSorting: true,
		enableHiding: false,
	},

	{
		accessorKey: 'completions',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Completions" />
		),
		size: 20,
		cell: ({ row }) => <div>{row.getValue('completions')}</div>,
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'completions',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Completion Rate" />
		),
		size: 20,
		cell: ({ row }) => <div>{row.getValue('completions')}</div>,
		enableSorting: true,
		enableHiding: false,
	},
];
