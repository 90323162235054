import {
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
	type ColumnDef,
	type PaginationState,
	type SortingState,
	TableOptions,
} from '@tanstack/react-table';
import { useState } from 'react';

interface UseDataTableProps<TData, TValue> {
	data: TData[];
	columns: ColumnDef<TData, TValue>[];
	totalCount: number;
	isServerSide?: boolean;
	enableRowSelection?: boolean;
	pagination?: PaginationState;
	setPagination?: (pagination: PaginationState) => void;
	sorting?: SortingState;
	setSorting?: (sorting: SortingState) => void;
	rowSelection?: Record<string, boolean>;
	setRowSelection?: (rowSelection: Record<string, boolean>) => void;
	defaultSort?: any;
}

export function useDataTable<TData, TValue>({
	data,
	columns,
	totalCount,
	isServerSide,
	enableRowSelection,
	pagination,
	setPagination,
	sorting,
	setSorting,
	rowSelection,
	setRowSelection,
	defaultSort,
}: UseDataTableProps<TData, TValue>) {
	const config: TableOptions<TData> = {
		data,
		columns,
		...(isServerSide
			? {
					pageCount: Math.ceil(totalCount / pagination?.pageSize),
					state: {
						pagination,
						sorting,
					},
					onPaginationChange: setPagination,
					onSortingChange: setSorting,
				}
			: {}),
		enableRowSelection: enableRowSelection ? true : false,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		manualPagination: isServerSide ? true : false,
		manualSorting: isServerSide ? true : false,
		manualFiltering: isServerSide ? true : false,
		...(defaultSort
			? {
					initialState: {
						sorting: defaultSort,
					},
				}
			: {}),
	};
	if (enableRowSelection) {
		if (!config.state) config.state = {};
		config.state.rowSelection = rowSelection;
		config.onRowSelectionChange = setRowSelection;
	}
	const table = useReactTable(config);
	return { table };
}
