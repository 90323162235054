import Spinner from '@/components/element/loading/Spinner';
import { useEffect, useRef, useState } from 'react';

const FullPageLoading = () => {
	const ref = useRef<HTMLDivElement>(null);
	const [height, setHeight] = useState('auto');

	useEffect(() => {
		const updateHeight = () => {
			const topOffset = ref.current?.getBoundingClientRect().top || 0;
			const calculatedHeight = `calc(100vh - ${topOffset}px - 80px)`;
			setHeight(calculatedHeight);
		};

		window.addEventListener('resize', updateHeight);
		updateHeight(); // Initial call to set height

		return () => {
			window.removeEventListener('resize', updateHeight);
		};
	}, []);

	return (
		<div
			className="w-full flex items-center justify-center"
			style={{ minHeight: height }}
		>
			<div role="status">
				<Spinner size={'lg'} />
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);
};

export default FullPageLoading;
