import {
	CampaignRewardCategory,
	ICampaignAddReward,
} from '@/features/campaigns/types';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import DiscordRoleReward from './components/reward-methods/AddDiscordRole';
import ERC20Reward from './components/reward-methods/token/AddERC20TokenReward';
import NFTReward from './components/reward-methods/reward/AddNFTReward';
import CustomReward from './components/reward-methods/AddCustomReward';
import AddRewardMethods from './components/reward-methods/AddRewardMethods';
import { links } from '@/config/links';

const CampaignRewards = () => {
	const { mode, rewards, setRewards, errors, setIsDirty, details } =
		useCreateCampaign();

	const updateReward = (
		category: CampaignRewardCategory,
		reward: ICampaignAddReward,
	) => {
		setIsDirty(true);
		setRewards((prev) => {
			const newRewards = [...prev];
			const index = newRewards.findIndex((r) => r.category === category);
			newRewards[index] = reward;
			return newRewards;
		});
	};

	return (
		<div>
			<div className="flex justify-center relative items-center pt-10">
				<div className="absolute top-0 start-0 mt-10 ms-10"></div>
				<div className="mb-5 w-[680px] relative">
					<h4 className="text-xl font-medium">Add Campaign Rewards</h4>
					<p className="text-muted-foreground text-xs mt-1 mb-4">
						Configure the rewards for your campaign.
						<a
							href={links.docs.rewards}
							target="_blank"
							className="ms-1 font-medium text-blue-800 dark:text-blue-800  hover:underline"
						>
							Learn more about rewards
						</a>
					</p>
					{/* <XPReward totalXP={totalXP} /> */}
					<AddRewardMethods rewards={rewards} mode={mode} />
					<div className="mt-4">
						{rewards
							?.filter((i) => i.isActive)
							.map((i) => {
								if (
									i.category === CampaignRewardCategory.DiscordRole
								)
									return (
										<DiscordRoleReward
											mode={mode}
											discordRoleReward={i}
											setDiscordRoleReward={(discord) =>
												updateReward(
													CampaignRewardCategory.DiscordRole,
													discord,
												)
											}
											errors={errors?.rewards?.discord}
											key={'discord'}
											noEndTime={details?.noEndTime}
										/>
									);
								if (i.category === CampaignRewardCategory.Token)
									return (
										<ERC20Reward
											tokenReward={i}
											setTokenReward={(token) => {
												updateReward(
													CampaignRewardCategory.Token,
													token,
												);
											}}
											errors={errors?.rewards?.token}
											key={'erc20'}
											noEndTime={details?.noEndTime}
										/>
									);
								if (i.category === CampaignRewardCategory.Nft)
									return (
										<NFTReward
											nftReward={i}
											setNftReward={(nft) => {
												updateReward(
													CampaignRewardCategory.Nft,
													nft,
												);
											}}
											errors={errors?.rewards?.nft}
											key={'nft'}
											noEndTime={details?.noEndTime}
										/>
									);
								if (i.category === CampaignRewardCategory.Whitelist)
									return (
										<CustomReward
											whitelistReward={i}
											setWhitelistReward={(whitelist) => {
												updateReward(
													CampaignRewardCategory.Whitelist,
													whitelist,
												);
											}}
											errors={errors?.rewards?.whitelist}
											key={'custom'}
											noEndTime={details?.noEndTime}
										/>
									);
								return <div></div>;
							})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CampaignRewards;
