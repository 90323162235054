import { Card, CardContent } from '@/components/ui/card';
import { links } from '@/config/links';

export const ProductGuides = () => {
	return (
		<Card>
			<CardContent className="py-4">
				<div className="font-medium">Resources</div>
				<div className="font-medium text-sm mt-3 space-y-2">
					{guideLinks.map((i) => (
						<a
							key={i.title}
							className="hover:underline cursor-pointer block"
							href={i.link}
							target="_blank"
							rel="noreferrer"
						>
							<i className={`bi-${i.icon} me-2`}></i>
							{i.title}
						</a>
					))}
				</div>
			</CardContent>
		</Card>
	);
};

const guideLinks = [
	{
		title: 'Docs',
		icon: 'file-earmark-text',
		link: links.docs.apiCreation,
	},
	{
		title: 'Case Studies',
		icon: 'file-earmark-text',
		link: links.docs.apiCreation,
	},
	{
		title: 'Join our Discord Community',
		icon: 'file-earmark-text',
		link: links.docs.apiCreation,
	},
	{
		title: 'Schedule a call with us',
		icon: 'file-earmark-text',
		link: links.docs.apiCreation,
	},
];
