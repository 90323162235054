import { Label } from '@/components/ui/label';
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from '@/components/ui/select';
import { useCreateCampaignTasks } from '@/features/campaigns/hooks/create/useCreateCampaignTasks';

const MaxRetriesConfig = ({
	maxFailedCountConfig,
	taskId,
	taskXP,
}: {
	taskId: string;
	taskXP: number;
	maxFailedCountConfig: {
		maxFailedCount?: number;
		isMaxFailedCountEnabled: boolean;
		isDiminishingXpEnabled: boolean;
	};
}) => {
	const { editTask } = useCreateCampaignTasks();

	return (
		<div className="border-t py-5 px-5 flex items-center justify-between">
			<div className="flex-grow">
				<Label className="block mb-1">
					<i className="bi-shield-lock mr-2"></i>
					Reduce XPs for wrong attempts
				</Label>
				<p className="text-xs mt-2 mb-0 text-muted-foreground max-w-[400px]">
					Set max retries that a user can do. Each failure reduces user's
					XP proptionally.
				</p>
			</div>
			<div>
				<Select
					value={maxFailedCountConfig.maxFailedCount?.toString()}
					onValueChange={(value) => {
						editTask(taskId, 'MAX_RETRIES', +value);
					}}
				>
					<SelectTrigger className=" bg-white min-w-[200px]">
						<SelectValue placeholder="Select Max Attempts" />
					</SelectTrigger>
					<SelectContent>
						{Array(11)
							.fill(0)
							.map((_, i) => {
								return {
									i,
								};
							})
							.filter(({ i }) =>
								i === 0 ? true : taskXP && taskXP % i === 0,
							)
							.map(({ i }) => (
								<SelectItem
									key={i}
									value={i === 0 ? (0)?.toString() : i.toString()}
								>
									{i === 0 ? 'Select Max Attempts' : i}
								</SelectItem>
							))}
					</SelectContent>
				</Select>
			</div>
		</div>
	);
};

export default MaxRetriesConfig;
