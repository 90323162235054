import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import LandingContainer from '../ui/LandingContainer';
import SectionHeader from '../ui/SectionHeader';
import { enterprisePage } from '../../content/enterpise.content';
import { Button } from '@/components/ui/button';

const EnterpriseHero = () => {
	return (
		<LandingContainer>
			<SectionHeader {...enterprisePage.hero} className="pt-20 pb-16" />
			<BookADemoForm />
		</LandingContainer>
	);
};

export default EnterpriseHero;

const BookADemoForm = () => {
	return (
		<div className="relative mx-auto w-full max-w-lg border-y border-gray-200 bg-white sm:rounded-2xl sm:border sm:shadow-lg">
			<form
				className="grid gap-5 p-7"
				style={{ opacity: 1, transform: 'none' }}
			>
				<div>
					<label
						htmlFor="name"
						className="mb-2 block text-xs font-medium text-gray-500"
					>
						YOUR NAME
					</label>
					<Input />
				</div>
				<div>
					<label
						htmlFor="email"
						className="mb-2 block text-xs font-medium text-gray-500"
					>
						YOUR WORK EMAIL
					</label>
					<Input />
				</div>
				<div>
					<label
						htmlFor="company"
						className="mb-2 block text-xs font-medium text-gray-500"
					>
						COMPANY NAME
					</label>
					<Input />
				</div>
				<div>
					<label
						htmlFor="feedback"
						className="mb-2 block text-xs font-medium text-gray-500"
					>
						HOW CAN WE HELP?
					</label>
					<Textarea
						name="comments"
						id="comments"
						rows={6}
						className="block w-full rounded-md border-gray-300 text-gray-900 placeholder-gray-400 focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
						placeholder="We're a media conglomerate that generates thousands of links per month – most of them programmatically. We're looking for a link management platform with the infrastructure that can handle our scale."
						aria-invalid="true"
						defaultValue={''}
					/>
				</div>
				<Button>Book a Demo</Button>
			</form>
		</div>
	);
};
