import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogDescription,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useCreateEnterprise } from '../../hooks/useCreateEnterprise';
import SetUpNewCommunityForm from '../form/SetUpNewCommunityForm';
import SetUpSocials from '../form/SetUpSocialsForm';
import { useRef } from 'react';

const CreateEnterpriseDialog = ({
	open,
	setOpen,
}: {
	open: boolean;
	setOpen: (open: boolean) => void;
}) => {
	const {
		community,
		setCommunity,
		errors,
		handleNext,
		isLoading,
		step,
		socials,
		setSocials,
	} = useCreateEnterprise(setOpen);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[585px] overflow-visible">
				<DialogHeader className="border-b pb-3 mb-7">
					<DialogTitle>Create new Project</DialogTitle>
					<DialogDescription>
						Make changes to your profile here. Click save when you're
						done.
					</DialogDescription>
				</DialogHeader>
				{step === 'community' && (
					<div className="grid gap-10">
						<SetUpNewCommunityForm
							community={community}
							setCommunity={setCommunity}
							errors={errors.community}
						/>
					</div>
				)}
				{step === 'socials' && (
					<div className="grid gap-10">
						<SetUpSocials
							socials={socials}
							setSocials={setSocials}
							errors={errors.socials}
						/>
					</div>
				)}

				<DialogFooter className="justify-between space-x-2 border-t pt-3 mt-">
					<Button variant="ghost" onClick={() => setOpen(false)}>
						Cancel
					</Button>
					<Button onClick={() => handleNext()} disabled={isLoading}>
						{isLoading ? 'Creating...' : 'Next'}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default CreateEnterpriseDialog;
