import { careersContent } from '../../content/careers.content';
import LandingContainer from '../ui/LandingContainer';

const CareersHero = () => {
	return (
		<LandingContainer>
			<div className="pt-40 pb-20">
				<div className="relative container space-y-16 md:space-y-32">
					<div className="flex w-full flex-col">
						<div className="text-card items-center text-center justify-center md:items-start md:text-left md:justify-start">
							<div className="flex flex-col items-center text-center justify-center md:items-start md:text-left md:justify-start">
								<div className="flex flex-col space-y-4 md:space-y-8 items-center text-center justify-center md:items-start md:text-left md:justify-start">
									<a
										className="group flex items-center gap-2 rounded-[5px] px-3 py-2 font-inter text-[9px] leading-normal tracking-[0.1px]  xs:gap-5 xs:text-[12px] xs:tracking-[0.14px] sm:pl-4 sm:text-[14px] md:py-[5.5px] md:pr-[5.5px] self-left bg-zinc-100 text-black border border-white/10"
										target="_self"
										href={careersContent.hero.link}
									>
										See open positions
										<div className="flex aspect-[25/22] size-6 shrink-0 items-center justify-center rounded-[3px] group-hover:bg-opacity-80 xs:w-[25px] bg-violet-400">
											<i className="bi-chevron-right text-[#fff]"></i>
										</div>
									</a>
									<h1 className="w-full  text-6xl md:max-w-5xl font-medium text-black">
										{careersContent.hero.title}
									</h1>
									<div className="text-lg transition-colors text-black w-full md:max-w-2xl">
										<p>{careersContent.hero.description}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</LandingContainer>
	);
};

export default CareersHero;
