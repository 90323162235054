import React from 'react';
import LandingContainer from '../ui/LandingContainer';
import SectionHeader from '../ui/SectionHeader';
import { aboutPage } from '../../content/about.content';

const Investors = () => {
	return (
		<LandingContainer>
			<SectionHeader title={aboutPage.investors.title} className="mb-20" />
			<div className=" ">
				<div className="gap-y-4x grid xxs:grid-cols-2 md:grid-cols-4">
					{aboutPage.investors.investors.map((i) => (
						<a
							target="_self"
							className="pointer-events-none cursor-default relative block border-e"
							href={i.link}
						>
							<div
								className="relative flex min-h-[100px] flex-col xs:min-h-[150px] md:mx-4 lg:min-h-[280px] lg:pl-8"
								style={{ opacity: 1, transform: 'none' }}
							>
								<p className="text-left text-muted-foreground !font-alliance !font-normal uppercase !tracking-[1.2px]  text-xs">
									{i.name}
								</p>
								<img
									alt="Svg"
									loading="lazy"
									width={172}
									height={23}
									decoding="async"
									data-nimg={1}
									className="transition-opacity absolute inset-0 m-auto pt-0 invert-0 xxs:max-w-[80%] xs:pt-6 lg:max-w-none opacity-100"
									style={{ color: 'transparent' }}
									src={i.img}
								/>
							</div>
						</a>
					))}
				</div>
			</div>
		</LandingContainer>
	);
};

export default Investors;
