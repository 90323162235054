export const careersContent = {
	hero: {
		title: 'Lend us your talents.',
		description:
			'We’re looking for ambitious and creative thinkers to help us power the future of commercial lending. Mind if we borrow you?',
		link: '',
	},
	about: {
		title: 'We build better financial products. And people’s careers.',
		description: `We design and build great products. But only because we’re the product of great people. Check out the link below to learn more about our platform and customers. Then if it sounds up your street, check out our open roles.`,
		img1: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/team-4.jpg',
		img2: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/team-5.jpg',
		img3: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/team-6.jpg',
	},
	winningCulture: {
		title: 'Join a winning culture.',
		description:
			'We know our work culture is great. But it’s nice to be awarded for it.',
		img: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/team-5.jpg',
	},
	positions: {
		title: 'We are expanding!',
		description: 'Check out our open positions.',
		roles: [
			{
				group: 'Status Network',
				roles: [
					{
						description: 'L2 Lead Developer',
						link: 'https://google.com',
					},
					{
						description:
							'L2 Business Development &amp; Partnerships Lead (Status Network)',
						link: 'https://google.com',
					},
				],
			},
			{
				group: 'Status Network',
				roles: [
					{
						description: 'L2 Lead Developer',
						link: 'https://google.com',
					},
					{
						description:
							'L2 Business Development &amp; Partnerships Lead (Status Network)',
						link: 'https://google.com',
					},
				],
			},
		],
	},
};
