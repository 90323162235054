import { cn } from '@/lib/utils';

const SelectActionType = ({ value, setValue }) => {
	return (
		<div className="">
			<div className="font-medium text-sm">What kind of transaction?</div>
			<p className="text-xs text-muted-foreground mb-3">
				Choose the type of transaction you want to validate.
			</p>
			<div className="grid grid-cols-2 gap-5">
				<RadioItem
					isChecked={value === 'contract'}
					label="Contract"
					icon="bi-code-square"
					description="A standard transaction with a smart contract (recommended)."
					onClick={() => setValue('contract')}
				/>
				<RadioItem
					isChecked={value === 'address'}
					label="Address"
					icon="bi-person"
					description="Where a external address is creating a transaction. Eg, Used in bridges."
					onClick={() => setValue('address')}
				/>
			</div>
		</div>
	);
};

export default SelectActionType;

const RadioItem = ({ isChecked, label, icon, description, onClick }) => {
	return (
		<div
			className={cn(
				isChecked
					? 'border-violet-500 border-2 border-opacity-80'
					: 'border-2 border-slate-300',
				`p-3 rounded-lg bg-white cursor-pointer relative`,
			)}
			onClick={onClick}
		>
			<div className="absolute top-0 end-0 p-3">
				<i
					className={cn(
						isChecked ? 'bi-check-circle-fill' : `bi-circle`,
						isChecked ? 'text-violet-500' : `text-slate-300 `,
					)}
				/>
			</div>
			<i
				className={cn(
					isChecked ? 'text-violet-500' : 'text-slate-400',
					icon,
					'mb-4 block',
				)}
			/>
			<div className="text-sm font-medium">{label}</div>
			<div className="text-xs text-muted-foreground mt-1">{description}</div>
		</div>
	);
};
