import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PostActionCreationDialog = ({
	open,
	setOpen,
	actionId,
}: {
	open?: boolean;
	actionId?: string;
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const navigate = useNavigate();
	return (
		<Dialog
			open={open}
			onOpenChange={() => {
				setOpen(false);
				navigate('/app/tools/dave/actions');
			}}
		>
			<DialogContent className="sm:max-w-[435px] px-0 p-0">
				<div className="relative p-7">
					<i className="bi bi-check-circle-fill text-violet-400 text-2xl"></i>
					<div>
						<h2 className="text-xl font-semibold mt-6">
							Action has been created
						</h2>
						<p className="text-sm text-[#6B7280] mt-2">
							Your action has been created successfully. You can now
							view the action in the actions list.
						</p>
						<div className="flex mt-5 space-x-3">
							<Button
								onClick={() => {
									navigate(
										`/app/tools/dave/query/new?actionId=${actionId}`,
									);
									setOpen(false);
								}}
							>
								Create Query
								<i className="bi bi-arrow-right ms-2"></i>
							</Button>
							<Button
								variant="outline"
								onClick={() => {
									navigate(`/app/tools/dave/action/${actionId}`);
									setOpen(false);
								}}
							>
								Check Data
							</Button>
						</div>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default PostActionCreationDialog;
