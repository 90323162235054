import { Navigate, Route, Routes } from 'react-router-dom';
import DavePage from './dave.page';
import DaveQueryCreatePage from './dave-query-create.page';
import DaveActionCreatePage from './dave-action-create.page';
import DaveQueryPage from './dave-query.page';
import DaveActionPage from './dave-action.page';
import DaveActionUpdatePage from './dave-action-update.page';

const DaveRoutes = () => {
	return (
		<Routes>
			<Route path="/actions" element={<DavePage />} />
			<Route path="/queries" element={<DavePage />} />
			<Route path="/query/new" element={<DaveQueryCreatePage />} />
			<Route path="/query/update/:id" element={<DaveQueryCreatePage />} />
			<Route path="/query/:id" element={<DaveQueryPage />} />
			<Route path="/action/new" element={<DaveActionCreatePage />} />
			<Route path="/action/update/:id" element={<DaveActionUpdatePage />} />
			<Route path="/action/:id" element={<DaveActionPage />} />
			<Route
				path="*"
				element={<Navigate replace to="/app/tools/dave/actions" />}
			/>
		</Routes>
	);
};

export default DaveRoutes;
