import { useEffect } from 'react';
import { useConnectAPI } from '../hooks/useConnectAPI';
import useTestAPI from '../hooks/useTestAPI';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Badge } from '@/components/ui/badge';
import { iconKeyMap } from '../types/task-apis.enum';
import clsx from 'clsx';

export const TestAPI = ({ setStep }) => {
	const { formErrors, formFields, setFormErrors } = useConnectAPI();

	const { handlePayloadInputTestValue } = useTestAPI();

	useEffect(() => {
		setFormErrors({});
	}, [formFields.apiPayload, setFormErrors]);

	return (
		<div>
			<div className="w-full">
				{formFields?.apiPayload?.length > 0
					? formFields?.apiPayload?.map((item: any, index: number) => (
							<div key={item.key} className="my-4">
								<div
									className={clsx(
										'flex items-center border rounded-lg overflow-none',
										formErrors[
											formFields?.apiPayload?.[index]?.key
										]
											? 'border-destructive'
											: '',
									)}
								>
									<div className="border-e h-9 flex items-center bg-muted rounded-s-md min-w-[157.7px]">
										<Label className="p-2 flex items-center text-black/70">
											<i
												className={`bi-${iconKeyMap[item.value]} me-2 text-black/70`}
											></i>
											{item.key.charAt(0).toUpperCase() +
												item.key.slice(1)}
										</Label>
									</div>
									<Input
										className={clsx(
											'border-none shadow-none w-full ring-0 focus:ring-0 focus:outline-none focus-visible:ring-0 focus-visible:outline-none',
										)}
										value={
											formFields?.apiPayload?.[index]
												?.testValue
										}
										onChange={(e: any) =>
											handlePayloadInputTestValue(
												e.target.value,
												index,
											)
										}
									/>
								</div>

								{formErrors[item.key] ? (
									<p className="text-xs text-destructive mt-1 text-right">
										{formErrors[item.key]}
									</p>
								) : null}
							</div>
						))
					: null}

				<div className=" max-w-[480px] overflow-x-auto">
					{formFields?.isApiTestDone ? (
						<>
							{typeof formFields?.responseSchema === 'string' ? (
								<Textarea
									className="w-full h-32 bg-muted"
									value={formFields?.responseSchema}
									readOnly
								/>
							) : (
								<pre className="h-fit relative rounded bg-muted min-w-full w-fit p-4 font-mono text-sm font-medium">
									{JSON.stringify(
										formFields?.responseSchema,
										null,
										2,
									)}
								</pre>
							)}
							<div className="my-4">
								{formFields?.isApiSchemaValid ? (
									<Badge
										variant="secondary"
										className="bg-green-100 text-green-900"
									>
										Valid Schema
									</Badge>
								) : (
									<Badge variant="destructive">
										Invalid Schema
									</Badge>
								)}
							</div>
						</>
					) : null}
				</div>
			</div>

			{/* {formFields?.isApiTestDone ? (
				<p className="mt-2 mb-0 text-xs text-destructive">
					API test failed, please check and try again.
				</p>
			) : null} */}
		</div>
	);
};
