import SeparatorWithText from '@/components/ui/seprator-with-text';
import {
	ITask,
	ITaskAdminInput,
	TemplateFamily,
	TemplateType,
} from '@/features/campaigns/types';
import { cn } from '@/lib/utils';
import TaskInputs from './inputs/TaskInputs';
import {
	IEditingTasks,
	useCreateCampaignTasks,
} from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import InputText from '@/components/element/inputs/InputText';
import InputSelect2 from '@/components/element/inputs/InputSelect2';

const TaskParams = ({
	adminInputs,
	templateFamily,
	templateType,
	requiredLogins,
	description,
	taskId,
	isCustomInitiationURLAllowed,
	customInitiationURL,
	isDisabled,
	isAdminRequiredLoginsSelectionNeeded,
	requiredLoginOptions,
}: IEditingTasks['task']) => {
	const { setErrors } = useCreateCampaign();
	const { errors, editTaskAdminInput, editTask, deleteTask } =
		useCreateCampaignTasks();

	return (
		<div>
			<SeparatorWithText
				className="mt-4 mb-3"
				text="Task Configuration"
				align="end"
			/>
			<div className={`grid py-2 grid-cols-12  gap-5`}>
				{(adminInputs ?? [])
					?.filter((i) => !i.hide)
					?.map((i) => (
						<div className={cn(`${i.size}`)}>
							<TaskInputs
								adminInput={i}
								adminInputs={adminInputs}
								templateFamily={templateFamily}
								templateType={templateType}
								description={description}
								taskId={taskId}
								deleteTask={deleteTask}
								error={errors?.[taskId]?.adminInputs?.[i.key]}
								editTaskAdminInput={editTaskAdminInput}
								setErrors={(taskId, key, value) => {
									setErrors((prev: any) => ({
										...prev,
										tasks: {
											...prev?.tasks,
											[taskId]: {
												...prev.tasks?.[taskId],
												adminInputs: {
													...prev.tasks?.[taskId]
														?.adminInputs,
													[key]: value,
												},
											},
										},
									}));
								}}
							/>
						</div>
					))}
				{isCustomInitiationURLAllowed && !isDisabled && (
					<div className="col-span-12 mt-4">
						<InputText
							value={customInitiationURL}
							setValue={(e) => {
								editTask(taskId, 'CUSTOM_INITIATION_URL', e);
							}}
							label="Initiation URL (optional)"
							placeholder="https://www.example.com"
							subLabel="Add link where users will be redirected to start the task."
							subLabelClassName="font-normal text-xs mt-1"
							error={errors?.[taskId]?.customInitiationURL}
							errorText="Invalid URL"
						/>
					</div>
				)}
				{isAdminRequiredLoginsSelectionNeeded && !isDisabled && (
					<div className="col-span-12 mt-4">
						<InputSelect2
							value={requiredLogins?.[0]}
							setValue={(e) => {
								editTask(taskId, 'REQUIRED_LOGIN', e);
							}}
							options={
								requiredLoginOptions?.map((i) => ({
									label: i,
									value: i,
								})) ?? []
							}
							label="Required Login"
							subLabel="Select the login method required to complete the task."
							subLabelClassName="font-normal text-xs mt-1 mb-2"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default TaskParams;
