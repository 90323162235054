import { Button } from '@/components/ui/button';
import {
	confirmEmail,
	resendVerificationEmail,
} from '@/features/auth/services/auth.service';
import { useRouter } from '@/hooks/useRouter';
import analytics from '@/lib/analytics';
import { queryClient } from '@/lib/react-query';
import { TrackingEvents } from '@/types/tracking.type';
import { handleErrorMessage } from '@/utils/notifications';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

export const useConfirmEmail = () => {
	const { query, navigate } = useRouter();
	const [, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		handleConfirm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query.token]);

	const handleConfirm = async () => {
		try {
			if (!query.token) {
				return;
			}
			setLoading(true);
			await confirmEmail(query.token.toString());
			analytics.track(TrackingEvents.EmailVerified, {});
			await queryClient.invalidateQueries({
				queryKey: ['user'],
			});
			setSearchParams({});
			toast.success('Email confirmed successfully, launch your first quest', {
				action: (
					<Button onClick={() => navigate('/app/campaign/quests/new')}>
						Launch Quest
					</Button>
				),
			});
			setLoading(false);
		} catch (err) {
			setLoading(false);
			handleErrorMessage(err);
		}
	};

	const resendEmail = async () => {
		toast.promise(Promise.all([resendVerificationEmail()]), {
			loading: 'Sending email...',
			success: 'Email sent successfully',
			error: 'Failed to send email',
		});
	};

	return {
		loading: query.token ? loading : false,
		resendEmail,
	};
};
