import { useEffect, useMemo, useState } from 'react';
import { useLoyaltyCampaign } from './useLoyaltyCampaign';
import {
	addLoyaltySection,
	deleteLoyaltySection,
	editLoyaltySection,
	rearrangeTasks,
} from '../services/loyalty.service';
import { queryClient } from '@/lib/react-query';
import { DNDType } from '@/components/element/dnd/DnDKanban';
import { handleErrorMessage } from '@/utils/notifications';
import { TaskSectionUnitTypes } from '@/features/campaigns/types/tasks.enums';
import { ILoyaltyTask } from '../types/loyalty.type';
import { toast } from 'sonner';

export const useLoyaltyCampaignSections = ({
	open,
	setOpen,
}: {
	open?: boolean;
	setOpen?: (value: boolean) => void;
}) => {
	const { id, campaign } = useLoyaltyCampaign();

	const [showAddSection, setShowAddSection] = useState(false);
	const [isDeletingSection, setIsDeletingSection] = useState(false);

	const [title, setTitle] = useState('');
	const [errors, setErrors] = useState<any>({});
	const [isAddingSection, setIsAddingSection] = useState(false);

	useEffect(() => {
		setErrors({});
	}, [title]);

	useEffect(() => {
		setTitle('');
	}, [open]);

	const sections: DNDType[] = useMemo(() => {
		if (
			!campaign ||
			!campaign.taskSections ||
			campaign.taskSections.length === 0
		)
			return [];
		return (
			campaign?.taskSections?.map((section) => {
				return {
					id: `container-${section._id}`,
					title: section.title,
					_id: section._id,
					items: section?.displayData
						?.map(({ unitId }) => {
							const task = campaign?.quest?.tasks?.find(
								(task) => task._id === unitId,
							);
							if (!task) return null;
							return {
								id: `item-${task._id}` as `item-${string}`,
								title: task.description,
								...task,
							};
						})
						?.filter((i) => i?.id),
				};
			}) ?? []
		);
	}, [campaign]);

	const addSection = async (title: string) => {
		if (!title.trim()) {
			setErrors({ sectionTitle: 'Section title is required' });
			return;
		}
		try {
			setIsAddingSection(true);
			await addLoyaltySection({
				section: {
					title,
				},
				campaignId: id,
			});
			queryClient.invalidateQueries({
				queryKey: ['campaign', 'loyalty-quests'],
			});
			setIsAddingSection(false);
			setOpen(false);
		} catch (err) {
			setIsAddingSection(false);
			console.log(err);
		}
	};
	const deleteSection = async (sectionId_: string) => {
		try {
			setIsDeletingSection(true);
			const sectionId = sectionId_;
			await deleteLoyaltySection({
				sectionId: sectionId,
				campaignId: id,
			});
			queryClient.invalidateQueries({
				queryKey: ['campaign', 'loyalty-quests'],
			});
			setIsDeletingSection(false);
		} catch (err) {
			setIsDeletingSection(false);
			handleErrorMessage(err);
			console.log(err);
		}
	};

	const editSection = async (
		sectionId: string,
		data: {
			title: string;
		},
	) => {
		try {
			if (!data || !data.title.trim()) {
				toast.error('Section title is required');
				return;
			}
			await editLoyaltySection({
				sectionId: sectionId,
				campaignId: id,
				...data,
			});
			queryClient.invalidateQueries({
				queryKey: ['campaign', 'loyalty-quests'],
			});
		} catch (err) {
			handleErrorMessage(err);
			console.log(err);
		}
	};

	return {
		isDeletingSection,
		deleteSection,
		sections,
		addSection,
		errors,
		setErrors,
		editSection,
		title,
		setTitle,
		isAddingSection,
		showAddSection,
		setShowAddSection,
		tasks: campaign?.quest?.tasks as ILoyaltyTask[],
	};
};
