import { Separator } from '@/components/ui/separator';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { ILeaderboardUserDetails } from '../../types/leaderboard.types';
import UserActivity from './UserActivity';
import UserProfile from './UserProfile';
import Spinner from '@/components/element/loading/Spinner';
import UserRewards from './UserRewards';

function UserDialog({
	open,
	setOpen,
	userProfile,
	isUserProfileLoading,
}: {
	open: boolean;
	setOpen: (open: boolean) => void;
	userProfile: ILeaderboardUserDetails;
	isUserProfileLoading: boolean;
}) {
	if (!userProfile && !isUserProfileLoading) return <></>;
	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetContent className="min-w-[550px] p-0 overflow-y-auto pb-10">
				{isUserProfileLoading ? (
					<div className="flex items-center justify-center h-[100vh]">
						<Spinner size="lg" />
					</div>
				) : (
					<div>
						<UserProfile
							profile={userProfile?.profile}
							totalQuests={
								userProfile?.activity?.filter((i) => i.xp > 0)
									?.length
							}
							totalXp={userProfile?.activity?.reduce(
								(a, b) => a + b.xp,
								0,
							)}
						/>
						<Separator className="my-4  mt-5 mb-5" />
						<UserRewards rewards={userProfile?.rewards} />
						<Separator className="my-4  mt-5 mb-5" />
						<UserActivity
							activity={userProfile?.activity?.filter((i) => i.xp > 0)}
						/>
					</div>
				)}
			</SheetContent>
		</Sheet>
	);
}

export default UserDialog;
