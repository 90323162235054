import {
	getSupportedChains,
	getSupportedContracts,
} from '@/services/utility.service';
import { useQuery } from '@tanstack/react-query';

export enum ContractType {
	PersonaNftTire1 = 'PERSONA_NFT_TIER_1',
	PersonaNftTire2 = 'PERSONA_NFT_TIER_2',
	IntractCampaignRewardToken = 'INTRACT_CAMPAIGN_REWARD_TOKEN',
	IntractCampaignRewardNft = 'INTRACT_CAMPAIGN_REWARD_NFT',
}

interface IChain {
	chainId: number;
	contractType: ContractType;
	address: string;
}

export const useGetContract = (chainId: number, contractType: ContractType) => {
	const { data, isLoading } = useQuery<IChain>({
		queryKey: ['contracts', chainId, contractType],
		queryFn: () => getSupportedContracts(chainId, contractType),
		staleTime: Infinity,
		enabled: !!chainId,
	});

	return {
		contract: data,
		isLoading,
	};
};
