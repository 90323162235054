import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/lib/react-query';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import AuthProvider from './AuthProvider';
import { ThemeProvider } from './ThemeProvider';
import { Toaster } from '@/components/ui/toaster';
import { ConfirmProvider } from './ConfirmProvider';
import { WagmiProvider } from 'wagmi';
import { wagmiConfig } from '../lib/wagmi';
import { Toaster as Toaster2 } from '@/components/ui/sonner';
import { SearchProvider } from './SearchProvider';
import GoogleTranslateWarningModal from '@/components/layout/error/GoogleTranslateWarningModal';

function AppProvider({ children }: { children: JSX.Element }) {
	return (
		<HelmetProvider>
			<WagmiProvider config={wagmiConfig}>
				<QueryClientProvider client={queryClient}>
					<AuthProvider>
						<ThemeProvider>
							<ConfirmProvider>
								<Suspense fallback={<FullPageLoading />}>
									{children}
									<Toaster />
									<Toaster2 />
								</Suspense>
							</ConfirmProvider>
						</ThemeProvider>
					</AuthProvider>
				</QueryClientProvider>
			</WagmiProvider>
			<GoogleTranslateWarningModal />
		</HelmetProvider>
	);
}

export default AppProvider;
