import PageHeader from '@/components/layout/PageHeader';
import { LoyaltyCampaignProvider } from '../providers/loyalty-campaigns.provider';
import LoyaltyCampaign from '../components/LoyaltyCampaign';
import { LoyaltyTaskProvider } from '../providers/loyalty-tasks.provider';
import { Button } from '@/components/ui/button';
import { useState } from 'react';
import LoyaltySettingsDialog from '../components/settings/LoyaltySettingsDialog';
import { useLoyaltyCampaign } from '../hooks/useLoyaltyCampaign';
import { links } from '@/config/links';
import { useAuth } from '@/hooks/useAuth';
import { QUEST_URL } from '@/config';

const LoyaltyCampaignsPage = () => {
	return (
		<>
			<LoyaltyCampaignProvider>
				<LoyaltyTaskProvider>
					<LoyaltyHeader />
					<LoyaltyCampaign />
				</LoyaltyTaskProvider>
			</LoyaltyCampaignProvider>
		</>
	);
};

export default LoyaltyCampaignsPage;

const LoyaltyHeader = () => {
	const [open, setOpen] = useState(false);
	const { campaign } = useLoyaltyCampaign();
	const { user } = useAuth();
	return (
		<PageHeader
			title="Loyalty Program"
			description="Set up your loyalty program to reward your community members for their contributions."
			learn={campaign?.publishCampaign ? '' : links.docs.loyalty}
		>
			{campaign?.publishCampaign && (
				<>
					<Button
						className="me-2"
						variant="outline"
						onClick={() => setOpen(true)}
					>
						<i className="bi-gear me-2"></i>
						Settings
					</Button>
					<Button
						onClick={() => {
							window.open(
								`${QUEST_URL}/project/${user?.enterpriseSlug}`,
								'_blank',
							);
						}}
					>
						<i className="bi-box-arrow-up-right me-2"></i>
						Open Community Hub
					</Button>
					<LoyaltySettingsDialog open={open} setOpen={setOpen} />
				</>
			)}
		</PageHeader>
	);
};
