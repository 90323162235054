import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { useTelegramIntegration } from '../../hooks/useTelegramIntegration';
import { TELEGRAM_BOT_USERNAME } from '@/config';
import Spinner from '@/components/element/loading/Spinner';

export function ConnectTelegramDialog({
	open,
	setOpen,
}: {
	open: boolean;
	setOpen: any;
}) {
	const { handleNext, isLoading, instructions } = useTelegramIntegration(setOpen);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[525px]">
				<div className="">
					<DialogTitle>
						Instructions for integrating with Telegram
					</DialogTitle>
					<DialogDescription className="mt-2 ">
						Please note that our bot can only be added in public channels
					</DialogDescription>
					<Button variant="outline" className="mt-4" size="sm" asChild>
						<a
							href={`https://t.me/${TELEGRAM_BOT_USERNAME}`}
							target="_blank"
							rel="noreferrer"
						>
							<i className="bi-link-45deg me-1"></i>
							Link to our bot
						</a>
					</Button>
				</div>
				<Separator />
				<div>
					{instructions.map((instruction, index) => (
						<div className="mb-8">
							<div className="text-sm font-medium mb-1">
								{index + 1}. {instruction.title}
							</div>
							<div className="text-sm text-muted-foreground">
								{instruction.description}
							</div>
							{instruction.code && (
								<div className="mt-3">
									<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
										{instruction.code}
									</code>
								</div>
							)}
						</div>
					))}
					<div className="font-medium text-sm mt-10">
						Once you follow these, the integration will be added
						automatically!
					</div>
					<Button className="mt-5 w-full" onClick={handleNext}>
						{isLoading && <Spinner className="me-2" />}
						<span>
							{isLoading ? 'Loading...' : 'Verify Integration'}
						</span>
						<i className="bi-arrow-right ms-2"></i>
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}
