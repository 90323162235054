export const aboutPage = {
	hero: {
		title: 'Powering the next generation of business lending.',
		description:
			'We believe financial products should be flexible, safe, and transparent. So we help businesses build them that way.',
		img: 'https://res.cloudinary.com/dhgck7ebz/image/upload/f_auto,c_limit,w_3840,q_auto/Team/Photos/Team_01',
	},
	mission: {
		title: 'OUR MISSION',
		description:
			'To enable organizations to launch personalized, transparent, and safer lending products.',
	},
	whyWeExist: {
		title: 'Why Intract Exists.',
		description: [
			`Canopy was founded by fintech and lending experts who saw – firsthand – the limitations created by working within an internal loan management system of record. From a product construct standpoint, inflexible rules and policies prevent quick changes and the launching of creative offerings. This is especially true within commercial lending.`,
			`Homegrown or out-of-the-box solutions also make it difficult to understand your borrower in real-time. Meanwhile, on the operational front, this level of personalization makes all the difference for borrowers who want more than the traditional lending provided by a bank.`,
			`Canopy was founded by fintech and lending experts who saw – firsthand – the limitations created by working within an internal loan management system of record. From a product construct standpoint, inflexible rules and policies prevent quick changes and the launching of creative offerings. This is especially true within commercial lending.`,
			`Homegrown or out-of-the-box solutions also make it difficult to understand your borrower in real-time. Meanwhile, on the operational front, this level of personalization makes all the difference for borrowers who want more than the traditional lending provided by a bank.`,
		],
		cta: 'Ready to launch your next lending program? Reach out to see how we can help.',
		ctaLink: '/contact',
	},
	culture: {
		title: 'One modern lending core. **Six core values.**',
		description: '',
		culture: [
			{
				title: 'Always learning.',
				description:
					'We can all do better. We strive to improve without getting defensive. As we grow, we “give away our legos” and empower teammates with ownership and responsibility.',
				img: 'https://supahub.com/assets/feedback-boards/feedback-board-privacy-supahub.png',
			},
			{
				title: 'Always learning.',
				description:
					'We can all do better. We strive to improve without getting defensive. As we grow, we “give away our legos” and empower teammates with ownership and responsibility.',
				img: 'https://supahub.com/assets/feedback-boards/feedback-board-privacy-supahub.png',
			},
			{
				title: 'Always learning.',
				description:
					'We can all do better. We strive to improve without getting defensive. As we grow, we “give away our legos” and empower teammates with ownership and responsibility.',
				img: 'https://supahub.com/assets/feedback-boards/feedback-board-privacy-supahub.png',
			},
			{
				title: 'Always learning.',
				description:
					'We can all do better. We strive to improve without getting defensive. As we grow, we “give away our legos” and empower teammates with ownership and responsibility.',
				img: 'https://supahub.com/assets/feedback-boards/feedback-board-privacy-supahub.png',
			},
			{
				title: 'Always learning.',
				description:
					'We can all do better. We strive to improve without getting defensive. As we grow, we “give away our legos” and empower teammates with ownership and responsibility.',
				img: 'https://supahub.com/assets/feedback-boards/feedback-board-privacy-supahub.png',
			},
			{
				title: 'Always learning.',
				description:
					'We can all do better. We strive to improve without getting defensive. As we grow, we “give away our legos” and empower teammates with ownership and responsibility.',
				img: 'https://supahub.com/assets/feedback-boards/feedback-board-privacy-supahub.png',
			},
		],
	},
	investors: {
		title: 'Backed by industry leaders.',
		investors: [
			{
				name: 'Alpha Wave',
				link: 'https://alphawave.com',
				linkLabel: 'alphawave.com',
				img: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/Checkout.com-svg-1.svg',
			},
			{
				name: 'Alpha Wave',
				link: 'https://alphawave.com',
				linkLabel: 'alphawave.com',
				img: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/Checkout.com-svg-1.svg',
			},
			{
				name: 'Alpha Wave',
				link: 'https://alphawave.com',
				linkLabel: 'alphawave.com',
				img: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/Checkout.com-svg-1.svg',
			},
			{
				name: 'Alpha Wave',
				link: 'https://alphawave.com',
				linkLabel: 'alphawave.com',
				img: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/Checkout.com-svg-1.svg',
			},
		],
	},
	careers: {
		badge: 'We’re hiring!',
		title: 'Careers at Canopy.',
		description:
			'Ready to join the financial product revolution? Consider joining us.',
	},
};
