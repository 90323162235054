import { FacetedFilter } from '@/components/element/inputs/FacetedFilter';
import InputText from '@/components/element/inputs/InputText';
import {
	EnterpriseApprovalStatus,
	EnterpriseOnboardingStage,
} from '@/features/dashboard/types/enterprise.types';

const EnterpriseFilters = ({
	search,
	filters,
	setSearch,
	setFilters,
}: {
	search: string;
	filters: {
		approvalStatus: string[];
		onboardingStatus: string[];
	};
	setSearch: any;
	setFilters: any;
}) => {
	return (
		<div className="flex items-center mt-10 space-x-4">
			<InputText
				value={search}
				setValue={setSearch as any}
				prepend={
					<i className="bi-search px-3 text-muted-foreground text-sm"></i>
				}
				placeholder="Search by project name or email"
				className="w-[250px] lg:w-[350px]"
			/>
			<FacetedFilter
				title="Approval Status"
				options={ApprovalStatus}
				selectedValues={filters.approvalStatus}
				setSelectedValues={(values: any) =>
					setFilters((p) => ({ ...p, approvalStatus: values }))
				}
			/>
			<FacetedFilter
				title="Onboarding Status"
				options={OnboardingStatus}
				selectedValues={filters.onboardingStatus}
				setSelectedValues={(values: any) =>
					setFilters((p) => ({ ...p, onboardingStatus: values }))
				}
			/>
		</div>
	);
};

export default EnterpriseFilters;

const ApprovalStatus = [
	{ value: EnterpriseApprovalStatus.Approved, label: 'Approved' },
	{ value: EnterpriseApprovalStatus.Rejected, label: 'Rejected' },
	{ value: EnterpriseApprovalStatus.Pending, label: 'Pending' },
];

const OnboardingStatus = [
	{ value: EnterpriseOnboardingStage.SignedUp, label: 'Sign Up' },
	{ value: EnterpriseOnboardingStage.AccountDetails, label: 'Account Details' },
	{ value: EnterpriseOnboardingStage.Community, label: 'Community Details' },
	{
		value: EnterpriseOnboardingStage.EmailConfirmationPending,
		label: 'Pending Email Confirmation',
	},
	{ value: EnterpriseOnboardingStage.Completed, label: 'Completed' },
];
