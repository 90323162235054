import DropzoneWithReposition from '@/components/element/dropzone/DropzoneWithReposition';
import Spinner from '@/components/element/loading/Spinner';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useEditCamapignMetadata } from '@/features/campaigns/hooks/report/useEditCamapignMetadata';

const QuestBannerUpdate = ({ open, setOpen }) => {
	const { details, errors, setDetails, updateBanner, isLoading } =
		useEditCamapignMetadata(setOpen);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[825px] p-0">
				<div className="p-1">
					<div className="px-2 pt-2">
						{open && (
							<DropzoneWithReposition
								file={details.bannerFile}
								fileLink={details.banner}
								clearSelection={() => {
									setDetails((p) => ({
										...p,
										bannerFile: null,
										banner: '',
									}));
								}}
								setFile={(file: File | File[], fileLink: string) => {
									setDetails((prev) => ({
										...prev,
										bannerFile: file,
										banner: fileLink,
									}));
								}}
								cta="Upload banner"
								description="Recommended size: 1920x1080px"
								aspectRatio={1920 / 1080}
								error={errors?.banner}
								errorText="Please upload a valid image or video file."
								onImageReposition={({ x, y }) => {
									setDetails((prev) => ({
										...prev,
										bannerPosition: { x, y },
									}));
								}}
								bannerPosition={details.bannerPosition}
								allowedFiles={['images']}
								maxSize={3}
							/>
						)}
					</div>
				</div>
				<div className="px-4 mb-4 text-end space-x-2">
					<Button
						onClick={() => {
							setDetails((p) => ({
								...p,
								bannerFile: null,
								banner: '',
							}));
						}}
						variant="outline"
					>
						<i className="bi-archive me-2"></i> Clear Selection
					</Button>
					<Button onClick={() => updateBanner()} disabled={isLoading}>
						{isLoading && <Spinner className="me-2" />}
						<span>Update Banner</span>
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default QuestBannerUpdate;
