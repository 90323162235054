import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { useGetNFTs } from '@/features/nft-contracts/hooks/useGetNFTs';
import { NFT } from './NFT';

export const ExploreNFTsDialog = ({ open, setOpen, setValue }) => {
	const { nfts } = useGetNFTs();
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="min-w-[700px]">
				<DialogHeader>
					<DialogTitle>Select your NFT </DialogTitle>
					<DialogDescription>
						Select your NFT from the list of NFTs available on the
						network.
					</DialogDescription>
				</DialogHeader>
				<div className="max-h-[400px] overflow-auto">
					<div className="grid grid-cols-4 gap-1">
						{nfts?.map((i) => (
							<NFT
								image={i.imageUrl}
								title={i.name}
								key={i._id}
								chainId={i.chainId}
								setOpen={() => {
									setValue(i._id);
									setOpen(false);
								}}
							/>
						))}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};
