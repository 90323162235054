import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
	getPoWTaskSubmissions,
	getPoWTaskSubmissionsCount,
} from '../../services/campaigns.service';
import { IQuestPOWSubmissions, POWVerifyStatus } from '../../types';

export const useCampaignSubmissions = ({ campaign }) => {
	const [rowSelection, setRowSelection] = useState({});
	const [selectedTaskId, setSelectedTaskId] = useState<string>('');
	const [cursor, setCursor] = useState('');
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});
	const [statusFilters, setStatusFilters] = useState([
		POWVerifyStatus.Initiated,
		POWVerifyStatus.Verified,
		POWVerifyStatus.Failed,
	]);
	const [sorting, setSorting] = useState([]);
	const [selectedRow, setSelectedRow] = useState<IQuestPOWSubmissions>();

	const powTasks = useMemo(() => {
		if (!campaign || !campaign?.quest || !campaign?.quest?.tasks) return [];
		return campaign.quest.tasks.filter(
			(task) => task?.powVerifyConfig?.isPOWVerify,
		);
	}, [campaign]);

	const queryKey = useMemo(() => {
		return [
			'campaign',
			'approvals',
			selectedTaskId,
			pagination.pageIndex,
			pagination.pageSize,
			sorting,
			statusFilters,
		];
	}, [
		selectedTaskId,
		pagination.pageIndex,
		pagination.pageSize,
		sorting,
		statusFilters,
	]);

	const { data, isLoading } = useQuery<{
		submissions: IQuestPOWSubmissions[];
		count: number;
		nextCursor: string;
		limit: number;
	}>({
		queryKey: queryKey,
		queryFn: () =>
			getPoWTaskSubmissions({
				campaignId: campaign?._id,
				taskId: selectedTaskId,
				page: pagination.pageIndex,
				limit: pagination.pageSize,
				sorting,
				statusFilters,
				cursor: cursor,
			}),
		enabled: !!campaign && powTasks?.length > 0 && !!selectedTaskId.length,
	});

	useEffect(() => {
		if (data) {
			setCursor(data.nextCursor);
		}
	}, [data]);

	useEffect(() => {
		setCursor('');
	}, [selectedTaskId, statusFilters, sorting]);

	const { data: count, isLoading: isCountLoading } = useQuery({
		queryKey: ['campaign', 'approvals', selectedTaskId, statusFilters],
		queryFn: () =>
			getPoWTaskSubmissionsCount({
				campaignId: campaign?._id,
				taskId: selectedTaskId,
				statusFilters,
			}),
		enabled: !!campaign && powTasks?.length > 0 && !!selectedTaskId.length,
	});

	useEffect(() => {
		//Taking user to the first unverified submission when a new page is loaded
		if (!data?.submissions || data?.submissions.length === 0) return;
		const selected = data.submissions?.find(
			(sub) => sub._id === selectedRow?._id,
		);
		if (!selected) {
			const firstUnverifiedSubmission = data.submissions.find(
				(i) =>
					i.status === POWVerifyStatus.Initiated ||
					i.status === POWVerifyStatus.Failed,
			);
			if (!firstUnverifiedSubmission) {
				setSelectedRow(data.submissions[0]);
				return;
			}
			setSelectedRow(firstUnverifiedSubmission);
		} else {
			setSelectedRow(selected);
		}
	}, [data, selectedRow?._id]);

	useEffect(() => {
		//Setting the first task as selected task
		if (!selectedTaskId && powTasks?.length > 0) {
			setSelectedTaskId(powTasks[0]._id);
		}
	}, [powTasks]);

	const handleNext = () => {
		const selectedRowIndex = data.submissions.findIndex(
			(submisison) => submisison._id === selectedRow._id,
		);
		if (selectedRowIndex === data.submissions.length - 1) {
			if (data.submissions.length < pagination.pageSize) return;
			setPagination((prev) => ({
				...prev,
				pageIndex: prev.pageIndex + 1,
			}));
			return;
		}
		const nextRow = data.submissions[selectedRowIndex + 1];
		if (nextRow) {
			setSelectedRow(nextRow);
		}
	};

	const handlePrevious = () => {
		const selectedRowIndex = data.submissions.findIndex(
			(submisison) => submisison._id === selectedRow._id,
		);
		if (selectedRowIndex === 0) {
			if (pagination.pageIndex === 0) return;
			setPagination((prev) => ({
				...prev,
				pageIndex: prev.pageIndex - 1,
			}));
			return;
		}
		const prevRow = data.submissions[selectedRowIndex - 1];
		if (prevRow) {
			setSelectedRow(prevRow);
		}
	};

	return {
		isLoading,
		powTasks,
		setSelectedTaskId,
		selectedTaskId,
		subissions: data?.submissions ?? [],
		pagination,
		setPagination,
		sorting,
		setSorting,
		isCountLoading,
		totalCount: count ?? 10,
		rowSelection,
		setRowSelection,
		selectedRow,
		setSelectedRow,
		handleNext,
		handlePrevious,
		statusFilters,
		setStatusFilters,
		queryKey,
		setCursor,
	};
};

// const handleReview = async (isApproved: boolean) => {
// 	if (!selectedRow) return;
// 	try {
// 		setIsActionLoading((prev) => ({
// 			...prev,
// 			[isApproved ? 'approval' : 'reject']: true,
// 		}));
// 		await updateSubmissionStatus(id, {
// 			results: [
// 				{
// 					submissionId: selectedRow?._id,
// 					status: isApproved
// 						? POWVerifyStatus.Verified
// 						: POWVerifyStatus.Failed,
// 				},
// 			],
// 			taskId: selectedTaskId,
// 		});
// 		const data = queryClient.getQueryData([
// 			'campaign',
// 			'approvals',
// 			selectedTaskId,
// 			pagination.pageIndex,
// 			pagination.pageSize,
// 			sorting,
// 			statusFilters,
// 		]) as {
// 			submissions: IQuestPOWSubmissions[];
// 			count: number;
// 		};
// 		console.log('data', data);
// 		if (data) {
// 			queryClient.setQueryData(
// 				[
// 					'campaign',
// 					'approvals',
// 					selectedTaskId,
// 					pagination.pageIndex,
// 					pagination.pageSize,
// 					sorting,
// 					statusFilters,
// 				],
// 				{
// 					...data,
// 					submissions: data.submissions.map(
// 						(submission: IQuestPOWSubmissions) => {
// 							if (submission._id === selectedRow._id) {
// 								return {
// 									...submission,
// 									status: isApproved
// 										? POWVerifyStatus.Verified
// 										: POWVerifyStatus.Failed,
// 								};
// 							}
// 							return submission;
// 						},
// 					),
// 				},
// 			);
// 		}
// 		handleNext();
// 		setIsActionLoading((prev) => ({
// 			...prev,
// 			[isApproved ? 'approval' : 'reject']: false,
// 		}));
// 	} catch (err) {
// 		setIsActionLoading((prev) => ({
// 			...prev,
// 			[isApproved ? 'approval' : 'reject']: false,
// 		}));
// 		handleErrorMessage(err);
// 	}
// };

// const handleReviewAll = async (isApproved: boolean) => {
// 	try {
// 		setIsActionLoading((prev) => ({
// 			...prev,
// 			[isApproved ? 'approvalAll' : 'rejectAll']: true,
// 		}));
// 		await bulkUpdateSubmissionStatus(id, {
// 			taskId: selectedTaskId,
// 			action: isApproved
// 				? POWVerifyStatus.Verified
// 				: POWVerifyStatus.Failed,
// 		});
// 		queryClient.invalidateQueries({
// 			queryKey: [
// 				'campaign',
// 				'approvals',
// 				selectedTaskId,
// 				pagination.pageIndex,
// 				pagination.pageSize,
// 				sorting,
// 				statusFilters,
// 			],
// 		});
// 		setIsActionLoading((prev) => ({
// 			...prev,
// 			[isApproved ? 'approvalAll' : 'rejectAll']: false,
// 		}));
// 	} catch (err) {
// 		setIsActionLoading((prev) => ({
// 			...prev,
// 			[isApproved ? 'approvalAll' : 'rejectAll']: false,
// 		}));
// 		handleErrorMessage(err);
// 	}
// };

// const handleReviewSelected = async (ids: string[], isApproved: boolean) => {
// 	try {
// 		console.log('ids', ids);
// 		setIsActionLoading((prev) => ({
// 			...prev,
// 			[isApproved ? 'approvalSelected' : 'rejectSelected']: true,
// 		}));
// 		await updateSubmissionStatus(id, {
// 			results: ids.map((id) => ({
// 				submissionId: id,
// 				status: isApproved
// 					? POWVerifyStatus.Verified
// 					: POWVerifyStatus.Failed,
// 			})),
// 			taskId: selectedTaskId,
// 		});
// 		const data = queryClient.getQueryData([
// 			'campaign',
// 			'approvals',
// 			selectedTaskId,
// 			pagination.pageIndex,
// 			pagination.pageSize,
// 			sorting,
// 			statusFilters,
// 		]) as {
// 			submissions: IQuestPOWSubmissions[];
// 			count: number;
// 		};
// 		console.log('data', data);
// 		if (data) {
// 			queryClient.setQueryData(
// 				[
// 					'campaign',
// 					'approvals',
// 					selectedTaskId,
// 					pagination.pageIndex,
// 					pagination.pageSize,
// 					sorting,
// 					statusFilters,
// 				],
// 				{
// 					...data,
// 					submissions: data.submissions.map(
// 						(submission: IQuestPOWSubmissions) => {
// 							if (ids.includes(submission._id)) {
// 								return {
// 									...submission,
// 									status: isApproved
// 										? POWVerifyStatus.Verified
// 										: POWVerifyStatus.Failed,
// 								};
// 							}
// 							return submission;
// 						},
// 					),
// 				},
// 			);
// 		}
// 		setIsActionLoading((prev) => ({
// 			...prev,
// 			[isApproved ? 'approvalSelected' : 'rejectSelected']: false,
// 		}));
// 	} catch (err) {
// 		setIsActionLoading((prev) => ({
// 			...prev,
// 			[isApproved ? 'approvalSelected' : 'rejectSelected']: false,
// 		}));
// 		handleErrorMessage(err);
// 	}
// };
