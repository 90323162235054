import ChainBadge from '@/components/element/badges/ChainBadge';
import { AdminInputType, ITaskAdminInput } from '@/features/campaigns/types';
import { axios } from '@/lib/axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const TaskAdminValue = ({ adminInput }: { adminInput: ITaskAdminInput }) => {
	if (!adminInput) return null;
	if (!adminInput.value) return null;
	if (
		adminInput.inputType === AdminInputType.InputAddress ||
		adminInput.inputType === AdminInputType.InputString ||
		adminInput.inputType === AdminInputType.InputNumber ||
		adminInput.inputType === AdminInputType.SelectFromAdminInput ||
		adminInput.inputType === AdminInputType.InputStringArray
	)
		return <>{adminInput.value?.toString()}</>;
	if (
		adminInput.inputType === AdminInputType.InputCheckbox ||
		adminInput.inputType === AdminInputType.InputSwitch
	)
		return <>{adminInput.value ? 'Yes' : 'No'}</>;
	if (adminInput.inputType === AdminInputType.TwitterTags)
		return <>{adminInput.value}</>;
	if (adminInput.inputType === AdminInputType.SelectNetwork) {
		const chainId = adminInput.value as number;
		return <ChainBadge chainId={chainId} className="" />;
	}
	if (adminInput.inputType === AdminInputType.InputDate) {
		return <>{dayjs(adminInput.value as Date).format('Do MMM YY hh:mmZ')}</>;
	}
	if (adminInput.inputType === AdminInputType.SelectAsync) {
		return <SelectAsyncValue adminInput={adminInput} />;
	}
	if (adminInput.inputType === AdminInputType.Select) {
		const options = adminInput.options;
		const label = options.find((i) => i.value === adminInput.value)?.label;
		return label;
	}
	return <>NA</>;
};

export default TaskAdminValue;

const SelectAsyncValue = ({ adminInput }: { adminInput: ITaskAdminInput }) => {
	const [options, setOptions] = useState([]);

	useEffect(() => {
		axios.get(adminInput.optionsFrom).then(({ data }) => {
			setOptions(
				data.map(
					(option: {
						label: string;
						description: string;
						value: string;
					}) => ({
						label: (
							<div className="">
								{option.label}{' '}
								<div>
									<div className="text-xs text-muted-foreground mt-1">
										{option.description}
									</div>
								</div>
							</div>
						),
						value: option.value,
						name: option.label,
					}),
				),
			);
		});
	}, [adminInput.optionsFrom]);

	const selected = (options ?? []).find((i) => i?.value === adminInput?.value);
	return <>{selected?.name}</>;
};
