import { axios } from '@/lib/axios';

export const submitAnnouncementScreenshot = async (body: any): Promise<any> => {
	const response = await axios.post(
		'/enterprise/approval/announcement-screenshot',
		body,
	);
	return response.data;
};

export const approvalDiscordCallback = async (body: any): Promise<any> => {
	const response = await axios.post('/enterprise/approval/discord/callback', body);
	return response.data;
};

export const approvalTwitterCallback = async (body: any): Promise<any> => {
	const response = await axios.post('/enterprise/approval/twitter/callback', body);
	return response.data;
};
