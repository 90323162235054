import { toast } from 'sonner';

export const handleErrorMessage = (
	err: any,
	message = 'Something went wrong, can you try again!',
) => {
	if (typeof err === 'string') return toast.error(err);
	if (Array.isArray(err?.response?.data?.data) && err?.response?.data?.data[0]) {
		toast.error(err.response.data?.data[0], {
			duration: 1000,
		});
	} else if (err?.response?.data?.message)
		toast.error(err.response.data.message, {
			duration: 1000,
		});
	else if (err?.data?.message)
		toast.error(err.data.message, {
			duration: 1000,
		});
	else if (err?.message)
		toast.error(err.message, {
			duration: 1000,
		});
	else if (typeof err === 'string')
		toast.error(err, {
			duration: 1000,
		});
	else
		toast.error(message, {
			duration: 1000,
		});
};

interface IData {
	code: ErrorTypes;
	message?: string;
	after?: () => any;
}
export const handleErrorMessageWithCode = (err: any, data: IData[]) => {
	const errCode = err?.response?.data?.code || err?.data?.code || err?.code;
	const errMessage =
		err?.response?.data?.message ||
		err?.data?.message ||
		err?.message ||
		'Something went wrong, can you try again!';
	for (let i = 0; i < data.length; i++) {
		const { code, message, after } = data[i];
		if (code === errCode) {
			toast.error(message || errMessage, {
				duration: 1000,
			});
			after?.();
			return;
		}
	}
};

export enum ErrorTypes {
	IncorrectEmail = 'INCORRECT_EMAIL',
	IncorrectPassword = 'INCORRECT_PASSWORD',
	UserAlreadyExists = 'USER_ALREADY_EXISTS',
	NoCodeProvided = 'NO_CODE_PROVIDED',
	InvalidHash = 'INVALID_HASH',
	UserNotFound = 'USER_NOT_FOUND',
	SubdomainAlreadyExists = 'SUBDOMAIN_ALREADY_EXISTS',
	InvalidToken = 'INVALID_TOKEN',
	IncorrectChain = 'INCORRECT_CHAIN',
	IncorrectTokenType = 'INCORRECT_TOKEN_TYPE',
	InvalidTelegram = 'INVALID_TELEGRAM',
	LoggedOut = 'LOGGED_OUT',
	MissingFields = 'MISSING_FIELDS',
	MissingHeaders = 'MISSING_HEADERS',
	InvalidFormBody = 'INVALID_FORM_BODY',
	InvalidCode = 'INVALID_CODE',
	AccessMissing = 'ACCESS_MISSING',
	ResourceMissing = 'RESOURCE_MISSING',
	AlreadyIntegrated = 'ALREADY_INTEGRATED',
	ResourceAlreadyExists = 'RESOURCE_ALREADY_EXISTS',
	ServiceIssue = 'SERVICE_ISSUE',
	UnknownMember = 'UNKNOWN_MEMBER',
	MissingPermissions = 'MISSING_PERMISSIONS',
	TypesMismatch = 'TYPES_MISMATCH',
	TypesMissing = 'TYPES_MISSING',
	ServerError = 'SERVER_ERROR',
	GeneralError = 'GENERAL_ERROR',
	ChannelPermissionIssue = 'CHANNEL_PERMISSION_ISSUE',
	TaskFailed = 'TASK_FAILED',
	TaskAlreadyDone = 'TASK_ALREADY_DONE',
	NotActive = 'NOT_ACTIVE',
	LoginError = 'LOGIN_ERROR',
	ReauthNeeded = 'REAUTH_NEEDED',
	Unauthorized = 'UNAUTHORIZED',
	InvalidCriteria = 'INVALID_CRITERIA',
	InvalidOperation = 'INVALID_OPERATION',
}
