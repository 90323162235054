import { Input } from '@/components/plate-ui/input';
import { Button } from '@/components/ui/button';
import { useConnectAPI } from '../hooks/useConnectAPI';

export const CustomHeaders = ({ items, setItems, index }) => {
	const { formErrors, formFields, setFormFields, setFormErrors } = useConnectAPI();

	const handleInputs = (e: any) => {
		const { name, value } = e.target;

		setFormErrors((prev: any) => ({
			...prev,
			[formFields?.apiHeaders[index][name]]: '',
		}));

		const tempApiHeaders = [...(formFields?.apiHeaders ?? [])];
		tempApiHeaders[index] = {
			...tempApiHeaders[index],
			[name]: value,
		};
		setFormFields((prev: any) => ({
			...prev,
			apiHeaders: tempApiHeaders,
		}));
	};

	const removeHeader = () => {
		if (items > 0) {
			setItems((prevItems: number) => prevItems - 1);
		}

		if (items <= 1) {
			setFormFields((prev: any) => ({
				...prev,
				isHeaderRequired: false,
			}));
		}

		setFormFields((prev: any) => ({
			...prev,
			apiHeaders: prev.apiHeaders.filter(
				(_: any, idx: number) => idx !== index,
			),
		}));
	};

	return (
		<div className="flex gap-2 mt-3">
			<div className="flex items-center flex-grow space-x-2 sm:space-x-0">
				<Input
					className={`h-9 `}
					name={'key'}
					value={formFields?.apiHeaders[index]?.key}
					onChange={handleInputs}
				/>
			</div>
			<div className="flex grow">
				<Input
					className={`h-9 ${
						formErrors[formFields?.apiHeaders[index]?.key]
							? 'border-destructive'
							: ''
					}`}
					name={'value'}
					value={formFields?.apiHeaders[index]?.value}
					onChange={handleInputs}
				/>
			</div>
			<div className="">
				<Button
					variant="outline"
					size="sm"
					className="w-9 h-9"
					onClick={removeHeader}
				>
					<i className="bi bi-x-lg"></i>
				</Button>
			</div>
		</div>
	);
};
