export const additionalChainsNotInDB = [
	{
		chainId: -10001,
		chainName: 'Ethereum',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_ethereum?w=48&h=48',
	},
	{
		chainId: -10002,
		chainName: 'Tron',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_tron?w=48&h=48',
	},
	{
		chainId: -10003,
		chainName: 'BSC',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_bsc?w=48&h=48',
	},
	{
		chainId: -10005,
		chainName: 'Arbitrum',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_arbitrum?w=48&h=48',
	},
	{
		chainId: -10006,
		chainName: 'Base',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_base?w=48&h=48',
	},
	{
		chainId: -10007,
		chainName: 'Blast',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_blast?w=48&h=48',
	},
	{
		chainId: -10009,
		chainName: 'Merlin',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_merlin?w=48&h=48',
	},
	{
		chainId: -10010,
		chainName: 'Avalanche',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_avalanche?w=48&h=48',
	},
	{
		chainId: -10011,
		chainName: 'Polygon',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_polygon?w=48&h=48',
	},
	{
		chainId: -10012,
		chainName: 'Optimism',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_optimism?w=48&h=48',
	},
	{
		chainId: -10014,
		chainName: 'Cronos',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_cronos?w=48&h=48',
	},
	{
		chainId: -10015,
		chainName: 'PulseChain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_pulsechain?w=48&h=48',
	},
	{
		chainId: -10018,
		chainName: 'Thorchain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_thorchain?w=48&h=48',
	},
	{
		chainId: -10019,
		chainName: 'Mode',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_mode?w=48&h=48',
	},
	{
		chainId: -10020,
		chainName: 'Mantle',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_mantle?w=48&h=48',
	},
	{
		chainId: -10021,
		chainName: 'Cardano',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_cardano?w=48&h=48',
	},
	{
		chainId: -10022,
		chainName: 'Gnosis',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_gnosis?w=48&h=48',
	},
	{
		chainId: -10023,
		chainName: 'Near',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_near?w=48&h=48',
	},
	{
		chainId: -10024,
		chainName: 'Linea',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_linea?w=48&h=48',
	},
	{
		chainId: -10025,
		chainName: 'Kava',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_kava?w=48&h=48',
	},
	{
		chainId: -10027,
		chainName: 'Rootstock',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_rootstock?w=48&h=48',
	},
	{
		chainId: -10028,
		chainName: 'Ronin',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_ronin?w=48&h=48',
	},
	{
		chainId: -10029,
		chainName: 'Osmosis',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_osmosis?w=48&h=48',
	},
	{
		chainId: -10030,
		chainName: 'Manta',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_manta?w=48&h=48',
	},
	{
		chainId: -10031,
		chainName: 'TON',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_ton?w=48&h=48',
	},
	{
		chainId: -10032,
		chainName: 'zkSync Era',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_zksync%20era?w=48&h=48',
	},
	{
		chainId: -10033,
		chainName: 'Stacks',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_stacks?w=48&h=48',
	},
	{
		chainId: -10034,
		chainName: 'WEMIX3.0',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_wemix3.0?w=48&h=48',
	},
	{
		chainId: -10035,
		chainName: 'MultiversX',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_multiversx?w=48&h=48',
	},
	{
		chainId: -10036,
		chainName: 'dYdX',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_dydx?w=48&h=48',
	},
	{
		chainId: -10037,
		chainName: 'Mixin',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_mixin?w=48&h=48',
	},
	{
		chainId: -10038,
		chainName: 'Fantom',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_fantom?w=48&h=48',
	},
	{
		chainId: -10039,
		chainName: 'EOS',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_eos?w=48&h=48',
	},
	{
		chainId: -10040,
		chainName: 'Celo',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_celo?w=48&h=48',
	},
	{
		chainId: -10041,
		chainName: 'MAP Protocol',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_map%20protocol?w=48&h=48',
	},
	{
		chainId: -10042,
		chainName: 'zkLink',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_zklink?w=48&h=48',
	},
	{
		chainId: -10043,
		chainName: 'Hedera',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_hedera?w=48&h=48',
	},
	{
		chainId: -10044,
		chainName: 'Fusion',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_fusion?w=48&h=48',
	},
	{
		chainId: -10045,
		chainName: 'Klaytn',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_klaytn?w=48&h=48',
	},
	{
		chainId: -10046,
		chainName: 'Manta Atlantic',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_manta%20atlantic?w=48&h=48',
	},
	{
		chainId: -10047,
		chainName: 'ICP',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_icp?w=48&h=48',
	},
	{
		chainId: -10048,
		chainName: 'Algorand',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_algorand?w=48&h=48',
	},
	{
		chainId: -10049,
		chainName: 'Kujira',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_kujira?w=48&h=48',
	},
	{
		chainId: -10050,
		chainName: 'DefiChain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_defichain?w=48&h=48',
	},
	{
		chainId: -10051,
		chainName: 'Karak Network',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_karak%20network?w=48&h=48',
	},
	{
		chainId: -10052,
		chainName: 'Metis',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_metis?w=48&h=48',
	},
	{
		chainId: -10053,
		chainName: 'Telos',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_telos?w=48&h=48',
	},
	{
		chainId: -10054,
		chainName: 'Tezos',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_tezos?w=48&h=48',
	},
	{
		chainId: -10055,
		chainName: 'Scroll',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_scroll?w=48&h=48',
	},
	{
		chainId: -10057,
		chainName: 'Filecoin',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_filecoin?w=48&h=48',
	},
	{
		chainId: -10058,
		chainName: 'Neutron',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_neutron?w=48&h=48',
	},
	{
		chainId: -10059,
		chainName: 'NEO',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_neo?w=48&h=48',
	},
	{
		chainId: -10060,
		chainName: 'Conflux',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_conflux?w=48&h=48',
	},
	{
		chainId: -10061,
		chainName: 'Moonbeam',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_moonbeam?w=48&h=48',
	},
	{
		chainId: -10062,
		chainName: 'Radix',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_radix?w=48&h=48',
	},
	{
		chainId: -10063,
		chainName: 'Astar',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_astar?w=48&h=48',
	},
	{
		chainId: -10064,
		chainName: 'Sei',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_sei?w=48&h=48',
	},
	{
		chainId: -10065,
		chainName: 'Canto',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_canto?w=48&h=48',
	},
	{
		chainId: -10066,
		chainName: 'FSC',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_fsc?w=48&h=48',
	},
	{
		chainId: -10067,
		chainName: 'Terra2',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_terra2?w=48&h=48',
	},
	{
		chainId: -10068,
		chainName: 'Mayachain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_mayachain?w=48&h=48',
	},
	{
		chainId: -10069,
		chainName: 'HydraDX',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_hydradx?w=48&h=48',
	},
	{
		chainId: -10070,
		chainName: 'IoTeX',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_iotex?w=48&h=48',
	},
	{
		chainId: -10071,
		chainName: 'Flow',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_flow?w=48&h=48',
	},
	{
		chainId: -10072,
		chainName: 'Aurora',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_aurora?w=48&h=48',
	},
	{
		chainId: -10073,
		chainName: 'opBNB',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_opbnb?w=48&h=48',
	},
	{
		chainId: -10074,
		chainName: 'Secret',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_secret?w=48&h=48',
	},
	{
		chainId: -10075,
		chainName: 'Heco',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_heco?w=48&h=48',
	},
	{
		chainId: -10076,
		chainName: 'Acala',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_acala?w=48&h=48',
	},
	{
		chainId: -10077,
		chainName: 'Oraichain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_oraichain?w=48&h=48',
	},
	{
		chainId: -10078,
		chainName: 'Bifrost Network',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_bifrost%20network?w=48&h=48',
	},
	{
		chainId: -10079,
		chainName: 'Zkfair',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_zkfair?w=48&h=48',
	},
	{
		chainId: -10080,
		chainName: 'Fraxtal',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_fraxtal?w=48&h=48',
	},
	{
		chainId: -10081,
		chainName: 'Venom',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_venom?w=48&h=48',
	},
	{
		chainId: -10082,
		chainName: 'CORE',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_core?w=48&h=48',
	},
	{
		chainId: -10083,
		chainName: 'Icon',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_icon?w=48&h=48',
	},
	{
		chainId: -10084,
		chainName: 'Waves',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_waves?w=48&h=48',
	},
	{
		chainId: -10085,
		chainName: 'Bitcoincash',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_bitcoincash?w=48&h=48',
	},
	{
		chainId: -10086,
		chainName: 'Parallel',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_parallel?w=48&h=48',
	},
	{
		chainId: -10087,
		chainName: 'Alephium',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_alephium?w=48&h=48',
	},
	{
		chainId: -10088,
		chainName: 'UX',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_ux?w=48&h=48',
	},
	{
		chainId: -10089,
		chainName: 'Ontology',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_ontology?w=48&h=48',
	},
	{
		chainId: -10090,
		chainName: 'Pego',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_pego?w=48&h=48',
	},
	{
		chainId: -10091,
		chainName: 'Polygon zkEVM',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_polygonZkevm?w=48&h=48',
	},
	{
		chainId: -10092,
		chainName: 'Bifrost',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_bifrost?w=48&h=48',
	},
	{
		chainId: -10093,
		chainName: 'Proton',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_proton?w=48&h=48',
	},
	{
		chainId: -10094,
		chainName: 'XDC',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_xdc?w=48&h=48',
	},
	{
		chainId: -10095,
		chainName: 'Flare',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_flare?w=48&h=48',
	},
	{
		chainId: -10096,
		chainName: 'Kardia',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_kardia?w=48&h=48',
	},
	{
		chainId: -10097,
		chainName: 'Beam',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_beam?w=48&h=48',
	},
	{
		chainId: -10098,
		chainName: 'Stellar',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_stellar?w=48&h=48',
	},
	{
		chainId: -10099,
		chainName: 'Carbon',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_carbon?w=48&h=48',
	},
	{
		chainId: -10100,
		chainName: 'smartBCH',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_smartbch?w=48&h=48',
	},
	{
		chainId: -10101,
		chainName: 'GodwokenV1',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_godwokenv1?w=48&h=48',
	},
	{
		chainId: -10102,
		chainName: 'Nuls',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_nuls?w=48&h=48',
	},
	{
		chainId: -10103,
		chainName: 'Moonriver',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_moonriver?w=48&h=48',
	},
	{
		chainId: -10104,
		chainName: 'Ergo',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_ergo?w=48&h=48',
	},
	{
		chainId: -10105,
		chainName: 'Archway',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_archway?w=48&h=48',
	},
	{
		chainId: -10106,
		chainName: 'Ultron',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_ultron?w=48&h=48',
	},
	{
		chainId: -10107,
		chainName: 'Meter',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_meter?w=48&h=48',
	},
	{
		chainId: -10108,
		chainName: 'Boba',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_boba?w=48&h=48',
	},
	{
		chainId: -10109,
		chainName: 'X Layer',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_x%20Layer?w=48&h=48',
	},
	{
		chainId: -10110,
		chainName: 'Wanchain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_wanchain?w=48&h=48',
	},
	{
		chainId: -10111,
		chainName: 'Astar zkEVM',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_astarZkevm?w=48&h=48',
	},
	{
		chainId: -10112,
		chainName: 'Persistence',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_persistence?w=48&h=48',
	},
	{
		chainId: -10113,
		chainName: 'Litecoin',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_litecoin?w=48&h=48',
	},
	{
		chainId: -10114,
		chainName: 'Interlay',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_interlay?w=48&h=48',
	},
	{
		chainId: -10115,
		chainName: 'Doge',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_doge?w=48&h=48',
	},
	{
		chainId: -10116,
		chainName: 'Dogechain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_dogechain?w=48&h=48',
	},
	{
		chainId: -10117,
		chainName: 'Vite',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_vite?w=48&h=48',
	},
	{
		chainId: -10118,
		chainName: 'Oasis Sapphire',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_oasis%20Sapphire?w=48&h=48',
	},
	{
		chainId: -10119,
		chainName: 'Horizen EON',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_horizen%20Eon?w=48&h=48',
	},
	{
		chainId: -10120,
		chainName: 'DFK',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_dfk?w=48&h=48',
	},
	{
		chainId: -10121,
		chainName: 'Theta',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_theta?w=48&h=48',
	},
	{
		chainId: -10122,
		chainName: 'Degen',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_degen?w=48&h=48',
	},
	{
		chainId: -10123,
		chainName: 'Vision',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_vision?w=48&h=48',
	},
	{
		chainId: -10124,
		chainName: 'ZetaChain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_zetachain?w=48&h=48',
	},
	{
		chainId: -10125,
		chainName: 'ShimmerEVM',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_shimmerevm?w=48&h=48',
	},
	{
		chainId: -10126,
		chainName: 'Elastos',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_elastos?w=48&h=48',
	},
	{
		chainId: -10127,
		chainName: 'ThunderCore',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_thundercore?w=48&h=48',
	},
	{
		chainId: -10128,
		chainName: 'Harmony',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_harmony?w=48&h=48',
	},
	{
		chainId: -10129,
		chainName: 'Oasys',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_oasys?w=48&h=48',
	},
	{
		chainId: -10130,
		chainName: 'FunctionX',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_functionx?w=48&h=48',
	},
	{
		chainId: -10131,
		chainName: 'Naka',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_naka?w=48&h=48',
	},
	{
		chainId: -10132,
		chainName: 'Songbird',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_songbird?w=48&h=48',
	},
	{
		chainId: -10133,
		chainName: 'Zilliqa',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_zilliqa?w=48&h=48',
	},
	{
		chainId: -10134,
		chainName: 'Evmos',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_evmos?w=48&h=48',
	},
	{
		chainId: -10135,
		chainName: 'DeFiVerse',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_defiverse?w=48&h=48',
	},
	{
		chainId: -10136,
		chainName: 'OKTChain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_oktchain?w=48&h=48',
	},
	{
		chainId: -10137,
		chainName: 'Shibarium',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_shibarium?w=48&h=48',
	},
	{
		chainId: -10138,
		chainName: 'Terra Classic',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_terra%20Classic?w=48&h=48',
	},
	{
		chainId: -10139,
		chainName: 'Everscale',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_everscale?w=48&h=48',
	},
	{
		chainId: -10140,
		chainName: 'EOS EVM',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_eos%20Evm?w=48&h=48',
	},
	{
		chainId: -10141,
		chainName: 'Zora',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_zora?w=48&h=48',
	},
	{
		chainId: -10142,
		chainName: 'Velas',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_velas?w=48&h=48',
	},
	{
		chainId: -10143,
		chainName: 'Arbitrum Nova',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_arbitrum%20Nova?w=48&h=48',
	},
	{
		chainId: -10144,
		chainName: 'Immutable zkEVM',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_immutable%20Zkevm?w=48&h=48',
	},
	{
		chainId: -10145,
		chainName: 'SXnetwork',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_sxnetwork?w=48&h=48',
	},
	{
		chainId: -10146,
		chainName: 'XPLA',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_xpla?w=48&h=48',
	},
	{
		chainId: -10147,
		chainName: 'Karura',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_karura?w=48&h=48',
	},
	{
		chainId: -10148,
		chainName: 'KCC',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_kcc?w=48&h=48',
	},
	{
		chainId: -10149,
		chainName: 'Milkomeda C1',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_milkomeda%20c1?w=48&h=48',
	},
	{
		chainId: -10150,
		chainName: 'Equilibrium',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_equilibrium?w=48&h=48',
	},
	{
		chainId: -10151,
		chainName: 'Hydra',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_hydra?w=48&h=48',
	},
	{
		chainId: -10152,
		chainName: 'Fuse',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_fuse?w=48&h=48',
	},
	{
		chainId: -10153,
		chainName: 'Bostrom',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_bostrom?w=48&h=48',
	},
	{
		chainId: -10154,
		chainName: 'Migaloo',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_migaloo?w=48&h=48',
	},
	{
		chainId: -10155,
		chainName: 'SatoshiVM',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_satoshivm?w=48&h=48',
	},
	{
		chainId: -10157,
		chainName: 'MVC',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_mvc?w=48&h=48',
	},
	{
		chainId: -10158,
		chainName: 'Oasis Emerald',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_oasis%20emerald?w=48&h=48',
	},
	{
		chainId: -10159,
		chainName: 'Kadena',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_kadena?w=48&h=48',
	},
	{
		chainId: -10160,
		chainName: 'Wax',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_wax?w=48&h=48',
	},
	{
		chainId: -10161,
		chainName: 'Elysium',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_elysium?w=48&h=48',
	},
	{
		chainId: -10162,
		chainName: 'Rollux',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_rollux?w=48&h=48',
	},
	{
		chainId: -10163,
		chainName: 'Onus',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_onus?w=48&h=48',
	},
	{
		chainId: -10164,
		chainName: 'Neon',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_neon?w=48&h=48',
	},
	{
		chainId: -10165,
		chainName: 'Heiko',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_heiko?w=48&h=48',
	},
	{
		chainId: -10166,
		chainName: 'Obyte',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_obyte?w=48&h=48',
	},
	{
		chainId: -10167,
		chainName: 'VeChain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_vechain?w=48&h=48',
	},
	{
		chainId: -10168,
		chainName: 'Grove',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_grove?w=48&h=48',
	},
	{
		chainId: -10169,
		chainName: 'RENEC',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_renec?w=48&h=48',
	},
	{
		chainId: -10170,
		chainName: 'Juno',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_juno?w=48&h=48',
	},
	{
		chainId: -10171,
		chainName: 'Energi',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_energi?w=48&h=48',
	},
	{
		chainId: -10172,
		chainName: 'Rangers',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_rangers?w=48&h=48',
	},
	{
		chainId: -10173,
		chainName: 'Chihuahua',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_chihuahua?w=48&h=48',
	},
	{
		chainId: -10174,
		chainName: 'Kroma',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_kroma?w=48&h=48',
	},
	{
		chainId: -10175,
		chainName: 'BEVM',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_bevm?w=48&h=48',
	},
	{
		chainId: -10176,
		chainName: 'Nolus',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_nolus?w=48&h=48',
	},
	{
		chainId: -10177,
		chainName: 'HAQQ',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_haqq?w=48&h=48',
	},
	{
		chainId: -10178,
		chainName: 'Starcoin',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_starcoin?w=48&h=48',
	},
	{
		chainId: -10179,
		chainName: 'Chiliz',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_chiliz?w=48&h=48',
	},
	{
		chainId: -10180,
		chainName: 'ENULS',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_enuls?w=48&h=48',
	},
	{
		chainId: -10181,
		chainName: 'Xai',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_xai?w=48&h=48',
	},
	{
		chainId: -10182,
		chainName: 'Comdex',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_comdex?w=48&h=48',
	},
	{
		chainId: -10183,
		chainName: 'EthereumClassic',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_ethereumclassic?w=48&h=48',
	},
	{
		chainId: -10184,
		chainName: 'Concordium',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_concordium?w=48&h=48',
	},
	{
		chainId: -10185,
		chainName: 'NOS',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_nos?w=48&h=48',
	},
	{
		chainId: -10186,
		chainName: 'Dash',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_dash?w=48&h=48',
	},
	{
		chainId: -10187,
		chainName: 'Step',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_step?w=48&h=48',
	},
	{
		chainId: -10188,
		chainName: 'Bitrock',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_bitrock?w=48&h=48',
	},
	{
		chainId: -10189,
		chainName: 'Crab',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_crab?w=48&h=48',
	},
	{
		chainId: -10190,
		chainName: 'BSquared',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_bsquared?w=48&h=48',
	},
	{
		chainId: -10191,
		chainName: 'Bittorrent',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_bittorrent?w=48&h=48',
	},
	{
		chainId: -10192,
		chainName: 'Viction',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_viction?w=48&h=48',
	},
	{
		chainId: -10193,
		chainName: 'Q Protocol',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_q%20protocol?w=48&h=48',
	},
	{
		chainId: -10194,
		chainName: 'Boba_Bnb',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_boba_bnb?w=48&h=48',
	},
	{
		chainId: -10195,
		chainName: 'Aura Network',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_aura%20network?w=48&h=48',
	},
	{
		chainId: -10196,
		chainName: 'Kintsugi',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_kintsugi?w=48&h=48',
	},
	{
		chainId: -10197,
		chainName: 'inEVM',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_inevm?w=48&h=48',
	},
	{
		chainId: -10198,
		chainName: 'EnergyWeb',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_energyweb?w=48&h=48',
	},
	{
		chainId: -10199,
		chainName: 'Loop',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_loop?w=48&h=48',
	},
	{
		chainId: -10200,
		chainName: 'Libre',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_libre?w=48&h=48',
	},
	{
		chainId: -10201,
		chainName: 'LightLink',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_lightlink?w=48&h=48',
	},
	{
		chainId: -10202,
		chainName: 'Nahmii',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_nahmii?w=48&h=48',
	},
	{
		chainId: -10203,
		chainName: 'Tombchain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_tombchain?w=48&h=48',
	},
	{
		chainId: -10204,
		chainName: 'CSC',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_csc?w=48&h=48',
	},
	{
		chainId: -10205,
		chainName: 'Sora',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_sora?w=48&h=48',
	},
	{
		chainId: -10206,
		chainName: 'MEER',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_meer?w=48&h=48',
	},
	{
		chainId: -10207,
		chainName: 'Omax',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_omax?w=48&h=48',
	},
	{
		chainId: -10208,
		chainName: 'Ancient8',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_ancient8?w=48&h=48',
	},
	{
		chainId: -10209,
		chainName: 'Stargaze',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_stargaze?w=48&h=48',
	},
	{
		chainId: -10210,
		chainName: 'Europa',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_europa?w=48&h=48',
	},
	{
		chainId: -10211,
		chainName: 'EthereumPoW',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_ethereumpow?w=48&h=48',
	},
	{
		chainId: -10212,
		chainName: 'Bahamut',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_bahamut?w=48&h=48',
	},
	{
		chainId: -10213,
		chainName: 'Godwoken',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_godwoken?w=48&h=48',
	},
	{
		chainId: -10214,
		chainName: 'OntologyEVM',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_ontologyevm?w=48&h=48',
	},
	{
		chainId: -10215,
		chainName: 'ALV',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_alv?w=48&h=48',
	},
	{
		chainId: -10216,
		chainName: 'Newton',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_newton?w=48&h=48',
	},
	{
		chainId: -10217,
		chainName: 'Shiden',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_shiden?w=48&h=48',
	},
	{
		chainId: -10218,
		chainName: 'ETHF',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_ethf?w=48&h=48',
	},
	{
		chainId: -10219,
		chainName: 'Crescent',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_crescent?w=48&h=48',
	},
	{
		chainId: -10220,
		chainName: 'RSS3',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_rss3?w=48&h=48',
	},
	{
		chainId: -10221,
		chainName: 'Syscoin',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_syscoin?w=48&h=48',
	},
	{
		chainId: -10222,
		chainName: 'Findora',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_findora?w=48&h=48',
	},
	{
		chainId: -10223,
		chainName: 'Ubiq',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_ubiq?w=48&h=48',
	},
	{
		chainId: -10224,
		chainName: 'Tenet',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_tenet?w=48&h=48',
	},
	{
		chainId: -10225,
		chainName: 'JBC',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_jbc?w=48&h=48',
	},
	{
		chainId: -10226,
		chainName: 'Goerli',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_goerli?w=48&h=48',
	},
	{
		chainId: -10227,
		chainName: 'REI',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_rei?w=48&h=48',
	},
	{
		chainId: -10228,
		chainName: 'Areon Network',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_areon%20network?w=48&h=48',
	},
	{
		chainId: -10229,
		chainName: 'HPB',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_hpb?w=48&h=48',
	},
	{
		chainId: -10230,
		chainName: 'MultiVAC',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_multivac?w=48&h=48',
	},
	{
		chainId: -10231,
		chainName: 'Lachain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_lachain?w=48&h=48',
	},
	{
		chainId: -10232,
		chainName: 'Milkomeda A1',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_milkomeda%20a1?w=48&h=48',
	},
	{
		chainId: -10233,
		chainName: 'Callisto',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_callisto?w=48&h=48',
	},
	{
		chainId: -10234,
		chainName: 'Planq',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_planq?w=48&h=48',
	},
	{
		chainId: -10235,
		chainName: 'Cube',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_cube?w=48&h=48',
	},
	{
		chainId: -10236,
		chainName: 'Darwinia',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_darwinia?w=48&h=48',
	},
	{
		chainId: -10237,
		chainName: 'GoChain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_gochain?w=48&h=48',
	},
	{
		chainId: -10238,
		chainName: 'Sifchain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_sifchain?w=48&h=48',
	},
	{
		chainId: -10239,
		chainName: 'Bitgert',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_bitgert?w=48&h=48',
	},
	{
		chainId: -10240,
		chainName: 'Bitnet',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_bitnet?w=48&h=48',
	},
	{
		chainId: -10241,
		chainName: 'REIchain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_reichain?w=48&h=48',
	},
	{
		chainId: -10242,
		chainName: 'Lung',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_lung?w=48&h=48',
	},
	{
		chainId: -10243,
		chainName: 'Palm',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_palm?w=48&h=48',
	},
	{
		chainId: -10244,
		chainName: 'AirDAO',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_airdao?w=48&h=48',
	},
	{
		chainId: -10245,
		chainName: 'DSC',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_dsc?w=48&h=48',
	},
	{
		chainId: -10246,
		chainName: 'Bitkub',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_bitkub?w=48&h=48',
	},
	{
		chainId: -10247,
		chainName: 'Boba_Avax',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_boba_avax?w=48&h=48',
	},
	{
		chainId: -10248,
		chainName: 'zkSync Lite',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_zksync%20lite?w=48&h=48',
	},
	{
		chainId: -10249,
		chainName: 'Celestia',
		chainLogo: 'https://defillama.com/placeholder.png',
	},
	{
		chainId: -10250,
		chainName: 'Dymension',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_dymension?w=48&h=48',
	},
	{
		chainId: -10251,
		chainName: 'Sommelier',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_sommelier?w=48&h=48',
	},
	{
		chainId: -10252,
		chainName: 'Stride',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_stride?w=48&h=48',
	},
	{
		chainId: -10253,
		chainName: 'Dexit',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_dexit?w=48&h=48',
	},
	{
		chainId: -10254,
		chainName: 'Polkadot',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_polkadot?w=48&h=48',
	},
	{
		chainId: -10255,
		chainName: 'Kusama',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_kusama?w=48&h=48',
	},
	{
		chainId: -10256,
		chainName: 'Stafi',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_stafi?w=48&h=48',
	},
	{
		chainId: -10257,
		chainName: 'DeFiChain EVM',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_defichain%20Evm?w=48&h=48',
	},
	{
		chainId: -10258,
		chainName: 'Nibiru',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_nibiru?w=48&h=48',
	},
	{
		chainId: -10259,
		chainName: 'Empire',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_empire?w=48&h=48',
	},
	{
		chainId: -10260,
		chainName: 'Quicksilver',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_quicksilver?w=48&h=48',
	},
	{
		chainId: -10261,
		chainName: 'Hoo',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_hoo?w=48&h=48',
	},
	{
		chainId: -10262,
		chainName: 'Echelon',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_echelon?w=48&h=48',
	},
	{
		chainId: -10263,
		chainName: 'CLV',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_clv?w=48&h=48',
	},
	{
		chainId: -10264,
		chainName: 'Pokt',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_pokt?w=48&h=48',
	},
	{
		chainId: -10265,
		chainName: 'CMP',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_cmp?w=48&h=48',
	},
	{
		chainId: -10266,
		chainName: 'Nova Network',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_nova%20Network?w=48&h=48',
	},
	{
		chainId: -10267,
		chainName: 'Genshiro',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_genshiro?w=48&h=48',
	},
	{
		chainId: -10268,
		chainName: 'Lamden',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_lamden?w=48&h=48',
	},
	{
		chainId: -10269,
		chainName: 'Polis',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_polis?w=48&h=48',
	},
	{
		chainId: -10270,
		chainName: 'ZYX',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_zyx?w=48&h=48',
	},
	{
		chainId: -10271,
		chainName: 'Coti',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_coti?w=48&h=48',
	},
	{
		chainId: -10272,
		chainName: 'Kekchain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_kekchain?w=48&h=48',
	},
	{
		chainId: -10273,
		chainName: 'MUUCHAIN',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_muuchain?w=48&h=48',
	},
	{
		chainId: -10274,
		chainName: 'Tlchain',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_tlchain?w=48&h=48',
	},
	{
		chainId: -10275,
		chainName: 'Zeniq',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_zeniq?w=48&h=48',
	},
	{
		chainId: -10276,
		chainName: 'Bitindi',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_bitindi?w=48&h=48',
	},
	{
		chainId: -10277,
		chainName: 'Bone',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_bone?w=48&h=48',
	},
	{
		chainId: -10278,
		chainName: 'LaChain Network',
		chainLogo:
			'https://icons.llamao.fi/icons/chains/rsz_lachain%20Network?w=48&h=48',
	},
	{
		chainName: 'Solana',
		chainLogo: 'https://upload.wikimedia.org/wikipedia/en/b/b9/Solana_logo.png',
		chainId: -10279,
	},
	{
		chainName: 'Bitcoin',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_bitcoin?w=48&h=48',
		chainId: -10280,
	},
	{
		chainName: 'Sui',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_sui?w=48&h=48',
		chainId: -10281,
	},
	{
		// sui, aptos, sei
		chainName: 'Hyperliquid',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_hyperliquid?w=48&h=48',
		chainId: -10282,
	},
	{
		chainName: 'Aptos',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_aptos?w=48&h=48',
		chainId: -10283,
	},
	{
		chainName: 'Starknet',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_starknet?w=48&h=48',
		chainId: -10284,
	},
	{
		chainName: 'Injective',
		chainLogo: 'https://icons.llamao.fi/icons/chains/rsz_injective?w=48&h=48',
		chainId: -10285,
	},
	{
		chainName: 'Cosmos',
		chainLogo:
			'https://oss.web3ite.io/logo/b1d031475299801e979dba921db0206c.jpg?x-oss-process=image/resize,w_800/quality,q_80/format,webp',
		chainId: -10286,
	},
];
