import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import clsx from 'clsx';
import InputGroup from './InputGroup';

interface InputTextProps<T extends string | number>
	extends React.InputHTMLAttributes<HTMLInputElement> {
	labelClassName?: string;
	inputClassName?: string;
	className?: string;
	label?: string;
	required?: boolean;
	tooltip?: string;
	error?: boolean | string;
	setValue: (value: T) => void;
	value: T;
	errorText?: string | boolean;
	placeholder: string;
	subLabel?: string;
	subLabelClassName?: string;
	prepend?: string | JSX.Element;
	append?: string | JSX.Element;
	type?: T extends number ? 'number' : 'text';
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	wrapperClassName?: string;
}

const InputText = <T extends string | number>({
	labelClassName,
	className,
	placeholder,
	label,
	required,
	tooltip,
	error,
	value,
	setValue,
	wrapperClassName,
	errorText,
	subLabel,
	subLabelClassName,
	inputClassName,
	prepend,
	append,
	type,
	onKeyDown,
	...props
}: InputTextProps<T>) => {
	return (
		<div className={className}>
			{label && (
				<Label className={clsx('flex items-center mb-2', labelClassName)}>
					<div className="flex flex-col">
						<div className="flex">
							{label}
							{tooltip && (
								<TooltipProvider delayDuration={0}>
									<Tooltip>
										<TooltipTrigger className="ms-2">
											<i className="bi-info-circle-fill block text-muted-foreground text-xs cursor-pointer"></i>
										</TooltipTrigger>
										<TooltipContent>
											<div className="text-sm font-normal max-w-[400px]">
												{tooltip}
											</div>
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							)}
							{required && (
								<span className="text-red-400 ms-1">*</span>
							)}
						</div>
						{subLabel ? (
							<p
								className={clsx(
									'text-xs text-muted-foreground',
									subLabelClassName,
								)}
							>
								{subLabel}
							</p>
						) : null}
					</div>
				</Label>
			)}
			{prepend || append ? (
				<InputGroup
					placeholder={placeholder}
					value={value as string}
					setValue={(e) => setValue(e as T)}
					className={cn(inputClassName)}
					error={!!error}
					prepend={prepend}
					append={append}
					type={type}
					inputClassName={inputClassName}
					onKeyDown={onKeyDown}
					wrapperClassName={wrapperClassName}
					{...props}
				/>
			) : (
				<Input
					placeholder={placeholder}
					autoCorrect="off"
					value={value}
					onChange={(e) => setValue(e.target.value as T)}
					className={cn(inputClassName, error ? 'border-destructive' : '')}
					type={type}
					{...props}
				/>
			)}
			{error && errorText && (
				<p className={cn('text-xs  text-destructive mt-1')}>{errorText}</p>
			)}
		</div>
	);
};

export default InputText;
