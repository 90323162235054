import GlobalLayout from '@/components/layout/GlobalLayout';
import LandingRoutes from '@/features/landing/routes/landing.route';
import { Navigate } from 'react-router-dom';

export const publicRoutes = [
	{
		path: '/*',
		element: (
			<GlobalLayout>
				<LandingRoutes />
			</GlobalLayout>
		),
	},
	{
		path: '*',
		element: <Navigate_ />,
	},
];

function Navigate_() {
	return <Navigate to={`/${window.location.search}`} />;
}
