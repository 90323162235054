import { Card, CardContent, CardHeader } from '@/components/ui/card';

const ActionExamples = () => {
	return (
		<div className="mt-5">
			<Card className="shadow-none">
				<CardHeader className="border-b p-4">
					<div>
						<div className="text-sm font-medium mb-1">
							Swapping on Uniswap
						</div>
						<div className="text-xs text-muted-foreground">
							Uniswap is a decentralized exchange that allows users to
							swap tokens directly from their wallets.
						</div>
						<div className="mt-8 font-medium text-xs">
							3 Actions Required
						</div>
					</div>
				</CardHeader>
				<CardContent className=" p-0">
					<ActionItem />
					<ActionItem />
				</CardContent>
			</Card>
		</div>
	);
};

export default ActionExamples;

const ActionItem = () => {
	return (
		<div className="border-b py-4 px-4 ">
			<div className="text-sm mb-7 flex justify-between">
				<div>
					<i className="bi bi-1-circle-fill text-green-500 me-2"></i>
					SwapAerodromeFromEth
				</div>
				<div className="text-xs font-medium hover:underline">See Txn</div>
			</div>
			<div className="flex justify-around">
				<div className="text-xs">
					<span className="text-muted-foreground">Function: </span>
					SwapAerodromeFromEth
				</div>
				<div className="text-xs ">
					<span className="text-muted-foreground">Event: </span>
					SwapAerodromeFromEth
				</div>
				<div className="text-xs">
					<span className="text-muted-foreground">User Address: </span>
					Transaction.From
				</div>
			</div>
		</div>
	);
};
