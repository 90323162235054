import { cn } from '@/lib/utils';
import { ITaskInputsProps } from './TaskInputs';
import { Checkbox } from '@/components/ui/checkbox';
import { TaskKeys } from '@/features/campaigns/types';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';

const TaskInputCheckbox = ({
	adminInput,
	taskId,
	error,
	editTaskAdminInput,
}: ITaskInputsProps) => {
	return (
		<>
			<div
				className="relative flex items-center cursor-pointer"
				onClick={() => {
					editTaskAdminInput(taskId, adminInput.key, !adminInput.value);
				}}
			>
				<Checkbox
					checked={adminInput.value as boolean}
					className={cn(error && 'border-red-500')}
				/>
				<div className="text-sm font-medium ms-2">{adminInput.label}</div>
				{adminInput.key === TaskKeys.TwitterAcknowledgementOpt ? (
					<>
						<TooltipWrapper tooltip="Switch on to verify using Twitter APIs. These APIs are paid for acurate verification of twitter related tasks. ">
							<i className="bi bi-question-circle text-gray-400 ms-2"></i>
						</TooltipWrapper>
					</>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export default TaskInputCheckbox;
