import { queryClient } from '@/lib/react-query';
import { toast } from 'sonner';
import { updateCampaignPublishStatus } from '../services/admin.service';

export const useAdminCampaignsActions = ({ refetch }) => {
	const togglePublishStatus = async (
		campaignId: string,
		publishCampaign: boolean,
	) => {
		return new Promise((resolve) => {
			toast.promise(
				Promise.all([
					updateCampaignPublishStatus(campaignId, {
						publishCampaign,
					}).then((res) => {
						resolve(res);
						refetch();
					}),
				]),
				{
					loading: publishCampaign
						? 'Publishing campaign on product'
						: 'Unpublishing campaign from product',
					success: publishCampaign
						? 'Campaign published successfully'
						: 'Campaign unpublished successfully',
					error: publishCampaign
						? 'Failed to publish campaign'
						: 'Failed to unpublish campaign',
				},
			);
		});
	};

	return {
		togglePublishStatus,
	};
};
