import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import clsx from 'clsx';
import { useRef, useState } from 'react';

const InputFile = ({
	labelClassName,
	className,
	placeholder,
	label,
	required,
	tooltip,
	error,
	value,
	setValue,
	errorText,
	description,
	containerSize,
	acceptableFiles,
	defaultValue,
}: {
	labelClassName?: string;
	className?: string;
	label: string;
	required?: boolean;
	tooltip?: string;
	error: boolean | string;
	setValue: (value: File) => void;
	value: string;
	errorText?: string;
	placeholder?: string;
	description: string;
	containerSize?: string;
	acceptableFiles?: string;
	defaultValue?: string;
}) => {
	const inputRef = useRef<HTMLInputElement>();
	const [fileLink, setFileLink] = useState<string | null>(defaultValue);
	return (
		<div className={className}>
			<Label className={clsx('flex items-center mb-2', labelClassName)}>
				{label}
				{tooltip && (
					<TooltipProvider delayDuration={0}>
						<Tooltip>
							<TooltipTrigger className="ms-2">
								<i className="bi-info-circle-fill block text-muted-foreground text-xs cursor-pointer"></i>
							</TooltipTrigger>
							<TooltipContent>
								<div className="text-sm font-normal max-w-[400px]">
									{tooltip}
								</div>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				)}
				{required && <span className="text-red-400 ms-1">*</span>}
			</Label>
			<div className="flex mt-2">
				<div
					className={`${
						containerSize ? containerSize : 'w-[64px] h-[64px]'
					} flex justify-center align-center bg-muted border-white-200 border p-2 rounded me-3 ${
						error && 'border-destructive'
					}`}
				>
					<img src={fileLink || value || defaultValue} className="" />
				</div>
				<div>
					<Button
						variant="outline"
						onClick={(e) => {
							e.preventDefault();
							inputRef.current.click();
						}}
					>
						Upload Image
					</Button>
					<div>
						<p className="text-muted-foreground text-xs mt-3">
							{description}
						</p>
					</div>
				</div>
			</div>
			<input
				type="file"
				className="absolute top-0 w-0 -z-1 opacity-0"
				ref={inputRef}
				onChange={(e) => {
					const file = e.target.files[0];
					setValue(file);
					setFileLink(URL.createObjectURL(inputRef.current.files[0]));
				}}
				accept={acceptableFiles ? acceptableFiles : '*'}
			/>
			{error && errorText && (
				<p className={cn('text-xs  text-destructive mt-1')}>{errorText}</p>
			)}
		</div>
	);
};

export default InputFile;
