import { Label } from '@/components/ui/label';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { useEffect } from 'react';
import { useConnectAPI } from '../hooks/useConnectAPI';
import InputText from '@/components/element/inputs/InputText';
import {
	ApiMethod,
	ApiVerification,
	DataPassingMethod,
} from '../types/task-apis.enum';

export const APIDetails = () => {
	const { formFields, setFormFields, formErrors, setFormErrors } = useConnectAPI();

	useEffect(() => {
		setFormErrors((prev: any) => ({
			...prev,
			apiEndpoint: '',
			apiName: '',
		}));
	}, [formFields.apiEndpoint, formFields.apiName, setFormErrors]);

	return (
		<div>
			<div>
				<InputText
					label="API Name"
					required
					placeholder="Verifying Swap Txn"
					className={formErrors['apiName'] ? 'border-destructive' : ''}
					value={formFields?.apiName}
					error={formErrors['apiName']}
					errorText={formErrors['apiName']}
					setValue={(e) =>
						setFormFields((prev: any) => ({ ...prev, apiName: e }))
					}
				/>
			</div>
			<div className="mt-6">
				<Label>What action will your API verify?</Label>
				<Select
					name="apiVerificationAction"
					defaultValue={formFields?.apiVerificationAction}
					onValueChange={(newVal) =>
						setFormFields((prev: any) => ({
							...prev,
							apiVerificationAction: newVal,
						}))
					}
				>
					<SelectTrigger aria-label="Area" className="text-sm">
						<SelectValue placeholder="Select" className="h-10 " />
					</SelectTrigger>
					<SelectContent>
						<SelectItem value={ApiVerification?.EVM}>
							On chain action on EVM Networks (Eg. Swap, Transfer)
						</SelectItem>
						<SelectItem value={ApiVerification?.NonEVM}>
							On chain action on non-EVM Networks
						</SelectItem>
						<SelectItem value={ApiVerification?.OffChain}>
							Off chain action (Eg. App sign up)
						</SelectItem>
					</SelectContent>
				</Select>
			</div>
			<div className="mt-8">
				<InputText
					label="API Endpoint"
					required
					placeholder="https://api.intract.io/api/v1/endpoint"
					className={formErrors['apiEndpoint'] ? 'border-destructive' : ''}
					value={formFields?.apiEndpoint}
					error={formErrors['apiEndpoint']}
					errorText={formErrors['apiEndpoint']}
					setValue={(e) =>
						setFormFields((prev: any) => ({ ...prev, apiEndpoint: e }))
					}
				/>
			</div>
			<div className="grid gap-4 sm:grid-cols-2 mt-8">
				<div className="grid gap-2">
					<Label>API method</Label>
					<Select
						defaultValue={formFields?.apiMethod}
						onValueChange={(newVal) =>
							setFormFields((prev: any) => ({
								...prev,
								apiMethod: newVal,
							}))
						}
					>
						<SelectTrigger aria-label="Area">
							<SelectValue placeholder="Select" />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value={ApiMethod?.Post}>Post</SelectItem>
							<SelectItem value={ApiMethod?.Get}>Get</SelectItem>
						</SelectContent>
					</Select>
				</div>
				<div className="grid gap-2">
					<Label>Payload method</Label>
					<Select
						defaultValue={formFields?.apiPayloadMethod}
						onValueChange={(newVal) =>
							setFormFields((prev: any) => ({
								...prev,
								apiPayloadMethod: newVal,
							}))
						}
					>
						<SelectTrigger>
							<SelectValue placeholder="Select" />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value={DataPassingMethod?.QueryParams}>
								Query Params
							</SelectItem>
							<SelectItem value={DataPassingMethod?.Body}>
								Body
							</SelectItem>
						</SelectContent>
					</Select>
				</div>
			</div>
		</div>
	);
};
