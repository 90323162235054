import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
	deleteAction,
	fetchAction,
	fetchActionTable,
	fetchActionTableCount,
} from '../../services/dave.service';
import { handleErrorMessage } from '@/utils/notifications';
import { useConfirm } from '@/hooks/useConfirm';
import { toast } from 'sonner';
import { queryClient } from '@/lib/react-query';

export const useDeleteAction = () => {
	const [isLoading, setIsLoading] = useState(false);

	const { show } = useConfirm();

	const deleteActionsHandler = async (ids: string[]) => {
		try {
			setIsLoading(true);
			const res = await show({
				title: 'Are you absolutely sure?',
				subtitle: `This action cannot be undone. This will permanently delete your ${ids.length} ${ids.length === 1 ? ' action' : ' actions'} from our servers.`,
				confirmText: 'Delete',
				cancelText: 'Cancel',
				type: 'error',
			});
			toast.promise(
				Promise.all(ids.map((id) => deleteAction(id))).then((res) => {
					setIsLoading(false);
					queryClient.invalidateQueries({
						queryKey: ['dave', 'actions'],
					});
				}),
				{
					loading: 'Deleting...',
					success: 'Action deleted successfully',
					error: 'Failed to delete action',
				},
			);
		} catch (err) {
			handleErrorMessage(err);
			console.error(err);
		} finally {
			setIsLoading(false);
		}
	};

	return {
		deleteActions: deleteActionsHandler,
		isLoading,
	};
};
