import { Link } from 'react-router-dom';
import { homepageContent } from '../../content/homepage.content';

const Customers = () => {
	const content = homepageContent.companies;
	return (
		<div>
			<div className="my-10">
				<p className="mx-auto max-w-sm text-center text-gray-600 sm:max-w-xl sm:text-lg">
					{content.title}
				</p>
				<Link
					className="mx-auto mt-8 grid w-full max-w-screen-lg grid-cols-2 items-center px-5 md:grid-cols-5 md:px-0 mb-8"
					to="/customers"
				>
					{content.logos.map((i) => (
						<img
							alt={i.name}
							loading="lazy"
							decoding="async"
							className="blur-0 h-12 text-center grayscale transition-all hover:grayscale-0 lg:h-20"
							src={i.img}
							style={{ color: 'transparent' }}
						/>
					))}
				</Link>
				<Link
					className="mx-auto mt-8 grid w-full max-w-screen-lg grid-cols-2 lg:grid-cols-5 items-center px-5  md:px-0 mb-8"
					to="/customers"
				>
					{content.logos.map((i) => (
						<img
							alt={i.name}
							loading="lazy"
							decoding="async"
							className="blur-0 h-12 text-center grayscale transition-all hover:grayscale-0 lg:h-20"
							src={i.img}
							style={{ color: 'transparent' }}
						/>
					))}
				</Link>
			</div>
		</div>
	);
};

export default Customers;
