import { Dialog, DialogContent } from '@/components/ui/dialog';
import React from 'react';

const GuideVideoDialog = ({
	open,
	setOpen,
	embed,
}: {
	open?: boolean;
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	embed?: string;
}) => {
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[835px] px-0 p-0">
				<div className="relative h-[460px] p-3">
					<iframe
						className="w-full h-full rounded"
						src={embed}
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default GuideVideoDialog;
