export const internalAdminNav: {
	title?: string;
	nav: {
		icon: string;
		href: string;
		label: string;
		isActive?: boolean;
		options?: {
			icon: string;
			href: string;
			label: string;
			isActive?: boolean;
		}[];
		badge?: {
			variant: 'default' | 'secondary' | 'destructive' | 'outline';
			text: string;
		};
	}[];
}[] = [
	{
		title: 'Approvals',
		nav: [
			{
				icon: 'building',
				href: '/app/admin/enterprises',
				label: 'Enterprises',
			},
			{
				icon: 'compass',
				href: '/app/admin/campaigns',
				label: 'Campaigns',
			},
			{
				icon: 'boombox',
				href: '/app/admin/dave-actions',
				label: 'Dave Actions',
			},
			{
				icon: 'coin',
				href: '/app/admin/custom-tokens',
				label: 'Tokens',
			},
		],
	},
	{
		title: 'Tools',
		nav: [
			{
				icon: 'building',
				href: '/app/admin/notifications',
				label: 'Notifications',
			},
		],
	},
];
