import NoDataCard from '@/components/element/cards/NoDataCard';
import nftImage from '@/assets/empty/light/nfts.png';
import { sampleNFTs } from '../types/nfts.content';
import { LaunchedNFTs } from '../components/LaunchedNFTs';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';

const NoNFTsCard = ({ setOpen }) => {
	return (
		<>
			<NoDataCard
				title="No NFT Contracts Deployed"
				icon="No NFT Contracts Deployed"
				description="You have not deployed any NFT contracts. Deploy one below."
				btnLabel="Deploy NFT"
				className="mt-10"
				btnOnClick={() => {
					setOpen(true);
				}}
				image={nftImage}
			/>
			<div>
				<div className="mt-16 space-y-1">
					<h2 className="text-xl font-semibold tracking-tight">
						NFTs made from community
					</h2>
				</div>

				<Separator className="my-4" />
				<div className="relative w-full">
					<ScrollArea>
						<div className="flex space-x-4 pb-4">
							{sampleNFTs?.map((nft, idx) => (
								<LaunchedNFTs
									key={`${nft.name}_${idx}`}
									data={nft}
									className="w-[150px]"
									aspectRatio="square"
									width={150}
									height={150}
								/>
							))}
						</div>
						<ScrollBar orientation="horizontal" />
					</ScrollArea>
				</div>
			</div>
		</>
	);
};

export default NoNFTsCard;
