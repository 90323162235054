import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card';
import { Avatar, AvatarImage } from '@/components/ui/avatar';
import { formatCompactNumber } from '@/utils/parsers';
import { Button } from '@/components/ui/button';
import MessageKolDialog from './MessageKolDialog';
import { useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

export const KOLCard = ({ kol }) => {
	const [show, setShow] = useState(false);
	const [showMoreSocials, setShowMoreSocials] = useState(false);
	return (
		<>
			<Card className=" h-full flex flex-col" key={kol._id}>
				<CardHeader className=" p-3">
					<div className="flex justify-between items-center ">
						<div className="flex">
							<Avatar className="h-10 w-10">
								<AvatarImage src={kol.avatar} />
							</Avatar>
							<div className="ms-3">
								<div className="font-medium">{kol.name}</div>
								<div className="text-xs text-muted-foreground">
									{kol.region}, {kol.language}
								</div>
							</div>
						</div>
						<div className="text-center">
							<div className="text-lg font-medium">
								{formatCompactNumber(kol.total_followers)}
							</div>
							<div className="text-xs">Followers</div>
						</div>
					</div>
				</CardHeader>
				<CardContent className="p-3 pb-">
					<div className="text-xs text-muted-foreground line-clamp-2 ">
						{kol.description}
					</div>
					<div className="mt-5 flex space-x-3">
						{Array.isArray(kol?.social_info) &&
							kol?.social_info?.slice(0, 3)?.map((key: any) => (
								<Button
									size="sm"
									variant="outline"
									className="text-muted-foreground"
									onClick={() => window.open(key.url, '_blank')}
								>
									<i className={`bi-${key?.name} me-2`}></i>{' '}
									{formatCompactNumber(key?.followers)}
								</Button>
							))}
						{kol?.social_info?.length > 3 ? (
							<Popover
								open={showMoreSocials}
								onOpenChange={setShowMoreSocials}
							>
								<PopoverTrigger asChild>
									<Button
										size="sm"
										variant="outline"
										className="text-muted-foreground"
									>
										<i className="bi-grid-3x3-gap-fill me-2 "></i>{' '}
										More
									</Button>
								</PopoverTrigger>
								<PopoverContent className="w-[4.9rem] p-0">
									{kol?.social_info?.slice(3)?.map((key: any) => (
										<Button
											size="sm"
											variant="ghost"
											className="text-muted-foreground w-full "
											onClick={() =>
												window.open(key.url, '_blank')
											}
										>
											<i
												className={`bi-${key?.name} me-2`}
											></i>{' '}
											{formatCompactNumber(key?.followers)}
										</Button>
									))}
								</PopoverContent>
							</Popover>
						) : null}
					</div>
				</CardContent>
				<CardFooter className="border-t mt-auto px-3 py-2">
					<div className="flex justify-between w-full">
						<div className="flex items-center">
							<div className="text-xs text-muted-foreground">
								Hype Level
							</div>
							<div className="ms-2 flex space-x-0.5">
								{[...Array(kol.hype_level)].map((_, index) => (
									<div
										key={index}
										className="h-[16px] w-[3px] rounded bg-green-500"
									></div>
								))}
								{[...Array(5 - kol.hype_level)].map((_, index) => (
									<div
										key={index}
										className="h-[16px] w-[3px] rounded bg-slate-300"
									></div>
								))}
							</div>
						</div>
						<Button
							variant="outline"
							size="sm"
							onClick={() => {
								analytics.track(TrackingEvents.BoostersCardClicked, {
									booster: 'kol',
									kolId: kol?._id,
									kolName: kol?.name,
								});
								setShow(true);
							}}
						>
							<i className="bi-chat me-2"></i>
							Send message
						</Button>
					</div>
				</CardFooter>
			</Card>
			<MessageKolDialog open={show} setOpen={setShow} kol={kol} />
		</>
	);
};
