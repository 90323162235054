import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import Dayjs from 'dayjs';

Dayjs.extend(timezone);
Dayjs.extend(duration);
Dayjs.extend(relativeTime);
Dayjs.extend(utc);
Dayjs.extend(advancedFormat);

export default Dayjs;
