import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';

const IntegrationCard = ({
	title,
	description,
	background,
	img,
	isConnected,
	onClick,
}: {
	title: string;
	background: string;
	img: string;
	description: string;
	isConnected: boolean;
	onClick: () => void;
}) => {
	return (
		<>
			<Card
				className="w-full "
				style={{
					background,
				}}
			>
				<CardContent className="py-6  h-full flex flex-col ">
					<img src={img} className="h-12 w-12 mb-4 rounded-full" />
					<div className="text-md font-medium">{title}</div>
					<div className="text-xs  mt-1">{description}</div>
					<div className="mt-auto"></div>
					{!isConnected && (
						<Button className="w-full mt-3 " onClick={() => onClick?.()}>
							+ Connect
						</Button>
					)}
					{isConnected && (
						<div>
							<Button
								variant="secondary"
								className="w-full mt-3 pointer-events-none"
							>
								<i className="bi-check-circle-fill text-green-500 me-2"></i>
								Connected
							</Button>
						</div>
					)}
				</CardContent>
			</Card>
		</>
	);
};

export default IntegrationCard;
