import { ConnectAPIProvider } from '../providers/ConnectAPIProvider';
import TaskAPIsPage from './task-apis.page';

const TaskAPIsRoute = () => {
	return (
		<ConnectAPIProvider>
			<TaskAPIsPage />
		</ConnectAPIProvider>
	);
};

export default TaskAPIsRoute;
