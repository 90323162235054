import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { Label } from '@/components/ui/label';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import clsx from 'clsx';

const InputSelect = ({
	labelClassName,
	className,
	placeholder,
	label,
	required,
	tooltip,
	options,
	error,
	value,
	setValue,
	errorText,
	inputClassName,
	description,
	append,
	prepend,
}: {
	labelClassName?: string;
	inputClassName?: string;
	className?: string;
	label?: string;
	required?: boolean;
	tooltip?: string;
	error?: boolean | string;
	setValue: (value: string) => void;
	value: string;
	errorText?: string;
	options: { value: string; label: string }[];
	placeholder?: string;
	description?: string;
	append?: string | JSX.Element;
	prepend?: string | JSX.Element;
}) => {
	return (
		<div className={className}>
			{label && (
				<Label className={clsx('flex items-center mb-2', labelClassName)}>
					<span>{label}</span>
					{tooltip && (
						<TooltipProvider delayDuration={0}>
							<Tooltip>
								<TooltipTrigger className="ms-2">
									<i className="bi-info-circle-fill block text-muted-foreground text-xs cursor-pointer"></i>
								</TooltipTrigger>
								<TooltipContent>
									<div className="text-sm font-normal max-w-[400px]">
										{tooltip}
									</div>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					)}
					{required && <span className="text-destructive ms-1">*</span>}
				</Label>
			)}
			<Select value={value} onValueChange={(e) => setValue(e)}>
				<SelectTrigger
					aria-label="Area"
					className={cn(
						'text-sm',
						inputClassName,
						error && 'border-destructive',
					)}
				>
					<div className="flex justify-between">
						{prepend && <span>{prepend}</span>}
						<span key={value} className="notranslate">
							<SelectValue
								placeholder={placeholder}
								className={cn('h-10')}
							/>
						</span>
						{append && <span>{append}</span>}
					</div>
				</SelectTrigger>
				<SelectContent>
					{options.map((i) => (
						<SelectItem key={i.value} value={i.value}>
							<span>{i.label}</span>
						</SelectItem>
					))}
				</SelectContent>
			</Select>
			{error && errorText && (
				<p className={cn('text-xs  text-destructive mt-1')}>{errorText}</p>
			)}
		</div>
	);
};

export default InputSelect;
