import { Button } from '@/components/ui/button';
import EventCampaign from './EventCampaign';
import EventSection from './EventSection';
import DnDKanban from '@/components/element/dnd/DnDKanban';
import { SearchCampaigns } from '../components/SearchCampaigns';
import { useCreateEventSections } from '@/features/events/hooks/create/useCreateEventSections';

const EventCampaignSections = () => {
	const {
		containers_,
		handleReorderContainers,
		addSection,
		addCampaign,
		editItem,
		editContainer,
		showSearchCampaigns,
		setShowSearchCampaigns,
		setSectionId,
		sections,
	} = useCreateEventSections();

	return (
		<div>
			{sections.length > 0 && (
				<>
					<DnDKanban
						containers={containers_}
						setContainers={handleReorderContainers}
						ContainerComponent={EventSection}
						ItemComponent={EventCampaign}
						addItem={(sectionId_) => {
							setSectionId(sectionId_);
							setShowSearchCampaigns(true);
						}}
						editItem={editItem}
						editContainer={editContainer}
						selectItem={() => {}}
					/>
					<Button variant="outline" onClick={addSection}>
						+ Add Section
					</Button>
				</>
			)}
			{sections.length === 0 && (
				<>
					<Button onClick={() => setShowSearchCampaigns(true)}>
						+ Add your first campaign
					</Button>
				</>
			)}
			<SearchCampaigns
				open={showSearchCampaigns}
				setOpen={setShowSearchCampaigns}
				onSelect={addCampaign}
				removeCampaigns={sections
					.map((i) => i.campaigns.map((i) => i._id))
					.flat()}
			/>
		</div>
	);
};

export default EventCampaignSections;
