/* eslint-disable no-mixed-spaces-and-tabs */
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { z } from 'zod';
import { Avatar } from '@/components/ui/avatar';
import { AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { formatNumberWithCommas, truncateString } from '@/utils/parsers';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { ILeaderboardUsers } from '../../types/leaderboard.types';

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.

const columnHelper = createColumnHelper<ILeaderboardUsers>();

export const columns = (showCampaignXps: boolean): ColumnDef<ILeaderboardUsers>[] =>
	[
		columnHelper.accessor('rank', {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Rank"
					className=" max-w-[20px] min-w-[20px]"
				/>
			),
			cell: ({ row }) => <div className="">{row.getValue('rank')}</div>,
			enableSorting: true,
			enableHiding: false,
		}),

		{
			accessorKey: 'username',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Name"
					className=" min-w-[200px] max-w-[200px] w-[200px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center ">
					<Avatar className="h-7 w-7 me-2">
						<AvatarImage src={row.original.avatar} alt="@shadcn" />
						<AvatarFallback name={row.original.username} />
					</Avatar>
					{truncateString(row.original.username, 20)}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'totalXp',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="XPs"
					className=" w-[100px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center">
					{formatNumberWithCommas(row.getValue('totalXp'))}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		...(showCampaignXps
			? [
					{
						accessorKey: 'campaignXp',
						header: ({ column }) => (
							<DataTableColumnHeader
								column={column}
								title="Campaign XPs"
								className=" w-[100px]"
							/>
						),
						cell: ({ row }) => (
							<div className="flex items-center">
								{formatNumberWithCommas(row.getValue('campaignXp'))}
							</div>
						),
						enableSorting: false,
						enableHiding: false,
					},
			  ]
			: []),
		columnHelper.accessor('twitter', {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Twitter"
					className=" w-[100px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center">
					{truncateString(row.getValue('twitter'), 20)}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		}),
		columnHelper.accessor('discord', {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Discord"
					className=" w-[100px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center">
					{truncateString(row.getValue('discord'), 20)}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		}),
	].filter((i) => i);
