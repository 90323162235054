import LandingLayout from '../components/layout/LandingLayout';

const BlogsPage = () => {
	return (
		<LandingLayout>
			<div></div>
		</LandingLayout>
	);
};

export default BlogsPage;
