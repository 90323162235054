import React from 'react';
import { IChannelPermissions } from '../../hooks/useDiscordIntegration';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { Button } from '@/components/ui/button';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';

export const ChannelPermissions = ({
	channels,
	isFetchingChannels,
	isLoadingChannels,
	handleFetching,
	loading,
	refetchChannel,
	integrationId,
}: {
	channels: IChannelPermissions[];
	isFetchingChannels: boolean;
	loading: boolean;
	isLoadingChannels: boolean;
	setStep: (step: number) => void;
	handleFetching: any;
	refetchChannel: any;
	integrationId: string;
}) => {
	if (isLoadingChannels) return <FullPageLoading />;
	return (
		<div className=" p-6 ">
			<div className="flex justify-between items-center mb-3">
				<div className="font-medium mb-0">
					Channels we will import (
					{channels?.filter((i) => i.hasAccess)?.length ?? 0})
				</div>
				<Button
					className="py-1"
					size="sm"
					onClick={refetchChannel}
					disabled={isLoadingChannels || isFetchingChannels}
					variant="outline"
				>
					Refresh list
				</Button>
			</div>
			<div>
				<div
					className="bg-slate-50 border rounded w-1/2"
					style={{ maxHeight: '200px', overflow: 'scroll' }}
				>
					{channels.map((i) => (
						<div className=" px-4 py-2 flex justify-between border-b">
							<div className="text-sm">
								<i className="bi-hash me-2"></i>
								{i.channelName}
							</div>
							<TooltipWrapper
								tooltip={
									!i.hasAccess
										? `One of ${i.requiredRoles
												.map((i) => i.name)
												.join(', ')} roles required`
										: ''
								}
							>
								<div className="d-flex">
									<div>
										{i.hasAccess ? (
											<i className="bi-patch-check-fill text-green-500"></i>
										) : (
											<i className="bi-patch-exclamation-fill text-destructive"></i>
										)}
									</div>
								</div>
							</TooltipWrapper>
						</div>
					))}
				</div>
			</div>
			<div className="mt-10">
				<div className="text-sm font-medium">
					Don't see a channel vou want to import?
				</div>
				<ul className="text-sm text-muted-foreground list-disc ps-4">
					<li>Navigate to the channel you want to import on Discord</li>
					<li>Go to the settings for that specific channel</li>
					<li>
						Within Permissions (located on the left side bar), click Add
						member or roles
					</li>
					<li>
						Select the Intract bot (the one with the green check) under
						members and click Done
					</li>
					<li>
						Under Advanced permissions, ensure that [View channel] and
						[Read message history] are marked as [✔️]
					</li>
				</ul>
			</div>
			<Button
				size="sm"
				className="mt-4 "
				onClick={() => handleFetching(integrationId)}
				disabled={loading}
			>
				Looks Good
			</Button>
		</div>
	);
};
