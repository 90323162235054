import React from 'react';
import LandingLayout from '../layout/LandingLayout';
import LandingContainer from '../ui/LandingContainer';
import { careersContent } from '../../content/careers.content';
import { Button } from '@/components/ui/button';
import SectionHeader from '../ui/SectionHeader';

const OpenPositions = () => {
	return (
		<LandingContainer>
			<SectionHeader
				title={careersContent.positions.title}
				description={careersContent.positions.description}
				className="mb-10"
			/>
			<div className="grid gap-12 xl:gap-16">
				{careersContent.positions.roles.map((i) => (
					<div className="border-b border-dashed border-neutral-80/20">
						<h3 className="mb-3 text-27 font-semibold">{i.group}</h3>
						<div className="divide-y divide-dashed divide-neutral-80/20">
							{i.roles.map((i) => (
								<PositionItem {...i} />
							))}
						</div>
					</div>
				))}
			</div>
		</LandingContainer>
	);
};

export default OpenPositions;

const PositionItem = ({ link, description }) => {
	return (
		<a className="flex items-center justify-between gap-6 py-5" href={link}>
			<div className="flex flex-1 flex-col justify-between xl:flex-row">
				<span className="is_Text font_sans _dsp-inline _bxs-border-box _ww-break-word _mt-0px _mr-0px _mb-0px _ml-0px _ff-299667014 _fos-19px _lh-28px _ls--0d0t304px46 _fow-600 _col-1358368096">
					{description}
				</span>
			</div>
			<span className="is_Button  _dsp_contents">
				<Button size="icon" variant="outline">
					<i className="bi-chevron-right "></i>
				</Button>
			</span>
		</a>
	);
};
