import { Card, CardContent } from '@/components/ui/card';
import { useMemo } from 'react';
import RewardCardHeader from '../others/RewardCardHeader';
import { CampaignRewardType, ICampaignAddReward } from '@/features/campaigns/types';
import RewardMethodSelect from '../input/RewardMethodSelect';
import SelectDiscordRole from '../input/SelectDiscordRole';
import InputText from '@/components/element/inputs/InputText';

const DiscordRoleReward = ({
	discordRoleReward,
	setDiscordRoleReward,
	errors,
	noEndTime,
}: {
	mode: string;
	noEndTime: boolean;
	discordRoleReward: ICampaignAddReward;
	setDiscordRoleReward: (reward: ICampaignAddReward) => void;
	errors: {
		method: string | boolean;
		roleId: boolean;
		winners: boolean;
	};
}) => {
	const allowedMethods = useMemo(() => {
		const methods = [CampaignRewardType.Fcfs, CampaignRewardType.Unlimited];
		if (noEndTime) {
			methods.push(CampaignRewardType.Leaderboard);
		}
		return methods;
	}, [noEndTime]);
	return (
		<Card className="mb-10 mt-10 shadow-none">
			<RewardCardHeader
				title="Discord Role Rewards"
				description="Discord Role are fungible tokens that are compatible with the Ethereum network."
				tooltip="NFTs are unique digital assets that are stored on a blockchain."
			/>
			<CardContent>
				<div className="mb-5">
					<div>
						<div className="text-sm font-medium mb-1">Reward Method</div>
						<RewardMethodSelect
							allowedMethods={allowedMethods}
							value={discordRoleReward.method}
							setValue={(value) =>
								setDiscordRoleReward({
									...discordRoleReward,
									method: value as CampaignRewardType,
								})
							}
							error={!!errors?.method}
							errorText="Please select a reward method"
						/>
					</div>
				</div>
				<div className="mb-6">
					<div className="text-sm font-medium mb-1">
						Select the discord role
					</div>
					<SelectDiscordRole
						value={discordRoleReward?.discordReward?.roleId}
						setValue={(id: string, name: string) => {
							setDiscordRoleReward({
								...discordRoleReward,
								discordReward: {
									roleId: id,
									roleName: name,
								},
							});
						}}
						error={errors?.roleId}
						errorText="Please select a discord role"
					/>
				</div>
				{discordRoleReward?.method !== CampaignRewardType.Unlimited && (
					<InputText
						label="Number of winners"
						placeholder="100"
						value={discordRoleReward?.numRewards}
						setValue={(e) =>
							setDiscordRoleReward({
								...discordRoleReward,
								numRewards: parseInt(e?.toString()),
							})
						}
						type="number"
						error={errors?.winners}
						errorText="Please enter a number of winners"
					/>
				)}
			</CardContent>
		</Card>
	);
};

export default DiscordRoleReward;
