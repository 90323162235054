import DashboardNav from './DashboardNav';

const Header = () => {
	return (
		<header className="sticky top-0 z-40 border-b bg-background">
			<DashboardNav />
		</header>
	);
};

export default Header;
