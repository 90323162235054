import { useEffect, useState } from 'react';
import { acceptInvite, checkInvitation } from '../services/auth.service';
import { toast } from 'sonner';
import { ErrorTypes, handleErrorMessageWithCode } from '@/utils/notifications';
import { useRouter } from '@/hooks/useRouter';
import { useMutation } from '@tanstack/react-query';
import { useAuth } from '@/hooks/useAuth';

interface IQuery {
	[x: string]: string | (string | null)[] | null | undefined;
}

export const useAcceptInvite = (searchParams: IQuery) => {
	const { navigate } = useRouter();
	const { updateUser } = useAuth();

	const [formFields, setFormFields] = useState({
		password: '',
		confirmPassword: '',
	});
	const [formErrors, setFormErrors] = useState<any>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const {
		mutateAsync: checkHashValidity,
		data: userExists,
		error,
		isPending,
	} = useMutation({
		mutationFn: () => checkInvitation(searchParams?.hash as string),
	});

	const acceptInvitation = async () => {
		try {
			const user = await acceptInvite({
				password: formFields.password || 'something',
				hash: searchParams.hash,
			});
			updateUser(user);
			navigate('/app/home');
		} catch (err) {
			handleErrorMessageWithCode(err, [
				{
					code: ErrorTypes.ResourceMissing,
					message: "Invitation doesn't exist, please try again",
					after: () => navigate('/'),
				},
				{
					code: ErrorTypes.ResourceAlreadyExists,
					message:
						'Looks like you already have an account, please login instead',
					after: () => navigate('/login'),
				},
			]);
		}
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		try {
			setIsLoading(true);
			const error: any = {};

			const isValid = userExists?.exists;

			if (!isValid) {
				if (!formFields.password || !formFields.password.trim()) {
					error.password = 'Password is required';
				}
				if (
					!formFields.confirmPassword ||
					!formFields.confirmPassword.trim()
				) {
					error.confirmPassword = 'Confirm password is required';
				}
				if (formFields.password !== formFields.confirmPassword) {
					error.confirmPassword = 'Passwords do not match';
				}
				if (formFields.password.length < 8) {
					error.password = 'Password must be at least 8 characters';
				}
			}

			if (Object.keys(error).length > 0) {
				setFormErrors(error);
				return;
			}

			acceptInvitation();
		} catch (err) {
			console.log(err);
			if (err.response.data.code === ErrorTypes.InvalidToken) {
				toast.error(
					'This token seems invalid, are you sure you copied the link correctly?',
				);
				navigate('/login');
				return;
			}
		} finally {
			// setIsLoading(false);
		}
	};

	useEffect(() => {
		checkHashValidity();
	}, []);

	return {
		handleSubmit,
		formErrors,
		isLoading,
		hashError: isLoading
			? null
			: (error as any)?.response?.data?.message || error?.message,
		formFields,
		setFormFields,
		setFormErrors,
		userExists: userExists?.exists,
	};
};

export default useAcceptInvite;
