import { Card, CardContent } from '@/components/ui/card';
import GuideVideoDialog from '@/features/dave/components/dialogs/GuideVideoDialog';
import { useState } from 'react';

export const ProductDemo = () => {
	const [showGuide, setShowGuide] = useState(false);
	return (
		<>
			<Card>
				<CardContent className="py-4">
					<div className="font-medium">Watch a demo</div>
					<div className="relative">
						<div
							className="group absolute inset-0 z-10 cursor-pointer flex h-full w-full items-center justify-center bg-black bg-opacity-0 transition-all duration-300 hover:bg-opacity-5 focus:outline-none"
							onClick={() => setShowGuide(true)}
						>
							<div className="flex flex-col items-center space-y-4">
								<div className="rounded-full bg-gradient-to-tr from-black to-gray-700 p-2 ring-[6px] ring-gray-300 transition-all duration-300 group-hover:scale-110 group-hover:ring-4 group-active:scale-90">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width={24}
										height={24}
										viewBox="0 0 24 24"
										fill="currentColor"
										stroke="currentColor"
										strokeWidth={2}
										strokeLinecap="round"
										strokeLinejoin="round"
										className="lucide lucide-play h-5 w-5 text-white"
									>
										<polygon points="6 3 20 12 6 21 6 3" />
									</svg>
								</div>
							</div>
						</div>
						<img
							src="https://assets.dub.co/features/powerful-analytics.jpg"
							alt="Product Demo"
							className="mt-4 rounded"
						/>
					</div>
				</CardContent>
			</Card>
			<GuideVideoDialog
				open={showGuide}
				setOpen={setShowGuide}
				embed="https://www.youtube.com/embed/ZMrjvxCIPpY?si=LzP0vR6vcLytBaZV"
			/>
		</>
	);
};
