import { axios } from '@/lib/axios';

export async function fetchAllActions() {
	const res = await axios.get(`/dave/actions`);
	return res.data;
}

export async function fetchAction(id: string) {
	const res = await axios.get(`/dave/actions/${id}`);
	return res.data;
}

export async function fetchQuery(id: string) {
	const res = await axios.get(`/dave/queries/${id}`);
	return res.data;
}

export async function fetchActionTable(
	id: string,
	data: {
		page: number;
		pageSize: number;
		search?: string;
		sort?: string;
	},
) {
	const res = await axios.get(
		`/dave/actions/${id}/table?page=${data.page}&pageSize=${data.pageSize}&search=${data.search}&sort=${data.sort}`,
	);
	return res.data;
}

export async function fetchActionTableCount(id: string, search: string) {
	const res = await axios.get(`/dave/actions/${id}/table/count?search=${search}`);
	return res.data;
}

export async function fetchAllContracts() {
	const res = await axios.get(`/dave/contracts`);
	return res.data;
}

export async function deleteContract(id: string) {
	const res = await axios.delete(`/dave/contracts/${id}`);
	return res.data;
}

export async function fetchAllQueries() {
	const res = await axios.get(`/dave/queries`);
	return res.data;
}

export async function createAction(actionData: any) {
	const res = await axios.post(`/dave/create-action`, actionData);
	return res.data;
}

export async function updateAction(actionData: any, actionId: string) {
	const res = await axios.put(`/dave/update-action/${actionId}`, actionData);
	return res.data;
}

export async function updateQuery(queryData: any, queryId: string) {
	const res = await axios.put(`/dave/update-query/${queryId}`, queryData);
	return res.data;
}

export async function createQuery(queryData: any) {
	const res = await axios.post(`/dave/create-query`, queryData);
	return res.data;
}

export async function createContract(queryData: any) {
	const res = await axios.post(`/dave/create-contract`, queryData);
	return res.data;
}

export async function getDaveSupportedChains() {
	const res = await axios.get(`/dave/supported-chains`);
	return res.data;
}

export async function getDaveSupportedTokens(chainId: number) {
	const res = await axios.get(`/dave/supported-tokens?chainId=${chainId}`);
	return res.data;
}

export async function fetchContractABI(contractAddress: string, chainId: number) {
	const res = await axios.get(
		`/dave/contract/check-abi?contractAddress=${contractAddress}&chainId=${chainId}`,
	);
	return res.data;
}

export async function checkIfContractIsProxy(
	contractAddress: string,
	chainId: number,
) {
	const res = await axios.get(
		`/dave/contract/check-proxy?contractAddress=${contractAddress}&chainId=${chainId}`,
	);
	return res.data;
}

export async function fetchTxnFromHash(hash: string, chainId: number) {
	const res = await axios.get(
		`/dave/guide/txn-hash?hash=${hash}&chainId=${chainId}`,
	);
	return res.data;
}

export async function deleteAction(id: string) {
	const res = await axios.delete(`/dave/action/${id}`);
	return res.data;
}

export async function deleteQuery(id: string) {
	const res = await axios.delete(`/dave/queries/${id}`);
	return res.data;
}
