import { Button } from '@/components/ui/button';
import { useQuickAccess } from '../../hooks/useQuickAccess';
import NotificationList from '@/features/dashboard/components/notifications/NotificationList';
import { useNavigate } from 'react-router-dom';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

const QuickAccess = () => {
	const { quickAccess } = useQuickAccess();

	return (
		<div className="mt-10">
			<div className="font-medium text-lg ">Quick Access</div>
			<p className="text-xs text-muted-foreground mb-4">
				Learn from our resources to help you get started with your campaign
			</p>
			<div className="grid grid-cols-2 gap-4">
				<QuickAccessCard
					title="Recently Visited"
					items={quickAccess?.recentResources ?? []}
				/>
				<CardLayout title="Notifications">
					<NotificationList size="sm" />
				</CardLayout>
			</div>
		</div>
	);
};

export default QuickAccess;

const QuickAccessCard = ({ title, items }) => {
	const navigate = useNavigate();
	return (
		<CardLayout title={title}>
			{items.map((i: any) => (
				<div
					className="border-b py-2 cursor-pointer hover:bg-slate-50 dark:hover:bg-slate-800 transition-all ..."
					key={i.banner}
					onClick={() => {
						analytics.track(TrackingEvents.HomepageQuickActionsClicked, {
							resourceType: i.type,
							resourceId: i._id,
						});
						if (i.type === 'event') {
							navigate(`/app/campaign/events/${i._id}`);
						} else {
							navigate(`/app/campaign/quests/${i._id}`);
						}
					}}
				>
					<div className="flex items-center px-2 py-2 text-xs  ... ">
						<img
							src={i.banner}
							className="h-5 w-6 object-cover rounded-md me-1"
						/>
						<div className="truncate ...">{i.name}</div>
					</div>
				</div>
			))}
			{items.length === 0 && (
				<>
					<div className=" flex w-full flex-col items-center justify-center h-full pt-20">
						<div className="text-xs  mb-5">No active campaigns</div>
					</div>
					<div className="absolute bottom-5 w-full flex items-center justify-center">
						<Button size="sm" variant="secondary" className="border ">
							+ Launch your first Quest
						</Button>
					</div>
				</>
			)}
		</CardLayout>
	);
};

const CardLayout = ({ children, title }) => {
	return (
		<div className="rounded-lg bg-slate-100 dark:bg-slate-900 ">
			<div className="text-xs font-medium pt-3 pb-2 px-3">{title}</div>

			<div className="min-h-[300px] bg-white dark:bg-black border-2 rounded-2xl -mb-4 max-h-[300px] relative overflow-auto">
				{children}
			</div>
		</div>
	);
};
