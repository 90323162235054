import { axios } from '@/lib/axios';
import { ILeaderboardUserDetails } from '../types/leaderboard.types';

export const getLeaderboards = async (
	page: number,
	limit: number,
	campaignId?: string,
	eventId?: string,
): Promise<{
	users: any[];
	page: number;
}> => {
	if (!page) return { users: [], page: 0 };
	const response = await axios.get(
		`/leaderboard?page=${page}&limit=${limit}&campaignId=${campaignId}&eventId=${eventId}`,
	);
	return response.data;
};

export const getLeaderboardUserCount = async (
	campaignId?: string,
	eventId?: string,
): Promise<{
	count: number;
}> => {
	const response = await axios.get(
		`/leaderboard/count?&campaignId=${campaignId}&eventId=${eventId}`,
	);
	return response.data;
};

export const exportLeaderboardUsers = async (
	campaignId?: string,
	eventId?: string,
): Promise<{
	count: number;
}> => {
	const response = await axios.get(
		`/leaderboard/export?&campaignId=${campaignId}&eventId=${eventId}`,
	);
	return response.data;
};

export const getLeaderboardUserDetails = async (
	userId?: string,
): Promise<ILeaderboardUserDetails> => {
	const response = await axios.get(`/leaderboard/${userId}`);
	return response.data;
};

export const exportLeaderboards = async ({
	campaignId,
	eventId,
}: {
	campaignId?: string;
	eventId?: string;
}): Promise<{
	users: any[];
	page: number;
}> => {
	const response = await axios.get(
		`/leaderboard/export?campaignId=${campaignId}&eventId=${eventId}`,
	);
	return response.data;
};
