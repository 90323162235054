import { useEffect, useState } from 'react';
import { IDaveCreateAction } from '../../types/dave.types';

export const useActionContextualSuggestions = ({
	details,
	setContextualSuggestions,
	contextualSuggestions,
}: {
	details: IDaveCreateAction;
	setContextualSuggestions: any;
	contextualSuggestions: {
		element: string | JSX.Element | Element;
		id: string;
	};
}) => {
	const [shownSuggestions, setShownSuggestions] = useState<string[]>([]);

	useEffect(() => {
		// Check if the suggestion for this detail category has already been shown

		if (details.category === 'bridge') {
			const id = 'bridge-user-address';
			const hasBeenShown = shownSuggestions.includes(id);
			if (hasBeenShown) return;
			setContextualSuggestions({
				element: (
					<div className="  text-xs text-center max-w-[900px]">
						In bridge transactions, typically a non-contract address
						sends bridge tokens to a user.
						<br />
						Select "Address" as the txn criteria type and enter the
						sending address in the "Address" field.
					</div>
				),
				id,
			});
			setShownSuggestions((prevShown) => [...prevShown, id]);
		} else {
			if (contextualSuggestions.id === 'bridge-user-address') {
				setContextualSuggestions({});
			}
		}
	}, [details.category, setContextualSuggestions, shownSuggestions]);
};
