import { featuresContent } from '../../content/features.content';
import LandingContainer from '../ui/LandingContainer';

const FeatureFAQs = () => {
	return (
		<LandingContainer>
			<div className="mx-auto max-w-md text-center sm:max-w-xl mt-40 ">
				<h2 className="font-display text-sm "></h2>
				<p className="mt-5 text-5xl font-medium">
					{featuresContent.faqs.title}
				</p>
				<p className="text-sm mt-5">{featuresContent.faqs.description}</p>
			</div>
			<div className="mt-10 mb-20">
				<div className="flex flex-col gap-4">
					<ul className="mt-16 sm:mt-10 grid grid-cols-3 lg:grid-cols-3 sm:grid-cols-1 gap-8 gap-y-10">
						{featuresContent.faqs.faqs.map((i) => (
							<li>
								<h3 className="font-medium">{i.question}</h3>
								<p className="text-sm mt-1">{i.answer}</p>
							</li>
						))}
					</ul>
				</div>
			</div>
		</LandingContainer>
	);
};

export default FeatureFAQs;
