import { Card, CardFooter, CardHeader } from '@/components/ui/card';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { formatCompactNumber } from '@/utils/parsers';
import { Button } from '@/components/ui/button';
import { IProject } from '../hooks/useGetProjects';
import { useMemo, useState } from 'react';
import MessageProjectDialog from './MessageProjectDialog';
import AvatarGroupSimple from '@/components/element/avatar/AvatarGroupSimple';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';
import { TrackingEvents } from '@/types/tracking.type';
import analytics from '@/lib/analytics';
import DOMPurify from 'dompurify';

const ProjectCard = ({ project }: { project: IProject }) => {
	const [show, setShow] = useState(false);
	const { getChainName, getNativeChainLogo } = useGetSupportedChains({
		showAdditionalChains: false,
	});

	const safeHTML = DOMPurify.sanitize(project?.description);

	const chainLogos = useMemo(() => {
		let logos = [];
		if (project) {
			if (project.chainIds && project.chainIds.length > 0) {
				logos = project.chainIds
					.map((chainId) => getNativeChainLogo(chainId))
					.filter((url) => url);
			} else if (
				project.primaryChainId &&
				getChainName(project.primaryChainId)
			) {
				logos.push(getNativeChainLogo(project.primaryChainId));
			}
		}
		return logos;
	}, [project]);

	if (!project) return null;

	return (
		<>
			<Card className="h-full flex flex-col" key={project._id}>
				<CardHeader className="p-3 py-8">
					<div className="flex justify-between items-center">
						<div className="flex">
							<Avatar className="h-10 w-10">
								<AvatarImage src={project.logo} />
								<AvatarFallback name={project.name} />
							</Avatar>
							<div className="mx-3 flex flex-col items-start justify-normal">
								<div className="font-medium mb-1">
									{project.name}
								</div>
								<div
									className="text-xs text-muted-foreground line-clamp-3 mb-1"
									dangerouslySetInnerHTML={{ __html: safeHTML }}
								></div>
							</div>
						</div>
						<div className="text-center">
							<div className="text-lg font-medium">
								{formatCompactNumber(project.followerCount)}
							</div>
							<div className="text-xs">Followers</div>
						</div>
					</div>
				</CardHeader>
				<CardFooter className="border-t mt-auto px-3 py-2">
					<div className="flex justify-between w-full">
						<div className="flex items-center ">
							<AvatarGroupSimple
								images={chainLogos}
								className="bg-white/95"
							/>
						</div>
						<Button
							variant="outline"
							size="sm"
							onClick={() => {
								analytics.track(TrackingEvents.BoostersCardClicked, {
									booster: 'partnership',
									projectId: project?._id,
									projectName: project?.name,
								});
								setShow(true);
							}}
						>
							<i className="bi-chat me-2"></i>
							Send message
						</Button>
					</div>
				</CardFooter>
			</Card>
			<MessageProjectDialog open={show} setOpen={setShow} project={project} />
		</>
	);
};

export default ProjectCard;
