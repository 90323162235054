import { useQuery } from '@tanstack/react-query';
import { getEventSlug } from '../../services/event.service';

export const useEventSlug = (name: string) => {
	const { data, isLoading, refetch } = useQuery({
		queryKey: ['event-slug', name],
		queryFn: () => getEventSlug(name),
		enabled: false,
	});

	const fetchSlug = () => {
		refetch();
	};

	return {
		slug: data,
		isLoading: isLoading,
		fetchSlug,
	};
};
