import BarChartComponent from '@/components/charts/BarChart';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import { ITask } from '@/features/campaigns/types';
import { useMemo } from 'react';

const TaskCompletionChart = () => {
	const { campaign } = useCampaignReport();

	const legends = [
		{
			key: 'initiations',
			label: 'Initiation',
			opacity: 0.6,
		},
		{
			key: 'completions',
			label: 'Completion',
			opacity: 1,
		},
	];

	const data = useMemo(() => {
		if (!campaign) return [];
		const data = campaign.quest.tasks.map((task: ITask) => ({
			task: task.description,
			initiations: task?.initiatedUsersCount || 0,
			completions: task?.completedUsersCount || 0,
		}));
		if (data.length < 5) {
			data.push(
				...[...Array(5 - data.length)].map(() => ({
					task: ' ',
					initiations: 0,
					completions: 0,
				})),
			);
		}
		return data;
	}, [campaign]);

	return (
		<Card className="grid grid-cols-10">
			<CardContent className="col-span-7 p-0">
				<CardHeader className="">
					<h2 className="text-lg font-semibold">Task Completions</h2>
				</CardHeader>
				<div className="h-[350px] flex ">
					<BarChartComponent
						data={data}
						xAxisKeyLength={campaign?.quest?.tasks.length}
						legends={legends}
						xAxisKey="task"
						tooltipFn={({ active, payload }) => {
							if (
								active &&
								payload &&
								payload.length &&
								payload[0].payload.task &&
								payload[0].payload.task !== ' '
							) {
								return (
									<div className="rounded-lg border bg-background p-2 shadow-sm">
										<div className="text-xs mb-3">
											Task:{' '}
											<span>{payload[0].payload.task}</span>
										</div>
										<div className="grid grid-cols-1 gap-2">
											<div className="flex flex-col">
												<span className="text-[0.70rem] uppercase text-muted-foreground">
													Initiations
												</span>
												<span className="font-bold text-muted-foreground">
													{payload[0].value}
												</span>
											</div>
											<div className="flex flex-col">
												<span className="text-[0.70rem] uppercase text-muted-foreground">
													Completions
												</span>
												<span className="font-bold">
													{payload[1].value}
												</span>
											</div>
										</div>
									</div>
								);
							}
							return null;
						}}
					/>
				</div>
			</CardContent>
			<CardContent className="col-span-3  px-0 border-s">
				<div className="">
					<div className="p-3 border-b font-medium">
						All Tasks ({campaign?.quest?.tasks.length})
					</div>
					<ScrollArea className="h-[350px]">
						{campaign?.quest?.tasks?.map((task: ITask, idx: number) => (
							<div
								className={`w-full flex px-3 justify-start text-sm py-3  border-b`}
							>
								<p className="me-2">{idx + 1}.</p>
								<div>{task?.description}</div>
							</div>
						))}
					</ScrollArea>
				</div>
			</CardContent>
		</Card>
	);
};

export default TaskCompletionChart;
