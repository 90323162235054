import React from 'react';
import LandingContainer from '../ui/LandingContainer';

const IntractTeamPhotos = () => {
	return (
		<LandingContainer>
			<div className="my-20">
				<div></div>
			</div>
		</LandingContainer>
	);
};

export default IntractTeamPhotos;
