import InputText from '@/components/element/inputs/InputText';
import InputTextArea from '@/components/element/inputs/InputTextArea';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
	DialogFooter,
} from '@/components/ui/dialog';
import { QUEST_URL } from '@/config';
import { getTweetShareLink } from '@/utils/twitter';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const CampaignTwitterShare = ({ open, setOpen, twitterShare, setTwitterShare }) => {
	const { id } = useParams();
	const [text, setText] = useState(twitterShare);

	const link = id ? `${QUEST_URL}/quests/${id}` : `${QUEST_URL}/quests/`;

	useEffect(() => {
		setText(twitterShare);
	}, [twitterShare, open]);

	const handleSave = () => {
		setTwitterShare(text);
		setOpen(false);
	};
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[525px] pb-3">
				<DialogHeader className="border-b pb-3">
					<DialogTitle>Add Custom Share Text</DialogTitle>
					<DialogDescription>
						Customize the placeholder tweet for your quest.
					</DialogDescription>
				</DialogHeader>
				<div className="">
					<div className="text-xs mb-2">
						Quest link will be appended to the tweet automatically.
					</div>
					<InputTextArea
						subLabelClassName="text-xs text-muted-foreground mt-2"
						value={text}
						setValue={(e) => {
							setText(e);
						}}
						placeholder={'Enter placeholder tweet text'}
						errorText="Tweet text is required."
					/>
				</div>
				<DialogFooter className="mt-5">
					<Button className="px-5" variant="outline" asChild>
						<a href={getTweetShareLink(text, link)} target="_blank">
							<i className="bi-twitter me-2"></i> Preview
						</a>
					</Button>
					<Button onClick={handleSave} className="px-10">
						Save
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default CampaignTwitterShare;
