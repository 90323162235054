import {
	IntegrationType,
	DataBackupStatus,
	BillingTier,
	AnnouncementStatus,
} from './integrations.enums';

export interface IIntegration {
	_id: string;
	name: string;
	color: string;
	icon: string;
	metrics: any;
	status: any;
	createdAt: Date;
	enterpriseId: string;
	integrationType: IntegrationType;
	billingTier: BillingTier;
	discord: {
		guildId: string;
		name: string;
		icon: string;
		avatar: string;
		memberCount: number;
		banner: string;
		ownerId: string;
		permissions: string;
		accessToken: string;
		refreshToken: string;
		dataBackup: {
			status: DataBackupStatus;
			progress: number;
			lastBackup?: Date;
			timeTaken?: number;
			channelsWithoutPermissions?: string[];
		};
		metrics: {
			messages: number;
			replies: number;
			members: number;
		};
		verificationChannels: {
			channelId: string;
			messageId: string;
			isFull: boolean;
			channelType: any;
		}[];
		announcement: {
			status: AnnouncementStatus;
			channelId: string;
			channelName: string;
		};
		firstEventDate: Date;
	};
	telegram: {
		chatId: number;
		name: string;
		username: string;
		groupType: string;
		date: number;
		admin: string;
		adminId: number;
	};
	isDeleted: boolean;
	deleteInProgress: boolean;
	isActive: boolean;
	isSynced: boolean;
	suggestedRoles: {
		roleId: string;
		roleName: string;
	};
	hasAllChannelAccess: boolean;
	updatedAt: any;
}

export enum IntegrationStatus {
	Connected = 'Connected',
	Syncing = 'Syncing',
	Error = 'Error',
}
