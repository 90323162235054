import { useLoyaltyCampaign } from '../hooks/useLoyaltyCampaign';
import LoyaltyTaskSections from './sections/LoyaltyTaskSections';
import LoyaltyAnalytics from './analytics/LoyaltyAnalytics';
import LoyaltyLeaderboard from './leaderboard/LoyaltyLeaderboard';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import NoDataCard from '@/components/element/cards/NoDataCard';
import { useLoyaltyCampaignMutations } from '../hooks/useLoyaltyCampaignMutations';
import TabOptions from '@/components/element/tabs/TabOptions';
import { LoyaltySectionTabs } from '../types/loyalty.enums';
import loyaltyImage from '@/assets/empty/light/loyalty.png';
import Submissions from '@/features/campaigns/components/campaign-report/sections/Submissions';
import DownloadLoyaltyLeaderboard from './settings/DownloadLoyaltyLeaderboard';

const LoyaltyCampaign = () => {
	const { selectedTab, setSelectedTab, isLoading, campaign, id } =
		useLoyaltyCampaign();
	const { launchLoyalty, isLaunching, toggleLoyaltyHide, isHiding } =
		useLoyaltyCampaignMutations();

	const options = [
		{
			value: LoyaltySectionTabs.Tasks,
			label: 'Tasks',
		},
		{
			value: LoyaltySectionTabs.Analytics,
			label: 'Analytics',
		},
		{
			value: LoyaltySectionTabs.Leaderboard,
			label: 'Leaderboard',
		},
		...(campaign?.quest?.tasks.some((i) => i.powVerifyConfig?.isPOWVerify)
			? [
					{
						value: LoyaltySectionTabs.Submissions,
						label: 'Submissions',
					},
				]
			: []),
	];

	if (isLoading) {
		return <FullPageLoading />;
	}
	if (!id) {
		return (
			<NoDataCard
				title="Launch your loyalty campaign"
				description="Create a new loyalty campaign to get started."
				btnLabel="Set Up Loyalty"
				btnOnClick={() => launchLoyalty()}
				isLoading={isLaunching}
				className="mt-10"
				image={loyaltyImage}
			/>
		);
	}

	if (!campaign.publishCampaign) {
		return (
			<NoDataCard
				title="Your loyalty campaign is not live"
				description="Make your loyalty campaign active for your users to do"
				btnLabel="Activate Loyalty"
				btnOnClick={() => toggleLoyaltyHide(true)}
				isLoading={isHiding}
				className="mt-10"
				image={loyaltyImage}
			/>
		);
	}
	return (
		<div>
			<div className="mt-8 flex justify-between mb-8">
				<TabOptions
					selected={selectedTab}
					setSelected={setSelectedTab}
					options={options}
				/>
				<DownloadLoyaltyLeaderboard campaignId={campaign?._id} />
			</div>
			{selectedTab === LoyaltySectionTabs.Tasks && <LoyaltyTaskSections />}
			{selectedTab === LoyaltySectionTabs.Analytics && <LoyaltyAnalytics />}
			{selectedTab === LoyaltySectionTabs.Submissions && (
				<Submissions campaign={campaign} />
			)}
			{selectedTab === LoyaltySectionTabs.Leaderboard && (
				<LoyaltyLeaderboard />
			)}
		</div>
	);
};

export default LoyaltyCampaign;
