import { Badge } from '@/components/ui/badge';
import { KOLCard } from '../components/KOLCard';
import { useEffect, useState } from 'react';
import KOLFilters from '../components/KOLFilters';
import useKolFilters from '../hooks/useKolFilters';
import { useQuery } from '@tanstack/react-query';
import { getKols } from '../services/kol-marketplace.service';
import { IKols } from '../hooks/useKOLMarketplace';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { set } from 'date-fns';

const KOLMarketplaceRoutes = () => {
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({
		language: [],
		region: [],
		channels: [],
		price: [],
	});
	const [kolsData, setKolsData] = useState<undefined | IKols[]>(undefined);

	const filteredKOLs = useKolFilters({ kolsData, search, filters });

	const kolsQuery = useQuery<IKols[]>({
		queryKey: ['kols-marketplace'],
		queryFn: () => getKols(),
	});

	useEffect(() => {
		if (!kolsQuery.data) return;
		setKolsData(kolsQuery.data);
	}, [kolsQuery.data]);

	if (kolsQuery.isLoading) return <FullPageLoading />;

	return (
		<div>
			<Badge className="rounded-full px-3" variant="outline">
				<i className="bi-stars me-1 text-yellow-600"></i>
				Build your Hype Tribe
			</Badge>
			<div className="text-2xl font-medium tracking-tight mt-4">
				KOLs Marketplace
			</div>
			<div className="text-md  mt-2">
				You need a
				<span className="px-1 text-violet-600 font-medium">
					right partner
				</span>{' '}
				to reach the world
			</div>
			<KOLFilters
				search={search}
				filters={filters}
				setSearch={setSearch}
				setFilters={setFilters}
			/>
			<div className="grid lg:grid-cols-3 2xl:grid-cols-4 gap-5 mt-8">
				{filteredKOLs.length > 0 ? (
					filteredKOLs
						?.slice(0, 50)
						?.map((kol: any) => <KOLCard kol={kol} />)
				) : (
					<div className="text-sm">No KOLs found</div>
				)}
			</div>
		</div>
	);
};

export default KOLMarketplaceRoutes;
