export enum IntegrationType {
	Discord = 'DISCORD',
	Telegram = 'TELEGRAM',
}

export enum DataBackupStatus {
	Error = 'ERROR',
	Started = 'STARTED',
	NotStarted = 'NOT_STARTED',
	InProgress = 'IN_PROGRESS',
	Complete = 'COMPLETE',
}

export enum BillingTier {
	Free = 'FREE',
	Paid = 'PAID',
}

export enum AnnouncementStatus {
	Active = 'ACTIVE',
	Inactive = 'INACTIVE',
}
