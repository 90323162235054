import { CardHeader, CardTitle } from '@/components/ui/card';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';

const RewardCardHeader = ({
	title,
	description,
	tooltip,
}: {
	title: string;
	description: string;
	tooltip?: string;
}) => {
	return (
		<CardHeader>
			<CardTitle>
				<div className="flex justify-between align-top">
					<div>
						<div className="font-medium">
							{title}
							{tooltip ? (
								<TooltipProvider delayDuration={0}>
									<Tooltip>
										<TooltipTrigger className="ms-2">
											<i className="bi-info-circle-fill block text-muted-foreground text-sm cursor-pointer"></i>
										</TooltipTrigger>
										<TooltipContent>
											<div className="text-sm max-w-[400px]">
												{tooltip}
											</div>
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							) : null}
						</div>
						<div className="font-normal text-xs text-muted-foreground mt-1">
							{description}
						</div>
					</div>
				</div>
			</CardTitle>
		</CardHeader>
	);
};

export default RewardCardHeader;
