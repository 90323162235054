import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogDescription,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import Spinner from '@/components/element/loading/Spinner';
import { useLoyaltyCampaignSections } from '../../hooks/useLoyaltyCampaignSections';

const AddLoyaltySection = ({
	open,
	setOpen,
}: {
	open: boolean;
	setOpen: (value: boolean) => void;
}) => {
	const { errors, title, setTitle, isAddingSection, addSection } =
		useLoyaltyCampaignSections({ setOpen, open });

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[525px] ">
				<DialogHeader className="border-b pb-3">
					<DialogTitle>Add Loyalty Section</DialogTitle>
					<DialogDescription>
						Make changes to your profile here. Click save when you're
						done.
					</DialogDescription>
				</DialogHeader>
				<InputText
					label="Section Title"
					value={title}
					setValue={(e) => setTitle(e)}
					error={errors?.sectionTitle}
					placeholder="Onboarding"
					errorText="Section title is required."
				/>
				<DialogFooter className="justify-between space-x-2 border-t pt-3 mt-">
					<Button
						disabled={isAddingSection}
						onClick={() => {
							addSection(title);
						}}
					>
						{isAddingSection && <Spinner className="me-2" />}
						<span>Create Section</span>
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default AddLoyaltySection;
