/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useState } from 'react';
import { updateCampaignsSettings } from '../../services/campaigns.service';
import { handleErrorMessage } from '@/utils/notifications';
import { toast } from 'sonner';
import { useIntegrations } from '@/features/integrations/hooks/useIntegrations';
import {
	AnnouncementStatus,
	IntegrationType,
} from '@/features/integrations/types/integrations.enums';
import { queryClient } from '@/lib/react-query';

export const useCampaignsSettings = (setOpen: any) => {
	const { integrations } = useIntegrations();
	const [isLoading, setIsLoading] = useState(false);
	const [settings, setSettings] = useState({
		isDiscordAnnouncement: false,
		announcementChannel: '',
	});

	useEffect(() => {
		const discord = integrations?.find(
			(i) => i.integrationType === IntegrationType.Discord,
		);
		if (discord) {
			setSettings({
				isDiscordAnnouncement:
					discord?.discord?.announcement?.status ==
					AnnouncementStatus.Active,
				announcementChannel: discord?.discord?.announcement?.channelId,
			});
		}
	}, [integrations]);

	const updateSettings = async () => {
		try {
			setIsLoading(true);
			await updateCampaignsSettings({
				announcementChannel: settings.announcementChannel,
				sendDiscordAnnouncement: settings.isDiscordAnnouncement,
			});
			queryClient.invalidateQueries({
				queryKey: ['integrations'],
			});
			toast.success('Settings updated successfully');
			setIsLoading(false);
			setOpen?.(false);
		} catch (err) {
			setIsLoading(false);
			handleErrorMessage(err);
			console.log(err);
		}
	};

	return {
		isLoading,
		updateSettings,
		settings,
		setSettings,
	};
};
