import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
	Command,
	CommandGroup,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectItem,
	SelectGroup,
	SelectContent,
	SelectLabel,
} from '@/components/ui/select';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';
import { cn } from '@/lib/utils';
import { CheckIcon } from '@radix-ui/react-icons';
import { useMemo } from 'react';
import ChainBadge from '../badges/ChainBadge';

export function SelectNetwork2({
	value,
	setValue,
	error,
	errorMsg,
	allowedChainIds,
	placeholder,
	isMulti,
	defaultAll = true,
	withoutPortal,
	disabled,
	showNonEVM,
}: {
	value: number | number[];
	isMulti?: boolean;
	setValue: (value: number | number[]) => void;
	allowedChainIds?: number[];
	error?: boolean | string;
	errorMsg?: string;
	placeholder?: string;
	defaultAll?: boolean;
	withoutPortal?: boolean;
	disabled?: boolean;
	showNonEVM?: boolean;
}) {
	const { chains } = useGetSupportedChains({
		showAdditionalChains: false,
	});

	const networks = useMemo(() => {
		if (!chains) return [];
		return [
			{
				title: 'EVM Chains',
				networks: chains
					.filter((chain) => !chain.isTestnet)
					?.map((i) => ({
						name: i.chainName,
						value: Number(i.chainId),
						chainId: Number(i.chainId),
						img: i.chainLogo,
					})),
			},
			...(showNonEVM
				? [
						{
							title: 'Non EVM Networks',
							networks: nonEVMChains?.map((i) => ({
								name: i.name,
								value: i.chainId as number,
								chainId: i.chainId as number,
								img: i.logo,
							})),
						},
					]
				: []),
			{
				title: 'EVM Testnets',
				networks: chains
					.filter((chain) => chain.isTestnet)
					?.map((i) => ({
						name: i.chainName,
						value: Number(i.chainId),
						chainId: Number(i.chainId),
						img: i.chainLogo,
					})),
			},
		]
			.map((network) => {
				if (!allowedChainIds || (allowedChainIds.length === 0 && defaultAll))
					return network;
				return {
					...network,
					networks: network.networks.filter((net: any) =>
						allowedChainIds.includes(net.chainId),
					),
				};
			})
			.filter((network) => network.networks.length > 0);
	}, [chains, allowedChainIds, defaultAll, showNonEVM]);

	const selectedValues = Array.isArray(value) ? value : [value];

	const handleSelect = (chainId: number) => {
		try {
			if (isMulti) {
				if (selectedValues.includes(chainId)) {
					setValue(selectedValues.filter((id) => id !== chainId));
				} else {
					setValue([...selectedValues, chainId]);
				}
			} else {
				setValue(chainId);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const isSelected = (chainId: number) => {
		return selectedValues.includes(chainId);
	};
	return (
		<>
			{isMulti ? (
				<Popover>
					<PopoverTrigger asChild disabled={disabled}>
						<Button
							variant="outline"
							className="w-full text-muted-foreground justify-between px-3"
							disabled={disabled}
						>
							{!selectedValues.length ? (
								<>
									<span>Select chain</span>
									<i className="bi-chevron-expand"></i>
								</>
							) : (
								<div className="hidden space-x-1 lg:flex truncate">
									{selectedValues.length > 2 ? (
										<>
											{selectedValues
												.slice(0, 2)
												.map((option) => (
													<Badge
														variant="secondary"
														key={option}
														className="rounded-sm px-1 font-normal truncate"
													>
														<ChainBadge
															chainId={option}
														/>
													</Badge>
												))}
											<Badge
												variant="secondary"
												className="rounded-sm px-1 font-normal"
											>
												+{selectedValues.length - 2} selected
											</Badge>
										</>
									) : (
										selectedValues?.map((option) => (
											<Badge
												variant="secondary"
												key={option}
												className="rounded-sm px-1 font-normal truncate"
											>
												<ChainBadge chainId={option} />
											</Badge>
										))
									)}
								</div>
							)}
						</Button>
					</PopoverTrigger>
					<PopoverContent
						align="start"
						className="p-0"
						withoutPortal={withoutPortal}
					>
						<Command>
							<CommandList className="">
								{networks?.map((network) => (
									<CommandGroup key={network.title}>
										<CommandItem className="hover:!bg-transparent cursor-default">
											{network.title}
										</CommandItem>
										{network.networks.map((option) => {
											const selected = isSelected(
												option.chainId,
											);
											return (
												<CommandItem
													key={option.chainId}
													onSelect={() =>
														handleSelect(option.chainId)
													}
													className="cursor-pointer"
												>
													<div
														className={cn(
															'mr-2 flex size-4 items-center justify-center rounded-sm border border-primary',
															selected
																? 'bg-primary text-primary-foreground'
																: 'opacity-50 [&_svg]:invisible',
														)}
													>
														{selected && (
															<CheckIcon
																className="size-4"
																aria-hidden="true"
															/>
														)}
													</div>
													{option.img && (
														<>
															<img
																src={option.img}
																alt=""
																className="h-5 w-5 me-2 rounded-lg object-cover"
															/>
															<span>
																{option.name}
															</span>
														</>
													)}
												</CommandItem>
											);
										})}
									</CommandGroup>
								))}
								{selectedValues?.length > 0 && (
									<>
										<CommandSeparator />
										<CommandGroup>
											<CommandItem
												onSelect={() => setValue([])}
												className="justify-center text-center"
											>
												Clear Selection
											</CommandItem>
										</CommandGroup>
									</>
								)}
							</CommandList>
						</Command>
					</PopoverContent>
				</Popover>
			) : (
				<Select
					value={value?.toString()}
					onValueChange={(newVal) => setValue(parseInt(newVal))}
					disabled={disabled}
				>
					<SelectTrigger
						className={`w-full bg-white ${
							error ? 'border-destructive' : ''
						} `}
					>
						<SelectValue placeholder={placeholder} />
					</SelectTrigger>

					<SelectContent className="">
						{networks?.map((network, index) => (
							<SelectGroup key={network.title}>
								<SelectLabel
									className={`text-muted-foreground text-xs ${
										index !== 0 ? 'mt-2' : ''
									}`}
								>
									{network.title}
								</SelectLabel>
								{network.networks.map((net: any) => (
									<SelectItem
										key={net.value}
										value={net.chainId.toString()}
									>
										<div className="flex py-1">
											<img
												src={net.img}
												alt=""
												className="h-5 w-5 me-2 rounded-lg object-cover"
											/>
											{net.name}
										</div>
									</SelectItem>
								))}
							</SelectGroup>
						))}
					</SelectContent>
				</Select>
			)}
			{error ? (
				<p className="text-destructive text-xs mt-1">{errorMsg || error}</p>
			) : null}
		</>
	);
}

const nonEVMChains = [
	{
		name: 'Solana',
		logo: 'https://upload.wikimedia.org/wikipedia/en/b/b9/Solana_logo.png',
		chainId: -10001,
	},
	{
		name: 'Bitcoin',
		logo: 'https://icons.llamao.fi/icons/chains/rsz_bitcoin?w=48&h=48',
		chainId: -10002,
	},
	{
		name: 'Sui',
		logo: 'https://icons.llamao.fi/icons/chains/rsz_sui?w=48&h=48',
		chainId: -10003,
	},
	{
		// sui, aptos, sei
		name: 'Hyperliquid',
		logo: 'https://icons.llamao.fi/icons/chains/rsz_hyperliquid?w=48&h=48',
		chainId: -10004,
	},
	{
		name: 'Aptos',
		logo: 'https://icons.llamao.fi/icons/chains/rsz_aptos?w=48&h=48',
		chainId: -10006,
	},
	{
		name: 'Starknet',
		logo: 'https://icons.llamao.fi/icons/chains/rsz_starknet?w=48&h=48',
		chainId: -10008,
	},
	{
		name: 'Injective',
		logo: 'https://icons.llamao.fi/icons/chains/rsz_injective?w=48&h=48',
		chainId: -10009,
	},
	{
		name: 'Cosmos',
		logo: 'https://oss.web3ite.io/logo/b1d031475299801e979dba921db0206c.jpg?x-oss-process=image/resize,w_800/quality,q_80/format,webp',
		chainId: -10020,
	},
];
