import { axios } from '@/lib/axios';
import { IAPIFormFields, IApiRequest } from '../types/task-apis.type';

export const testAPI = async (data: {
	type: string;
	apiDataFields: any;
	apiDataPassingMethod: string;
	apiEndpoint: string;
	apiHeaders: any;
	apiMethod: string;
	apiName: string;
}): Promise<{
	isResponseSchemaValid: boolean;
	response: any;
}> => {
	const response = await axios.post('api-requests/test', data);
	return response.data;
};

export const apiRequests = async (): Promise<IApiRequest[]> => {
	const response = await axios.get('api-requests');
	return response.data;
};

export const createAPI = async (data: {
	_id: string;
	apiDataFields: any;
	apiDataPassingMethod: string;
	apiEndpoint: string;
	apiHeaders: any;
	apiMethod: string;
	apiName: string;
	apiVerificationFor: string;
	isRecurringTaskEnabled: boolean;
}): Promise<IApiRequest> => {
	const response = await axios.post('api-requests', data);
	return response.data;
};

export const deleteTaskAPI = async (data: any) => {
	const response = await axios.delete(`api-requests`, {
		data,
	});
	return response.data;
};
