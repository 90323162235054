import InputSwitch from '@/components/element/inputs/InputSwitch';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogDescription,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import { useLoyaltyCampaign } from '../../hooks/useLoyaltyCampaign';
import InputText from '@/components/element/inputs/InputText';
import { useAuth } from '@/hooks/useAuth';
import { Button } from '@/components/ui/button';
import { useEffect, useState } from 'react';
import { convertToSlug } from '@/utils/parsers';
import Spinner from '@/components/element/loading/Spinner';
import { useLoyaltyCampaignMutations } from '../../hooks/useLoyaltyCampaignMutations';

const LoyaltySettingsDialog = ({ open, setOpen }) => {
	const { user } = useAuth();
	const { campaign } = useLoyaltyCampaign();
	const [settings, setSettings] = useState({
		slug: user.enterpriseSlug,
		isVisibleAfterCompletion: campaign?.metadata?.isVisibleAfterCompletion,
		isDiscordAnnouncement: campaign?.metadata?.isDiscordAnnouncement,
	});

	const { isHiding, toggleLoyaltyHide, saveLoyaltyCampaignSettings, isUpdating } =
		useLoyaltyCampaignMutations(setOpen);

	useEffect(() => {
		setSettings({
			slug: user.enterpriseSlug,
			isVisibleAfterCompletion: campaign?.metadata?.isVisibleAfterCompletion,
			isDiscordAnnouncement: campaign?.metadata?.isDiscordAnnouncement,
		});
	}, [campaign?.metadata, user.enterpriseSlug]);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[550px] px-0 pb-0">
				<DialogHeader className="border-b pb-3 px-5">
					<DialogTitle>Settings</DialogTitle>
					<DialogDescription>
						Configure your loyalty program settings.
					</DialogDescription>
				</DialogHeader>
				<div className="space-y-10 mt-4 px-5 mb-10">
					<div>
						<InputText
							label="Loyalty URL"
							subLabel="This is the unique identifier for your loyalty program. "
							subLabelClassName="text-muted-foreground mt-2 font-normal"
							value={settings.slug}
							setValue={(value) => {
								setSettings((p) => ({
									...p,
									slug: convertToSlug(value),
								}));
							}}
							placeholder="Enter loyalty program name"
							prepend={
								<div className="text-xs px-3 ps-4 text-muted-foreground flex items-center">
									<i className="bi-globe me-2"></i>
									https://intract.io/project/
								</div>
							}
						/>
					</div>
					<InputSwitch
						value={settings.isVisibleAfterCompletion}
						setValue={() => {
							setSettings({
								...settings,
								isVisibleAfterCompletion:
									!settings.isVisibleAfterCompletion,
							});
						}}
						label="Do you want to hide the task once it is completed by user?"
						subLabel="This will hide the task from the user once they have completed it."
						subLabelClassName="text-muted-foreground mt-2 font-normal"
					/>
					<InputSwitch
						value={settings.isDiscordAnnouncement}
						setValue={() => {
							setSettings({
								...settings,
								isDiscordAnnouncement:
									!settings.isDiscordAnnouncement,
							});
						}}
						label="Send a discord annoucement when the task is launched"
						subLabel="This will send a discord annoucement when the task is launched."
						subLabelClassName="text-muted-foreground mt-2 font-normal"
					/>
				</div>
				<DialogFooter className="border-t px-5 pt-3 pb-3">
					{campaign?.publishCampaign && (
						<Button
							variant="outline"
							onClick={() => toggleLoyaltyHide(false)}
							disabled={isUpdating || isHiding}
						>
							{isHiding ? (
								<Spinner className="me-2" />
							) : (
								<i className="bi-archive me-2"></i>
							)}
							<span>Archive Program</span>
						</Button>
					)}
					<Button
						onClick={() => saveLoyaltyCampaignSettings(settings)}
						disabled={isUpdating || isHiding}
					>
						{isUpdating && <Spinner className="me-2" />}
						<span>Save Changes</span>
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default LoyaltySettingsDialog;
