import InputSelect from '@/components/element/inputs/InputSelect';
import InputText from '@/components/element/inputs/InputText';
import { useLoyaltyTasks } from '@/features/loyalty-campaigns/hooks/tasks/useLoyaltyTasks';
import { cn } from '@/lib/utils';
import { uploadFile } from '@/services/utility.service';
import { useMemo, useRef, useState } from 'react';
import { toast } from 'sonner';

const TaskTitleAndLogo = ({ isUpdate }) => {
	const inputRef = useRef<HTMLInputElement>();

	const { task, setTask, template, setIsDirty, errors } = useLoyaltyTasks();
	const [fileLink, setFileLink] = useState<string | null>(
		task?.logo?.type === 'image' ? task?.logo?.value : null,
	);

	const options = useMemo(() => {
		if (template.minXp && template.maxXp) {
			const length = Math.ceil((template.maxXp - template.minXp + 1) / 5);
			return Array.from({ length }, (_, i) => {
				const value = i * 5 + template.minXp;
				return { value: value?.toString(), label: `${value} XP` };
			});
		}
		return [
			{
				value: task.xp?.toString(),
				label: task.xp + ' XP',
			},
		];
		return [];
	}, [task, template]);

	const uploadTaskIcon = async (file: any) => {
		return new Promise((resolve) => {
			toast.promise(
				Promise.all([
					uploadFile(file).then((res) => {
						resolve(res);
						setTask((prev) => ({
							...prev,
							logo: {
								type: 'image',
								value: res,
							},
						}));
					}),
				]),
				{
					loading: 'Uploading icon...',
					success: 'Task icon uploaded successfully',
					error: 'Failed to upload',
				},
			);
		});
	};

	return (
		<div className="flex item-end space-x-5 ">
			<div className="h-14 w-14 rounded bg-slate-100 py-1  flex items-center justify-center relative border">
				<div
					className="absolute top-0 cursor-pointer end-0 h-4 w-4 bg-slate-400 flex items-center justify-center -m-1 rounded-full"
					onClick={() => {
						inputRef.current.click();
					}}
				>
					<i className="bi-pencil-fill text-[8px] text-white cursor-pointer"></i>
				</div>
				{fileLink ? (
					<img src={fileLink} className="h-12 w-12 rounded" />
				) : (
					template.logo?.type === 'icon' && (
						<i
							className={cn(
								'text-4xl',
								template?.logo?.value,
								template?.color,
							)}
						></i>
					)
				)}
			</div>
			<InputText
				className="flex-grow"
				label="Task Title"
				placeholder="Add a title"
				value={task?.description}
				setValue={(e) => {
					setIsDirty(true);
					setTask({ ...task, description: e });
				}}
				error={errors?.description}
				errorText={errors?.description}
			/>
			{!isUpdate && (
				<div>
					<InputSelect
						className="w-28"
						label="XP"
						options={options}
						value={task.xp?.toString()}
						setValue={(e) => {
							setTask({ ...task, xp: parseInt(e) });
						}}
					/>
				</div>
			)}
			<input
				type="file"
				className="absolute top-0 w-0 -z-1 opacity-0"
				ref={inputRef}
				onChange={(e) => {
					const file = e.target.files[0];
					setFileLink(URL.createObjectURL(inputRef.current.files[0]));
					uploadTaskIcon(file);
				}}
				accept={'image/*'}
			/>
		</div>
	);
};

export default TaskTitleAndLogo;

// const SelectXP = ({ task }: { task: ICreateTasks }) => {
// 	const { editTask } = useCreateCampaignTasks();

// 	if (!options.length)
// 		return (
// 			<Badge variant="secondary" className="border border-slate-200 me-3">
// 				{task.xp} XP
// 			</Badge>
// 		);
// 	return (
// 		<Select
// 			value={task.xp?.toString()}
// 			onValueChange={(v) => {
// 				editTask(task.taskId, 'EDIT_XP', Number(v));
// 			}}
// 		>
// 			<SelectTrigger>
// 				<Badge
// 					variant="secondary"
// 					className="border border-slate-200 me-3 pe-0"
// 				>
// 					<SelectValue />
// 					<CaretSortIcon className="ml-1 h-4 w-4 shrink-0 opacity-50" />
// 				</Badge>
// 			</SelectTrigger>
// 			<SelectContent>
// 				{options.map((i) => (
// 					<SelectItem key={i.value} value={i.value?.toString()}>
// 						{i.label} XP
// 					</SelectItem>
// 				))}
// 			</SelectContent>
// 		</Select>
// 	);
// };
