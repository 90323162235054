import Spinner from '@/components/element/loading/Spinner';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import CreateEnterpriseDialog from '@/features/onboarding/components/create-enterprise/CreateEnterprise';
import { ChevronsUpDownIcon, PlusCircleIcon } from 'lucide-react';
import { useSwitchEnterprise } from '../../hooks/useSwitchEnterrpise';
import { useState } from 'react';
import { useQueryInitialise } from '@/hooks/useQueryInitialise';

export default function DashboardProject() {
	const [open, setOpen] = useState(false);
	const {
		handleSwitchEnterprise,
		enterprises,
		primaryEnterpriseIndex,
		isSwitching,
	} = useSwitchEnterprise();

	useQueryInitialise('create-enterprise', 'true', () => {
		setOpen(true);
	});

	return (
		<>
			<Avatar className="size-7">
				<AvatarImage
					src={enterprises[primaryEnterpriseIndex]?.logo}
					alt="@username"
				/>
				<AvatarFallback name={enterprises[primaryEnterpriseIndex]?.name} />
			</Avatar>

			{isSwitching ? (
				<span className="md:mx-3 ml-3 mr-1 text-sm w-[60px]">
					<Spinner />
				</span>
			) : (
				<span className="md:mx-3 ml-3 mr-1 text-sm flex items-center">
					<span>{enterprises[primaryEnterpriseIndex]?.name}</span>
					<span>
						{enterprises[primaryEnterpriseIndex]?.isApproved && (
							<i className="bi bi-patch-check-fill text-green-500 ms-3"></i>
						)}
					</span>
				</span>
			)}

			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button variant="ghost" size="sm" className="px-2">
						<ChevronsUpDownIcon className="text-muted-foreground size-4" />
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent className="w-[200px] max-h-[400px] overflow-y-auto space-y-2">
					<DropdownMenuLabel className="font-normal">
						Projects
					</DropdownMenuLabel>
					{enterprises.map((i) => (
						<DropdownMenuItem
							className="space-x-3"
							key={i._id}
							onClick={() => handleSwitchEnterprise(i._id)}
						>
							<Avatar className="size-4">
								<AvatarImage src={i.logo} alt="@tesla" />
								<AvatarFallback name={i.name} />
							</Avatar>
							<span>
								<span>{i.name}</span>
								{i.isApproved && (
									<i className="bi bi-patch-check-fill text-green-500 ms-1"></i>
								)}
							</span>
						</DropdownMenuItem>
					))}
					<DropdownMenuItem
						className="space-x-3"
						onClick={() => setOpen(true)}
					>
						<PlusCircleIcon className="size-4" />
						<span>Create Project</span>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
			<CreateEnterpriseDialog open={open} setOpen={setOpen} />
		</>
	);
}
