import LandingContainer from '../ui/LandingContainer';
import { careersContent } from '../../content/careers.content';

const JoinWiningCulture = () => {
	return (
		<LandingContainer>
			<div className="relative  space-y-16 md:space-y-32 my-20">
				<div className="grid   rounded-xl gap-4 md:gap-[36px]">
					<div className="double-border-card relative ">
						<div className="double-border-card__outer-border relative h-full w-full rounded-xl  md:border border-black-100 md:p-8">
							<div className="absolute -left-[0.5px] -top-[0.5px] z-10 h-4 w-4 overflow-hidden rounded-tl-xl md:h-16 md:w-16">
								<div className="double-border-card__diagonals--to-left" />
							</div>
							<div className="absolute -right-[0.5px] -top-[0.5px] z-10 h-4 w-4 rotate-90 overflow-hidden rounded-tl-xl md:h-16 md:w-16">
								<div className="double-border-card__diagonals--to-left" />
							</div>
							<div className="absolute -bottom-[0.5px] -left-[0.5px] z-10 h-4 w-4 -rotate-90 overflow-hidden rounded-bl-xl md:h-16 md:w-16">
								<div className="double-border-card__diagonals--bottom-left" />
							</div>
							<div className="absolute -bottom-[0.5px] -right-[0.5px] z-10 h-4 w-4 overflow-hidden rounded-br-xl md:h-16 md:w-16">
								<div className="double-border-card__diagonals--to-left" />
							</div>
							<div className="relative z-10 h-full w-full rounded-xl border border-black-10 outline-[12px] outline-white">
								<div className="flex flex-col justify-between gap-12 lg:flex-row-reverse  p-6 lg:p-[44px]">
									<img
										alt="Team 5"
										loading="lazy"
										width={1977}
										height={1698}
										decoding="async"
										data-nimg={1}
										className="transition-opacity rounded-xl  w-full object-cover !aspect-[468/302] !max-w-[468px] !object-right opacity-100"
										style={{ color: 'transparent' }}
										src={careersContent.winningCulture.img}
									/>
									<div className="flex flex-col space-y-4 md:space-y-8 h-full w-full items-start text-left md:items-start md:text-left md:justify-start">
										<div className="relative">
											<h2
												className="w-full transition-colors text-3xl md:custom text-black"
												style={{ maxWidth: 270 }}
											>
												{careersContent.winningCulture.title}
											</h2>
										</div>
										<div
											className="text-sm transition-colors text-zinc-500 w-full"
											style={{ maxWidth: 260 }}
										>
											<p className="">
												{
													careersContent.winningCulture
														.description
												}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</LandingContainer>
	);
};

export default JoinWiningCulture;
