import GeoChart from '@/components/charts/GeoChart';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import { useMemo } from 'react';
import worldmap from '@/assets/worldmap.json';

const UserGeoLocationMap = () => {
	const { campaign } = useCampaignReport();

	const data = useMemo(() => {
		if (
			!campaign ||
			!campaign?.geolocationData ||
			!campaign?.geolocationData.length
		)
			return [];
		return campaign?.geolocationData.map((i) => {
			const country = worldmap.features.find(
				(c) => c.properties.name === i.country,
			)?.id;
			return {
				value: i.count,
				id: country,
			};
		});
	}, [campaign?.geolocationData]);

	return (
		<Card className="mt-10">
			<CardHeader className="">
				<h2 className="text-lg font-semibold">User Locations</h2>
			</CardHeader>
			<CardContent className="p-0">
				<div className="h-[400px]">
					<GeoChart height={'360px'} data={data} />
					<div className="text-xs mt-3 px-3 text-muted-foreground">
						Geo data is only available for users who have participated
						after 20th April 2024.
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default UserGeoLocationMap;
