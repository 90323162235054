import { ITaskTemplates } from '@/features/campaigns/types';
import { Dispatch, SetStateAction, createContext, useState } from 'react';
import { ILoyaltyTask } from '../types/loyalty.type';
import { LoyaltyTaskCreateSteps } from '../types/loyalty.enums';

interface LoyaltyTaskProviderState {
	task: ILoyaltyTask;
	setTask: Dispatch<SetStateAction<ILoyaltyTask>>;
	showAddTask: boolean;
	setShowAddTask: (showAddTask: boolean) => void;
	selectedSectionId: string;
	setSelectedSectionId: (selectedSectionId: string) => void;
	template: ITaskTemplates;
	setTemplate: Dispatch<SetStateAction<ITaskTemplates>>;
	errors: any;
	setErrors: any;
	isDirty: boolean;
	setIsDirty: (isDirty: boolean) => void;
	step: LoyaltyTaskCreateSteps;
	setStep: (step: LoyaltyTaskCreateSteps) => void;
	isUpdate: boolean;
	setIsUpdate: (isUpdate: boolean) => void;
}

export const LoyaltyTaskContext = createContext<LoyaltyTaskProviderState>({
	task: null,
	setTask: () => {},
	showAddTask: false,
	setShowAddTask: () => {},
	selectedSectionId: '',
	setSelectedSectionId: () => {},
	template: null,
	errors: {},
	setErrors: () => {},
	isDirty: false,
	setIsDirty: () => {},
	step: LoyaltyTaskCreateSteps.TaskParams,
	setStep: () => {},
	setTemplate: () => {},
	isUpdate: false,
	setIsUpdate: () => {},
});

export function LoyaltyTaskProvider({ children }) {
	const [task, setTask] = useState<ILoyaltyTask>(null);
	const [errors, setErrors] = useState<any>({});
	const [showAddTask, setShowAddTask] = useState(false);
	const [template, setTemplate] = useState<ITaskTemplates>();
	const [selectedSectionId, setSelectedSectionId] = useState('');
	const [isDirty, setIsDirty] = useState(false);
	const [step, setStep] = useState(LoyaltyTaskCreateSteps.TaskParams);
	const [isUpdate, setIsUpdate] = useState(false);

	return (
		<LoyaltyTaskContext.Provider
			value={{
				task,
				setTask,
				showAddTask,
				setShowAddTask,
				selectedSectionId,
				setSelectedSectionId,
				template,
				errors,
				setErrors,
				isDirty,
				setIsDirty,
				step,
				setStep,
				setTemplate,
				isUpdate,
				setIsUpdate,
			}}
		>
			{children}
		</LoyaltyTaskContext.Provider>
	);
}
