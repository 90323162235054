import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import SeparatorWithText from '@/components/ui/seprator-with-text';
import { API_URL, QUEST_URL } from '@/config';
import { useProjectVerification } from '../../hooks/useProjectVerification';
import Spinner from '@/components/element/loading/Spinner';
import { useGetEnterprise } from '../../hooks/useGetEnterprise';
import { Badge } from '@/components/ui/badge';
import { links } from '@/config/links';
import { Media } from '@/components/element/media/Media';

const VerifyProjectsDialog = ({ open, setOpen }: any) => {
	const { enterprise } = useGetEnterprise();
	const { isAuthenticating, inputRef, uploadScreenshot, setIsAuthenticating } =
		useProjectVerification(setOpen);

	const isCompleted =
		enterprise?.approvalData?.discord?.discordId &&
		enterprise?.approvalData?.twitter?.twitterId &&
		enterprise?.approvalData?.announcementScreenshot;

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[565px] p-0">
				<DialogHeader className="flex flex-row items-end justify-between pt-8 px-8 pb-5">
					<div>
						<Badge className="rounded-full px-3 mb-3" variant="outline">
							<i className="bi-rocket-takeoff-fill me-1 text-red-600"></i>
							Unlock Your Reach
						</Badge>
						<DialogTitle className="text-2xl font-medium">
							<span className="text-3xl">Engage with 10M+ Users!</span>
						</DialogTitle>
						<p className="text-sm mt-4 ">
							Welcome to the first step in connecting your project with
							our vibrant community of over
							<strong className="px-1">10 million</strong>
							members!
						</p>
					</div>
					<div className="h-32 min-w-32 border rounded-full flex items-center justify-between p-3 relative">
						<div className="h-[7.3rem] w-[7.3rem] border rounded-full flex items-center justify-between p-3 absolute z-0 bg-slate-100  top-0 start-0 bottom-0 end-0 mx-auto my-auto"></div>
						<div className="absolute bottom-0 end-0 p-3 bg-white w-10 h-10 rounded-full flex items-center justify-center border text-2xl z-10">
							🚀
						</div>
						<Media
							src={enterprise?.logo}
							className="h-full w-full relative z-[3] object-cover rounded-full"
						/>
					</div>
				</DialogHeader>
				<div className="min-h-[200px] bg-slate-100">
					<div className="p-8">
						{isCompleted ? (
							<div className="mb-10 text-center py-20">
								<div className="text-lg font-medium">
									You're all set!
								</div>
								<div className=" text-muted-foreground">
									Your project will be verified shortly.
								</div>
							</div>
						) : (
							<div className="flex flex-col space-y-4">
								<div className="border shadow rounded-2xl flex items-center justify-between p-3 bg-white">
									<div className="flex items-center">
										<div className="h-10 w-10 border rounded-full flex items-center justify-center me-3">
											<i className="bi bi-discord text-xl text-blue-600"></i>
										</div>
										<div className="">
											<div className="text-sm font-medium">
												Connect Project Discord
											</div>
											<div className="text-xs text-muted-foreground">
												Connect your project's Discord server
												to our platform.
											</div>
										</div>
									</div>
									{enterprise?.approvalData?.discord?.discordId ? (
										<Badge variant="secondary">
											<i className="bi-check-circle-fill text-green-500 me-1"></i>
											Completed
										</Badge>
									) : (
										<Button
											className="px-6"
											size="sm"
											onClick={() => {
												setIsAuthenticating((p) => ({
													...p,
													discord: true,
												}));
												window.location.href = `${API_URL}/api/v1/enterprise/approval/discord`;
											}}
											disabled={isAuthenticating.discord}
										>
											{isAuthenticating.discord && (
												<Spinner className="me-2" />
											)}
											<span>Connect</span>
										</Button>
									)}
								</div>
								<div className="border shadow rounded-2xl flex items-center justify-between p-3 bg-white ">
									<div className="flex items-center">
										<div className="h-10 w-10 border rounded-full flex items-center justify-center me-3">
											<i className="bi bi-twitter text-xl text-blue-500"></i>
										</div>
										<div className="">
											<div className="text-sm font-medium">
												Connect Project Twitter
											</div>
											<div className="text-xs text-muted-foreground">
												Connect your project's Twitter to our
												platform.
											</div>
										</div>
									</div>
									{enterprise?.approvalData?.twitter?.twitterId ? (
										<Badge variant="secondary">
											<i className="bi-check-circle-fill text-green-500 me-1"></i>
											Completed
										</Badge>
									) : (
										<Button
											className="px-6"
											size="sm"
											onClick={() => {
												setIsAuthenticating((p) => ({
													...p,
													twitter: true,
												}));
												window.location.href = `${API_URL}/api/v1/enterprise/approval/twitter`;
											}}
											disabled={isAuthenticating.twitter}
										>
											{isAuthenticating.twitter && (
												<Spinner className="me-2" />
											)}
											<span>Connect</span>
										</Button>
									)}
								</div>
								<div>
									<SeparatorWithText
										text="Want to speed up the approval process?"
										align="start"
										className="ps-6 mt-7"
										textClassName="bg-slate-100 text-muted-foreground text-xs px-2 py-1 rounded-full"
									/>
								</div>
								<div className="border  rounded-2xl flex items-center justify-between p-3 bg-white">
									<div className="flex items-center pe-6">
										<div className="h-10 min-w-10 border rounded-full flex items-center justify-center me-3">
											<i className="bi bi-megaphone-fill text-xl text-red-500"></i>
										</div>
										<div className="">
											<div className="text-sm font-medium mb-1">
												Announce Your Community Hub
											</div>
											<div className="text-xs text-muted-foreground">
												Announce launch of your hub on your
												Discord and upload the announcement
												screenshot.
											</div>
										</div>
									</div>
									{enterprise?.approvalData
										?.announcementScreenshot ? (
										<Badge variant="secondary">
											<i className="bi-check-circle-fill text-green-500 me-1"></i>
											Completed
										</Badge>
									) : (
										<Button
											className="px-8"
											size="sm"
											onClick={() => {
												inputRef?.current?.click();
											}}
											disabled={isAuthenticating.upload}
										>
											{isAuthenticating.upload && (
												<Spinner className="me-2" />
											)}
											<span>Upload</span>
										</Button>
									)}
								</div>
							</div>
						)}

						<Separator className="my-6 " />
						<div>
							<div className="text-sm">
								<strong className="me-1">
									Prefer a quick chat?
								</strong>
								<span>
									Connect with us on Telegram and let's get you
									verified in no time!
								</span>
							</div>
							<Button
								variant="outline"
								size="sm"
								className="mt-3"
								onClick={() => {
									window.open(links.telegram, '_blank');
								}}
							>
								<span>Connect on Telegram</span>
								<i className="bi bi-telegram ms-1"></i>
							</Button>
						</div>
					</div>
				</div>
				<input
					type="file"
					className="absolute top-0 w-0 -z-1 opacity-0"
					ref={inputRef}
					onChange={(e) => {
						const file = e.target.files[0];
						uploadScreenshot(file);
					}}
					accept={'images/*'}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default VerifyProjectsDialog;
