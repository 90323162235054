import {
	Bar,
	BarChart,
	Legend,
	ResponsiveContainer,
	XAxis,
	YAxis,
	Tooltip,
} from 'recharts';

const BarChartComponent = ({
	data,
	legends,
	xAxisKey,
	tooltipFn,
	xAxisKeyLength,
}: {
	data: { [key: string]: any }[];
	legends?: { key: string; label: string; opacity: number }[];
	xAxisKey?: string;
	tooltipFn?: (props: any) => JSX.Element;
	xAxisKeyLength?: number;
}) => {
	const CustomTick = (props) => {
		const { x, y, index, xAxisKeyLength } = props;

		if (index + 1 > xAxisKeyLength) return null;

		const taskNumber = index + 1;
		const displayValue = `Task ${taskNumber}`;

		return (
			<g transform={`translate(${x},${y + 20})`}>
				<text
					x={0}
					y={0}
					textAnchor="middle"
					fill="#666"
					style={{
						fontSize: '0.875rem',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
					}}
				>
					{displayValue}
				</text>
			</g>
		);
	};

	return (
		<ResponsiveContainer width="100%">
			<BarChart data={data}>
				<XAxis
					dataKey={xAxisKey}
					stroke="#888888"
					fontSize={12}
					tickLine={false}
					axisLine={false}
					height={40}
					minTickGap={3}
					angle={20}
					tick={(props) => (
						<CustomTick
							{...props}
							index={props.index}
							xAxisKeyLength={xAxisKeyLength}
						/>
					)}
				/>
				<Tooltip content={tooltipFn} />
				<YAxis
					stroke="#888888"
					fontSize={12}
					tickLine={false}
					axisLine={false}
					tickFormatter={(value) => `${value}`}
					// domain={['0', '0']}
				/>

				{legends.map((i) => (
					<Bar
						key={i.key}
						dataKey={i.key}
						fill="currentColor"
						radius={[4, 4, 0, 0]}
						className="fill-primary"
						opacity={i.opacity}
					/>
				))}
			</BarChart>
		</ResponsiveContainer>
	);
};

export default BarChartComponent;
