import { FacetedFilter } from '@/components/element/inputs/FacetedFilter';
import InputText from '@/components/element/inputs/InputText';
import {
	EnterpriseApprovalStatus,
	EnterpriseOnboardingStage,
} from '@/features/dashboard/types/enterprise.types';

const DaveFilters = ({
	search,
	filters,
	setSearch,
	setFilters,
}: {
	search: string;
	filters: {
		chains: string[];
	};
	setSearch: any;
	setFilters: any;
}) => {
	return (
		<div className="flex items-center mt-10 space-x-4">
			<InputText
				value={search}
				setValue={setSearch as any}
				prepend={
					<i className="bi-search px-3 text-muted-foreground text-sm"></i>
				}
				placeholder="Search by project name or email"
				className="w-[250px] lg:w-[350px]"
			/>
			<FacetedFilter
				title="Chain"
				options={[]}
				selectedValues={filters.chains}
				setSelectedValues={(values: any) =>
					setFilters((p) => ({ ...p, chains: values }))
				}
			/>
		</div>
	);
};

export default DaveFilters;
