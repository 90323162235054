import { Dispatch, SetStateAction } from 'react';
import InputText from '@/components/element/inputs/InputText';
import InputFile from '@/components/element/inputs/InputFile';
import InputMultiSelect from '@/components/element/inputs/InputMultiSelect';
import { IOnboardingCommunity } from '../../hooks/useOnboarding';
import { SelectNetwork2 } from '@/components/element/inputs/SelectNetworks2';
import { SelectNetworksTag } from '@/components/element/inputs/SelectNetworksTab';

const SetUpNewCommunityForm = ({
	community,
	setCommunity,
	errors,
}: {
	community: IOnboardingCommunity;
	setCommunity: Dispatch<SetStateAction<IOnboardingCommunity>>;
	errors: {
		name: boolean;
		logo: boolean;
		category: boolean;
	};
}) => {
	return (
		<>
			<InputFile
				label="Project Logo"
				required={true}
				placeholder="Elon Musk"
				value={community.logoFile}
				setValue={(e) =>
					setCommunity({
						...community,
						logoFile: e,
					})
				}
				error={errors.logo}
				description="Upload a logo. Recommended size is 200x200px"
			/>
			<InputText
				label="Project Name"
				required={true}
				placeholder="Uniswap"
				value={community.name}
				setValue={(e) =>
					setCommunity({
						...community,
						name: e,
					})
				}
				error={errors.name}
			/>
			<InputMultiSelect
				value={community.category}
				setValue={(e) => setCommunity({ ...community, category: e })}
				options={categories}
				label="What describes you the best?"
				required={true}
				placeholder="Select Category"
				error={errors.category}
				withoutPortal={true}
			/>
			<div className="">
				<div className="text-sm font-medium mb-2">Supported Chain</div>
				<SelectNetworksTag
					placeholder="Select a chain"
					value={community?.chainIds}
					setValue={(newVal) =>
						setCommunity((prev: any) => ({
							...prev,
							chainIds: newVal,
						}))
					}
					errorMsg={'Please select at least one chain'}
					isMulti
					withoutPortal={true}
				/>
			</div>
		</>
	);
};

export default SetUpNewCommunityForm;

const categories_ = [
	'DeFi',
	'NFT',
	'Memecoins',
	'Gaming',
	'Dex',
	'Staking',
	'Bridge',
	'Collectibles',
	'YieldFarming',
	'DID',
	'Infrastructure',
	'Metaverse',
	'DAO',
	'Social',
	'DeSci',
];
const categories: {
	label: string;
	value: string;
}[] = categories_.map((category) => ({
	label: category,
	value: category,
}));
