import { Label } from '@/components/ui/label';
import CampaignAdvancedConfig from './advanced/CampaignAdvancedConfig';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import InputText from '@/components/element/inputs/InputText';
import { SelectNetwork2 } from '@/components/element/inputs/SelectNetworks2';
import DropzoneWithReposition from '@/components/element/dropzone/DropzoneWithReposition';
import InputDuration from '@/components/element/inputs/input-duration/InputDuration';
import { QuillEditor } from '@/components/element/rich-text-editor/QuillEditor';

const AddDetails = () => {
	const { mode, details, setDetails, setIsDirty, errors } = useCreateCampaign();

	const editDetails = (
		key: string,
		value: string | Date | boolean | { x: number; y: number },
	) => {
		setIsDirty(true);
		setDetails((prev) => ({ ...prev, [key]: value }));
	};

	return (
		<div>
			<div className="flex justify-center items-center py-10">
				<div className="mb-5 w-[600px] relative">
					<div className="grid grid-cols-12 gap-4 second-element">
						<InputText
							label="Title"
							placeholder="Launch on Intract"
							className="col-span-8"
							value={details.name}
							setValue={(e) => editDetails('name', e)}
							error={errors?.details?.name}
							inputClassName="bg-white dark:bg-black"
							errorText={
								errors?.details?.name || 'Please enter a valid title'
							}
						/>
						<div className="col-span-4">
							<Label className="font- mb-2 block">Chain</Label>
							<SelectNetwork2
								value={details.network}
								setValue={(e) =>
									setDetails({ ...details, network: e as number })
								}
								showNonEVM
							/>
						</div>
					</div>
					<div className="mt-10">
						<Label className="font-normal mb-3 block">Description</Label>
						<QuillEditor
							value={details.description}
							setValue={(e) => editDetails('description', e)}
							placeholder="Describe your campaign here..."
							error={errors?.details?.description}
							errorText="Please enter a valid description"
						/>
					</div>
					<DropzoneWithReposition
						className="mt-10"
						file={details.bannerFile}
						fileLink={details.bannerLink}
						clearSelection={() => {
							setDetails((p) => ({
								...p,
								bannerFile: null,
								bannerLink: '',
							}));
						}}
						setFile={(file: File | File[], fileLink: string) => {
							setDetails((prev) => ({
								...prev,
								bannerFile: file,
								bannerLink: fileLink,
							}));
						}}
						description="Recommended size: 1920x1080px"
						label="Banner"
						cta="Upload banner"
						aspectRatio={1920 / 1080}
						error={errors?.details?.bannerFile}
						errorText="Please upload a valid image or video file."
						onImageReposition={({ x, y }) => {
							setDetails((prev) => ({
								...prev,
								bannerPosition: { x, y },
							}));
						}}
						bannerPosition={details.bannerPosition}
						allowedFiles={['images']}
						maxSize={3}
					/>
					<div className="my-10 mt-16">
						<div>
							<div className="mb-3">
								<div className="text-sm font-medium">
									<i className="bi-calendar me-2"></i>
									Campaign Durations
								</div>
								<div className="text-xs text-muted-foreground mt-1">
									Set the duration of the campaign
								</div>
							</div>
							<InputDuration
								className=""
								startDate={details?.startDate}
								endDate={details?.endDate}
								setStartDate={(date) =>
									editDetails('startDate', date)
								}
								setEndDate={(date) => editDetails('endDate', date)}
								startImmediately={details?.startImmediately}
								setStartImmediately={(e) =>
									editDetails('startImmediately', e)
								}
								timezone={details?.timezone}
								setTimezone={(e) => editDetails('timezone', e)}
								noDeadline={details?.noEndTime}
								setNoDeadline={(e) => editDetails('noEndTime', e)}
							/>
						</div>
						{errors?.details?.duration && (
							<p className="text-red-500 text-xs mt-3">
								{errors?.details?.duration ||
									'Please enter a valid duration.'}
							</p>
						)}
					</div>

					{mode !== 'simple' && <CampaignAdvancedConfig />}
				</div>
			</div>
		</div>
	);
};

export default AddDetails;
