import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { IDaveActions, IDaveQueries } from '../../../types/dave.types';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import { Badge } from '@/components/ui/badge';
import ChainBadge from '@/components/element/badges/ChainBadge';

export const queriesColumns = (
	options: any,
	actions: IDaveActions[],
): ColumnDef<IDaveQueries>[] => {
	return [
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Name" />
			),
			cell: ({ row }) => <div className="py-1">{row.getValue('name')}</div>,
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'description',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Description" />
			),
			cell: ({ row }) => (
				<div className=" py-1">{row.getValue('description')}</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'chainId',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="ChainId" />
			),
			cell: ({ row }) => {
				const chains = row.original?.queryList?.map((q) => q.chainId) ?? [];
				if (chains.length > 0) {
					return chains
						?.filter(onlyUnique)
						.map((chainId) => (
							<ChainBadge
								chainId={chainId}
								className=" py-1 flex items-center"
							/>
						));
				}
			},
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'actions',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Actions" />
			),
			cell: ({ row }) => {
				const actionTags =
					row.original?.queryList?.map((q) => q.actionTag) ?? [];
				const actionNames =
					actions
						?.filter((a) => actionTags.includes(a.actionTag))
						?.map((a) => a.name) ?? [];
				return (
					<div className=" py-1">
						{actionNames.map((name) => (
							<Badge variant="secondary" className="me-2">
								{name}
							</Badge>
						))}
					</div>
				);
			},
			enableSorting: true,
			enableHiding: false,
		},
		{
			id: 'actions',
			cell: ({ row }) => <DataTableRowActions row={row} options={options} />,
		},
	];
};

const onlyUnique = (value: any, index: any, self: any) => {
	return self.indexOf(value) === index;
};
