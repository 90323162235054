import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import { cn } from '@/lib/utils';
import clsx from 'clsx';
import React, { useState, useEffect, useRef } from 'react';

const ImageControl = ({
	aspectRatio,
	backgroundImage,
	error,
	errorText,
	onImageReposition,
	imagePosition,
	lockBodyScroll = true,
}: {
	aspectRatio: number;
	backgroundImage: string;
	error?: boolean | string;
	errorText?: string;
	onImageReposition?: ({ x, y }: { x: number; y: number }) => void;
	imagePosition?: { x: number; y: number };
	lockBodyScroll?: boolean;
}) => {
	const imageContainerRef = useRef(null);
	const [backgroundPosition, setBackgroundPosition] = useState({ x: 0, y: 0 });
	const [isDragging, setIsDragging] = useState(false);
	useLockBodyScroll(lockBodyScroll && isDragging);

	useEffect(() => {
		if (imagePosition) {
			setBackgroundPosition(imagePosition);
		}
	}, [imagePosition]);

	useEffect(() => {
		const handleMouseMove = (event: any) => {
			if (!imageContainerRef.current) return;
			setIsDragging(true);

			const patternBackground = imageContainerRef.current;
			const patternBackgroundWidth = patternBackground.offsetWidth;
			const mouseDownX = event.pageX;
			const mouseDownY = event.pageY;

			const moveHandler = (moveEvent: any) => {
				const mousePosX = moveEvent.pageX;
				const mousePosY = moveEvent.pageY;

				const movePercentageX =
					(100 * (mousePosX - mouseDownX)) / patternBackgroundWidth;
				const movePercentageY =
					(100 * (mousePosY - mouseDownY)) / patternBackgroundWidth;

				const x = backgroundPosition.x - movePercentageX;
				const y = backgroundPosition.y - movePercentageY;
				setBackgroundPosition({
					x: x > 100 ? 100 : x < 0 ? 0 : x,
					y: y > 100 ? 100 : y < 0 ? 0 : y,
				});
				onImageReposition?.({
					x: x > 100 ? 100 : x < 0 ? 0 : x,
					y: y > 100 ? 100 : y < 0 ? 0 : y,
				});
			};

			document.addEventListener('mousemove', moveHandler);
			document.addEventListener('mouseup', () => {
				setIsDragging(false);
				document.removeEventListener('mousemove', moveHandler);
			});
		};

		const patternBackground = imageContainerRef.current;
		patternBackground.addEventListener('mousedown', handleMouseMove);

		return () => {
			if (patternBackground) {
				patternBackground.removeEventListener('mousedown', handleMouseMove);
			}
		};
	}, [backgroundPosition, onImageReposition]);

	return (
		<div className=" group">
			<div
				className={`border  overflow-hidden w-full rounded border-dashed group ${
					error ? 'border-destructive' : 'border-gray-300'
				} bg-gray-100 relative z-39`}
				style={{
					paddingBottom: `calc(100% / (${aspectRatio}))`,
				}}
			>
				<div
					className={cn(
						'fixed inset-0 bg-black transition-opacity duration-300 z-[111150]',
						isDragging ? 'opacity-50' : 'opacity-0 pointer-events-none',
					)}
				></div>

				<div
					ref={imageContainerRef}
					className="rounded-md relative"
					style={{
						width: '98%',
						height: '94%',
						top: '3%',
						left: '1%',
						position: 'absolute',
						overflow: 'hidden',
						cursor: 'move',
						backgroundImage: `url(${backgroundImage})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						backgroundPosition: `${backgroundPosition.x}% ${backgroundPosition.y}%`,
					}}
				>
					<div
						className={cn(
							'absolute top-0 bottom-0 start-0 end-0 mx-auto my-auto h-full w-full  items-center justify-center flex hover:opacity-90',
							isDragging ? 'opacity-90' : 'opacity-0 ',
						)}
					>
						<div
							className=" pointer-events-none select-none px-3 py-1 rounded-full bg-black bg-opacity-50 text-white text-xs font-semibold"
							unselectable="on"
						>
							Drag image to reposition
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ImageControl;
