import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { ISlides } from '@/features/campaigns/types';
import InputText from '@/components/element/inputs/InputText';
import { cn } from '@/lib/utils';
import { QuillEditor } from '@/components/element/rich-text-editor/QuillEditor';
import { v4 as uuidv4 } from 'uuid';

interface IEditingSlides {
	id: string;
	title: string;
	content: string;
}

const AddContentDialog = ({
	open,
	setOpen,
	slides: slides_,
	setSlides: setSlides_,
}: {
	open: boolean;
	setOpen: any;
	slides: ISlides[];
	setSlides: (slides: ISlides[]) => void;
}) => {
	const [slides, setSlides] = useState<IEditingSlides[]>([]);
	const [activeSlideId, setActiveSlideId] = useState('');
	const [error, setError] = useState([]);

	const addNewSlide = () => {
		const id = uuidv4();
		const newSlide = { title: '', content: '', id: id };
		setSlides((prev) => [...prev, newSlide]);
		setError((p) => [...p, { title: false, content: false, id }]);
		setActiveSlideId(id);
	};

	useEffect(() => {
		const slides__ = slides_?.map((i) => ({
			id: uuidv4(),
			title: i.title,
			content: i.content,
		}));
		setSlides(slides__);
		setError(
			slides__?.map(({ id }) => ({
				id,
				content: false,
				title: false,
			})),
		);
	}, [slides_]);

	const editSlide = (key: string, value: string, id: string) => {
		const newSlides = [...slides];
		const newErrors = [...error];
		const index = newSlides.findIndex((i) => i.id === id);
		newSlides[index][key] = value;
		newErrors[index][key] = false;
		setSlides(newSlides);
		setError(newErrors);
	};

	const saveSlides = () => {
		if (slides?.every((i) => !i.title && !i.content)) {
			setSlides_([]);
			setOpen(false);
			return;
		}
		const newErrors = [];
		let isValid = true;
		for (const slide of slides) {
			const newErrors_ = {
				id: slide.id,
				title: false,
				content: false,
			};
			if (!slide.title || slide.title.length < 10) {
				newErrors_.title = true;
				isValid = false;
			}
			if (!slide.content || slide.content.length < 50) {
				newErrors_.content = true;
				isValid = false;
			}
			newErrors.push(newErrors_);
		}
		setError(newErrors);
		if (isValid) {
			setSlides_(
				slides.map((i) => ({
					title: i.title,
					content: i.content,
				})),
			);
			setOpen(false);
		}
	};

	const cancel = () => {
		if (slides_?.every((i) => !i.title && !i.content)) {
			setSlides_([]);
		}
		setOpen(false);
	};

	const deleteSlide = (id: string) => {
		const slidesIndex = slides.findIndex((i) => i.id === id);
		setSlides((prev) => {
			const newSlides = [...prev];
			newSlides.splice(slidesIndex, 1);
			return newSlides;
		});
		const errorIndex = error.findIndex((i) => i.id === id);
		setError((prev) => {
			const newError = [...prev];
			newError.splice(errorIndex, 1);
			return newError;
		});
		if (activeSlideId === id) {
			setActiveSlideId('');
		}
	};

	return (
		<Dialog open={open} onOpenChange={() => {}}>
			<DialogContent className="sm:max-w-[925px] p-0 gap-0">
				<DialogHeader className=" border-b p-5 flex flex-row items-center justify-between">
					<div className="flex">
						<i className="bi bi-easel2 text-4xl text-muted-foreground me-3"></i>
						<div>
							<DialogTitle className="mb-2 font-medium">
								Add Education Slides in your Task
							</DialogTitle>
							<p className="text-muted-foreground text-sm">
								Add content slides in your task to help users
								understand.
							</p>
						</div>
					</div>
					<div>
						<Button onClick={() => {}} variant="outline">
							Learn more
						</Button>
					</div>
				</DialogHeader>
				<div className="grid grid-cols-8 p-0 min-h-[500px]">
					<Slides
						slides={slides}
						activeSlideId={activeSlideId}
						setActiveSlideId={setActiveSlideId}
						addNewSlide={addNewSlide}
						error={error}
						deleteSlide={deleteSlide}
					/>
					{activeSlideId && slides.find((i) => i.id === activeSlideId) && (
						<SlideContent
							title={
								slides?.find((i) => i.id === activeSlideId)?.title
							}
							content={
								slides?.find((i) => i.id === activeSlideId)?.content
							}
							id={activeSlideId}
							editSlide={editSlide}
							error={error?.find((i) => i.id === activeSlideId)}
							key={activeSlideId}
						/>
					)}
				</div>
				<DialogFooter className="border-t p-5 py-3">
					<Button
						onClick={() => cancel()}
						className="px-10"
						size="lg"
						variant="outline"
					>
						Cancel
					</Button>
					<Button onClick={() => saveSlides()} className="px-20" size="lg">
						Save
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default AddContentDialog;

const SlideContent = ({ title, content, editSlide, error, id }) => {
	return (
		<div className="col-span-6 p-4">
			<div className="mb-4">
				<InputText
					placeholder="Add Slide Title"
					inputClassName=" font-medium text-lg py-6"
					value={title}
					setValue={(e) => editSlide('title', e, id)}
					errorText="Please add title of the slide. Mininum 10 characters are required"
					error={error?.title}
					prepend={
						<i className="bi bi-card-heading px-3 text-muted-foreground text-xl"></i>
					}
					wrapperClassName=" w-full bg-slate-50 rounded-lg border"
				/>
			</div>
			<div className="bg-slate-50 rounded-lg border">
				<QuillEditor
					value={content}
					setValue={(e) => editSlide('content', e, id)}
					errorText="Please add contetn of the slide. Mininum 50 characters are required."
					error={error?.content}
					size="lg"
				/>
			</div>
		</div>
	);
};

const Slides = ({
	slides,
	activeSlideId,
	setActiveSlideId,
	addNewSlide,
	error,
	deleteSlide,
}) => {
	return (
		<div className="col-span-2 border-e p-4 space-y-2 overflow-auto h-full bg-slate-50 max-h-[500px]">
			{slides.map((i: IEditingSlides) => {
				const error_ = error.find((i) => i.id === activeSlideId);
				return (
					<div
						className={cn(
							`h-24 w-full border rounded-lg cursor-pointer px-2 py-3 bg-white relative`,
							i.id === activeSlideId ? 'border-black' : '',
							error_?.title || error_?.content
								? 'border-destructive'
								: '',
						)}
						onClick={() => setActiveSlideId(i.id)}
					>
						<div className="absolute top-2 right-2">
							<i
								className="bi bi-trash text-xs text-muted-foreground cursor-pointer hover:text-primary"
								onClick={() => deleteSlide(i.id)}
							></i>
						</div>
						{i.title ? (
							<div className="text-xs font-medium truncate">
								{i.title}
							</div>
						) : (
							<div className="text-xs text-muted-foreground font-medium">
								Untitled Slide
							</div>
						)}
						<div className="text-[8px] mt-1 text-muted-foreground h-[48px] overflow-hidden">
							{i.content ? (
								<p
									dangerouslySetInnerHTML={{ __html: i.content }}
								></p>
							) : (
								'No content'
							)}
						</div>
					</div>
				);
			})}

			<AddNewSlide addNewSlide={addNewSlide} />
		</div>
	);
};

const AddNewSlide = ({ addNewSlide }) => {
	return (
		<div
			className="h-24 w-full border rounded-lg flex bg-white items-center justify-center border-dashed cursor-pointer hover:border-black"
			onClick={() => addNewSlide()}
		>
			<i className="bi bi-plus-circle-fill text-muted-foreground text-2xl"></i>
		</div>
	);
};
