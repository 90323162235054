import React from 'react';
import {
	IAdminEnterprise,
	IEnterpriseFeatureUsage,
	IEnterpriseSimilarProjects,
} from '../../types/admin.types';
import { JSONViewer } from '@/components/element/json/JSONViewer';
import { convertToTitleCase, formatCompactNumber } from '@/utils/parsers';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import ChainBadge from '@/components/element/badges/ChainBadge';
import { EnterpriseOnboardingStage } from '@/features/dashboard/types/enterprise.types';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { QUEST_URL } from '@/config';
import { Status } from '@/features/campaigns/types';
import { CustomTokenStatus } from '@/features/campaigns/hooks/tokens/useCreateToken';

export const ApprovalData = ({
	enterprise,
	isLoading,
}: {
	enterprise: IAdminEnterprise;
	isLoading: boolean;
}) => {
	if (isLoading) return <div>Loading...</div>;
	const approvalData = enterprise?.approvalData;
	return (
		<div className="mx-2">
			<div className="mb-10">
				<div className="font-medium mb-3 text-sm">Enterprise Data</div>
				<JSONViewer
					data={[
						{
							icon: 'building',
							label: 'Enterprise Name',
							value: (
								<div className="flex items-center py-1 max-w-[450px] truncate">
									<Avatar className="h-5 w-5 me-2">
										<AvatarImage
											src={enterprise?.enterpriseData?.logo}
											alt="@shadcn"
										/>
										<AvatarFallback
											name={enterprise?.enterpriseData?.name}
										/>
									</Avatar>
									{enterprise?.enterpriseData?.name}
								</div>
							),
						},
						{
							icon: 'file-text',
							label: 'Description',
							value: (
								<div>
									<p
										dangerouslySetInnerHTML={{
											__html: enterprise?.enterpriseData
												?.description,
										}}
									></p>
								</div>
							),
						},
						{
							icon: 'file-text',
							label: 'Onboarding Stage',
							value: (
								<Badge
									variant="secondary"
									className={
										enterprise?.enterpriseData
											?.onboardingStage ===
										EnterpriseOnboardingStage.Completed
											? 'bg-green-100'
											: ''
									}
								>
									{convertToTitleCase(
										enterprise?.enterpriseData?.onboardingStage,
									)}
								</Badge>
							),
						},
						{
							icon: 'link',
							label: 'Supported Chains',
							value: (
								<div className="flex flex-wrap gap-x-1 justify-end">
									{enterprise?.enterpriseData?.chainIds.map(
										(chainId) => (
											<ChainBadge
												key={chainId}
												chainId={chainId}
												hideName
											/>
										),
									)}
								</div>
							),
						},
						{
							icon: 'bullseye',
							label: 'Goal',
							value: (
								<Badge variant="secondary">
									{enterprise?.enterpriseData?.goal}
								</Badge>
							),
						},
						{
							icon: 'person',
							label: 'Owner Profile',
							value: (
								<Badge variant="secondary">
									{enterprise?.enterpriseData?.role}
								</Badge>
							),
						},
						{
							icon: 'shop',
							label: 'Acquisition Source',
							value: (
								<Badge variant="secondary">
									{enterprise?.enterpriseData?.source}
								</Badge>
							),
						},

						{
							icon: 'person-plus',
							label: 'Referred By',
							value: enterprise?.enterpriseData?.referredBy,
						},
					]}
				/>
			</div>
			<div className="mb-10">
				<div className="font-medium mb-3 text-sm">Approval Data</div>
				<JSONViewer
					data={[
						{
							icon: 'twitter',
							label: 'Official twitter handle',
							link: `https://x.com/${approvalData?.twitter?.username}`,
							value:
								`${approvalData?.twitter?.username} | ${formatCompactNumber(approvalData?.twitter?.followers)} Followers` ||
								'Not Provided',
						},
						{
							icon: 'discord',
							label: 'Official discord username',
							link: `https://discord.com/${approvalData?.discord?.username}`,
							value: approvalData?.discord?.username || 'Not Provided',
						},
						{
							icon: 'envelope-fill',
							label: 'Verified Email',
							value: approvalData?.verifiedEmail
								? approvalData?.verifiedEmail
								: 'Noe',
						},
						{
							icon: 'image',
							label: 'Community Announcement Screenshot',
							link: approvalData?.announcementScreenshot,
							value: approvalData?.announcementScreenshot
								? 'Link'
								: 'Not Provided',
						},
					]}
				/>
			</div>
		</div>
	);
};

export const EnterpriseCampaigns = ({
	enterprise,
	isLoading,
}: {
	enterprise: IAdminEnterprise;
	isLoading: boolean;
}) => {
	if (isLoading) return <div>Loading...</div>;
	return (
		<div className="mx-2">
			<div className="mb-10">
				<div className="font-medium mb-3 text-sm">Campaigns</div>
				<JSONViewer
					data={
						Array.isArray(enterprise?.campaigns) &&
						enterprise?.campaigns?.map((i) => ({
							label: (
								<div
									className="cursor-pointer hover:underline"
									onClick={() => {
										window.open(
											`${QUEST_URL}/quest/${i._id}`,
											'_blank',
										);
									}}
								>
									<div>{i.name}</div>
									<div className="text-xs mt-1">
										{i.status === Status.Draft
											? ''
											: `${formatCompactNumber(
													i?.pageViewUsersCount,
												)} > ${formatCompactNumber(
													i?.initiatedUsersCount,
												)} > ${formatCompactNumber(
													i?.completedUsersCount,
												)}`}
									</div>
								</div>
							),
							value: (
								<div className="flex items-center gap-3">
									<StatusBadge status={i.status} />
									<i
										className="bi bi-box-arrow-up-right text-xs cursor-pointer ms-3"
										onClick={() => {
											window.open(
												`${QUEST_URL}/quest/${i._id}`,
												'_blank',
											);
										}}
									></i>
								</div>
							),
						}))
					}
				/>
			</div>
		</div>
	);
};

export const EnterpriseFeatureUsage = ({
	enterpriseFeatureUsage,
	isLoading,
}: {
	enterpriseFeatureUsage: IEnterpriseFeatureUsage;
	isLoading: boolean;
}) => {
	if (isLoading) return <div>Loading...</div>;
	return (
		<div className="mx-2">
			<div className="mb-10">
				<div className="font-medium mb-3 text-sm">Core Feature Usage</div>
				<JSONViewer
					data={[
						{
							label: 'Quests',
							value: enterpriseFeatureUsage?.quests,
						},

						{
							label: 'Loyalty Tasks',
							value: enterpriseFeatureUsage?.loyaltyTasks,
						},
						{
							label: 'Events',
							value: enterpriseFeatureUsage?.events,
						},
						{
							label: 'Total Quest Users',
							value: enterpriseFeatureUsage?.users,
						},
					]}
				/>
			</div>
			<div className="mb-10">
				<div className="font-medium mb-3 text-sm">Tools Usage</div>
				<JSONViewer
					data={[
						{
							label: 'NFTS',
							value: enterpriseFeatureUsage?.nfts,
						},
						{
							label: 'Dave Actions',
							value: enterpriseFeatureUsage?.daveActions,
						},
						{
							label: 'Integrated APIs',
							value: enterpriseFeatureUsage?.apis,
						},

						{
							label: 'KOL Requests',
							value: enterpriseFeatureUsage?.kolRequests,
						},
						{
							label: 'Partnership Requests',
							value: enterpriseFeatureUsage?.partnershipRequests,
						},
						{
							label: 'Integrations',
							value: enterpriseFeatureUsage?.integrations,
						},
					]}
				/>
			</div>
		</div>
	);
};

export const SimilarEnterprises = ({
	similarEnterprises,
	isLoading,
}: {
	similarEnterprises: IEnterpriseSimilarProjects[];
	isLoading: boolean;
}) => {
	if (isLoading) return <div>Loading...</div>;
	return (
		<div className="mx-2">
			<div className="mb-10">
				<div className="font-medium mb-3 text-sm">Similar Enterprises</div>
				<JSONViewer
					data={
						similarEnterprises &&
						Array.isArray(similarEnterprises) &&
						similarEnterprises.map((i) => ({
							label: (
								<div
									className="cursor-pointer flex items-center hover:underline"
									onClick={() => {
										window.open(
											`${QUEST_URL}/project/${i._id}`,
											'_blank',
										);
									}}
								>
									<Avatar className="h-5 w-5 me-2">
										<AvatarImage src={i.logo} alt="@shadcn" />
										<AvatarFallback name={i.name} />
									</Avatar>
									<div>{i.name}</div>
								</div>
							),
							value: (
								<div className="flex items-center gap-3">
									<div>
										<StatusBadge
											status={
												i.isApproved
													? CustomTokenStatus.Approved
													: CustomTokenStatus.Rejected
											}
										/>
									</div>
									<i
										className="bi bi-box-arrow-up-right text-xs cursor-pointer ms-3"
										onClick={() => {
											window.open(
												`${QUEST_URL}/project/${i.slug}`,
												'_blank',
											);
										}}
									></i>
								</div>
							),
						}))
					}
				/>
			</div>
		</div>
	);
};
