import { useRouter } from '@/hooks/useRouter';
import { handleErrorMessage } from '@/utils/notifications';
import { useEffect, useRef, useState } from 'react';
import {
	approvalDiscordCallback,
	approvalTwitterCallback,
	submitAnnouncementScreenshot,
} from '../services/verification.service';
import { uploadFile } from '@/services/utility.service';
import { useGetEnterprise } from './useGetEnterprise';
import { useSearchParams } from 'react-router-dom';
import { queryClient } from '@/lib/react-query';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

export const useProjectVerification = (setOpen: any) => {
	const inputRef = useRef<HTMLInputElement>();
	const { query } = useRouter();
	const [searchParam, setSearchParam] = useSearchParams();
	const { enterprise } = useGetEnterprise();
	const [isAuthenticating, setIsAuthenticating] = useState({
		discord: false,
		twitter: false,
		upload: false,
	});

	useEffect(() => {
		if (!enterprise) return;
		if (query.approval === 'true') {
			setOpen(true);
			setSearchParam({});
		}
	}, [query, enterprise]);

	useEffect(() => {
		if (!enterprise) return;
		if (enterprise?.approvalData?.discord?.discordId) return;
		if (query.code && query.state === 'discord:approval') {
			setOpen(true);
			connectDiscord({
				code: query.code.toString(),
			});
		}
	}, [query, enterprise]);

	useEffect(() => {
		if (!enterprise) return;
		if (enterprise?.approvalData?.twitter?.twitterId) return;
		if (query.code && query.state?.includes('twitter:approval')) {
			setOpen(true);
			connectTwitter({
				code: query.code.toString(),
				codeVerifier: (query.state as string)?.split(':')[2],
			});
		}
	}, [query, enterprise]);

	const connectDiscord = async (data: { code: string }) => {
		try {
			if (!data) return;
			if (!data.code) return;
			if (isAuthenticating.discord) return;
			setIsAuthenticating((p) => ({ ...p, discord: true }));
			await approvalDiscordCallback({
				code: data.code,
			});
			analytics.track(TrackingEvents.ApprovalDialogActions, {
				actionType: 'discord',
			});
			queryClient.invalidateQueries({
				queryKey: ['enterprise'],
			});
			setIsAuthenticating((p) => ({ ...p, discord: false }));
		} catch (err) {
			handleErrorMessage(err);
			setIsAuthenticating((p) => ({ ...p, discord: false }));
		} finally {
			if (searchParam.has('code') || searchParam.has('state')) {
				const newParams = new URLSearchParams(location.search);
				newParams.delete('code');
				newParams.delete('state');
				setSearchParam(newParams.toString());
			}
		}
	};

	const connectTwitter = async (data: { code: string; codeVerifier: string }) => {
		try {
			if (!data) return;
			if (!data.code) return;
			if (isAuthenticating.twitter) return;
			setIsAuthenticating((p) => ({ ...p, twitter: true }));
			await approvalTwitterCallback({
				code: data.code,
				codeVerifier: data.codeVerifier,
			});
			analytics.track(TrackingEvents.ApprovalDialogActions, {
				actionType: 'twitter',
			});
			queryClient.invalidateQueries({
				queryKey: ['enterprise'],
			});
			setIsAuthenticating((p) => ({ ...p, twitter: false }));
		} catch (err) {
			handleErrorMessage(err);
			setIsAuthenticating((p) => ({ ...p, twitter: false }));
		}
	};

	const uploadScreenshot = async (file: File) => {
		try {
			if (!file) return;
			setIsAuthenticating((p) => ({ ...p, upload: true }));
			const fileLink = await uploadFile(file);
			analytics.track(TrackingEvents.ApprovalDialogActions, {
				actionType: 'screenshot',
			});
			await submitAnnouncementScreenshot({
				screenshot: fileLink,
			});
			queryClient.invalidateQueries({
				queryKey: ['enterprise'],
			});
			setIsAuthenticating((p) => ({ ...p, upload: true }));
		} catch (err) {
			setIsAuthenticating((p) => ({ ...p, upload: true }));
			handleErrorMessage(err);
		}
	};

	return {
		isAuthenticating,
		inputRef,
		uploadScreenshot,
		setIsAuthenticating,
	};
};
