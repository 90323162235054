import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { useGetQuery } from '../hooks/queries/useGetQuery';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { validateTransactionHash } from '@/utils/validation';
import { Separator } from '@/components/ui/separator';
import InputTextArea from '@/components/element/inputs/InputTextArea';

const DaveQueryPage = () => {
	const navigate = useNavigate();
	const { query, isLoading } = useGetQuery();

	const [txnHashes, setTxnHashes] = useState<string>('');
	const [isLoading1, setIsLoading1] = useState(false);
	const [error, setError] = useState(false);
	const [report, setReport] = useState<any>(null);

	useEffect(() => {
		setError(false);
	}, [txnHashes]);

	const validateaInput = () => {
		const hashes = getHashes();
		for (const hash of hashes) {
			if (!hash || !validateTransactionHash(hash)) {
				setError(true);
				return false;
			}
		}
		return true;
	};

	const getHashes = () => {
		return txnHashes
			?.trim()
			?.split(',')
			?.map((i) => i.trim())
			?.filter((i) => i);
	};

	const startTest = async () => {
		if (!validateaInput()) return;
		try {
			setIsLoading1(true);
			const hashes = getHashes();
			setReport(
				hashes.map((i) => ({
					hash: i,
					isLoading1: true,
					isCompleted: false,
					isSuccess: false,
				})),
			);
			const report_ = [];
			for (const hash of hashes) {
				const res = await mockHashTestCall(hash);
				report_.push(res);
				setReport((prev: any) =>
					prev.map((i: any) => {
						if (i.hash === hash) {
							return res;
						}
						return i;
					}),
				);
			}
			if (report_.some((i: any) => !i.isSuccess)) {
				//
			} else {
				//
			}
			setIsLoading1(false);
		} catch (err) {
			console.log(err);
			setIsLoading1(false);
		}
	};

	const mockHashTestCall = async (hash: string) => {
		await new Promise((resolve) =>
			setTimeout(resolve, Math.floor(Math.random() * 400) + 0),
		);
		return {
			hash,
			isLoading1: false,
			isCompleted: true,
			isSuccess: Math.random() > 0.2,
		};
	};

	if (isLoading) {
		return <FullPageLoading />;
	}

	return (
		<div>
			<div className="flex items-center justify-between">
				<div className="space-y-1">
					<h2 className="text-2xl font-medium tracking-tight">
						{query?.name}
					</h2>
					<p className="text-sm text-muted-foreground">
						{query?.description}
					</p>
				</div>
				<div className="flex items-center space-x-4">
					<Button
						onClick={() =>
							navigate('/app/tools/dave/query/update/' + query?._id)
						}
						variant="outline"
					>
						<i className="bi-pencil-square me-2"></i>
						Edit Query
					</Button>
				</div>
			</div>
			<Separator className="my-6" />
			<div className="relative grid grid-cols-12 gap-8 ">
				<div className="bg-slate-100 p-6 col-span-7 rounded-lg">
					<div className="flex justify-between items-center ">
						<div>
							<div className="font-medium mb-1">
								Test your action with sample transactions
							</div>
							<div className="text-xs ">
								Add comma seperated transaction hashes to test if
								your action will capture them.
							</div>
						</div>
						<div>
							<Button
								onClick={() => startTest()}
								size="sm"
								variant={
									txnHashes.length > 0 ? 'default' : 'outline'
								}
								disabled={isLoading}
							>
								{isLoading ? (
									<img
										className="h-4 me-1  animate-spin-slow"
										src="https://static-00.iconduck.com/assets.00/loading-spinner-icon-2048x2048-hoenwdth.png"
									/>
								) : (
									<i className="bi bi-play-circle-fill me-1"></i>
								)}
								Start Test
							</Button>
						</div>
					</div>
					<Separator className="my-5" />
					<div>
						<InputTextArea
							value={txnHashes}
							setValue={setTxnHashes}
							placeholder="Enter transaction hash (comma seperated)"
							inputClassName="bg-white dark:bg-gray-800 min-h-[400px]"
							error={error}
							className=""
							errorText="Invalid transaction hashes"
						/>
					</div>
				</div>
				<div className="col-span-5">
					{report && report.length > 0 && (
						<div className="">
							<div className="mb-3">Results</div>
							<div>
								{report.map((i) => (
									<LoadingItem
										isLoading={i.isLoading}
										isCompleted={i.isCompleted}
										isSuccess={i.isSuccess}
										title={i.hash}
									/>
								))}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default DaveQueryPage;

const LoadingItem = ({
	isLoading,
	isCompleted,
	title,
	isSuccess,
}: {
	isLoading: boolean;
	isCompleted: boolean;
	title: string;
	isSuccess: boolean;
}) => {
	return (
		<div className="flex items-center mb-2 border rounded-lg bg-slate-50 px-3 py-2">
			{isLoading ? (
				<img
					className="h-4 me-2  animate-spin-slow"
					src="https://static-00.iconduck.com/assets.00/loading-spinner-icon-2048x2048-hoenwdth.png"
				/>
			) : isCompleted ? (
				isSuccess ? (
					<i className="bi bi-check-circle-fill text-green-500 me-3 "></i>
				) : (
					<i className="bi bi-x-circle-fill text-red-500 me-3 "></i>
				)
			) : (
				<i className="bi bi-circle text-slate-300 me-3 text-"></i>
			)}
			<div>
				<div className="text-sm truncate max-w-[400px]">{title}</div>
				{/* <p className="text-xs text-muted-foreground">{description}</p> */}
			</div>
		</div>
	);
};
