import InputSwitch from '@/components/element/inputs/InputSwitch';
import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import React, { useState } from 'react';

const InviteYourDeveloperDialog = ({
	open,
	setOpen,
}: {
	open?: boolean;
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const [email, setEmail] = useState('');
	const [isFullAccess, setIsFullAccess] = useState(false);
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[535px] p-8">
				<div>
					<div className="font-medium text-xl">Invite your developers</div>
					<div className="text-muted-foreground text-sm mt-1">
						You can invite your developers to collaborate on your actions
						and queries.
					</div>
					<InputText
						placeholder="Enter your developer's email"
						value={email}
						setValue={setEmail as any}
						className="mt-5"
						// inputClassName="py-5"
						prepend={
							<div className="flex items-center ps-3">
								<i className="bi bi-envelope"></i>
							</div>
						}
					/>
					<InputSwitch
						label="Give full access to your developer"
						subLabel="Your developer will be able to create, edit, and delete campaigns, events and other resources."
						subLabelClassName="mt-2 font-normal"
						className="mt-4"
						value={isFullAccess}
						setValue={setIsFullAccess}
					/>
					<div className="mt-10 flex justify-end">
						<Button className="">
							<i className="bi bi-envelope me-2"></i>Send Invite
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default InviteYourDeveloperDialog;
