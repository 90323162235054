import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import dayjs from 'dayjs';
import { Button } from '@/components/ui/button';
import { getScanLink } from '@/utils/blockChain';

export const actionUsersColumns = (chainId: number) => {
	const a = [
		{
			accessorKey: 'transactionHash',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Txn Hash" />
			),
			cell: ({ row }) => (
				<div className="py-1 truncate max-w-[250px]">
					{row.getValue('transactionHash')}
				</div>
			),
			enableSorting: false,
		},
		{
			accessorKey: 'userAddress',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="User Address" />
			),
			cell: ({ row }) => (
				<div className="py-1 truncate max-w-[250px]">
					{row.getValue('userAddress')}
				</div>
			),
			enableSorting: false,
		},

		{
			accessorKey: 'timestamp',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Timestamp" />
			),
			cell: ({ row }) => (
				<div className="py-1">
					{dayjs(row.getValue('timestamp')).fromNow()}
				</div>
			),
		},

		{
			id: 'actions',
			cell: ({ row }) => (
				<div>
					<Button
						size="sm"
						variant="outline"
						className=""
						onClick={() => {
							window.open(
								getScanLink(
									row.original.transactionHash,
									chainId,
									'tx',
								),
							);
						}}
					>
						View Tranasction
					</Button>
				</div>
			),
			enableSorting: false,
		},
	] as ColumnDef<{
		transactionHash: string;
		userAddress: string;
		actionTag: string;
		timestamp: number;
	}>[];
	return a;
};
