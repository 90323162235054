import React, { useState } from 'react';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

const ConditionLogicSelector = ({ value, setValue }) => {
	return (
		<div className="flex items-center rounded-full bg-slate-200 px-6 py-1 border mb-5 text-xs font-medium">
			<span className="text-xs">Must meet</span>
			<Tabs value={value} onValueChange={setValue}>
				<TabsList
					className={`grid  grid-cols-2 rounded-full px-1 py-1 h-8 mx-2`}
				>
					<TabsTrigger
						value={'and'}
						className="rounded-full px-3 py-1 text-xs data-[state=active]:bg-violet-500 data-[state=active]:text-white"
					>
						all
					</TabsTrigger>
					<TabsTrigger
						value={'or'}
						className="rounded-full px-3 py-1 text-xs data-[state=active]:bg-violet-500 data-[state=active]:text-white"
					>
						any
					</TabsTrigger>
				</TabsList>
			</Tabs>
			<span className="text-xs">of the conditions</span>
		</div>
	);
};

export default ConditionLogicSelector;
