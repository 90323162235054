import { useContext } from 'react';
import { CampaignContext } from '../../providers/CampaignProvider';

export const useCampaignReport = () => {
	const context = useContext(CampaignContext);

	if (context === undefined)
		throw new Error(
			'useCampaignReport must be used within a CreateCampaignProvider',
		);

	return context;
};
