import analytics from '@/lib/analytics';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GlobalLayout = ({ children }) => {
	const location = useLocation();
	useEffect(() => {
		analytics.pageView(location.pathname, {
			title: document.title,
			search: location.search,
		});
	}, [location]);

	return <div>{children}</div>;
};

export default GlobalLayout;
