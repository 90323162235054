import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import clsx from 'clsx';
import InputGroup from './InputGroup';
import { Switch } from '@/components/ui/switch';

const InputSwitch = ({
	labelClassName,
	className,
	label,
	required,
	tooltip,
	value,
	setValue,
	subLabel,
	subLabelClassName,
	inputClassName,
	disabled,
}: {
	labelClassName?: string;
	inputClassName?: string;
	className?: string;
	label?: string;
	required?: boolean;
	tooltip?: string;
	setValue: (value: boolean) => void;
	value: boolean;
	disabled?: boolean;
	subLabel?: string;
	subLabelClassName?: string;
}) => {
	return (
		<>
			<div className={cn('flex justify-between items-center', className)}>
				<div className="pe-5">
					{label && (
						<Label
							className={clsx(
								'flex items-center mb-2',
								labelClassName,
							)}
						>
							<div className="flex flex-col">
								<div className="flex">
									{label}
									{tooltip && (
										<TooltipProvider delayDuration={0}>
											<Tooltip>
												<TooltipTrigger className="ms-2">
													<i className="bi-info-circle-fill block text-muted-foreground text-xs cursor-pointer"></i>
												</TooltipTrigger>
												<TooltipContent>
													<div className="text-sm font-normal max-w-[400px]">
														{tooltip}
													</div>
												</TooltipContent>
											</Tooltip>
										</TooltipProvider>
									)}
									{required && (
										<span className="text-red-400 ms-1">*</span>
									)}
								</div>
								{subLabel ? (
									<p
										className={clsx(
											'text-xs text-muted-foreground',
											subLabelClassName,
										)}
									>
										{subLabel}
									</p>
								) : null}
							</div>
						</Label>
					)}
				</div>
				<Switch
					checked={value}
					onCheckedChange={(value) => {
						setValue(value);
					}}
					className={inputClassName}
					disabled={disabled}
				/>
			</div>
		</>
	);
};

export default InputSwitch;
