import { queryClient } from '@/lib/react-query';
import { createAPI } from '../services/task-apis.service';
import { useMutation } from '@tanstack/react-query';
import { isValidEndpoint } from '@/utils/parsers';
import { useConnectAPI } from './useConnectAPI';
import { CreateTaskAPISteps } from '../types/task-apis.enum';
import useTestAPI from './useTestAPI';
import { IAPIFormFields } from '../types/task-apis.type';
import { handleErrorMessage } from '@/utils/notifications';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

const useCreateTaskAPI = ({ setOpen }: { setOpen: any }) => {
	const { handleTestApi } = useTestAPI();
	const { formFields, setFormErrors, step, setStep, resetForm, setIsLoading } =
		useConnectAPI();

	const nextStepMap = {
		[CreateTaskAPISteps.Basic]: CreateTaskAPISteps.Payload,
		[CreateTaskAPISteps.Payload]: CreateTaskAPISteps.Testing,
		[CreateTaskAPISteps.Testing]: CreateTaskAPISteps.Upsert,
		[CreateTaskAPISteps.ReTesting]: CreateTaskAPISteps.ReTesting,
	};
	const prevStepMap = {
		[CreateTaskAPISteps.Payload]: CreateTaskAPISteps.Basic,
		[CreateTaskAPISteps.Testing]: CreateTaskAPISteps.Payload,
		[CreateTaskAPISteps.Upsert]: CreateTaskAPISteps.Testing,
		[CreateTaskAPISteps.ReTesting]: CreateTaskAPISteps.ReTesting,
	};

	const handleStartOver = () => {
		setStep(CreateTaskAPISteps.Basic);
		resetForm();
		setOpen(false);
	};
	const formatPayload = (payload: IAPIFormFields['apiPayload']) => {
		const tempPayload = {};
		payload.forEach((item) => {
			tempPayload[item.key] = item.value;
		});

		return tempPayload;
	};
	const formatCustomHeaders = (headers: IAPIFormFields['apiHeaders']) => {
		const tempHeaders: any = {};
		headers.forEach((item: any) => {
			tempHeaders[item.key] = item.value;
		});

		return tempHeaders;
	};
	const handleClick = async (step_?: CreateTaskAPISteps) => {
		if (step_) {
			return setStep(step_);
		}
		if (!verifyFormFields(step)) return;

		if (
			step === CreateTaskAPISteps.Testing ||
			step === CreateTaskAPISteps.ReTesting
		) {
			handleTestApi();
			return;
		}
		if (step === CreateTaskAPISteps.Upsert) {
			try {
				setIsLoading(true);
				await createTaskApiMutation.mutateAsync({
					_id: formFields._id,
					apiDataFields: formatPayload(formFields.apiPayload),
					apiDataPassingMethod: formFields.apiPayloadMethod?.toUpperCase(),
					apiEndpoint: formFields.apiEndpoint,
					apiHeaders: formFields?.isHeaderRequired
						? formatCustomHeaders(formFields.apiHeaders)
						: {},
					apiMethod: formFields.apiMethod?.toUpperCase(),
					apiName: formFields.apiName,
					apiVerificationFor: formFields.apiVerificationAction,
					isRecurringTaskEnabled: formFields.isRecurringTasksEnabled,
				});
				setIsLoading(false);
			} catch (err) {
				handleErrorMessage(err);
				setIsLoading(false);
			}
		}
		if (step === CreateTaskAPISteps.Basic) {
			analytics.track(TrackingEvents.TaskAPisCreateDetailAdded, {
				// isUpdate: ,
			});
		}
		if (step === CreateTaskAPISteps.Payload) {
			analytics.track(TrackingEvents.TaskAPIsCreatePayloadAdded, {
				// isUpdate: ,
			});
		}
		setStep(nextStepMap[step]);
		setFormErrors({});
	};

	const verifyFormFields = (currentStep: CreateTaskAPISteps) => {
		const error: any = {};

		switch (currentStep) {
			case CreateTaskAPISteps.Basic:
				if (!formFields?.apiName || !formFields?.apiName.trim()) {
					error.apiName = `API name is required`;
				}
				if (!formFields?.apiEndpoint || !formFields?.apiEndpoint.trim()) {
					error.apiEndpoint = `API endpoint is required`;
				}
				if (
					formFields?.apiEndpoint &&
					!isValidEndpoint(formFields?.apiEndpoint)
				) {
					error.apiEndpoint = `Please enter a valid endpoint. API Endpoint should be https`;
				}
				if (formFields?.apiEndpoint) {
					if (formFields?.apiEndpoint?.includes('?')) {
						error.apiEndpoint = `API endpoint should not contain query params as they will be dynamically added based on user`;
					}
				}
				break;
			case CreateTaskAPISteps.Payload:
				// formFields?.apiPayload.forEach((item) => {
				// 	if (!item.key || !item.key.trim()) {
				// 		error[item.value] = `API payload key is required`;
				// 	}
				// 	if (!item.value || !item.value.trim()) {
				// 		error[item.value] = `${item.value} template is required`;
				// 	}
				// });
				if (formFields?.isHeaderRequired) {
					formFields?.apiHeaders?.forEach((item) => {
						if (
							(!item.key || !item.key.trim()) &&
							(!item.value || !item.value.trim())
						) {
							error[item.key] = `Please enter header: key and value`;
						} else if (!item.key || !item.key.trim()) {
							error[item.key] = `Please enter the key`;
						} else if (!item.value || !item.value.trim()) {
							error[item.key] = `Please enter the value`;
						}
					});
				}
				break;
			case CreateTaskAPISteps.Testing:
			case CreateTaskAPISteps.ReTesting:
				formFields?.apiPayload.forEach((item) => {
					if (!item.testValue || !item.testValue.trim()) {
						error[item.key] = `${item.key} is required`;
					}
				});
				break;

			default:
				return true;
		}
		setFormErrors(error);
		return Object.keys(error).length === 0;
	};

	const createTaskApiMutation = useMutation({
		mutationFn: createAPI,
		onSuccess: async () => {
			handleStartOver();
			analytics.track(TrackingEvents.TaskAPIsCreateSuccessful, {});
			await queryClient.invalidateQueries({ queryKey: ['api-requests'] });
		},
	});

	return {
		handleClick,
		verifyFormFields,
		nextStepMap,
		prevStepMap,
		handleStartOver,
	};
};

export default useCreateTaskAPI;
