import GlobalLayout from '@/components/layout/GlobalLayout';
import OnboardingRoutes from '@/features/onboarding/routes/onboarding.route';
import { Navigate } from 'react-router-dom';

export const onboardingRoutes = [
	{
		path: '/onboarding/*',
		element: (
			<GlobalLayout>
				<OnboardingRoutes />
			</GlobalLayout>
		),
	},
	{
		path: '*',
		element: <Navigate_ />,
	},
];

function Navigate_() {
	return <Navigate to={`/onboarding${window.location.search}`} />;
}
