/* eslint-disable no-mixed-spaces-and-tabs */
import * as React from 'react';
import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { ISelectedLeaderboard } from '../hooks/useLeaderboard';
import { useCampaigns } from '@/features/campaigns/hooks/report/useCampaigns';
import { useEvents } from '@/features/events/hooks/report/useEvents';

export function SelectLeaderboard({
	selectedLeaderboard,
	setSelectedLeaderboard,
}: {
	selectedLeaderboard: ISelectedLeaderboard;
	setSelectedLeaderboard: (value: ISelectedLeaderboard) => void;
}) {
	const [open, setOpen] = React.useState(false);

	const { campaigns } = useCampaigns();
	const { events } = useEvents();

	const leaderboards = React.useMemo(() => {
		const options = [
			{
				heading: '',
				options: [
					{
						label: 'Overall',
						type: 'overall' as 'event' | 'campaign' | 'overall',
						value: 'overall',
					},
				],
			},
		];
		if (campaigns && campaigns.length > 0) {
			const campaignLeaderboards = campaigns.map((campaign) => ({
				label: campaign.name,
				value: campaign._id,
				type: 'campaign' as 'event' | 'campaign',
			}));
			options.push({
				heading: `Campaigns (${campaigns.length})`,
				options: campaignLeaderboards,
			});
		}
		if (events && events.length) {
			const eventLeaderboards = events.map((event) => ({
				label: event.title,
				value: event._id,
				type: 'event' as 'event' | 'campaign',
			}));
			options.push({
				options: eventLeaderboards,
				heading: `Events (${events.length})`,
			});
		}
		return options;
	}, [campaigns, events]);

	const options = React.useMemo(() => {
		return leaderboards.map((i) => i.options).flat();
	}, [leaderboards]);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					className="w-[300px] justify-between"
				>
					<div className="truncate">
						<i className="bi-circle-fill me-2"></i>
						{selectedLeaderboard
							? options.find(
									(framework) =>
										framework.value === selectedLeaderboard.id,
								)?.label
							: 'Select Leaderboard...'}
					</div>
					<CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[300px] p-0">
				<Command
					filter={(value, search) => {
						const name = options.find(
							(framework) => framework.value === value,
						)?.label;
						return name?.toLowerCase().includes(search.toLowerCase())
							? 1
							: 0;
					}}
				>
					<CommandInput
						placeholder="Search leaderboards..."
						className="h-9"
					/>
					<CommandList>
						<CommandEmpty>No framework found.</CommandEmpty>
						{leaderboards.map((i) => (
							<CommandGroup heading={i.heading}>
								{i.options.map((framework) => (
									<CommandItem
										key={framework.value}
										value={framework.value}
										className="truncate"
										onSelect={(currentValue) => {
											setSelectedLeaderboard({
												type: framework.type,
												id: currentValue,
											});
											setOpen(false);
										}}
									>
										{framework.label}
										<CheckIcon
											className={cn(
												'ml-auto h-4 w-4',
												selectedLeaderboard.id ===
													framework.value
													? 'opacity-100'
													: 'opacity-0',
											)}
										/>
									</CommandItem>
								))}
							</CommandGroup>
						))}
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
}
