import { Route, Routes } from 'react-router-dom';
import IntegrationPage from './integrations.page';
import DiscordIntegration from './discord-integration.page';

const IntegrationRoutes = () => {
	return (
		<Routes>
			<Route path="" element={<IntegrationPage />} />
			<Route path="discord" element={<DiscordIntegration />} />
		</Routes>
	);
};

export default IntegrationRoutes;
