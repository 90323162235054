import InputSelect from '@/components/element/inputs/InputSelect';
import InputSwitch from '@/components/element/inputs/InputSwitch';
import { EnterpriseApprovalStatus } from '@/features/dashboard/types/enterprise.types';
import React, { Dispatch, SetStateAction } from 'react';

const EnterpriseApproval = ({
	details,
	setDetails,
	errors,
}: {
	details: {
		status: any;
		reason: string;
		approveCampaigns: boolean;
		publishCampaigns: boolean;
	};
	setDetails: Dispatch<SetStateAction<any>>;
	errors: {
		status: boolean;
		message: boolean;
	};
}) => {
	return (
		<div>
			<div className="space-y-10">
				<InputSelect
					options={[
						{
							label: 'Approved',
							value: EnterpriseApprovalStatus.Approved,
						},
						{
							label: 'Rejected',
							value: EnterpriseApprovalStatus.Rejected,
						},
					]}
					value={details.status}
					setValue={(value) =>
						setDetails((p) => ({
							...p,
							status: value as EnterpriseApprovalStatus,
						}))
					}
					label="Approval Status"
					placeholder="Select Status"
					error={errors.status}
				/>
				{details.status === EnterpriseApprovalStatus.Approved && (
					<>
						<InputSwitch
							label="Remove disclaimer from all existing campaigns"
							subLabel="This will remove disclaimer from all existing campaigns and by deafult all new campaigns will not have disclaimer. Please note, any XP not given before will not be given now"
							subLabelClassName="font-normal mt-2"
							value={details.approveCampaigns}
							setValue={(e) =>
								setDetails((p) => ({ ...p, approveCampaigns: e }))
							}
						/>
						<InputSwitch
							label="Publish all existing campaigns"
							subLabel="This will publish all the campaigns created before this. Campaigns created in future has to be published seperatly. "
							subLabelClassName="font-normal mt-2"
							value={details.publishCampaigns}
							setValue={(e) =>
								setDetails((p) => ({ ...p, publishCampaigns: e }))
							}
						/>
					</>
				)}
				{details.status === EnterpriseApprovalStatus.Rejected && (
					<>
						<InputSelect
							options={rejectionReason}
							value={details.reason}
							setValue={(value) =>
								setDetails((p) => ({
									...p,
									reason: value,
									message: notifications?.[value],
								}))
							}
							label="Rejection Reason"
							placeholder="Select Reason"
							error={errors.message}
						/>
						{notifications?.[details.reason] && (
							<div>
								<div className="text-xs font-medium">
									Following notification will be sent
								</div>
								<div className="text-sm mt-2">
									{notifications?.[details.reason]}
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default EnterpriseApproval;

const rejectionReason = [
	{
		label: 'Suspected Scam or Fraudulent Activity',
		value: 'scam',
	},
	{
		label: 'Inappropriate Content',
		value: 'content',
	},
	{
		label: 'Invalid Request- Individuals ineligible to be Projects',
		value: 'individul',
	},
	{
		label: 'Project with same name already exists',
		value: 'duplicate',
	},
	{
		label: 'Project does not have all required details',
		value: 'incomplete',
	},
];

const notifications = {
	scam: 'We regret to inform you that your project has been declined due to suspected scam or fraudulent activity. Please review your submission and ensure with our guidelines.',
	content:
		'We are unable to approve your project as it contains inappropriate content. Kindly revise your submission to align with our content standards.',
	individul:
		'Unfortunately, your project has been rejected as individuals are ineligible to be projects. Please consider registering as an organization to proceed.',
	duplicate:
		'We noticed that a project with the same name already exists. Please choose a unique name for your project to avoid duplication.',
	incomplete:
		'Your project lacks some required details. To proceed, please ensure all necessary information is provided in the submission.',
};
