import DropzoneWithReposition from '@/components/element/dropzone/DropzoneWithReposition';
import InputSwitch from '@/components/element/inputs/InputSwitch';
import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogFooter,
	DialogTitle,
	DialogDescription,
} from '@/components/ui/dialog';
import { useState } from 'react';

const CampaignSEOConfig = ({
	open,
	setOpen,
	seo,
	setSeo,
	name,
	description,
	banner,
}) => {
	const [details, setDetails] = useState({
		title: name,
		description: description,
		keywords: '',
		image: banner,
		imageFile: null,
		isCustomImage: false,
	});

	const handleSeo = (key: string, value: string | any) => {
		setDetails((prev) => ({ ...prev, [key]: value }));
	};

	const handleSave = () => {
		setSeo(details);
		setOpen(false);
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[525px] ">
				<DialogHeader className="border-b pb-3">
					<DialogTitle>Edit SEO Metadata</DialogTitle>
					<DialogDescription>
						Customize your quest page's search engine visibility by
						editing the title, description, and keywords.
					</DialogDescription>
				</DialogHeader>
				<div className="space-y-8">
					<InputText
						label="Title"
						value={details.title}
						setValue={(e) => handleSeo('title', e)}
						placeholder={name || 'Enter title'}
					/>
					<InputText
						label="Description"
						value={details.description}
						setValue={(e) => handleSeo('description', e)}
						placeholder={description?.slice(0, 300)}
					/>
					<InputText
						label="Keyworks"
						value={details.keywords}
						setValue={(e) => handleSeo('keywords', e)}
						placeholder={'Enter keywords separated by commas'}
					/>
					<InputSwitch
						label="Do you want to use a custom image for metadata?"
						subLabel="SEO Images are the one that comes when you share a link on tweet as a card. If you want to use a custom image, enable this switch."
						subLabelClassName="font-normal mt-2"
						value={details.isCustomImage}
						setValue={(e) => {
							handleSeo('isCustomImage', e);
						}}
					/>
					{details?.isCustomImage && (
						<div>
							<DropzoneWithReposition
								className="mt-10"
								file={details.imageFile}
								fileLink={details.image}
								clearSelection={() => {
									setSeo({
										...seo,
										imageFile: null,
										image: '',
									});
								}}
								setFile={(file: File | File[], fileLink: string) => {
									setSeo({
										...seo,
										imageFile: file,
										image: fileLink,
									});
								}}
								description="Recommended size: 1200x400px"
								label="SEO Image"
								cta="Upload image"
								aspectRatio={1200 / 400}
								errorText="Please upload a valid image or video file."
								allowedFiles={['images']}
								maxSize={3}
							/>
						</div>
					)}
				</div>
				<DialogFooter>
					<Button
						onClick={() => {
							handleSave();
						}}
						className="px-10"
					>
						Save
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default CampaignSEOConfig;
