import { format } from 'date-fns';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import SeparatorWithText from '@/components/ui/seprator-with-text';

const SelectDate = ({
	date,
	setDate,
	isContinous,
	isStart,
	continuousLabel,
	setIsContinous,
	fromDate,
	toDate,
}) => {
	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					variant={'outline'}
					className={cn('justify-start text-left font-normal')}
				>
					{isStart ? (
						<i className="me-2 bi-calendar-check text-muted-foreground"></i>
					) : (
						<i className="me-2 bi-calendar2-check text-muted-foreground"></i>
					)}
					{isContinous ? (
						<span>{continuousLabel}</span>
					) : date ? (
						format(date, 'PPP')
					) : (
						<span>Pick a date</span>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0 group" align="start" withoutPortal>
				<div className="p-2 group-data-[side=top]:hidden">
					<Button
						variant={isContinous ? 'secondary' : 'outline'}
						className="w-full border"
						onClick={() => setIsContinous(!isContinous)}
					>
						{isContinous ? (
							<i className="bi-check-circle-fill text-green-500 me-2"></i>
						) : (
							''
						)}
						{continuousLabel}
					</Button>
				</div>
				<SeparatorWithText
					className="group-data-[side=top]:hidden"
					text="or"
				/>
				<div
					onClick={() => {
						if (isContinous) {
							setIsContinous(false);
						}
					}}
				>
					<Calendar
						mode="single"
						selected={isContinous ? null : new Date(date)}
						onSelect={(e) => {
							setDate(e);
						}}
						initialFocus
						fromDate={fromDate}
						toDate={toDate}
					/>
				</div>
				{setIsContinous && (
					<>
						<SeparatorWithText
							className="group-data-[side=bottom]:hidden"
							text="or"
						/>
						<div className="p-2 group-data-[side=bottom]:hidden">
							<Button
								variant={isContinous ? 'secondary' : 'outline'}
								className="w-full border"
								onClick={() => setIsContinous(!isContinous)}
							>
								{isContinous ? (
									<i className="bi-check-circle-fill text-green-500 me-2"></i>
								) : (
									''
								)}
								{continuousLabel}
							</Button>
						</div>
					</>
				)}
			</PopoverContent>
		</Popover>
	);
};

export default SelectDate;
