import { axios } from '@/lib/axios';

/**
 *
 * Enterprises
 */
export const getAdminEnterprises = async (params: any) => {
	const res = await axios.get(
		`/admin/enterprises?${new URLSearchParams(params).toString()}`,
	);
	return res.data;
};

export const getAdminEnterprisesCount = async (params: any) => {
	const res = await axios.get(
		`/admin/enterprises/count?${new URLSearchParams(params).toString()}`,
	);
	return res.data;
};

export const getEnterpriseApprovalData = async (enterpriseId: string) => {
	const res = await axios.get(`/admin/enterprise/${enterpriseId}/approval`);
	return res.data;
};

export const getEnterpriseFeatureUsage = async (enterpriseId: string) => {
	const res = await axios.get(`/admin/enterprise/${enterpriseId}/feature-usage`);
	return res.data;
};

export const getSimilarEnterprises = async (enterpriseId: string) => {
	const res = await axios.get(
		`/admin/enterprise/${enterpriseId}/similar-enterprises`,
	);
	return res.data;
};

export const updateEntepriseApproval = async (enterpriseId: string, data: any) => {
	const res = await axios.put(`/admin/enterprise/${enterpriseId}/approval`, data);
	return res.data;
};

export const ghostLogin = async (enterpriseId: string) => {
	const res = await axios.put(`/admin/enterprise/${enterpriseId}/ghost-login`);
	return res.data;
};

export const removeGhostLogin = async (enterpriseId: string) => {
	new Promise((resolve) => {
		setTimeout(() => {
			resolve(true);
		}, 4000);
	});
	const res = await axios.delete(`/admin/enterprise/${enterpriseId}/ghost-login`);
	return res.data;
};

/**
 *
 * Campaigns
 */
export const getAdminCampaigns = async (params: any) => {
	const res = await axios.get(
		`/admin/campaigns?${new URLSearchParams(params).toString()}`,
	);
	return res.data;
};
export const getAdminCampaignsCount = async (params: any) => {
	const res = await axios.get(
		`/admin/campaigns/count?${new URLSearchParams(params).toString()}`,
	);
	return res.data;
};

export const updateCampaignPublishStatus = async (campaignId: string, data: any) => {
	const res = await axios.put(`/admin/campaigns/${campaignId}/publish`, data);
	return res.data;
};

/**
 *
 * Tokens
 */
export const getAdminTokens = async (params: any) => {
	const res = await axios.get(
		`/admin/custom-tokens?${new URLSearchParams(params).toString()}`,
	);
	return res.data;
};

export const updateCustomTokenApproval = async (tokenId: string, data: any) => {
	const res = await axios.put(`/admin/custom-tokens/${tokenId}/approval`, data);
	return res.data;
};

/**
 *
 * Dave Actions
 */
export const getAdminDaveActions = async () => {
	const res = await axios.get(`/admin/dave-actions`);
	return res.data;
};

export const updateDaveActionApproval = async (actionId: string, data: any) => {
	const res = await axios.put(`/admin/dave-actions/${actionId}/approval`, data);
	return res.data;
};
