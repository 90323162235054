import { useState } from 'react';
import EventCreateLayout from '../components/events-create/layout/EventCreateLayout';
// import EventRewards from '../components/events-create/rewards/EventRewards';
import EventDetails from '../components/events-create/details/EventDetails';
import EventCampaigns from '../components/events-create/campaigns/EventCampaigns';
import { EventCreateProvider } from '../providers/EventCreateProvider';
import { EventsCreateEnum } from '../types/events.type';

const EventsCreatePage = () => {
	const [section, setSection] = useState(EventsCreateEnum.Details);
	return (
		<EventCreateProvider section={section} setSection={setSection}>
			<EventCreateLayout>
				{section === EventsCreateEnum.Details && <EventDetails />}
				{section === EventsCreateEnum.Campaigns && <EventCampaigns />}
			</EventCreateLayout>
		</EventCreateProvider>
	);
};

export default EventsCreatePage;
