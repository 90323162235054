import StatusBadge from '@/components/element/badges/StatusBadge';
import DotsDropdown from '@/components/element/dropdowns/DotsDropdown';
import { Badge } from '@/components/ui/badge';
import { CSS } from '@dnd-kit/utilities';

const EventCampaign = ({
	name,
	_id,
	banner,
	status,
	listeners,
	setNodeRef,
	transform,
	transition,
	isDragging,
	attributes,
	editItem,
}) => {
	return (
		<div
			ref={setNodeRef}
			{...attributes}
			style={{
				transition,
				transform: CSS.Translate.toString(transform),
			}}
			className={`h-[60px] border-b p-0 flex flex-row items-center space-y-0 group ${
				isDragging && 'bg-slate-100'
			}`}
		>
			<div
				className="w-[50px] h-[60px]   items-center justify-center border-e flex cursor-pointer"
				{...listeners}
			>
				<i className="text-muted-foreground text-xl  bi-grip-vertical opacity-0 group-hover:opacity-100"></i>
			</div>
			<div className="h-[60px] w-full grid grid-cols-12 items-center justify-center px-4 hover:bg-slate-50">
				<div className="flex items-center  col-span-7">
					<div className="me-1">
						<img
							src={banner}
							alt="campaign"
							className="w-12 h-7 rounded-sm object-cover"
						/>
					</div>
					<div className="ms-3">
						<div className="text-sm">{name}</div>
					</div>
				</div>
				<div className="col-span-4 justify-end flex">
					<Badge variant="outline" className=" ">
						<StatusBadge status={status} />
					</Badge>
				</div>
				<div className=" col-span-1 justify-end flex">
					<DotsDropdown
						options={[
							{
								label: 'Delete',
								onClick: () => {
									editItem(_id, 'DELETE', true);
								},
								type: 'item',
							},
						]}
					/>
				</div>
			</div>
		</div>
	);
};

export default EventCampaign;
