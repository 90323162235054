import LoyaltyCampaignsPage from './loyalty-campaigns.page';

const LoyaltyCampaignsRoute = () => {
	return (
		<div>
			<LoyaltyCampaignsPage />
		</div>
	);
};

export default LoyaltyCampaignsRoute;
