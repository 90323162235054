import * as React from 'react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import SeparatorWithText from '@/components/ui/seprator-with-text';
import { useLogin } from '@/features/auth/hooks/useLogin';

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
	const [showPassword, setShowPassword] = React.useState(false);
	const {
		handleSubmit,
		onSubmit,
		register,
		isLoading,
		loginWithDiscord,
		loginWithGoogle,
		isDiscordLoading,
		isGoogleLoading,
	} = useLogin();

	return (
		<div className={cn('grid gap-6', className)} {...props}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid gap-2">
					<div className="grid gap-5">
						<Input
							id="email"
							placeholder="Your Name"
							autoCapitalize="none"
							className="h-10"
						/>
						<Input
							id="email"
							placeholder="name@example.com"
							type="email"
							autoCapitalize="none"
							autoComplete="email"
							autoCorrect="off"
							className="h-10"
							{...register('email', { required: true })}
						/>
						<div className="relative">
							<Input
								id="password"
								placeholder="Password"
								type={showPassword ? 'text' : 'password'}
								autoCapitalize="none"
								autoComplete="email"
								autoCorrect="off"
								{...register('password', { required: true })}
							/>
							<span
								className="absolute right-2 top-2 cursor-pointer text-muted-foreground"
								onClick={() => setShowPassword((prev) => !prev)}
							>
								<i
									className={`bi-${showPassword ? 'eye-slash' : 'eye'}`}
								></i>
							</span>
						</div>
					</div>

					<Button disabled={isLoading} className="mt-5" size="lg">
						{isLoading && (
							<i className="bi-arrow-clockwise mr-2  animate-spin" />
						)}
						<span>Sign Up</span>
					</Button>
				</div>
			</form>
			<SeparatorWithText text="Or continue with" />
			<div className="flex  space-x-3">
				<Button
					onClick={() => loginWithDiscord()}
					variant="outline"
					className="w-full"
					size="lg"
					disabled={isLoading || isDiscordLoading}
				>
					{isDiscordLoading && (
						<i className="bi-arrow-clockwise mr-2  animate-spin" />
					)}
					<i className="bi-discord  text-blue-700 me-2" />
					<span>Discord</span>
				</Button>
				<Button
					onClick={() => loginWithGoogle()}
					variant="outline"
					className="w-full"
					size="lg"
					disabled={isLoading || isGoogleLoading}
				>
					{isGoogleLoading && (
						<i className="bi-arrow-clockwise mr-2  animate-spin" />
					)}
					<i className="bi-google text-red-500 me-2" />
					<span>Google</span>
				</Button>
			</div>
			<div className="text-xs text-muted-foreground mt-2">
				By submitting your information, you consent to be contacted about our
				content, products, and services, as detailed in our privacy policy.
			</div>
		</div>
	);
}
