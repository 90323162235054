import { Icons } from '@/components/icons';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { ConditionCard } from './ConditionCard';
import { useCreateQueryProvider } from '@/features/dave/hooks/queries/useCreateQueryProvider';
import ConditionLogicSelector from './ConditionLogicSelector';
import { QueryListType } from '@/features/dave/types/dave.types';
import { v4 as uuidv4 } from 'uuid';
import { QueryCategory } from '@/features/dave/types/dave.enum';

const EditConditions = () => {
	const { details, setDetails, errors, setErrors } = useCreateQueryProvider();

	const addQueryItem = () => {
		const id = uuidv4();
		setDetails((p) => {
			const newQueryItem: QueryListType = {
				id,
				category: QueryCategory.SingleTransaction,
				actionTag: '',
				eligibleInflowAddressesTag: 'ANY',
				inflowAddressesList: [],
				eligibleOutflowAddressesTag: 'ANY',
				outflowAddressesList: [],
				inflowAmount: 0,
				outflowAmount: 0,
				chainId: null,
				countCondition: 1,
				isChecked: {
					tokenIn: false,
					tokenOut: false,
					multipleTxn: false,
					aggregatedVolume: false,
				},
			};
			return { ...p, queryList: [...p.queryList, newQueryItem] };
		});
		setErrors((p) => {
			const newErrors = {
				id: id,
				actionTag: false,
				inflowAmount: false,
				outflowAmount: false,
				countCondition: false,
			};
			return { ...p, conditions: [...p.conditions, newErrors] };
		});
	};

	return (
		<div className="relative">
			<div className="mb-6 pt-8 border-t">
				<div className="font-medium text-sm">Action Conditions</div>
				<p className="text-xs text-muted-foreground">
					Customize how actions are validated by setting specific
					conditions. Add multiple conditions to precisely define when an
					action counts towards completing a quest task.
				</p>
			</div>
			<div className="flex flex-col items-center justify-center relative mt-10">
				<div className="absolute top-0 h-full">
					<Separator className=" h-full  -z-10" orientation="vertical" />
				</div>
				<div className="relative flex flex-col items-center justify-center z-30">
					{details.queryList.length > 1 && (
						<ConditionLogicSelector
							value={details.logicSelector}
							setValue={(e: 'and' | 'or') =>
								setDetails({ ...details, logicSelector: e })
							}
						/>
					)}
					{details.queryList &&
						details.queryList.map((i) => (
							<ConditionCard
								{...i}
								error={errors?.conditions?.find(
									(j) => j.id === i.id,
								)}
							/>
						))}
					<Button
						variant="outline"
						size="icon"
						className="rounded-full"
						onClick={addQueryItem}
					>
						<Icons.add className="h-4 w-4" />
					</Button>
				</div>
			</div>
		</div>
	);
};

export default EditConditions;
