import { useContext } from 'react';
import { CommunityHubContext } from '../providers/CommunityHubProvider';

export const useCommunityHub = () => {
	const context = useContext(CommunityHubContext);

	if (context === undefined) {
		throw new Error(
			'useCommunityHub must be used within a CommunityHubProvider',
		);
	}

	return context;
};
