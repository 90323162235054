import { Button } from '@/components/ui/button';
import { useCreateActionProvider } from '@/features/dave/hooks/actions/useCreateActionProvider';
import { useGetContracts } from '@/features/dave/hooks/contracts/useGetContracts';
import { useMemo, useState } from 'react';
import AddContractDialog from '../../contracts/AddContractDialog';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';
import { IDaveContracts } from '@/features/dave/types/dave.types';
import InputMultiSelect from '@/components/element/inputs/InputMultiSelect';

const SelectSecondaryContract = ({ setShow }) => {
	const [open, setOpen] = useState(false);
	const { contracts } = useGetContracts();
	const { details, setDetails } = useCreateActionProvider();
	const { getNativeChainLogo } = useGetSupportedChains({
		showAdditionalChains: false,
	});

	const handleSelectContract = (ids: string[]) => {
		const contracts_ = contracts?.filter((i) => ids.includes(i.contractAddress));
		const abis = [
			{
				contract: details.transactionTo,
				abi: JSON.parse(JSON.stringify(details.abi)),
			},
		];
		for (const contract of contracts_) {
			abis.push({
				contract: contract.contractAddress,
				abi: JSON.parse(JSON.stringify(contract.abi)),
			});
		}
		setDetails((prev) => ({
			...prev,
			abis: abis,
		}));
	};

	const handleSelectNewContract = (contract: IDaveContracts) => {
		try {
			const abi = JSON.parse(contract.abi);
			setDetails((prev) => ({
				...prev,
				abis: [...prev.abis, { abi, contract: contract.contractAddress }],
			}));
		} catch (err) {
			console.error(err);
		}
	};

	const options = useMemo(() => {
		return (
			contracts
				?.filter((i) => {
					if (details.contractId === i._id) {
						return false;
					}
					return true;
				})
				?.map((i) => ({
					value: i.contractAddress,
					searchText: i.name + ' ' + i.contractAddress,
					label: (
						<div className="flex items-center">
							<div className="">
								<img
									src={getNativeChainLogo(i.chainId)}
									className="h-6 w-6 me-2 rounded-full"
									alt="Chain Logo"
								/>
							</div>
							<div>
								<div className="font-medium">{i.name}</div>
								<div className="text-xs text-muted-foreground">
									{i.contractAddress}
								</div>
								{i.implementationAddress && (
									<div className="text-xs text-muted-foreground mt-1">
										Implementation at{' '}
										{i.implementationAddress?.slice(0, 6) +
											'...' +
											i.implementationAddress?.slice(-4)}
									</div>
								)}
							</div>
						</div>
					),
					selectedLabel: (
						<div className="">
							<div className="font-medium truncate max-w-[100px]">
								{i.name ? i.name : i.contractAddress}
							</div>
						</div>
					),
				})) ?? []
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		contracts,
		details.contractId,
		details.abi,
		details.transactionTo,
		details.abis,
	]);

	return (
		<div className="bg-white rounded-lg p-6 relative">
			<div
				className="absolute top-0 end-0 -mt-2 cursor-pointer"
				onClick={() => setShow(false)}
			>
				<i className="bi-x-circle"></i>
			</div>
			<div className="text-sm font-medium">Add secondary contracts</div>
			<p className="text-xs text-muted-foreground mt-1 mb-4">
				If your contract communicates with another contract and you need to
				monitor events from that contract, simply input its address here.
			</p>

			<div className="grid grid-cols-12 items-end gap-4">
				{options.length === 0 ? (
					<Button
						variant="outline"
						className=" col-span-12 h-9"
						onClick={() => setOpen(true)}
					>
						No contracts available. Please add a new contract.
					</Button>
				) : (
					<InputMultiSelect
						options={options}
						value={details.abis
							.filter((i) => i.contract !== details.transactionTo)
							.map((i) => i.contract)}
						setValue={(value) => {
							handleSelectContract(value);
						}}
						error={false}
						placeholder="Select contract"
						className="col-span-9"
						popoverClassName="w-[400px]"
					/>
				)}
				{options.length !== 0 && (
					<Button
						variant="outline"
						className=" col-span-3 h-9"
						onClick={() => setOpen(true)}
					>
						+ Add Contract
					</Button>
				)}
			</div>

			<AddContractDialog
				open={open}
				setOpen={setOpen}
				onSuccess={(contract: any) => {
					handleSelectNewContract(contract);
				}}
				chainId={details.chainId}
			/>
		</div>
	);
};

export default SelectSecondaryContract;
