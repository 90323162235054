import { useState } from 'react';
import { getAdminTokens } from '../services/admin.service';
import { useQuery } from '@tanstack/react-query';
import { ITokens } from '@/features/campaigns/hooks/tokens/useGetAllTokens';
import { useDebounce } from '@/hooks/useDebounce';

export const useGetAdminCustomTokens = () => {
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({
		approvalStatus: [],
		onboardingStatus: [],
	});
	const debounceSearch = useDebounce(search, 500);

	const { data, isLoading, refetch } = useQuery<ITokens[]>({
		queryKey: [
			'admin',
			'tokens',
			{
				filters,
				debounceSearch,
			},
		],
		queryFn: () =>
			getAdminTokens({
				search: debounceSearch,
			}),
	});

	return {
		search,
		setSearch,
		filters,
		setFilters,
		results: data || [],
		isLoading: isLoading,
		refetch,
	};
};
