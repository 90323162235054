import { useSearchParams } from 'react-router-dom';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import AuthLayout from '@/components/layout/auth/AuthLayout';
import { useRegister } from '../hooks/useRegister';
import { useEffect } from 'react';

export default function RegisterPage() {
	const {
		handleSubmit,
		onSubmit,
		register,
		isLoading,
		loginWithDiscord,
		loginWithGoogle,
	} = useRegister();

	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (searchParams.get('referralCode')) {
			localStorage.setItem('referral-code', searchParams.get('referralCode'));
		}
	}, [searchParams]);
	return (
		<AuthLayout>
			<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
				<div className="flex flex-col space-y-2 text-center">
					<h1 className="text-2xl font-semibold tracking-tight">
						Create an account
					</h1>
					<p className="text-sm text-muted-foreground">
						Enter your email below to create your account
					</p>
				</div>
				<div className="grid gap-6">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="grid gap-2">
							<div className="grid gap-1 mb-2">
								<Label className="font-normal" htmlFor="name">
									Name
								</Label>
								<Input
									id="name"
									placeholder="Cartman"
									autoCorrect="off"
									{...register('name', { required: true })}
								/>
								<Label className="font-normal mt-4" htmlFor="email">
									Email
								</Label>
								<Input
									id="email"
									placeholder="name@example.com"
									type="email"
									autoCapitalize="none"
									autoComplete="email"
									autoCorrect="off"
									{...register('email', { required: true })}
								/>
								<Label
									className="mt-4 font-normal"
									htmlFor="password"
								>
									Password
								</Label>
								<Input
									id="password"
									placeholder="8+ characters required"
									type="password"
									autoCapitalize="none"
									autoComplete="email"
									autoCorrect="off"
									{...register('password', { required: true })}
								/>
							</div>
							<Button disabled={isLoading}>
								{isLoading && (
									<i className="bi-arrow-clockwise mr-2  animate-spin" />
								)}
								<span>Register</span>
							</Button>
						</div>
					</form>
					<div className="relative">
						<div className="absolute inset-0 flex items-center">
							<span className="w-full border-t"></span>
						</div>
						<div className="relative flex justify-center text-xs uppercase">
							<span className="bg-background px-2 text-muted-foreground">
								Or continue with
							</span>
						</div>
					</div>
					<div className="flex flex-col space-y-2">
						<Button variant="outline" onClick={loginWithDiscord}>
							<i className="bi-discord me-2 text-blue-600"></i>
							<span>Discord</span>
						</Button>

						<Button
							variant="outline"
							className=""
							onClick={loginWithGoogle}
						>
							<i className="bi-google me-2 text-red-500"></i>
							<span>Google</span>
						</Button>
					</div>
				</div>
				<p className="px-8 text-center text-sm text-muted-foreground">
					By clicking continue, you agree to our&nbsp;
					<a
						className="underline underline-offset-4 hover:text-primary"
						href="/terms"
					>
						Terms of Service
					</a>{' '}
					<a
						className="underline underline-offset-4 hover:text-primary"
						href="/privacy"
					>
						Privacy Policy
					</a>
					.
				</p>
			</div>
		</AuthLayout>
	);
}
