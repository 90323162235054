import { PlusCircledIcon } from '@radix-ui/react-icons';

import { cn } from '@/lib/utils';
import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuSeparator,
	ContextMenuSub,
	ContextMenuSubContent,
	ContextMenuSubTrigger,
	ContextMenuTrigger,
} from '@/components/ui/context-menu';

interface AlbumArtworkProps extends React.HTMLAttributes<HTMLDivElement> {
	data: any;
	aspectRatio?: 'portrait' | 'square';
	width?: number;
	height?: number;
}

export function LaunchedNFTs({
	data,
	aspectRatio = 'portrait',
	width,
	height,
	className,
	...props
}: AlbumArtworkProps) {
	return (
		<div className={cn('space-y-3', className)} {...props}>
			<div className="overflow-hidden rounded-md">
				<img
					src={data.cover}
					alt={data.name}
					width={width}
					height={height}
					className={cn(
						'h-auto w-auto object-cover transition-all hover:scale-105',
						aspectRatio === 'portrait'
							? 'aspect-[3/4]'
							: 'aspect-square',
					)}
				/>
			</div>
			<div className="space-y-1 text-sm">
				<h3 className="font-medium leading-none">{data.name}</h3>
				<p className="text-xs text-muted-foreground">{data.artist}</p>
			</div>
		</div>
	);
}
