import StatusBadge from '@/components/element/badges/StatusBadge';
import DotsDropdown from '@/components/element/dropdowns/DotsDropdown';
import { Badge } from '@/components/ui/badge';
import { Checkbox } from '@/components/ui/checkbox';
import { TaskStatus } from '@/features/campaigns/types/tasks.enums';
import { CSS } from '@dnd-kit/utilities';
import { useMemo } from 'react';
import { useLoyaltyTasksInitialise } from '../../hooks/tasks/useLoyaltyTasksInitialise';
import { useLoyaltyTasks } from '../../hooks/tasks/useLoyaltyTasks';
import { useLoyaltyTasksEdit } from '../../hooks/tasks/useLoyaltyTasksEdit';
import { cn } from '@/lib/utils';
import { useTaskTemplates } from '@/features/campaigns/hooks/create/useTaskTemplates';
import { ILoyaltyTask } from '../../types/loyalty.type';
import { Status } from '@/features/campaigns/types';

interface LoyaltyTaskProps extends ILoyaltyTask {
	sectionId: string;
	attributes: any;
	setNodeRef: any;
	listeners: any;
	transform: any;
	transition: any;
	isDragging: any;
	id: any;
	isSorting: any;
	selectItem: any;
	editItem: any;
}

const LoyaltyTask = ({
	description,
	sectionId,
	attributes,
	setNodeRef,
	transition,
	status,
	transform,
	listeners,
	isDragging,
	selectItem,
	templateFamily,
	xp,
	logo,
	_id,
	taskStartDate,
	taskEndDate,
	startImmediately,
	noEndTime,
	recurrenceConfig,
}: LoyaltyTaskProps) => {
	const { editDraftTask, editActiveTask, duplicateTask } =
		useLoyaltyTasksInitialise();

	const { deleteTask } = useLoyaltyTasksEdit();
	const { setSelectedSectionId } = useLoyaltyTasks();

	const sectionId_ = useMemo(() => {
		return sectionId.split('-')[1];
	}, [sectionId]);

	const options = useMemo(() => {
		const options_ = [
			{
				type: 'item',
				label: 'Edit',
				onClick: () => {
					setSelectedSectionId(sectionId_);
					if (status === TaskStatus.Draft) {
						editDraftTask(_id);
					} else {
						editActiveTask(_id);
					}
				},
			},
			{
				type: 'item',
				label: 'Duplicate',
				onClick: () => {
					setSelectedSectionId(sectionId_);
					duplicateTask(_id);
				},
			},
			...(status === TaskStatus.Draft
				? [{ type: 'item', label: 'Publish Task', onClick: () => {} }]
				: []),
			{
				type: 'separator',
			},
			{
				type: 'item',
				label: 'Delete',
				onClick: () => {
					deleteTask(_id);
				},
			},
		];
		return options_;
	}, [
		status,
		_id,
		sectionId_,
		setSelectedSectionId,
		editDraftTask,
		editActiveTask,
		duplicateTask,
		deleteTask,
	]);

	const { getDefaultLogo } = useTaskTemplates();

	const taskStatus: Status = useMemo(() => {
		if (status === TaskStatus.Active) {
			if (startImmediately && noEndTime) {
				return Status.Active;
			}
			if (new Date(taskStartDate) > new Date() && !startImmediately) {
				return Status.Scheduled;
			}
			if (new Date(taskEndDate) < new Date() && !noEndTime) {
				return Status.Completed;
			}
			return Status.Active;
		}
		if (status === TaskStatus.Draft) return Status.Draft;
		if (status === TaskStatus.Inactive) return Status.Inactive;
	}, [status, startImmediately, noEndTime, taskStartDate, taskEndDate]);

	const frequency = useMemo(() => {
		const isRecurring = recurrenceConfig?.isRecurring;
		if (!isRecurring) return;
		const frequency = recurrenceConfig?.frequencyInDays;
		if (frequency === 1) return 'daily';
		if (frequency === 7) return 'weekly';
		if (frequency === 30) return 'monthly';
		return `every ${frequency} day`;
	}, [recurrenceConfig]);

	return (
		<div
			ref={setNodeRef}
			{...attributes}
			style={{
				transition,
				transform: CSS.Translate.toString(transform),
			}}
			className={`h-[60px] border-b p-0 flex flex-row items-center space-y-0 group ${
				isDragging && 'bg-slate-100 dark:bg-slate-900'
			}`}
		>
			<div
				className="w-[50px] h-[60px]   items-center justify-center border-e flex cursor-pointer"
				{...listeners}
			>
				<i className="text-muted-foreground text-xl  bi-grip-vertical opacity-0 group-hover:opacity-100"></i>
			</div>
			<div
				className="h-[60px] w-full flex items-center justify-between px-4 hover:bg-slate-50 dark:hover:bg-black"
				onClick={() => {
					selectItem(_id, sectionId?.split('-')[1]);
				}}
			>
				<div className="flex items-center  ">
					<div className="ms-3 flex items-center ">
						<div className="h-7 w-7 rounded bg-slate-100  flex items-center justify-center relative border">
							{logo?.type === 'image' ? (
								<img src={logo?.value} className="h-6 w-6 rounded" />
							) : (
								logo?.type === 'icon' && (
									<i
										className={cn(
											'text-xl',
											logo.value,
											getDefaultLogo(templateFamily).color,
										)}
									></i>
								)
							)}
						</div>

						<div className="text-sm mx-2">{description}</div>
						<Badge variant="outline">
							{xp} XP {frequency ? `/ ${frequency}` : ''}
						</Badge>
					</div>
				</div>

				<div
					className="justify-end items-center gap-3 flex"
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<Badge variant="outline" className=" ">
						<StatusBadge status={taskStatus} />
					</Badge>
					<DotsDropdown options={options} />
				</div>
			</div>
		</div>
	);
};

export default LoyaltyTask;
