import { useQuery } from '@tanstack/react-query';
import { getSuggestedActions } from '../services/home.service';

export const useSuggestedActions = () => {
	const { data: suggestedActions, isLoading } = useQuery({
		queryKey: ['suggested-actions'],
		queryFn: () => getSuggestedActions(),
	});

	return {
		suggestedActions: suggestedActions || [],
		isLoading,
	};
};
