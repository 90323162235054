import { useConfirm } from '@/hooks/useConfirm';
import { useLoyaltyTasks } from './useLoyaltyTasks';
import { useState } from 'react';
import { toast } from 'sonner';
import {
	changeLoyaltyTaskStatus,
	deleteLoyaltyTask,
} from '../../services/loyalty.service';
import { queryClient } from '@/lib/react-query';
import { useLoyaltyCampaign } from '../useLoyaltyCampaign';
import { TaskStatus } from '@/features/campaigns/types/tasks.enums';

export const useLoyaltyTasksEdit = () => {
	const { id } = useLoyaltyCampaign();
	const { setIsDirty, task, setTask } = useLoyaltyTasks();
	const { show } = useConfirm();

	const editTask = (key: any, value: any) => {
		setIsDirty(true);
		setTask((prev) => ({ ...prev, [key]: value }));
	};

	const editTaskAdminInput = (
		_taskId: string,
		key: string,
		value: string | number,
	) => {
		if (key === 'disabled') {
			setTask((p) => ({
				...p,
				isDisabled: true,
			}));
			return;
		}
		setIsDirty(true);
		const updatedTask = { ...task };
		const adminInputs = updatedTask.adminInputs.map((input) => {
			if (input.key === key) {
				input.value = value;
			}
			return input;
		});
		setTask({ ...updatedTask, adminInputs });
	};

	const deleteTask = async (taskId: string) => {
		try {
			const res = await show({
				title: 'Delete Task',
				subtitle: 'Are you sure you want to delete this task?',
				confirmText: 'Delete',
				cancelText: 'Cancel',
			});
			if (!res) return;
			toast.promise(
				Promise.all([
					deleteLoyaltyTask({ taskId, campaignId: id }).then((res) => {
						console.log(res);
						queryClient.invalidateQueries({
							queryKey: ['campaign', 'loyalty-quests'],
						});
					}),
				]),
				{
					loading: 'Deleting task...',
					success: 'Task deleted successfully',
					error: 'Failed to delete task',
				},
			);
		} catch (err) {
			console.log(err);
		}
	};

	const completeTask = async (taskId: string) => {
		try {
			const res = await show({
				title: 'Complete Task',
				subtitle: 'Users won’t be able to complete this task anymore. ',
				confirmText: 'Complete',
				cancelText: 'Cancel',
			});
			if (!res) return;
			toast.promise(
				Promise.all([
					changeLoyaltyTaskStatus({
						taskId,
						campaignId: id,
						status: TaskStatus.Active,
						isComplete: true,
					}).then((res) => {
						console.log(res);
						queryClient.invalidateQueries({
							queryKey: ['campaign', 'loyalty-quests'],
						});
					}),
				]),
				{
					loading: 'Marking task as completed...',
					success: 'Task marked as completed',
					error: 'Failed to mark task compelte',
				},
			);
		} catch (err) {
			console.log(err);
		}
	};
	const makeScheduledTaskActive = async (taskId: string) => {
		try {
			const res = await show({
				title: 'Pubishing Task',
				subtitle: 'This will make task active for your users immediately',
				confirmText: 'Publish',
				cancelText: 'Cancel',
			});
			if (!res) return;
			toast.promise(
				Promise.all([
					changeLoyaltyTaskStatus({
						taskId,
						campaignId: id,
						status: TaskStatus.Active,
						startImmediately: true,
					}).then((res) => {
						console.log(res);
						queryClient.invalidateQueries({
							queryKey: ['campaign', 'loyalty-quests'],
						});
					}),
				]),
				{
					loading: 'Making task active...',
					success: 'Task made active successfully',
					error: 'Failed to delete task',
				},
			);
		} catch (err) {
			console.log(err);
		}
	};
	const sendDiscordNotification = async (taskId: string) => {};

	return {
		editTaskAdminInput,
		editTask,
		deleteTask,
		completeTask,
		sendDiscordNotification,
		makeScheduledTaskActive,
	};
};
