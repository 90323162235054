import { useEffect, useState } from 'react';
import {
	useAccount,
	useConnect,
	useReadContract,
	useSwitchChain,
	useWriteContract,
} from 'wagmi';
import { injected } from 'wagmi/connectors';
import { readContract } from 'wagmi/actions';
import { wagmiConfig } from '@/lib/wagmi';

export enum TxnStatus {
	Idle = 'idle',
	AccountRequested = 'account-request',
	WalletConnected = 'wallet-connected',
	ChainChangedRequested = 'chain-changed-request',
	ChainChanged = 'chain-changed',
	Hash = 'hash',
	Receipt = 'receipt',
	Completed = 'completed',
	Error = 'error',
	AccountRequestRejected = 'account-request-rejected',
	ProviderNotFound = 'provider-not-found',
}

export const useReadContracts = () => {
	const { address, chainId: currentChainId } = useAccount();
	const { connectAsync } = useConnect();
	const { switchChainAsync } = useSwitchChain();

	const [status, setStatus] = useState<TxnStatus>(TxnStatus.Idle);
	const [loading, setLoading] = useState(false);
	const [txHash, setTxHash] = useState('');

	useEffect(() => {
		setLoading(false);
		setStatus(TxnStatus.Idle);
		setTxHash('');
	}, []);

	const connectWallet = async () => {
		try {
			if (!address) {
				await connectAsync({ connector: injected() });
			}
		} catch (error) {
			console.log(error);
		}
	};

	const changeNetwork = async (chainId: number) => {
		try {
			if (chainId !== currentChainId) {
				await switchChainAsync({ chainId: chainId });
				return true;
			}
			if (currentChainId === chainId) {
				return true;
			}
			return false;
		} catch (error) {
			console.log(error);
		}
	};

	const readContract_ = async ({
		chainId,
		contractAddress,
		args,
		ABI,
		fnName,
	}: {
		chainId: number;
		contractAddress: string;
		args: any[];
		ABI: any;
		fnName: string;
	}) => {
		try {
			setLoading(true);
			setStatus(TxnStatus.AccountRequested);
			await connectWallet();
			setStatus(TxnStatus.ChainChangedRequested);
			const isChainChanged = await changeNetwork(chainId);
			if (!isChainChanged) {
				setLoading(false);
				throw new Error(
					'There was an error requesting your Metamask to change the chain to start the transaction. Please change the chain manually and click on the button again!',
				);
			}
			setStatus(TxnStatus.ChainChanged);
			const result = await readContract(wagmiConfig, {
				abi: ABI,
				address: contractAddress as `0x${string}`,
				functionName: fnName,
				args: args,
			});
			setLoading(false);
			return result;
		} catch (err) {
			console.log(err);
		}
	};

	return {
		readContract: readContract_,
		account: address,
		isLoading: loading,
	};
};
