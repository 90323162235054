import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogDescription,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { TestAPI } from './TestAPI';
import { PayloadDetails } from './PayloadDetails';
import { APIDetails } from './APIDetails';
import { useConnectAPI } from '../hooks/useConnectAPI';
import useCreateTaskAPI from '../hooks/useCreateTaskAPI';
import { CreateTaskAPISteps } from '../types/task-apis.enum';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { links } from '@/config/links';

const ConnectAPIDialog = ({ open, setOpen }: { open: boolean; setOpen: any }) => {
	const { setFormFields, isLoading, step, setStep } = useConnectAPI();

	const { prevStepMap, handleClick, handleStartOver } = useCreateTaskAPI({
		setOpen,
	});

	const goBack = () => {
		if (prevStepMap[step]) {
			setStep(prevStepMap[step]);
			setFormFields((prev: any) => ({
				...prev,
				isApiTestDone: false,
			}));
		}
	};

	const isTesting = useMemo(() => {
		return (
			step === CreateTaskAPISteps.Testing ||
			step === CreateTaskAPISteps.ReTesting
		);
	}, [step]);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[525px] ">
				<DialogHeader className="border-b pb-3">
					<DialogTitle className="flex justify-between">
						{isTesting ? 'Test with your data' : 'Connect your API'}
						<Link
							to={links?.docs?.apiCreation}
							target="_blank"
							className="text-sm font-normal text-normal text-muted-foreground hover:text-primary/80 hover:underline"
						>
							<i className="bi-question-circle me-1"></i>Guidelines
						</Link>
					</DialogTitle>
					<DialogDescription>
						{isTesting
							? 'Add your payload data to test the API response'
							: 'Add your API details to verify on-chain tasks.'}
					</DialogDescription>
				</DialogHeader>
				{step === CreateTaskAPISteps.Basic && <APIDetails />}
				{step === CreateTaskAPISteps.Payload && <PayloadDetails />}
				{(isTesting || step === CreateTaskAPISteps.Upsert) && (
					<TestAPI setStep={setStep} />
				)}
				<DialogFooter
					className={`justify-between space-x-2 border-t pt-3 flex sm:justify-between `}
				>
					{step !== CreateTaskAPISteps.ReTesting &&
					step !== CreateTaskAPISteps.Basic ? (
						<div className="flex">
							<Button variant="outline" onClick={() => goBack()}>
								Back
							</Button>
						</div>
					) : (
						<div></div>
					)}
					<div className="flex gap-2">
						<Button variant="ghost" onClick={() => handleStartOver()}>
							Cancel
						</Button>
						<Button onClick={() => handleClick()} disabled={isLoading}>
							{isLoading ? (
								<i className="bi bi-arrow-clockwise animate-spin mr-1 text-sm"></i>
							) : null}
							{isTesting
								? 'Test API'
								: step === CreateTaskAPISteps.Upsert
									? 'Save API'
									: 'Next'}
						</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default ConnectAPIDialog;
