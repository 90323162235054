import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { fetchQuery } from '../../services/dave.service';
import { IDaveQueries } from '../../types/dave.types';

export const useGetQuery = () => {
	const { id } = useParams();

	const { data, isLoading } = useQuery<IDaveQueries>({
		queryKey: ['dave-queries', id],
		queryFn: () => fetchQuery(id),
		enabled: !!id,
	});

	return {
		query: data,
		isLoading,
	};
};
