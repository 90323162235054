import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';

export const BeginDataImport = () => {
	const navigate = useNavigate();
	return (
		<div className="p-5">
			<h4 className="font-medium">
				You're on you way to building more community!
			</h4>
			<p className="text-sm text-muted-foreground">
				We're currently importing your discord data and all discord messages
				will automatically show up in your dashboard. In the meanwhile, we
				recommend sending someone appreciation for the work they do
			</p>
			<Button
				size="sm"
				className="mt-4 "
				onClick={() => navigate('/app/integrations')}
			>
				Go to dashboard
			</Button>
		</div>
	);
};
