import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useState } from 'react';
import { IAdminDaveActions } from '../../types/admin.types';

const DaveApprovalDialog = ({
	open,
	setOpen,
}: {
	open: boolean;
	setOpen: any;
	dave: IAdminDaveActions;
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [details, setDetails] = useState({
		status: '',
		message: '',
	});

	const updateStatus = async () => {
		try {
			setIsLoading(true);
			//
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			console.log(err);
		}
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[525px] ">
				<DialogHeader className="border-b pb-3">
					<DialogTitle>Update Enterprise Approval Status</DialogTitle>
				</DialogHeader>
				<DialogFooter
					className={`justify-between space-x-2 border-t pt-3 flex sm:justify-between `}
				>
					<div>
						<div></div>
					</div>
					<div className="flex gap-2">
						<Button variant="ghost" onClick={() => setOpen(false)}>
							Cancel
						</Button>
						<Button onClick={() => updateStatus()} disabled={isLoading}>
							Update
						</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default DaveApprovalDialog;
