import { defineChain } from 'viem';

export const dojima = defineChain({
	id: 1401,
	name: 'Dojima',
	nativeCurrency: { decimals: 18, name: 'DOJ', symbol: 'DOJ' },
	rpcUrls: {
		default: {
			http: ['https://api.d11k.dojima.network/'],
			webSocket: ['https://rpc.d11k.dojima.network/'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Dojima Explorer',
			url: 'https://explorer.dojima.network/',
		},
	},
	testnet: false,
});
