import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';

const ChainBadge = ({
	chainId,
	className,
	hideName = false,
	showAdditionalChains = false,
}: {
	chainId: number;
	className?: string;
	hideName?: boolean;
	showAdditionalChains?: boolean;
}) => {
	const { getChainName, getNativeChainLogo } = useGetSupportedChains({
		showAdditionalChains,
	});

	return (
		<div className={`py-1 flex items-center ${className}`}>
			<img
				src={getNativeChainLogo(chainId)}
				className="w-4 h-4 rounded-full object-contain me-2"
			/>
			{!hideName && getChainName(chainId)}
		</div>
	);
};

export default ChainBadge;
