const useUpdateSocials = ({
	formFields,
	setFormFields,
	setShowAddSocial,
	setFormErrors,
	formErrors,
}: {
	formFields: any;
	setFormFields: any;
	setShowAddSocial: any;
	setFormErrors: any;
	formErrors: any;
}) => {
	const addSocial = (key: any) => {
		const socials = [...formFields.socials];
		socials.push({
			key: key.name.toLowerCase(),
			value: '',
			placeholder: key.placeholder,
		});
		setFormFields({
			...formFields,
			socials: socials,
		});
		setShowAddSocial(false);
	};
	const removeSocial = (item: any) => {
		if (formFields.socials.length <= 2) {
			setFormErrors({
				...formErrors,
				socials: 'You must connect to at least 2 social networks',
			});
			return;
		}
		const socials = formFields.socials.filter(
			(social: any) => social.key !== item.key,
		);
		setFormFields({
			...formFields,
			socials: socials,
		});
	};
	const handleSocialInput = (value: string, index: number) => {
		const socials = [...formFields.socials];
		socials[index].value = value;

		setFormFields({
			...formFields,
			socials: socials,
		});
	};

	return {
		addSocial,
		removeSocial,
		handleSocialInput,
	};
};

export default useUpdateSocials;
