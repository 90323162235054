import AppProvider from './providers/AppProvider';
import AppRoutes from './routes';
import '@/lib/analytics';
import { AppError } from '@/components/layout/error/SentryErrorFallback';
import * as Sentry from '@sentry/react';

export default function App() {
	return (
		<Sentry.ErrorBoundary fallback={AppError} showDialog>
			<AppProvider>
				<AppRoutes />
			</AppProvider>
		</Sentry.ErrorBoundary>
	);
}
