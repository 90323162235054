import ChainBadge from '@/components/element/badges/ChainBadge';
import { DialogHeader } from '@/components/ui/dialog';
import { rewardMethods } from '@/features/campaigns/core/reward-defaults';
import { useMemo } from 'react';

const DepositTokenDialog = ({ tokenReward }) => {
	const totalRewardPool = useMemo(() => {
		if (
			!tokenReward?.tokenReward?.tokenAmountPerUser ||
			!tokenReward?.numRewards ||
			!tokenReward?.tokenReward?.tokenSymbol
		) {
			return 0;
		}
		return (
			+tokenReward?.tokenReward?.tokenAmountPerUser * tokenReward?.numRewards
		);
	}, [
		tokenReward?.numRewards,
		tokenReward?.tokenReward?.tokenAmountPerUser,
		tokenReward?.tokenReward?.tokenSymbol,
	]);

	return (
		<div>
			<div>
				<DialogHeader>
					<div>
						<h3 className="text-xl font-bold mb-2">
							<i className="bi-bank text-violet-600 mr-2"></i>
							Deposit Token Before Launching
						</h3>
						<p className="text-sm text-gray-500">
							Deposit the token you want to reward your users with for
							automatic reward claims.
						</p>
					</div>
				</DialogHeader>

				<div>
					<div className="flex justify-between items-end py-4 border-b mb-4">
						<div>
							<p className="text-sm font-medium">Reward Pool</p>
						</div>
						<div>
							<p className="text-2xl font-bold">
								${totalRewardPool}{' '}
								{tokenReward?.tokenReward?.tokenSymbol}
							</p>
						</div>
					</div>
					<div className="flex justify-between items-center py-4">
						<div>
							<p className="text-sm text-gray-500 ">
								Reward per winner
							</p>
						</div>
						<div className="flex items-center justify-center text-sm font-medium">
							${tokenReward?.tokenReward?.tokenAmountPerUser}{' '}
							{tokenReward?.tokenReward?.tokenSymbol}
						</div>
					</div>
					<div className="flex justify-between items-center py-4">
						<div>
							<p className="text-sm text-gray-500 ">
								Number of winners
							</p>
						</div>
						<div className="flex items-center justify-center text-sm font-medium">
							{tokenReward?.numRewards}
						</div>
					</div>

					<div className="flex justify-between items-center py-4">
						<div>
							<p className="text-sm text-gray-500 ">
								Distribution Method
							</p>
						</div>
						<div className="flex items-center justify-center text-sm font-medium">
							{
								rewardMethods.find(
									(i) => i.method === tokenReward?.method,
								)?.label
							}
						</div>
					</div>

					<div className="flex justify-between items-center py-4">
						<div>
							<p className="text-sm text-gray-500 ">Network</p>
						</div>
						<ChainBadge
							chainId={tokenReward?.tokenReward?.chainId}
							className="justify-center text-sm font-medium"
						/>
					</div>
				</div>
				<div className="bg-gray-100 w-full rounded-lg text-xs p-4 mt-16 font-medium">
					Please Note: Once you submit the tokens, you will not be able to
					change this reward from the product. If you want to get a refund,
					please fill the form.
				</div>
			</div>
		</div>
	);
};

export default DepositTokenDialog;
