import { axios } from '@/lib/axios';

export const setUpUserProfileAPI = async (data: {
	name: string;
	referralCode: string;
	source: string;
	role: string;
}) => {
	const response = await axios.post('/onboarding/user-profile', data);
	return response.data;
};

export const setUpCommunityProfileAPI = async (data: {
	logo: string;
	name: string;
	chainIds: number[];
	category: string[];
	goal: string[];
}) => {
	const response = await axios.post('/onboarding/community-profile', data);
	return response.data;
};

export const setUpCommunityProfileAPIV2 = async (data: {
	name: string;
	twitter: string;
}) => {
	const response = await axios.post('/onboarding/community-profile-v2', data);
	return response.data;
};

export const setUpSocialsAPI = async (data: {
	twitter?: string;
	website?: string;
	discord?: string;
}) => {
	const response = await axios.post('/onboarding/socials', data);
	return response.data;
};

export const getOnboardingData = async () => {
	const response = await axios.get('/onboarding/');
	return response.data;
};
