import PageHeader from '@/components/layout/PageHeader';
import { DataTable } from '@/components/element/data-table/DataTable';
import { useState } from 'react';
import { IAdminDaveActions } from '../types/admin.types';
import { useGetAdminDaveActions } from '../hooks/useGetAdminDaveActions';
import DaveFilters from '../components/dave/DaveFilters';
import DaveApprovalDialog from '../components/dave/DaveApprovalDialog';
import DaveDetailsDialog from '../components/dave/DaveDetailsDialog';
import { daveColumns } from '../components/dave/DaveColumns';
import { useAdminDaveActions } from '../hooks/useAdminDaveActions';
import { toast } from 'sonner';

const AdminDaveActions = () => {
	const [selected, setSelected] = useState<IAdminDaveActions>(null);
	const [showApproval, setShowApproval] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	const { search, setSearch, filters, setFilters, isLoading, results, refetch } =
		useGetAdminDaveActions();
	const { toggleApprovalStatus } = useAdminDaveActions({ refetch });

	const columns = daveColumns([
		{
			type: 'button',
			label: 'Approve Action',
			onClick: (row: IAdminDaveActions) => {
				toggleApprovalStatus(row._id, true);
			},
		},
		{
			type: 'button',
			label: 'Reject Action',
			onClick: (row: IAdminDaveActions) => {
				toggleApprovalStatus(row._id, false);
			},
		},
		{
			type: 'separator',
		},
		{
			type: 'button',
			label: 'Copy Enterprise ID',
			onClick: (row: IAdminDaveActions) => {
				navigator.clipboard.writeText(row.enterprise?._id);
				toast.success('Enterprise ID copied to clipboard');
			},
		},
		{
			type: 'button',
			label: 'Copy Action Tag',
			onClick: (row: IAdminDaveActions) => {
				navigator.clipboard.writeText(row.actionTag);
				toast.success('Action Tag copied to clipboard');
			},
		},
	]);

	return (
		<div>
			<PageHeader title="Dave Actions Approval" />
			<DaveFilters
				search={search}
				filters={filters}
				setSearch={setSearch}
				setFilters={setFilters}
			/>
			<div className="mt-6">
				<DataTable
					onRowClick={(row) => {
						setSelected(row);
						setShowDetails(true);
					}}
					data={results}
					columns={columns}
					totalCount={300}
					isLoading={isLoading}
					isServerSide={false}
				/>
			</div>
			<DaveApprovalDialog
				open={showApproval}
				setOpen={setShowApproval}
				dave={selected}
			/>
			<DaveDetailsDialog
				open={showDetails}
				setOpen={setShowDetails}
				dave={selected}
			/>
		</div>
	);
};

export default AdminDaveActions;
