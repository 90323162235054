import Hero from '../components/homepage/Hero';
import Testimonials from '../components/homepage/Testimonials';
import LandingLayout from '../components/layout/LandingLayout';
import Customers from '../components/homepage/Customers';
import Hero2 from '../components/homepage/Hero2';
import Numbers from '../components/homepage/Numbers';
import Features from '../components/homepage/Features';
import FAQs from '../components/homepage/FAQs';
import ValueProps from '../components/homepage/ValueProps';
import Blogs from '../components/homepage/Blogs';
import { GlobeDemo } from '../components/homepage/Globe';

const HomePage = () => {
	return (
		<LandingLayout>
			<Hero />
			<Hero2 />
			<Customers />
			<GlobeDemo />
			<Numbers />
			<Features />
			<ValueProps />
			<Testimonials />
			<Blogs />
			<FAQs />
		</LandingLayout>
	);
};

export default HomePage;
