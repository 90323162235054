import { useState } from 'react';
import { rearrangeTasks } from '../services/loyalty.service';
import { handleErrorMessage } from '@/utils/notifications';
import { useLoyaltyCampaign } from './useLoyaltyCampaign';
import { TaskSectionUnitTypes } from '@/features/campaigns/types/tasks.enums';
import { queryClient } from '@/lib/react-query';

export const useLoyaltyCampaignReorder = (setOpen?: any) => {
	const { id, campaign } = useLoyaltyCampaign();

	const [isReorderingSections, setIsReorderingSections] = useState(false);
	const [isMoving, setIsMoving] = useState({
		up: false,
		down: false,
	});

	const reorderSections = async (sections: any) => {
		try {
			const isReorderRequired = checkIfReorderRequired(
				sections,
				campaign.taskSections,
			);
			if (!isReorderRequired) return;
			setIsReorderingSections(true);
			await rearrangeTasks(
				id,
				sections?.map((i: any) => {
					return {
						_id: i._id,
						title: i.title,
						displayData: i.items?.map((j: any) => {
							return {
								unitId: j._id,
								unitType: TaskSectionUnitTypes.Task,
							};
						}),
					};
				}),
			);

			setIsReorderingSections(false);
		} catch (err) {
			handleErrorMessage(err);
			setIsReorderingSections(false);
			console.log(err);
		}
	};

	const moveTask = async (taskId: string, dir: 'up' | 'down') => {
		const secitons = campaign.taskSections;
		const sectionIndex = secitons.findIndex((section) =>
			section.displayData.find((task) => task.unitId === taskId),
		);
		const taskIndex = secitons[sectionIndex].displayData.findIndex(
			(task) => task.unitId === taskId,
		);
		const totalTasks = secitons[sectionIndex].displayData.length;

		const newSections = [...secitons];
		if (taskIndex === 0 && sectionIndex === 0 && dir === 'up') return;
		if (
			taskIndex === totalTasks - 1 &&
			sectionIndex === secitons.length - 1 &&
			dir === 'down'
		)
			return;

		if (dir === 'up') {
			if (taskIndex === 0) {
				newSections[sectionIndex - 1].displayData.push(
					newSections[sectionIndex].displayData[taskIndex],
				);
				newSections[sectionIndex].displayData = newSections[
					sectionIndex
				].displayData.filter((_, i) => i !== taskIndex);
			} else {
				const temp = newSections[sectionIndex].displayData[taskIndex];
				newSections[sectionIndex].displayData[taskIndex] =
					newSections[sectionIndex].displayData[taskIndex - 1];
				newSections[sectionIndex].displayData[taskIndex - 1] = temp;
			}
		} else {
			if (taskIndex === totalTasks - 1) {
				newSections[sectionIndex + 1].displayData.unshift(
					newSections[sectionIndex].displayData[taskIndex],
				);
				newSections[sectionIndex].displayData = newSections[
					sectionIndex
				].displayData.filter((_, i) => i !== taskIndex);
			} else {
				const temp = newSections[sectionIndex].displayData[taskIndex];
				newSections[sectionIndex].displayData[taskIndex] =
					newSections[sectionIndex].displayData[taskIndex + 1];
				newSections[sectionIndex].displayData[taskIndex + 1] = temp;
			}
		}
		try {
			setIsMoving((p) => ({ ...p, [dir]: true }));
			await rearrangeTasks(id, newSections);
			queryClient.invalidateQueries({
				queryKey: ['campaign', 'loyalty-quests'],
			});
			setIsMoving((p) => ({ ...p, [dir]: false }));
		} catch (err) {
			setIsMoving((p) => ({ ...p, [dir]: false }));
			handleErrorMessage(err);
		}
	};

	const moveSection = async (sectionId: string, dir: 'up' | 'down') => {
		const secitons = campaign.taskSections;
		const sectionIndex = secitons.findIndex(
			(section) => section._id === sectionId,
		);
		const totalSections = secitons.length;

		const newSections = [...secitons];
		if (sectionIndex === 0 && dir === 'up') return;
		if (sectionIndex === totalSections - 1 && dir === 'down') return;

		if (dir === 'up') {
			const temp = newSections[sectionIndex];
			newSections[sectionIndex] = newSections[sectionIndex - 1];
			newSections[sectionIndex - 1] = temp;
		} else {
			const temp = newSections[sectionIndex];
			newSections[sectionIndex] = newSections[sectionIndex + 1];
			newSections[sectionIndex + 1] = temp;
		}
		try {
			setIsMoving((p) => ({ ...p, [dir]: true }));
			await rearrangeTasks(id, newSections);
			queryClient.invalidateQueries({
				queryKey: ['campaign', 'loyalty-quests'],
			});
			setIsMoving((p) => ({ ...p, [dir]: false }));
		} catch (err) {
			setIsMoving((p) => ({ ...p, [dir]: false }));
			handleErrorMessage(err);
		}
	};

	return {
		reorderSections,
		isMoving,
		isReorderingSections,
		moveUp: (taskId: string) => moveTask(taskId, 'up'),
		moveDown: (taskId: string) => moveTask(taskId, 'down'),
		moveSectionUp: (sectionId: string) => moveSection(sectionId, 'up'),
		moveSectionDown: (sectionId: string) => moveSection(sectionId, 'down'),
	};
};

const checkIfReorderRequired = (sections: any, taskSections: any) => {
	const taskIds = taskSections
		.map((i: any) => i.displayData?.map((j: any) => j.unitId + ':' + i._id))
		.flat();
	const newTaskIds = sections
		.map((i: any) => i.items?.map((j: any) => j._id + ':' + i._id))
		.flat();
	return JSON.stringify(taskIds) !== JSON.stringify(newTaskIds);
};
