export const enterpriseCategories = [
	{ label: 'DeFi', value: 'DeFi' },
	{ label: 'NFT', value: 'NFT' },
	{ label: 'Memecoins', value: 'Memecoins' },
	{ label: 'Gaming', value: 'Gaming' },
	{ label: 'Dex', value: 'Dex' },
	{ label: 'Staking', value: 'Staking' },
	{ label: 'Bridge', value: 'Bridge' },
	{ label: 'Collectibles', value: 'Collectibles' },
	{ label: 'YieldFarming', value: 'YieldFarming' },
	{ label: 'DID', value: 'DID' },
	{ label: 'Infrastructure', value: 'Infrastructure' },
	{ label: 'Metaverse', value: 'Metaverse' },
	{ label: 'DAO', value: 'DAO' },
	{ label: 'Social', value: 'Social' },
	{ label: 'DeSci', value: 'DeSci' },
];
