export const links = {
	docs: {
		campaigns: 'https://intract.gitbook.io/product-guide/product-guides/quests',
		loyalty:
			'https://intract.gitbook.io/product-guide/product-guides/loyalty-program',
		events: 'https://intract.gitbook.io/product-guide/product-guides/events',
		taskAPIs: 'https://intract.gitbook.io/product-guide/tools/integrate-apis',
		dave: 'https://intract.gitbook.io/product-guide/tools/dave-no-code-task-verification',
		nfts: 'https://intract.gitbook.io/product-guide/tools/nft-rewards',
		tokenApproval:
			'https://intract.gitbook.io/product-guide/product-guides/quests/launching-your-quests/setting-up-rewards/adding-custom-token-as-rewards',
		updatingTasks: 'https://docs.intract.io',
		rewards:
			'https://intract.gitbook.io/product-guide/product-guides/quests/launching-your-quests/setting-up-rewards',
		addingContract:
			'https://intract.gitbook.io/product-guide/tools/dave-no-code-task-verification/understanding-and-creating-actions',
		needHelpAddingContract:
			'https://intract.gitbook.io/product-guide/tools/dave-no-code-task-verification/understanding-and-creating-actions',
		apiCreation:
			'https://www.notion.so/intract-io/Create-your-OWN-API-based-template-3cf655ee00e34d27bd230f1fa6deee1e?pvs=4',
		exports:
			'https://intract.gitbook.io/product-guide/getting-started/manage-your-team#data-exports',
		discordIntegration:
			'https://intract.gitbook.io/product-guide/tools/integrations/discord',
		telegramIntegration:
			'https://intract.gitbook.io/product-guide/tools/integrations/telegram',
		kols: 'https://intract.gitbook.io/product-guide/product-guides/kols-marketplace',
		projectsPartnerships:
			'https://intract.gitbook.io/product-guide/product-guides/partnerships',
	},
	telegram: 'https://t.me/Venky3456',
};
