import Testimonials from '../components/homepage/Testimonials';
import LandingLayout from '../components/layout/LandingLayout';
import EnterpriseHero from '../components/enterprise/EnterpriseHero';
import Customers from '../components/homepage/Customers';

const EnterprisePage = () => {
	return (
		<LandingLayout>
			<EnterpriseHero />
			<Customers />
			<Testimonials />
		</LandingLayout>
	);
};

export default EnterprisePage;
