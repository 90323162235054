import { axios } from '@/lib/axios';

export async function fetchAllProjects({
	search,
	filters,
	page,
	pageSize,
}: {
	search: string;
	filters: any;
	page: number;
	pageSize: number;
}) {
	const res = await axios.post(`/boosters/partnerships`, {
		search,
		filters,
		page,
		pageSize,
	});
	return res.data;
}

export async function createPartnershipRequest(body: {
	enterpriseId: string;
	message: string;
	category: string;
}) {
	const res = await axios.post(`/boosters/partnerships/request`, body);
	return res.data;
}
