import { defineChain } from 'viem';

export const bSquared = defineChain({
	id: 223,
	name: 'B2 Mainnet',
	nativeCurrency: { decimals: 8, name: 'Bitcoin', symbol: 'BTC' },
	rpcUrls: {
		default: {
			http: ['https://b2-mainnet.alt.technology/'],
			webSocket: ['https://b2-mainnet.alt.technology/'],
		},
	},
	blockExplorers: {
		default: {
			name: 'B2 Mainnet',
			url: 'https://explorer.bsquared.network/',
		},
	},
	testnet: false,
});
