import { Status } from '@/features/campaigns/types';
import { Button } from '@/components/ui/button';
import { ILoyaltyTask } from '../../types/loyalty.type';
import {
	LoyaltyTaskActions,
	useLoyaltyTasksActions,
} from '../../hooks/tasks/useLoyaltyTasksActions';

const TaskActions = ({
	taskStatus,
	task,
	setOpen,
}: {
	taskStatus: Status;
	task: ILoyaltyTask;
	setOpen: (value: boolean) => void;
}) => {
	const { getTaskAction } = useLoyaltyTasksActions({ setOpen, task });
	return (
		<div className="w-full flex items-center justify-between">
			<div className="space-x-2">
				{!(
					taskStatus === Status.Completed || taskStatus === Status.Inactive
				) && (
					<Button
						onClick={() => getTaskAction(LoyaltyTaskActions.Edit)}
						variant="outline"
					>
						Edit Task
					</Button>
				)}
				{(taskStatus === Status.Completed ||
					taskStatus === Status.Scheduled ||
					taskStatus === Status.Inactive) && (
					<Button
						onClick={() => getTaskAction(LoyaltyTaskActions.Duplicate)}
						variant="outline"
					>
						Duplicate Task
					</Button>
				)}
				{taskStatus === Status.Active && (
					<Button
						onClick={() => getTaskAction(LoyaltyTaskActions.Complete)}
						variant="outline"
					>
						Make Task Complete
					</Button>
				)}
			</div>
			<div>
				{/* {taskStatus === Status.Active && (
					<Button
						onClick={() =>
							getTaskAction(LoyaltyTaskActions.SendNotification)
						}
					>
						Send Discord Notification
					</Button>
				)} */}
				{taskStatus === Status.Scheduled && (
					<Button onClick={() => getTaskAction(LoyaltyTaskActions.Active)}>
						Make Task Active
					</Button>
				)}
				{taskStatus === Status.Completed && (
					<Button
						onClick={() => getTaskAction(LoyaltyTaskActions.Archive)}
					>
						Archive Task
					</Button>
				)}
			</div>
		</div>
	);
};

export default TaskActions;
