import LandingContainer from '../ui/LandingContainer';
import SectionHeader from '../ui/SectionHeader';
import { customersContent } from '../../content/customers.content';

const TopProjects = () => {
	const content = customersContent;

	const projects = content.projects;
	const projectsGrid1 = [];
	const projectsGrid2 = [];
	const projectsGrid3 = [];
	for (let i = 0; i < projects.length; i++) {
		const project = projects[i];
		if (i % 3 === 0) {
			projectsGrid1.push(project);
		} else if (i % 3 === 1) {
			projectsGrid2.push(project);
		} else {
			projectsGrid3.push(project);
		}
	}

	return (
		<LandingContainer className="pt-20">
			<SectionHeader
				title={content.hero.title}
				description={content.hero.description}
				titleGradient={content.hero.titleGradient}
			/>
			<div className="grid grid-cols-1 gap-6 py-8 sm:grid-cols-2 lg:grid-cols-3 px-10 lg:px-0">
				{[projectsGrid1, projectsGrid2, projectsGrid3].map(
					(projectsGrid, index) => (
						<div key={index}>
							{projectsGrid.map((project) => (
								<ProjectItem key={project.link.link} {...project} />
							))}
						</div>
					),
				)}
			</div>
		</LandingContainer>
	);
};

export default TopProjects;

const ProjectItem = ({
	link,
	logo,
	caseStudy,
}: {
	link: { label: string; link: string };
	logo: string;
	caseStudy?: { description: string; link: string };
}) => {
	return (
		<div className="group relative">
			<a
				href={link.link}
				target="_blank"
				className="absolute right-3 top-3 z-10 mx-auto flex max-w-fit items-center space-x-2 rounded-full border border-gray-200 px-3 py-1 text-xs font-medium text-gray-600 opacity-0 transition-all hover:bg-gray-50 group-hover:opacity-100"
			>
				{link.label} ↗
			</a>
			<a target="_blank" href={link.link}>
				<div className="relative mb-6 flex break-inside-avoid flex-col rounded-3xl border border-gray-200 bg-white transition-all">
					<div className="p-8 sm:p-10">
						<img
							alt={link.label}
							draggable="false"
							loading="lazy"
							width={520}
							height={182}
							decoding="async"
							data-nimg={1}
							className="max-h-16 grayscale transition-all group-hover:grayscale-0"
							src={logo}
							style={{ color: 'transparent' }}
						/>
					</div>
					{caseStudy && (
						<div className="flex flex-col space-y-4 border-t border-gray-200 px-8 py-6 text-left">
							<p className="prose prose-gray leading-normal">
								{caseStudy.description}
							</p>
							<p
								className="font-medium text-green-500 group-hover:text-green-600"
								onClick={() => {
									window.open(caseStudy.link);
								}}
							>
								Read more →
							</p>
						</div>
					)}
				</div>
			</a>
		</div>
	);
};
