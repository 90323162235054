import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { useAuth } from '@/hooks/useAuth';
import { useSuggestedActions } from '../../hooks/useSuggestedActions';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';
import { useNavigate } from 'react-router-dom';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

const SuggestedActions = () => {
	const { suggestedActions, isLoading } = useSuggestedActions();

	return (
		<div className="grid grid-cols-2 gap-3 mt-5">
			{isLoading &&
				Array(2)
					.fill(0)
					.map((_, i) => (
						<Skeleton
							key={i}
							className="col-span-1 h-[170px] rounded-2xl"
						/>
					))}
			{suggestedActions.map((i: any, index: number) => (
				<div className="col-span-1">
					<SuggestedAction
						title={i.title}
						description={i.description}
						icon={i.icon}
						className={cn(
							`rounded-2xl  `,
							index === 1 ? 'rounded-s-md' : 'rounded-e-md',
							index === 0
								? 'bg-violet-100 dark:bg-violet-900'
								: 'bg-violet-50 dark:bg-violet-950',
						)}
						learnMore={i.learnMore}
						cta={i.cta}
					/>
				</div>
			))}
		</div>
	);
};

export default SuggestedActions;

const SuggestedAction = ({
	title,
	icon,
	description,
	className,
	learnMore,
	cta,
}) => {
	const navigate = useNavigate();
	return (
		<Card className={`shadow-none  h-full ${className}`}>
			<CardContent className="py-4 h-full flex flex-col">
				<div className="z-30 relative">
					<div className="mb-4">
						<i className={`bi bi-${icon} text-muted-foreground `} />
						<h4 className="text-md font-medium mt-3">{title}</h4>
						<p className="text-xs text-muted-foreground line-clamp-2">
							{description}
						</p>
					</div>
				</div>
				<div className="mt-auto">
					<Button
						size="sm"
						variant="outline"
						onClick={() => {
							analytics.track(
								TrackingEvents.HomepageSuggestedCardsClicked,
								{
									actionType: 'cta',
									cardTitle: title,
									cardLink: cta.action,
								},
							);
							navigate(cta.action);
						}}
					>
						{cta.label}
					</Button>
					<Button
						size="sm"
						variant="link"
						onClick={() => {
							analytics.track(
								TrackingEvents.HomepageSuggestedCardsClicked,
								{
									actionType: 'learn',
									cardTitle: title,
									cardLink: learnMore,
								},
							);
							window.open(learnMore, '_blank');
						}}
					>
						Learn more
					</Button>
				</div>
			</CardContent>
		</Card>
	);
};
