import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { IRewardHistory } from '../../types/leaderboard.types';
import { useState } from 'react';
import { CampaignRewardCategory } from '@/features/campaigns/types';
import { Media } from '@/components/element/media/Media';

const UserRewards = ({ rewards }: { rewards: IRewardHistory[] }) => {
	const [showAll, setShowAll] = useState(false);
	return (
		<div className="px-8 pb-4">
			<div className="flex justify-between">
				<div className="font-medium text-sm text-muted-foreground">
					Rewards
				</div>
				{rewards?.length > 4 ? (
					<Button
						variant="link"
						className="p-0"
						size="sm"
						onClick={() => setShowAll((p) => !p)}
					>
						{showAll ? 'Show less' : 'Show all'}
					</Button>
				) : (
					<></>
				)}
			</div>
			<div className="grid grid-cols-4 gap-3">
				{!!rewards &&
					rewards.length !== 0 &&
					rewards
						.slice(0, showAll ? rewards.length : 4)
						.map((i) => (
							<RewardHistoryItem
								title={i.name}
								image={i.img}
								date={i.claimedAt}
								isToken={
									i.rewardCategory === CampaignRewardCategory.Token
								}
							/>
						))}
			</div>
		</div>
	);
};

export default UserRewards;

const RewardHistoryItem = ({ title, image, isToken }: any) => {
	return (
		<Card className="shadow-none mt-2 rounded">
			<CardContent className="p-2">
				{isToken ? (
					<TokenImage logo={image} title={title} />
				) : (
					<Media
						src={image}
						className="rounded object-cover border aspect-square"
					/>
				)}
				<div className="text-xs text-center mt-2 truncate">{title}</div>
			</CardContent>
		</Card>
	);
};

const TokenImage = ({ logo, title }) => {
	return (
		<div
			className="rounded"
			style={{
				overflow: 'hidden',
				width: '100%',
				aspectRatio: '1/1',
				padding: '1rem',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'relative',
				border: '1px solid rgba(255,255,255,.1)',
				background:
					'linear-gradient(250deg, rgb(27 68 123) 14%, rgb(96 114 127))',
			}}
		>
			<img
				src="https://www.intract.io/assets/grid-4522a485.png"
				alt="grid"
				style={{
					width: '70%',
					aspectRatio: '1/1',
					objectFit: 'cover',
					position: 'absolute',
				}}
			/>
			<img
				src={logo}
				alt="logo"
				style={{
					width: '57px',
					height: '57px',
					flexShrink: '0',
					borderRadius: '100%',
					position: 'relative',
					zIndex: '2',
				}}
			/>
			<img
				src="https://www.intract.io/assets/star-a7225128.png"
				style={{
					position: 'absolute',
					width: '18.867px',
					height: '18.867px',
					flexShrink: '0',
					objectFit: 'contain',
					bottom: '30%',
					right: '30%',
				}}
			/>
			<img
				src="https://www.intract.io/assets/star-a7225128.png"
				style={{
					position: 'absolute',
					width: '9.867px',
					height: '9.867px',
					flexShrink: '0',
					objectFit: 'contain',
					bottom: '30%',
					right: '30%',
				}}
			/>
		</div>
	);
};
