import { Separator } from '@/components/ui/separator';
import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import logo from '@/assets/logo.svg';
import { Badge } from '@/components/ui/badge';
import Dayjs from '@/lib/dayjs';
import { Input } from '@/components/plate-ui/input';
import { Link } from 'react-router-dom';

const LayoutContext = createContext<{
	showGrid?: boolean;
	headerHeight?: number;
	headerRef?: any;
	navigationRef?: any;
}>({});

const FullScreenCreateLayout = ({
	showGrid,
	children,
}: {
	showGrid: boolean;
	children: JSX.Element[];
}) => {
	const headerRef = useRef<any>(null);
	const navigationRef = useRef<any>(null);

	const [headerHeight, setHeaderHeight] = useState(0);

	useEffect(() => {
		if (!headerRef.current || !navigationRef.current) return;
		setHeaderHeight(
			headerRef.current.clientHeight + navigationRef.current.clientHeight + 25,
		);
	}, [headerRef, navigationRef]);

	useEffect(() => {
		const interval = setInterval(() => {
			const intercomEle = document.getElementsByClassName(
				'intercom-lightweight-app-launcher',
			);
			if (intercomEle.length > 0) {
				clearInterval(interval);
				const ele = intercomEle[0];
				(ele as any).style.bottom = '90px';
				console.log('Adjusted Intercom launcher position');
			}
		}, 100); // Checks every 100ms

		return () => {
			const intercomEle = document.getElementsByClassName(
				'intercom-lightweight-app-launcher',
			);
			if (intercomEle.length > 0) {
				const ele = intercomEle[0];
				(ele as any).style.bottom = '20px'; // Reset position on unmount
				console.log('Reset Intercom launcher position');
			}
		};
	}, []);

	const value = {
		showGrid,
		headerHeight,
		headerRef,
		navigationRef,
	};

	return (
		<LayoutContext.Provider value={value}>
			<div>{children}</div>
		</LayoutContext.Provider>
	);
};

const Header = ({ children }) => {
	const { headerRef } = useContext(LayoutContext);
	return (
		<header className="border-b bg-background" ref={headerRef}>
			{children}
		</header>
	);
};

const Title = ({
	name,
	placeholder,
	status,
	lastSaved,
	onFeedback,
	onBack,
	setName,
}: {
	name: string;
	placeholder: string;
	status?: string;
	lastSaved?: Date;
	onFeedback?: () => void;
	onBack: () => void;
	setName?: (e: string) => void;
}) => {
	return (
		<div className="border-b flex justify-between items-center px-5 py-3">
			<div className="flex justify-center items-center">
				<Link to="/">
					<img src={logo} alt="back" className="h-7" />
				</Link>
				<Separator className="mx-5 h-6" orientation="vertical" />
				<div className="flex items-center">
					<div onClick={onBack} className=" cursor-pointer">
						<i className="text-muted-foreground bi-arrow-left me-4 " />
					</div>
					<Input
						variant="ghost"
						className={`text-md h-7 p-0 ${
							!name
								? 'font-normal text-muted-foreground'
								: 'font-medium'
						}`}
						placeholder="Untitled Section"
						value={name}
						onChange={(e) => setName(e?.target?.value)}
					/>
				</div>
			</div>
			<div className="flex justify-center items-center">
				{onFeedback && (
					<div
						className="me-4 text-sm font-medium cursor-pointer"
						onClick={onFeedback}
					>
						<i className="bi bi-question-circle text-muted-foreground me-2"></i>
						Feedback
					</div>
				)}
				{lastSaved && (
					<div className="text-sm text-muted-foreground">
						Draft. Saved {Dayjs(lastSaved).fromNow(true)}.
					</div>
				)}
			</div>
		</div>
	);
};

const Sections = ({ children }) => {
	return (
		<div className="flex justify-center items-center px-5 py-4 relative">
			{children}
		</div>
	);
};
const Content = ({
	children,
	fullHeight,
}: {
	showPreview?: boolean;
	children: JSX.Element | JSX.Element[] | ReactNode;
	fullHeight?: boolean;
}) => {
	const { headerHeight, showGrid } = useContext(LayoutContext);
	return (
		<div
			style={{
				maxHeight: fullHeight
					? `calc(100vh - ${headerHeight}px)`
					: `calc(100vh - ${headerHeight}px)`,
				minHeight: fullHeight
					? `calc(100vh - ${headerHeight}px)`
					: `calc(100vh - ${headerHeight}px)`,
			}}
			className=" bg-slate-100 dark:bg-slate-900 rounded-lg m-3 pb-6 relative"
		>
			{showGrid && (
				<div
					className="h-full w-full absolute top-0 start-0 pointer-events-none"
					style={{
						backgroundImage:
							'radial-gradient(#e5e4e4 1px, transparent 0px)',
						backgroundSize: '10px 10px',
						backgroundPosition: '-19px -19px',
					}}
				></div>
			)}
			<div
				className="pb-10 flex justify-center"
				style={{
					maxHeight: `calc(100vh - ${headerHeight}px )`,
					minHeight: `calc(100vh - ${headerHeight}px )`,
					overflowY: 'auto',
				}}
			>
				{children}
			</div>
		</div>
	);
};

const Preview = ({ showPreview, setShowPreview }) => {
	return <></>;
};

const Navigation = ({ children }) => {
	const { navigationRef } = useContext(LayoutContext);
	return <div ref={navigationRef}>{children}</div>;
};

FullScreenCreateLayout.Header = Header;
FullScreenCreateLayout.Title = Title;
FullScreenCreateLayout.Sections = Sections;
FullScreenCreateLayout.Content = Content;
FullScreenCreateLayout.Navigation = Navigation;

Content.Preview = Preview;

export default FullScreenCreateLayout;
