import LandingLayout from '../components/layout/LandingLayout';

import CareersHero from '../components/careers/CareersHero';
import WhyIntract from '../components/careers/WhyIntract';
import OpenPositions from '../components/careers/OpenPositions';
import JoinWiningCulture from '../components/careers/JoinWiningCulture';
import IntractTeamPhotos from '../components/careers/IntractTeamPhotos';
import IntractCulture from '../components/about/IntractCulture';

const CareersPage = () => {
	return (
		<LandingLayout>
			<CareersHero />
			<WhyIntract />
			<IntractCulture />
			<IntractTeamPhotos />
			<OpenPositions />
			<JoinWiningCulture />
		</LandingLayout>
	);
};

export default CareersPage;
