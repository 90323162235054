import React from 'react';
import { useConfirmEmail } from '../../hooks/useConfirmEmail';
import { useAuth } from '@/hooks/useAuth';

const BannerNotification = () => {
	const { user } = useAuth();
	const { resendEmail } = useConfirmEmail();

	return <></>;
	if (!user.isEmailVerified) {
		return (
			<div className="flex w-full items-center justify-center bg-red-50 text-primary text-xs py-2  ">
				Confirm your email to access all features. Check your email for the
				confirmation link.
				<span
					onClick={resendEmail}
					className="hover:underline px-2 cursor-pointer font-semibold text-primary-500"
				>
					Resend email
				</span>
				<div className="absolute end-0 pe-4">
					<i className="bi-x text-xl cursor-pointer"></i>
				</div>
			</div>
		);
	}
	return <></>;
};

export default BannerNotification;
