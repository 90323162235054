import CommunityHubOverview from '../components/CommunityHubOverview';
import { useAuth } from '@/hooks/useAuth';
import { QUEST_URL } from '@/config';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

const CustomiseHubPage = () => {
	const { user } = useAuth();
	return (
		<div>
			<div className={cn('flex items-center justify-between')}>
				<div className="space-y-1">
					<h2 className="text-xl font-medium tracking-tight">
						Project Profile
					</h2>
					<p className="text-sm text-muted-foreground max-w-[700px]">
						Customise your community hub to match your brand and style
					</p>
				</div>
				<div className="ml-auto mr-4">
					<Button
						onClick={() => {
							window.open(
								`${QUEST_URL}/project/${user?.enterpriseSlug}`,
								'_blank',
							);
						}}
						variant="outline"
					>
						<i className={`bi-share-fill me-2`}></i>
						Share your hub
					</Button>
				</div>
			</div>
			<CommunityHubOverview />
		</div>
	);
};

export default CustomiseHubPage;
