import { AuthenticateDiscord } from './AuthenticateDiscord';
import { ChannelPermissions } from './ChannelPermissions';
import { BeginDataImport } from './BeginDataImport';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import grid1 from '../../assets/grid-1.png';
import grid2 from '../../assets/grid-2.png';
import logo from '@/assets/logo.svg';

export const DiscordDataImport = ({
	isLoadingChannels,
	step,
	setStep,
	channels,
	isFetchingChannels,
	authenticateDiscord,
	handleFetching,
	loading,
	refetchChannel,
	isAuthenticating,
	integrationId,
}) => {
	if (isAuthenticating || isLoadingChannels) {
		return <FullPageLoading />;
	}

	return (
		<div className="grid grid-cols-12 mt-5 gap-10">
			<div className="col-span-2"></div>
			<div className="col-span-8 ">
				<div className="py-4 flex items-center justify-center">
					<img src={grid1} className="h-14 w-14 me-4" />
					<div className="h-16 w-16 border-2 rounded-lg flex items-center justify-center ">
						<img
							className="h-14 w-14 bg-[#b470ec] p-2 rounded-lg"
							src={logo}
						/>
					</div>
					<div className="h-6 w-6 rounded-full border -mx-2 bg-white flex items-center justify-center z-10">
						<i className="bi-link-45deg text-muted-foreground text-sm"></i>
					</div>
					<div className="h-16 w-16 border-2 rounded-lg flex items-center justify-center">
						<img
							className="h-14 w-14  bg-[#000] rounded-lg "
							src={
								'https://gdm-catalog-fmapi-prod.imgix.net/ProductLogo/a85b36ef-ff72-49be-8c06-c385860eecd0.jpeg'
							}
						/>
					</div>
					<img src={grid2} className="h-14 w-14 ms-4" />
				</div>
				<div className="space-y-1 mb-10 text-center ">
					<h2 className="text-2xl font-medium tracking-tight">
						Discord Integration
					</h2>
					<p className="text-sm text-muted-foreground ">
						Connect Discord to assign user discord roles, send
						announcements and more
					</p>
				</div>
				<Card>
					<CardHeader_
						step={1}
						activeStep={step}
						title="Connect your community Discord"
					/>
					{step === 1 && (
						<CardContent>
							<AuthenticateDiscord
								authenticateDiscord={authenticateDiscord}
							/>
						</CardContent>
					)}
					<CardHeader_
						step={2}
						activeStep={step}
						title="Confirm your imported
								channels"
					/>
					{step === 2 && (
						<CardContent>
							<ChannelPermissions
								setStep={setStep}
								channels={channels}
								isFetchingChannels={isFetchingChannels}
								isLoadingChannels={isLoadingChannels}
								handleFetching={handleFetching}
								loading={loading}
								refetchChannel={refetchChannel}
								integrationId={integrationId}
							/>
						</CardContent>
					)}
					<CardHeader_
						step={3}
						activeStep={step}
						title="Begin data import"
					/>
					{step === 3 && (
						<CardContent>
							<BeginDataImport />
						</CardContent>
					)}
				</Card>
			</div>
			<div className="col-span-2"></div>
			{/* <div className="2xl:col-span-3 col-span-4">
				<ImportHelper />
			</div> */}
		</div>
	);
};

const CardHeader_ = ({ step, activeStep, title }) => {
	return (
		<CardHeader
			className={cn(
				'flex flex-row py-4 ',
				step === activeStep ? 'text-black' : 'text-muted-foreground',
				step > 1 ? 'border-t' : '',
			)}
		>
			<i className={`bi-${step}-circle-fill me-2`}></i>
			{title}
		</CardHeader>
	);
};
